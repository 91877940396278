import useCurrentCustomerStore from "store/CustomerStore";
import { useFromUser } from "store/LocalStorageHelper";

export const useBuildCustomer = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const {
		ID,
		firstName,
		middleName,
		lastName,
		gender,
		generation,
		age,
		cellPhone,
		SSN,
		birthDate,
		email,
		extraPhone,
		homePhone,
		workPhone,
		license,
		licExpires,
		licState,
		street,
		unit,
		city,
		state,
		zip,
		county,
		fromDate,
		toDate,
		notes,
		creditScore,
		creditScoreEfx,
		creditScoreXpn,
		source,
		mailRecNum,
		customerNumber,
		llCity,
		llName,
		llPhone,
		llState,
		llStreet,
		llUnit,
		llZip,
		customerStatus,
		originalID,
		status,
		military,
		type,
		licType,
		licCountry,
		uploadCode,
	} = useCurrentCustomerStore();
	// Customer information stored and retrieved from the store
	const customer = {
		ID,
		firstName,
		middleName,
		lastName,
		gender,
		generation,
		age,
		cellPhone,
		SSN,
		birthDate,
		email,
		extraPhone,
		homePhone,
		workPhone,
		license,
		licExpires,
		licState,
		street,
		unit,
		city,
		state,
		zip,
		county,
		fromDate,
		toDate,
		dealerID,
		locationID,
		notes: notes.length > 0 ? JSON.stringify(notes) : "",
		creditScore,
		creditScoreEfx,
		creditScoreXpn,
		source,
		mailRecNum,
		customerNumber,
		llCity,
		llName,
		llPhone,
		llState,
		llStreet,
		llUnit,
		llZip,
		customerStatus,
		originalID,
		status,
		military,
		type,
		licType,
		licCountry,
		uploadCode,
	};

	return customer;
};

export default useBuildCustomer;
