import React from "react";
import { Row, Col } from "reactstrap";
import NumberFormat from "react-number-format";
import ReviewVehicle from "./ReviewVehicle";
import ReviewCustomer from "./ReviewCustomer";
import ReviewCash from "./ReviewCash";
import ReviewDown from "./ReviewDown";
import ReviewFinance from "./ReviewFinance";

export const DisplayCurrency = ({ value }) => {
	return (
		<NumberFormat
			value={value}
			thousandSeparator={true}
			decimalScale={2}
			fixedDecimalScale={true}
			prefix={"$"}
			isNumericString={true}
			displayType="text"
		/>
	);
};

export const DisplayPercent = ({ value }) => {
	return (
		<NumberFormat
			value={value * 100}
			decimalScale={2}
			fixedDecimalScale={true}
			suffix={"%"}
			isNumericString={true}
			displayType="text"
		/>
	);
};

export const ReviewRow = ({
	isCurrency = false,
	isPercent = false,
	value,
	label,
	right = true,
	fontSize = "68%",
	colSize = "6",
	isPhone = false,
}) => {
	return (
		<Row style={{ fontSize: fontSize }}>
			<Col xs={colSize}>{label}</Col>

			<Col className={right ? "text-right" : ""}>
				{isCurrency && <DisplayCurrency value={value} />}
				{isPercent && <DisplayPercent value={value} />}
				{!isCurrency && !isPercent && !isPhone && value}
				{isPhone && (
					<NumberFormat
						value={value}
						format="### ###-####"
						isNumericString={true}
						displayType="text"
					/>
				)}
			</Col>
		</Row>
	);
};

const Review = () => {
	return (
		<Col>
			<Row className="px-0">
				<Col>
					<Row>
						<Col sm="6">
							<ReviewVehicle />
						</Col>
						<Col sm="6">
							<ReviewCustomer />
						</Col>
						<Col sm="6">
							<ReviewCash />
						</Col>
						<Col sm="6">
							<ReviewDown />
						</Col>
					</Row>
				</Col>
				<Col xl="4">
					<Row>
						<Col>
							<ReviewFinance />
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};

export default Review;
