import React from "react";
import { Table, Row, Col, CardBody, Card } from "reactstrap";
import PaymentOption from "component/dc/deal/paymentOptions/PaymentOption";

const PaymentOptionsModal = ({ toggle }) => {
	return (
		<div>
			<Card>
				<CardBody>
					<div>
						<Table>
							<thead>
								<div className="py-2">
									<Row className="pb-1">
										<Col sm="7">
											<Row>
												<Col xs="3">
													<p className="h6 mb-0">Term</p>
												</Col>
												<Col xs="4">
													<p className="h6 mb-0">Payment</p>
												</Col>
												<Col xs="5">
													<p className="h6 mb-0">Finance Chg.</p>
												</Col>
											</Row>
										</Col>

										<Col sm="5">
											<Row className="mx-0">
												<Col xs="5">
													<p className="h6 mb-0">Date</p>
												</Col>
												<Col xs="5"></Col>
											</Row>
										</Col>
									</Row>
									<hr />
								</div>
								
							</thead>
							<tbody>
								<PaymentOption rowNum={1} toggle={toggle} />
								<PaymentOption rowNum={2} toggle={toggle} />
								<PaymentOption rowNum={3} toggle={toggle} />
								<PaymentOption rowNum={4} toggle={toggle} />
								<PaymentOption rowNum={5} toggle={toggle} />
								<PaymentOption rowNum={6} toggle={toggle} />
								<PaymentOption rowNum={7} toggle={toggle} />
								<PaymentOption rowNum={8} toggle={toggle} />
								<PaymentOption rowNum={9} toggle={toggle} />
							</tbody>
						</Table>
					</div>
				</CardBody>
			</Card>
		</div>
	);
};

export default PaymentOptionsModal;
