import React from "react";
import {
	Row,
	Col,
	CardTitle,
	CardBody,
	CardHeader,
	Input,
	Label,
} from "reactstrap";
import useCurrentSaleStore from "store/SaleStore";
import dayjs from "dayjs";
import NumberFormat from "react-number-format";
import shallow from "zustand/shallow";

const Summary = () => {
	const {
		firstDueDate,
		finalDueDate,
		payment,
		finalPmt,
		term,
		amtFinanced,
		financeCharge,
		loan,
		balloonDate,
		balloonTerm,
		balloon,
		hasBalloon,
		onePay,
		monthlyTax,
		deferredTax,
		taxType,
	} = useCurrentSaleStore((state) => state, shallow);

	const CurrencyDisplay = ({ value, className = "bold" }) => (
		<NumberFormat
			readOnly={true}
			value={value}
			thousandSeparator={true}
			decimalScale={2}
			fixedDecimalScale={true}
			prefix={"$"}
			isNumericString={true}
			customInput={Input}
			className={className}
		/>
	);

	const summaryRow1 = [
		{
			label: "First Due Date",
			value: (
				<Input
					className="bold"
					value={firstDueDate ? dayjs(firstDueDate).format("MM/DD/YYYY") : ""}
					disabled
				/>
			),
		},
		{
			label: "Final Due Date",
			value: (
				<Input
					className="bold"
					value={finalDueDate ? dayjs(finalDueDate).format("MM/DD/YYYY") : ""}
					disabled
				/>
			),
		},
		{
			label: (term > 1 ? term - 1 : 1) + " Payments of  ",
			value: (
				<CurrencyDisplay value={payment} className="regular-payment bold" />
			),
		},
		taxType === 1
			? {
					label: "With monthly Tax ",
					value: (
						<CurrencyDisplay
							value={monthlyTax}
							className="regular-payment bold"
						/>
					),
			  }
			: "",
		{
			label: "Final Payment",
			value: (
				<CurrencyDisplay value={finalPmt} className="final-payment bold" />
			),
		},
	];

	const balloonSummaryRow = [
		{
			label: "First Due Date",
			value: (
				<Input
					className="bold"
					value={firstDueDate ? dayjs(firstDueDate).format("MM/DD/YYYY") : ""}
					disabled
				/>
			),
		},
		{
			label: "Balloon Date",
			value: (
				<Input
					className="bold"
					value={balloonDate ? dayjs(balloonDate).format("MM/DD/YYYY") : ""}
					disabled
				/>
			),
		},
		{
			label: (balloonTerm >= 1 ? balloonTerm : 0) + " Payments",
			value: <CurrencyDisplay value={payment} className="text-success bold" />,
		},
		{
			label: "Balloon Payment",
			value: <CurrencyDisplay value={balloon} className="bold" />,
		},
	];

	const summaryRow2 = [
		{
			label: "Out the Door Price",
			value: <CurrencyDisplay value={onePay} />,
		},
		{
			label: "Amount Financed",
			value: <CurrencyDisplay value={amtFinanced} />,
		},
		{
			label: "Finance Charge",
			value: <CurrencyDisplay value={financeCharge} />,
		},
		taxType === 1
			? {
					label: "Tax Deferred",
					value: <CurrencyDisplay value={deferredTax} />,
			  }
			: "",
	];

	const summaryRow3 = [
		{
			label: "Total of Payments",
			value: <CurrencyDisplay value={loan} />,
		},
	];

	let row1 = summaryRow1;

	if (hasBalloon) {
		row1 = balloonSummaryRow;
	}

	return (
		<div className="deal-summary">
			<CardHeader>
				<CardTitle tag="h3">Deal Summary</CardTitle>
			</CardHeader>
			<CardBody className="pb-3">
				<Row>
					{row1.map((obj) => (
						<>
							<Col className="mb-2 col-6 col-xxl-6">
								<Label className="bold" value="">
									{obj.label}
								</Label>
								{obj.value}
							</Col>
						</>
					))}
				</Row>
				<hr className="my-2" />
				{summaryRow2.map((obj) => (
					<div>
						<Row className="">
							<Col xl="6">
								<Label className="pt-2">{obj.label}</Label>
							</Col>
							<Col xl="6">{obj.value}</Col>
						</Row>
					</div>
				))}

				<hr className="my-2" />
				{summaryRow3.map((obj) => (
					<Row>
						<Col className="my-2 col-6 px-0 text-center">
							<h6>{obj.label}</h6>
						</Col>
						<Col className="my-2 col-6 px-0 text-center deal-total">
							{obj.value}
						</Col>
					</Row>
				))}
			</CardBody>
		</div>
	);
};

export default Summary;
