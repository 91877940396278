import create from "zustand";

import { convertToReactSelect } from "component/dc/selector//helperFunction/SelectorFormat.js";
import { getNotesArray } from "component/dc/note/NoteFunctions";
//import { date } from "yup";
import dayjs from "dayjs";
import { version } from "../version";

const useCurrentVehicleStore = create((set) => ({
	inventoryID: null,
	stockNo: "",
	vin: "",
	year: "",
	make: "",
	model: "",
	trim: "",
	odometerIn: "",
	color: "",
	intColor: "",
	condition: "",
	transmission: "",
	doors: "",
	engine: "",
	mPGHwy: "",
	mPGCity: "",
	weight: "",
	tag: "",
	description: "",
	titleNo: "",
	customEquip: "",
	selectedEquip: "",
	optionalEquip: "",
	type: "",
	titleDateOfIssue: "",
	standardEquip: "",
	specificationEquip: "",
	isLoading: true,
	isVinLoading: false,
	thumbnail: "",
	askingPrice: 0,
	onlinePrice: "",
	dateIn: dayjs().format("YYYY-MM-DD"),
	cost: 0,
	pack: 0,
	holdBack: 0,
	incentive: 0,
	totalRecon: 0,
	totalOtherCost: 0,
	totalCost: 0,
	downPayment: 0,
	msrp: 0,
	weightFee: 0,
	isDraft: false,
	datePaid: "",
	titleBrand: "Clean",
	paymentMethod: "",
	checkNo: 0,
	newUsed: "Used",
	license: "",
	licenseExpires: "",
	location: "",
	buyer: "",
	vendorName: "",
	notes: [],
	reportOfSale: "",
	style: "",
	status: "Available",
	numCylinders: "",
	odometerOut: "",
	driveTrain: "",
	fuelType: "",
	axelCount: 0,

	wholesalePrice: 0,
	actualMileageFlag: 0,
	newTitleReceivedDate: "",
	newTitleNumber: "",
	titleInDate: "",
	titleState: "",
	newTitleState: "",
	shippedDate: dayjs().format("YYYY-MM-DD"),
	auctionDate: dayjs().format("YYYY-MM-DD"),
	smogCheckDate: dayjs().format("YYYY-MM-DD"),
	soldDate: dayjs().format("YYYY-MM-DD"),
	dcVersion: "v" + version,

	createdOn: dayjs().format("YYYY-MM-DD"),
	createdByID: "",
	modifiedOn: dayjs().format("YYYY-MM-DD"),
	modifiedByID: "",
	openedBy: "",
	isSmogCheck: 0,
	kbbVehicleId: null,

	flooringComp: "",
	isFloored: false,
	flooringInt: 0,
	useFlooring: 0,
	flooringAmt: 0,
	flooringValue: 0,
	flooringAdminFee: 0,
	flooringCheckNo: "",
	flooring: 0,
	flooringPaidOn: dayjs().format("YYYY-MM-DD"),
	dateFloored: dayjs().format("YYYY-MM-DD"),
	flooringStopDate: dayjs().format("YYYY-MM-DD"),

	hasGPS: false,
	gps_ID: "",
	gpsCompany: "",
	gpsWebsite: "",
	gps_ID2: "",
	gpsCompany2: "",
	gpsWebsite2: "",
	hasGPS2: false,
	videoURL: "",
	isAdvertised: true,
	acv: 0,
	allowance: 0,
	isTradeIn: false,
	payoff: 0,

	tradeLenderRecNum: null,
	tradeLenderList: [],

	buyersList: [],
	locationList: [],
	costHist: [],
	costDescList: [],
	departmentsList: [],
	vendorRecNum: null,
	allVendorList: [],
	vendorsList: [],
	reconVendorsList: [],
	flooringVendorsList: [],
	styleList: [],
	colorList: [],
	intColorList: [],
	makeList: [],
	modelList: [],
	customEquipList: [],

	editAxelCount: (draftAxelCount) => set({ axelCount: draftAxelCount }),
	editDriveTrain: (draftDriveTrain) => set({ driveTrain: draftDriveTrain }),
	editFuelType: (draftFuelType) => set({ fuelType: draftFuelType }),
	editInventoryID: (draftInventoryID) => set({ inventoryID: draftInventoryID }),
	editStockNo: (draftStockNo) => set({ stockNo: draftStockNo }),
	editVin: (draftVin) => set({ vin: draftVin }),
	editYear: (draftYear) => set({ year: draftYear }),
	editMake: (draftMake) => set({ make: draftMake }),
	editModel: (draftModel) => set({ model: draftModel }),
	editTrim: (draftTrim) => set({ trim: draftTrim }),
	editOdometerIn: (draftOdometerIn) => set({ odometerIn: draftOdometerIn }),
	editColor: (draftColor) => set({ color: draftColor }),
	editIntColor: (draftIntColor) => set({ intColor: draftIntColor }),
	editCondition: (draftCondition) => set({ condition: draftCondition }),
	editTransmission: (draftTransmission) =>
		set({ transmission: draftTransmission }),
	editDoors: (draftDoors) => set({ doors: draftDoors }),
	editEngine: (draftEngine) => set({ engine: draftEngine }),
	editMpgHwy: (draftMpgHwy) => set({ mPGHwy: draftMpgHwy }),
	editMpgCity: (draftMpgCity) => set({ mPGCity: draftMpgCity }),
	editWeight: (draftWeight) => set({ weight: draftWeight }),

	editTag: (draftTag) => set({ tag: draftTag }),
	editDescription: (draftDescription) => set({ description: draftDescription }),
	editTitleNo: (draftTitleNo) => set({ titleNo: draftTitleNo }),

	editCustomEquip: (draftCustomEquip) => set({ customEquip: draftCustomEquip }),
	editSelectedEquip: (draftSelectedEquip) =>
		set({ selectedEquip: draftSelectedEquip }),
	editOptionalEquip: (draftOptionalEquip) =>
		set({ optionalEquip: draftOptionalEquip }),
	editType: (draftType) => set({ type: draftType }),
	editTitleDateOfIssue: (draftTitleDateOfIssue) =>
		set({ titleDateOfIssue: draftTitleDateOfIssue }),
	editStandardEquip: (draftStandardEquip) =>
		set({ standardEquip: draftStandardEquip }),
	editSpecificationEquip: (draftSpecificationEquip) =>
		set({ specificationEquip: draftSpecificationEquip }),
	editIsLoadingTrue: () => set({ isLoading: true }),
	editIsLoadingFalse: () => set({ isLoading: false }),
	editIsVinLoading: (draftIsLoading) => set({ isVinLoading: draftIsLoading }),
	editThumbnail: (draftThumbnail) => set({ thumbnail: draftThumbnail }),
	editAskingPrice: (draftAskingPrice) => set({ askingPrice: draftAskingPrice }),
	editOnlinePrice: (draftOnlinePrice) => set({ onlinePrice: draftOnlinePrice }),

	editDateIn: (dateIn) => set({ dateIn: dateIn }),

	editCost: (cost) => set({ cost: cost }),
	editAcv: (acv) => set({ acv: acv }),
	editAllowance: (allowance) => set({ allowance: allowance }),
	editPayoff: (payoff) => set({ payoff: payoff }),
	editIsTradeIn: (isTradeIn) => set({ isTradeIn: isTradeIn }),
	tradeLienHolderList: [],

	editPack: (pack) => set({ pack: pack }),
	editHoldBack: (holdBack) => set({ holdBack: holdBack }),
	editIncentive: (incentive) => set({ incentive: incentive }),
	editTotalRecon: (totalRecon) => set({ totalRecon: totalRecon }),
	editTotalOtherCost: (totalOtherCost) =>
		set({ totalOtherCost: totalOtherCost }),

	editTotalCost: (totalCost) => set({ totalCost: totalCost }),

	editCostHist: (draftCostHist) => set({ costHist: draftCostHist }),
	editAllVendorList: (draftAllVendorList) =>
		set({ allVendorList: draftAllVendorList }),
	editVendorsList: (draftVendorsList) => set({ vendorsList: draftVendorsList }),
	editReconVendorsList: (draftReconVendorsList) =>
		set({ reconVendorsList: draftReconVendorsList }),
	editFlooringVendorsList: (draftFlooringVendorsList) =>
		set({ flooringVendorsList: draftFlooringVendorsList }),

	editLocationsList: (draftLocationsList) =>
		set({ locationList: draftLocationsList }),
	editBuyersList: (draftBuyersList) => set({ buyersList: draftBuyersList }),
	editCostDescList: (draftCostDescList) =>
		set({ costDescList: draftCostDescList }),
	editDepartmentsList: (draftDepartmentsList) =>
		set({ departmentsList: draftDepartmentsList }),
	editDownPayment: (draftDownPayment) => set({ downPayment: draftDownPayment }),
	editMsrp: (draftMsrp) => set({ msrp: draftMsrp }),
	editWeightFee: (draftWeightFee) => set({ weightFee: draftWeightFee }),
	editIsDraft: (draftIsDraft) => set({ isDraft: draftIsDraft }),
	editDatePaid: (draftDatePaid) => set({ datePaid: draftDatePaid }),
	editTitleBrand: (draftTitleBrand) => set({ titleBrand: draftTitleBrand }),
	editPaymentMethod: (draftPaymentMethod) =>
		set({ paymentMethod: draftPaymentMethod }),
	editCheckNo: (draftCheckNo) => set({ checkNo: draftCheckNo }),
	editNewUsed: (draftNewUsed) => set({ newUsed: draftNewUsed }),
	editLicense: (draftLicense) => set({ license: draftLicense }),
	editLicenseExpires: (draftLicenseExpires) =>
		set({ licenseExpires: draftLicenseExpires }),
	editLocation: (draftLocation) => set({ location: draftLocation }),
	editBuyer: (draftBuyer) => set({ buyer: draftBuyer }),
	editNotes: (draftNotes) => set({ notes: draftNotes }),
	editVendorRecNum: (draftVendorRecNum) =>
		set({ vendorRecNum: draftVendorRecNum }),
	editVendorName: (draftVendorName) => set({ vendorName: draftVendorName }),
	editReportOfSale: (draftReportOfSale) =>
		set({ reportOfSale: draftReportOfSale }),
	editStyle: (val) => set({ style: val }),
	editStyleList: (val) => set({ styleList: val }),
	editColorList: (val) => set({ colorList: val }),
	editIntColorList: (val) => set({ intColorList: val }),
	editStatus: (val) => set({ status: val }),
	editNumCylinders: (val) => set({ numCylinders: val }),
	editOdometerOut: (draftOdometerOut) => set({ odometerOut: draftOdometerOut }),

	editCustomEquipList: (val) => set({ customEquipList: val }),
	editMakeList: (val) => set({ makeList: val }),
	editModelList: (val) => set({ modelList: val }),
	editWholesalePrice: (val) => set({ wholesalePrice: val }),
	editActualMileageFlag: (val) => set({ actualMileageFlag: val }),
	editNewTitleReceivedDate: (val) => set({ newTitleReceivedDate: val }),
	editNewTitleNumber: (val) => set({ newTitleNumber: val }),
	editTitleInDate: (val) => set({ titleInDate: val }),
	editTitleState: (val) => set({ titleState: val }),
	editNewTitleState: (val) => set({ newTitleState: val }),

	editSmogCheckDate: (val) => set({ smogCheckDate: val }),
	editSoldDate: (val) => set({ soldDate: val }),
	editShippedDate: (val) => set({ shippedDate: val }),
	editAuctionDate: (val) => set({ auctionDate: val }),
	editDCVersion: (val) => set({ dCVersion: val }),
	editCreatedOn: (val) => set({ createdOn: val }),
	editCreatedByID: (val) => set({ createdByID: val }),
	editModifiedOn: (val) => set({ modifiedOn: val }),
	editModifiedByID: (val) => set({ modifiedByID: val }),
	editOpenedBy: (val) => set({ openedBy: val }),
	editIsSmogCheck: (val) => set({ isSmogCheck: val }),
	editKbbVehicleId: (val) => set({ kbbVehicleId: val }),

	editFlooringComp: (val) => set({ flooringComp: val }),
	editIsFloored: (val) => set({ isFloored: val }),
	editFlooringInt: (val) => set({ flooringInt: val }),
	editUseFlooring: (val) => set({ useFlooring: val }),
	editFlooringAmt: (val) => set({ flooringAmt: val }),
	editFlooringValue: (val) => set({ flooringValue: val }),
	editFlooringAdminFee: (val) => set({ flooringAdminFee: val }),
	editFlooringCheckNo: (val) => set({ flooringCheckNo: val }),
	editTotalFlooring: (val) => set({ flooring: val }),
	editFlooringPaidOn: (val) => set({ flooringPaidOn: val }),
	editDateFloored: (val) => set({ dateFloored: val }),
	editFlooringStopDate: (val) => set({ flooringStopDate: val }),

	editHasGPS: (val) => set({ hasGPS: val }),
	editGps_ID: (val) => set({ gps_ID: val }),
	editGpsCompany: (val) => set({ gpsCompany: val }),
	editGpsWebsite: (val) => set({ gpsWebsite: val }),
	editGps_ID2: (val) => set({ gps_ID2: val }),
	editGpsCompany2: (val) => set({ gpsCompany2: val }),
	editGpsWebsite2: (val) => set({ gpsWebsite2: val }),
	editHasGPS2: (val) => set({ hasGPS2: val }),
	editVideoURL: (val) => set({ videoURL: val }),
	editIsAdvertised: (val) => set({ isAdvertised: val }),

	editTradeLenderRecNum: (draftTradeLenderRecNum) =>
		set({ tradeLenderRecNum: draftTradeLenderRecNum }),

	//'editTradeLenderRecNum: (val) => set({ tradeLenderRecNum: val }),
	//editTradeLenderList: (val) => set({ tradeLenderList: val }),
	editTradeLenderList: (draftTradeLenderList) =>
		set({ tradeLenderList: draftTradeLenderList }),

	setInventoryFields: (vehicle) => {
		set({
			...vehicle,
			optionalEquip: convertToReactSelect(vehicle.optionalEquip),
			downPayment: vehicle.downPmt,
			licenseExpires: vehicle.expireDate,
			notes: getNotesArray(vehicle.notes),
			style: vehicle.subModel,
			year: vehicle.year.substring(0, 4),
		});
	},

	reset: () =>
		set({
			inventoryID: null,
			stockNo: "",
			vin: "",
			year: "",
			make: "",
			model: "",
			trim: "",
			odometerIn: "",
			color: "",
			intColor: "",
			condition: "",
			transmission: "",
			doors: "",
			engine: "",
			mPGHwy: "",
			mPGCity: "",
			weight: "",
			Tag: "",
			description: "",
			titleNo: "",
			customEquip: "",
			selectedEquip: "",
			optionalEquip: "",
			type: "",
			titleDateOfIssue: "",
			standardEquip: "",
			specificationEquip: "",
			isLoading: true,
			thumbnail: "",
			askingPrice: 0,
			onlinePrice: 0,
			dateIn: dayjs().format("YYYY-MM-DD"),
			cost: 0,
			acv: 0,
			allowance: 0,
			payoff: 0,
			pack: 0,
			holdBack: 0,
			incentive: 0,
			totalRecon: 0,
			totalOtherCost: 0,
			totalCost: 0,
			downPayment: 0,
			msrp: 0,
			weightFee: 0,
			isDraft: false,
			datePaid: "",
			paymentMethod: "",
			titleBrand: "Clean",
			checkNo: 0,
			costHist: [],
			costDescList: [],
			departmentsList: [],
			allVendorList: [],
			vendorsList: [],
			reconVendorsList: [],
			flooringVendorsList: [],
			buyersList: [],
			locationsList: [],
			vendorName: "",
			vendorRecNum: null,
			newUsed: "Used",
			license: "",
			licenseExpires: "",
			location: "",
			buyer: "",
			notes: [],
			reportOfSale: "",
			style: "",
			styleList: [],
			colorList: [],
			intColorList: [],
			status: "Available",
			numCylinders: "",
			odometerOut: "",
			customEquipList: [],
			makeList: [],
			modelList: [],
			driveTrain: "",
			fuelType: "",
			axelCount: 0,
			wholesalePrice: 0,
			actualMileageFlag: "",
			newTitleReceivedDate: "",
			newTitleNumber: "",
			titleInDate: "",
			titleState: "",
			newTitleState: "",
			shippedDate: dayjs().format("YYYY-MM-DD"),
			auctionDate: dayjs().format("YYYY-MM-DD"),
			smogCheckDate: dayjs().format("YYYY-MM-DD"),
			soldDate: dayjs().format("YYYY-MM-DD"),
			dCVersion: "",
			createdOn: dayjs().format("YYYY-MM-DD"),
			createdByID: "",
			modifiedOn: dayjs().format("YYYY-MM-DD"),
			modifiedByID: "",
			openedBy: "",
			isSmogCheck: 0,
			kbbVehicleId: null,

			flooringComp: "",
			isFloored: false,
			flooringInt: 0,
			useFlooring: 0,
			flooringAmt: 0,
			flooringValue: 0,
			flooringAdminFee: 0,
			flooringCheckNo: "",
			flooring: 0,
			flooringPaidOn: dayjs().format("YYYY-MM-DD"),
			dateFloored: dayjs().format("YYYY-MM-DD"),
			flooringStopDate: dayjs().format("YYYY-MM-DD"),
			tradeLenderRecNum: null,
			tradeLenderList: [],
			hasGPS: false,
			gps_ID: "",
			gpsCompany: "",
			gpsWebsite: "",
			gps_ID2: "",
			gpsCompany2: "",
			gpsWebsite2: "",
			hasGPS2: false,
			videoURL: "",
			isAdvertised: true,
		}),
}));

export default useCurrentVehicleStore;
