import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup, Label } from "reactstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider, Controller } from "react-hook-form";

import useCurrentDealStore from "../../../../../../store/DealStore";
//import { deleteMiscSellerFee } from "../../../../../../api/DealAPI";
import { useFromUser } from "../../../../../../store/LocalStorageHelper";
import {
	saveSuccessAlert,
	// saveFailAlert,
	// deleteSuccessAlert,
	// deleteFailAlert,
} from "component/dc/modal/SweetAlerts";

import InputElement, {
	CurrencyInputElement,
	SelectorElement,
} from "../../../../../../view/formValidation/InputElement";
import InputWrapper from "../../../../../../view/formValidation/InputWrapper";
import { DealStatus, isTaxableOption } from "../../../../../../Constants";
import shallow from "zustand/shallow";
//import { showApiError } from "../../../../../../helperFunction/ApiErrorHandler";
import CreatableSelect from "react-select/creatable";

export const SellerFeesEditForm = ({
	currentIndex,
	setCurrentIndex,
	toggle,
	optionList,
}) => {
	// Deal store
	const {
		miscSellerFees,
		editMiscSellerFees,
		dealRecNum,
		type,
		companies,
		calculate,
	} = useCurrentDealStore(
		(state) => ({
			miscSellerFees: state.miscSellerFees,
			editMiscSellerFees: state.editMiscSellerFees,
			dealRecNum: state.deal.ID,
			type: state.deal.type,
			companies: state.sellerFeeCompanies,
			calculate: state.calculate,
		}),
		shallow
	);

	console.log(companies);
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const [description, setDescription] = useState("");
	const [amount, setAmount] = useState(0);
	const [company, setCompany] = useState("");

	const [isTaxable, setIsTaxable] = useState("");
	const [cost, setCost] = useState(0);
	const [sellerFeeType, setSellerFeeType] = useState("");
	const [buttonText, setButtonText] = useState("Add");
	const changeText = (text) => setButtonText(text);

	const clearFields = () => {
		changeText("Add");
		setDescription("");
		setAmount(0);
		setCompany("");
		setIsTaxable(0);
		setCost(0);
		setCurrentIndex(miscSellerFees.length);
		setSellerFeeType("OTHER");
		setIsTaxable(false);
	};

	const addMiscFee = () => {
		const getID = () => {
			if (miscSellerFees[currentIndex] === undefined) {
				return null;
			} else if (miscSellerFees[currentIndex].ID === undefined) {
				return null;
			} else {
				return miscSellerFees[currentIndex].ID;
			}
		};
		const ID = getID();
		const newMiscFee = {
			type: sellerFeeType || "OTHER",
			description,
			amount: parseFloat(amount),
			company,
			cost: parseFloat(cost),
			dealerID,
			locationID,
			ID: ID,
			dealRecNum,
			currentIndex: currentIndex,
			isTaxable: isTaxable || false,
		};
		console.log(newMiscFee);
		console.log("Current index is: " + currentIndex);

		const miscList = () => {
			let obj = miscSellerFees;
			obj[currentIndex] = newMiscFee;
			return obj;
		};
		const newMiscFeeObj = miscList();
		editMiscSellerFees(newMiscFeeObj);
		clearFields();
		saveSuccessAlert();
		calculate();
		toggle();
	};

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
			length: "Required format is YYYY/MM/DD",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({
		sellerFeeDescription: yup.string().required().max(50),
		sellerFeeAmount: yup.string().required().max(50),
		sellerFeeCompany: yup.string().required().max(50),
		sellerFeeCost: yup.string().required().max(50),
		SellerFeeType: yup.string().required().max(50),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});
	useEffect(() => {
		setSellerFeeType(miscSellerFees[currentIndex]?.type || "");
		setAmount(miscSellerFees[currentIndex]?.amount || "");
		setCompany(miscSellerFees[currentIndex]?.company || "");
		setCost(miscSellerFees[currentIndex]?.cost || "");
		setDescription(miscSellerFees[currentIndex]?.description || "");
		setIsTaxable(miscSellerFees[currentIndex]?.isTaxable);
		changeText("Save");
		//setTotalComm(totalCommission);

		return clearFields;
		// eslint-disable-next-line
	}, [currentIndex]);

	const taxableHandler = (value) => {
		console.log(typeof value);
		if (value === "true") {
			setIsTaxable(true);
		} else if (value === "false") {
			setIsTaxable(false);
		}
		// this should never happen
		else {
			setIsTaxable(false);
		}
	};

	console.log(JSON.stringify(isTaxable));
	return (
		<>
			<FormProvider className="" {...methods}>
				<Form>
					<InputWrapper
						formTitle="Seller Fee"
						inputComponents={[
							<SelectorElement
								{...methods}
								name="SellerFeeType"
								label="SellerFee Type"
								value={sellerFeeType}
								onChange={(e) => setSellerFeeType(e.target.value)}
								options={optionList}
								labelClass=" required"
								colSize="col-sm-6"
							/>,
							<Controller
								as={
									<FormGroup className="col-md-9">
										<div>
											<Label>Company</Label>
											<CreatableSelect
												value={{
													value: company,
													label: company,
												}}
												options={companies.map((value) => ({
													value: value,
													label: value,
												}))}
												onChange={(e) => {
													console.log(e);
													setCompany(e.value);
												}}
											/>
										</div>
									</FormGroup>
								}
								name="SellerFeeCompanies"
								defaultValue={
									company === null || company === "" ? "0" : company
								}
							/>,

							<SelectorElement
								readOnly={type !== DealStatus.PENDING}
								{...methods}
								name="SellerFeeTaxable"
								label="Taxable"
								value={isTaxable}
								onChange={(e) => taxableHandler(e.target.value)}
								options={isTaxableOption}
							/>,
							<InputElement
								readOnly={type !== DealStatus.PENDING}
								{...methods}
								value={description}
								onChange={(e) => {
									setDescription(e.target.value);
								}}
								name="sellerFeeDescription"
								label="Description"
								type="text"
								colSize="col-sm-12"
							/>,
							<CurrencyInputElement
								readOnly={type !== DealStatus.PENDING}
								value={cost}
								onChange={setCost}
								name="sellerFeeCost"
								label="Cost"
								type="number"
							/>,
							<CurrencyInputElement
								readOnly={type !== DealStatus.PENDING}
								value={amount}
								onChange={setAmount}
								name="sellerFeeAmount"
								label="Amount"
								type="number"
							/>,
						]}
						buttons1={
							<div className="text-center">
								<Button
									readOnly={type !== DealStatus.PENDING}
									color="primary"
									onClick={methods.handleSubmit(addMiscFee)}
								>
									<i className="nc-icon nc-simple-add"></i> Add
								</Button>
							</div>
						}
						buttons={
							<div className="d-flex justify-content-center mt-2">
								<Button color="primary" onClick={addMiscFee}>
									{buttonText === "Save" ? (
										<i class="nc-icon nc-check-2"></i>
									) : (
										<i class="nc-icon nc-simple-add"></i>
									)}
									{buttonText}
								</Button>
								<div className="btn btn-link btn-md" onClick={clearFields}>
									Reset
								</div>
							</div>
						}
					/>
				</Form>
			</FormProvider>
		</>
	);
};

export default SellerFeesEditForm;
