import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

class Footer extends React.Component {
	render() {
		return (
			<footer
				className={"footer" + (this.props.default ? " footer-default" : "")}
			>
				<Container>
					<Row>
						<nav className="footer-nav">
							<ul>
								<span className="copyright"></span>
								<li>
									<a href="https://dealerclick.com">DealerClick</a>
								</li>
								<li>
									<p>
										<a href="https://dealerclick.com/blog">Blog</a>
									</p>
								</li>
								<li>
									<a
										href="https://dealerclick.com/license"
										target="_blank"
										rel="noopener noreferrer"
									>
										Licenses
									</a>
								</li>
							</ul>
						</nav>
						<div className="credits ml-auto">
							<span className="copyright">
								&copy; {1900 + new Date().getYear()}, made with{" "}
								<i className="fa fa-heart heart" /> by DealerClick
							</span>
						</div>
					</Row>
				</Container>
			</footer>
		);
	}
}

Footer.propTypes = {
	default: PropTypes.bool,
	fluid: PropTypes.bool,
};

export default Footer;
