import React, { useState, createContext } from "react";
import dayjs from "dayjs";

export const TradeContext = createContext();

const TradeProvider = ({ children }) => {
	const today = dayjs().format("YYYY-MM-DD");
	const [inventoryID, editInventoryID] = useState(null);
	const [stockNo, editStockNo] = useState("");
	const [vin, editVin] = useState("");
	const [year, editYear] = useState("");
	const [make, editMake] = useState("");
	const [model, editModel] = useState("");
	const [trim, editTrim] = useState("");
	const [odometerIn, editOdometerIn] = useState("");
	const [color, editColor] = useState("");
	const [intColor, editIntColor] = useState("");
	const [condition, editCondition] = useState("");
	const [transmission, editTransmission] = useState("");
	const [doors, editDoors] = useState("");
	const [engine, editEngine] = useState("");
	const [mPGHwy, editMpgHwy] = useState("");
	const [mPGCity, editMpgCity] = useState("");
	const [weight, editWeight] = useState("");
	const [description, editDescription] = useState("");
	const [titleNo, editTitleNo] = useState("");
	// const [selectedEquip, editSelectedEquip] = useState("");
	// const [optionalEquip, editOptionalEquip] = useState("");
	const [type, editType] = useState("");
	// const [titleDateOfIssue, editTitleDateOfIssue] = useState("");
	// const [standardEquip, editStandardEquip] = useState("");
	const [specificationEquip, editSpecificationEquip] = useState("");
	// const [isVinLoading, editIsVinLoading] = useState(false);
	// const [thumbnail, editThumbnail] = useState("");
	const [askingPrice, editAskingPrice] = useState(0);
	const [dateIn, editDateIn] = useState("");
	const [cost, editCost] = useState(0);
	// const [pack, editPack] = useState(0);
	// const [holdBack, editHoldBack] = useState(0);
	// const [incentive, editIncentive] = useState(0);
	// const [totalRecon, editTotalRecon] = useState(0);
	// const [flooring, editFlooring] = useState(0);
	// const [totalOtherCost, editTotalOtherCost] = useState(0);
	// const [totalCost, editTotalCost] = useState(0);
	// const [costHist, editCostHist] = useState([]);
	// const [costDescList, editCostDescList] = useState([]);
	// const [departmentsList, editDepartmentsList] = useState([]);
	// const [vendorsList, editVendorsList] = useState([]);
	const [actualMileageFlag, editActualMileageFlag] = useState(0);
	const [colorList, editColorList] = useState([]);
	const [intColorList, editIntColorList] = useState([]);

	// DcTableTrade values
	const [tradeLenderRecNum, editTradeLenderRecNum] = useState(null);
	const [tradeID, editTradeID] = useState(null);
	const [acv, editAcv] = useState(0);
	const [allowance, editAllowance] = useState(0);
	const [payoff, editPayoff] = useState(0);

	// context "global" stuff so it doesnt need to be passed
	const [modalActive, setModalActive] = useState(false);
	const toggleTradeModal = () => setModalActive(!modalActive);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [isLoading, editIsLoading] = useState(false);

	const setVehicleFields = (trade) => {
		editInventoryID(trade?.car.inventoryID || null);
		editStockNo(trade?.car.stockNo || "");
		editVin(trade?.car.vin || "");
		editYear(trade?.car.year || "");
		editMake(trade?.car.make || "");
		editModel(trade?.car.model || "");
		editTrim(trade?.car.trim || "");
		editColor(trade?.car.color || "");
		editIntColor(trade?.car.intColor || "");
		editCondition(trade?.car.condition || "");
		editCost(trade?.car.cost || 0);
		editTradeID(trade?.car.tradeID || null);
		editTransmission(trade?.car.transmission || "");
		editDoors(trade?.car.doors || "");
		editOdometerIn(trade?.car.odometerIn || 0);
		editEngine(trade?.car.engine || "");
		editMpgHwy(trade?.car.mPGHwy || "");
		editMpgCity(trade?.car.mPGCity || "");
		editWeight(trade?.car.weight || "");
		editDescription(trade?.car.description || "");
		editTitleNo(trade?.car.titleNo || "");
		editType(trade?.car.type || "");
		editAskingPrice(trade?.car.askingPrice || 0);
		editDateIn(trade?.car.dateIn || today);
		editAcv(trade?.acv || 0);
		editAllowance(trade?.allowance || 0);
		editPayoff(trade?.payoff || 0);
		editActualMileageFlag(trade?.car.actualMileageFlag || 0);
		editTradeLenderRecNum(trade?.tradeLenderRecNum || null);
		editSpecificationEquip(trade?.specificationEquip || "");
		editIsLoading(false);
	};

	const reset = () => {
		editInventoryID(null);
		editStockNo("");
		editVin("");
		editYear("");
		editMake("");
		editModel("");
		editTrim("");
		editColor("");
		editIntColor("");
		editCondition("");
		editCost(0);
		editTradeID(null);
		editTransmission("");
		editDoors("");
		editOdometerIn(0);
		editEngine("");
		editMpgHwy("");
		editMpgCity("");
		editWeight("");
		editDescription("");
		editTitleNo("");
		editType("");
		editAskingPrice(0);
		editDateIn(today);
		editAcv(0);
		editAllowance(0);
		editPayoff(0);
		editActualMileageFlag(0);
		editTradeLenderRecNum(null);
		editSpecificationEquip("");
		editIsLoading(false);
	};

	const context = {
		tradeID,
		inventoryID,
		stockNo,
		vin,
		year,
		make,
		model,
		trim,
		odometerIn,
		color,
		intColor,
		condition,
		transmission,
		payoff,
		doors,
		engine,
		cost,
		mPGHwy,
		mPGCity,
		acv,
		allowance,
		weight,
		description,
		titleNo,
		type,
		askingPrice,
		dateIn,
		isLoading,
		actualMileageFlag,
		colorList,
		intColorList,
		tradeLenderRecNum,
		specificationEquip,

		currentIndex,
		setCurrentIndex,

		editTradeID,
		editInventoryID,
		editStockNo,
		editVin,
		editYear,
		editMake,
		editAcv,
		editAllowance,
		editPayoff,
		editModel,
		editTrim,
		editOdometerIn,
		editColor,
		editIntColor,
		editCondition,
		editCost,
		editTransmission,
		editDoors,
		editEngine,
		editMpgHwy,
		editMpgCity,
		editWeight,
		editDescription,
		editTitleNo,
		editType,
		editAskingPrice,
		editDateIn,
		editIsLoading,
		editActualMileageFlag,
		editColorList,
		editIntColorList,
		editTradeLenderRecNum,
		editSpecificationEquip,

		modalActive,
		setModalActive,
		toggleTradeModal,
		setVehicleFields,
		reset,
	};

	return (
		<TradeContext.Provider value={context}>{children}</TradeContext.Provider>
	);
};

export default TradeProvider;
