import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";

import ReactTable from "./ReactTable";
import SelectColumnFilter from "../../../component/dc/table/SelectColumnFilter";

const InventoryTable = ({ data }) => {
	console.log(data);
	const vehicleCell = (props) => (
		<div>
			<span style={{ fontWeight: "bold" }}>
				<div>
					{props.row.original.vin !== null && (
						<div> {props.row.original.vehicle}</div>
					)}
				</div>
				<div>
					{props.row.original.vin !== null && (
						<div>VIN: {props.row.original.vin}</div>
					)}
				</div>
				<div>
					{props.row.original.license !== null &&
						props.row.original.license !== "" && (
							<div>License Plate: {props.row.original.license}</div>
						)}
				</div>
			</span>
		</div>
	);
	const statusCell = (props) => (
		<div>
			<span style={{ fontWeight: "bold" }}>
				<div>
					{props.row.original.status != null && (
						<div>{props.row.original.status}</div>
					)}
				</div>
			</span>
		</div>
	);
	const priceCell = (props) => (
		<div>
			<span style={{ fontWeight: "bold" }}>
				<div>
					{props.row.original.askingPrice != null && (
						<div>{props.row.original.askingPrice}</div>
					)}
				</div>
			</span>
		</div>
	);

	const stockCell = (props) => (
		<div>
			<span style={{ fontWeight: "bold" }}>
				<div>
					{props.row.original.stockNo != null && (
						<div>
							Stock#:
							{props.row.original.stockNo}
						</div>
					)}
				</div>
				{/* <div>
				{props.row.original.vin != null && (
					<div>VIN: {props.row.original.vin}</div>
				)}
			</div> */}
				<div>
					{props.row.original.vin != null &&
						props.row.original.status !== "Deleted" && (
							<div>Age: {props.row.original.age} Days</div>
						)}
				</div>
				<div>
					{props.row.original.vin != null &&
						props.row.original.status !== "Deleted" && (
							<div>DateIn: {props.row.original.start} </div>
						)}
				</div>

				<div>
					{props.row.original.vin !== null &&
						props.row.original.end !== "" &&
						props.row.original.status !== "Deleted" && (
							<div>DateOut: {props.row.original.end} </div>
						)}
				</div>
			</span>
		</div>
	);

	return (
		<div>
			<Row>
				<Col md="12">
					<Card>
						<CardBody>
							<ReactTable
								data={data}
								columns={[
									{
										Header: "Photo",
										accessor: "thumbnail",
										className: "vehicle-photo",
										disableFilters: true,
									},
									{
										Header: "Stock",
										accessor: "stockAndVin",
										Cell: stockCell,
										sortType: (rowA, rowB, columnId) => {
											// Custom sorting by the 'dateIn' field
											console.log(
												"rowA:",
												rowA.original[columnId],
												"rowB:",
												rowB.original[columnId]
											);
											return rowA.original[columnId] > rowB.original[columnId]
												? 1
												: -1;
										},
									},
									{
										Header: "Vehicle",
										accessor: "vehicleSearch",
										Cell: vehicleCell,
										//style: { whiteSpace: "unset" },
									},

									{
										Header: "Price",
										accessor: "askingPrice",
										Cell: priceCell,
									},
									{
										Header: "Status",
										accessor: "status",
										Filter: SelectColumnFilter,
										filter: "includes",
										Cell: statusCell,
									},
									{
										Header: "Actions",
										accessor: "Actions",
										disableFilters: true,
									},
								]}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default InventoryTable;
