import React, { useContext, useEffect } from "react";
import {
	CUSTOMER_MSG_TEMPLATE_VAR,
	MESSAGE_TEMPLATE_VARIABLES,
} from "../Constants";
import { TemplateVariableContext } from "./TemplateVariableContext";

const TemplateVariableInit = ({ isDeal = false, children }) => {
	const { setTemplateVars } = useContext(TemplateVariableContext);
	useEffect(() => {
		if (isDeal) {
			setTemplateVars(MESSAGE_TEMPLATE_VARIABLES);
		} else {
			setTemplateVars(CUSTOMER_MSG_TEMPLATE_VAR);
		}
		// eslint-disable-next-line
	}, []);

	return <>{children}</>;
};

export default TemplateVariableInit;
