import React, { useState } from "react";
import { Progress } from "reactstrap";

import useCurrentPhotoStore from "store/PhotoStore";
import useCurrentVehicleStore from "store/InventoryStore";

import { uploadImages } from "api/InventoryAPI";
import { useFromUser } from "store/LocalStorageHelper";
import { failAlert } from "component/dc/modal/SweetAlerts";

import { createPhotosObj, getThumbnailURL } from "./helperFunctions";
import MultiImageUploader from "component/dc/image/multi/MultiImageUploader";
import { useMultiImageData } from "component/dc/image/multi/MultiImageContext";

/**
 * Component that has the feature to let users open their file explorer and choose images to upload.
 * On save button click, makes API call to FTP server to store images
 */
const UploadPhotos = ({ newVehicle }) => {
	const MAX_UPLOADS = 100;

	// User store
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	// Progress state for handling upload progress
	const [progress, setProgress] = useState(0);

	// Vehicle store
	const { vin, editThumbnail } = useCurrentVehicleStore();

	// Photo store
	const {
		gallery,
		editUploads,
		editGallery,
		editReorders,
	} = useCurrentPhotoStore();

	// Multi image context
	const { croppedUploads } = useMultiImageData();

	// Uploads the selected images to the FTP server
	const upload = () => {
		const imageFilesToArray = Object.entries(croppedUploads);

		if (isOverMaxUploads(imageFilesToArray.length)) {
			return;
		}

		const data = new FormData();

		for (const [key, val] of imageFilesToArray) {
			data.append(key, val);
		}

		uploadImages(dealerID, locationID, vin, data, setProgress).then(
			(res) => {
				let imgArr = res.data.content;
				let photoObj = createPhotosObj(imgArr);

				editUploads({});
				editThumbnail(getThumbnailURL(imgArr));
				editGallery(photoObj);
				editReorders(photoObj);
				setProgress(0);
			},
			(error) => {
				failAlert("Failed to save, please try again later.");
				setProgress(0);
				console.log(error);
			}
		);
	};

	// Verifies that user is not over max number of allowed uploads. Else continues
	// to upload photos
	const isOverMaxUploads = (uploadSize) => {
		if (gallery.length + uploadSize > MAX_UPLOADS) {
			failAlert(
				`A maximum of ${MAX_UPLOADS} images are allowed. You cannot upload any more images.`
			);

			return true;
		}

		return false;
	};
	return (
		<div>
			<div className="mt-5">
				<div className="d-flex justify-content-center align-items-center">
					{progress !== 0 && (
						<div className="text-center mb-5 w-75">
							<Progress striped max="100" value={progress}>
								<span className="progress-value">{progress}%</span>
							</Progress>
						</div>
					)}
				</div>
				<MultiImageUploader
					totalFiles={gallery.length}
					maxFiles={MAX_UPLOADS}
					handleUpload={upload}
					hideUploadButton={newVehicle}
				/>
			</div>
		</div>
	);
};

export default UploadPhotos;
