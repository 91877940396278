/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)

* Coded by DealerClick Software **Peyman))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useRef } from "react";
import { Row } from "reactstrap";
import dayjs from "dayjs";

import useRoute from "../../../helperFunction/UseRoute";
import history from "../../../helperFunction/History";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";
import { retrieveFailAlert } from "../../../component/dc/modal/SweetAlerts";
import { useFromUser } from "../../../store/LocalStorageHelper";
import { PATHS, FTP_IMAGE_URL, OnlineLeadStatus } from "../../../Constants";
import Loading from "../../../component/dc/load/Loading";
import Camera from "../../../assets/img/imgPlaceholder.png";
import LeadTable from "../../../component/dc/table/LeadTable";
import { getLeadDetails, getLeadList } from "../../../api/LeadApi";
import ChildrenModal from "../../../component/dc/modal/ChildrenModal";
import DealTemplateModal from "../../settings/settingsTabs/dealTemplates/DealTemplateModal";
import {
	getVehicleDetails,
	getVehicleDetailsByStockNo,
} from "../../../api/InventoryAPI";
import { saveAsCustomer } from "../../../api/LeadApi";
import TwilioSendMessage from "../../../component/dc/integration/twilio/TwilioSendMessage";
import OnlineLeadDetailsPage from "./OnlineLeadDetailsPage";

const OnlineLeadListPage = ({ setMessageCustomers, getAll }) => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const unmounted = useRef(false);
	const homeRoute = useRoute(PATHS.DASHBOARD);

	//toggle template modal
	const [templateModal, setTemplateModal] = useState(false);
	const toggleTemplate = () => setTemplateModal(!templateModal);
	const [vehicle, setVehicle] = useState({});

	// Local state
	const [data, setData] = useState([]);
	const [loadingList, setLoadingList] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [tmpLead, setTmpLead] = useState({});
	const [customer, setCustomer] = useState({});
	const [viewTextModal, setViewTextModal] = useState(false);
	const [textHistoryNumber, setTextHistoryNumber] = useState();
	const [customerID, setCustomerID] = useState(null);

	// Text message modal
	const toggleViewTextModal = () => setViewTextModal(!viewTextModal);

	const toggle = () => {
		setShowModal(!showModal);
	};

	const handleView = (id) => {
		getLeadDetails(id).then(
			(res) => {
				setTmpLead(res.data.content);
				toggle();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	const dataFormat = (content) => {
		console.log(content);

		const container = Object.keys(content).map((key) => {
			let container = {};
			console.log(content);
			//			content[key].thumbnail =				"https://www.dealernetwork.com/images/inventory/3534/4757/5N1AR2MN4EC617625-0.jpg";
			content[key].thumbnail =
				FTP_IMAGE_URL +
				"/" +
				dealerID +
				"/" +
				locationID +
				"/" +
				content[key].vin +
				"-0.jpg";

			container.thumbnail = (
				<div>
					<img
						className="zoom"
						alt="vehicle"
						src={
							content[key].thumbnail
								? `${content[key].thumbnail}?lastmod=${Date.now()}`
								: Camera
						}
						onError={(e) => {
							e.target.onerror = null;
							e.target.src = Camera;
						}}
						width="250"
						height="150"
					/>
				</div>
			);
			container.firstName = content[key].firstName;
			container.lastName = content[key].lastName;
			container.workPhone = content[key].workPhone;
			container.phone = content[key].phone;
			container.cellPhone = content[key].cellPhone;
			container.Website = content[key].Website;
			container.email = content[key].email;
			console.log(content[key].leadStatus);
			container.status = OnlineLeadStatus[content[key].leadStatus].label;
			container.origin = content[key].origin;
			container.leadDetails =
				container.firstName +
				" " +
				container.lastName +
				" " +
				container.email +
				" " +
				container.cellPhone +
				" " +
				container.phone +
				" " +
				container.origin +
				" " +
				container.status;

			container.stockNumber = content[key].stockNumber;
			container.vin = content[key].vin;
			container.vehicleDetails = container.vin + " " + container.stockNumber;

			container.unit = content[key].unit;
			container.address = content[key].address;
			container.city = content[key].city;
			container.state = content[key].state;
			container.zipCode = content[key].zipCode;
			container.country = content[key].country;
			container.dateGenerated = dayjs(content[key].dateGenerated).format(
				"MM/DD/YYYY HH:mm:ss"
			);
			container.dateRead = content[key].dateRead;
			container.leadStatus = content[key].leadStatus;
			container.leadOrigin = content[key].leadOrigin = null;

			container.message = content[key].message;
			container.dealerNotes = content[key].dealerNotes;
			container.priceRange = content[key].priceRange;
			container.year = content[key].year;
			container.make = content[key].make;
			container.model = content[key].model;
			container.trim = content[key].trim;
			container.color = content[key].color;
			container.lookingFor =
				container.make + " " + container.model + " " + container.year;
			container.inventoryID = content[key].inventoryID;
			container.customerID = content[key].customerID;
			container.salesPersonID = content[key].salesPersonID;
			container.salesPerson2ID = content[key].salesPerson2ID;
			container.source = content[key].source;
			container.lastSync = content[key].lastSync;
			container.insertedOn = content[key].insertedOn;
			container.UpdatedOn = content[key].UpdatedOn;
			container.Actions = (
				<Row>
					<div
						className="zoom btn-md btn btn-link btn-link-success"
						onClick={() => {
							console.log(content[key]);
							setTextHistoryNumber(
								content[key].cellPhone || content[key].phone
							);
							setCustomerID(content[key].customerID);
							toggleViewTextModal();
						}}
					>
						<i className="icon-big text-center nc-icon nc-chat-33 text-default" />
						<span className="noMobile"> Chat </span>
					</div>
					<div
						className="zoom btn-md btn btn-primary"
						onClick={() => {
							handleView(content[key].leadID);
						}}
					>
						<i className="icon-big text-center nc-icon nc-zoom-split text-default"></i>{" "}
						<span className="noMobile"> View </span>
					</div>
					<div
						className="zoom btn-md btn btn-link btn-link-success"
						onClick={() => {
							setTmpLead(content[key]);
							handleDeal(content[key]);
							console.log(content[key]);
						}}
					>
						<i className="icon-big text-center nc-icon nc-briefcase-24"></i>{" "}
						<span className="noMobile"> Deal</span>{" "}
					</div>
				</Row>
			);

			return container;
		});

		return container;
	};

	const handleDeal = async (lead) => {
		console.log(lead);
		saveAsCustomer(lead.leadID).then(
			(res) => {
				console.log(res);
				setCustomer(res.data.content.Customer);
				toggleTemplate();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);

		console.log(lead.inventoryID);
		if (lead.inventoryID > 0) {
			// Adds vehicle information to the deal store
			getVehicleDetails(lead.inventoryID).then(
				(res) => {
					console.log(res);
					const v = res.data.content;
					setVehicle(v);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		} else {
			if (lead.stockNumber !== "" && lead.stockNumber !== null) {
				getVehicleDetailsByStockNo(lead.toLocationID, lead.stockNumber).then(
					(res) => {
						console.log(res);
						const v = res.data.content;
						setVehicle(v);
					},
					(err) => {
						if (!err.isGeneralError) {
							showApiError(err, retrieveFailAlert);
						}
					}
				);
			}
		}
	};

	// Gets a list of all the Leads for this dealer and location
	const getListData = () => {
		setLoadingList(true);
		getAll();

		console.log("ID: " + locationID);
		if (locationID !== undefined) {
			getLeadList(locationID).then(
				(response) => {
					console.log(response.data.content);
					const result = dataFormat(response.data.content.leads);
					setData(result);
					setLoadingList(false);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
						history.push(homeRoute);
					}
				}
			);
		}
	};
	const LeadAddButton = () => {
		return (
			!showModal && (
				<>
					<div
						className="btn btn-md btn-success float-button"
						onClick={() => {
							setTmpLead({});
							toggle();
						}}
					>
						<i className="nc-icon nc-check-2" /> Add Lead
					</div>
				</>
			)
		);
	};

	useEffect(() => {
		// This is to make the component work on customer list page
		if (!unmounted.current) {
			getListData();
		}

		return () => {
			unmounted.current = true;
		};

		// eslint-disable-next-line
	}, []);

	return (
		<div>
			{loadingList && <Loading />}
			{!loadingList && (
				<LeadTable data={data} setMessageCustomers={setMessageCustomers} />
			)}

			<LeadAddButton />
			<ChildrenModal
				modalTitle="Lead"
				modal={showModal}
				toggle={toggle}
				onClosed={getListData}
			>
				<OnlineLeadDetailsPage lead={tmpLead} toggle={toggle} />
			</ChildrenModal>
			<ChildrenModal
				modalTitle="Deal Templates"
				modal={templateModal}
				toggle={toggleTemplate}
			>
				<DealTemplateModal customer={customer} newVehicle={vehicle} />
			</ChildrenModal>
			<ChildrenModal
				size="lg"
				modal={viewTextModal}
				toggle={toggleViewTextModal}
				modalTitle="Send Message"
			>
				<TwilioSendMessage
					sendToNumber={textHistoryNumber}
					customerID={customerID}
					personType="Lead"
				/>
			</ChildrenModal>
		</div>
	);
};

export default OnlineLeadListPage;
