import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup, Label } from "reactstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider, Controller } from "react-hook-form";
import useCurrentDealStore from "../../../../../../store/DealStore";
import { useFromUser } from "../../../../../../store/LocalStorageHelper";
import { saveSuccessAlert } from "component/dc/modal/SweetAlerts";
import InputElement, {
	CurrencyInputElement,
	SelectorElement,
} from "../../../../../../view/formValidation/InputElement";
import InputWrapper from "../../../../../../view/formValidation/InputWrapper";
import { DealStatus, isTaxableOption } from "../../../../../../Constants";
import shallow from "zustand/shallow";
import CreatableSelect from "react-select/creatable";

export const DMVFeeForms = ({
	currentIndex,
	setCurrentIndex,
	toggle,
	optionList,
}) => {
	// Deal store
	const {
		miscDMVFees,
		editMiscDMVFees,
		dealRecNum,
		type,
		companies,
		calculate,
	} = useCurrentDealStore(
		(state) => ({
			miscDMVFees: state.miscDMVFees,
			editMiscDMVFees: state.editMiscDMVFees,
			dealRecNum: state.deal.ID,
			type: state.deal.type,
			companies: state.dmvFeeCompanies,
			calculate: state.calculate,
		}),
		shallow
	);

	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const [description, setDescription] = useState("");
	const [amount, setAmount] = useState(0);
	const [company, setCompany] = useState("");
	const [cost, setCost] = useState(0);
	const [dmvFeeType, setDmvFeeType] = useState("");
	const [buttonText, setButtonText] = useState("Add Fee");
	const [isTaxable, setIsTaxable] = useState("");
	const changeText = (text) => setButtonText(text);

	const clearFields = () => {
		changeText("Add Fee");
		setDescription("");
		setAmount(0);
		setCompany("");
		setIsTaxable(false);
		setCost(0);
		setCurrentIndex(miscDMVFees.length);
		setDmvFeeType("OTHER");
	};

	const addMiscFee = () => {
		const getID = () => {
			if (miscDMVFees[currentIndex] === undefined) {
				return null;
			} else if (miscDMVFees[currentIndex].ID === undefined) {
				return null;
			} else {
				return miscDMVFees[currentIndex].ID;
			}
		};
		const ID = getID();
		const newMiscFee = {
			type: dmvFeeType || "OTHER",
			description,
			amount: parseFloat(amount),
			company,
			cost: parseFloat(cost),
			currentIndex: currentIndex,
			dealerID,
			locationID,
			ID,
			dealRecNum,
			isTaxable: isTaxable || false,
		};
		console.log(newMiscFee);
		console.log("Current index is: " + currentIndex);

		const miscList = () => {
			let obj = miscDMVFees;
			obj[currentIndex] = newMiscFee;
			return obj;
		};
		const newMiscFeeObj = miscList();
		editMiscDMVFees(newMiscFeeObj);
		clearFields();
		saveSuccessAlert();
		calculate();
		toggle();
	};
	const taxableHandler = (value) => {
		console.log(value);
		if (value === "true") {
			setIsTaxable(true);
		} else if (value === "false") {
			setIsTaxable(false);
		}
		// this should never happen
		else {
			setIsTaxable(false);
		}
	};

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
			length: "Required format is YYYY/MM/DD",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({
		DMVFeeDescription: yup.string().max(50).min(1).required(),
		DMVFeeAmount: yup.string().required().max(50),
		DMVFeeCompany: yup.string().required().max(50),
		DMVFeeCost: yup.string().required().max(50),
		DMVFeeType: yup.string().required().max(50).min(1).nullable(),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	console.log(methods.getValues());
	console.log(isTaxable);

	useEffect(() => {
		if (currentIndex < miscDMVFees.length) {
			setDmvFeeType(miscDMVFees[currentIndex]?.type);
			setAmount(miscDMVFees[currentIndex]?.amount);
			setCompany(miscDMVFees[currentIndex]?.company);
			setCost(miscDMVFees[currentIndex]?.cost);
			setDescription(miscDMVFees[currentIndex]?.description);
			setIsTaxable(miscDMVFees[currentIndex]?.isTaxable);
			changeText("Save Fee");
		}
		return clearFields;
		// eslint-disable-next-line
	}, [currentIndex]);

	return (
		<>
			<FormProvider className="" {...methods}>
				<Form>
					<InputWrapper
						formTitle="Add Fee"
						inputComponents={[
							<SelectorElement
								{...methods}
								name="DMVFeeType"
								label="DMV Fee Type"
								value={dmvFeeType}
								onChange={(e) => setDmvFeeType(e.target.value)}
								options={optionList}
								labelClass="required"
								colSize="col-sm-6"
							/>,
							<Controller
								as={
									<FormGroup className="col-md-9">
										<div>
											<Label>Company</Label>
											<CreatableSelect
												value={{
													value: company,
													label: company,
												}}
												options={companies.map((value) => ({
													value: value,
													label: value,
												}))}
												onChange={(e) => {
													console.log(e);
													setCompany(e.value);
												}}
											/>
										</div>
									</FormGroup>
								}
								name="DMVCompanies"
								defaultValue={
									company === null || company === "" ? "0" : company
								}
							/>,

							<SelectorElement
								readOnly={type !== DealStatus.PENDING}
								{...methods}
								name="DMVFeeTaxable"
								label="Taxable"
								value={isTaxable}
								onChange={(e) => taxableHandler(e.target.value)}
								options={isTaxableOption}
							/>,
							<InputElement
								readOnly={type !== DealStatus.PENDING}
								{...methods}
								value={description}
								onChange={(e) => {
									setDescription(e.target.value);
								}}
								name="DMVFeeDescription"
								label="Description"
								type="text"
								colSize="col-sm-12"
							/>,
							<CurrencyInputElement
								readOnly={type !== DealStatus.PENDING}
								value={cost}
								onChange={setCost}
								name="DMVFeeCost"
								label="Cost"
								type="number"
							/>,
							<CurrencyInputElement
								readOnly={type !== DealStatus.PENDING}
								value={amount}
								onChange={setAmount}
								name="DMVFeeAmount"
								label="Amount"
								type="number"
							/>,
						]}
						buttons1={
							<div className="text-center">
								<Button
									readOnly={type !== DealStatus.PENDING}
									color="primary"
									onClick={methods.handleSubmit(addMiscFee)}
								>
									<i className="nc-icon nc-simple-add"></i> Add
								</Button>
							</div>
						}
						buttons={
							<div className="d-flex justify-content-center mt-2">
								<Button color="primary" onClick={addMiscFee}>
									{buttonText === "Save Fee" ? (
										<i class="nc-icon nc-check-2"></i>
									) : (
										<i class="nc-icon nc-simple-add"></i>
									)}
									{buttonText}
								</Button>
								<div className="btn btn-link btn-md" onClick={clearFields}>
									Reset
								</div>
							</div>
						}
					/>
				</Form>
			</FormProvider>
		</>
	);
};

export default DMVFeeForms;
