import React from "react";
import NumberFormat from "react-number-format";
import { Row, Card, CardBody, CardTitle } from "reactstrap";
import useCurrentSaleStore from "../../../../../../store/SaleStore";
import shallow from "zustand/shallow";
import useCurrentDealStore from "../../../../../../store/DealStore";
import { CurrencyInput } from "../../../../../../view/formValidation/InputElement";
import { DealStatus } from "../../../../../../Constants";

const Currency = ({ value }) => {
	return (
		<NumberFormat
			value={value}
			thousandSeparator={true}
			decimalScale={2}
			fixedDecimalScale={true}
			prefix={"$"}
			isNumericString={true}
			displayType="text"
		/>
	);
};

const SummaryTax = () => {
	const { county } = useCurrentDealStore((state) => state, shallow);
	const { type } = useCurrentDealStore(
		(state) => ({ type: state.deal.type }),
		shallow
	);

	const {
		tax,
		totalTaxable,
		taxableAmount,
		salesTax,
		vit,
		userTax1,
		userTax2,
		userTax3,
		userTax4,
		userTax5,
		userTax6,
		fixedAmountTax,
		editFixedAmountTax,
	} = useCurrentSaleStore(
		(state) => ({
			tax: state.tax,
			taxableAmount: state.taxableAmount,
			totalTaxable: state.totalTaxable,
			salesTax: state.salesTax,
			vit: state.vit,
			userTax1: state.userTax1,
			userTax2: state.userTax2,
			userTax3: state.userTax3,
			userTax4: state.userTax4,
			userTax5: state.userTax5,
			userTax6: state.userTax6,
			fixedAmountTax: state.fixedAmountTax,
			editFixedAmountTax: state.editFixedAmountTax,
		}),
		shallow
	);
	const TaxSplit = ({ userTax, taxName }) => {
		console.log(userTax, taxName);
		return (
			userTax > 0 && (
				<Row>
					<div className="col">{taxName}</div>
					<div className="justify-content-right">
						<Currency value={userTax} />
					</div>
				</Row>
			)
		);
	};
	const xCounty = county ? county.isTaxSplit : 0;
	console.log(xCounty);

	return (
		<Card className="py-2">
			<CardTitle>
				<h2 className="d-flex justify-content-center align-items-center m-1">
					Summary
				</h2>
			</CardTitle>
			<CardBody>
				<Row>
					<div className="col">
						<h5 className="">Sales Tax</h5>
					</div>
					<div className="justify-content-right">
						<h5 className="">
							<Currency value={salesTax} />
						</h5>
					</div>
				</Row>
				<Row>
					<CurrencyInput
						wholeRow
						value={fixedAmountTax}
						readOnly={type !== DealStatus.PENDING}
						onChange={editFixedAmountTax}
						name="fixedAmountTax"
						label="+ Fixed Tax Amount"
						type="Currency"
						disableValidation
					/>
				</Row>
				{vit > 0 && (
					<>
						<Row>
							<div className="col">
								<h5 className="">VIT</h5>
							</div>
							<div className="justify-content-right">
								<h5 className="">
									<Currency value={vit} />
								</h5>
							</div>
						</Row>
						<hr />
					</>
				)}
				<Row>
					<div className="col">
						<h5 className="">Total Tax</h5>
					</div>
					<div className="justify-content-right">
						<h5 className="">
							<Currency value={tax} />
						</h5>
					</div>
				</Row>
				<Row>
					<div className="col">Taxable Amount</div>
					<div className="justify-content-right">
						<Currency value={taxableAmount} />
					</div>
				</Row>

				{totalTaxable !== taxableAmount && (
					<Row>
						<div className="col">Total Taxable</div>
						<div className="justify-content-right">
							<Currency value={totalTaxable} />
						</div>
					</Row>
				)}

				{xCounty === true && (
					<>
						<TaxSplit userTax={userTax1} taxName={county?.userTaxName1} />
						<TaxSplit userTax={userTax2} taxName={county?.userTaxName2} />
						<TaxSplit userTax={userTax3} taxName={county?.userTaxName3} />
						<TaxSplit userTax={userTax4} taxName={county?.userTaxName4} />
						<TaxSplit userTax={userTax5} taxName={county?.userTaxName5} />
						<TaxSplit userTax={userTax6} taxName={county?.userTaxName6} />
					</>
				)}
			</CardBody>
		</Card>
	);
};

export default SummaryTax;
