import { useCallback, useEffect, useState } from "react";

// Returns an object containing information about the key code
// Timestamp is required or else state wont update properly if the same key is pressed
const useGetKeyPress = () => {
	const [keyCode, setKeyCode] = useState({
		keyString: null,
		keyNum: null,
		timestamp: null,
	});

	const getKeyPress = useCallback(
		(e) =>
			setKeyCode({
				keyString: e.code,
				keyNum: e.keyCode,
				timestamp: e.timestamp,
			}),
		// eslint-disable-next-line
		[]
	);

	useEffect(() => {
		document.addEventListener("keydown", getKeyPress);

		return () => document.removeEventListener("keydown", getKeyPress);
	}, [getKeyPress]);

	return keyCode;
};

export default useGetKeyPress;
