import React from "react";
import aulImage from "assets/img/AUL.png";

export const AULButton = () => {
	const commitData = () => {
		window.open(
			"https://dealerclick.com/aul.pdf",
			"_blank",
			"noopener,noreferrer"
		);
	};

	return (
		<div className="row row-sm-6 d-flex justify-content-center align-items-center">
			<div className="col-sm-4 m-0 p-0">
				<span>
					<div className="miniLogo">
						<img className="pr-2" src={aulImage} alt="react-logo" />
					</div>
				</span>
				<div className="btn btn-info btn-sm" onClick={commitData}>
					SignUp
				</div>
			</div>
		</div>
	);
};
export default AULButton;
