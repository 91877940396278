import React, { useState, useEffect } from "react";
import { Card, CardBody, Row, Spinner } from "reactstrap";

import useCurrentCustomerStore from "../../store/CustomerStore";
import Customer700CreditTab from "./customerDetailTab/credit700/Customer700CreditTab";
import ChildrenModal from "../../component/dc/modal/ChildrenModal";
import FileUploader from "../../component/dc/fileUploader/FileUploader";

import Credit from "assets/img/700CreditLogo2.png";
import Experian from "assets/img/experianLogo.png";
import Equifax from "assets/img/equifaxLogo.png";
import Transunion from "assets/img/transUnionLogo.png";

import { getPartnerLinks } from "../../api/dealerIntegrationsApi";
import { saveFailAlert } from "../../component/dc/modal/SweetAlerts";
import { showApiError } from "../../helperFunction/ApiErrorHandler";
import { useFromUser } from "../../store/LocalStorageHelper";
import {
	getCustomerPhoto,
	uploadCustomerPhoto,
} from "../../api/AttachmentsAPI";

import shallow from "zustand/shallow";

const CustomerDetailHeader = () => {
	// Local storage
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const [tuModal, setTuModal] = useState(false);
	const [xpnModal, setXpnModal] = useState(false);
	const [efxModal, setEfxModal] = useState(false);
	const [isSignup, setIsSignup] = useState(false);
	const [profilePicture, setProfilePicture] = useState({});
	const [profilePictureSrc, setProfilePictureSrc] = useState(null);
	const [loadingProfile, setLoadingProfile] = useState(false);

	const tuToggle = () => {
		setTuModal(!tuModal);
	};
	const xpnToggle = () => {
		setXpnModal(!xpnModal);
	};
	const efxToggle = () => {
		setEfxModal(!efxModal);
	};

	// Customer store
	const {
		customerID,
		creditScoreXpn,
		creditScore,
		creditScoreEfx,
	} = useCurrentCustomerStore(
		(state) => ({
			customerID: state.ID,
			firstName: state.firstName,
			lastName: state.lastName,
			creditScoreXpn: state.creditScoreXpn,
			creditScore: state.creditScore,
			creditScoreEfx: state.creditScoreEfx,
		}),
		shallow
	);

	//call api to retrieve the see if user sign up or not.
	const get700Signup = () => {
		getPartnerLinks(locationID).then(
			(res) => {
				console.log(res);
				setIsSignup(res.data.content.isCreditSignup);
			},
			(err) => console.log(err)
		);
	};

	// Converts the bytes of a file
	const setCustomerPhotoSrc = (byteArray) => {
		if (byteArray.byteLength > 0) {
			const blob2File = new Blob([byteArray]);
			const blobUrl = URL.createObjectURL(blob2File);

			setProfilePictureSrc(blobUrl);
		}
	};

	useEffect(() => {
		get700Signup();
		customerID != null &&
			getCustomerPhoto(dealerID, locationID, customerID).then(
				(res) => setCustomerPhotoSrc(res.data),
				(err) => console.log(err)
			);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (customerID != null && Object.keys(profilePicture).length > 0) {
			setLoadingProfile(true);

			const formData = new FormData();

			Object.keys(profilePicture).forEach((key) =>
				formData.append("file", profilePicture[key])
			);

			uploadCustomerPhoto(dealerID, locationID, customerID, formData).then(
				(res) => {
					setLoadingProfile(false);
					setProfilePicture({});
					setCustomerPhotoSrc(res.data);
				},
				(err) => {
					setLoadingProfile(false);

					if (!err.isGeneralError) {
						showApiError(err, saveFailAlert);
					}
				}
			);
		}
		// eslint-disable-next-line
	}, [profilePicture]);

	return (
		<>
			<Row className="mx-0">
				<div className="col-md-4 text-center px-0">
					<div className="col-md">
						<Card className="pt-4 d-flex justify-content-center align-items-center">
							{(loadingProfile && (
								<Spinner className="mb-3" color="primary" />
							)) || (
								<>
									{profilePictureSrc != null && (
										<img
											className="pb-3 px-3"
											src={profilePictureSrc}
											alt="customer-profile"
										/>
									)}
									<FileUploader
										maxFileSize={20}
										iconClass="fa fa-camera"
										hideIconText
										setUploads={setProfilePicture}
										accept=".png, .jpeg, .jpg"
										acceptArray={[".png", ".jpeg", ".jpg"]}
										single
									/>
								</>
							)}
						</Card>
					</div>
				</div>
				{/* <div className="col-md-4 text-center">
					<Card className="pb-4">
						<CardHeader>
							<CardTitle tag="h3">{firstName + " " + lastName}</CardTitle>
						</CardHeader>
					</Card>
				</div> */}
				{/* <div className="text-center col-md-9 px-0">
					<Col className="px-0">
						<Row> */}
				<div className="col-xl-2">
					<Card className="zoom">
						<CardBody onClick={xpnToggle} className="text-center">
							{" "}
							<img src={Experian} alt="experian" className="rounded" />
							Score: {creditScoreXpn ? creditScoreXpn : "None"}
						</CardBody>
					</Card>
				</div>
				<div className="col-xl-2">
					<Card className="zoom">
						<CardBody onClick={tuToggle} className="text-center">
							{" "}
							<img src={Transunion} alt="transunion" className="rounded" />
							Score: {creditScore ? creditScore : "None"}
						</CardBody>
					</Card>{" "}
				</div>
				<div className="col-xl-2">
					<Card className="zoom">
						<CardBody onClick={efxToggle} className="text-center">
							{" "}
							<img src={Equifax} alt="equifax" className="rounded" />
							Score: {creditScoreEfx ? creditScoreEfx : "None"}
						</CardBody>
					</Card>{" "}
				</div>
				{!isSignup ? (
					<div className="col-xl-2">
						<Card className="zoom">
							<a
								href="https://700credit.com/dealerclick"
								target="_blank"
								rel="noopener noreferrer"
								className=""
							>
								<CardBody>
									{" "}
									<img src={Credit} alt="700credit" className="rounded" />
									<div className="p text-center">Sign Up</div>
								</CardBody>
							</a>
						</Card>{" "}
					</div>
				) : (
					" "
				)}
				{/* </Row>
					</Col>
				</div> */}
			</Row>

			<div>
				<ChildrenModal
					modal={tuModal}
					toggle={tuToggle}
					modalTitle={
						<img src={Credit} alt="700credit" className="rounded mb-3 pt-3" />
					}
					children={<Customer700CreditTab bureau="TU" id={customerID} />}
				/>
				<ChildrenModal
					modal={xpnModal}
					toggle={xpnToggle}
					modalTitle={
						<img src={Credit} alt="700credit" className="rounded mb-3 pt-3" />
					}
					children={<Customer700CreditTab bureau="XPN" id={customerID} />}
				/>
				<ChildrenModal
					modal={efxModal}
					toggle={efxToggle}
					modalTitle={
						<img src={Credit} alt="700credit" className="rounded mb-3 pt-3" />
					}
					children={<Customer700CreditTab bureau="EFX" id={customerID} />}
				/>
			</div>
		</>
	);
};
export default CustomerDetailHeader;
