/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)

* Coded by DealerClick Software **RoarinLion))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import CardContent from "../card/CardContent";
import CardContentBottom from "../card/CardContentBottom";
import { getDealsStatusList } from "api/DealAPI";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";
import { retrieveFailAlert } from "component/dc/modal/SweetAlerts";
import { Card, Row, Col, CardTitle } from "reactstrap";
import { useFromUser } from "store/LocalStorageHelper";

const DealHeader = ({ statusList, setStatusList }) => {
	const [totalDeal, setTotalDeal] = useState([]);
	//const [data, setData] = useState([]);
	const locationID = useFromUser("locationID");
	const [pending, setPending] = useState(0);
	const [sold, setSold] = useState(0);
	const [deleted, setDeleted] = useState(0);
	const [Funded, setFunded] = useState(0);
	const [repo, setRepo] = useState(0);

	const [status, setStatus] = useState("Pending");

	const dataFormat = (content, setHook) => {
		var total = 0;
		const container = Object.keys(content).map((key) => {
			total = total + content[key].numOfType;
			if (content[key].typeDesc === "Pending") {
				setPending(content[key].numOfType);
			}
			if (content[key].typeDesc === "Deleted") {
				setDeleted(content[key].numOfType);
			}
			if (content[key].typeDesc === "Sold") {
				setSold(content[key].numOfType);
			}
			if (content[key].typeDesc === "Funded") {
				setFunded(content[key].numOfType);
			}
			if (content[key].typeDesc === "Repo") {
				setRepo(content[key].numOfType);
			}

			return (
				<div>
					{content[key].typeDesc} : {content[key].numOfType}
				</div>
			);
		});
		setHook(total);
		console.log(totalDeal);
		return container;
	};
	const getListData = (LocationID) => {
		console.log(LocationID);
		if (locationID !== "") {
			getDealsStatusList(LocationID).then(
				(response) => {
					console.log(response.data.content);
					dataFormat(response.data.content, setTotalDeal);
					//setData(result);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}
	};
	useEffect(() => {
		getListData(locationID);
		//eslint-disable-next-line
	}, []);

	return (
		<>
			<div className="pt-3 pb-3 mobileHeading">
				<Col className="text-center">
					<CardTitle>
						{" "}
						<h2>Deal - {status}</h2>
					</CardTitle>
				</Col>
			</div>
			<Row className="mx-0 mb-3">
				{/* Card 1 */}
				<Col
					lg="3"
					md="6"
					sm="6"
					onClick={() => {
						setStatusList("Pending");
						setStatus("Pending");
					}}
				>
					<Card
						className={
							status === "Pending" ? "card-stats-active" : "card-stats"
						}
					>
						<NavLink to="/deal-list" activeClassName="" className="text-dark">
							<CardContent
								iconClass="icon-big text-center nc-icon nc-briefcase-24 text-warning"
								title1="Pending"
								title2={pending}
								check={status === "Pending"}
							/>
						</NavLink>
						<CardContentBottom
							navClass="col-xs-3"
							routePath="/deal-add"
							iconClass="nc-icon nc-minimal-down"
							iconText=" Add new"
						/>
					</Card>
				</Col>

				{/* Card 2 */}
				<Col
					lg="3"
					md="6"
					sm="6"
					onClick={() => {
						setStatusList("Sold");
						setStatus("Sold");
					}}
				>
					<Card
						className={status === "Sold" ? "card-stats-active" : "card-stats"}
					>
						<NavLink to="/deal-list" activeClassName="" className="text-dark">
							<CardContent
								iconClass="icon-big text-center nc-icon nc-briefcase-24 text-success"
								title1="Sold"
								title2={sold}
								check={status === "Sold"}
							/>
						</NavLink>
						<CardContentBottom
							navClass="col-xs-3"
							routePath="/deal-list"
							iconClass="nc-icon nc-simple-add"
							iconText=" Sold Report"
						/>
					</Card>
				</Col>

				{/* Card 3 */}
				<Col
					lg="3"
					md="6"
					sm="6"
					onClick={() => {
						setStatusList("Funded");
						setStatus("Funded");
					}}
				>
					<Card
						className={status === "Funded" ? "card-stats-active" : "card-stats"}
					>
						<NavLink to="/deal-list" activeClassName="" className="text-dark">
							<CardContent
								iconClass="icon-big text-center nc-icon nc-briefcase-24 text-info"
								title1="Funded"
								title2={Funded}
								check={status === "Funded"}
							/>
						</NavLink>
						<CardContentBottom
							navClass="col-xs-3"
							routePath="/deal-list"
							iconClass="nc-icon nc-minimal-down"
							iconText=" Funded Report"
						/>
					</Card>
				</Col>

				{/* Card 4 */}
				<Col
					lg="3"
					md="6"
					sm="6"
					onClick={() => {
						setStatusList("Deleted,Repo");
						setStatus("Deleted & Repo");
					}}
				>
					<Card
						className={
							status === "Deleted & Repo" ? "card-stats-active" : "card-stats"
						}
					>
						<NavLink to="/deal-list" activeClassName="" className="text-dark">
							<CardContent
								iconClass="icon-big text-center nc-icon nc-briefcase-24 text-danger"
								title1="Deleted"
								title2={deleted + repo}
								check={status === "Deleted & Repo"}
							/>
						</NavLink>
						<CardContentBottom
							navClass="col-xs-3"
							routePath="/deal-list"
							iconClass="nc-icon nc-minimal-down"
							iconText=" Deleted Report"
						/>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default DealHeader;
