import React, { useState } from "react";
import VINDecoderForm from "component/dc/forms/VINDecoderForm";
import ChildrenModal from "./ChildrenModal";

function ShowModal({ title }) {
	const [toggle, setToggle] = useState(false);

	const setIsToggled = () => {
		setToggle((prevState) => !prevState);
	};

	return (
		<>
			<div onClick={setIsToggled} className="btn-info btn btn-md">
				<i className="icon-big text-center nc-icon nc-refresh-69 text-default"></i>{" "}
				{title}
			</div>
			<ChildrenModal
				modal={toggle}
				toggle={setIsToggled}
				size="md"
				modalTitle={title}
				children={
					<VINDecoderForm
						header=""
						useGlobalVin={true}
						updateStore={false}
						textInputSize="modal"
						styleSelectorSize="modalStyle"
						toggle={setIsToggled}
					/>
				}
			/>
		</>
	);
}

export default ShowModal;
