import React, { useEffect, useState } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";

import useCurrentCustomerStore from "../../../../store/CustomerStore";
import ChildrenModal from "../../../../component/dc/modal/ChildrenModal";
import InputElement, {
	ZipInputElement,
} from "../../../formValidation/InputElement";
import InputWrapper from "../../../formValidation/InputWrapper";
import MailingAddress from "./MailingAddress";
import ZipCityTable from "../../../../component/dc/address/ZipCityTable";

import { stateOptions, ownershipType } from "../../../../Constants";
import { useFormContext } from "react-hook-form";
import {
	SelectorElement,
	CurrencyInputElement,
} from "../../../formValidation/InputElement";
import dayjs from "dayjs";

import shallow from "zustand/shallow";

const CurrentAddress = ({ getCities }) => {
	// Local states
	const [currentStreet, setCurrentStreet] = useState();
	const [currentUnit, setCurrentUnit] = useState();
	const [currentCity, setCurrentCity] = useState();
	const [currentState, setCurrentState] = useState();
	const [currentZip, setCurrentZip] = useState();
	const [currentCounty, setCurrentCounty] = useState();
	const [currentFromDate, setCurrentFromDate] = useState();
	const [mailCityModal, setMailCityModal] = useState(false);
	const [mailAddressData, setMailAddressData] = useState({});
	const [currentOwnership, setCurrentOwnership] = useState();
	const [checkAddress, setCheckAddress] = useState(false);
	const [currentMonth, setCurrentMonth] = useState();
	const [currentYear, setCurrentYear] = useState();
	const [currentMonthlyPayment, setcurrentMonthlyPayment] = useState(0);

	// Customer store
	const {
		currentAddress,
		mailingAddress,
		editMailingAddress,
	} = useCurrentCustomerStore(
		(state) => ({
			currentAddress: state.currentAddress,
			mailingAddress: state.mailingAddress,
			editMailingAddress: state.editMailingAddress,
		}),
		shallow
	);

	// Opens and closes the zip city modal for mailing address
	const toggleMailCityModal = () => setMailCityModal(!mailCityModal);

	// Show modal containing cities with the 5 digit zip entered for
	// mailing address
	const handleMailZipChange = (zip, address) => {
		setMailAddressData({ zip, address });
		toggleMailCityModal();
	};

	// Updates the current address object in customer store when the
	// input fields and selected city when zip field is focused out
	const handleCurrentZipChange = (zip) => {
		const updatedAddress = {
			street: methods.getValues("currentStreet"),
			unit: methods.getValues("currentUnit"),
			city: methods.getValues("currentCity"),
			state: methods.getValues("currentState"),
			zip: methods.getValues("currentZip"),
			county: methods.getValues("currentCounty"),
			fromDate: methods.getValues("currentFromDate"),
			ownership: methods.getValues("currentOwnership"),
			months: methods.getValues("currentMonth"),
			years: methods.getValues("currentYear"),
			monthlyPayment: methods.getValues("currentMonthlyPayment"),
		};

		getCities(zip, updatedAddress);
	};

	// Checks if two values are equal
	const equalsValue = (currentField, mailField) =>
		currentField != null && currentField !== "" && currentField === mailField;

	// Sets local states to store value
	useEffect(() => {
		setCurrentStreet(currentAddress.street || "");
		setCurrentUnit(currentAddress.unit || "");
		setCurrentCity(currentAddress.city || "");
		setCurrentState(currentAddress.state || "");
		setCurrentZip(currentAddress.zip || "");
		setCurrentCounty(currentAddress.county || "");
		setCurrentFromDate(currentAddress.fromDate || "");
		setCurrentOwnership(currentAddress.ownership || "");
		setCurrentMonth(currentAddress.months || "");
		setCurrentYear(currentAddress.years || "");
		setcurrentMonthlyPayment(currentAddress.monthlyPayment || 0);
		// eslint-disable-next-line
	}, [currentAddress]);

	useEffect(() => {
		calculateYearsAndMonths();
		// eslint-disable-next-line
	}, [currentFromDate]);

	// Sets the address check mark to true if the current and mailing address match
	useEffect(() => {
		const allMatch =
			equalsValue(currentAddress?.street, mailingAddress?.street) &&
			equalsValue(currentAddress?.unit, mailingAddress?.unit) &&
			equalsValue(currentAddress?.city, mailingAddress?.city) &&
			equalsValue(currentAddress?.state, mailingAddress?.state) &&
			equalsValue(currentAddress?.zip, mailingAddress?.zip) &&
			equalsValue(currentAddress?.county, mailingAddress?.county);
		if (allMatch) {
			setCheckAddress(true);
		}
		// eslint-disable-next-line
	}, []);

	const calculateYearsAndMonths = () => {
		if (currentFromDate !== null && currentFromDate !== "") {
			let findMonth = dayjs(dayjs()).diff(currentFromDate, "month");
			let getMonth = findMonth % 12;
			let getYear = Math.floor(findMonth / 12);
			setCurrentMonth(getMonth || "");
			setCurrentYear(getYear || "");
			console.log(getYear);
		}
	};

	// Get validation context
	const methods = useFormContext();

	return (
		<div>
			<Form>
				<InputWrapper
					formTitle="Address"
					inputComponents={[
						<SelectorElement
							{...methods}
							value={currentOwnership}
							name="currentOwnership"
							label="Ownership"
							onChange={(e) => {
								setCurrentOwnership(e.target.value);
							}}
							options={ownershipType}
						/>,
						<InputElement
							{...methods}
							value={currentFromDate}
							onBlur={calculateYearsAndMonths}
							onChange={(e) => {
								setCurrentFromDate(e.target.value);
							}}
							name="currentFromDate"
							label="From Date"
							type="date"
						/>,
						<InputElement
							{...methods}
							readOnly={true}
							value={currentYear}
							name="currentYear"
							label="Years"
							type="number"
						/>,
						<InputElement
							{...methods}
							readOnly={true}
							value={currentMonth}
							name="currentMonth"
							label="Months"
							type="number"
						/>,

						<InputElement
							{...methods}
							value={currentStreet}
							onChange={(e) => {
								setCurrentStreet(e.target.value);
							}}
							name="currentStreet"
							label="Street Address"
							type="text"
						/>,
						<InputElement
							{...methods}
							value={currentUnit}
							onChange={(e) => {
								setCurrentUnit(e.target.value);
							}}
							name="currentUnit"
							label="Apt/Unit/Bld"
							type="text"
						/>,
						<InputElement
							{...methods}
							value={currentCity}
							onChange={(e) => {
								setCurrentCity(e.target.value);
							}}
							name="currentCity"
							label="City"
							type="text"
						/>,
						<SelectorElement
							{...methods}
							value={currentState}
							name="currentState"
							label="State"
							onChange={(e) => setCurrentState(e.target.value)}
							options={stateOptions}
						/>,
						<ZipInputElement
							{...methods}
							value={currentZip}
							onChange={setCurrentZip}
							openZipModal={handleCurrentZipChange}
							name="currentZip"
							label="Zip"
						/>,
						<InputElement
							{...methods}
							value={currentCounty}
							onChange={(e) => setCurrentCounty(e.target.value)}
							name="currentCounty"
							label="County"
							type="text"
						/>,
						<CurrencyInputElement
							{...methods}
							value={currentMonthlyPayment}
							onChange={setcurrentMonthlyPayment}
							name="currentMonthlyPayment"
							label="Rent/Mortgage"
							type="text"
						/>,
						<FormGroup className="ml-3 mt-3 mb-0 col-sm-12">
							<span onClick={() => setCheckAddress(!checkAddress)}>
								<Input
									name="sameAsMailing"
									type="checkbox"
									checked={checkAddress}
								/>
								<Label check>Mailing Address Same as Current Address</Label>
							</span>
						</FormGroup>,
					]}
				/>
			</Form>
			<div>
				<MailingAddress
					getMailCities={handleMailZipChange}
					sameAsCurrent={checkAddress}
				/>
				<ChildrenModal
					modal={mailCityModal}
					toggle={toggleMailCityModal}
					modalTitle="Cities"
				>
					<ZipCityTable
						data={mailAddressData}
						toggle={toggleMailCityModal}
						address={mailingAddress}
						setAddress={editMailingAddress}
					/>
				</ChildrenModal>
			</div>
		</div>
	);
};

export default CurrentAddress;
