import React, { useEffect, useRef } from "react";

import ImageCropper from "../cropper/ImageCropper";
import MultiImageErrors from "./MultiImageErrors";
import MultiImageFiles from "./MultiImageFiles";
import MultiImageUploadButton from "./MultiImageUploadButton";
import UploadImageIcon from "../UploadImageIcon";

import { failAlert } from "component/dc/modal/SweetAlerts";
import { useMultiImageData } from "./MultiImageContext";
import {
	actualFileType,
	getErrors,
	// isFileSizeTooLarge,
	isImagePortrait,
	// isImageTooLarge,
	// isImageTooSmall,
} from "../ImageUtils";

import "assets/css/multi-image.css";

/**
 *
 * @param {React.state: number} totalFiles	State containing the total number of images
 * @param {React.state: number} maxFiles	State containing the maximum images allowed
 *
 * TODO: Add a callback function for when upload is clicked
 */
const MultiImageUploader = ({
	totalFiles,
	maxFiles,
	handleUpload,
	hideUploadButton,
}) => {
	// Multi image context
	const {
		selectedImage,
		setSelectedImage,
		selectedImageName,
		setSelectedImageName,
		selectedImageIndex,
		setSelectedImageIndex,
		imageKeys,
		setImageKeys,
		hideCrop,
		setHideCrop,
		croppedImage,
		setCroppedImage,
		croppedImages,
		setCroppedImages,
		imageSources,
		setImageSources,
		fileUploads,
		setFileUploads,
		croppedUploads,
		setCroppedUploads,
		setUploadErrors,
	} = useMultiImageData();

	// Local states
	const fileInputRef = useRef(null);

	// Calls the onClick function of the file input element
	const updateInput = () => {
		setUploadErrors([]);
		fileInputRef.current.click();
	};

	// Called when an image is selected for upload
	const uploadImage = (e) => {
		e.preventDefault();

		const inputFiles = e.target.files;
		const allowedUploads = imageKeys.length + totalFiles + inputFiles.length;

		if (allowedUploads > maxFiles) {
			if (maxFiles - totalFiles - imageKeys.length <= 0) {
				failAlert(
					`A maximum of ${maxFiles} images are allowed. You cannot select any more images to upload.`
				);
			} else {
				failAlert(
					`A maximum of ${maxFiles} images are allowed. You are allowed to upload ${
						maxFiles - totalFiles - imageKeys.length
					} more image(s).`
				);
			}
			return;
		}

		Object.entries(inputFiles).forEach((arr) => {
			const reader = new FileReader();
			const currentFile = arr[1];

			if (currentFile != null) {
				reader.readAsArrayBuffer(currentFile);
				reader.onloadend = (load) => fileLoaded(load, currentFile);
				reader.onerror = (err) => fileError(err, e);
			}
		});
	};

	// Callback for when a file has successfully been loaded into the input element.
	// Verifies that file is JPEG or PNG
	const fileLoaded = (e, upload) => {
		var arr = new Uint8Array(e.target.result).subarray(0, 4);
		var header = "";

		for (var i = 0; i < arr.length; i++) {
			header += arr[i].toString(16);
		}

		const acceptedFileType = actualFileType(header);

		if (acceptedFileType === upload.type) {
			let reader = new FileReader();

			reader.readAsDataURL(upload);
			reader.onload = (e) => fileAccepted(e, upload);
		} else {
			setUploadErrors((prev) => ({
				...prev,
				[upload.name]: ["Only JPEG/JPG and PNG files are allowed"],
			}));
		}
	};

	// Handler for processing an accepted image file.
	const fileAccepted = (e, upload) => {
		const imageData = e.target.result;
		var image = new Image();

		image.src = imageData;

		image.onload = () => {
			const height = image.height;
			const width = image.width;
			const imageIsPortrait = isImagePortrait(width, height);
			// const fileTooLarge = isFileSizeTooLarge(upload.size);
			// const imageTooLarge = isImageTooLarge(width, height);
			// const imageTooSmall = isImageTooSmall(width, height);

			// Set upload file error(s) or else add the file to the upload container
			if (imageIsPortrait) {
				// if (imageIsPortrait || fileTooLarge || imageTooLarge || imageTooSmall) {
				// const errorMessages = getErrors(
				// 	imageIsPortrait,
				// 	fileTooLarge,
				// 	imageTooLarge,
				// 	imageTooSmall,
				// 	width,
				// 	height,
				// 	upload.size
				// );
				const errorMessages = getErrors(imageIsPortrait);

				setUploadErrors((prev) => ({ ...prev, [upload.name]: errorMessages }));
			} else {
				const updatedCroppedImages = croppedImages.filter(
					(fileName) => fileName !== upload.name
				);

				setImageSources((prev) => ({
					...prev,
					[upload.name]: URL.createObjectURL(upload),
				}));

				setFileUploads((prev) => ({
					...prev,
					[upload.name]: upload,
				}));

				// Remove cropped image back to the original if original is re-uploaded
				setCroppedImages(updatedCroppedImages);

				if (croppedUploads[upload.name] != null) {
					const updatedCrops = { ...croppedUploads };

					delete updatedCrops[upload.name];
					setCroppedUploads(updatedCrops);
				}
			}
		};
	};

	// Called when there is an error in the file upload process
	const fileError = (e, upload) => {
		if (e?.target?.error?.name === "NotReadableError") {
			setUploadErrors((prev) => ({
				...prev,
				[upload.name]: ["File could not be read, please try again"],
			}));
		} else {
			setUploadErrors((prev) => ({
				...prev,
				[upload.name]: [`${e?.target?.error?.name || "Unexpected error"}`],
			}));
		}
	};

	// Replaces the image src for an image with the cropped version and add its to the cropped
	// uploads. Updates other states as needed
	const replaceWithCroppedImage = () => {
		if (croppedImage !== null) {
			const blob2File = new File([croppedImage], selectedImageName, {
				type: "image/jpeg",
			});
			const imageUrlObject = URL.createObjectURL(croppedImage);

			setImageSources((prev) => ({
				...prev,
				[selectedImageName]: imageUrlObject,
			}));

			setCroppedUploads((prev) => ({
				...prev,
				[selectedImageName]: blob2File,
			}));

			setSelectedImage(imageUrlObject);
			setCroppedImages((prev) => [...prev, selectedImageName]);
		}
	};

	// Resets the cropped image to the initial image selected for upload
	const resetCrop = () => {
		if (fileUploads[selectedImageName] != null) {
			const imageSource = URL.createObjectURL(fileUploads[selectedImageName]);

			let modifiedCroppedUploads = { ...croppedUploads };
			delete modifiedCroppedUploads[selectedImageName];

			setSelectedImage(imageSource);
			setCroppedUploads(modifiedCroppedUploads);
			setImageSources((prev) => ({
				...prev,
				[selectedImageName]: imageSource,
			}));
			setCroppedImages((prev) =>
				prev.filter((name) => name !== selectedImageName)
			);
		}
	};

	// Update image keys with the uploaded files
	useEffect(() => {
		const keys = Object.keys(fileUploads);

		if (keys.length > 0 && selectedImage == null) {
			setSelectedImage(imageSources[keys[0]]);
			setHideCrop(false);
		}

		setImageKeys(keys);
		// eslint-disable-next-line
	}, [fileUploads]);

	// Updates states of image uploads and src if cropped image changes
	useEffect(
		() => replaceWithCroppedImage(),
		// eslint-disable-next-line
		[croppedImage]
	);

	// Updates the image to crop
	useEffect(() => {
		const currentImageName = imageKeys[selectedImageIndex];

		setSelectedImageName(imageKeys[selectedImageIndex]);
		setSelectedImage(imageSources[currentImageName]);
		// eslint-disable-next-line
	}, [imageKeys, selectedImageIndex]);

	return (
		<div>
			{!hideCrop && (
				<ImageCropper
					cropImage={selectedImage}
					setCropImage={setCroppedImage}
					setHideCrop={setHideCrop}
					resetCrop={resetCrop}
					selectedImageIndex={selectedImageIndex}
					totalImages={imageKeys.length}
					setSelectedImageIndex={setSelectedImageIndex}
				/>
			)}
			<div className="d-flex justify-content-center align-items-center">
				<div className="multi-image-wrapper">
					<MultiImageFiles />
					<div
						role="button"
						className="multi-image-file-upload d-flex justify-content-center align-items-center"
						onClick={updateInput}
					>
						<UploadImageIcon />
						<span>ADD IMAGE</span>
						<input
							ref={fileInputRef}
							type="file"
							onChange={uploadImage}
							multiple
							value=""
							accept=".png, .jpeg, .jpg"
						/>
					</div>
					<MultiImageErrors />
					<MultiImageUploadButton
						handleUpload={handleUpload}
						hideUploadButton={hideUploadButton}
					/>
				</div>
			</div>
		</div>
	);
};

export default MultiImageUploader;
