import React, { useState } from "react";
import {
	Card,
	CardHeader,
	CardBody,
	Form,
	InputGroup,
	Container,
	Col,
	CardTitle,
} from "reactstrap";
import { useFormContext } from "react-hook-form";
import FaviconGif from "assets/img/favicon.png";
import Footer from "layout/Footer.js";
import { useHistory } from "react-router-dom";
import { retrieveFailAlert } from "component/dc/modal/SweetAlerts";
import { showApiError } from "helperFunction/ApiErrorHandler";
import useRoute from "helperFunction/UseRoute";
import { PATHS } from "Constants";
import { resetPassword } from "api/LoginAPI";
import InputElement from "view/formValidation/InputElement";
import { successAlertCustom } from "component/dc/modal/SweetAlerts";

const ForgottenPasswordForm = () => {
	const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");
	const history = useHistory();
	// Routing
	const loginPage = useRoute(PATHS.LOGIN);

	const checkUser = () => {
		const data = {
			username,
			email,
		};

		resetPassword(data).then(
			(res) => {
				console.log(res);
				successAlertCustom(
					"Successfully reset your password. Please check your email."
				);
				history.push(loginPage);
			},
			(err) => {
				console.log(err);
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};
	// Get validation context
	const methods = useFormContext();
	return (
		<>
			<div className="forgotten-password">
				<Container>
					<Col className="ml-auto mr-auto" lg="4" md="6">
						<Form action="" className="form mt-10" method="">
							<div className="text-center">
								<img src={FaviconGif} alt="favicon" className="favicon-gif" />
							</div>
							<Card className="card-login">
								<CardHeader>
									<CardTitle>
										<h3 className="text-center">Password Reset!</h3>
									</CardTitle>
								</CardHeader>
								<CardBody className="px-4">
									<InputElement
										{...methods}
										colSize="col"
										type="text"
										name="username"
										label="Username"
										value={username}
										onChange={(e) => setUsername(e.target.value)}
									/>

									<CardTitle>
										<h5 className="text-center mt-4">Or</h5>
									</CardTitle>
									<InputGroup>
										<InputElement
											{...methods}
											colSize="col"
											type="email"
											label="Email"
											name="email"
											value={email}
											onChange={(e) => setEmail(e.target.value)}
										/>
									</InputGroup>
									<div className="text-center mt-2">
										<div
											type="submit"
											className="btn btn-primary mx-auto"
											onClick={methods.handleSubmit(checkUser)}
										>
											submit
										</div>
									</div>
								</CardBody>
							</Card>
						</Form>
					</Col>
				</Container>
			</div>
			<Footer />
		</>
	);
};

export default ForgottenPasswordForm;
