import { getPartnerSend } from "../../../../../api/dealerIntegrationsApi";
import { companyFeaturesList } from "../../../../../Constants";
import { showApiError } from "../../../../../helperFunction/ApiErrorHandler";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col, Input, Label, Row } from "reactstrap";
import { useFromUser } from "../../../../../store/LocalStorageHelper";
import useCurrentLoginStore from "../../../../../store/LoginStore";
import shallow from "zustand/shallow";

const CompanyFeature = () => {
	const [data1, setData1] = useState([]);
	const [data2, setData2] = useState([]);
	const [data3, setData3] = useState([]);
	const locationID = useFromUser("locationID");
	const {
		dc,
		cr,
		qb,
		ls,
		nls,
		fn,
		ps,
		psTimeClock,
		psBarCode,
		keyless,
	} = useCurrentLoginStore((state) => state, shallow);
	const getFeature = () => {
		getPartnerSend(locationID).then(
			(res) => {
				const response = res.data.content;
				const list1 = [];
				const list2 = [];
				for (const key in companyFeaturesList) {
					const getValue = companyFeaturesList[key].value;
					if (key % 2 === 0) {
						list1.push({
							label: companyFeaturesList[key].label,
							value: response[getValue],
						});
					} else {
						list2.push({
							label: companyFeaturesList[key].label,
							value: response[getValue],
						});
					}
				}
				console.log(list1);
				setData1(list1);
				setData2(list2);
			},
			(err) => {
				console.log(err);
				if (!err.isGeneralError) {
					showApiError(err);
				}
			}
		);
	};
	useEffect(() => {
		const list3 = [
			{ value: keyless, label: "Keyless" },
			{ value: dc, label: "DMS" },
			{ value: cr, label: "Credit Reports" },
			{ value: qb, label: "QuickBook" },
			{ value: ls, label: "Leasing" },
			{ value: nls, label: "RTO" },
			{ value: fn, label: "BHPH" },
			{ value: ps, label: "Part & Service" },
			{ value: psTimeClock, label: "Time Clock" },
			{ value: psBarCode, label: "BarCode" },
		];
		setData3(list3);
		getFeature();
		// eslint-disable-next-line
	}, []);

	const col1 = data1.map((obj) => (
		<>
			<Col>
				<Input checked={obj.value} name="feature" type="checkbox" />
				<Label>{obj.label}</Label>
			</Col>
		</>
	));
	const col2 = data2.map((obj) => (
		<>
			<Col>
				<Input checked={obj.value} name="feature" type="checkbox" />
				<Label>{obj.label}</Label>
			</Col>
		</>
	));
	const col3 = data3.map((obj) => (
		<>
			<Col>
				<Input checked={obj.value} name="feature" type="checkbox" />
				<Label>{obj.label}</Label>
			</Col>
		</>
	));

	return (
		<Card>
			<CardTitle tag="h4" className="pl-3">
				Features
			</CardTitle>
			<CardBody>
				<Row>
					<Col md="4">{col1}</Col>
					<Col md="4">{col2}</Col>
					<Col md="4">{col3}</Col>
				</Row>
			</CardBody>
		</Card>
	);
};

export default CompanyFeature;
