import React, { useState, useEffect } from "react";
import {
	Button,
	FormGroup,
	Label,
	Input,
	Card,
	Col,
	CardBody,
	CardHeader,
	Row,
	CardTitle,
	Form,
} from "reactstrap";
import { commissionSettingsEnum } from "Constants.js";
import useCurrentSettingStore from "store/SettingStore";
import { saveSettings } from "api/SettingsAPI";
import { saveDeal } from "api/DealAPI";
import { saveSuccessAlert } from "component/dc/modal/SweetAlerts";
import { showApiError } from "../../../../helperFunction/ApiErrorHandler";
import { saveFailAlert } from "../../../../component/dc/modal/SweetAlerts";
import useCurrentDealStore from "store/DealStore";
import useBuildDeal from "helperFunction/deal/useBuildDeal";
import { retrieveFailAlert } from "component/dc/modal/SweetAlerts";
import { useFromUser } from "store/LocalStorageHelper";
import { getSettings } from "api/SettingsAPI";
import { DealStatus } from "Constants";
import tabOnEnter from "helperFunction/tabOnEnter";
import shallow from "zustand/shallow";
import useSetDeal from "../../../../helperFunction/deal/useSetDeal";

const CommissionSettings = ({ dealSettings = false }) => {
	const {
		commissionSettings: companyCommissionSettings,
		profitSettings: companyProfitSettings,
		taxDefaults: companyTaxDefaults,
	} = useCurrentSettingStore();

	const { currentDealSettings, editDealSettings, type } = useCurrentDealStore(
		(state) => ({
			currentDealSettings: state.deal.dealSettings,
			editDealSettings: state.editDealSettings,
			type: state.deal.type,
		}),
		shallow
	);

	const getDeal = useBuildDeal();
	const setDeal = useSetDeal();

	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	let commissionSettings = {};
	let profitSettings = {};
	let taxDefaults = {};

	if (dealSettings) {
		if (currentDealSettings.commissionSettings == null) {
			commissionSettings = companyCommissionSettings;
		} else {
			commissionSettings = currentDealSettings.commissionSettings;
		}
		profitSettings = currentDealSettings.profitSettings;
		taxDefaults = currentDealSettings.taxDefaults;
	} else {
		commissionSettings = companyCommissionSettings;
		profitSettings = companyProfitSettings;
		taxDefaults = companyTaxDefaults;
	}

	const [isDocFeeComm, setIsDocFeeComm] = useState(
		commissionSettings.isDocFeeComm
	);
	const [isAftMktComm, setIsAftMktComm] = useState(
		commissionSettings.isAftMktComm
	);
	const [isServConComm, setIsServConComm] = useState(
		commissionSettings.isServConComm
	);
	const [isGapComm, setIsGapComm] = useState(commissionSettings.isGapComm);
	const [isMiscComm, setIsMiscComm] = useState(commissionSettings.isMiscComm);
	const [isBankFeeComm, setIsBankFeeComm] = useState(
		commissionSettings.isBankFeeComm
	);
	const [isReserveComm, setIsReserveComm] = useState(
		"" || commissionSettings.isReserveComm
	);
	const [isSmogComm, setIsSmogComm] = useState(commissionSettings.isSmogComm);

	useEffect(() => {
		setIsDocFeeComm(commissionSettings.isDocFeeComm);
		setIsAftMktComm(commissionSettings.isAftMktComm);
		setIsServConComm(commissionSettings.isServConComm);
		setIsAftMktComm(commissionSettings.isAftMktComm);
		setIsServConComm(commissionSettings.isServConComm);
		setIsGapComm(commissionSettings.isGapComm);
		setIsMiscComm(commissionSettings.isMiscComm);
		setIsBankFeeComm(commissionSettings.isBankFeeComm);
		setIsReserveComm(commissionSettings.isReserveComm);
		setIsSmogComm(commissionSettings.isSmogComm);
	}, [commissionSettings]);

	const commissionObj = [
		{
			name: "isDocFeeComm",
			dropDownValue: isDocFeeComm,
			dropdownLabel: "Doc Fee",
			onChange: setIsDocFeeComm,
		},
		{
			name: "isAftMktComm",
			dropDownValue: isAftMktComm,
			dropdownLabel: "Aftermarket",
			onChange: setIsAftMktComm,
		},
		{
			name: "isServConComm",
			dropDownValue: isServConComm,
			dropdownLabel: "Service Contract",
			onChange: setIsServConComm,
		},
		{
			name: "isGapComm",
			dropDownValue: isGapComm,
			dropdownLabel: "Gap",
			onChange: setIsGapComm,
		},
		{
			name: "isMiscComm",
			dropDownValue: isMiscComm,
			dropdownLabel: "Misc",
			onChange: setIsMiscComm,
		},
		{
			name: "isBankFeeComm",
			dropDownValue: isBankFeeComm,
			dropdownLabel: "Bank Fee",
			onChange: setIsBankFeeComm,
		},
		{
			name: "isReserveComm",
			dropDownValue: isReserveComm,
			dropdownLabel: "Reserve",
			onChange: setIsReserveComm,
		},
		{
			name: "isSmogComm",
			dropDownValue: isSmogComm,
			dropdownLabel: "Dealer Smog",
			onChange: setIsSmogComm,
		},
	];

	const save = () => {
		let oldSettings = commissionSettings;
		oldSettings.isDocFeeComm = commissionObj[0].dropDownValue;
		oldSettings.isAftMktComm = commissionObj[1].dropDownValue;
		oldSettings.isServConComm = commissionObj[2].dropDownValue;
		oldSettings.isGapComm = commissionObj[3].dropDownValue;
		oldSettings.isMiscComm = commissionObj[4].dropDownValue;
		oldSettings.isBankFeeComm = commissionObj[5].dropDownValue;
		oldSettings.isReserveComm = commissionObj[6].dropDownValue;
		oldSettings.isSmogComm = commissionObj[7].dropDownValue;
		console.log(oldSettings);

		const settings = {
			taxDefaults,
			profitSettings,
			commissionSettings: oldSettings,
		};

		if (dealSettings) {
			//update the deal settings obj and save the deal
			editDealSettings(settings);
			// zustand doesnt update state quick enough so manually add it
			const deal = getDeal();
			deal.deal.dealSettings = JSON.stringify(settings);
			saveDeal(deal).then(
				(response) => {
					setDeal(response);
					saveSuccessAlert();
					console.log("Deal saved with ID: " + response.data.content.deal.ID);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, saveFailAlert);
					}
				}
			);
		} else {
			console.log(settings);
			saveSettings(settings).then(
				(res) => {
					console.log(res);
					saveSuccessAlert();
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}
	};

	const SaveButton = () => {
		return (
			<Button
				readOnly={type !== DealStatus.PENDING}
				color="success"
				onClick={() => {
					save();
				}}
				size="md"
			>
				<i className="nc-icon nc-cloud-download-93"></i> Save
			</Button>
		);
	};

	const getData = () => {
		getSettings(dealerID, locationID).then(
			(res) => {
				console.log(res);
				commissionObj[0].onChange(
					res.data.content.commissionSettings.isDocFeeComm
				);
				commissionObj[1].onChange(
					res.data.content.commissionSettings.isAftMktComm
				);
				commissionObj[2].onChange(
					res.data.content.commissionSettings.isServConComm
				);
				commissionObj[3].onChange(
					res.data.content.commissionSettings.isGapComm
				);
				commissionObj[4].onChange(
					res.data.content.commissionSettings.isMiscComm
				);
				commissionObj[5].onChange(
					res.data.content.commissionSettings.isBankFeeComm
				);
				commissionObj[6].onChange(
					res.data.content.commissionSettings.isReserveComm
				);
				commissionObj[7].onChange(
					res.data.content.commissionSettings.isSmogComm
				);
				//	save();
			},
			(err) => {
				console.log(err);

				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	const UseDefaults = () => {
		return (
			<Button
				readOnly={type !== DealStatus.PENDING}
				color="primary"
				onClick={() => {
					getData();
				}}
				size="md"
				className="pull-right"
			>
				<i className="nc-icon nc-check-2"></i> Use Defaults
			</Button>
		);
	};

	const commissionComponents = commissionObj.map((obj) => {
		return (
			// <Row className="mx-0">
			// 	<Col>
			// 		<FormGroup>
			// 			{" "}
			// 			<Label>{obj.dropdownLabel}</Label>
			// 			<Input
			// 				name={obj.name}
			// 				type="select"
			// 				value={obj.dropDownValue}
			// 				onChange={(e) => {
			// 					obj.onChange(e.target.value);
			// 					console.log(e.target.value);
			// 				}}
			// 			>
			// 				<option value={commissionSettingsEnum.NO_COMMISSION.value}>
			// 					{commissionSettingsEnum.NO_COMMISSION.label}
			// 				</option>
			// 				<option value={commissionSettingsEnum.FRONT_COMMISSION.value}>
			// 					{commissionSettingsEnum.FRONT_COMMISSION.label}
			// 				</option>
			// 				<option value={commissionSettingsEnum.BACK_COMMISSION.value}>
			// 					{commissionSettingsEnum.BACK_COMMISSION.label}
			// 				</option>
			// 				<option value={commissionSettingsEnum.BOTH_COMMISION.value}>
			// 					{commissionSettingsEnum.BOTH_COMMISION.label}
			// 				</option>
			// 			</Input>
			// 		</FormGroup>
			// 	</Col>
			// </Row>

			<FormGroup>
				<Label key={obj.name}>
					{obj.dropdownLabel}
					<Col>
						<Col className="radioFont">
							<Input
								type="radio"
								name={obj.name}
								checked={obj.dropDownValue === 0 || obj.dropDownValue > 3}
								onChange={() => {
									obj.onChange(0);
								}}
							/>

							{commissionSettingsEnum.NO_COMMISSION.label}
						</Col>
						<Col className="radioFont">
							<Input
								type="radio"
								name={obj.name}
								checked={obj.dropDownValue === 1}
								onChange={() => {
									obj.onChange(1);
								}}
							/>

							{commissionSettingsEnum.FRONT_COMMISSION.label}
						</Col>
						<Col className="radioFont">
							<Input
								type="radio"
								name={obj.name}
								checked={obj.dropDownValue === 2}
								onChange={() => {
									obj.onChange(2);
								}}
							/>
							{commissionSettingsEnum.BACK_COMMISSION.label}
						</Col>
						<Col className="radioFont">
							<Input
								type="radio"
								name={obj.name}
								checked={obj.dropDownValue === 3}
								onChange={() => {
									obj.onChange(3);
								}}
							/>
							{commissionSettingsEnum.BOTH_COMMISION.label}
						</Col>
					</Col>
				</Label>
			</FormGroup>
		);
	});
	return (
		<div onKeyDown={tabOnEnter}>
			<Form>
				<Row className="mx-0 pb-2">
					<Col>
						<SaveButton />
					</Col>
					{dealSettings && (
						<Col>
							<UseDefaults />
						</Col>
					)}
				</Row>
				<Card>
					<CardHeader>
						<CardTitle tag="h3">Commission Settings</CardTitle>
					</CardHeader>
					<CardBody>
						<Col>
							<Row className="mx-0">{commissionComponents}</Row>
						</Col>
					</CardBody>
				</Card>
			</Form>
		</div>
	);
};

export default CommissionSettings;
