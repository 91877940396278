import React, { useEffect } from "react";
import { Input } from "reactstrap";
import useCurrentSaleStore from "store/SaleStore";
import dayjs from "dayjs";
import useCurrentDealStore from "store/DealStore";
import { DealStatus } from "Constants";
import { LoanPeriod } from "Constants.js";
import shallow from "zustand/shallow";

const FinanceDaysToPayInput = () => {
	const {
		// amtFinanced,
		loanPeriod,
		daysToPay,
		editDaysToPay,
		firstDueDate,
		loanDate,
	} = useCurrentSaleStore(
		(state) => ({
			loanPeriod: state.loanPeriod,
			daysToPay: state.daysToPay,
			editDaysToPay: state.editDaysToPay,
			firstDueDate: state.firstDueDate,
			loanDate: state.loanDate,
		}),
		shallow
	);

	// Deal store
	const { type, dealId } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
			dealId: state.deal.ID,
		}),
		shallow
	);

	const re = /^\d{1,3}$/;

	const calculateDaysToPay = () => {
		const date1 = dayjs(loanDate);
		const date2 = dayjs(firstDueDate);

		console.log(date1);
		console.log(date2);
		const diff = date2.diff(date1, "day");
		console.log(diff);
		editDaysToPay(diff);
	};

	const daysInPeriod = LoanPeriod[loanPeriod].daysInPeriod;

	useEffect(() => {
		calculateDaysToPay();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		console.log(loanPeriod);
		console.log(dealId);

		if (dealId === 0 || dealId === null) {
			editDaysToPay(LoanPeriod[loanPeriod].daysInPeriod);
		}
		// eslint-disable-next-line
	}, [editDaysToPay, loanPeriod]);

	return (
		<Input
			type="number"
			readOnly={Number(loanPeriod) === 5 || type !== DealStatus.PENDING}
			onFocus={(e) => e.target.select()}
			value={daysToPay}
			className="bold"
			onBlur={(e) => {
				let val = e.target.value;

				if (val === "") {
					editDaysToPay(daysInPeriod);
				} else {
					editDaysToPay(e.target.valueAsNumber);
				}
			}}
			onChange={(e) => {
				let changed = e.target.value;
				if (changed === "") {
					editDaysToPay(daysInPeriod);
				} else if (changed.match(re)) {
					editDaysToPay(e.target.valueAsNumber);
				}
			}}
		/>
	);
};

export default FinanceDaysToPayInput;
