/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 DealerClick (https://dealerclick.com)

* Coded by DealerClick Software ))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import classnames from "classnames";

import {
	Button,
	Collapse,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Navbar,
	Nav,
	Container,
	NavItem,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import avatar from "assets/img/faces/ayo-ogunseinde-2.jpg";
import { logout } from "auth/Authentication";
import { useFromUser } from "store/LocalStorageHelper";
import SunIcon from "../../../assets/img/sun-icon.svg";
import MoonIcon from "../../../assets/img/moon-icon.svg";
import ScreenRecorder from "../../../helperFunction/ScreenRecorder";

class DefaultNavbar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			collapseOpen: false,
			color: "navbar-transparent",
			name: "",
			locationID: "",
			dealerID: "",
		};
	}
	componentDidMount() {
		const fname = useFromUser("firstName");
		const locationID = useFromUser("locationID");
		const dealerID = useFromUser("dealerID");

		this.setState({ name: fname, locationID: locationID, dealerID: dealerID });
	}

	// this function opens and closes the sidebar on small devices
	toggleSidebar = () => {
		document.documentElement.classList.toggle("nav-open");
	};
	// this function opens and closes the collapse on small devices
	// it also adds navbar-transparent class to the navbar when closed
	// ad bg-white when opened
	toggleCollapse = () => {
		let newState = {
			collapseOpen: !this.state.collapseOpen,
		};
		if (!this.state.collapseOpen) {
			newState["color"] = "bg-white";
		} else {
			newState["color"] = "navbar-transparent";
		}
		this.setState(newState);
	};
	render() {
		return (
			<>
				<Navbar
					className="navbar-absolute bg-white customNav fixed-top"
					expand="lg"
				>
					<Container fluid className="pb-half">
						<div className="navbar-wrapper">
							<div className="navbar-minimize">
								<Button
									className="btn btn-icon btn-round"
									color="primary"
									id="minimizeSidebar"
									onClick={this.props.handleMiniClick}
								>
									<i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
									<i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
								</Button>
							</div>
							<div
								className={classnames("navbar-toggle", {
									toggled: this.state.sidebarOpen,
								})}
							>
								<button
									className="navbar-toggler"
									type="button"
									onClick={this.toggleSidebar}
								>
									<span className="navbar-toggler-bar bar1" />
									<span className="navbar-toggler-bar bar2" />
									<span className="navbar-toggler-bar bar3" />
								</button>
							</div>
							<h5 className="m-0">Hi, {this.state.name}!</h5>
						</div>
						<button
							aria-controls="navigation-index"
							aria-expanded={this.state.collapseOpen}
							aria-label="Toggle navigation"
							className="navbar-toggler"
							// data-target="#navigation"
							data-toggle="collapse"
							type="button"
							onClick={this.toggleCollapse}
						>
							<span className="navbar-toggler-bar navbar-kebab" />
							<span className="navbar-toggler-bar navbar-kebab" />
							<span className="navbar-toggler-bar navbar-kebab" />
						</button>{" "}
						<Collapse
							className="justify-content-end"
							navbar
							isOpen={this.state.collapseOpen}
						>
							<Form className="hide">
								<InputGroup className="no-border">
									<Input defaultValue="" placeholder="Search..." type="text" />
									<InputGroupAddon addonType="append">
										<InputGroupText>
											<i className="nc-icon nc-zoom-split" />
										</InputGroupText>
									</InputGroupAddon>
								</InputGroup>
							</Form>
							<Nav navbar>
								<div className="app-container">
									<div className="left-column">
										<ScreenRecorder />
									</div>
									<div className="right-column">
										<DarkModeToggle />
									</div>
								</div>
								<NavItem style={{ paddingTop: ".3rem" }}>
									<a
										className="btn btn-info btn-sm ml-2"
										href="http://216.34.250.239/"
										target="_blank"
										rel="noopener noreferrer"
									>
										<i className="nc-icon nc-support-17" /> Support
									</a>
								</NavItem>

								<UncontrolledDropdown className="ml-2 hide" nav>
									<DropdownToggle
										aria-haspopup={true}
										caret
										data-toggle="dropdown"
										id="navbarDropdownMenuLink"
										nav
										className="btn-rotate pr-1"
									>
										<i className="nc-icon nc-bell-55" />
									</DropdownToggle>
									<DropdownMenu
										persist
										aria-labelledby="navbarDropdownMenuLink"
										right
									>
										<DropdownItem to="">
											<NavLink className="nav-link" to="/settings">
												<p>Notification</p>
											</NavLink>
										</DropdownItem>
									</DropdownMenu>
								</UncontrolledDropdown>
								<UncontrolledDropdown className="ml-2 hide" nav>
									<DropdownToggle
										aria-haspopup={true}
										data-toggle="dropdown"
										id="navbarDropdownMenuLink"
										className="p-0"
										nav
										caret
									>
										<img className="avatar zoom" src={avatar} alt="Avatar" />
									</DropdownToggle>
									<DropdownMenu
										persist
										aria-labelledby="navbarDropdownMenuLink"
										right
									>
										<DropdownItem to="" className="text-center">
											<NavLink
												className="btn-rotate"
												to="/user-account"
												href="/user-account"
												onClick={(e) => {}}
											>
												<i className="nc-icon nc-single-02" />
												Account
											</NavLink>
										</DropdownItem>
										<DropdownItem to="" className="text-center">
											<NavLink
												to="/"
												className="btn-rotate"
												href="/"
												onClick={(e) => logout()}
											>
												<i className="nc-icon nc-button-power" /> Logout
											</NavLink>
										</DropdownItem>
									</DropdownMenu>
								</UncontrolledDropdown>
							</Nav>
						</Collapse>
					</Container>
				</Navbar>
			</>
		);
	}
}

export default DefaultNavbar;
const DarkModeToggle = () => {
	const [darkMode, setDarkMode] = useState(false);

	useEffect(() => {
		if (darkMode) {
			document.body.classList.add("dark");
		} else {
			document.body.classList.remove("dark");
		}
	}, [darkMode]);
	return (
		<>
			{darkMode === true ? (
				<div
					role="button"
					className="toggle"
					onClick={() => setDarkMode(!darkMode)}
				>
					<img alt="sun icon" src={MoonIcon} />
				</div>
			) : (
				<div
					role="button"
					className="toggle"
					onClick={() => setDarkMode(!darkMode)}
				>
					<img alt="sun icon" src={SunIcon} />
				</div>
			)}
		</>
	);
};
