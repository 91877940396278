import getAxiosInstance from "./interceptors/buildReqAndResInstance";

import { userBaseUrl } from "./Constants";

const instance = getAxiosInstance({
	baseURL: userBaseUrl + "/settings/",
});

export const getSettings = async (dealerID, locationID) => {
	return await instance.get(`${dealerID}/${locationID}`);
};

// TODO get the correct api from Angelo
export const saveSettings = async (body) => {
	return await instance.post(`save`, body);
};
