import { useContext } from "react";
import shallow from "zustand/shallow";
import { PATHS } from "../../../Constants";
import useRoute from "../../../helperFunction/UseRoute";
import useCurrentVehicleStore from "../../../store/InventoryStore";
import { KbbContext } from "../../../view/inventory/inventoryDetailTab/value/kbb/KbbContext";
import history from "../../../helperFunction/History";
import { saveFailAlert } from "../modal/SweetAlerts";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";
import { saveVehicle } from "../../../api/InventoryAPI";
import useBuildVehicle from "../../../helperFunction/BuildInventoryObj";
import { useLocation } from "react-router-dom";

/**
 *
 * @returns func for calling inventory save api and
 */
const useSaveInventory = () => {
	const { getDcKbbObj } = useContext(KbbContext);
	const getVehicle = useBuildVehicle();
	const setVehicle = useSetInventory();

	const saveInv = () => {
		const inventoryVehicle = getVehicle();
		const kbb = getDcKbbObj();
		const request = {
			vehicle: inventoryVehicle,
			kbb: kbb,
		};
		saveVehicle(request).then(
			(response) => {
				console.log(response);
				setVehicle(response);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};
	return saveInv;
};

export default useSaveInventory;

/**
 * Custom hook for setting inventoryfields like recon, kbb and the vehicle itself. This should be used only from inventory pages not deal since history is replaced here in case of a page reload
 *
 * @param {*} response object containing dcTableVehicle and its kbb object
 * @returns
 */
export const useSetInventory = () => {
	const inventoryDetail = useRoute(PATHS.INVENTORY_DETAIL);
	const { setDcKbbObj } = useContext(KbbContext);
	const location = useLocation();
	const { setInventoryFields } = useCurrentVehicleStore(
		(state) => state,
		shallow
	);

	return (response) => {
		setInventoryFields(response.data.content.vehicle);
		setDcKbbObj(response.data.content.kbb);

		const params = new URLSearchParams(location.search);
		const queryParamState = params.get("state");
		const queryParamCode = params.get("code");

		// replace current history and store with possibly updated invnetoryID
		console.log(history.location.pathname);
		if (history.location.pathname === inventoryDetail) {
			if (queryParamCode != null && queryParamState != null) {
				history.replace(
					`${inventoryDetail}?code=${queryParamCode}&state=${queryParamState}`,
					{ ID: response.data.content.vehicle.inventoryID }
				);
			} else {
				history.replace(inventoryDetail, {
					ID: response.data.content.vehicle.inventoryID,
				});
			}
		}
	};
};
