import React, { useContext, useEffect } from "react";

import { FormGroup, Form, Button } from "reactstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider, Controller } from "react-hook-form";
import dayjs from "dayjs";
import shallow from "zustand/shallow";

import { useFromUser } from "../../../../store/LocalStorageHelper";
import Selector from "component/dc/selector/Selector";
import InputElement, {
	CurrencyInputElement,
	IntegerInputElement,
	SelectorElement,
} from "../../../../view/formValidation/InputElement";
import InputWrapper from "../../../../view/formValidation/InputWrapper";
import { TradeContext } from "./TradeContext";
import useCurrentDealStore from "../../../../store/DealStore";
import { DealStatus, ActualMileageFlagOption } from "../../../../Constants";
import TradeInfo from "./TradeInfo";
import KbbValues from "../../../../view/inventory/inventoryDetailTab/value/kbb/KbbValues";
import { saveDeal } from "../../../../api/DealAPI";
import useBuildDeal from "../../../../helperFunction/deal/useBuildDeal";
import useSetDeal from "../../../../helperFunction/deal/useSetDeal";
import { showApiError } from "../../../../helperFunction/ApiErrorHandler";
import { saveSuccessAlert } from "../../modal/SweetAlerts";
import { KbbContext } from "../../../../view/inventory/inventoryDetailTab/value/kbb/KbbContext";
import Swal from "sweetalert2";

const TradeForm = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const today = dayjs().format("YYYY-MM-DD");
	const {
		ID,
		trades,
		editTrades,
		dealType,
		lenders,
		editTradeVendors,
		calculate,
	} = useCurrentDealStore(
		(state) => ({
			ID: state.deal.ID,
			trades: state.trades,
			editTrades: state.editTrades,
			dealType: state.deal.type,
			editCarRecNum: state.editCarRecNum,
			tradeVendors: state.tradeVendors,
			lenders: state.lenders,
			calculate: state.calculate,
		}),
		shallow
	);
	const {
		inventoryID,
		stockNo,
		vin,
		year,
		make,
		model,
		trim,
		odometerIn,
		color,
		intColor,
		condition,
		transmission,
		doors,
		engine,
		cost,
		mPGHwy,
		mPGCity,
		acv,
		allowance,
		payoff,
		weight,
		titleNo,
		type,
		actualMileageFlag,
		askingPrice,
		dateIn,
		editInventoryID,
		editStockNo,
		editVin,
		editYear,
		editMake,
		editAcv,
		editAllowance,
		editPayoff,
		editModel,
		editTrim,
		editOdometerIn,
		editColor,
		editIntColor,
		editCondition,
		editCost,
		editTradeID,
		editTransmission,
		editDoors,
		editEngine,
		editMpgHwy,
		editMpgCity,
		editWeight,
		editDescription,
		editTitleNo,
		editType,
		editAskingPrice,
		editDateIn,
		editActualMileageFlag,
		colorList,
		intColorList,
		tradeLenderRecNum,
		editTradeLenderRecNum,
		currentIndex,
		setCurrentIndex,
		toggleTradeModal,
		specificationEquip,
		editSpecificationEquip,
	} = useContext(TradeContext);

	// KBB context
	const { getDcKbbObj, setDcKbbObj } = useContext(KbbContext);

	const getDeal = useBuildDeal();
	const setDeal = useSetDeal();

	useEffect(() => {
		editDateIn(today);
		// eslint-disable-next-line
	}, []);

	const clearFields = () => {
		editInventoryID(null);
		editStockNo("");
		editVin("");
		editYear("");
		editMake("");
		editAcv(0);
		editAllowance(0);
		editPayoff(0);
		editModel("");
		editTrim("");
		editOdometerIn(0);
		editColor("");
		editIntColor("");
		editCondition("");
		editCost("");
		editTradeID(null);
		editTransmission("");
		editDoors("");
		editEngine("");
		editMpgHwy("");
		editMpgCity("");
		editWeight("");
		editDescription("");
		editTitleNo("");
		editType("");
		editAskingPrice("");
		editDateIn(today);
		editActualMileageFlag(0);
		setCurrentIndex(trades.length);
		editTradeLenderRecNum(null);
		editSpecificationEquip("");
	};
	const getID = () => {
		if (trades[currentIndex] === undefined) {
			return null;
		} else if (trades[currentIndex].ID === undefined) {
			return null;
		} else {
			return trades[currentIndex].ID;
		}
	};

	const addTrade = () => {
		const tradeID = getID();
		console.log(tradeID);
		const dcKbbObj = getDcKbbObj();
		const newTrade = {
			ID: tradeID,
			dealRecNum: ID,
			tradeLenderRecNum: tradeLenderRecNum,
			allowance: parseFloat(allowance),
			payoff: parseFloat(payoff),
			acv: parseFloat(acv),
			retailBook: 0.0,
			useBlueBook: false,
			wholesaleBook: 0.0,
			currentIndex: currentIndex,
			kbb: dcKbbObj,
			car: {
				inventoryID: inventoryID,
				vin: vin,
				dateIn: dateIn,
				tradeLenderRecNum: tradeLenderRecNum,
				acv: parseFloat(acv),
				allowance: parseFloat(allowance),
				askingPrice: parseFloat(askingPrice),
				cost: parseFloat(cost),
				totalRecon: 0.0,
				flooring: 0.0,
				totalOtherCost: 0.0,
				totalCost: 0.0,
				isTradeIn: true,
				make: make,
				model: model,
				odometerIn: odometerIn,
				pack: 0.0,
				status: "Trade",
				stockNo: stockNo,
				transmission: transmission,
				year: year,
				payoff: parseFloat(payoff),
				description: "",
				createdByID: null,
				modifiedOn: null,
				modifiedByID: null,
				intColor: intColor,
				condition: condition,
				engine: engine,
				numCylinders: null,
				stockNoPrefix: null,
				stockNoSuffix: null,
				totalTaxCredit: null,
				tempPlate: null,
				mPGHwy: mPGHwy,
				mPGCity: mPGCity,
				trim: trim,
				dealerID: dealerID,
				locationID: locationID,
				doors: doors,
				color: color,
				weight: weight,
				titleNo: titleNo,
				actualMileageFlag: actualMileageFlag,
				specificationEquip: specificationEquip,
			},
		};
		console.log(newTrade);
		console.log("Current index is: " + currentIndex);
		const tradesList = () => {
			let obj = [...trades];
			obj[currentIndex] = newTrade;
			return obj;
		};
		const newTrades = tradesList();
		console.log(currentIndex);
		editTrades(newTrades);
		calculate();
		const deal = getDeal();
		deal.trades = newTrades;
		saveDeal(deal).then(
			(res) => {
				setDeal(res);
				// TODO this needs to be confirmed
				setDcKbbObj(res.data.content?.trades[currentIndex]?.kbb);
				toggleTradeModal();
				clearFields();
				saveSuccessAlert();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, (msg) => {
						Swal.fire({
							title: "Warning...",
							text: msg || "Save Unsuccessful!",
							confirmButtonText: "Ok",
						});
					});
				}
			}
		);
	};
	//get curb weight from specification Equipment
	useEffect(() => {
		let specObj = "";
		let specifications = "";
		try {
			specObj = JSON.parse(specificationEquip);
			console.log(specObj);
		} catch (e) {
			console.log(e.message);
		}
		let findWeight = "";
		if (specObj !== null && specObj !== "") {
			Object.keys(specObj).forEach((key) => {
				if (specObj[key].Key === "Measurements of Weight") {
					specifications = specObj[key].Value;
					Object.keys(specifications).forEach((key) => {
						findWeight = specifications[key];
						if (findWeight.Name === "Curb Weight") {
							editWeight(findWeight.Value);
						}
					});
				}
			});
		}
		// eslint-disable-next-line
	}, [editWeight, JSON.stringify(specificationEquip)]);

	const createColorList = (colorLists) => {
		let colorList = [];

		console.log(colorLists);
		colorLists.forEach((color) => {
			colorList.push({
				label: color,
				value: color,
			});
		});
		console.log(colorList);
		return colorList;
	};
	// Shows a list of all the exterior colors taken from VIN decoder, needs style
	const hasColor = () =>
		(colorList != null && colorList.length !== 0 && (
			<SelectorElement
				{...methods}
				name="color"
				label="Exterior Color"
				value={color}
				onChange={(e) => editColor(e.target.value)}
				options={createColorList(colorList)}
			/>
		)) || (
			<InputElement
				{...methods}
				value={color}
				name="color"
				label="Exterior Color"
				type="text"
				onChange={(e) => editColor(e.target.value)}
			/>
		);

	// Shows a list of all the interior colors taken from VIN decoder, needs style
	const hasIntColor = () =>
		(intColorList != null && intColorList.length !== 0 && (
			<SelectorElement
				{...methods}
				name="intColor"
				label="Interior Color"
				value={intColor}
				onChange={(e) => editIntColor(e.target.value)}
				options={createColorList(intColorList)}
			/>
		)) || (
			<InputElement
				{...methods}
				value={intColor}
				name="intColor"
				label="Interior Color"
				type="text"
				onChange={(e) => editIntColor(e.target.value)}
			/>
		);

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
			// eslint-disable-next-line
			min: "Must be ${min} characters or more",
			// eslint-disable-next-line
			length: "Must be ${length} numbers long",
			matches: "Only numbers and letters are allowed",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({
		make: yup.string().required().max(50),
		model: yup.string().required().max(50),
		tradeVin: yup.string().required(),
		dateIn: yup.string().required().length(10, "Required format is YYYY/MM/DD"),
		year: yup
			.string()
			.required("Required (YYYY)")
			.length(4)
			.matches(/^[0-9]*$/, "Only numbers are allowed"),
		stockNo: yup
			.string()
			.required()
			.min(1)
			.max(12)
			.matches(/^[a-zA-Z0-9]*$/),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	//Condition is Trade-in locked

	return (
		<FormProvider className="" {...methods}>
			<TradeInfo
				tradeLenderRecNum={tradeLenderRecNum}
				editTradeLenderRecNum={editTradeLenderRecNum}
				acv={acv}
				allowance={allowance}
				payoff={payoff}
				editAcv={editAcv}
				editAllowance={editAllowance}
				editPayoff={editPayoff}
				tradeVendors={lenders}
				editTradeVendors={editTradeVendors}
				type={dealType}
			/>
			<KbbValues />

			<Form>
				<InputWrapper
					formTitle="Trade In Vehicle Details"
					inputComponents={[
						<InputElement
							{...methods}
							value={stockNo}
							name="stockNo"
							label="Stock Number"
							type="text"
							onChange={(e) => editStockNo(e.target.value)}
						/>,
						<InputElement
							{...methods}
							value={vin}
							name="tradeVin"
							label="VIN"
							type="text"
							onChange={(e) => {
								editVin(e.target.value);
							}}
						/>,
						<CurrencyInputElement
							value={askingPrice}
							name="price"
							label="Price"
							type="text"
							onChange={editAskingPrice}
						/>,
						<Controller
							as={
								<FormGroup className="col-md-2">
									<Selector
										name="tradeCondition"
										selectedOption={condition}
										handleChange={editCondition}
										selectType="condition"
										selectLabel="Condition"
									/>
								</FormGroup>
							}
							name="tradeCondition"
							defaultValue={condition}
						/>,
						<Controller
							as={
								<FormGroup className="col-md-2">
									<Selector
										name="tradeType"
										selectedOption={type}
										handleChange={editType}
										selectType="vehicleType"
										selectLabel="Vehicle Type"
									/>
								</FormGroup>
							}
							name="tradeType"
							defaultValue={type}
						/>,
						<InputElement
							{...methods}
							value={year}
							name="year"
							label="Year"
							type="text"
							onChange={(e) => {
								editYear(e.target.value);
							}}
						/>,
						<InputElement
							{...methods}
							value={make}
							name="make"
							label="Make"
							type="text"
							onChange={(e) => {
								editMake(e.target.value);
							}}
						/>,
						<InputElement
							{...methods}
							value={model}
							name="model"
							label="Model"
							type="text"
							onChange={(e) => {
								editModel(e.target.value);
							}}
						/>,
						<InputElement
							{...methods}
							value={trim}
							name="trim"
							label="Trim"
							type="text"
							onChange={(e) => {
								editTrim(e.target.value);
							}}
						/>,
						<SelectorElement
							{...methods}
							name="actualMileage"
							label="Actual Mileage"
							value={actualMileageFlag}
							onChange={(e) => editActualMileageFlag(e.target.value)}
							options={ActualMileageFlagOption}
						/>,
						<IntegerInputElement
							value={odometerIn}
							name="odometerIn"
							label="Mileage"
							type="text"
							onChange={editOdometerIn}
						/>,
						<InputElement
							{...methods}
							value={transmission}
							name="transmission"
							label="Transmission"
							type="text"
							onChange={(e) => {
								editTransmission(e.target.value);
							}}
						/>,
						hasColor(),
						hasIntColor(),
						<InputElement
							{...methods}
							value={doors}
							name="doors"
							label="Doors"
							type="text"
							onChange={(e) => {
								editDoors(e.target.value);
							}}
						/>,
						<InputElement
							{...methods}
							value={engine}
							name="engine"
							label="Engine"
							type="text"
							onChange={(e) => {
								editEngine(e.target.value);
							}}
						/>,
						<InputElement
							{...methods}
							value={mPGHwy}
							name="mPGHwy"
							label="MPG HWY"
							type="text"
							onChange={(e) => {
								editMpgHwy(e.target.value);
							}}
						/>,
						<InputElement
							{...methods}
							value={mPGCity}
							name="mPGCity"
							label="MPG City"
							type="text"
							onChange={(e) => {
								editMpgCity(e.target.value);
							}}
						/>,
						<InputElement
							{...methods}
							value={weight}
							name="weight"
							label="Weight (lbs)"
							type="text"
							onChange={(e) => {
								editWeight(e.target.value);
							}}
						/>,
						<InputElement
							{...methods}
							value={titleNo}
							name="titleNo"
							label="Title Number"
							type="text"
							onChange={(e) => {
								editTitleNo(e.target.value);
							}}
						/>,
						<InputElement
							{...methods}
							value={dateIn}
							onChange={(e) => editDateIn(e.target.value)}
							name="dateIn"
							label="Date In"
							type="date"
						/>,
					]}
					buttons={
						<div style={{ display: "flex", justifyContent: "center" }}>
							<Button
								readOnly={dealType !== DealStatus.PENDING}
								onClick={methods.handleSubmit(addTrade)}
							>
								Save
							</Button>
							<Button
								readOnly={dealType !== DealStatus.PENDING}
								onClick={clearFields}
							>
								Clear
							</Button>
						</div>
					}
				/>
			</Form>
		</FormProvider>
	);
};
export default TradeForm;
