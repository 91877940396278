import React from "react";

/*
  Database values come in as comma separated string
  to populate the multiselector, the string needs to be split and then stored in the following format:
  [{ value: , label: }]
  In order to be saved in the database, they have to be in the comma separated format again.
*/
const InventoryOptionalEquipment = ({
	changeHandler,
	optionalEquip,
	selectedEquip,
	disabled,
}) => {
	if (!optionalEquip) {
		return null; // or return <div>No options available</div> or similar
	}

	let d = disabled;
	if (optionalEquip === "" || optionalEquip === null) {
		d = true;
	}

	// Convert selectedEquip to an array
	console.log(optionalEquip);
	console.log(selectedEquip);
	const selectedEquipArray = selectedEquip ? selectedEquip.split(",") : []; // split with comma and space

	// Create Sets for optionalEquip and selectedEquipArray
	let optionalEquipSet = new Set(optionalEquip.map((item) => item.value));
	let selectedEquipSet = new Set(selectedEquipArray);

	// Combine Sets
	let combinedEquipSet = new Set([...optionalEquipSet, ...selectedEquipSet]);

	// Now, we need to build our new optionalEquip array from the combined set.
	// This includes all existing optional equipment, plus any new ones from selectedEquipArray
	// We assume that new equipment from selectedEquipArray use their value as their label
	optionalEquip = Array.from(combinedEquipSet).map((value) => {
		const existingEquipment = optionalEquip.find(
			(item) => item.value === value
		);
		return existingEquipment ? existingEquipment : { value, label: value };
	});
	const handleCheckboxChange = (event, value) => {
		const newSelectedEquipArray = event.target.checked
			? [...selectedEquipArray, value]
			: selectedEquipArray.filter((item) => item !== value);
		console.log(newSelectedEquipArray.join(","));
		// Convert the array back to a string
		changeHandler(newSelectedEquipArray.join(",")); // join with comma and space
	};
	const columnSize = optionalEquip.length / 4;
	const columns = [];
	for (let i = 0; i < optionalEquip.length; i += columnSize) {
		columns.push(optionalEquip.slice(i, i + columnSize));
	}
	console.log(columns);
	return (
		<div>
			<div className="checkbox-columns">
				{columns.map((column, columnIndex) => (
					<div key={`column-${columnIndex}`} className="checkbox-column">
						{column.map((option, index) => (
							<div key={index} className="checkbox-item">
								<div>
									<input
										type="checkbox"
										id={`${option.value}-${columnIndex}-${index}`}
										value={option.value}
										disabled={d}
										checked={selectedEquipArray.includes(option.value)}
										onChange={(e) => handleCheckboxChange(e, option.value)}
									/>
								</div>
								<label htmlFor={`${option.value}-${columnIndex}-${index}`}>
									{option.label}
								</label>
							</div>
						))}
					</div>
				))}
			</div>
		</div>
	);
};

export default InventoryOptionalEquipment;
