import React, { useContext } from "react";
import { Card, CardHeader, CardTitle, Col, Row } from "reactstrap";
import KbbLogo from "../../../../../assets/img/KbbLogo.png";

import { KbbContext } from "./KbbContext";
import NumberFormat from "react-number-format";

const KbbValues = () => {
	// KBB context
	const { dcTableKbbValues } = useContext(KbbContext);
	const lending =
		dcTableKbbValues.find((obj) => obj.priceTypeId === 1)?.configuredValue ||
		"";
	const typicalListing =
		dcTableKbbValues.find((obj) => obj.priceTypeId === 2)?.configuredValue ||
		"";
	return (
		<>
			<Row>
				<Col md="6">
					<Card className="pb-3">
						<img src={KbbLogo} alt="kbb badge" className="kbbLogo" />
						<CardHeader>
							<CardTitle>
								<h3>Lending Value</h3>
							</CardTitle>
						</CardHeader>
						<Col className="col-12 text-center">
							<NumberFormat
								className="display-4 text-center text-success"
								value={lending}
								thousandSeparator={true}
								decimalScale={0}
								fixedDecimalScale={true}
								prefix={"$"}
								isNumericString={true}
								displayType="text"
							/>
						</Col>
					</Card>
				</Col>
				<Col md="6">
					<Card className="pb-3">
						<img src={KbbLogo} alt="kbb badge" className="w-25 kbbLogo" />
						<CardHeader>
							<CardTitle>
								<h3>Typical Listing Price</h3>
							</CardTitle>
						</CardHeader>
						<Col className="ml-auto mr-auto col-md-8 text-center">
							<NumberFormat
								className="display-4 text-center text-success"
								value={typicalListing}
								thousandSeparator={true}
								decimalScale={0}
								fixedDecimalScale={true}
								prefix={"$"}
								isNumericString={true}
								displayType="text"
							/>
						</Col>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default KbbValues;
