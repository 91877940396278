import classNames from "classnames";
import React, { useState } from "react";
import { CardBody, Card } from "reactstrap";
import BuyerHeader from "../../component/dc/deal/customer/buyer/BuyerHeader";
import CoBuyerHeader from "../../component/dc/deal/customer/coBuyer/CoBuyerHeader";

const DealBuyerSwitchButton = ({ saleType }) => {
	console.log(saleType);
	const [switchBuyer, setSwitchBuyer] = useState(0);
	return (
		<div>
			<Card className="pb-2">
				<div role="group" className="btn-group pb-2 px-3 pt-3">
					{saleType === 1 || saleType === 2 ? (
						<button
							type="button"
							className={classNames(
								"px-1 btn-round btn-md btn btn-outline-secondary",
								{
									"-active": switchBuyer === 0,
								}
							)}
							onClick={() => setSwitchBuyer(0)}
						>
							Business
						</button>
					) : (
						<>
							<button
								type="button"
								className={classNames(
									"px-1 btn-round btn-md btn btn-outline-secondary",
									{
										"-active": switchBuyer === 0,
									}
								)}
								onClick={() => setSwitchBuyer(0)}
							>
								Buyer
							</button>
							<button
								type="button"
								className={classNames(
									"px-1 btn-round btn-md btn btn-outline-secondary",
									{
										"-active": switchBuyer === 1,
									}
								)}
								onClick={() => setSwitchBuyer(1)}
							>
								CoBuyer
							</button>
						</>
					)}
				</div>
				<CardBody className="pt-0">
					{switchBuyer === 0 ? <BuyerHeader /> : <CoBuyerHeader />}
				</CardBody>
			</Card>
		</div>
	);
};

export default DealBuyerSwitchButton;
