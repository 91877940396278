import React, { useEffect, useState } from "react";
import { submitPublicPrescreen } from "../../api/dealerIntegrationsApi";
import { credit700Bureaus } from "../../Constants";
import Experian from "assets/img/experianLogo.png";
import Equifax from "assets/img/equifaxLogo.png";
import Transunion from "assets/img/transUnionLogo.png";
import { saveLead } from "../../api/LeadApi";
import { saveCreditApp } from "../../api/CreditAppApi";
import { Button, Card, Col, Row } from "reactstrap";
import Swal from "sweetalert2";
import { saveFailAlert } from "../../component/dc/modal/SweetAlerts";
import { showApiError } from "../../helperFunction/ApiErrorHandler";

const Credit700PreScreen = ({ bureau, lead, creditApp, toggle }) => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [address, setAddress] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [zip, setZip] = useState("");
	const [selectedOption, setSelectedOption] = useState(bureau);

	const dealerInfo = JSON.parse(sessionStorage.getItem("user"));
	const dealerID = dealerInfo.dealerID;
	const locationID = dealerInfo.locationID;

	const submit = () => {
		sendingApp();
		const prescreen = {
			firstName,
			lastName,
			address,
			city,
			state,
			zip,
			bureau: selectedOption,
			dealerID,
			locationID,
		};
		console.log(prescreen);
		submitPublicPrescreen(prescreen).then(
			(response) => {
				const res = response.data.content;
				let apiCall = () => {};
				let obj = {};

				if (lead) {
					obj = { ...lead };
					apiCall = saveLead;
				} else if (creditApp) {
					obj = { ...creditApp };
					apiCall = saveCreditApp;
				}

				if (bureau === credit700Bureaus.equifax) {
					obj.efxScore = res.score;
				} else if (bureau === credit700Bureaus.experian) {
					obj.xpnScore = res.score;
				} else if (bureau === credit700Bureaus.transUnion) {
					obj.tuScore = res.score;
				}
				obj.credit700Url = res.url;
				apiCall(obj).then(
					() => {
						toggle();
					},
					() => {
						toggle();
					}
				);
				Swal.close();
				window.open(res.url);
			},
			(err) => {
				Swal.close();
				console.log(err.repsonse);
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				} else {
					saveFailAlert("An unexpected error occurred");
				}
			}
		);
	};

	useEffect(() => {
		console.log({ lead, creditApp });
		if (lead) {
			setFirstName(lead.firstName);
			setLastName(lead.lastName);
			setAddress(lead.address);
			setCity(lead.city);
			setState(lead.state);
			setZip(lead.zipCode);
		} else if (creditApp) {
			setFirstName(creditApp.firstName);
			setLastName(creditApp.lastName);
			setAddress(creditApp.homeAddress);
			setCity(creditApp.homeCity);
			setState(creditApp.homeState);
			setZip(creditApp.homeZipCode);
		}
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<Card>
				<div className="radio bg-light pb-2 pt-3">
					<Row className="px-0 text-center">
						<Col>
							<label className="col-xl-6">
								<input
									type="radio"
									value={credit700Bureaus.experian}
									checked={selectedOption === credit700Bureaus.experian}
									onChange={(e) => {
										setSelectedOption(e.target.value);
									}}
									src={Experian}
								/>
								<img src={Experian} alt="Experian" className="rounded" />
							</label>
						</Col>
						<Col>
							<label className="col-xl-6">
								<input
									type="radio"
									value={credit700Bureaus.transUnion}
									checked={selectedOption === credit700Bureaus.transUnion}
									onChange={(e) => {
										setSelectedOption(e.target.value);
									}}
								/>
								<img src={Transunion} alt="transunion" className="rounded " />
							</label>
						</Col>
						<Col>
							<label className="col-xl-6">
								<input
									type="radio"
									value={credit700Bureaus.equifax}
									checked={selectedOption === credit700Bureaus.equifax}
									onChange={(e) => {
										setSelectedOption(e.target.value);
									}}
									src={Equifax}
								/>
								<img src={Equifax} alt="Equifax" className="rounded" />
							</label>
						</Col>
					</Row>
				</div>

				<Row className="px-0 text-center my-3">
					<div className="col">
						<Button color="primary" onClick={submit}>
							Submit
						</Button>
					</div>
				</Row>
			</Card>
		</>
	);
};

export default Credit700PreScreen;

const sendingApp = () => {
	Swal.fire({
		title: "Sending Prescreen...",
		showConfirmButton: false,
		willOpen() {
			Swal.showLoading();
		},
		didClose() {
			Swal.hideLoading();
		},
		allowOutsideClick: false,
		allowEscapeKey: false,
		allowEnterKey: false,
	});
};
