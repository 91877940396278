import React, { useState, useEffect } from "react";
import { Button, Col, Input, ModalFooter, Table } from "reactstrap";
import Loading from "component/dc/load/Loading";
import { showApiError } from "../../helperFunction/ApiErrorHandler";
import {
	saveFailAlert,
	retrieveFailAlert,
	saveSuccessAlert,
} from "../../component/dc/modal/SweetAlerts";
import { useFromUser } from "store/LocalStorageHelper";
import { getInventoryFeed, updateSendFeed } from "../../api/InventoryAPI";

export const InventoryAdvertisingModal = ({ toggle, sendVariable }) => {
	const locationID = useFromUser("locationID");
	const [data, setData] = useState([]);
	const [loadingList, setLoadingList] = useState(true);
	const [sendFeed, setSendFeed] = useState([]);
	const [checkedAll, setCheckedAll] = useState(false);

	const getSendFeed = (content) => {
		console.log(content);
		const Items = content.map((obj) => {
			const sendValue = obj[sendVariable];
			console.log(sendValue);
			const ID = obj.inventoryID;
			return { sendValue, ID };
		});
		return Items;
	};
	const manageFeed = () => {
		const Items = sendFeed;
		const listVehicleFeed = { sendVariable, Items };
		updateSendFeed(locationID, listVehicleFeed).then(
			(res) => {
				console.log(listVehicleFeed);
				console.log(res.data.content);
				saveSuccessAlert();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};
	const selectAll = (value) => {
		setCheckedAll(value);
		console.log(value);
		setSendFeed(() => {
			const newState = [...sendFeed];
			console.log(newState);
			for (let i = 0; i < newState.length; i++) {
				if (value === true) {
					newState[i].sendValue = 1;
				} else if (value === false) {
					newState[i].sendValue = 0;
				}
			}
			console.log(newState);
			return newState;
		});
	};

	// Headers
	const headers = ["ID", "Stock", "Year", "Make", "Model", "Vin"];
	//  table headers
	const head = headers.map((key) => <th key={key}>{key}</th>);

	// row items
	const rows = data.map((key, index) => (
		<tr key={index}>
			<th scope="row">{index + 1}</th>
			<td>{key.stockNo}</td>
			<td>{key.year}</td>
			<td>{key.make}</td>
			<td>{key.model}</td>
			<td>{key.vin}</td>
			<td className="form-check-input">
				<Input
					type="checkbox"
					onChange={(e) => {
						console.log(sendFeed);
						let tempVal = e.target.checked ? 1 : 0;
						console.log(sendFeed);
						setSendFeed((prev) => {
							let newState = [...prev];
							newState[index].sendValue = tempVal;
							return newState;
						});
					}}
					checked={sendFeed[index].sendValue}
					style={{ width: "20px", height: "20px" }}
				/>
			</td>
		</tr>
	));
	const getListData = () => {
		console.log("ID: " + locationID);
		if (locationID !== "") {
			getInventoryFeed(locationID).then(
				(response) => {
					console.log(response.data.content);
					const Items = getSendFeed(response.data.content);
					console.log(Items);
					setSendFeed(Items);
					setData(response.data.content);
					setLoadingList(false);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}
	};
	useEffect(() => {
		getListData();
		// eslint-disable-next-line
	}, []);
	return (
		<div>
			{loadingList && <Loading />}

			<Col>
				<Table striped hover>
					<thead>
						<tr>
							{head}
							<th>
								<input
									type="checkbox"
									defaultChecked={checkedAll}
									onChange={(e) => {
										selectAll(e.target.checked);
									}}
								/>
								<label>
									Check/<br></br>Uncheck All{" "}
								</label>
							</th>
						</tr>
					</thead>
					<tbody>{rows}</tbody>
				</Table>
				<ModalFooter>
					<Button
						style={{
							justifyContent: "center",
						}}
						className=" btn-round btn-bg "
						color="success"
						onClick={() => {
							manageFeed();
							toggle();
						}}
					>
						<i className="nc-icon nc-bookmark-2"></i>&nbsp;Save
					</Button>
				</ModalFooter>
			</Col>
		</div>
	);
};
