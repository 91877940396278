import React, { useState } from "react";
import { Row } from "reactstrap";
import ChildrenModal from "component/dc/modal/ChildrenModal";
import OpenModalButton from "../../OpenModalButton";
import CustomerDealDetails from "../CustomerDealDetails";
import CustomerListPage from "view/contact/CustomerListPage";
import ContactAddPage from "view/contact/CustomerAddPage";
import { failAlert } from "../../../modal/SweetAlerts";
import { DealStatus } from "Constants";
import IDPhoto from "../../../../../assets/img/imgPlaceholder.png";
import shallow from "zustand/shallow";
import useCurrentDealStore from "../../../../../store/DealStore";

const CoBuyerHeader = () => {
	// Deal store
	const { coBuyer } = useCurrentDealStore(
		(state) => ({
			coBuyer: state.coBuyer,
		}),
		shallow
	);

	const { ID, firstName, lastName } = coBuyer || "";

	return (
		<>
			{(ID == null && (
				<Row>
					<CoBuyerModal />
				</Row>
			)) || (
				<Row className="mx-0">
					<div className="mx-auto w-100">
						<h4 className="text-center mb-2 mt-0">
							{firstName + " " + lastName}
						</h4>
						<img
							className="customer-profile pt-1 pb-0"
							src={IDPhoto}
							alt="Co-Buyer ID Card"
						/>
						<CoBuyerModal />
					</div>
				</Row>
			)}
		</>
	);
};

export default CoBuyerHeader;

const CoBuyerModal = () => {
	// Local state
	const [addModal, setAddModal] = useState(false);
	const [editModal, setEditModal] = useState(false);
	const [newModal, setNewModal] = useState(false);

	// Deal store
	const {
		buyer,
		coBuyerRecNum,
		type,
		editCoBuyer,
		editCoBuyerRecNum,
		editCoBuyerAddress,
		editCoBuyerMailAddress,
	} = useCurrentDealStore(
		(state) => ({
			buyer: state.buyer,
			coBuyer: state.coBuyer,
			coBuyerRecNum: state.deal.coBuyerRecNum,
			type: state.deal.type,
			editCoBuyer: state.editCoBuyer,
			editCoBuyerRecNum: state.editCoBuyerRecNum,
			editCoBuyerAddress: state.editCoBuyerAddress,
			editCoBuyerMailAddress: state.editCoBuyerMailAddress,
		}),
		shallow
	);

	// Modal toggle functions
	const toggleAdd = () => {
		if (!buyer.ID) {
			failAlert("Must add Buyer before entering Co-Buyer");
		} else {
			setAddModal(!addModal);
		}
	};

	const toggleEdit = () => {
		setEditModal(!editModal);
	};
	const toggleNew = () => {
		if (!buyer.ID) {
			failAlert("Must add Buyer before entering Co-Buyer");
		} else {
			setNewModal(!newModal);
		}
	};

	const toggleDelete = () => {
		editCoBuyerRecNum(null);
		editCoBuyer({});
	};

	// Update deal store with new customer's info
	const updateNewCustomer = (data) => {
		editCoBuyerRecNum(data.customer.ID);
		editCoBuyer(data.customer);
		editCoBuyerAddress(data.currentAddress);
		editCoBuyerMailAddress(data.mailingAddress);
		toggleNew();
	};

	return (
		<>
			{(type === DealStatus.PENDING && coBuyerRecNum == null && (
				<div className="mx-auto">
					<OpenModalButton
						buttonClass="btn-sm"
						buttonColor="secondary"
						onClick={toggleNew}
						buttonText="Add"
						iconClass="nc-icon nc-simple-add"
					/>
					<OpenModalButton
						buttonClass="btn-sm"
						buttonColor="secondary"
						onClick={toggleAdd}
						buttonText="Select"
						icon={true}
						iconClass="nc-icon nc-tap-01"
					/>
				</div>
			)) ||
				(coBuyerRecNum !== null && (
					<>
						<div className="mx-auto">
							<OpenModalButton
								buttonClass="btn-sm btn-primary"
								onClick={toggleEdit}
								buttonText={type === DealStatus.PENDING ? "Edit" : "View"}
								iconClass="nc-icon nc-align-center"
							/>
							{type === DealStatus.PENDING && (
								<OpenModalButton
									buttonClass="btn-link btn-sm"
									onClick={toggleDelete}
									buttonColor="danger"
									iconClass="nc-icon nc-simple-remove"
									buttonText="Remove"
								/>
							)}
						</div>
					</>
				))}
			<ChildrenModal
				modal={newModal}
				toggle={toggleNew}
				modalTitle="Add New Contact"
				children={
					<ContactAddPage
						updateDeal={updateNewCustomer}
						isDeal={true}
						isBuyer={false}
					/>
				}
			/>
			<ChildrenModal
				modal={addModal}
				toggle={toggleAdd}
				modalTitle="Select Contact"
				children={
					<CustomerListPage
						isDeal={true}
						toggle={toggleAdd}
						editCustomer={editCoBuyer}
						editAddress={editCoBuyerAddress}
						editMailAddress={editCoBuyerMailAddress}
						editRecNum={editCoBuyerRecNum}
					/>
				}
			/>
			<ChildrenModal
				modal={editModal}
				toggle={toggleEdit}
				modalTitle="Edit Current Contact"
				children={
					<CustomerDealDetails
						ID={coBuyerRecNum}
						editCustomer={editCoBuyer}
						editAddress={editCoBuyerAddress}
						editMailAddress={editCoBuyerMailAddress}
						editRecNum={editCoBuyerRecNum}
						toggle={toggleEdit}
						cloneCustomer={false}
					/>
				}
			/>
			{type !== DealStatus.PENDING && coBuyerRecNum == null && (
				<p className="d-flex justify-content-center required">
					No Co-buyer was added to this deal
				</p>
			)}
		</>
	);
};
