import React from "react";
import { Card, CardBody, CardText, Col, Label, Row, Input } from "reactstrap";
import useCurrentSaleStore from "store/SaleStore";
import useCurrentDealStore from "store/DealStore";
import NumberFormat from "react-number-format";
import ChildrenModal from "component/dc/modal/ChildrenModal";
import shallow from "zustand/shallow";
import SummaryTax from "./SummaryTax";
import SellerFeeTax from "./SellerFeeTax";
import DmvFeeTax from "./DmvFeeTax";
import AftermarketTax from "./AftermarketTax";

const Percent = ({ value, numDigit = 2 }) => {
	return (
		<NumberFormat
			value={value * (numDigit === 2 || numDigit === 4 ? 100 : 1)}
			decimalScale={numDigit}
			fixedDecimalScale={true}
			suffix={numDigit === 2 || numDigit === 4 ? "%" : ""}
			isNumericString={true}
			displayType="text"
		/>
	);
};

const Currency = ({ value }) => {
	//	const PATTERN1 = "#,##0.00;(#,##0.00)";
	// const PATTERN2 = "$#,##0.00;-$#,##0.00";
	// const PATTERN3 = "#,##0.00;(#,##0.00)";
	// NumberFormat.apply(PATTERN1);

	return (
		<NumberFormat
			value={value}
			thousandSeparator={true}
			decimalScale={2}
			fixedDecimalScale={true}
			prefix={"$"}
			isNumericString={true}
			displayType="text"
			//			format={PATTERN1}
		/>
	);
};

const TaxModal = ({ modal, toggle }) => {
	const {
		taxRate,
		price,
		totalDMVFees,
		gap,
		//collisionTotPremium,
		warranty,
		dealerSmog,
		docFee,
		aftmktTaxable,
		trade,
		taxableSellerFees,
		vit,
		tradeTaxCredit,
	} = useCurrentSaleStore(
		(state) => ({
			stateSmog: state.stateSmog,
			tax: state.tax,
			taxRate: state.taxRate,
			price: state.price,
			totalDMVFees: state.totalDMVFees,
			gap: state.gap,
			//collisionTotPremium: state.collisionTotPremium,
			warranty: state.warranty,
			dealerSmog: state.dealerSmog,
			docFee: state.docFee,
			aftmktTaxable:
				state.aftmkt - state.aftmktNonTaxable > 0
					? state.aftmkt - state.aftmktNonTaxable
					: 0,
			trade: state.trade,
			taxableAmount: state.taxableAmount,
			totalTaxable: state.totalTaxable,
			vit: state.vit,

			taxableSellerFees:
				state.totalSellerFees -
					state.totalNonTaxableSellerFees -
					state.dealerSmog -
					state.docFee -
					state.stateSmog >
				0
					? state.totalSellerFees -
					  state.totalNonTaxableSellerFees -
					  state.dealerSmog -
					  state.docFee -
					  state.stateSmog
					: 0,
			tradeTaxCredit: state.tradeTaxCredit,
		}),
		shallow
	);
	const {
		gapTaxRate,
		docFeeTaxRate,
		dmvTaxRate,
		servContractTaxRate,
		dealerSmogTaxRate,
		aftmktTaxRate,
		taxCreditRate,
		miscDMVFees,
		// businessTaxRate,
		// luxuryTaxRate,
		vitRate,
	} = useCurrentDealStore(
		(state) => ({
			gapTaxRate: state?.deal?.dealSettings?.taxDefaults?.gapTaxRate,
			docFeeTaxRate: state?.deal?.dealSettings?.taxDefaults?.docFeeTaxRate,
			dmvTaxRate: state?.deal?.dealSettings?.taxDefaults?.dmvTaxRate,
			servContractTaxRate:
				state?.deal?.dealSettings?.taxDefaults?.servContractTaxRate,
			dealerSmogTaxRate:
				state?.deal?.dealSettings?.taxDefaults?.dealerSmogTaxRate,
			aftmktTaxRate: state?.deal?.dealSettings?.taxDefaults?.aftmktTaxRate,
			taxCreditRate: state?.deal?.dealSettings?.taxDefaults?.taxCreditRate,

			// businessTaxRate: state?.deal?.dealSettings?.taxDefaults?.businessTaxRate,
			// luxuryTaxRate: state?.deal?.dealSettings?.taxDefaults?.luxuryTaxRate,
			miscDMVFees: state.miscDMVFees,
			vitRate: state?.deal?.dealSettings?.taxDefaults?.vitRate,
		}),
		shallow
	);

	const priceTaxes = price * taxRate;
	const gapTaxes = gap * (gapTaxRate !== -1 ? gapTaxRate : taxRate);
	// const collisionTaxes =
	// 	collisionTotPremium * (gapTaxRate !== -1 ? gapTaxRate : taxRate);
	const warrantyTaxes =
		warranty * (servContractTaxRate !== -1 ? servContractTaxRate : taxRate);
	const smogTaxes =
		dealerSmog * (dealerSmogTaxRate !== -1 ? dealerSmogTaxRate : taxRate);
	const docTaxes = docFee * (docFeeTaxRate !== -1 ? docFeeTaxRate : taxRate);
	const sellerFeesTaxes = taxableSellerFees * taxRate;

	const DMVTaxes = totalDMVFees * (dmvTaxRate !== -1 ? dmvTaxRate : taxRate);
	let taxableDMVFees = 0;
	miscDMVFees.forEach((element) => {
		taxableDMVFees += element.isTaxable ? parseFloat(element.amount) : 0;
		console.log(element.isTaxable + " MiscDMV = " + parseFloat(element.amount));
	});
	console.log(taxableDMVFees);
	const dmvFeesTaxes = taxableDMVFees * (dmvTaxRate > 0 ? dmvTaxRate : taxRate);
	console.log(dmvTaxRate > 0 ? dmvTaxRate : taxRate);
	console.log(taxRate);
	console.log(dmvTaxRate);
	console.log(dmvFeesTaxes);
	let aftmktTaxes = 0;

	if (aftmktTaxable > 0) {
		aftmktTaxes =
			aftmktTaxable * (aftmktTaxRate !== -1 ? aftmktTaxRate : taxRate);
	}

	console.log(trade, taxCreditRate, taxRate, tradeTaxCredit);
	//	const VITTaxes = price * vitRate;
	// const luxuryTaxes = price * luxuryTaxRate;
	// const businessTaxes = price * businessTaxRate;

	//LocalSale.VIT = LocalSale.Price * LocalSale.vitRate
	const rows = [
		{
			taxableAmount: price,
			taxValue: priceTaxes,
			taxRate: taxRate,
			label: "Price",
			taxable: true,
		},
		{
			taxableAmount: totalDMVFees - taxableDMVFees,
			taxValue: DMVTaxes,
			taxRate: dmvTaxRate !== -1 ? dmvTaxRate : taxRate,
			label: "DMV",
			taxable: dmvTaxRate !== 0 ? true : false,
		},
		{
			taxableAmount: gap,
			taxValue: gapTaxes,
			taxRate: gapTaxRate !== -1 ? gapTaxRate : taxRate,
			label: "Gap",
			taxable: gapTaxRate !== 0 ? true : false,
		},
		// {
		// 	taxableAmount: collisionTotPremium,
		// 	taxValue: collisionTaxes,
		// 	taxRate: gapTaxRate !== -1 ? gapTaxRate : taxRate,
		// 	label: "collision",
		// 	taxable: gapTaxRate !== 0 ? true : false,
		// },

		{
			taxableAmount: warranty,
			taxValue: warrantyTaxes,
			taxRate: servContractTaxRate !== -1 ? servContractTaxRate : taxRate,
			label: "Warranty",
			taxable: warrantyTaxes !== 0 ? true : false,
		},
		{
			taxableAmount: dealerSmog,
			taxValue: smogTaxes,
			taxRate: dealerSmogTaxRate !== -1 ? dealerSmogTaxRate : taxRate,
			label: "Smog",
			taxable: dealerSmogTaxRate !== 0 ? true : false,
		},
		{
			taxableAmount: docFee,
			taxValue: docTaxes,
			taxRate: docFeeTaxRate !== -1 ? docFeeTaxRate : taxRate,
			label: "Doc Fee",
			taxable: docFeeTaxRate !== 0 ? true : false,
		},
		{
			taxableAmount: aftmktTaxable,
			taxValue: aftmktTaxes,
			taxRate: aftmktTaxRate !== -1 ? aftmktTaxRate : taxRate,
			label: "After market",
			taxable: aftmktTaxRate !== 0 ? true : false,
		},
		{
			taxableAmount: tradeTaxCredit,
			taxValue: -1 * tradeTaxCredit * taxRate,
			taxRate: taxRate,
			label: "Trade-in Credit",
			taxable: true,
		},
		{
			taxableAmount: taxableSellerFees,
			taxValue: sellerFeesTaxes,
			taxRate: taxRate,
			label: "Seller Fees",
			taxable: true,
		},
		{
			taxableAmount: taxableDMVFees,
			taxValue: dmvFeesTaxes,
			taxRate: dmvTaxRate !== -1 && dmvTaxRate !== 0 ? dmvTaxRate : taxRate,
			label: "DMV Fees",
			taxable: true,
		},
		{
			taxableAmount: price,
			taxValue: vit,
			taxRate: vitRate,
			label: "VIT",
			taxable: true,
		},
	];

	return (
		<ChildrenModal
			modal={modal}
			toggle={toggle}
			size="lg"
			modalTitle="Tax Breakdown"
			children={
				<Row>
					<Col className="pt-2">
						<SummaryTax />
						<Card className="py-2">
							<CardBody>
								<Row>
									<div className="col"></div>
									<div className="col">
										<Label>Taxable</Label>
									</div>
									<div className="col">
										<Label>Taxable Amount</Label>
									</div>

									<div className="col">
										<Label>Tax Rate</Label>
									</div>
									<div className="col">
										<Label>Tax</Label>
									</div>
								</Row>
								<br />
								{rows.map(
									(element) =>
										(element.label === "VIT" && element.taxRate > 0) ||
										(element.label !== "VIT" && (
											<>
												<Row>
													<Col>
														<Label>{element.label}</Label>
													</Col>
													<Col>
														<CardText className="px-5">
															<Input
																type="checkbox"
																checked={element.taxable}
															/>
														</CardText>
													</Col>
													<Col>
														<CardText>
															<Currency value={element.taxableAmount} />
														</CardText>
													</Col>
													<Col>
														<CardText>
															<Percent
																value={element.taxRate}
																numDigit={element.label === "VIT" ? 8 : 4}
															/>
														</CardText>
													</Col>
													<Col>
														<CardText>
															<Currency value={element.taxValue} />
														</CardText>
													</Col>
												</Row>
												<hr />
											</>
										))
								)}
							</CardBody>
						</Card>
						<SellerFeeTax />
						<DmvFeeTax />
						<AftermarketTax />
					</Col>
				</Row>
			}
		/>
	);
};

export default TaxModal;
