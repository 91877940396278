import React from "react";
import FormsReportsList from "component/dc/formsReports/FormsReportsList";
import { getAllInventoryForms } from "../../../api/FormsAPI";
import { useFromUser } from "../../../store/LocalStorageHelper";

const InventoryFormsTab = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const params = { dealerID, locationID };
	return (
		<FormsReportsList
			listTitle="Inventory Forms"
			apiFunction={getAllInventoryForms}
			apiParams={params}
			searchable={true}
			isInv={true}
		/>
	);
};

export default InventoryFormsTab;
