import React, { useState } from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import CustomerInsurance from "./CustomerInsurance";
import ChildrenModal from "../../../../component/dc/modal/ChildrenModal";
import * as yup from "yup";
import { setLocale } from "yup";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { DealStatus } from "Constants";
import shallow from "zustand/shallow";
import useCurrentDealStore from "../../../../store/DealStore";

const CustomerInsuranceTab = () => {
	const [showList, setShowList] = useState(false);
	const toggleShowList = () => setShowList(!showList);

	// Deal store
	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
			length: "Required format is YYYY/MM/DD",
		},
	});

	// Define rules for each input field
	//  **NOTE** -> Because of ReactNumberFormat library, the name value which
	// the validation library gets is different from actual value of the field.
	// We use the name value (which is formatted) for validation, and actual
	// value for saving (which is unformatted)
	const schema = yup.object().shape({
		insuranceCompany: yup.string().required().max(50),
		insurancePolicyNum: yup.string().required().max(50),
		insuranceStartDate: yup.string().length(10),
		insuranceEndDate: yup.string().length(10),
		insuranceDeductible: yup.string().required().max(50),
		insuranceAgentName: yup.string().required().max(50),
		insuranceAgentWorkNum: yup.string().required().max(50),
		insuranceAgentWorkExt: yup.string().required().max(6),
		insuranceAgentCellNum: yup
			.string()
			.matches(/^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, {
				message: "Invalid Phone Number ",
			}),
		insuranceAgentAddress: yup.string().required().max(50),
		insuranceAgentCity: yup.string().required().max(50),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<>
			<FormProvider {...methods}>
				<div className="col">
					<CustomerInsurance />
				</div>
			</FormProvider>
			<ChildrenModal
				modalTitle="Insurance"
				modal={showList}
				toggle={toggleShowList}
			>
				<Card>
					<CardBody>
						<CardTitle className="col text-center">
							<h3 className="mb-0">Need Insurance?</h3>
							<p className="mt-0 pt-0">
								Quickly get a quote from one of our awesome partners.
							</p>
						</CardTitle>
						<div className="col text-center">
							<div className="col mt-2">
								<a
									href="https://libertymutual.com"
									rel="noopener noreferrer"
									target="_blank"
								>
									<button className="btn btn-round btn-md btn-warning mx-1">
										Liberty Mutual
									</button>
								</a>
								<a
									href="https://statefarm.com"
									rel="noopener noreferrer"
									target="_blank"
								>
									<button className="btn btn-round btn-md btn-danger mx-1">
										State Farm
									</button>
								</a>
								<a
									href="https://progressive.com"
									rel="noopener noreferrer"
									target="_blank"
								>
									<button className="btn btn-round btn-md btn-info mx-1">
										Progressive
									</button>
								</a>
							</div>
						</div>
					</CardBody>
				</Card>
			</ChildrenModal>
			<div className="col pb-3">
				<div className="row mx-0">
					<div className="col-md-6">
						<Button
							className="btn btn-primary btn-md"
							onClick={toggleShowList}
							readOnly={type !== DealStatus.PENDING}
						>
							<i className="nc-icon nc-simple-add"></i> Get Insurance
						</Button>
					</div>
					<div className="col-md-6 text-right"></div>
				</div>
			</div>
		</>
	);
};

export default CustomerInsuranceTab;
