import React from "react";
import Select from "react-select";
import {
	Form,
	FormGroup,
	Label,
	Col,
	Row,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
} from "reactstrap";
import { useForm, FormProvider } from "react-hook-form";

import useCurrentDealStore from "../../../../../../store/DealStore";
import { DealStatus } from "Constants";
import shallow from "zustand/shallow";
import LabelAndInput from "../../LabelAndInput";
import useCurrentSaleStore from "store/SaleStore";
import { DisplayCurrency } from "component/dc/deal/review/Review";
import InputElement from "view/formValidation/InputElement";
import { IntegerInputElement } from "view/formValidation/InputElement";
import { CurrencyInput } from "../../../../../../view/formValidation/InputElement";
import useCurrentRecapStore from "../../../../../../store/RecapStore";

const CollisionForm = () => {
	// Sale Store
	const { collisionTotPremium } = useCurrentSaleStore(
		(state) => ({
			collisionTotPremium: state.collisionTotPremium,
			editCollisionTotPremium: state.editCollisionTotPremium,
		}),
		shallow
	);

	// Deal Store
	const {
		term,
		coRecNum,
		appNumber,
		type,
		bodyInjuryLimit,
		bodyInjuryPremium,
		collisionDeductible,
		collisionPremium,
		compDeductible,
		compPremium,
		medicalLimit,
		medicalPremium,
		otherLimit,
		otherPremium,
		propertyLimit,
		propertyPremium,
		editCoRecNum,
		editTerm,
		editAppNumber,
		editBodyInjuryLimit,
		editBodyInjuryPremium,
		editCollisionDeductible,
		editCollisionPremium,
		editCompDeductible,
		editCompPremium,
		editMedicalLimit,
		editMedicalPremium,
		editOtherLimit,
		editOtherPremium,
		editPropertyLimit,
		editPropertyPremium,
		insuranceCompanies,
	} = useCurrentDealStore(
		(state) => ({
			coRecNum: state?.collisionInsurance?.coRecNum,
			editCoRecNum: state?.editCoRecNum,
			appNumber: state?.collisionInsurance?.appNumber,
			type: state?.deal.type,
			bodyInjuryLimit: state?.collisionInsurance?.bodyInjuryLimit,
			bodyInjuryPremium: state?.collisionInsurance?.bodyInjuryPremium,
			collisionDeductible: state?.collisionInsurance?.collisionDeductible,
			collisionPremium: state?.collisionInsurance?.collisionPremium,
			compDeductible: state?.collisionInsurance?.compDeductible,
			compPremium: state?.collisionInsurance?.compPremium,
			medicalLimit: state?.collisionInsurance?.medicalLimit,
			medicalPremium: state?.collisionInsurance?.medicalPremium,
			otherLimit: state?.collisionInsurance?.otherLimit,
			otherPremium: state?.collisionInsurance?.otherPremium,
			propertyLimit: state?.collisionInsurance?.propertyLimit,
			propertyPremium: state?.collisionInsurance?.propertyPremium,
			term: state?.collisionInsurance?.term,
			editTerm: state?.editTerm,
			editAppNumber: state?.editAppNumber,
			editBodyInjuryLimit: state?.editBodyInjuryLimit,
			editBodyInjuryPremium: state?.editBodyInjuryPremium,
			editCollisionDeductible: state?.editCollisionDeductible,
			editCollisionPremium: state?.editCollisionPremium,
			editCompDeductible: state?.editCompDeductible,
			editCompPremium: state?.editCompPremium,
			editMedicalLimit: state?.editMedicalLimit,
			editMedicalPremium: state?.editMedicalPremium,
			editOtherLimit: state?.editOtherLimit,
			editOtherPremium: state?.editOtherPremium,
			editPropertyLimit: state?.editPropertyLimit,
			editPropertyPremium: state?.editPropertyPremium,
			insuranceCompanies: state.gapCompanies,
		}),

		shallow
	);

	const { collisionTotCost, editCollisionTotCost } = useCurrentRecapStore(
		(state) => state,
		shallow
	);

	const comList = insuranceCompanies.map((obj) => {
		return {
			label: obj.name,
			value: obj.ID,
			gapCo: obj,
		};
	});

	const methods = useForm();

	return (
		<Card>
			<CardHeader>
				<CardTitle tag="h3">Collision Insurance</CardTitle>
			</CardHeader>
			<CardBody>
				<FormProvider {...methods}>
					<Form>
						<Row>
							<Col>
								<FormGroup className={"col"}>
									<Label>Company</Label>
									<Select
										isDisabled={type !== DealStatus.PENDING}
										value={{
											value: coRecNum,
											label: comList.filter((e) => e.value === coRecNum)[0]
												?.label,
										}}
										options={comList}
										onChange={(e) => {
											console.log(e.value);
											editCoRecNum(e.value);
										}}
									/>
								</FormGroup>
							</Col>
							<Col>
								<InputElement
									{...methods}
									disabled={type !== DealStatus.PENDING}
									value={appNumber}
									onChange={(e) => {
										console.log(e.target.value);
										editAppNumber(e.target.value);
									}}
									name="appNumber"
									label="Application Number"
									type="text"
									wholeRow={true}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<LabelAndInput
									{...methods}
									disabled={type !== DealStatus.PENDING}
									value={compPremium}
									onChange={editCompPremium}
									name="compPremium"
									label="Comp Premium"
									buttonLabel={true}
									btnClass="btn btn-md btn-primary disabled w-100"
								/>
								<LabelAndInput
									{...methods}
									disabled={type !== DealStatus.PENDING}
									value={collisionPremium}
									onChange={(e) => {
										editCollisionPremium(e);
									}}
									name="collisionPremium"
									label="Collision Premium"
									type="text"
									wholeRow={true}
									buttonLabel={true}
									btnClass="btn btn-md btn-primary disabled w-100"
								/>

								<LabelAndInput
									{...methods}
									disabled={type !== DealStatus.PENDING}
									value={bodyInjuryPremium}
									onChange={(e) => {
										editBodyInjuryPremium(e);
									}}
									name="bodyInjuryPremium"
									label="Bodily Injury Premium"
									type="text"
									wholeRow={true}
									buttonLabel={true}
									btnClass="btn btn-md btn-primary disabled w-100"
								/>

								<LabelAndInput
									{...methods}
									disabled={type !== DealStatus.PENDING}
									value={propertyPremium}
									onChange={(e) => {
										console.log(e);

										editPropertyPremium(e);
									}}
									name="propertyPremium"
									label="Property DMG Premium"
									type="text"
									wholeRow={true}
									buttonLabel={true}
									btnClass="btn btn-md btn-primary disabled w-100"
								/>

								<LabelAndInput
									{...methods}
									disabled={type !== DealStatus.PENDING}
									value={medicalPremium}
									onChange={(e) => {
										editMedicalPremium(e);
									}}
									name="medicalPremium"
									label="Medical Premium"
									type="text"
									wholeRow={true}
									buttonLabel={true}
									btnClass="btn btn-md btn-primary disabled w-100"
								/>

								<LabelAndInput
									{...methods}
									disabled={type !== DealStatus.PENDING}
									value={otherPremium}
									onChange={(e) => {
										editOtherPremium(e);
									}}
									name="otherPremium"
									label="Other Premium"
									type="text"
									wholeRow={true}
									buttonLabel={true}
									btnClass="btn btn-md btn-primary disabled w-100"
								/>
							</Col>
							<Col>
								<LabelAndInput
									{...methods}
									disabled={type !== DealStatus.PENDING}
									value={compDeductible}
									onChange={(e) => {
										editCompDeductible(e);
									}}
									name="compDeductible"
									label="Comp Deductible"
									type="text"
									wholeRow={true}
									buttonLabel={true}
									btnClass="btn btn-md btn-primary disabled w-100"
								/>

								<LabelAndInput
									{...methods}
									disabled={type !== DealStatus.PENDING}
									value={collisionDeductible}
									onChange={(e) => {
										editCollisionDeductible(e);
									}}
									name="collisionDeductible"
									label="Collision Deductible"
									type="text"
									wholeRow={true}
									buttonLabel={true}
									btnClass="btn btn-md btn-primary disabled w-100"
								/>

								<LabelAndInput
									{...methods}
									disabled={type !== DealStatus.PENDING}
									value={bodyInjuryLimit}
									onChange={(e) => {
										editBodyInjuryLimit(e);
									}}
									name="bodyInjuryLimit"
									label="Bodily Injury Limit"
									type="text"
									wholeRow={true}
									buttonLabel={true}
									btnClass="btn btn-md btn-primary disabled w-100"
								/>

								<LabelAndInput
									{...methods}
									disabled={type !== DealStatus.PENDING}
									value={propertyLimit}
									onChange={(e) => {
										editPropertyLimit(e);
									}}
									name="propertyLimit"
									label="Property DMG Limit"
									type="text"
									wholeRow={true}
									buttonLabel={true}
									btnClass="btn btn-md btn-primary disabled w-100"
								/>

								<LabelAndInput
									{...methods}
									disabled={type !== DealStatus.PENDING}
									value={medicalLimit}
									onChange={(e) => {
										editMedicalLimit(e);
									}}
									name="medicalLimit"
									label="Medical Limit"
									type="text"
									wholeRow={true}
									buttonLabel={true}
									btnClass="btn btn-md btn-primary disabled w-100"
								/>

								<LabelAndInput
									{...methods}
									disabled={type !== DealStatus.PENDING}
									value={otherLimit}
									onChange={(e) => {
										editOtherLimit(e);
									}}
									name="otherLimit"
									label="Other Limit"
									type="text"
									wholeRow={true}
									buttonLabel={true}
									btnClass="btn btn-md btn-primary disabled w-100"
								/>
							</Col>
						</Row>
					</Form>

					<div>
						<Row className="">
							<IntegerInputElement
								disabled={type !== DealStatus.PENDING}
								value={term}
								onChange={(e) => {
									editTerm(e);
								}}
								name="term"
								label="Term in Months"
								type="number"
								wholeRow={true}
							/>
							<CurrencyInput
								disabled={type !== DealStatus.PENDING}
								value={collisionTotCost}
								onChange={editCollisionTotCost}
								label="Cost"
								disableValidation
								wholeRow={true}
							/>
						</Row>
						<Row>
							<Col className="my-2 col px-0 text-center">
								<h6>Total Collision Premium</h6>
								<h5 className="text-success">
									<DisplayCurrency value={collisionTotPremium} />
								</h5>
							</Col>
						</Row>
					</div>
				</FormProvider>
			</CardBody>
		</Card>
	);
};
export default CollisionForm;
