/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 DealerClick (https://dealerclick.com)

* Coded by DealerClick Software ))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import DashboardHeader from "component/dc/header/DashboardHeader";
import DashboardSection from "./dashboardSection";

const Dashboard = () => {
	return (
		<div className="bodyHeight">
			<DashboardHeader />
			<DashboardSection />
		</div>
	);
};

export default Dashboard;
