import React, { useEffect } from "react";

import useCalculateSales from "../../helperFunction/deal/useCalculateSales";
import useCurrentDealStore from "../../store/DealStore";
import useCurrentRecapStore from "../../store/RecapStore";
import useCurrentSaleStore from "../../store/SaleStore";
import useRoute from "../../helperFunction/UseRoute";
import Loading from "../../component/dc/load/Loading.js";

import { getDealDetails } from "../../api/DealAPI";
import { getSettings } from "../../api/SettingsAPI";
import { showApiError } from "../../helperFunction/ApiErrorHandler";
import {
	retrieveFailAlert,
	failAlert,
} from "../../component/dc/modal/SweetAlerts";
import { useFromUser } from "../../store/LocalStorageHelper";
import { useHistory } from "react-router-dom";
import { PATHS, DealStatus, SaleType } from "../../Constants";

import shallow from "zustand/shallow";
import useSetDeal from "../../helperFunction/deal/useSetDeal";
import { TemplateVariableProvider } from "../../helperFunction/TemplateVariableContext";
import DealTabs from "./DealTabs";
import useCalculateGlobalfield from "../../helperFunction/deal/useCalculateGlobalfield";
// import useBuildDeal from "helperFunction/deal/useBuildDeal";

const DealDetailPage = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const dcLoginID = useFromUser("ID");
	const firstName = useFromUser("firstName");
	const lastName = useFromUser("lastName");
	const history = useHistory();
	const dealListPage = useRoute(PATHS.DEAL_LIST);
	const dealDetails = useRoute(PATHS.DEAL_DETAIL);
	const dealAddPage = useRoute(PATHS.DEAL_ADD);
	const setDeal = useSetDeal();

	// Deal store
	const {
		ID,
		isLoading,
		type,
		resetDeal,
		editIsLoading,
		updateSettings,
		editModifiedBy,
		editModifiedById,
	} = useCurrentDealStore(
		(state) => ({
			ID: state.deal.ID,
			isLoading: state.isLoading,
			type: state.deal.type,
			resetDeal: state.reset,
			editIsLoading: state.editIsLoading,
			updateSettings: state.updateSettings,
			editModifiedBy: state.editModifiedBy,
			editModifiedById: state.editModifiedById,
		}),
		shallow
	);

	// Recap store
	const { resetRecap } = useCurrentRecapStore(
		(state) => ({
			resetRecap: state.reset,
		}),
		shallow
	);

	// Sale store
	const { resetSale } = useCurrentSaleStore(
		(state) => ({
			resetSale: state.reset,
		}),
		shallow
	);

	const getData = () => {
		const dealID = ID || history.location.state?.ID;
		if (dealID != null) {
			Promise.all([
				getSettings(dealerID, locationID),
				getDealDetails(dealID),
			]).then(
				(res) => {
					console.log("getData() has finished");
					console.log(res);
					console.log("Getting Deal details for: " + dealID);
					const defaultSettings = res[0].data.content;
					const settingsFromDeal = JSON.parse(
						res[1].data.content.deal.dealSettings
					);
					setDeal(res[1]);
					updateSettings(defaultSettings, settingsFromDeal);
					editModifiedBy(lastName + ", " + firstName);
					editModifiedById(dcLoginID);
					history.push(dealDetails, { ID: res[1].data.content.deal.ID });
					const resSaleType = res[1].data.content.deal.sale.saleType;
					// only open retail and wholesale deals
					if (
						resSaleType !== SaleType[0].value &&
						resSaleType !== SaleType[1].value &&
						resSaleType !== SaleType[2].value
					) {
						//						history.push(dealListPage);
						failAlert(
							"This type of deal is under construction. DealerClick is working on it right now."
						);
						history.push(dealListPage);
					}
					editIsLoading(false);
				},
				(err) => {
					console.log(err);
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
						history.push(dealListPage);
					}
				}
			);
		} else {
			history.push(dealAddPage);
		}
	};
	// console.log(deal);
	useEffect(() => {
		if (isLoading) {
			console.log("loading deal");
			getData();
		}

		return () => {
			resetDeal();
			resetRecap();
			resetSale();
		};
		// eslint-disable-next-line
	}, []);

	const TabHandler = () => {
		if (type !== DealStatus.PENDING) {
			return <SoldOrDeletedDetailTab />;
		} else {
			return <PendingDetailTab />;
		}
	};

	return (
		<div>
			{(isLoading && <Loading />) || (
				<TemplateVariableProvider>
					<TabHandler />
				</TemplateVariableProvider>
			)}
		</div>
	);
};

const SoldOrDeletedDetailTab = () => {
	useCalculateGlobalfield();
	return <DealTabs />;
};

const PendingDetailTab = () => {
	useCalculateSales();
	return <DealTabs />;
};

export default DealDetailPage;
