import React, { memo, useEffect, useMemo, useState } from "react";
import { Container, Row, Col, FormGroup, Input } from "reactstrap";
import {
	useTable,
	useFilters,
	useSortBy,
	usePagination,
	useRowSelect,
} from "react-table";

import classnames from "classnames";
import matchSorter from "match-sorter";
import Select from "react-select";

const ReactTable = memo(
	({
		columns,
		data,
		vehicleHiddenColumns = [],
		customerHiddenColumns = [],
		hiddenColumns = [],
		customerColumn,
		vehicleColumn,
		isDealTable = false,
		checkboxColumn,
		setSelectedRows,
	}) => {
		console.log(data);
		const CHECKBOX_ID = "checkbox";
		const [appendCustomer, setappendCustomer] = useState(customerHiddenColumns);
		const [appendVehicle, setAppendVehicle] = useState(vehicleHiddenColumns);

		const [customerCol, setCustomerCol] = useState(customerColumn);
		const [vehicleCol, setVehicleCol] = useState(vehicleColumn);

		const [pushCustomer, setPushCustomer] = useState(false);
		const [pushVehicle, setPushVehicle] = useState(false);

		const [numberOfRows, setNumberOfRows] = useState({
			value: 100,
			label: "100 rows",
		});

		const [pageSelect, setPageSelect] = useState({
			value: 0,
			label: "Page 1",
		});

		//drop down option for customer
		const customerOptions = useMemo(() => {
			const options = new Set();
			customerHiddenColumns.forEach((element) => {
				options.add(element);
			});
			return [...options.values()];
		}, [customerHiddenColumns]);

		const handleCustomerDropDown = (e) => {
			const val = e.target.value;
			if (!pushCustomer) {
				customerHiddenColumns.push(customerColumn);
				setPushCustomer(true);
			}
			const filtered = customerHiddenColumns.filter((value) => value !== val);
			setappendCustomer(filtered);
			const newHiddenCol = filtered.concat(appendVehicle);
			setHiddenColumns(newHiddenCol);
			setCustomerCol(val);
		};

		//drop down option for vehicle
		const vehicleOptions = useMemo(() => {
			const options = new Set();
			vehicleHiddenColumns.forEach((element) => {
				options.add(element);
			});
			return [...options.values()];
		}, [vehicleHiddenColumns]);

		const handleVehicleDropdown = (e) => {
			const val = e.target.value;
			if (!pushVehicle) {
				vehicleHiddenColumns.push(vehicleColumn);
				setPushVehicle(true);
			}
			const filtered = vehicleHiddenColumns.filter((value) => value !== val);
			setAppendVehicle(filtered);
			const newHiddenCol = filtered.concat(appendCustomer);
			setHiddenColumns(newHiddenCol);
			setVehicleCol(val);
		};

		const fuzzyTextFilterFn = (rows, id, filterValue) => {
			return matchSorter(rows, filterValue, {
				keys: [(row) => row.values[id]],
			});
		};

		// Let the table remove the filter if the string is empty
		fuzzyTextFilterFn.autoRemove = (val) => !val;

		// Define a custom filter filter function!
		const filterGreaterThan = (rows, id, filterValue) => {
			return rows.filter((row) => {
				const rowValue = row.values[id];
				return rowValue >= filterValue;
			});
		};

		// This is an autoRemove method on the filter function that
		// when given the new filter value and returns true, the filter
		// will be automatically removed. Normally this is just an undefined
		// check, but here, we want to remove the filter if it's not a number
		filterGreaterThan.autoRemove = (val) => typeof val !== "number";

		//  Define a default UI for filtering
		const DefaultColumnFilter = ({
			column: { filterValue, preFilteredRows, setFilter },
		}) => {
			const count = preFilteredRows.length;

			return (
				<FormGroup>
					<Input
						value={filterValue || ""}
						placeholder={`Search ${count} records...`}
						type="text"
						onChange={(e) => {
							setFilter(e.target.value || undefined);
						}}
					/>
				</FormGroup>
			);
		};

		const filterTypes = useMemo(
			() => ({
				// Add a new fuzzyTextFilterFn filter type.
				fuzzyText: fuzzyTextFilterFn,
				// Or, override the default text filter to use
				// "startWith"
				text: (rows, id, filterValue) => {
					return rows.filter((row) => {
						const rowValue = row.values[id];

						return rowValue !== undefined
							? String(rowValue)
									.toLowerCase()
									.startsWith(String(filterValue).toLowerCase())
							: true;
					});
				},
			}),
			[]
		);

		const defaultColumn = useMemo(
			() => ({
				// Let's set up our default Filter UI
				Filter: DefaultColumnFilter,
			}),
			[]
		);

		const {
			getTableProps,
			getTableBodyProps,
			headerGroups,
			page,
			prepareRow,
			nextPage,
			pageOptions,
			previousPage,
			canPreviousPage,
			canNextPage,
			setPageSize,
			gotoPage,
			setHiddenColumns,
			selectedFlatRows,
		} = useTable(
			{
				columns,
				data,
				defaultColumn, // Be sure to pass the defaultColumn option
				filterTypes,
				initialState: {
					pageSize: 100,
					pageIndex: 0,
					hiddenColumns: hiddenColumns,
				},
				getRowId: (row, relativeIndex) => {
					return row.id || relativeIndex;
				},
			},
			useFilters,
			useSortBy,
			usePagination,
			useRowSelect,
			(hooks) => {
				hooks.allColumns.push((columns) => {
					if (checkboxColumn === true) {
						return [
							{
								id: CHECKBOX_ID,
								// The header can use the table's getToggleAllRowsSelectedProps method
								// to render a checkbox
								Header: ({ getToggleAllRowsSelectedProps }) => (
									<div>
										<IndeterminateCheckbox
											{...getToggleAllRowsSelectedProps()}
										/>
									</div>
								),
								// The cell can use the individual row's getToggleRowSelectedProps method
								// to the render a checkbox
								Cell: ({ row }) => (
									<div>
										<IndeterminateCheckbox
											{...row.getToggleRowSelectedProps()}
										/>
									</div>
								),
							},
							...columns,
						];
					}

					return [...columns];
				});
			}
		);

		// We don't want to render all of the rows for this example, so cap
		// it for this use case
		// const firstPageRows = rows.slice(0, 10);
		let pageSelectData = Array.apply(null, Array(pageOptions.length));

		const numberOfRowsData = [5, 10, 20, 25, 50, 100];

		useEffect(() => {
			if (setSelectedRows != null) {
				setSelectedRows(selectedFlatRows.map((row) => ({ ...row.original })));
			}
			// eslint-disable-next-line
		}, [selectedFlatRows]);

		return (
			<>
				<div className="ReactTable -striped -highlight">
					<div className="pagination-top">
						<div className="-pagination">
							<div className="-previous">
								<button
									type="button"
									onClick={() => previousPage()}
									readOnly={!canPreviousPage}
									className="-btn"
								>
									<i className="fa fa-angle-double-left text-center" />
								</button>
							</div>
							<div className="-center">
								<Container>
									<Row className="justify-content-center">
										<Col>
											<Select
												className="react-select primary d-block"
												classNamePrefix="react-select"
												name="pageSelect"
												value={pageSelect}
												onChange={(value) => {
													gotoPage(value.value);
													setPageSelect(value);
												}}
												options={pageSelectData.map((_prop, key) => {
													return {
														value: key,
														label: "Page " + (key + 1),
													};
												})}
												placeholder="Choose Page"
											/>
										</Col>
										<Col>
											<Select
												className="react-select primary"
												classNamePrefix="react-select"
												name="numberOfRows"
												value={numberOfRows}
												onChange={(value) => {
													setPageSize(value.value);
													setNumberOfRows(value);
												}}
												options={numberOfRowsData.map((prop) => {
													return {
														value: prop,
														label: prop + " rows",
													};
												})}
												placeholder="Choose Rows"
											/>
										</Col>
									</Row>
								</Container>
							</div>
							<div className="-next">
								<button
									type="button"
									onClick={() => nextPage()}
									readOnly={!canNextPage}
									className="-btn"
								>
									<i className="fa fa-angle-double-right text-center" />
								</button>
							</div>
						</div>
					</div>
					<table {...getTableProps()} className="rt-table">
						<thead className="rt-thead -header">
							<div className="pagination-top">
								<div>
									<Container>
										<Row className="justify-content-center">
											<Col>
												<button
													type="button"
													onClick={() => previousPage()}
													readOnly={!canPreviousPage}
													className="-btn"
												>
													<i className="fa fa-angle-double-left text-center" />
												</button>
											</Col>
											<Col>
												<Select
													className="react-select primary d-block"
													classNamePrefix="react-select"
													name="pageSelect"
													value={pageSelect}
													onChange={(value) => {
														gotoPage(value.value);
														setPageSelect(value);
													}}
													options={pageSelectData.map((_prop, key) => {
														return {
															value: key,
															label: "Page " + (key + 1),
														};
													})}
													placeholder="Choose Page"
												/>
											</Col>
											<Col>
												<Select
													className="react-select primary"
													classNamePrefix="react-select"
													name="numberOfRows"
													value={numberOfRows}
													onChange={(value) => {
														setPageSize(value.value);
														setNumberOfRows(value);
													}}
													options={numberOfRowsData.map((prop) => {
														return {
															value: prop,
															label: prop + " rows",
														};
													})}
													placeholder="Choose Rows"
												/>
											</Col>
											<Col className="actions-right">
												<button
													type="button"
													onClick={() => nextPage()}
													readOnly={!canNextPage}
													className="-btn"
												>
													<i className="fa fa-angle-double-right text-center" />
												</button>
											</Col>
										</Row>
									</Container>
								</div>
							</div>

							{headerGroups.map((headerGroup) => (
								<tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
									{headerGroup.headers.map((column) => (
										<th
											key={column.id}
											className={classnames("rt-th rt-resizable-header", {
												"-cursor-pointer": column.canFilter,
												"-sort-asc": column.isSorted && !column.isSortedDesc,
												"-sort-desc": column.isSorted && column.isSortedDesc,
												"-check-box": column.id === CHECKBOX_ID,
											})}
										>
											<div
												className="rt-resizable-header-content"
												{...(column.canFilter && {
													...column.getHeaderProps(
														column.getSortByToggleProps()
													),
												})}
											>
												{column.render("Header")}
											</div>

											{/* customer and vehicle drop down options sorted */}
											<div>
												{column.customerSort && isDealTable ? (
													<FormGroup>
														<Input
															type="select"
															value={customerCol}
															onChange={(e) => {
																handleCustomerDropDown(e);
																column.setFilter("");
															}}
														>
															<option value={customerColumn}>Customer</option>
															{customerOptions.map((option, i) => (
																<option key={i} value={option}>
																	{option}
																</option>
															))}
														</Input>
													</FormGroup>
												) : null}

												{column.vehicleSort && isDealTable ? (
													<FormGroup>
														<Input
															type="select"
															value={vehicleCol}
															onChange={(e) => {
																handleVehicleDropdown(e);
																column.setFilter("");
															}}
														>
															<option value={vehicleColumn}>Vehicle</option>
															{vehicleOptions.map((option, i) => (
																<option key={i} value={option}>
																	{option}
																</option>
															))}
														</Input>
													</FormGroup>
												) : null}
											</div>
											{/* Render the columns filter UI */}
											{column.canFilter ? column.render("Filter") : null}
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()} className="rt-tbody">
							{page.map((row, i) => {
								prepareRow(row);

								return (
									<tr
										{...row.getRowProps()}
										className={classnames(
											"rt-tr",
											{ " -odd": i % 2 === 0 },
											{ " -even": i % 2 === 1 }
										)}
									>
										{row.cells.map((cell) => (
											<td
												{...cell.getCellProps([
													{
														style: cell.column.style,
														className: classnames("rt-td singleCell", {
															"-check-box": cell.column.id === CHECKBOX_ID,
															[cell.column.className]:
																cell.column.className != null,
														}),
													},
												])}
											>
												{cell.render("Cell")}
											</td>
										))}
									</tr>
								);
							})}
						</tbody>
					</table>
					<div className="pagination-top">
						<div>
							<Container>
								<Row className="justify-content-center">
									<Col>
										<button
											type="button"
											onClick={() => previousPage()}
											readOnly={!canPreviousPage}
											className="-btn"
										>
											<i className="fa fa-angle-double-left text-center" />
										</button>
									</Col>
									<Col>
										<Select
											className="react-select primary d-block"
											classNamePrefix="react-select"
											name="pageSelect"
											value={pageSelect}
											onChange={(value) => {
												gotoPage(value.value);
												setPageSelect(value);
											}}
											options={pageSelectData.map((_prop, key) => {
												return {
													value: key,
													label: "Page " + (key + 1),
												};
											})}
											placeholder="Choose Page"
										/>
									</Col>
									<Col>
										<Select
											className="react-select primary"
											classNamePrefix="react-select"
											name="numberOfRows"
											value={numberOfRows}
											onChange={(value) => {
												setPageSize(value.value);
												setNumberOfRows(value);
											}}
											options={numberOfRowsData.map((prop) => {
												return {
													value: prop,
													label: prop + " rows",
												};
											})}
											placeholder="Choose Rows"
										/>
									</Col>
									<Col className="actions-right">
										<button
											type="button"
											onClick={() => nextPage()}
											readOnly={!canNextPage}
											className="-btn"
										>
											<i className="fa fa-angle-double-right text-center" />
										</button>
									</Col>
								</Row>
							</Container>
						</div>
					</div>
				</div>
			</>
		);
	},
	(prevProps, nextProps) =>
		JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data)
);

const IndeterminateCheckbox = React.forwardRef(
	({ indeterminate, ...rest }, ref) => {
		const defaultRef = React.useRef();
		const resolvedRef = ref || defaultRef;

		React.useEffect(() => {
			resolvedRef.current.indeterminate = indeterminate;
		}, [resolvedRef, indeterminate]);

		return <input type="checkbox" ref={resolvedRef} {...rest} />;
	}
);

export default ReactTable;
