import React, { useState } from "react";
import shallow from "zustand/shallow";

import useBuildVehicle from "../../helperFunction/BuildInventoryObj";
import useCurrentVehicleStore from "../../store/InventoryStore";
import AttachmentModal from "../../component/dc/attachment/AttachmentModal";
import ChildrenModal from "../../component/dc/modal/ChildrenModal";
import DealTemplateModal from "../../view/settings/settingsTabs/dealTemplates/DealTemplateModal";
import InventoryDealList from "./InventoryDealList";
import InventorySaveButton from "../../component/dc/inventory/InventorySaveButton";
import NoteButton from "../../component/dc/note/NoteButton";
import ShowModal from "../../component/dc/modal/ShowModal";

import { saveFailAlert } from "../../component/dc/modal/SweetAlerts";
import { saveVehicle } from "../../api/InventoryAPI";
import { showApiError } from "../../helperFunction/ApiErrorHandler";
import { KbbContext } from "./inventoryDetailTab/value/kbb/KbbContext";
import { useContext } from "react";

const InventoryActionBar = () => {
	// Vehicle store
	const { inventoryID, notes, editNotes, status } = useCurrentVehicleStore(
		(state) => ({
			inventoryID: state.inventoryID,
			notes: state.notes,
			editNotes: state.editNotes,
			status: state.status,
		}),
		shallow
	);

	const [dealListModal, setDealListModal] = useState(false);
	const [templateModal, setTemplateModal] = useState(false);

	const getVehicle = useBuildVehicle();
	const { setDcKbbObj, getDcKbbObj } = useContext(KbbContext);

	// Opens or closes the deal list modal
	const toggleDealListModal = () => setDealListModal(!dealListModal);
	const toggleTemplate = () => setTemplateModal(!templateModal);

	const saveNotes = () => {
		if (inventoryID != null) {
			const vehicle = getVehicle();
			let modifiedVehicle = { ...vehicle };
			const modifiedNotes = notes.length > 0 ? JSON.stringify(notes) : "";

			modifiedVehicle.notes = modifiedNotes;
			const kbb = getDcKbbObj();
			const request = {
				vehicle: modifiedVehicle,
				kbb: kbb,
			};
			saveVehicle(request).then(
				(response) => setDcKbbObj(response.data.content.kbb),
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, saveFailAlert);
					}
				}
			);
		}
	};

	return (
		<div className="action-bar action-bar pt-1 mx-0 px-0">
			<div className="text-center">
				<ShowModal title="Re-Decode VIN" />
				<div
					outline
					onClick={toggleTemplate}
					readOnly={status !== "Pending" && status !== "Available"}
					className="btn btn-primary-dark btn-md ml-3 onMobileScreen"
				>
					Start Deal
				</div>
				<AttachmentModal relatedID={inventoryID} type="inventory" />
				<div
					onClick={toggleDealListModal}
					className="btn-primary-dark btn btn-md onMobileScreen"
				>
					<i className="nc-icon nc-zoom-split" /> View Deals
				</div>
				<NoteButton notes={notes} editNotes={editNotes} saveNotes={saveNotes} />
				<InventorySaveButton />
			</div>
			<ChildrenModal
				modalTitle="Vehicle Deals"
				modal={dealListModal}
				toggle={toggleDealListModal}
			>
				<InventoryDealList
					toggle={toggleDealListModal}
					inventoryID={inventoryID}
				/>
			</ChildrenModal>
			<ChildrenModal
				modalTitle="Deal Templates"
				modal={templateModal}
				toggle={toggleTemplate}
			>
				<DealTemplateModal />
			</ChildrenModal>
		</div>
	);
};

export default InventoryActionBar;
