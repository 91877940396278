import React, { useState, useEffect } from "react";
import { getFlooringRatio } from "../../../api/InventoryAPI";
import {
	Table,
	Card,
	// CardHeader,
	Label,
	Input,
	CardBody,
	Row,
	Col,
	CardFooter,
} from "reactstrap";
import NumberFormat from "react-number-format";

const FlooringRatioTable = () => {
	const [show, toggleShow] = useState(true);
	const [data, setData] = useState([]);
	const dealerInfo = JSON.parse(sessionStorage.getItem("user"));
	const dealerID = dealerInfo.dealerID;
	const locationID = dealerInfo.locationID;
	const CurrencyDisplay = ({ value, className = "" }) => (
		<NumberFormat
			value={value}
			thousandSeparator={true}
			decimalScale={2}
			fixedDecimalScale={true}
			prefix={"$"}
			isNumericString={true}
			className={className}
			customInput={Input}
			//readOnly
			//displayType="text"
		/>
	);

	const getData = () => {
		getFlooringRatio(dealerID, locationID).then(
			(res) => {
				console.log(res.data.content.length);
				setData(res.data.content);
				if (res.data.content.length === 0) toggleShow(false);
			},
			(err) => {}
		);
	};

	useEffect(() => {
		getData();
		// eslint-disable-next-line
	}, []);

	const tableData = data.map((obj, index) => (
		<>
			<td key={index}>
				<tr>
					<tr>
						<Input
							disable
							type="text"
							name="flooringComp"
							className="flooring-overview"
							value={obj.flooringComp}
						/>
					</tr>
					<tr>
						<td>
							<Label># of Vehicles</Label>
						</td>
						<td>
							<Input
								disable
								type="text"
								name="numOfVehicle"
								value={obj.numOfVehicle}
							/>
						</td>
					</tr>
					<tr>
						<td>
							<Label>Credit Line</Label>
						</td>
						<td>
							<Label>
								<CurrencyDisplay value={obj.lineOfCredit} />
							</Label>
						</td>
					</tr>
					<tr>
						<td>
							<Label>Total Floored</Label>
						</td>
						<td>
							<Label>
								<CurrencyDisplay value={obj.totalFlooringAmt} />
							</Label>
						</td>
					</tr>

					<tr>
						<td>
							<Label>Available Credit</Label>
						</td>
						<td>
							<Label>
								<CurrencyDisplay
									value={obj.lineOfCredit - obj.totalFlooringAmt}
								/>
							</Label>
						</td>
					</tr>
				</tr>
			</td>
		</>
	));

	return (
		<Row>
			<Col>
				{show && (
					<Col>
						<Card>
							<CardBody>
								{/* <CardHeader> */}
								<h5 className="title">Flooring Overview</h5>
								{/* </CardHeader> */}
								<Table>
									<tbody>{tableData}</tbody>
								</Table>
							</CardBody>
							<CardFooter>
								<div onClick={() => toggleShow(!show)}>
									{show ? <p className="toggle-btn">Close X</p> : ""}
								</div>
							</CardFooter>
						</Card>
					</Col>
				)}
			</Col>
		</Row>
	);
};

export default FlooringRatioTable;
