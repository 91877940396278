import React, { useState } from "react";

import useCurrentCustomerStore from "store/CustomerStore";
import ChildrenModal from "component/dc/modal/ChildrenModal";
import CustomerDetails from "./CustomerDetails";
import UpdateCustomerButton from "component/dc/customer/UpdateCustomerButton";
import shallow from "zustand/shallow";
import { Row } from "reactstrap";
import DeleteCustomerButton from "component/dc/customer/DeleteCustomerButton";
import { CustomerStartDealButton } from "component/dc/customer/CustomerStartDealButton";
import CustomerDealList from "view/contact/CustomerDealList";
import CustomerSaveNoteButton from "component/dc/customer/CustomerSaveNoteButton";
import CustomerDetailHeader from "../../CustomerDetailHeader";
import tabOnEnter from "helperFunction/tabOnEnter";

const CustomerDetailsTab = () => {
	// Local states

	const [customerListModal, setCustomerListModal] = useState(false);

	// Opens or closes the deal list modal
	const toggleCustomerListModal = () =>
		setCustomerListModal(!customerListModal);
	// Customer store
	const { originalID } = useCurrentCustomerStore(
		(state) => ({
			originalID: state.originalID,
		}),
		shallow
	);

	return (
		<>
			<CustomerDetailHeader />
			<div onKeyDown={tabOnEnter} className="col">
				<CustomerDetails />
			</div>
			<div className="col">
				<UpdateCustomerButton />
			</div>
			<div className="col pb-3">
				<div className="row mx-0 hide">
					<div className="col-md-6">
						<Row className="px-0">
							<CustomerStartDealButton />

							<div
								className="btn btn-secondary btn-md"
								onClick={toggleCustomerListModal}
							>
								<i className="icon-big text-center nc-icon nc-briefcase-24"></i>{" "}
								View<span className="noMobile"> Deals</span>
							</div>
						</Row>
					</div>
					<div className="col-md-6 text-right">
						<CustomerSaveNoteButton />
					</div>
				</div>
			</div>

			<DeleteCustomerButton redirect={true} />
			<ChildrenModal
				modal={customerListModal}
				toggle={toggleCustomerListModal}
				modalTitle="Customer Deals"
				children={
					<CustomerDealList
						toggle={toggleCustomerListModal}
						originalID={originalID}
					/>
				}
			/>
		</>
	);
};

export default CustomerDetailsTab;
