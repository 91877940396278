import getAxiosInstance from "./interceptors/buildReqAndResInstance";

import { userBaseUrl } from "./Constants";

const instance = getAxiosInstance({
	baseURL: userBaseUrl,
});

export const decodeVIN = async (vin) => {
	return await instance.get(`/integrations/decode/${vin}`);
};
