import React, { useEffect } from "react";
import { Input } from "reactstrap";
import useCurrentSaleStore from "store/SaleStore";
import NumberFormat from "react-number-format";
import shallow from "zustand/shallow";
// import useCurrentDealStore from "store/DealStore";
//import { DealStatus } from "Constants";

const AdjustmentAPR = () => {
	const {
		addOn,
		editAddOn,
		// amtFinanced,
		loanPeriod,
		// calculateCompoundInterest,
	} = useCurrentSaleStore(
		(state) => ({
			addOn: state.addOn,
			editAddOn: state.editAddOn,
			loanPeriod: state.loanPeriod,
		}),
		shallow
	);

	useEffect(() => {
		if (Number(loanPeriod) === 5) {
			editAddOn(0);
		}
	}, [loanPeriod, editAddOn]);

	return (
		<NumberFormat
			name="adjustmentAPR"
			value={addOn * 100}
			decimalScale={4}
			fixedDecimalScale={true}
			onFocus={(e) => e.target.select()}
			isNumericString={true}
			readOnly={true} //{Number(loanPeriod) === 5 || type !== DealStatus.PENDING}
			customInput={Input}
			className="bold"
			onValueChange={(values) => {
				if (Number(loanPeriod) !== 5) {
					if (values.value === "") {
						editAddOn(0);
					} else {
						editAddOn(values.floatValue / 100);
					}
				}
			}}
		/>
	);
};

export default AdjustmentAPR;
