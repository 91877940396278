import React from "react";
import { Input } from "reactstrap";

const DealTemplateList = ({ templates, selected, setSelected }) => {
	const options = templates.map((obj, idx) => (
		<option key={obj.template.ID} value={idx}>
			{obj.template.name}
		</option>
	));

	return (
		<div className="d-flex justify-content-center mt-2 mb-5">
			<Input
				className="col-4"
				onChange={(e) => setSelected(e.target.value)}
				type="select"
				value={selected}
			>
				<option key="default" value={-1} label="Create New Template" />
				{options != null && options.length > 0 && options}
			</Input>
		</div>
	);
};

export default DealTemplateList;
