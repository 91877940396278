import React, { useState } from "react";

import ChildrenModal from "../../modal/ChildrenModal";
import BlastMessageReceivers from "../BlastMessageReceivers";
import MailgunSendBlastEmail from "./MailgunSendBlastEmail";
import MailgunSendEmail from "./MailgunSendEmail";

/**
 * Used to send single text emails or blast emails to multiple people.
 * Can you template variables for blast email because each email is
 * mapped and emailed separately to each customer. Not all fields are required.
 * Below is listed what fields are required for single email vs blast
 *
 * Single email: {@param customerEmail} and {@param templateVariableData} required
 * Blast email: {@param blastMessage}, {@param customers}, and {@param setCustomers} required
 *
 * @param {String} customerEmail Customer email address
 * @param {Object} lenderPacket Lender packet information
 * @param {boolean} blastMessage True if you want to enable blast email
 * @param {Array} customers React state array of customer objects. Should at least contain
 * 				  the fields ID, cellPhone, firstName, lastName, email and name
 * @param {React.SetStateAction} setCustomers React setState for the customers object.
 * 				  Used to remove objects from customers array that the send email request
 * 				  was successful for.
 */
const MailgunEmail = ({
	customerEmail,
	lenderPacket = null,
	blastMessage,
	customers,
	setCustomers,
}) => {
	const [mailgunModal, setMailgunModal] = useState(false);

	// Toggles the modal
	const toggleMailgunModal = () => setMailgunModal(!mailgunModal);

	return (
		<>
			{blastMessage === true && (
				<div
					className="px-1 btn-round btn-sm btn btn-outline-secondary w-25 my-0"
					onClick={toggleMailgunModal}
				>
					<i className="nc-icon nc-send" /> Blast Email
					<ChildrenModal
						size="lg"
						modal={mailgunModal}
						toggle={toggleMailgunModal}
						modalTitle="Send Email"
					>
						<MailgunSendBlastEmail
							toggle={toggleMailgunModal}
							customers={customers}
							setCustomers={setCustomers}
						/>
						<BlastMessageReceivers customers={customers} />
					</ChildrenModal>
				</div>
			)}
			{blastMessage !== true && (
				<div
					className="btn-round btn btn-md btn-info onMobileScreen"
					onClick={toggleMailgunModal}
				>
					<i className="nc-icon nc-send" /> Email
					<ChildrenModal
						size="lg"
						modal={mailgunModal}
						toggle={toggleMailgunModal}
						modalTitle="Send Email"
					>
						<MailgunSendEmail
							customerEmail={customerEmail}
							toggle={toggleMailgunModal}
							lenderPacket={lenderPacket}
						/>
					</ChildrenModal>
				</div>
			)}
		</>
	);
};

export default MailgunEmail;
