import React from "react";
import { Card, CardBody, CardText, Row, Col, CardHeader } from "reactstrap";
import ReserveCalculator from "./ReserveCalculator";
import LenderFeeCalculator from "./LenderFeeCalculator";
import shallow from "zustand/shallow";
import { CurrencyInput } from "../../../../view/formValidation/InputElement";
import useCurrentRecapStore from "../../../../store/RecapStore";
import useCurrentDealStore from "../../../../store/DealStore";
import { DealStatus } from "Constants";

const SummaryRow = ({ label, value, onChange, disabled }) => {
	return (
		<Row className="p-0">
			<Col md="6">
				<CardText>{label}</CardText>
			</Col>
			<CurrencyInput
				value={value}
				onChange={onChange}
				disabled={disabled}
				readOnly={disabled}
				disableValidation
				wholeRow
			/>
		</Row>
	);
};

const RecapSummaryCard = () => {
	const {
		frontendProfit,
		backendProfit,
		//lenderFee,
		incentive,
		finderReward,
		//editLenderFee,
		editIncentive,
		editFinderReward,
		gpBeforeFees,
		gpBeforeReserve,
		gpBeforeComm,
		receivable,
		netProfit,
		//		totalComm,
		commGross,
	} = useCurrentRecapStore((state) => state, shallow);

	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);

	return (
		<Card>
			<div>
				<CardHeader>
					<h3>Deal Summary</h3>
					<hr />
				</CardHeader>
				<CardBody>
					<SummaryRow
						disabled={true}
						value={frontendProfit}
						label={"Frontend Gross Profit"}
					/>
					<SummaryRow
						disabled={true}
						value={backendProfit}
						label={"Backend Gross Profit"}
					/>
					<hr />
					<SummaryRow
						disabled={true}
						value={gpBeforeFees}
						label={"GP before Fees"}
					/>
					<LenderFeeCalculator />
					<SummaryRow
						disabled={type !== DealStatus.PENDING}
						value={incentive}
						onChange={editIncentive}
						label={"Dealer Incentive"}
					/>
					<SummaryRow
						disabled={type !== DealStatus.PENDING}
						value={finderReward}
						onChange={editFinderReward}
						label={"Finder Reward"}
					/>
					<SummaryRow
						disabled={true}
						value={gpBeforeReserve}
						label={"GP before Reserve"}
					/>
					<ReserveCalculator disabled={type !== DealStatus.PENDING} />
					<SummaryRow
						disabled={true}
						value={gpBeforeComm}
						label={"GP before Comm."}
					/>
					<SummaryRow
						disabled={true}
						value={commGross}
						label={"Total Commission"}
					/>
					<SummaryRow disabled={true} value={netProfit} label={"Net Profit"} />
					<SummaryRow disabled={true} value={receivable} label={"Receivable"} />
				</CardBody>
			</div>
		</Card>
	);
};
export default RecapSummaryCard;
