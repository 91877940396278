import React from "react";
import { Form } from "reactstrap";

import InputElement from "view/formValidation/InputElement";
import LenderWrapper from "../LenderWrapper";

import { newOrUsed } from "Constants";
import { IntegerInputElement } from "view/formValidation/InputElement";
import { SelectorElement } from "view/formValidation/InputElement";

import { useFormContext } from "react-hook-form";

const UACVehicle = ({ fields, setters, cardId, cardTitle }) => {
	// Get validation context
	const methods = useFormContext();

	return (
		<Form id={cardId}>
			<LenderWrapper cardId={cardId} cardTitle={cardTitle}>
				<InputElement
					{...methods}
					colSize="col-lg-3"
					value={fields.vin}
					onChange={(e) => setters.setVin(e.target.value)}
					name="vehicleVin"
					label="VIN"
					type="text"
				/>
				<IntegerInputElement
					{...methods}
					colSize="col-lg-3"
					value={fields.odometerIn}
					onChange={setters.setOdometerIn}
					name="vehicleOdometer"
					label="Odometer"
					type="text"
				/>
				<InputElement
					{...methods}
					colSize="col-lg-3"
					value={fields.year}
					onChange={(e) => setters.setYear(e.target.value)}
					name="vehicleYear"
					label="Vehicle Year"
					type="number"
				/>
				<InputElement
					{...methods}
					colSize="col-lg-3"
					value={fields.make}
					onChange={(e) => setters.setMake(e.target.value)}
					name="vehicleMake"
					label="Vehicle Make"
					type="text"
				/>
				<InputElement
					{...methods}
					colSize="col-lg-3"
					value={fields.model}
					onChange={(e) => setters.setModel(e.target.value)}
					name="vehicleModel"
					label="Vehicle Model"
					type="text"
				/>
				<InputElement
					{...methods}
					colSize="col-lg-3"
					value={fields.trim}
					onChange={(e) => setters.setTrim(e.target.value)}
					name="vehicleTrim"
					label="Vehicle Trim"
					type="text"
				/>
				<SelectorElement
					{...methods}
					colSize="col-lg-3"
					name="vehicleStatus"
					label="Vehicle Status"
					value={fields.newUsed}
					onChange={(e) => setters.setNewUsed(e.target.value)}
					options={newOrUsed}
				/>
			</LenderWrapper>
		</Form>
	);
};

export default UACVehicle;
