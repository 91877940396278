import React from "react";
import { Form } from "reactstrap";

import InputWrapper from "view/formValidation/InputWrapper";

import { useCompanyEmployeeData } from "./employees/CompanyEmployeesContext";
import { MultiCheckboxes } from "view/formValidation/InputElement";
import { SelectorElement } from "view/formValidation/InputElement";

import { useFormContext } from "react-hook-form";

const UserRoleAndPrivileges = () => {
	const {
		selectedRole,
		privilegeOptions,
		setSelectedRole,
		setSelectedPrivileges,
	} = useCompanyEmployeeData();

	const roles = [
		{ label: "Administrator", value: "admin" },
		{ label: "Employee", value: "user" },
	];

	const methods = useFormContext();

	return (
		<Form>
			<InputWrapper
				formTitle="Role & Privilege(s)"
				inputComponents={[
					<div className="col-12 d-flex justify-content-center align-items-center mb-3">
						<SelectorElement
							{...methods}
							colSize="col-sm-4"
							value={selectedRole}
							name="employeeRole"
							selectText="Select new user's role"
							onChange={(e) => setSelectedRole(e.target.value)}
							options={roles}
						/>
					</div>,
					<>
						{selectedRole !== "admin" && (
							<MultiCheckboxes
								options={privilegeOptions}
								onChange={(e) =>
									setSelectedPrivileges((prev) => ({
										...prev,
										[e.target.value]: !prev[e.target.value],
									}))
								}
							/>
						)}
					</>,
				]}
			/>
		</Form>
	);
};

export default UserRoleAndPrivileges;
