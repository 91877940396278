/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)

* Coded by DealerClick Software **RoarinLion))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// import { useState, useEffect } from "react";

import CardContentBottom from "component/dc/card/CardContentBottom";

// import { retrieveFailAlert } from "component/dc/modal/SweetAlerts";
// import { useFromUser } from "store/LocalStorageHelper";

// reactstrap components
import { Card, Row, Col, CardTitle } from "reactstrap";
// import { getNumberOfCustomers } from "api/DealAPI";
// import { showApiError } from "../../helperFunction/ApiErrorHandler";
import { NavLink } from "react-router-dom";
import CardContent from "../../component/dc/card/CardContent";

const CustomerHeader = ({
	leadCount,
	creditAppCount,
	customerCount,
	lenderCount,
	status,
	setStatus,
}) => {
	// const [totalCustomers, setTotalCustomers] = useState([]);
	// const locationID = useFromUser("locationID");
	// const [leads, setLeads] = useState(0);
	// const [previous, setprevious] = useState(0);
	// const [pending, setPending] = useState(0);
	// const [lost, setLost] = useState(0);

	// const dataFormat = (content, setHook) => {
	// 	var total = 0;
	// 	console.log(content);
	// 	const container = Object.keys(content).map((key) => {
	// 		total = total + content[key].numOfType;
	// 		if (content[key].typeDesc === "Leads") {
	// 			setLeads(content[key].numOfType);
	// 		}
	// 		if (content[key].typeDesc === "Previous") {
	// 			setprevious(content[key].numOfType);
	// 		}
	// 		if (content[key].typeDesc === "Pending") {
	// 			setPending(content[key].numOfType);
	// 		}
	// 		if (content[key].typeDesc === "Lost") {
	// 			setLost(content[key].numOfType);
	// 		}

	// 		return (
	// 			<li key={key.toString()}>
	// 				<div>
	// 					{content[key].typeDesc} : {content[key].numOfType}
	// 				</div>
	// 			</li>
	// 		);
	// 	});
	// 	setHook(total);
	// 	return container;
	// };

	// const NumberOfCustomers = (LocationID) => {
	// 	console.log(LocationID);
	// 	if (locationID !== "") {
	// 		getNumberOfCustomers(LocationID).then(
	// 			(response) => {
	// 				console.log(response.data.content);
	// 				const result = dataFormat(response.data.content, setTotalCustomers);
	// 				console.log(result, totalCustomers);
	// 				//setcustomerData(result);
	// 			},
	// 			(err) => {
	// 				if (!err.isGeneralError) {
	// 					showApiError(err, retrieveFailAlert);
	// 				}
	// 			}
	// 		);
	// 	}
	// };

	// useEffect(() => {
	// 	NumberOfCustomers(locationID);
	// 	// eslint-disable-next-line
	// }, []);

	return (
		<div className="pt-3 pb-3 mobileHeading">
			<Row className="mx-0">
				<Col className="pb-3 text-center">
					<CardTitle>{/* <h2>Contacts - {status}</h2> */}</CardTitle>
				</Col>
			</Row>
			<Row className="mx-0">
				{/* Card 1 */}
				<Col lg="3" md="6" sm="6" onClick={() => setStatus("Leads")}>
					<Card
						className={status === "Leads" ? "card-stats-active" : "card-stats"}
					>
						<NavLink
							to="/customer-list"
							activeClassName=""
							className="text-dark"
						>
							<CardContent
								iconClass="icon-big text-center icon-warning nc-icon nc-single-02 text-success"
								title1="Active Leads"
								title2={leadCount}
								check={status === "Leads"}
							/>
						</NavLink>
						<CardContentBottom
							navClass="col-xs-3"
							routePath="/lead-add"
							// iconClass="nc-icon nc-simple-add"
							// iconText=" Add New"
						/>
					</Card>
				</Col>

				{/* Card 2 */}
				<Col lg="3" md="6" sm="6" onClick={() => setStatus("Credit Apps")}>
					<Card
						className={
							status === "Credit Apps" ? "card-stats-active" : "card-stats"
						}
					>
						<NavLink
							to="/customer-list"
							activeClassName=""
							className="text-dark"
						>
							<CardContent
								iconClass="icon-big text-center icon-warning nc-icon nc-single-02 text-warning"
								title1="Active Credit Apps"
								title2={creditAppCount}
								check={status === "Credit Apps"}
							/>
						</NavLink>
						<CardContentBottom
							navClass="col-xs-3"
							routePath="/deal-list"
							// iconClass="nc-icon nc-minimal-down"
							// iconText=" Open Deals"
						/>
					</Card>
				</Col>

				{/* Card 3 */}
				<Col lg="3" md="6" sm="6" onClick={() => setStatus("Customers")}>
					<Card
						className={
							status === "Customers" ? "card-stats-active " : "card-stats"
						}
					>
						<NavLink
							to="/customer-list"
							activeClassName=""
							className="text-dark"
						>
							<CardContent
								iconClass="icon-big text-center icon-danger nc-icon nc-single-02 text-primary"
								title1="Customers"
								title2={customerCount}
								check={status === "Customers"}
							/>
						</NavLink>
						<CardContentBottom
							navClass="col-xs-3"
							routePath="/customer-list"
							// iconClass="nc-icon nc-minimal-down "
							// iconText=" Sold List"
						/>
					</Card>
				</Col>
				{/* Card 4 */}
				<Col lg="3" md="6" sm="6" onClick={() => setStatus("Lenders")}>
					<Card
						className={
							status === "Lenders" ? "card-stats-active " : "card-stats"
						}
					>
						<NavLink
							to="/customer-list"
							activeClassName=""
							className="text-dark"
						>
							<CardContent
								iconClass="icon-big text-center icon-danger nc-icon nc-single-02 text-danger"
								title1="Lenders"
								title2={lenderCount}
								check={status === "Lenders"}
							/>
						</NavLink>
						<CardContentBottom
							navClass="col-xs-3"
							routePath="/customer-list"
							// iconClass="nc-icon nc-minimal-down"
							// iconText=" Sold List"
						/>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default CustomerHeader;
