import React, { useState, useEffect } from "react";
import useCurrentDealStore from "store/DealStore";
import { useFromUser } from "store/LocalStorageHelper";

import { Button, Form } from "reactstrap";
import InputElement, {
	CurrencyInputElement,
} from "view/formValidation/InputElement";
import InputWrapper from "view/formValidation/InputWrapper";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider } from "react-hook-form";
import { DealStatus } from "Constants";
import shallow from "zustand/shallow";

const DownPaymentForm = (props) => {
	const { currentIndex, setCurrentIndex, toggle } = props;
	const {
		pickupPmts,
		editPickupPmts,
		dealRecNum,
		type,
		calculate,
	} = useCurrentDealStore(
		(state) => ({
			pickupPmts: state.pickupPmts,
			editPickupPmts: state.editPickupPmts,
			dealRecNum: state.deal.dealRecNum,
			type: state.deal.type,
			calculate: state.calculate,
		}),
		shallow
	);

	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const [dateDue, setdateDue] = useState("");
	const [amount, setamount] = useState(0);
	const [datePaid, setdatePaid] = useState("");
	const [amountPaid, setamountPaid] = useState(0);
	const [receiptNo, setreceiptNo] = useState("");

	const clearFields = () => {
		setdateDue("");
		setamount(0);
		setdatePaid("");
		setamountPaid(0);
		setreceiptNo("");
		setCurrentIndex(pickupPmts.length);
	};

	useEffect(() => {
		setdateDue(pickupPmts[currentIndex]?.dateDue || "");
		setamount(pickupPmts[currentIndex]?.amount || "");
		setdatePaid(pickupPmts[currentIndex]?.datePaid || "");
		setamountPaid(pickupPmts[currentIndex]?.amountPaid || "");
		setreceiptNo(pickupPmts[currentIndex]?.receiptNo || "");
		return clearFields;
		// eslint-disable-next-line
	}, [currentIndex]);

	const addPickupPayment = () => {
		const getID = () => {
			if (pickupPmts[currentIndex] === undefined) {
				return null;
			} else if (pickupPmts[currentIndex].ID === undefined) {
				return null;
			} else {
				return pickupPmts[currentIndex].ID;
			}
		};
		const ID = getID();
		const newPickup = {
			dateDue,
			amount: parseFloat(amount),
			datePaid,
			amountPaid: parseFloat(amountPaid),
			receiptNo,
			currentIndex,
			dealerID,
			locationID,
			ID,
			dealRecNum,
		};
		console.log(newPickup);
		console.log("Current index is: " + currentIndex);

		const pickupList = () => {
			let obj = pickupPmts;
			obj[currentIndex] = newPickup;
			return obj;
		};
		const newPickupObj = pickupList();
		editPickupPmts(newPickupObj);
		clearFields();
		calculate();
		toggle();
	};

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
			length: "Required format is YYYY/MM/DD",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({
		dateDue: yup.string().required().max(50),
		amount: yup.string().required().max(50),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<>
			<FormProvider className="" {...methods}>
				<Form>
					<InputWrapper
						formTitle="Add Deferred Payment"
						inputComponents={[
							<InputElement
								{...methods}
								readOnly={type !== DealStatus.PENDING}
								value={dateDue}
								onChange={(e) => {
									setdateDue(e.target.value);
								}}
								name="dateDue"
								label="Due Date"
								type="date"
							/>,
							<CurrencyInputElement
								readOnly={type !== DealStatus.PENDING}
								value={amount}
								onChange={setamount}
								name="amount"
								label="amount"
								type="number"
							/>,
							<InputElement
								{...methods}
								readOnly={type !== DealStatus.PENDING}
								value={datePaid}
								onChange={(e) => {
									setdatePaid(e.target.value);
								}}
								name="datePaid"
								label="Date Paid"
								type="date"
							/>,
							<CurrencyInputElement
								readOnly={type !== DealStatus.PENDING}
								value={amountPaid}
								onChange={setamountPaid}
								name="amountPaid"
								label="Amount Paid"
								type="number"
							/>,
							<InputElement
								{...methods}
								readOnly={type !== DealStatus.PENDING}
								value={receiptNo}
								onChange={(e) => {
									setreceiptNo(e.target.value);
								}}
								name="receiptNo"
								label="Receipt #"
								type="text"
							/>,
						]}
						buttons={
							<div style={{ display: "flex", justifyContent: "center" }}>
								<Button
									readOnly={type !== DealStatus.PENDING}
									color="primary"
									onClick={methods.handleSubmit(addPickupPayment)}
								>
									Save
								</Button>
							</div>
						}
					/>
				</Form>
			</FormProvider>
		</>
	);
};

export default DownPaymentForm;
