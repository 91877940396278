/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)

* Coded by DealerClick Software **Peyman Sabery))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import NumberFormat from "react-number-format";
import ReactTable from "./ReactTable";
import { CreditAppColumnFilter } from "../../../component/dc/table/SelectColumnFilter";

const CreditAppTable = ({ data, setMessageCustomers }) => {
	const Cell = (props) => (
		<div>
			<span style={{ fontWeight: "bold" }}>
				<div>
					{props.row.original.lastName} {props.row.original.firstName}
				</div>
				<div>{props.row.original.origin}</div>
				<div style={{ wordBreak: "break-all" }}>{props.row.original.email}</div>
				<div>
					P:{" "}
					<NumberFormat
						value={props.row.original.phone}
						format="+1 (###) ###-####"
						isNumericString={true}
						displayType="text"
					/>
				</div>
				<div>
					C:{" "}
					<NumberFormat
						value={props.row.original.cellPhone}
						format="+1 (###) ###-####"
						isNumericString={true}
						displayType="text"
					/>
				</div>
				<div>Status : {props.row.original.status}</div>
				{/* <div>ApplicationDate : {props.row.original.applicationDate}</div> */}
			</span>
		</div>
	);
	const vehicleCell = (props) => (
		<div>
			<span style={{ fontWeight: "bold" }}>
				<div>
					{props.row.original.stockNo != null && (
						<div>Stock#: {props.row.original.stockNo}</div>
					)}
				</div>
				<div>
					{props.row.original.invVin != null && (
						<div>VIN: {props.row.original.invVin}</div>
					)}
				</div>
			</span>
		</div>
	);
	const statusCell = (props) => (
		<div>
			<span style={{ fontWeight: "bold" }}>{props.row.original.status}</span>
		</div>
	);
	const dateCell = (props) => (
		<div>
			<span style={{ fontWeight: "bold" }}>
				{props.row.original.applicationDate}
			</span>
		</div>
	);

	// const lookinCell = (props) => (
	// 	<div>
	// 		<div>{props.row.original.vehicle}</div>

	// 		<div>
	// 			{props.row.original.stockNumber != null && (
	// 				<div>Stock#: {props.row.original.stockNumber}</div>
	// 			)}
	// 		</div>
	// 		<div>
	// 			{props.row.original.vin != null && (
	// 				<div>VIN: {props.row.original.vin}</div>
	// 			)}
	// 		</div>

	// 		<div>
	// 			{props.row.original.make != null && (
	// 				<div>Make: {props.row.original.make}</div>
	// 			)}
	// 		</div>
	// 		<div>
	// 			{props.row.original.model != null && (
	// 				<div>Model: {props.row.original.Model}</div>
	// 			)}
	// 		</div>
	// 		<div>
	// 			{props.row.original.year != null && (
	// 				<div>Year: {props.row.original.Year}</div>
	// 			)}
	// 		</div>
	// 	</div>
	// );

	return (
		<div>
			<Row>
				<Col md="12">
					<Card>
						<CardBody>
							<ReactTable
								className="-striped -highlight primary-pagination"
								checkboxColumn={setMessageCustomers != null}
								setSelectedRows={setMessageCustomers}
								data={data}
								columns={[
									{
										Header: "Photo",
										accessor: "thumbnail",
										disableFilters: true,
									},
									{
										id: "CreditAppDetails",
										Header: "Lead",
										accessor: "CreditAppDetails",
										style: { whiteSpace: "unset" },
										Cell: Cell,
									},

									{
										id: "vehicleDetails",
										Header: "Vehicle",
										accessor: "vehicleDetails",
										style: { whiteSpace: "unset" },

										Cell: vehicleCell,
									},
									{
										id: "applicationDate",
										Header: "Date",
										accessor: "applicationDate",

										Cell: dateCell,
									},
									{
										id: "status",
										Header: "status",
										accessor: "status",
										Filter: CreditAppColumnFilter,
										filter: "includes",

										Cell: statusCell,
									},

									// {
									// 	Header: "LookingFor",
									// 	accessor: "lookingFor",
									// 	style: { whiteSpace: "unset" },
									// 	Cell: lookinCell,
									// },
									{
										Header: "Actions",
										accessor: "Actions",
										disableFilters: true,
									},
								]}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default CreditAppTable;
