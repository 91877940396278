import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import {
	Row,
	Card,
	CardBody,
	Col,
	Label,
	CardText,
	Input,
	CardTitle,
} from "reactstrap";
import useCurrentDealStore from "store/DealStore";
import useCurrentSaleStore from "store/SaleStore";
import shallow from "zustand/shallow";

const AftermarketTax = () => {
	const { aftermarket, aftmktTaxRate } = useCurrentDealStore(
		(state) => ({
			aftermarket: state.aftermarket,
			aftmktTaxRate: state?.deal?.dealSettings.taxDefaults?.aftmktTaxRate,
		}),
		shallow
	);

	const { taxRate } = useCurrentSaleStore(
		(state) => ({
			taxRate: state.taxRate,
		}),
		shallow
	);

	const [tRate, setTRate] = useState(0);
	useEffect(() => {
		if (aftmktTaxRate === -1) {
			setTRate(taxRate);
		} else {
			setTRate(aftmktTaxRate);
		}
	}, [taxRate, aftmktTaxRate]);
	console.log(taxRate);
	console.log(aftmktTaxRate);
	const Currency = ({ value }) => {
		return (
			<NumberFormat
				value={value}
				thousandSeparator={true}
				decimalScale={2}
				fixedDecimalScale={true}
				prefix={"$"}
				isNumericString={true}
				displayType="text"
			/>
		);
	};

	const Percent = ({ value }) => {
		return (
			<NumberFormat
				value={value * 100}
				decimalScale={2}
				fixedDecimalScale={true}
				suffix={"%"}
				isNumericString={true}
				displayType="text"
			/>
		);
	};
	console.log(aftmktTaxRate);
	return (
		aftermarket.length > 0 && (
			<Card>
				<h5 className="p-2">AfterMarket</h5>
				<CardTitle className="px-4">
					{aftermarket.length < 1 &&
						"There is no additional aftermarket in this deal."}
				</CardTitle>
				<CardBody>
					{aftermarket.map((element) => (
						<>
							<Row>
								<Col>
									<Label>{element.description}</Label>
								</Col>
								<Col>
									<CardText className="px-5">
										<Input type="checkbox" checked={element.isTaxable} />
									</CardText>
								</Col>
								<Col>
									<CardText>
										<Currency value={element.price} />
									</CardText>
								</Col>
								<Col>
									<CardText>
										<Percent value={element.isTaxable ? tRate : 0} />
									</CardText>
								</Col>
								<Col>
									<CardText>
										<Currency
											value={element.isTaxable ? tRate * element.price : 0}
										/>
									</CardText>
								</Col>
							</Row>

							<hr />
						</>
					))}
				</CardBody>
			</Card>
		)
	);
};

export default AftermarketTax;
