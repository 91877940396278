import React, { useState, useEffect } from "react";
import {
	Button,
	FormGroup,
	Label,
	Input,
	Card,
	Col,
	CardBody,
	CardHeader,
	CardTitle,
	Row,
	Form,
} from "reactstrap";
import useCurrentSettingStore from "store/SettingStore";
import { saveSettings } from "api/SettingsAPI";
import { saveSuccessAlert } from "component/dc/modal/SweetAlerts";
import { taxRateEnum, TaxRegion, TaxType } from "../../../../Constants.js";
import NumberFormat from "react-number-format";
import { retrieveFailAlert } from "component/dc/modal/SweetAlerts";
import { useFromUser } from "store/LocalStorageHelper";
import ChildrenModal from "component/dc/modal/ChildrenModal";
import CountyTax from "./county/CountyTax";
import tabOnEnter from "helperFunction/tabOnEnter";
import { getZipAndCustomTaxRate } from "api/DealAPI";
import { showApiError } from "../../../../helperFunction/ApiErrorHandler";
import { saveFailAlert } from "../../../../component/dc/modal/SweetAlerts";
import shallow from "zustand/shallow";

/**
 *
 */
const TaxDefaultsSetting = () => {
	const dealerZip = useFromUser("locations")["zipCode"];
	const dealerCity = useFromUser("locations")["city"];

	const {
		commissionSettings,
		profitSettings,
		taxDefaults,
		setSettingFields,
	} = useCurrentSettingStore(
		(state) => ({
			commissionSettings: state.commissionSettings,
			profitSettings: state.profitSettings,
			taxDefaults: state.taxDefaults,
			setSettingFields: state.setSettingFields,
		}),
		shallow
	);
	console.log({ taxDefaults });

	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const [openCountyTax, setOpenCountyTax] = useState(false);
	const [taxRatesList, setTaxRatesList] = useState([]);
	const toggleCountyTax = () => setOpenCountyTax(!openCountyTax);

	// Hooks for taxDefaults store values
	const [aftMktTaxRate, setAftMktTaxRate] = useState(null);
	const [servContractTaxRate, setsServContractTaxRate] = useState(null);
	const [gapTaxRate, setGapTaxRate] = useState(null);
	const [docFeeTaxRate, setDocFeeTaxRate] = useState(null);
	const [dmvTaxRate, setDmvTaxRate] = useState(null);
	const [dealerSmogTaxRate, setDealerSmogTaxRate] = useState(null);
	const [taxCreditRate, setTaxCreditRate] = useState(null);
	const [taxCreditFixAmount, setTaxCreditFixAmount] = useState(null);
	const [maxTaxCredit, setMaxTaxCredit] = useState(null);
	const [vitRate, setVitRate] = useState(null);
	const [taxRegion, setTaxRegion] = useState(null);
	const [taxType, setTaxType] = useState(null);
	const [taxRate, setTaxRate] = useState(null);
	const [countyRecNum, setCountyRecNum] = useState(null);

	// Gets the tax rate by zip
	const ZipAndCustomTaxRate = (zip) => {
		console.log(zip);
		console.log(countyRecNum);
		let cRecnum = countyRecNum == null ? 0 : countyRecNum;
		//		getTaxRateByZip(zip).then(
		getZipAndCustomTaxRate(dealerID, locationID, zip, cRecnum).then(
			(res) => {
				console.log(res.data.content);
				const formattedRatesList = formatTaxRates(res.data.content);
				setTaxRatesList(formattedRatesList);
			},
			(err) => {
				console.log(err);

				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	const getCountyRecNum = (taxRegion) => {
		let xCity = "";
		if (taxRegion === 2) {
			xCity = "";
		} else if (taxRegion === 1 && dealerZip != null) {
			console.log(dealerZip);
			xCity = dealerCity;
		} else {
			console.log(dealerID, locationID, dealerZip);
		}
		const taxRateValue = taxRatesList.find(
			(taxRatesList) => taxRatesList.city === xCity
		);
		if (taxRateValue.value === "") {
			setCountyRecNum(null);
			setTaxRate(0);
		} else {
			setCountyRecNum(Number(taxRateValue.value));
			setTaxRate(taxRateValue.taxRate);
		}
		console.log(countyRecNum);
	};

	// Obj for creating components
	const ratesObj = [
		{
			name: "aftMktTaxRate",
			value: aftMktTaxRate,
			onChange: setAftMktTaxRate,
			rateLabel: "Aftermarket Tax Rate",
			dropdownLabel: "Tax Aftermarket",
		},
		{
			name: "aftMktTaxRate",
			value: servContractTaxRate,
			onChange: setsServContractTaxRate,
			rateLabel: "Service Contract Tax Rate",
			dropdownLabel: "Tax Service Contract",
		},
		{
			name: "gapTaxRate",
			value: gapTaxRate,
			onChange: setGapTaxRate,
			rateLabel: "Gap Insurance Tax Rate",
			dropdownLabel: "Tax Gap Insurance",
		},
		{
			name: "docFeeTaxRate",
			value: docFeeTaxRate,
			onChange: setDocFeeTaxRate,
			rateLabel: "Doc Fee Tax Rate",
			dropdownLabel: "Tax Doc Fee",
		},
		{
			name: "dmvTaxRate",
			value: dmvTaxRate,
			onChange: setDmvTaxRate,
			rateLabel: "DMV Tax Rate",
			dropdownLabel: "Tax DMV Fees",
		},
		{
			name: "dealerSmogTaxRate",
			value: dealerSmogTaxRate,
			onChange: setDealerSmogTaxRate,
			rateLabel: "Dealer Smog Tax Rate",
			dropdownLabel: "Tax Dealer Smog",
		},
	];

	const save = () => {
		let oldSettings = { ...taxDefaults };
		oldSettings.aftMktTaxRate = aftMktTaxRate;
		oldSettings.servContractTaxRate = servContractTaxRate;
		oldSettings.gapTaxRate = gapTaxRate;
		oldSettings.docFeeTaxRate = docFeeTaxRate;
		oldSettings.dmvTaxRate = dmvTaxRate;
		oldSettings.dealerSmogTaxRate = dealerSmogTaxRate;
		oldSettings.taxRegion = parseInt(taxRegion);
		oldSettings.taxCreditRate = parseFloat(taxCreditRate);
		oldSettings.taxCreditFixAmount = parseFloat(taxCreditFixAmount);
		oldSettings.maxTaxCredit = parseFloat(maxTaxCredit);
		oldSettings.vitRate = parseFloat(vitRate);
		oldSettings.taxType = parseInt(taxType);
		oldSettings.taxRate = parseFloat(taxRate);
		oldSettings.countyRecNum = countyRecNum;

		console.log(oldSettings);

		const settings = {
			profitSettings,
			taxDefaults: oldSettings,
			commissionSettings,
		};
		setSettingFields(settings);
		saveSettings(settings).then(
			(res) => {
				console.log(res);
				saveSuccessAlert();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	const TaxDefaultSaveButton = () => {
		return (
			<Button
				color="success"
				onClick={() => {
					save();
				}}
				size="md"
			>
				<i className="nc-icon nc-cloud-download-93"></i> Save
			</Button>
		);
	};

	const taxComponents = ratesObj.map((obj) => {
		return <TaxComponent obj={obj} />;
	});

	const optionList = taxRatesList.map((rate) => (
		<option key={rate.value} value={rate.value}>
			{rate.label}
		</option>
	));

	const regionDropdown = (
		<>
			<Col md="3">
				<Label>Tax Type</Label>
				<Input
					className="bold"
					name="selectTaxType"
					type="select"
					value={taxType}
					onChange={(e) => {
						console.log(e.target.value);
						setTaxType(parseInt(e.target.value));
					}}
				>
					<option value={TaxType[0].value}>{TaxType[0].label}</option>
					<option value={TaxType[1].value}>{TaxType[1].label}</option>
				</Input>
			</Col>
			<Col md="3">
				<Label>Select Tax Region</Label>
				<Input
					className="bold"
					name="selectTaxRegion"
					type="select"
					value={taxRegion}
					onChange={(e) => {
						setTaxRegion(parseInt(e.target.value));
						getCountyRecNum(parseInt(e.target.value));
					}}
				>
					<option value={TaxRegion[0].value}>{TaxRegion[0].label}</option>
					<option value={TaxRegion[1].value}>{TaxRegion[1].label}</option>
					<option value={TaxRegion[2].value}>{TaxRegion[2].label}</option>
				</Input>
			</Col>

			<Col md="3">
				{/* <TaxRateSetting localTaxRegion={taxRegion} /> */}
				<FormGroup className="row-12">
					<Label>
						Tax County
						{/* "Tax Last Updated : "{dayjs(county?.startDate).format("MM/DD/YYYY")} */}
					</Label>
					<Input
						className="bold"
						name="taxRate"
						disabled={taxRegion !== 2}
						type="select"
						value={countyRecNum === null ? "" : countyRecNum}
						onChange={(e) => {
							handleSelect(e.target.value);
						}}
					>
						{optionList}
					</Input>
				</FormGroup>
			</Col>
			<Col md="3">
				<>
					<Label>Tax Rate (%)</Label>
					<NumberFormat
						className="bold"
						name="settingsTaxRate"
						value={taxRate * 100}
						readOnly={
							// eslint-disable-next-line
							taxRegion === 2 && countyRecNum === null ? false : true
						}
						decimalScale={4}
						fixedDecimalScale={true}
						onFocus={(e) => e.target.select()}
						isNumericString={true}
						customInput={Input}
						onValueChange={(values) => {
							if (values.value === "") {
								setTaxRate(0);
							} else {
								setTaxRate(values.floatValue / 100);
							}
							if (values.floatValue !== taxRate * 100) {
								console.log(countyRecNum);
							}
						}}
					/>
				</>
			</Col>
		</>
	);

	const taxCredit = (
		<>
			<Col md="3">
				<FormGroup>
					<Label>Trade In Tax Credit Rate (%)</Label>
					<NumberFormat
						className="bold"
						name="taxCreditRate"
						value={taxCreditRate * 100}
						decimalScale={4}
						fixedDecimalScale={true}
						onFocus={(e) => e.target.select()}
						isNumericString={true}
						customInput={Input}
						onValueChange={(values) => {
							if (values.value === "") {
								setTaxCreditRate(0);
							} else {
								setTaxCreditRate(values.floatValue / 100);
							}
						}}
					/>
				</FormGroup>
			</Col>
			<Label>+</Label>
			<Col md="3">
				<FormGroup>
					<Label>Tax Credit Fixed Amount</Label>
					<NumberFormat
						className="bold"
						value={taxCreditFixAmount}
						name="taxCreditFixAmount"
						thousandSeparator={true}
						decimalScale={0}
						prefix={"$"}
						fixedDecimalScale={true}
						onFocus={(e) => e.target.select()}
						isNumericString={true}
						customInput={Input}
						onValueChange={(values) => {
							if (values.value === "") {
								setTaxCreditFixAmount(0);
							} else {
								setTaxCreditFixAmount(values.value);
							}
						}}
					/>
				</FormGroup>
			</Col>
			{/* taxCreditFixAmount
     		maxTaxCredit		 */}
			<Col md="3">
				<FormGroup>
					<Label>Maximum Tax Credit</Label>
					<NumberFormat
						className="bold"
						value={maxTaxCredit}
						name="maxTaxCredit"
						thousandSeparator={true}
						decimalScale={0}
						prefix={"$"}
						fixedDecimalScale={true}
						onFocus={(e) => e.target.select()}
						isNumericString={true}
						customInput={Input}
						onValueChange={(values) => {
							if (values.value === "") {
								setMaxTaxCredit(0);
							} else {
								setMaxTaxCredit(values.value);
							}
						}}
					/>
				</FormGroup>
			</Col>
		</>
	);
	const otherTax = (
		<>
			<Col md="3">
				<FormGroup>
					<Label>Vehicle Inventory TaxRate (VIT)</Label>
					<NumberFormat
						className="bold"
						name="vitRate"
						value={vitRate}
						decimalScale={8}
						fixedDecimalScale={true}
						onFocus={(e) => e.target.select()}
						isNumericString={true}
						customInput={Input}
						onValueChange={(values) => {
							if (values.value === "") {
								setVitRate(0);
							} else {
								setVitRate(values.floatValue);
							}
						}}
					/>
				</FormGroup>
			</Col>
		</>
	);

	// Create the html option tags with the rates array of objects
	// Format the rates into a JSX options, to be used in a selector
	const formatTaxRates = (taxRates) => {
		const options = taxRates.map((tax) => {
			const rate = Number(parseFloat(tax.taxRate) * 100).toFixed(4);
			return {
				city: tax.city,
				label: `${tax.zip} - ${tax.city}, ${tax.state} - ${rate}%`,
				value: tax.recNum,
				taxRate: tax.taxRate,
				obj: tax,
			};
		});
		const optionsWithNone = [
			...options,
			{
				city: "",
				label: "None",
				value: "",
				taxRate: 0,
				obj: null,
			},
		];
		return optionsWithNone;
	};

	const handleSelect = (ID) => {
		console.log(ID);
		if (ID === "" || ID === null) {
			setCountyRecNum(null);
			setTaxRate(taxDefaults.taxRate);
		} else if (ID) {
			// select does not register null as a value its returning the label instead
			const rateObj = taxRatesList.find((rate) => rate.value === Number(ID));
			if (rateObj) {
				setCountyRecNum(Number(ID));
				setTaxRate(rateObj.taxRate);
			} else {
				setCountyRecNum(null);
				setTaxRate(0);
			}
		}
	};

	useEffect(() => {
		ZipAndCustomTaxRate(dealerZip);
		// eslint-disable-next-line
	}, [taxRegion]);

	useEffect(() => {
		//value for tax region
		setTaxRegion(taxDefaults.taxRegion);
		setTaxType(taxDefaults.taxType);
		//value(%) of specific rate
		setAftMktTaxRate(taxDefaults?.aftMktTaxRate);
		setsServContractTaxRate(taxDefaults?.servContractTaxRate);
		setGapTaxRate(taxDefaults?.gapTaxRate);
		setDocFeeTaxRate(taxDefaults?.docFeeTaxRate);
		setDmvTaxRate(taxDefaults?.dmvTaxRate);
		setDealerSmogTaxRate(taxDefaults?.dealerSmogTaxRate);
		setTaxCreditRate(taxDefaults?.taxCreditRate);
		setTaxCreditFixAmount(taxDefaults?.taxCreditFixAmount);
		setMaxTaxCredit(taxDefaults?.maxTaxCredit);
		setVitRate(taxDefaults?.vitRate);
		handleSelect(taxDefaults.countyRecNum);
		// eslint-disable-next-line
	}, [JSON.stringify(taxDefaults), JSON.stringify(taxRatesList)]);

	return (
		<div onKeyDown={tabOnEnter}>
			<Form>
				<Row className="mx-0 pb-2">
					<Col>
						<TaxDefaultSaveButton />
					</Col>

					<Col>
						<div
							className="btn btn-primary-dark btn-md pull-right"
							onClick={toggleCountyTax}
						>
							County Tax
						</div>
						<ChildrenModal
							modalTitle="County Tax Rates"
							modal={openCountyTax}
							toggle={toggleCountyTax}
						>
							<CountyTax toggle={toggleCountyTax} />
						</ChildrenModal>
					</Col>
				</Row>
				<Card>
					<CardHeader>
						<CardTitle tag="h3">Tax Settings</CardTitle>
					</CardHeader>
					<CardBody>
						<Col>
							<Row className="mx-0">{regionDropdown}</Row>
							<Row className="mx-0">{taxComponents}</Row>
							<Row className="mx-0">{taxCredit}</Row>
							<Row className="mx-0">{otherTax}</Row>
						</Col>
					</CardBody>
				</Card>
			</Form>
		</div>
	);
};

export default TaxDefaultsSetting;

export const TaxComponent = ({ obj, updateStore = null }) => {
	const setToggleState = (dbValue) => {
		console.log({ dbValue });
		if (
			dbValue === taxRateEnum.DEFAULT_TAX_RATE ||
			dbValue === taxRateEnum.NONTAXABLE
		) {
			return false;
		} else return true;
	};
	const initializeToSelect = (dbValue) => {
		console.log({ dbValue });
		if (dbValue === taxRateEnum.DEFAULT_TAX_RATE) {
			return "-1";
		} else if (dbValue === taxRateEnum.NONTAXABLE) {
			return "0";
		} else {
			return "1";
		}
	};
	const [toggle, setToggle] = useState();
	const [select, setSelect] = useState();

	useEffect(() => {
		setToggle(setToggleState(obj.value));
		setSelect(initializeToSelect(obj.value));
	}, [obj.value]);
	return (
		<>
			<Col md="3">
				<FormGroup>
					<Label>{obj.dropdownLabel}</Label>
					<Input
						className="bold"
						name={obj.name}
						type="select"
						value={select}
						onChange={(e) => {
							setSelect(e.target.value);
							if (e.target.value === "-1" || e.target.value === "0") {
								setToggle(false);
								obj.onChange(Number(e.target.value));
								if (updateStore !== null) {
									updateStore(obj.name, Number(e.target.value));
								}
							} else {
								setToggle(true);
								obj.onChange("0");
								if (updateStore !== null) {
									updateStore(obj.name, "0");
								}
							}
						}}
					>
						<option value="0">Non-Taxable</option>
						<option value="-1">Default Tax Rate</option>
						<option value="1">Specific Rate</option>
					</Input>
				</FormGroup>
			</Col>

			{toggle && (
				<Col md="3">
					<FormGroup>
						<Label>{obj.rateLabel + " (%)"}</Label>
						<NumberFormat
							name={`${obj.name}%`}
							value={obj.value * 100}
							decimalScale={4}
							fixedDecimalScale={true}
							onFocus={(e) => e.target.select()}
							isNumericString={true}
							customInput={Input}
							onValueChange={(values) => {
								if (values.value === "") {
									obj.onChange(0);
									if (updateStore !== null) {
										updateStore(obj.name, 0);
									}
								} else {
									obj.onChange(values.floatValue / 100);
									if (updateStore !== null) {
										updateStore(obj.name, values.floatValue / 100);
									}
								}
							}}
						/>
					</FormGroup>
				</Col>
			)}
		</>
	);
};
