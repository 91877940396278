import React, { useState } from "react";
import { Button, Form } from "reactstrap";

import FileUploader from "../fileUploader/FileUploader";
import InputWrapper from "../../../view/formValidation/InputWrapper";

import { saveFailAlert, saveSuccessAlert } from "../modal/SweetAlerts";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";
import { uploadDealerAttachments } from "../../../api/AttachmentsAPI";
import { ATTACHMENT_CATEGORY } from "../../../Constants";
import InputElement, {
	SelectorElement,
} from "../../../view/formValidation/InputElement";

import { lazy, mixed, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers";
import { FormProvider, useForm } from "react-hook-form";

/**
 * Uploads an attachment to the Ftp server
 *
 * @param {number} relatedID Required. ID of deal, inventory, or customer
 * @param {String} type Required. One of: deal, inventory, customer
 * @param {React.SetStateAction} setRefreshAttachments Required. Sets state to refresh table data
 * @param {Function} toggle Required. Toggles the upload modal to close
 */
const UploadAttachmentButton = ({
	relatedID,
	type,
	setRefreshAttachments,
	toggle,
}) => {
	// Dealer info from local storage
	const dealerInfo = JSON.parse(sessionStorage.getItem("user"));
	const dealerID = dealerInfo.dealerID;
	const locationID = dealerInfo.locationID;

	const acceptableFileTypes = [
		".png",
		".jpeg",
		".jpg",
		".txt",
		".csv",
		".pdf",
		".xlsx",
		".xls",
		".docx",
		".doc",
	];

	// Local states
	const [uploads, setUploads] = useState([]);
	const [category, setCategory] = useState(null);
	const [uploadErrors, setUploadErrors] = useState([]);

	// Calls api to upload every attachment
	// If at least one attachment was successfully uploaded, refresh the table and close modal
	// If at least one upload error was returned, refresh table but don't close modal
	const uploadAttachment = () => {
		const formData = new FormData();

		// Add each file to the form data and modify file info object
		const fileInfo = uploads.map((obj) => {
			formData.append("files", obj);
			return {
				fileName: obj.name,
				description: "",
				category,
				dealerID,
				locationID,
				type,
				relatedID,
			};
		});

		formData.append(
			"fileInfo",
			new Blob([JSON.stringify(fileInfo)], {
				type: "application/json",
			})
		);

		uploadDealerAttachments(
			dealerID,
			locationID,
			type,
			relatedID,
			formData
		).then(
			(res) => handleResponse(res.data.content, res.data.message),
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	// Append the db attachments objects to the table, if any
	// If no errors exist for an upload, close modal. Else, leave
	// modal open and show errors
	const handleResponse = (data, msg) => {
		const errors = data.errors;

		setRefreshAttachments((prev) => !prev);
		setUploadErrors(errors);
		saveSuccessAlert(msg);

		if (errors.length < 1) {
			toggle();
		}
	};

	// Returns UI for users to add info about each file or remove a file from upload
	const fileInfo = () =>
		uploads.map((obj, i) => (
			<div className="col-lg-6" key={obj.name}>
				<div className="row">
					<InputElement
						{...methods}
						colSize="col-10"
						readOnly
						value={obj.name}
						name={`uploadFile_${i}`}
						label=""
					/>
					<div className="col-2 d-flex justify-content-center align-items-center">
						<div
							className="red-close-icon"
							onClick={() => {
								setUploads((prev) => {
									let modifiedUploads = [...prev];
									delete modifiedUploads[i];
									return modifiedUploads;
								});
							}}
						>
							<i className="fa fa-close" />
						</div>
					</div>
				</div>
			</div>
		));

	// Validation for each input field
	const getSchemaObj = (obj) => {
		let schemaObj = {};

		Object.keys(obj).map((key) => {
			if (key.includes("uploadFile")) {
				return (schemaObj = {
					...schemaObj,
					[key]: string().matches(/^(?:[a-zA-Z\d]+[-_.\s]{0,1})+\.[a-zA-Z]+/, {
						message:
							"Invalid File name. Cannot contain more than 1 consecutive - _ . or space.",
					}),
				});
			} else if (key === "uploadCategory") {
				return (schemaObj = {
					...schemaObj,
					[key]: string().required("Required"),
				});
			}

			return (schemaObj = { ...schemaObj, [key]: mixed() });
		});

		return schemaObj;
	};

	// Define the fields to put validations on
	const schema = lazy((obj) => object(getSchemaObj(obj)));

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<div>
			<FormProvider {...methods}>
				<Form>
					<InputWrapper
						inputComponents={[
							<SelectorElement
								{...methods}
								colSize="col-lg-7 mb-3"
								value={category}
								onChange={(e) => setCategory(e.target.value)}
								name={`uploadCategory`}
								label="Category"
								options={ATTACHMENT_CATEGORY}
							/>,
							fileInfo(),
						]}
					/>
					<div className="d-flex justify-content-center align-items-center mb-3">
						<FileUploader
							maxFileSize={50}
							setUploads={setUploads}
							accept={acceptableFileTypes.join(",")}
							acceptArray={acceptableFileTypes}
						/>
						<Button
							readOnly={uploads.length === 0}
							onClick={methods.handleSubmit(uploadAttachment)}
						>
							Upload
						</Button>
					</div>
				</Form>
			</FormProvider>

			{uploadErrors.length > 0 && (
				<div className="col-12 file-uploader-list">
					<p className="file-uploader-list-title">Failed to save some files:</p>
					<ul>
						{uploadErrors.map((err, i) => (
							<li key={`${err[0]}_${i}`}>{err}</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};

export default UploadAttachmentButton;
