import React from "react";
import { Button } from "reactstrap";

import { deleteDealerAttachments } from "../../../api/AttachmentsAPI";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";
import {
	failAlert,
	saveSuccessAlert,
	twoOptionsAlert,
} from "../modal/SweetAlerts";

/**
 * Calls api to delete an attachment
 *
 * @param {number} dcAttachmentId Required to delete from DB as well as delete file
 * @param {Function} toggleDetailsModal Required. Closes details modal
 * @param {React.SetStateAction} setRefreshAttachments Required. Sets state to refresh table data
 */
const DeleteAttachment = ({
	dcAttachmentId,
	toggleDetailsModal,
	setRefreshAttachments,
}) => {
	// Asks for user confirmation then deletes an attachment if confirmed
	const deleteAttachments = async () => {
		const option = await twoOptionsAlert(
			"Are you sure you want to delete this attachment?",
			"Yes",
			"Cancel"
		);

		if (option === "Cancel") {
			return;
		}

		deleteDealerAttachments(dcAttachmentId).then(
			(res) => handleResponse(res.data.message),
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}
			}
		);
	};

	// Handle response
	const handleResponse = (msg) => {
		saveSuccessAlert(msg);
		toggleDetailsModal();
		setRefreshAttachments((prev) => !prev);
	};

	return (
		<Button color="danger" onClick={deleteAttachments}>
			Delete
		</Button>
	);
};

export default DeleteAttachment;
