import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "reactstrap";

import useCurrentDealStore from "store/DealStore";
import useCurrentRecapStore from "store/RecapStore";
import useCurrentSaleStore from "store/SaleStore";
import useBuildDeal from "../../../../helperFunction/deal/useBuildDeal";
import DealTemplateList from "./DealTemplateList";
import TemplatePaymentOption from "./TemplatePaymentOption";
import TemplateSale from "./TemplateSale";

import { deleteDealTemplate } from "api/DealAPI";
import { getDealTemplates } from "api/DealAPI";
import { saveDealTemplate } from "api/DealAPI";
import { saveSuccessAlert } from "component/dc/modal/SweetAlerts";
import { showApiError } from "../../../../helperFunction/ApiErrorHandler";
import { twoOptionsAlert } from "component/dc/modal/SweetAlerts";
import { useFromUser } from "store/LocalStorageHelper";
import {
	deleteFailAlert,
	retrieveFailAlert,
	saveFailAlert,
} from "../../../../component/dc/modal/SweetAlerts";

import shallow from "zustand/shallow";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider } from "react-hook-form";
import useSetDeal from "../../../../helperFunction/deal/useSetDeal";
import useCurrentSettingStore from "../../../../store/SettingStore";

const DealTemplate = () => {
	// Grab information from local storage
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const setDeal = useSetDeal();
	const getDeal = useBuildDeal();

	// Local states
	const [templates, setTemplates] = useState([]); // List of templates and deal obj in each index retrieved from db
	const [selectedIndex, setSelectedIndex] = useState(-1); // The current selected template index from the template list
	const [currentTemplate, setCurrentTemplate] = useState({}); // Currently selected template
	const [templateName, setTemplateName] = useState("");
	// const [settings, setSettings] = useState({});

	const {
		taxDefaults,
		commissionSettings,
		profitSettings,
	} = useCurrentSettingStore((state) => state, shallow);

	// Sale store
	const { resetSale } = useCurrentSaleStore(
		(state) => ({
			resetSale: state.reset,
		}),
		shallow
	);

	// Deal store
	const {
		resetDeal,
		editIsTemplate,
		editGapCoName,
		editDealSettings,
	} = useCurrentDealStore(
		(state) => ({
			resetDeal: state.reset,
			editIsTemplate: state.editIsTemplate,
			editGapCoName: state.editGapCoName,
			editDealSettings: state.editDealSettings,
		}),
		shallow
	);

	// Recap store
	const { resetRecap } = useCurrentRecapStore(
		(state) => ({
			resetRecap: state.reset,
		}),
		shallow
	);

	// Updates the deal object because we don't need a lot of these fields for
	// templates, so set them to null or empty
	const updateDealObj = () => {
		const dealObj = getDeal();
		let modifiedDeal = { ...dealObj };

		modifiedDeal.deal.dealSettings = JSON.stringify({});
		modifiedDeal.deal.isTemplate = true;
		modifiedDeal.deal.soldDate = null;
		modifiedDeal.deal.createdOn = null;
		modifiedDeal.deal.recap.recapDate = null;

		modifiedDeal.deal.sale = {
			...modifiedDeal.deal.sale,
			amtFinanced: 0,
			financeCharge: 0,
			loan: 0,
			salesTax: 0,
			tax: 0,
			totalTaxable: 0,
			taxableAmount: 0,
			finalPmt: 0,
			payment: 0,
			firstDueDate: null,
			finalDueDate: null,
			loanDate: null,
			balloon: 0.0,
			balloonTerm: 0,
			balloonDate: "",
			hasBalloon: false,
		};
		return modifiedDeal;
	};

	// Saves the form information as a new deal template and append it to the
	// template list
	const saveTemplate = () => {
		const updatedDealInfo = updateDealObj();
		const templateInfo = {
			ID: null,
			dealID: null,
			name: templateName,
			dealerID,
			locationID,
		};

		const payload = {
			deal: updatedDealInfo,
			template: templateInfo,
		};

		saveDealTemplate(payload).then(
			(res) => {
				const content = res.data.content;

				setTemplates([...templates, { ...content }]);
				setCurrentTemplate({ ...content.template });
				editGapCoName(content.gapCoName);
				setSelectedIndex(templates.length);
				saveSuccessAlert("Successfully saved deal template.");
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	// Updates a saved deal template and update the template list
	const updateTemplate = () => {
		const updatedDealInfo = updateDealObj();
		const templateInfo = { ...currentTemplate, name: templateName };

		const payload = {
			deal: updatedDealInfo,
			template: templateInfo,
		};

		saveDealTemplate(payload).then(
			(res) => {
				const content = res.data.content;
				let currentTemplates = [...templates];

				currentTemplates[selectedIndex] = { ...content };

				setTemplates(currentTemplates);
				setCurrentTemplate({ ...content.template });
				editGapCoName(content.gapCoName);
				saveSuccessAlert("Successfully updated deal template.");
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	// Deletes a deal template if user confirms that they want to delete it
	const shouldDeleteTemplate = async () => {
		const optionYes = "Yes";
		const optionNo = "No";

		const option = await twoOptionsAlert(
			"Are you sure you want to delete this template?",
			optionYes,
			optionNo
		);
		const dealObj = getDeal();

		if (option === optionYes) {
			const payload = {
				deal: dealObj,
				template: templates[selectedIndex].template,
			};

			payload.deal.deal.sale.firstDueDate = "";
			payload.deal.deal.sale.finalDueDate = "";
			payload.deal.deal.sale.balloonDate = "";

			deleteDealTemplate(payload).then(
				(res) => {
					let templatesCopy = [...templates];

					console.log(res);
					templatesCopy.splice(selectedIndex, 1);
					setTemplates(templatesCopy);
					setSelectedIndex(-1);
					saveSuccessAlert("Successfully deleted deal template");
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, deleteFailAlert);
					}
				}
			);
		}
	};

	// Set the store values to the selected template, else empty
	useEffect(() => {
		if (Number(selectedIndex) !== -1) {
			console.log(templates[selectedIndex]);
			setCurrentTemplate(templates[selectedIndex].template);
			setTemplateName(templates[selectedIndex].template.name);
			// doing this to conform to setDeal rules
			let deal = {};
			let data = {};
			let content = {};

			deal.data = data;
			deal.data.content = content;
			deal.data.content = templates[selectedIndex].deal;

			setDeal(deal);
			console.log(taxDefaults);
			// TODO remove this when template has setting include
			editDealSettings({ taxDefaults, commissionSettings, profitSettings });
			editGapCoName(templates[selectedIndex].gapCoName);
			editIsTemplate(true);
		} else {
			setTemplateName("");
			setCurrentTemplate({});
			resetDeal();
			resetSale();
			resetRecap();
			editIsTemplate(true);
		}
		// eslint-disable-next-line
	}, [selectedIndex]);

	// Resets store information and gets the list of all the dealer's template
	// upon initial render
	useEffect(() => {
		resetDeal();
		resetSale();
		resetRecap();
		editIsTemplate(true);

		getDealTemplates(dealerID, locationID).then(
			(res) => {
				console.log(res);
				setTemplates(res.data.content.templates);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);

		return () => {
			resetDeal();
			resetSale();
			resetRecap();
		};
		// eslint-disable-next-line
	}, []);

	// Get validation context
	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
			// eslint-disable-next-line
			min: "Must be at least ${min} characters long",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({
		templateName: yup.string().required().max(50),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});
	return (
		<div>
			<Card className="p-3">
				<DealTemplateList
					templates={templates}
					selected={selectedIndex}
					setSelected={setSelectedIndex}
				/>
				<FormProvider {...methods}>
					<Row>
						<Col lg="6">
							<TemplatePaymentOption
								templateName={templateName}
								setTemplateName={setTemplateName}
							/>
						</Col>
						<Col lg="6" className="p-0 m-0">
							<TemplateSale />
						</Col>
					</Row>
					<div className="d-flex justify-content-center mt-3">
						{(Number(selectedIndex) === -1 && (
							<Button
								color="primary"
								onClick={methods.handleSubmit(saveTemplate)}
							>
								Save
							</Button>
						)) || (
							<div className="align-items-center">
								<Button
									color="primary"
									onClick={methods.handleSubmit(updateTemplate)}
								>
									Update
								</Button>
								<Button color="danger" onClick={shouldDeleteTemplate}>
									Delete
								</Button>
							</div>
						)}
					</div>
				</FormProvider>
			</Card>
		</div>
	);
};

export default DealTemplate;
