/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)

* Coded by DealerClick Software **RoarinLion))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Dashboard from "../view/dashboard/Dashboard";
import InventoryAddPage from "../view/inventory/InventoryAddPage";
import InventoryListPage from "../view/inventory/InventoryListPage";
import CustomerDetailPage from "../view/contact/CustomerDetailPage";
import CustomerListPage from "../view/contact/CustomerListPage";
import CustomerAddPage from "../view/contact/CustomerAddPage";
import DealListPage from "../view/deal/DealListPage";
import DevLogin from "../view/login/DevLogin.js";
import BuyersGuideView from "../view/form/inventory/state/ca/form/BuyersGuideView";
import InventoryDetailsPage from "../view/inventory/InventoryDetailPage";
import DealAddPage from "../view/deal/DealAddPage";
import DealDetailPage from "../view/deal/DealDetailPage";
import { PATHS } from "../Constants.js";
import { QuickQuoteDeal } from "../view/deal/QuickQuoteDeal";

const routes = [
	{
		path: PATHS.DASHBOARD,
		name: "Dashboard",
		icon: "nc-icon nc-layout-11",
		component: Dashboard,
		layout: "/dev",
	},
	{
		path: PATHS.LOGIN,
		name: "Login",
		icon: "nc-icon nc-simple-add",
		component: DevLogin,
		layout: "/dev",
		invisible: true,
	},
	{
		collapse: true,
		name: "Inventory",
		icon: "nc-icon nc-delivery-fast",
		state: "inventoryCollapse",
		views: [
			{
				path: PATHS.INVENTORY_LIST,
				name: "View Inventory",
				icon: "nc-icon nc-bullet-list-67",
				component: InventoryListPage,
				layout: "/dev",
			},
			{
				path: PATHS.INVENTORY_ADD,
				name: "Add New Inventory",
				icon: "nc-icon nc-simple-add",
				component: InventoryAddPage,
				layout: "/dev",
			},
			{
				path: PATHS.INVENTORY_DETAIL,
				name: "Inventory Details",
				component: InventoryDetailsPage,
				layout: "/dev",
				invisible: true,
			},
		],
	},
	{
		collapse: true,
		name: "Contacts",
		icon: "nc-icon nc-single-02",
		state: "contactCollapse",
		views: [
			{
				path: PATHS.CUSTOMER_LIST,
				name: "View Customers",
				icon: "nc-icon nc-bullet-list-67",
				component: CustomerListPage,
				layout: "/dev",
			},
			{
				path: PATHS.LEAD_ADD,
				name: "Add Lead",
				icon: "nc-icon nc-simple-add",
				component: CustomerAddPage,
				layout: "/dev",
			},
			{
				path: PATHS.CUSTOMER_DETAIL,
				name: "Customer Detail",
				component: CustomerDetailPage,
				layout: "/dev",
				invisible: true,
			},
			{
				path: PATHS.LEAD_DETAIL,
				name: "Lead Detail",
				component: CustomerDetailPage,
				layout: "/dev",
				invisible: true,
			},
		],
	},
	{
		collapse: true,
		name: "Deals",
		icon: "nc-icon nc-briefcase-24",
		state: "dealCollapse",
		views: [
			{
				path: PATHS.DEAL_LIST,
				name: "View Deals",
				icon: "nc-icon nc-bullet-list-67",
				component: DealListPage,
				layout: "/dev",
			},
			{
				path: PATHS.DEAL_ADD,
				name: "New Deal",
				icon: "nc-icon nc-simple-add",
				component: DealAddPage,
				layout: "/dev",
			},
			{
				path: PATHS.QuikQuote,
				name: "Quik Quote",
				icon: "nc-icon nc-bullet-list-67",
				component: QuickQuoteDeal,
				layout: "/dev",
			},
			{
				path: PATHS.DEAL_DETAIL,
				name: "Deal Detail",
				component: DealDetailPage,
				layout: "",
				invisible: true,
			},
		],
	},
	{
		collapse: true,
		name: "Reports",
		icon: "nc-icon nc-refresh-69",
		state: "reportsCollapse",
		invisible: "true",
		views: [],
	},
	//{
	// collapse: true,
	// name: "Communication",
	// icon: "fa fa-comments-o",
	// state: "communicationCollapse",
	// views: [
	// {
	// 	path: PATHS.MESSAGE_TEMPLATES,
	// 	name: "Templates",
	// 	icon: "nc-icon nc-bullet-list-67",
	// 	component: MessageTemplates,
	// 	layout: "",
	// },
	// ],
	//},
	{
		collapse: true,
		name: "Forms",
		icon: "nc-icon nc-refresh-69",
		state: "formsCollapse",
		invisible: "true",
		views: [
			{
				path: PATHS.BUYERS_GUIDE,
				name: "Buyers Guide",
				icon: "nc-icon nc-bullet-list-67",
				component: BuyersGuideView,
				layout: "/dev",
			},
		],
	},
];

export default routes;
