import { checkAdminPassword } from "api/LoginAPI";
import ChildrenModal from "component/dc/modal/ChildrenModal";
import { failAlert } from "component/dc/modal/SweetAlerts";
import { showApiError } from "helperFunction/ApiErrorHandler";
import React, { useState } from "react";
import { Button, Col, FormGroup } from "reactstrap";
import { useFromUser } from "store/LocalStorageHelper";
import InputElement from "view/formValidation/InputElement";
import VoidRepoForm from "./VoidRepoForm";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { setLocale } from "yup";
import { FormProvider, useForm } from "react-hook-form";
const VoidRepoAdminForm = ({ toggleAdminForm }) => {
	const user = useFromUser("username");

	const [adminPassword, setAdminPassword] = useState("");
	const [open, setOpen] = useState(false);

	const toggle = () => {
		setOpen(!open);
	};
	const handleContinue = () => {
		const userinfo = {
			username: user,
			password: adminPassword,
		};
		checkAdminPassword(userinfo).then(
			(res) => {
				if (res.data.content === true) {
					toggle();
				}
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}
			}
		);
	};
	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
	});
	const schema = yup
		.object()
		.shape({ password: yup.string().min(1).max(26).required() });

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<div>
			<Col>
				<FormProvider>
					<FormGroup>
						<InputElement
							{...methods}
							type="password"
							onChange={(e) => setAdminPassword(e.target.value)}
							value={adminPassword}
							name="password"
							colSize="col-lg"
							label="Administrator Password"
						/>

						<div className="text-center">
							<Button
								color="info"
								onClick={methods.handleSubmit(handleContinue)}
							>
								<i></i> Continue
							</Button>
						</div>
					</FormGroup>
				</FormProvider>
			</Col>
			<ChildrenModal
				modal={open}
				toggle={toggle}
				size="s"
				modalTitle="Void Detail"
				children={
					<VoidRepoForm toggleAdminForm={toggleAdminForm} toggle={toggle} />
				}
			/>
		</div>
	);
};

export default VoidRepoAdminForm;
