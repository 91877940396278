import React, { useState, useEffect } from "react";
import {
	Col,
	CardTitle,
	Button,
	Modal,
	ModalBody,
	ModalHeader,
	Card,
	CardBody,
	Row,
} from "reactstrap";

import Loading from "component/dc/load/Loading";
import OpenModalButton from "../../component/dc/deal/OpenModalButton";

import { InventoryAdvertisingData } from "./InventoryAdvertisingData";
import { InventoryAdvertisingModal } from "./InventoryAdvertisingModal";
import { showApiError } from "../../helperFunction/ApiErrorHandler";
import { retrieveFailAlert } from "component/dc/modal/SweetAlerts";
import { getPartnerSend } from "../../api/dealerIntegrationsApi";
import { useFromUser, useUser } from "../../store/LocalStorageHelper";
import { successAlertCustom } from "component/dc/modal/SweetAlerts";

const InventoryAdvertisingPage = () => {
	const [addModal, setAddModal] = useState(false);

	const [getData, setGetData] = useState([]);
	const [feedName, setFeedName] = useState("");

	const locationID = useFromUser("locationID");
	const locations = useFromUser("locations");
	const login = useUser();

	const [sendFeed, setSendFeed] = useState("");

	const [loadingList, setLoadingList] = useState(true);

	const toggleAdd = () => {
		setAddModal(!addModal);
	};

	const checkSubscription = () => {
		getPartnerSend(locationID).then(
			(response) => {
				console.log(response.data.content);
				let formattedData = [...InventoryAdvertisingData];
				formattedData.forEach((data) => {
					// gets the value for the sendVariable received from endpoint
					data.sendValue = response.data.content[data.partnerSendVariable];
					setLoadingList(false);
				});

				console.log(formattedData);
				setGetData(formattedData);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	const requestSubscription = () => {
		successAlertCustom(
			"Please contact Dealerclick to activate your subscription."
		);
	};

	const requestSignUp = (signUpFor) => {
		console.log(signUpFor);
		if (signUpFor === "siriusXM") {
			const XMRadioSignup =
				"https://siriusxmdealertrial.com/enrollNowPost.aspx?dmstype=dealerclick" +
				"&dealerphone=" +
				locations.phone +
				"&dealerid=" +
				locations.dealerID +
				"&dealername=" +
				login.dealers.companyName +
				"&address1=" +
				locations.address +
				"&address2=''" +
				"&city=" +
				locations.city +
				"&state=" +
				locations.state +
				"&zip=" +
				locations.zipCode +
				"&authfname=" +
				login.firstName +
				"&authlname=" +
				login.lastName +
				"&authphone=" +
				login.phone +
				"&authemail=" +
				login.email;

			window.open(XMRadioSignup, "_blank", "noopener,noreferrer");
		} else {
			window.open(signUpFor, "_blank", "noopener,noreferrer");
		}
		// if (signUpFor === "autoTrader") {
		// 	window.open(
		// 		"https://www.autotrader.com/account/sign-up",
		// 		"_blank",
		// 		"noopener,noreferrer"
		// 	);
		// }
	};
	console.log(getData);
	// Address row items
	const content = getData
		? getData.map((data) => (
				<>
					<div className="col-sm-3 pt-3">
						<Card>
							<CardBody>
								<div>
									<img className="adImage" alt={data.Name} src={data.Image} />
								</div>
								{data.sendValue > 0 && (
									<OpenModalButton
										readOnly={!data.sendValue}
										buttonClass="btn-round btn-bg"
										onClick={() => {
											toggleAdd();
											setSendFeed(data.sendVariable);
											setFeedName(data.Name);
										}}
										buttonColor="primary"
										buttonText="Manage"
									/>
								)}
								{data.sendValue === 0 && (
									<Button
										className="btn-round btn-bg"
										color="link"
										readOnly={data.sendValue}
										onClick={requestSubscription}
									>
										Request
									</Button>
								)}
								{data.signUp !== "" && (
									<Button
										className="btn-round btn-bg"
										color="link"
										onClick={() => {
											requestSignUp(data.signUp);
										}}
									>
										SignUp
									</Button>
								)}
							</CardBody>
						</Card>
					</div>
				</>
		  ))
		: null;
	useEffect(() => {
		checkSubscription();
		// eslint-disable-next-line
	}, []);
	return (
		<div>
			{loadingList && <Loading />}
			<Col className="text-center pt-3 pb-3 mobileHeading">
				<CardTitle>
					<h2>Advertising</h2>
				</CardTitle>
				<Row> {content}</Row>
			</Col>
			<Modal
				returnFocusAfterClose={false}
				isOpen={addModal}
				toggle={toggleAdd}
				size="xl"
			>
				<ModalHeader toggle={toggleAdd}>{feedName}</ModalHeader>
				<ModalBody>
					<InventoryAdvertisingModal
						toggle={toggleAdd}
						sendVariable={sendFeed}
					/>
				</ModalBody>
			</Modal>
		</div>
	);
};

export default InventoryAdvertisingPage;
