import React, { useContext, useState } from "react";
import { Form, Button, Row, Col } from "reactstrap";

import tabOnEnter from "helperFunction/tabOnEnter";
import AllValuesWrapper from "./print/allValues/AllValuesWrapper";
import AllValues from "./AllValues";
import BookSheet from "./print/bookSheet/BookSheet";
import InputElement from "view/formValidation/InputElement";
import InputWrapper from "../../../../../view/formValidation/InputWrapper";
import KbbEquipmentSection from "./KbbEquipmentSection";
import KbbValues from "./KbbValues";

import CenteredLoadingSpinner from "component/dc/load/CenteredLoadingSpinner";
import ChildrenModal from "component/dc/modal/ChildrenModal";

import { retrieveAllSelected } from "./kbbHelperFunctions";
import { KbbContext } from "./KbbContext";
import { decodeVin, getKbbValues } from "../../../../../api/KbbAPI";
import { useFromUser } from "store/LocalStorageHelper";

import { IntegerInput } from "view/formValidation/InputElement";
import { SelectorElement } from "../../../../../view/formValidation/InputElement";
import { kbbConditionOptions } from "Constants";
import { failAlert } from "component/dc/modal/SweetAlerts";

import dayjs from "dayjs";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider } from "react-hook-form";

import "assets/css/kbb-all-values.css";
import "assets/css/kbb-book-sheet.css";

const InventoryKbbTab = ({ inventoryID, vehicleKbbObj }) => {
	const IN_DEV = process.env.NODE_ENV === "development";
	// Today's date
	const today = dayjs().format("MM/DD/YYYY");

	// User store
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const dealerZip = useFromUser("locations").zipCode;

	// Progress spinner state
	const [showSubmitProgress, setShowSubmitProgress] = useState(false);

	// KBB context
	const {
		getDcKbbObj,
		setDcKbbObj,
		dcTableKbb,
		setDcTableKbb,
		setDcTableKbbEquipment,
		setDcTableKbbValues,
		condition,
		setCondition,
	} = useContext(KbbContext);

	const [showAllValues, setShowAllValues] = useState(false);

	// Toggles the all values modal
	const toggleAllValues = () => setShowAllValues(!showAllValues);

	const [style, setStyle] = useState("");
	const [styleList, setStyleList] = useState([]);
	const [styleModal, setStyleModal] = useState(false);

	// Toggler for the file details modal
	const toggleStyleModal = () => setStyleModal(!styleModal);

	const createStyleList = (styles) => {
		console.log(styles);
		const tempStyle = styles.map((style) => {
			return {
				label: style.trimName,
				value: style.trimName,
				data: style,
			};
		});
		setStyleList(tempStyle);
		setStyle(tempStyle[0].label);
		getInitialValues(styles[0]);
	};

	const getKbbDetails = () => {
		setShowSubmitProgress(true);
		decodeVin(vehicleKbbObj.vin).then(
			(res) => {
				const data = res.data.content.vinResults;
				console.log(res.data.content);
				createStyleList(data);
			},
			(err) => {
				console.log(err);
				failAlert("Failed to decode vehicle with vin: " + vehicleKbbObj.vin);
				setShowSubmitProgress(false);
			}
		);
	};

	// merge data from vinDecode into DcKbbObj
	// This is the only time we can create the initial dcTableKbbEquipment array and set vehicleId
	const getInitialValues = (kbbVehicleDetails) => {
		console.log(kbbVehicleDetails);
		const details = kbbVehicleDetails;
		const options = details.vehicleOptions;
		// TODO need to check if vehicleId is the same as previous so that equipment dont duplicate
		vehicleKbbObj.editYear(String(details.yearId));
		vehicleKbbObj.editMake(details.makeName);
		vehicleKbbObj.editModel(details.modelName);

		if (dcTableKbb?.kbbVehicleId !== details.vehicleId) {
			const dcEquipmentArr = options.map((obj) => {
				return {
					carRecNum: inventoryID,
					vehicleOptionID: obj.vehicleOptionId,
					categoryGroup: obj.categoryGroup,
					equip: obj.optionName,
					equipType: obj.optionType.charAt(0).toUpperCase(),
					isSelected: obj.isTypical,
					row: obj.sortOrder,
					vehicleId: details.vehicleId,
				};
			});
			setDcTableKbbEquipment(dcEquipmentArr);
			setDcTableKbbValues([]);
		}

		setDcTableKbb((prev) => {
			return {
				...prev,
				dealerID,
				locationID,
				condition,
				inventoryID: inventoryID,
				bookDate: dayjs().format("YYYY-MM-DD"),
				trimStyle: details.trimName,
				kbbVehicleId: details.vehicleId,
				isBooked: true,
			};
		});
		setShowSubmitProgress(false);
	};

	const updateKbbValues = () => {
		setShowSubmitProgress(true);
		if (dcTableKbb?.kbbVehicleId) {
			const kbb = getDcKbbObj();
			const request = {
				kbb,
				valuesRequest: {
					configuration: {
						vehicleId: dcTableKbb.kbbVehicleId,
						vehicleOptionIds: retrieveAllSelected(kbb.dcTableKbbEquipment),
					},
					mileage: vehicleKbbObj.odometerIn,
					zipCode: dealerZip,
					valuationDate: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
				},
			};

			getKbbValues(request).then((res) => {
				console.log(request);
				setDcKbbObj(res.data.content);
				console.log(res.data.content);
				setShowSubmitProgress(false);
			});
		} else {
			failAlert("Decode KBB and select a condition, then update values.");
			setShowSubmitProgress(false);
		}
	};

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
			// eslint-disable-next-line
			length: "Must be ${length} characters long",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({
		enteredVin: yup.string().required().max(50),
		kbbOdometer: yup.string().required().max(50),
		kbbCondition: yup.string().required(),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<>
			<FormProvider {...methods}>
				{(showSubmitProgress && <CenteredLoadingSpinner />) || (
					<div>
						<>
							<Row>
								<Col className="pull-left">
									<BookSheet
										inventoryID={inventoryID}
										vehicleKbbObj={vehicleKbbObj}
									/>
								</Col>
								<Col className="pull-right">
									<div className="row mx-0 pull-right">
										<Button
											className="btn-primary btn-sm"
											onClick={toggleAllValues}
										>
											<i className="nc-icon nc-paper"></i> All Values
										</Button>
										<ChildrenModal
											modal={showAllValues}
											toggle={toggleAllValues}
											modalTitle="All Values"
										>
											<AllValues>
												<div className="d-flex justify-content-between mb-3">
													<AllValuesWrapper />
												</div>
											</AllValues>
										</ChildrenModal>
									</div>
								</Col>
							</Row>
							<div>
								<KbbValues />
							</div>
						</>
						<div>
							<Form onKeyDown={tabOnEnter}>
								<Col>
									<InputWrapper
										formTitle="KBB Vehicle Details"
										inputComponents={[
											<InputElement
												{...methods}
												colSize="col-lg-3"
												value={vehicleKbbObj.vin || ""}
												name="enteredVin"
												label="VIN"
												type="text"
												onChange={(e) => vehicleKbbObj.editVin(e.target.value)}
											/>,
											<IntegerInput
												{...methods}
												colSize="col-lg-3"
												value={vehicleKbbObj.odometerIn}
												name="kbbOdometer"
												label="Odometer"
												type="text"
												onChange={vehicleKbbObj.editOdometerIn}
											/>,
											<Col className="align-self-center">
												<Button
													className="btn-primary btn btn-sm"
													type="submit"
													onClick={methods.handleSubmit((data) => {
														getKbbDetails();
														toggleStyleModal();
													})}
												>
													<i className="nc-icon nc-refresh-69" /> Decode
												</Button>
												<Button
													className="btn-primary btn btn-sm"
													color="success"
													onClick={methods.handleSubmit(updateKbbValues)}
												>
													Get Values
												</Button>

												<BookSheet
													inventoryID={inventoryID}
													vehicleKbbObj={vehicleKbbObj}
												/>
												{IN_DEV && (
													<Button
														className="btn-primary btn btn-sm"
														//className="btn-link"
														color="success"
														onClick={() => console.log(getDcKbbObj())}
													>
														JSON KBB
													</Button>
												)}
											</Col>,
											<Col
												md="12"
												className="d-flex justify-content-center mb-1.5"
											></Col>,
											<InputElement
												{...methods}
												colSize="col-lg-3"
												value={vehicleKbbObj.year}
												name="kbbYear"
												label="Year"
												type="text"
												onChange={(e) => vehicleKbbObj.editYear(e.target.value)}
												readOnly={true}
											/>,
											<InputElement
												{...methods}
												colSize="col-lg-3"
												value={vehicleKbbObj.make}
												name="kbbMake"
												label="Make"
												type="text"
												onChange={(e) => vehicleKbbObj.editMake(e.target.value)}
												readOnly={true}
											/>,
											<InputElement
												{...methods}
												colSize="col-lg-3"
												value={vehicleKbbObj.model}
												name="kbbModel"
												label="Model"
												type="text"
												onChange={(e) =>
													vehicleKbbObj.editModel(e.target.value)
												}
												readOnly={true}
											/>,

											<InputElement
												{...methods}
												colSize="col-lg-3"
												disabled
												value={dcTableKbb?.kbbVehicleId}
												name="kbbID"
												label="KBB Vehicle ID"
												type="text"
												//onChange={(e) => setKbbID(e.target.value)}
											/>,
											<IntegerInput
												{...methods}
												colSize="col-lg-3"
												disabled
												value={dcTableKbb?.kbbmileageZero}
												name="kbbCutoffMileage"
												label="Typical Mileage"
												type="text"
											/>,
											<SelectorElement
												{...methods}
												name="kbbCondition"
												label="Condition"
												colSize="col-lg-3"
												value={condition}
												onChange={(e) => setCondition(e.target.value)}
												options={kbbConditionOptions}
											/>,
											<InputElement
												{...methods}
												colSize="col-lg-3"
												disabled
												value={dcTableKbb?.bookDate || today}
												name="kbbValuationDate"
												label="Valuation Date"
												type="date"
											/>,
										]}
									/>
								</Col>
							</Form>
							{styleList.length > 1 && (
								<ChildrenModal
									size="lg"
									modalTitle="Select Style"
									modal={styleModal}
									toggle={toggleStyleModal}
									// this might not be working
									// onClosed={updateKbbValues}
								>
									<SelectorElement
										{...methods}
										name="style"
										label="Style"
										value={style}
										onChange={(e) => {
											setStyle(e.target.value);
											const details = styleList.find(
												(style) => style.data.trimName === e.target.value
											).data;
											getInitialValues(details);
											setStyleModal(false);
										}}
										options={styleList}
									/>
								</ChildrenModal>
							)}
						</div>
						<KbbEquipmentSection />
					</div>
				)}
			</FormProvider>
		</>
	);
};

export default InventoryKbbTab;
