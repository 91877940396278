import ChildrenModal from "component/dc/modal/ChildrenModal";
import { DealStatus } from "Constants";
import tabOnEnter from "helperFunction/tabOnEnter";
import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Form, Row } from "reactstrap";
import useCurrentDealStore from "store/DealStore";
import useCurrentSaleStore from "store/SaleStore";
import shallow from "zustand/shallow";
import LabelAndInput from "../../LabelAndInput";
import DMVFeesTable from "./DMVFeesTable";
import DMVPriceSection from "./DMVPriceSection";

// DMV fees calculations are done here
const DMVModal = ({ modal, toggle }) => {
	// Deal store
	const { type } = useCurrentDealStore(
		(state) => ({ type: state.deal.type }),
		shallow
	);

	// Store values
	const {
		titleFee,
		filingFee,
		smogExemption,
		weightFee,
		tireFee,
		bpa,
		editTitleFee,
		editFilingFee,
		editSmogExemption,
		editWeightFee,
		editTireFee,
		editBpa,
	} = useCurrentSaleStore(
		(state) => ({
			titleFee: state.titleFee,
			filingFee: state.filingFee,
			smogExemption: state.smogExemption,
			weightFee: state.weightFee,
			tireFee: state.tireFee,
			bpa: state.bpa,
			totalDMVFees: state.totalDMVFees,
			editTitleFee: state.editTitleFee,
			editFilingFee: state.editFilingFee,
			editSmogExemption: state.editSmogExemption,
			editWeightFee: state.editWeightFee,
			editTireFee: state.editTireFee,
			editBpa: state.editBpa,
		}),
		shallow
	);

	// All the editable input fields, labels, and their edit functions
	let fields = [
		{
			name: "dmvTitleFee",
			value: titleFee,
			edit: editTitleFee,
			label: "Title Fee",
		},
		{
			name: "dmvFilingFee",
			value: filingFee,
			edit: editFilingFee,
			label: "Filing Fee",
		},
		{
			name: "dmvSmogExemption",
			value: smogExemption,
			edit: editSmogExemption,
			label: "Smog Exemption",
		},
		{
			name: "dmvWeightFee",
			value: weightFee,
			edit: editWeightFee,
			label: "Weight Fee",
		},
		{
			name: "dmvTireFee",
			value: tireFee,
			edit: editTireFee,
			label: "Tire Fee",
		},
		{
			name: "dmvBpa",
			value: bpa,
			edit: editBpa,
			label: "Vehicle ROS(BPA)",
		},
	];

	// Returns input fields and a text field that shows the total of the inputs

	//the labels are not printing**

	return (
		<ChildrenModal
			modal={modal}
			toggle={toggle}
			//onClosed={calcDMVFees}
			size="lg"
			modalTitle="DMV Fees"
			children={
				<>
					<div className="col">
						<Form onKeyDown={tabOnEnter}>
							<Card>
								<CardHeader>
									<CardTitle tag="h3">Standard Fees</CardTitle>
								</CardHeader>
								<CardBody>
									<DMVPriceSection />
								</CardBody>
							</Card>
							<Card>
								<CardHeader>
									<CardTitle tag="h3">Manage Fees</CardTitle>
								</CardHeader>
								<CardBody>
									<Row>
										{fields.map((obj) => (
											<div className="col-md-6">
												<LabelAndInput
													name={obj.name}
													readOnly={type !== DealStatus.PENDING}
													value={obj.value}
													label={obj.label}
													onChange={obj.edit}
													buttonLabel={true}
													btnClass="btn btn-md btn-primary disabled w-100"
												/>
											</div>
										))}
									</Row>
									<hr />
								</CardBody>
							</Card>
							<DMVFeesTable />
						</Form>
					</div>
				</>
			}
		/>
	);
};

export default DMVModal;
