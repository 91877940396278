import React from "react";
import NavBar from "component/dc/tab/NavTabsHolder";
import ProfitSettings from "../../../../view/settings/settingsTabs/dealDefault/ProfitSettings";
import CommissionSettings from "../../../../view/settings/settingsTabs/dealDefault/CommissionSetting";
import TaxDefaults from "../../../../view/settings/settingsTabs/dealDefault/TaxDefaults";
import TaxDefaultsSetting from "../../../../view/settings/settingsTabs/dealDefault/TaxDefaultsSetting";

const DealSettings = ({ isDeal = true }) => {
	console.log(isDeal);
	const tabs = ["Tax Settings", "Profit Settings", "Commission Settings"];

	const tabComponents = [
		<TaxDefaults dealSettings={isDeal} />,
		<ProfitSettings dealSettings={isDeal} />,
		<CommissionSettings dealSettings={isDeal} />,
	];
	const tabComponentsSetting = [
		<TaxDefaultsSetting />,
		<ProfitSettings dealSettings={isDeal} />,
		<CommissionSettings dealSettings={isDeal} />,
	];

	return (
		<div className="col">
			<NavBar
				navClass="nav-pills-secondary justify-content-center nav nav-pills"
				tabs={tabs}
				tabComponents={isDeal ? tabComponents : tabComponentsSetting}
			/>
		</div>
	);
};

export default DealSettings;
