import React from "react";

const UnauthorizedContent = () => {
	return (
		<div
			className="d-flex flex-column justify-content-center align-items-center"
			style={{ height: "80vh" }}
		>
			<i
				className="fa fa-exclamation-triangle text-warning mb-3"
				style={{ fontSize: "70px" }}
			/>
			<h3 className="text-center">
				This account is not authorized to access this content
			</h3>
		</div>
	);
};

export default UnauthorizedContent;
