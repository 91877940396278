import React from "react";
import { Row, Col, Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import NumberFormat from "react-number-format";
import useCurrentRecapStore from "store/RecapStore";
import CommissionTable from "component/dc/table/CommissionTable";
import shallow from "zustand/shallow";

const Commission = () => {
	// GrossProfit	= Total of all profits and fees (new)
	// salesGross	= total of Sale commissionable
	// fiGross	= total of F&I commissionable
	// totalGross	= total of all commissionable (new) (edited)

	// profitComm	= total of all commission with type GP(GrossProfit) (new)
	// salesComm	= total of all commission with type Sale (salesGross)
	// fiComm	    = total of all commission with type F&I(fiGross)
	// totalComm	= total of all commission with type TOTAL
	// commGross	= total of all commission

	// netProfit	= profit after commission (new)

	const {
		grossProfit,
		salesGross,
		fiGross,
		totalGross,
		profitComm,
		salesComm,
		fiComm,
		totalComm,
	} = useCurrentRecapStore(
		(state) => ({
			grossProfit: state.grossProfit,
			salesGross: state.salesGross,
			fiGross: state.fiGross,
			totalGross: state.totalGross,
			profitComm: state.profitComm,
			salesComm: state.salesComm,
			fiComm: state.fiComm,
			totalComm: state.totalComm,
		}),
		shallow
	);

	return (
		<Col>
			<Card className="pb-3">
				<CardHeader>
					<CardTitle tag="h3">Total Commissions</CardTitle>
				</CardHeader>
				<CardBody>
					<Row>
						<Col className="col-3"></Col>
						<Col className="col-2">
							<h5>Sale</h5>
						</Col>
						<Col className="col-2">
							<h5>F&I</h5>
						</Col>
						<Col className="col-2">
							<h5>Commissionables</h5>
						</Col>
						<Col className="col-2 text-left">
							<h5>Deal Profit</h5>
						</Col>
					</Row>
					<Row>
						<Col className="col-3 text-left">
							<h5>Commissionable Gross</h5>
						</Col>
						<Col className="col-2 text-left">
							<NumberFormat
								className="bold"
								style={{ border: "none" }}
								value={salesGross}
								name="salesGross"
								thousandSeparator={true}
								decimalScale={2}
								prefix={"$"}
								fixedDecimalScale={true}
								onFocus={(e) => e.target.select()}
								isNumericString={true}
								readOnly={true}
							/>
						</Col>
						<Col className="col-2 text-left">
							<NumberFormat
								className="bold"
								style={{ border: "none" }}
								value={fiGross}
								name="fiGross"
								thousandSeparator={true}
								decimalScale={2}
								prefix={"$"}
								fixedDecimalScale={true}
								onFocus={(e) => e.target.select()}
								isNumericString={true}
								readOnly={true}
							/>
						</Col>
						<Col className="col-2 text-left">
							<NumberFormat
								className="bold"
								style={{ border: "none" }}
								value={totalGross}
								name="totalGross(Both)"
								thousandSeparator={true}
								decimalScale={2}
								prefix={"$"}
								fixedDecimalScale={true}
								onFocus={(e) => e.target.select()}
								isNumericString={true}
								readOnly={true}
							/>
						</Col>
						<Col className="col-2 text-left">
							<NumberFormat
								className="bold"
								style={{ border: "none" }}
								value={grossProfit}
								name="GrossProfit"
								thousandSeparator={true}
								decimalScale={2}
								prefix={"$"}
								fixedDecimalScale={true}
								onFocus={(e) => e.target.select()}
								isNumericString={true}
								readOnly={true}
							/>
						</Col>
					</Row>
					<Row>
						<Col className="col-3 text-left">
							<h5>Commission</h5>
						</Col>
						<Col className="col-2 text-left">
							<NumberFormat
								className="bold"
								style={{ border: "none" }}
								value={salesComm}
								name="saleComm"
								thousandSeparator={true}
								decimalScale={2}
								prefix={"$"}
								fixedDecimalScale={true}
								onFocus={(e) => e.target.select()}
								isNumericString={true}
								readOnly={true}
							/>
						</Col>
						<Col className="col-2 text-left">
							<NumberFormat
								className="bold"
								style={{ border: "none" }}
								value={fiComm}
								name="fiComm"
								thousandSeparator={true}
								decimalScale={2}
								prefix={"$"}
								fixedDecimalScale={true}
								onFocus={(e) => e.target.select()}
								isNumericString={true}
								readOnly={true}
							/>
						</Col>
						<Col className="col-2 text-left">
							<NumberFormat
								className="bold"
								style={{ border: "none" }}
								value={totalComm}
								name="commGross"
								thousandSeparator={true}
								decimalScale={2}
								prefix={"$"}
								fixedDecimalScale={true}
								onFocus={(e) => e.target.select()}
								isNumericString={true}
								readOnly={true}
							/>
						</Col>
						<Col className="col-2 text-left">
							<NumberFormat
								className="bold"
								style={{ border: "none" }}
								value={profitComm}
								name="CommNetProfit"
								thousandSeparator={true}
								decimalScale={2}
								prefix={"$"}
								fixedDecimalScale={true}
								onFocus={(e) => e.target.select()}
								isNumericString={true}
								readOnly={true}
							/>
						</Col>
					</Row>
				</CardBody>
			</Card>
			<CommissionTable />
		</Col>
	);
};

export default Commission;
