import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import InputWrapper from "view/formValidation/InputWrapper";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { setLocale } from "yup";
import {
	saveFailAlert,
	saveSuccessAlert,
	continueOpenForm,
	retrieveFailAlert,
} from "../modal/SweetAlerts";
import InputElement from "view/formValidation/InputElement";
import { saveVehicle } from "api/InventoryAPI";
import useBuildDeal from "../../../helperFunction/deal/useBuildDeal";
import shallow from "zustand/shallow";
import { saveDeal } from "api/DealAPI";
import useCurrentDealStore from "../../../store/DealStore";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";
import useSetDeal from "../../../helperFunction/deal/useSetDeal";

const FormsModal = ({ toggle }) => {
	const getDeal = useBuildDeal();
	const setDeal = useSetDeal();

	// Vehicle store
	const { editCarRecNum, editVehicle, vehicle } = useCurrentDealStore(
		(state) => ({
			editCarRecNum: state.editCarRecNum,
			editVehicle: state.editVehicle,
			vehicle: state.vehicle,
		}),
		shallow
	);

	const [ROS, setROS] = useState(vehicle?.reportOfSale || "");
	const [odometerOut, setOdometerOut] = useState(
		vehicle?.odometerOut < vehicle?.odometerIn || vehicle?.odometerOut === 0
			? vehicle?.odometerIn
			: vehicle?.odometerOut
	);

	const handleContinue = () => {
		const inventoryID = vehicle?.inventoryID;

		if (inventoryID !== null && vehicle.inventoryID) {
			let updatedVehicle = { ...vehicle };
			updatedVehicle.odometerOut = odometerOut;
			updatedVehicle.reportOfSale = ROS;
			const request = {
				vehicle: updatedVehicle,
				// no kbb stuff will get updated, cannot call context here for a simple save
				kbb: { dcTableKbb: null },
			};
			saveVehicle(request).then(
				(res) => {
					const v = res.data.content.vehicle;
					editVehicle(v);
					editCarRecNum(v.inventoryID);
					let deal = getDeal();
					deal.vehicle = v;
					deal.deal.carRecNum = v.inventoryID;
					console.log(deal);
					save(deal);
					saveSuccessAlert();
					toggle();
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		} else if (
			vehicle === null ||
			vehicle === {} ||
			inventoryID === null ||
			inventoryID === "" ||
			inventoryID === undefined
		) {
			continueOpenForm();
			toggle();
		}
	};

	const save = (d) => {
		saveDeal(d).then(
			(res) => {
				setDeal(res);
				saveSuccessAlert();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
	});
	const schema = yup.object().shape({
		"Odometer Out": yup
			.number()
			.positive()
			.typeError("Invalid")
			.min(
				vehicle?.odometerIn,
				"Odometer Out must be greater or equal to Odomter In"
			)
			.required(),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		// dont need to do this modal if there is no vehicle
		if (vehicle === null || Object.keys(vehicle).length === 0) {
			toggle();
			const deal = getDeal();
			save(deal);
		}
		// eslint-disable-next-line
	}, []);

	return (
		<div>
			<FormProvider {...methods}>
				<Form className="px-0">
					<InputWrapper
						inputComponents={[
							<InputElement
								{...methods}
								colSize="col-lg"
								value={odometerOut}
								name="Odometer Out"
								label="Odometer Out"
								type="number"
								onChange={(e) => setOdometerOut(e.target.value)}
								labelClass="required"
							/>,
							<InputElement
								{...methods}
								colSize="col-lg"
								value={ROS}
								name="ROS"
								label="Report of Sale"
								type="text"
								onChange={(e) => setROS(e.target.value)}
							/>,

							<Form className="px-3 ">
								<p style={{ color: "red" }}>
									CAUTION: The information above is usually required
								</p>
							</Form>,
						]}
						buttons={
							<div className="d-flex justify-content-center mt-2">
								<Button
									color="primary"
									onClick={methods.handleSubmit(handleContinue)}
								>
									Save
								</Button>
							</div>
						}
					/>
				</Form>
			</FormProvider>
		</div>
	);
};

export default FormsModal;
