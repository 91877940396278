import React, { useState, useEffect } from "react";
import { Card, CardBody, Form, FormGroup, Row } from "reactstrap";
import dayjs from "dayjs";

import InputWrapper from "view/formValidation/InputWrapper";
import { updateLeadStatus } from "api/LeadApi";
import { OnlineLeadStatus } from "../../../Constants";
import InputElement, {
	WorkPhoneInputElement,
	PhoneInputElement,
	SelectorElement,
	EditableSelectorElement,
} from "../../formValidation/InputElement";
import {
	getInventoryMakeList,
	getInventoryModelList,
	getVehicleDetails,
} from "../../../api/InventoryAPI";
import DeleteLeadButton from "../../../component/dc/customer/DeleteLeadButton.js";
import { getPartnerLinks } from "../../../api/dealerIntegrationsApi";
import ChildrenModal from "../../../component/dc/modal/ChildrenModal";
import Experian from "assets/img/experianLogo.png";
import Equifax from "assets/img/equifaxLogo.png";
import Transunion from "assets/img/transUnionLogo.png";
import Credit from "assets/img/700CreditLogo2.png";
import { getLeadDealerNotes, saveLead } from "../../../api/LeadApi";
import Credit700PreScreen from "../Credit700PreScreen";
import Camera from "../../../assets/img/imgPlaceholder.png";
import InventorySlelectList from "../../../component/dc/inventory/InventorySlelectList";
import { FormProvider, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";
import { retrieveFailAlert } from "../../../component/dc/modal/SweetAlerts";
// import NoteButton from "../../../component/dc/note/NoteButton";
import { getNotesArray } from "../../../component/dc/note/NoteFunctions";
import NoteComponent from "../../../component/dc/note/NoteComponent";
// import NoteComponent from "../../../component/dc/note/NoteComponent";

const OnlineLeadDetailsPage = ({ lead, toggle }) => {
	// Lead store
	const dealerInfo = JSON.parse(sessionStorage.getItem("user"));
	const dealerID = dealerInfo.dealerID;
	const locationID = dealerInfo.locationID;
	const [currentLead, setCurrentLead] = useState(lead);

	const [yearList, editYearList] = useState([]);
	const [makeList, editMakeList] = useState([]);
	const [modelList, editModelList] = useState([]);
	//	const [colorList, editColorList] = useState([]);

	const [dealerNote, setDealerNote] = useState([]);
	const [leadNote, setLeadNote] = useState([]);
	const [leadVehicle, setLeadVehicle] = useState({});
	const [status, setStatus] = useState(lead?.leadStatus || 0);
	const [incID, setIncID] = useState(lead?.inventoryID);
	console.log(lead);
	const [tuModal, setTuModal] = useState(false);
	const [xpnModal, setXpnModal] = useState(false);
	const [efxModal, setEfxModal] = useState(false);
	const [isSignup, setIsSignup] = useState(false);
	const schema = yup.object().shape({
		year: yup
			.date()
			.max(new Date().getFullYear() + 1)
			.min(new Date("January 01, 1900 00:00:00").getFullYear())
			.nullable()
			.transform((curr, orig) => (orig?.length === 4 ? curr : null))
			.required("Required (YYYY)"),
	});
	const methods = useFormContext({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	const today = dayjs().format("YYYY-MM-DD");
	const todayTime = dayjs().format("YYYY-MM-DDTHH:mm:ss");

	let defaultLead = {
		toLocationID: locationID,
		firstName: "",
		lastName: "",
		middleName: "",
		gender: "",
		businessName: "",
		workPhone: "",
		workPhoneExt: "",
		dob: "",
		phone: "",
		cellPhone: "",
		Website: "",
		email: "",
		unit: "",
		address: "",
		city: "",
		state: "",
		zipCode: "",
		country: "",
		dateGenerated: todayTime,
		dateRead: null,
		leadStatus: 0,
		leadOrigin: null,
		leadOriginName: "",
		message: "",
		dealerNotes: "",
		priceRange: "",
		year: "",
		make: "",
		model: "",
		trim: "",
		Color: "",
		stockNumber: "",
		inventoryID: null,
		customerID: null,
		salesPersonID: null,
		salesPerson2ID: null,
		source: "",
		lastSync: null,
		insertedOn: today,
		updatedOn: today,
		driverLicense: "",
		driverLicExpDate: null,
		createdBy: "",
		xpnScore: null,
		efxScore: null,
		tuScore: null,
		credit700Url: "",
	}; // Define rules for each input field

	const tuToggle = () => {
		setTuModal(!tuModal);
	};
	const xpnToggle = () => {
		setXpnModal(!xpnModal);
	};
	const efxToggle = () => {
		setEfxModal(!efxModal);
	};
	const handleChange = (name, value) => {
		if (name === "dealerNotes") {
			console.log(value);
			console.log(value.size);
		}
		setCurrentLead((prevLead) => ({
			...prevLead,
			[name]: value,
		}));
	};
	const personal = [
		<InputElement
			disableValidation={true}
			value={currentLead.lastName}
			name="lastName"
			label="Last Name"
			type="text"
			onChange={(e) => handleChange("lastName", e.target.value)}
		/>,
		<InputElement
			disableValidation={true}
			value={currentLead.firstName}
			name="firstName"
			label="First Name"
			type="text"
			onChange={(e) => handleChange("firstName", e.target.value)}
		/>,
		<InputElement
			disableValidation={true}
			value={currentLead.gender}
			name="gender"
			label="Gender"
			type="text"
			onChange={(e) => handleChange("gender", e.target.value)}
		/>,
		<InputElement
			disableValidation={true}
			value={currentLead.dob}
			name="dob"
			label="DateOfBirth"
			type="date"
			onChange={(e) => handleChange("dob", e.target.value)}
		/>,
		<PhoneInputElement
			{...methods}
			disableValidation={true}
			value={currentLead?.cellPhone}
			name="cellPhone"
			label="Cell Phone"
			onChange={(e) => handleChange("cellPhone", e)}
		/>,
		<PhoneInputElement
			{...methods}
			disableValidation={true}
			value={currentLead.phone}
			name="phone"
			label="Primary Phone"
			onChange={(e) => handleChange("phone", e)}
		/>,
		<WorkPhoneInputElement
			disableValidation={true}
			value={
				currentLead.phone +
				(currentLead.workPhoneExt === null ? "" : currentLead.workPhoneExt)
			}
			name="workPhone"
			label="Work Phone"
			onChange={(e) => handleChange("workPhone", e)}
		/>,
		<InputElement
			disableValidation={true}
			value={currentLead.email}
			name="email"
			label="Email"
			type="text"
			onChange={(e) => handleChange("email", e.target.value)}
		/>,
		<InputElement
			disableValidation={true}
			value={currentLead.driverLicense}
			name="driverLicense"
			label="Driver License"
			type="text"
			onChange={(e) => handleChange("driverLicense", e.target.value)}
		/>,
		<InputElement
			disableValidation={true}
			value={currentLead.driverLicExpDate}
			name="driverLicExpDate"
			label="DL ExpDate"
			type="date"
			onChange={(e) => handleChange("driverLicExpDate", e.target.value)}
		/>,
		<InputElement
			disableValidation={true}
			value={currentLead.address}
			name="address"
			label="Street"
			type="text"
			onChange={(e) => handleChange("address", e.target.value)}
		/>,
		<InputElement
			disableValidation={true}
			value={currentLead.unit}
			name="unit"
			label="Apt/Unit/Bld"
			type="text"
			onChange={(e) => handleChange("unit", e.target.value)}
		/>,
		<InputElement
			disableValidation={true}
			value={currentLead.city}
			name="city"
			label="City"
			type="text"
			onChange={(e) => handleChange("city", e.target.value)}
		/>,
		<InputElement
			disableValidation={true}
			value={currentLead.state}
			name="state"
			label="State"
			onChange={(e) => handleChange("state", e.target.value)}
		/>,
		<InputElement
			disableValidation={true}
			value={currentLead.zipCode}
			name="zip"
			label="Zip"
			onChange={(e) => handleChange("zip", e.target.value)}
		/>,
		<InputElement
			disableValidation={true}
			label="Lead Origin"
			name="leadOriginName"
			value={currentLead.leadOriginName}
			//options={AdSource}
			onChange={(e) => handleChange("leadOriginName", e.target.value)}
		/>,
		<InputElement
			disableValidation={true}
			label="CreatedBy"
			name="createdBy"
			value={currentLead.createdBy}
			onChange={(e) => handleChange("createdBy", e.target.value)}
		/>,
	];
	const htmlMessage = (currentLead?.message ?? "")
		.replace(
			/<a href=([^>]+)><b>([^<]+)<\/b><\/a>/g,
			(match, p1, p2) => `<a href="${p1}"><b>${p2}</b></a>`
		)
		.replace(/&amp;/g, "&")
		.replace(/&quot;/g, '"')
		.replace(/&lt;/g, "<")
		.replace(/&gt;/g, ">");
	const message = [
		<>
			<div dangerouslySetInnerHTML={{ __html: htmlMessage }} />
		</>,
	];
	const looking = [
		<EditableSelectorElement
			{...methods}
			value={currentLead?.year}
			label="Year"
			disable={false}
			name="year"
			type="number"
			onChange={(e) => handleChange("year", e)}
			options={yearList}
		/>,

		<EditableSelectorElement
			{...methods}
			disable={false}
			label="Make"
			value={currentLead?.make}
			onChange={(e) => handleChange("make", e)}
			options={makeList}
			name="make"
		/>,
		<EditableSelectorElement
			{...methods}
			disable={false}
			label="Model"
			value={currentLead?.model}
			onChange={(e) => handleChange("model", e)}
			options={modelList}
			name="model"
		/>,

		<InputElement
			disableValidation={true}
			value={currentLead.color}
			name="color"
			label="Color"
			type="text"
			onChange={(e) => handleChange("color", e.target.value)}
		/>,
	];
	const vehicle = [
		<InputElement
			disableValidation={true}
			value={leadVehicle.stockNo}
			readOnly={true}
			name="stockNumber"
			label="StockNumber"
			type="text"
		/>,
		<InputElement
			disableValidation={true}
			value={leadVehicle.vin}
			readOnly={true}
			name="Vin"
			label="Vin"
			type="text"
		/>,
		<InputElement
			disableValidation={true}
			value={leadVehicle.year}
			readOnly={true}
			name="year"
			label="Year"
			type="text"
		/>,

		<InputElement
			disableValidation={true}
			value={leadVehicle?.make}
			readOnly={true}
			name="make"
			label="Make"
			type="text"
		/>,
		<InputElement
			disableValidation={true}
			value={leadVehicle?.model}
			readOnly={true}
			name="model"
			label="Model"
			type="text"
		/>,
		<InputElement
			disableValidation={true}
			value={leadVehicle.color}
			readOnly={true}
			name="color"
			label="Color"
			type="text"
		/>,
		<FormGroup className={"col-sm-3"}></FormGroup>,

		<FormGroup className={"col-sm-3"}>
			<InventorySlelectList editCarRecNum={setIncID} />,
		</FormGroup>,
	];
	const getLeadNotes = (leadID) => {
		getLeadDealerNotes(leadID).then(
			(res) => {
				console.log(res.data);
				console.log(res.data.content.DealerNotes);
				const container = res.data.content.DealerNotes.map((obj) => {
					console.log(obj);
					const note = (
						<>
							<div>
								{dayjs(obj.createdOn).format("MM/DD/YYYY HH:MM") +
									" " +
									obj.description}
							</div>
						</>
					);
					return note;
				});

				setLeadNote(container);
			},
			(err) => {
				console.log(err);
			}
		);
	};
	const saveNotes = () => {
		let tempNote = dealerNote.length > 0 ? JSON.stringify(dealerNote) : "";
		//setDealerNote(dealerNote);
		handleChange("dealerNotes", tempNote);
	};

	// const dealersNote = (
	// 	<NoteComponent
	// 		notes={dealerNote}
	// 		editNotes={setDealerNote}
	// 		saveNotes={saveNotes}
	// 	/>
	// );
	const getVehicleData = (inventoryID) => {
		getVehicleDetails(inventoryID).then(
			(res) => {
				console.log(res.data.content);
				setLeadVehicle(res.data.content);
			},
			(err) => {
				console.log(err);
			}
		);
	};
	//call api to retrieve the see if user sign up or not.
	const get700Signup = () => {
		getPartnerLinks(locationID).then(
			(res) => {
				console.log(res);
				setIsSignup(res.data.content.isCreditSignup);
			},
			(err) => console.log(err)
		);
	};

	const scoreHandler = (score) => {
		if (score === null || score === undefined) {
			return "None";
		} else if (score >= 0) {
			return score;
		} else {
			return "None";
		}
	};
	const getMakeList = () => {
		const makes = [];
		getInventoryMakeList().then(
			(res) => {
				const resp = res.data.content;
				for (const key in resp) {
					makes.push({ label: resp[key], value: resp[key] });
				}
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
		editMakeList(makes);
	};

	const getModelList = () => {
		const models = [];
		getInventoryModelList().then(
			(res) => {
				const resp = res.data.content;
				for (const key in resp) {
					models.push({ label: resp[key], value: resp[key] });
				}
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
		editModelList(models);
	};
	const getYearList = () => {
		const currentYear = new Date().getFullYear() + 1;
		const yearStart = 1900;
		const getYearList = [];
		for (let i = currentYear; i >= yearStart; i--) {
			getYearList.push({ label: i.toString(), value: i.toString() });
		}
		editYearList(getYearList);
	};
	useEffect(() => {
		getYearList();
		getMakeList();
		getLeadNotes(lead?.leadID);
		console.log(lead);
		console.log(lead?.dealerNotes);
		setDealerNote(getNotesArray(lead?.dealerNotes));
		get700Signup();
		if (lead && lead?.leadID) {
			setIncID(lead?.inventoryID);
		} else {
			setCurrentLead(defaultLead);
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		getModelList();
		// eslint-disable-next-line
	}, [lead.make]);
	useEffect(() => {
		getVehicleData(incID);
		handleChange("inventoryID", incID);
		// eslint-disable-next-line
	}, [incID]);
	useEffect(() => {
		setDealerNote(getNotesArray(lead?.dealerNotes));
		// eslint-disable-next-line
	}, [lead?.dealerNotes]);

	useEffect(() => {
		if (lead?.leadID !== null) {
			updateLeadStatus(lead?.leadID, status);
		}
		handleChange("leadStatus", status);

		// eslint-disable-next-line
	}, [status]);
	const ProfilePicture = [
		<div className="col-sm-4 text-center px-0">
			<div className="col-sm">
				<Card className="pt-4 d-flex justify-content-center align-items-center">
					<>
						<img
							className="pb-3 px-3"
							//src={profilePictureSrc}
							src={`https://Dealernetwork.com/images/PersonalImages/${dealerID}/${locationID}/${lead?.leadID}.jpg`}
							onError={(e) => {
								e.target.onerror = null;
								e.target.src = Camera;
							}}
							alt="customer-profile"
						/>
					</>
				</Card>
			</div>
		</div>,
	];

	const PrescreenModals = () => {
		return (
			<>
				<ChildrenModal
					modal={xpnModal}
					toggle={xpnToggle}
					modalTitle={
						<img src={Credit} alt="700credit" className="rounded mb-3 pt-3" />
					}
					children={
						<Credit700PreScreen
							bureau="XPN"
							lead={lead}
							toggle={() => {
								//toggle();
								xpnToggle();
							}}
						/>
					}
				/>
				<ChildrenModal
					modal={tuModal}
					toggle={tuToggle}
					modalTitle={
						<img src={Credit} alt="700credit" className="rounded mb-3 pt-3" />
					}
					children={
						<Credit700PreScreen
							bureau="TU"
							lead={lead}
							toggle={() => {
								//toggle();
								tuToggle();
							}}
						/>
					}
				/>
				<ChildrenModal
					modal={efxModal}
					toggle={efxToggle}
					modalTitle={
						<img src={Credit} alt="700credit" className="rounded mb-3 pt-3" />
					}
					children={
						<Credit700PreScreen
							bureau="EFX"
							lead={lead}
							toggle={() => {
								//toggle();
								efxToggle();
							}}
						/>
					}
				/>
			</>
		);
	};
	const LeadSaveButton = () => {
		return (
			<>
				<div
					className="btn btn-lg btn-success float-button"
					onClick={() => {
						let l = { ...currentLead };
						if (currentLead.leadID == null) {
							l.dateGenerated = todayTime;
							l.insertedOn = today;
							l.updatedOn = today;
						}
						saveLead(l);
						toggle();
					}}
				>
					<i className="nc-icon nc-check-2" /> Save
				</div>
			</>
		);
	};

	return (
		<Form>
			<div className="text-center col-md-12 px-0">
				<Row>
					<SelectorElement
						disableValidation={true}
						value={status}
						name="LeadStatus"
						label="LeadStatus"
						onChange={(e) => setStatus(Number(e.target.value))}
						options={OnlineLeadStatus}
					/>
					<div className="col-xl-2">
						<Card className="zoom cursor-pointer">
							<CardBody onClick={xpnToggle} className="text-center">
								<img src={Experian} alt="experian" className="rounded" />
								Score: {scoreHandler(lead?.xpnScore)}
							</CardBody>
						</Card>
					</div>
					<div className="col-xl-2">
						<Card className="zoom cursor-pointer">
							<CardBody onClick={tuToggle} className="text-center">
								<img src={Transunion} alt="transunion" className="rounded" />
								Score: {scoreHandler(lead?.tuScore)}
							</CardBody>
						</Card>
					</div>
					<div className="col-xl-2">
						<Card className="zoom cursor-pointer">
							<CardBody onClick={efxToggle} className="text-center">
								<img src={Equifax} alt="equifax" className="rounded" />
								Score: {scoreHandler(lead?.efxScore)}
							</CardBody>
						</Card>
					</div>
					<PrescreenModals />
					{!isSignup ? (
						<div className="col-xl-4">
							<Card className="zoom cursor-pointer">
								<a
									href="https://700credit.com/dealerclick"
									target="_blank"
									rel="noopener noreferrer"
									className=""
								>
									<CardBody>
										<img src={Credit} alt="700credit" className="rounded" />
										<div className="p text-center">Sign Up</div>
									</CardBody>
								</a>
							</Card>{" "}
						</div>
					) : (
						" "
					)}
				</Row>
			</div>
			<FormProvider {...methods}>
				<InputWrapper formTitle="Person" inputComponents={personal} />
				<InputWrapper formTitle="Looking For" inputComponents={looking} />
				<InputWrapper formTitle="Vehicle" inputComponents={vehicle} />
				<InputWrapper formTitle="Lead Note" inputComponents={message} />
				<InputWrapper formTitle="Dealer Notes" inputComponents={leadNote} />

				{/* <InputWrapper formTitle="Dealer Notes2" inputComponents={dealersNote} /> */}

				<NoteComponent
					notes={dealerNote}
					editNotes={setDealerNote}
					saveNotes={saveNotes}
				/>
				<div>{ProfilePicture}</div>
				<DeleteLeadButton IDs={lead?.leadID} toggle={toggle} />
				<LeadSaveButton />
				<div>{lead?.leadID}</div>
			</FormProvider>
		</Form>
	);
};

export default OnlineLeadDetailsPage;
