import React, { useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";

import defaultImage from "assets/img/image_placeholder.jpg";

import { actualFileType } from "./ImageUtils";
import { failAlert } from "../modal/SweetAlerts";

const SingleImageUpload = ({
	containerWidth,
	minWidth,
	minHeight,
	maxWidth,
	maxHeight,
	file,
	setFile,
	disablePhotoChange,
	picData,
	setPicData,
}) => {
	const fileInputRef = useRef(null);
	const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);

	// Calls the onClick function of the file input element
	const updateInput = () => fileInputRef.current.click();

	// Resets the image container to default image and removes any uploaded file
	const removeImage = () => {
		setFile(null);
		setImagePreviewUrl(defaultImage);

		if (picData != null) {
			setPicData(null);
		}
	};

	// Called when an image is selected for upload
	const uploadImage = (e) => {
		e.preventDefault();

		let reader = new FileReader();
		let upload = e.target.files[0];

		if (upload != null) {
			reader.readAsArrayBuffer(upload);
			reader.onloadend = (e) => fileLoaded(e, upload);
			reader.onerror = fileError;
		}
	};

	// Handler for when a file has successfully been loaded into the input element.
	// Throws error if file is not an accepted type.
	const fileLoaded = (e, upload) => {
		var arr = new Uint8Array(e.target.result).subarray(0, 4);
		var header = "";

		for (var i = 0; i < arr.length; i++) {
			header += arr[i].toString(16);
		}

		const acceptedFileType = actualFileType(header);

		if (acceptedFileType === upload.type) {
			let reader = new FileReader();

			reader.readAsDataURL(upload);
			reader.onload = (e) => fileAccepted(e, upload);
		} else {
			failAlert("Only JPEG/JPG and PNG files are allowed");
			setFile(null);
			setImagePreviewUrl(defaultImage);
		}
	};

	// Handler for processing an accepted image file. Throws error if image width
	// or height does not fall in the accepted range.
	const fileAccepted = (e, upload) => {
		const imageData = e.target.result;
		var image = new Image();

		image.src = imageData;

		image.onload = () => {
			var height = image.height;
			var width = image.width;

			if (
				width < minWidth ||
				height < minHeight ||
				width > maxWidth ||
				height > maxHeight
			) {
				setFile(null);
				fileInputRef.current.value = null;
				failAlert(
					`Image resolution must be between ${minWidth}x${minHeight} and ${maxWidth}x${maxHeight}`
				);
			} else {
				setFile(upload);
				setImagePreviewUrl(imageData);
				setPicData(imageData);
			}
		};
	};

	// Called when there is an error in the file upload process
	const fileError = (e) => {
		if (e.target.error.name === "NotReadableError") {
			failAlert("File could not be read, please try again");
		} else {
			failAlert(e?.target?.error?.name || "Unexpected error");
		}

		setFile(null);
	};

	useEffect(() => {
		if (picData != null) {
			setImagePreviewUrl(picData);
		}
	}, [picData]);

	return (
		<div className={`fileinput text-center ${containerWidth || "w-100"}`}>
			<input ref={fileInputRef} type="file" onChange={uploadImage} />
			<div className="thumbnail">
				<img src={imagePreviewUrl} alt="imageUpload" />
			</div>
			{disablePhotoChange === false && (
				<div>
					{file == null && picData == null && (
						<Button className="btn-round btn-primary" onClick={updateInput}>
							Select image
						</Button>
					)}
					{(file != null || picData != null) && (
						<span>
							<Button className="btn-round btn-primary" onClick={updateInput}>
								Change
							</Button>
							<Button
								color="danger"
								className="btn-round btn-primary"
								onClick={removeImage}
							>
								<i className="fa fa-times" />
								Remove
							</Button>
						</span>
					)}
				</div>
			)}
		</div>
	);
};

export default SingleImageUpload;
