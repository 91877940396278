import React, { useState, useContext } from "react";
import { Input, Button, Row, Col, FormGroup, Form } from "reactstrap";

import useCurrentDealStore from "store/DealStore";
import StyleSelector from "../../selector/StyleSelector";

import { decodeVIN } from "api/VINDecoderAPI";
import { retrieveFailAlert } from "../../modal/SweetAlerts";
import { showApiError } from "../../../../helperFunction/ApiErrorHandler";
import { TradeContext } from "./TradeContext";
import { DealStatus } from "Constants";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import shallow from "zustand/shallow";

/**
 * Component to take a user entered VIN and decode it
 */
const DealVINDecoderForm = () => {
	// Deal store
	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);

	const fieldSize = {
		modal: "ml-auto mr-auto col-xl-9",
		add: "ml-auto mr-auto col-xl-3",
		modalStyle: "ml-auto mr-auto col-xl-10",
	};

	const {
		editVin,
		editYear,
		editMake,
		editModel,
		editTrim,
		editTransmission,
		editDoors,
		editEngine,
		editType,
		editMpgHwy,
		editMpgCity,
		editStockNo,
		editColorList,
		editIntColorList,
		editSpecificationEquip,
		vin,
	} = useContext(TradeContext);

	// Local states
	const [enteredVin, setEnteredVin] = useState(vin || "");
	const [isVinEntered, setIsVinEntered] = useState(false);
	const [styleNames, setStyleNames] = useState([]);
	const [resultContents, setContents] = useState([]);
	//const [isVinLoading, setIsVinLoading] = useState(false);

	// API call
	const getData = () => {
		//Uncomment when adding a loading feature
		//setIsVinLoading(true);
		decodeVIN(enteredVin).then(
			(res) => {
				let contents = res.data.content;
				let contentStyles = [];
				console.log(contents);

				contents.forEach((content) => {
					contentStyles.push(content.styleName);
				});

				setStyleNames(contentStyles);
				setIsVinEntered(true);
				setContents(contents);
				setStore(contents[0], enteredVin);
				//setIsVinLoading(false);
				console.log(contents[0], enteredVin);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	// Sets the vehicle store
	const setStore = (style, vin) => {
		editStockNo(vin.substring(vin.length - 6, vin.length));

		//editSelectedEquip("");
		editVin(vin);
		editType(style.vehicleType);
		editYear(style.year);
		editMake(style.make);
		editModel(style.model);
		if (style.trim !== "Base") {
			editTrim(style.trim);
		}
		editDoors(style.doors);
		editEngine(style.engine);
		editSpecificationEquip(style.specifications);
		editTransmission(style.transmission);
		editMpgHwy(style.mpghwy);
		editMpgCity(style.mpgcity);
		editColorList(style.exteriorColors);
		editIntColorList(style.interiorColors);
	};

	// Changes the local entered VIN variable and removes error
	const handleInputChange = (e) => {
		setEnteredVin(e.target.value.toUpperCase());
		setIsVinEntered(false);
	};

	// Changes the selected style and updates the global state
	const handleSelectorChange = (e) => {
		let style = e.target.value;

		setStore(
			resultContents.filter((item) => item.styleName === style)[0],
			enteredVin
		);
	};

	const styleSelectorLength = fieldSize[1] + " ml-auto mr-auto";

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			length: "VIN must contain a combination of 17 letters and numbers",
			matches: "Only number and letters allowed",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({
		vin: yup
			.string()
			.required()
			.length(17)
			.matches(/^[a-zA-Z0-9]*$/),
	});

	// Define form validation parameters
	const { register, handleSubmit, errors } = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<Form onSubmit={(e) => e.preventDefault()}>
			<Col id="vinDecoder" style={{ marginBottom: "1em" }}>
				<Row className={fieldSize[1]}>
					<Col className="col-6 px-0">
						<FormGroup className="pt-2">
							<Input
								maxLength="17"
								innerRef={register}
								placeholder="Enter VIN"
								value={enteredVin}
								onChange={(e) => handleInputChange(e)}
								invalid={errors.vin ? true : false}
								name="vin"
							/>
							<ErrorMessage
								errors={errors}
								name="vin"
								render={({ message }) => (
									<p className="error_text">{message}</p>
								)}
							/>
						</FormGroup>
					</Col>
					<Col className="col-2 px-0">
						<FormGroup className="ml-2">
							<Button
								readOnly={type !== DealStatus.PENDING}
								type="submit"
								onClick={handleSubmit(getData)}
								className="btn btn-round btn-primary"
							>
								Decode
							</Button>
						</FormGroup>
					</Col>
				</Row>

				<br />
				<Col className={styleSelectorLength}>
					{isVinEntered && (
						<StyleSelector
							options={styleNames}
							label="Select a style"
							getInput={handleSelectorChange}
						/>
					)}
				</Col>
			</Col>
		</Form>
	);
};

export default DealVINDecoderForm;
