import shallow from "zustand/shallow";
import useCurrentDealStore from "../../store/DealStore";
import useCurrentRecapStore from "../../store/RecapStore";
import useCurrentSaleStore from "../../store/SaleStore";
import useCurrentVehicleStore from "../../store/InventoryStore";

const useSetDeal = () => {
	// Deal store
	const { setDealFields, editVehicle } = useCurrentDealStore(
		(state) => ({
			setDealFields: state.setDealFields,
			editVehicle: state.editVehicle,
		}),
		shallow
	);
	const { setRecapFields } = useCurrentRecapStore(
		(state) => ({
			setRecapFields: state.setRecapFields,
		}),
		shallow
	);
	const { setSaleFields } = useCurrentSaleStore(
		(state) => ({
			setSaleFields: state.setSaleFields,
		}),
		shallow
	);
	const { editInventoryID } = useCurrentVehicleStore(
		(state) => ({
			editInventoryID: state.editInventoryID,
		}),
		shallow
	);

	const setDeal = (response) => {
		console.log(response);
		let dealVehicle = response.data.content.vehicle;
		setDealFields(response.data.content);
		setRecapFields(response.data.content.deal.recap);
		setSaleFields(response.data.content.deal.sale);
		editInventoryID(response.data.content.deal.carRecNum);
		if (dealVehicle === null || dealVehicle.inventoryID === 0) {
			editVehicle(null);
		} else if (dealVehicle.inventoryID !== 0) {
			editVehicle(dealVehicle);
		} else {
			editVehicle(null);
		}
	};

	return setDeal;
};

export default useSetDeal;
