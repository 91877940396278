import React from "react";
import {
	Card,
	CardBody,
	CardText,
	Row,
	Col,
	CardHeader,
	Input,
} from "reactstrap";
import useCurrentSaleStore from "store/SaleStore";
import useCurrentRecapStore from "store/RecapStore";
import NumberFormat from "react-number-format";
import shallow from "zustand/shallow";
import useCurrentDealStore from "../../../../store/DealStore";
import { DealStatus } from "Constants";

const FinanceCurrency = ({ label, value, onChange, disabled }) => {
	return (
		<Row className="p-2">
			<Col md="6">
				<CardText>{label}</CardText>
			</Col>
			<Col md="6">
				<NumberFormat
					value={value}
					thousandSeparator={true}
					decimalScale={2}
					prefix={"$"}
					fixedDecimalScale={true}
					onFocus={(e) => e.target.select()}
					isNumericString={true}
					disabled={disabled}
					readOnly={disabled}
					customInput={Input}
					onValueChange={(values) => {
						if (!disabled) {
							if (values.value === "") {
								onChange(0);
							} else {
								onChange(values.value);
							}
						}
					}}
				/>
			</Col>
		</Row>
	);
};

const FinancePercent = ({ label, value, onChange, disabled }) => {
	return (
		<Row className="p-2">
			<Col md="6">
				<CardText>{label}</CardText>
			</Col>
			<Col md="6">
				<NumberFormat
					value={value * 100}
					decimalScale={2}
					fixedDecimalScale={true}
					onFocus={(e) => e.target.select()}
					isNumericString={true}
					disabled={disabled}
					readOnly={disabled}
					customInput={Input}
					onValueChange={(values) => {
						if (!disabled) {
							if (values.value === "") {
								onChange(0);
							} else {
								onChange(values.floatValue / 100);
							}
						}
					}}
				/>
			</Col>
		</Row>
	);
};

const RecapFinanceCard = () => {
	const { apr, financeCharge } = useCurrentSaleStore(
		(state) => ({
			apr: state.apr,
			financeCharge: state.financeCharge,
		}),
		shallow
	);
	const {
		interestCost,
		dlrParticipation,
		additionalProfit,
		interestProfit,
		interestNetProfit,
		editInterestCost,
		editDlrParticipation,
		editAdditionalProfit,
	} = useCurrentRecapStore(
		(state) => ({
			interestCost: state.interestCost,
			dlrParticipation: state.dlrParticipation,
			additionalProfit: state.additionalProfit,
			interestProfit: state.interestProfit,
			interestNetProfit: state.interestNetProfit,
			editInterestCost: state.editInterestCost,
			editDlrParticipation: state.editDlrParticipation,
			editAdditionalProfit: state.editAdditionalProfit,
		}),
		shallow
	);
	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);

	return (
		<Card>
			<div>
				<CardHeader>
					<h3>Finance (Interest)</h3>
					<hr />
				</CardHeader>
				<CardBody>
					<Row>
						<Col md="6">
							<FinancePercent disabled label="Sale Rate (%)" value={apr} />
							<FinancePercent
								disabled={type !== DealStatus.PENDING}
								label="Buy Rate (%)"
								value={interestCost}
								onChange={editInterestCost}
							/>
						</Col>
						<Col md="6">
							<FinanceCurrency
								disabled={true}
								label="Finance Charge"
								value={financeCharge}
							/>
							{/** lots of conditionals for this field... */}
							<FinanceCurrency
								disabled={true}
								label="Gross Financing Profit"
								value={interestProfit}
							/>
							<FinancePercent
								disabled={type !== DealStatus.PENDING}
								label="Participation (%)"
								value={dlrParticipation}
								onChange={editDlrParticipation}
							/>
							<FinanceCurrency
								disabled={type !== DealStatus.PENDING}
								label="Additional Financing Profit"
								value={additionalProfit}
								onChange={editAdditionalProfit}
							/>
							<FinanceCurrency
								disabled={true}
								label="Net Financing Profit"
								value={interestNetProfit}
							/>
						</Col>
					</Row>
				</CardBody>
			</div>
		</Card>
	);
};

export default RecapFinanceCard;
