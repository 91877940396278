import React, { useState, useCallback, useEffect, useContext } from "react";
import { Button, Card, CardBody, Col, Row, Label } from "reactstrap";
import shallow from "zustand/shallow";
import dcLogo from "../../../assets/img/lenders-logo.jpg";
import { getPackList } from "../../../api/FormsAPI";
import useCurrentDealStore from "../../../store/DealStore";
import ChildrenModal from "../modal/ChildrenModal";
import { getDealAttachmentsList } from "../../../api/AttachmentsAPI";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";
import { retrieveFailAlert } from "../modal/SweetAlerts";
import { useFromUser } from "../../../store/LocalStorageHelper";
import Select from "react-select";
import swal from "sweetalert2";
import {
	EmailPackRequestButton,
	PackRequestButton,
} from "../formsReports/FormsReportsButtons";
import { PackRequestContext } from "../formsReports/PackContexts";
import { ATTACHMENT_CATEGORY } from "../../../Constants";
import FormsEmailProvider from "../formsReports/FormsEmailContext";

const LenderPacket = () => {
	const { dealID } = useCurrentDealStore(
		(state) => ({
			dealID: state.deal.ID,
		}),
		shallow
	);

	const [modalActive, setModalActive] = useState(false);
	const [attachments, setAttachments] = useState([]);
	const [packs, setPacks] = useState([]);

	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const params = { dealerID, locationID };

	const toggle = () => {
		setModalActive(!modalActive);
	};

	const createAndSetDealTableData = (obj) => {
		let tableData = [];

		Object.keys(obj).forEach((key) =>
			Object.keys(obj[key]).forEach((innerKey) => {
				let selected = false;
				if (obj[key][innerKey].category === ATTACHMENT_CATEGORY[1].value) {
					selected = true;
				}
				const fileDetails = {
					...obj[key][innerKey],
					selected,
				};
				tableData.push({
					...fileDetails,
				});
			})
		);

		setAttachments(tableData);
	};

	const setPacksDropdown = (arr) => {
		const options = arr.map((obj) => {
			return { value: obj.pack.id, label: obj.pack.packName, pack: obj };
		});
		setPacks(options);
	};

	// Gets all the attachment files information for a deal
	const getLists = useCallback(() => {
		dealID != null &&
			Promise.all([getPackList(params), getDealAttachmentsList(dealID)]).then(
				(res) => {
					setPacksDropdown(res[0].data.content.packs);
					createAndSetDealTableData(res[1].data.content);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		getLists();
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<Card>
				<CardBody>
					<img alt="Lender Packet" className="lenderImg" src={dcLogo} />
					<Col>
						<Button
							size="md"
							className="w-100 btn"
							onClick={() => {
								if (packs.length > 0) {
									toggle();
								} else {
									swal.fire(
										"Error!",
										"Create a pack in the forms tab to use this feature",
										"error"
									);
								}
							}}
						>
							Lender Package
						</Button>
					</Col>
					<ChildrenModal
						modal={modalActive}
						toggle={toggle}
						modalTitle={"Select Pack and Attachments"}
						size="lg"
					>
						<AttachmentsCheckBoxTable
							data={attachments}
							packOptions={packs}
							toggle={toggle}
						/>
					</ChildrenModal>
				</CardBody>
			</Card>
		</>
	);
};

export default LenderPacket;

const AttachmentsCheckBoxTable = ({ data, packOptions, toggle }) => {
	const { dealID, inventoryID } = useCurrentDealStore(
		(state) => ({
			dealID: state.deal.ID,
			inventoryID: state.deal.carRecNum,
		}),
		shallow
	);

	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const createParamsList = (packObj) => {
		let paramsList = {};
		packObj.forms.forEach((form) => {
			paramsList[form.id] = { dealerID, locationID };
		});
		return paramsList;
	};

	const [list, setList] = useState(data);
	const [checkAll, setCheckAll] = useState(false);
	const [packSelect, setPackSelect] = useState(packOptions[0]);

	const { lenderPackRequest, setLenderPackRequest } = useContext(
		PackRequestContext
	);

	useEffect(() => {
		setLenderPackRequest(() => {
			const paramsList = createParamsList(packOptions[0].pack);
			const ids = getSelectedIDs();
			return {
				dealID: dealID,
				packID: packOptions[0].value,
				attachmentIDs: ids,
				inventoryID,
				paramsList,
			};
		});
		// eslint-disable-next-line
	}, []);

	console.log(lenderPackRequest);

	// Select/ UnSelect Table rows
	const onMasterCheck = (e) => {
		let tempList = list;
		// Check/ UnCheck All Items
		// this might not apply to attachemnts though i might be able to append this data to each obj
		tempList.map((user) => (user.selected = e.target.checked));

		//Update State
		setList(tempList);
		setCheckAll(e.target.checked);
	};

	const getSelectedIDs = () => {
		const selected = list.filter((e) => e.selected);
		const ids = selected.map((e) => {
			return e.ID;
		});
		setLenderPackRequest((prevState) => {
			return { ...prevState, attachmentIDs: ids };
		});
		return ids;
	};
	console.log(packSelect);

	return (
		<div className="row">
			<div className="col-md-12">
				<Row>
					<Col lg="2">
						<Label className="h5">Packs</Label>
					</Col>
					<Col>
						<Select
							onChange={(option) => {
								const paramsList = createParamsList(option.pack);
								setLenderPackRequest((prevState) => {
									return {
										...prevState,
										packID: option.value,
										paramsList,
									};
								});
								setPackSelect(option);
							}}
							value={packSelect}
							options={packOptions}
						/>
					</Col>
				</Row>

				<table className="table">
					<thead>
						<tr>
							<th scope="col">#</th>
							<th scope="col">Form</th>
						</tr>
					</thead>
					<tbody>
						{packSelect.pack.forms.map((obj, index) => (
							<tr key={obj.id}>
								<th scope="row">{index + 1}</th>
								<td>{obj.title}</td>
							</tr>
						))}
					</tbody>
				</table>

				<table className="table">
					<thead>
						<tr>
							<th scope="col">
								<input
									type="checkbox"
									checked={checkAll}
									id="mastercheck"
									onChange={(e) => onMasterCheck(e)}
								/>
							</th>
							<th scope="col">File</th>
							<th scope="col">Category</th>
							<th scope="col">Description</th>
						</tr>
					</thead>
					<tbody>
						{list.map((obj, index) => (
							<tr
								key={obj.ID}
								onClick={() => {
									let tempVal = !obj.selected ? true : false;
									setList(() => {
										const newState = [...list];
										newState[index].selected = tempVal;
										return newState;
									});
								}}
							>
								<th scope="row">
									<input
										type="checkbox"
										checked={obj.selected}
										id="rowcheck{obj.ID}"
										onChange={(e) => {
											let tempVal = e.target.checked ? true : false;
											setList(() => {
												const newState = [...list];
												newState[index].selected = tempVal;
												return newState;
											});
										}}
									/>
								</th>
								<td>{obj.fileName}</td>
								<td>{obj.category}</td>
								<td>{obj.description}</td>
							</tr>
						))}
					</tbody>
				</table>
				<span onClick={getSelectedIDs}>
					<FormsEmailProvider>
						<EmailPackRequestButton packObj={packSelect.pack} dealID={dealID} />
					</FormsEmailProvider>
				</span>
				<span onClick={getSelectedIDs}>
					<PackRequestButton
						packObj={packSelect.pack}
						dealID={dealID}
						attach={true}
					/>
				</span>
			</div>
		</div>
	);
};
