import React, { useState } from "react";

import ChildrenModal from "../../modal/ChildrenModal";
import BlastMessageReceivers from "../BlastMessageReceivers";
import TwilioSendBlastMessage from "./TwilioSendBlastMessage";
import TwilioSendMessage from "./TwilioSendMessage";

/**
 * Used to send single text messages or blast messages to multiple people.
 * Can you template variables for blast message because each message is
 * mapped and texted separately to each customer. Not all fields are required.
 * Below is listed what fields are required for single texts vs blast
 *
 * Single Text: {@param sendToNumber} and {@param templateVariableData} required
 * Blast Text: {@param blastMessage}, {@param customers}, and {@param setCustomers} required
 *
 * @param {String} sendToNumber 10 digit phone number
 * @param {Object} templateVariableData Object containing the values mapping to the template
 * 				  variables. Check MESSAGE_TEMPLATE_VARIABLE_DATA in Constants.js
 * @param {boolean} blastMessage True if you want to enable blast text
 * @param {Array} customers React state array of customer objects. Should at least contain
 * 				  the fields ID, cellPhone, firstName, lastName, email and name
 * @param {React.SetStateAction} setCustomers React setState for the customers object.
 * 				  Used to remove objects from customers array that the send message request
 * 				  was successful for.
 */
const Twilio = ({
	sendToNumber,
	blastMessage,
	customers,
	setCustomers,
	dealID,
	customerID,
}) => {
	const [twilioModal, setTwilioModal] = useState(false);

	// Toggles the texting modal
	const toggleTwilio = () => setTwilioModal(!twilioModal);

	return (
		<>
			{blastMessage === true && (
				<div
					className="px-1 btn-round btn-sm btn btn-outline-secondary w-25 my-0"
					onClick={toggleTwilio}
				>
					<i className="nc-icon nc-chat-33"></i> Blast Text
					<ChildrenModal
						size="lg"
						modal={twilioModal}
						toggle={toggleTwilio}
						modalTitle="Send Message"
					>
						<TwilioSendBlastMessage
							toggle={toggleTwilio}
							customers={customers}
							setCustomers={setCustomers}
						/>
						<BlastMessageReceivers customers={customers} />
					</ChildrenModal>
				</div>
			)}
			{blastMessage !== true && (
				<div
					className="btn-round btn btn-md btn-info onMobileScreen"
					onClick={toggleTwilio}
				>
					<i className="nc-icon nc-chat-33"></i> Text
					<ChildrenModal
						size="lg"
						modal={twilioModal}
						toggle={toggleTwilio}
						modalTitle="Send Message"
					>
						<TwilioSendMessage
							sendToNumber={sendToNumber}
							dealID={dealID}
							customerID={customerID}
						/>
					</ChildrenModal>
				</div>
			)}
		</>
	);
};

export default Twilio;
