/*!
 
=========================================================
* DealerClick 2.0 - v1.0
=========================================================
 
* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)
 
* Coded by DealerClick Software **RoarinLion))
 
=========================================================
 
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 
*/
import React from "react";
import {
	Col,
	Table,
	Button,
	Card,
	CardHeader,
	CardTitle,
	CardBody,
} from "reactstrap";

import useCurrentCustomerStore from "store/CustomerStore";

import { deleteReference } from "api/CustomerAPI";
import { showApiError } from "../../../../helperFunction/ApiErrorHandler";
import {
	deleteSuccessAlert,
	deleteFailAlert,
	confirmDeleteAlert,
} from "component/dc/modal/SweetAlerts";

import shallow from "zustand/shallow";
import NumberFormat from "react-number-format";

/**
 * @description This table generates a list of addresses
 * @param setCurrentIndex Used to index the store address history array
 */
const CustomerReferenceTable = ({ disabled, setCurrentIndex, toggle }) => {
	// Headers
	const headers = [
		"#",
		"First Name",
		"Last Name",
		"Relationship",
		"Time Known in year",
		"Phone Number",
		"Zip",
		"Actions",
	];

	// Customer store
	const { references, editReferences } = useCurrentCustomerStore(
		(state) => ({
			references: state.references,
			editReferences: state.editReferences,
		}),
		shallow
	);

	// Modal to ask confirmation to delete an address
	const toggleDelete = (currentID) => {
		confirmDeleteAlert(removeReference, currentID);
	};

	// Calls the delete address API
	const removeReference = (ID) => {
		if (ID != null) {
			deleteReference(ID).then(
				() => {
					let obj = references.filter((item) => item.ID !== ID);
					editReferences(obj);
					setCurrentIndex(obj.length);
					deleteSuccessAlert();
				},
				(err) => {
					console.log(err);

					if (!err.isGeneralError) {
						showApiError(err, deleteFailAlert);
					}
				}
			);
		}
	};

	// Address table headers
	const head = headers.map((key) => <th key={key}>{key}</th>);

	// Address row items
	const rows = references.map((key, index) => (
		<tr key={index}>
			<th scope="row">{index + 1}</th>
			<td>{key.firstName}</td>
			<td>{key.lastName}</td>
			<td>{key.relationship}</td>
			<td>{key.known}</td>
			<td>
				<a href={"tel:" + key.homePhone}>
					{" "}
					<NumberFormat
						value={key.homePhone}
						format="+1 (###) ###-####"
						isNumericString={true}
						displayType="text"
					/>
				</a>
			</td>
			<td>{key.zip}</td>
			<td className="text-right">
				<Button
					disabled={disabled}
					className="btn-sm"
					color="success"
					onClick={() => {
						setCurrentIndex(index);
						toggle();
					}}
				>
					Edit
				</Button>
				<Button
					disabled={disabled}
					className="btn-sm"
					color="danger"
					onClick={() => {
						toggleDelete(key.ID);
					}}
				>
					X
				</Button>
			</td>
		</tr>
	));

	return (
		<Card>
			<CardHeader>
				<CardTitle>
					<h3>References</h3>
				</CardTitle>
			</CardHeader>
			<CardBody>
				<Col>
					<Table striped hover>
						<thead>
							<tr>{head}</tr>
						</thead>
						<tbody>{rows}</tbody>
					</Table>
				</Col>{" "}
			</CardBody>
		</Card>
	);
};
export default CustomerReferenceTable;
