/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)

* Coded by DealerClick Software **RoarinLion))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { TemplateVariableProvider } from "helperFunction/TemplateVariableContext.js";
import TemplateVariableInit from "helperFunction/TemplateVariableInit.js";
import React from "react";
import { useHistory } from "react-router-dom";
import CustomerListData from "./CustomerListData.js";

const CustomerListPage = ({
	isDeal = false,
	toggle,
	editCustomer,
	editAddress,
	editMailAddress,
	editRecNum,
}) => {
	const history = useHistory();
	return (
		<div>
			<TemplateVariableProvider>
				<TemplateVariableInit>
					<CustomerListData
						isDeal={isDeal}
						toggle={toggle}
						editCustomer={editCustomer}
						editAddress={editAddress}
						editMailAddress={editMailAddress}
						editRecNum={editRecNum}
						initStatus={history.location.state?.status}
					/>
				</TemplateVariableInit>
			</TemplateVariableProvider>
		</div>
	);
};

export default CustomerListPage;
