import React from "react";
import { Card, CardBody, Input } from "reactstrap";
import { useFromUser } from "../../../store/LocalStorageHelper";

/**
 * Renders the "name" and "description" input fields in the modal body.
 *
 * @param {String} lNote - Local state for description
 * @param {String} name - Local state for name
 * @param {React.SetStateAction} setLNote - Setter function note state
 * @param {React.SetStateAction} setName - Setter function name state
 */
const NoteModalBody = ({ lNote, setLNote }) => {
	const ADD_NOTE = "addNoteTextArea";
	const firstName = useFromUser("firstName");
	const lastName = useFromUser("lastName");
	return (
		<Card className="py-2">
			<CardBody>
				<div className="col">
					<Input
						className="pl-2 mb-3 col-3"
						placeholder="Name"
						type="text"
						value={firstName + " " + lastName}
						disabled
						onKeyDown={(e) => {
							if (e.keyCode === 13) {
								const element = document.getElementById(ADD_NOTE);
								e.preventDefault();
								element.focus();
							}
						}}
					/>
					<Input
						id={ADD_NOTE}
						className="pl-2"
						placeholder="Add new note"
						type="textarea"
						value={lNote}
						onChange={(e) => setLNote(e.target.value)}
					/>
				</div>
			</CardBody>
		</Card>
	);
};

export default NoteModalBody;
