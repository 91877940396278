import React, { useState, useEffect } from "react";
import {
	Button,
	FormGroup,
	Label,
	Input,
	Card,
	Col,
	CardBody,
	CardHeader,
	Row,
	CardTitle,
	Form,
} from "reactstrap";
import { profitSettingsEnum } from "Constants.js";
import useCurrentSettingStore from "store/SettingStore";
import { saveSettings } from "api/SettingsAPI";
import { saveDeal } from "api/DealAPI";
//import { saveSuccessAlert } from "component/dc/modal/SweetAlerts";
import { showApiError } from "../../../../helperFunction/ApiErrorHandler";
import useCurrentDealStore from "store/DealStore";
import useBuildDeal from "helperFunction/deal/useBuildDeal";
//import { retrieveFailAlert } from "component/dc/modal/SweetAlerts";
import { useFromUser } from "store/LocalStorageHelper";
import { getSettings } from "api/SettingsAPI";
import { DealStatus } from "Constants";
import tabOnEnter from "helperFunction/tabOnEnter";
import {
	saveFailAlert,
	saveSuccessAlert,
	retrieveFailAlert,
} from "../../../../component/dc/modal/SweetAlerts";
import shallow from "zustand/shallow";
import useSetDeal from "../../../../helperFunction/deal/useSetDeal";

const ProfitSettings = ({ dealSettings = false }) => {
	const {
		profitSettings: companyProfitSettings,
		taxDefaults: companyTaxDefaults,
		commissionSettings: companyCommissionSettings,
	} = useCurrentSettingStore();

	const { currentDealSettings, editDealSettings, type } = useCurrentDealStore(
		(state) => ({
			currentDealSettings: state.deal.dealSettings,
			editDealSettings: state.editDealSettings,
			type: state.deal.type,
		}),
		shallow
	);

	const getDeal = useBuildDeal();
	const setDeal = useSetDeal();

	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	let profitSettings = {};
	let taxDefaults = {};
	let commissionSettings = {};

	if (dealSettings) {
		profitSettings = currentDealSettings.profitSettings;
		taxDefaults = currentDealSettings.taxDefaults;
		commissionSettings = currentDealSettings.commissionSettings;
	} else {
		profitSettings = companyProfitSettings;
		taxDefaults = companyTaxDefaults;
		commissionSettings = companyCommissionSettings;
	}

	const [isDocFeeProfit, setIsDocFeeProfit] = useState(
		profitSettings.isDocFeeProfit
	);
	const [isAftMktProfit, setIsAftMktProfit] = useState(
		profitSettings.isAftMktProfit
	);
	const [isServConProfit, setIsServConProfit] = useState(
		profitSettings.isServConProfit
	);
	const [isGapProfit, setIsGapProfit] = useState(profitSettings.isGapProfit);
	const [isMiscProfit, setIsMiscProfit] = useState(profitSettings.isMiscProfit);
	const [isBankFeeProfit, setIsBankFeeProfit] = useState(
		profitSettings.isBankFeeProfit
	);
	const [isReserveProfit, setIsReserveProfit] = useState(
		profitSettings.isReserveProfit
	);
	const [isDealerSmogProfit, setIsDealerSmogProfit] = useState(
		profitSettings.isDealerSmogProfit
	);

	useEffect(() => {
		setIsDocFeeProfit(profitSettings.isDocFeeProfit);
		setIsAftMktProfit(profitSettings.isAftMktProfit);
		setIsServConProfit(profitSettings.isServConProfit);
		setIsAftMktProfit(profitSettings.isAftMktProfit);
		setIsServConProfit(profitSettings.isServConProfit);
		setIsGapProfit(profitSettings.isGapProfit);
		setIsMiscProfit(profitSettings.isMiscProfit);
		setIsBankFeeProfit(profitSettings.isBankFeeProfit);
		setIsReserveProfit(profitSettings.isReserveProfit);
		setIsDealerSmogProfit(profitSettings.isDealerSmogProfit);
	}, [profitSettings]);
	const profitsObj = [
		{
			name: "isDocFeeProfit",
			dropDownValue: isDocFeeProfit,
			dropdownLabel: "Doc Fee",
			onChange: setIsDocFeeProfit,
		},
		{
			name: "isAftMktProfit",
			dropDownValue: isAftMktProfit,
			dropdownLabel: "Aftermarket",
			onChange: setIsAftMktProfit,
		},
		{
			name: "isServConProfit",
			dropDownValue: isServConProfit,
			dropdownLabel: "Service Contract",
			onChange: setIsServConProfit,
		},
		{
			name: "isGapProfit",
			dropDownValue: isGapProfit,
			dropdownLabel: "Gap",
			onChange: setIsGapProfit,
		},
		{
			name: "isMiscProfit",
			dropDownValue: isMiscProfit,
			dropdownLabel: "Misc",
			onChange: setIsMiscProfit,
		},
		{
			name: "isBankFeeProfit",
			dropDownValue: isBankFeeProfit,
			dropdownLabel: "Bank Fee",
			onChange: setIsBankFeeProfit,
		},
		{
			name: "isReserveProfit",
			dropDownValue: isReserveProfit,
			dropdownLabel: "Reserve",
			onChange: setIsReserveProfit,
		},
		{
			name: "isDealerSmogProfit",
			dropDownValue: isDealerSmogProfit,
			dropdownLabel: "Dealer Smog",
			onChange: setIsDealerSmogProfit,
		},
	];

	const save = () => {
		let oldSettings = profitSettings;

		oldSettings.isDocFeeProfit = profitsObj[0].dropDownValue;
		oldSettings.isAftMktProfit = profitsObj[1].dropDownValue;
		oldSettings.isServConProfit = profitsObj[2].dropDownValue;
		oldSettings.isGapProfit = profitsObj[3].dropDownValue;
		oldSettings.isMiscProfit = profitsObj[4].dropDownValue;
		oldSettings.isBankFeeProfit = profitsObj[5].dropDownValue;
		oldSettings.isReserveProfit = profitsObj[6].dropDownValue;
		oldSettings.isDealerSmogProfit = profitsObj[7].dropDownValue;

		const settings = {
			taxDefaults,
			profitSettings: oldSettings,
			commissionSettings,
		};

		if (dealSettings) {
			//update the deal settings obj and save the deal
			editDealSettings(settings);
			// zustand doesnt update state quick enough so manually add it
			const deal = getDeal();
			deal.deal.dealSettings = JSON.stringify(settings);
			saveDeal(deal).then(
				(response) => {
					setDeal(response);
					saveSuccessAlert();
					console.log("Deal saved with ID: " + response.data.content.deal.ID);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		} else {
			saveSettings(settings).then(
				(res) => {
					console.log(res);
					saveSuccessAlert();
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, saveFailAlert);
					}
				}
			);
		}
	};

	const SaveButton = () => {
		return (
			<Button
				readOnly={type !== DealStatus.PENDING}
				color="success"
				onClick={() => {
					save();
				}}
				size="md"
			>
				<i className="nc-icon nc-cloud-download-93"></i> Save
			</Button>
		);
	};

	const getData = () => {
		getSettings(dealerID, locationID).then(
			(res) => {
				console.log(res);
				profitsObj[0].onChange(res.data.content.profitSettings.isDocFeeProfit);
				profitsObj[1].onChange(res.data.content.profitSettings.isAftMktProfit);
				profitsObj[2].onChange(res.data.content.profitSettings.isServConProfit);
				profitsObj[3].onChange(res.data.content.profitSettings.isGapProfit);
				profitsObj[4].onChange(res.data.content.profitSettings.isMiscProfit);
				profitsObj[5].onChange(res.data.content.profitSettings.isBankFeeProfit);
				profitsObj[6].onChange(res.data.content.profitSettings.isReserveProfit);
				profitsObj[7].onChange(
					res.data.content.profitSettings.isDealerSmogProfit
				);
				//save();
			},
			(err) => {
				console.log(err);

				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	const UseDefaults = () => {
		return (
			<Button
				readOnly={type !== DealStatus.PENDING}
				color="primary"
				onClick={() => {
					getData();
				}}
				size="md"
				className="pull-right"
			>
				<i className="nc-icon nc-check-2"></i> Use Defaults
			</Button>
		);
	};

	const profitComponents = profitsObj.map((obj) => {
		return (
			<Row className="mx-0">
				<FormGroup>
					<Label key={obj.name}>
						{obj.dropdownLabel}
						<Col>
							<Col className="radioFont">
								<Input
									type="radio"
									name={obj.name}
									checked={obj.dropDownValue === 0 || obj.dropDownValue > 2}
									onChange={() => {
										obj.onChange(0);
									}}
								/>

								{profitSettingsEnum.NOT_PROFIT.label}
							</Col>
							<Col className="radioFont">
								<Input
									type="radio"
									name={obj.name}
									checked={obj.dropDownValue === 1}
									onChange={() => {
										obj.onChange(1);
									}}
								/>

								{profitSettingsEnum.FRONT.label}
							</Col>
							<Col className="radioFont">
								<Input
									type="radio"
									name={obj.name}
									checked={obj.dropDownValue === 2}
									onChange={() => {
										obj.onChange(2);
									}}
								/>
								{profitSettingsEnum.BACK.label}
							</Col>
						</Col>
					</Label>
				</FormGroup>
			</Row>
		);
	});
	return (
		<div onKeyDown={tabOnEnter}>
			<Form>
				<Row className="mx-0 pb-2">
					<Col>
						<SaveButton />
					</Col>
					{dealSettings && (
						<Col>
							<UseDefaults />
						</Col>
					)}
				</Row>
				<Card>
					<CardHeader>
						<CardTitle tag="h3">Profit Settings</CardTitle>
					</CardHeader>
					<CardBody>
						<Col>
							<Row className="mx-0">{profitComponents}</Row>
						</Col>
					</CardBody>
				</Card>
			</Form>
		</div>
	);
};

export default ProfitSettings;
