import React, { useState } from "react";
import NumberFormat from "react-number-format";
import ChildrenModal from "../modal/ChildrenModal";
import { Table } from "reactstrap";
import useCurrentCustomerStore from "store/CustomerStore";
import { showSwalMessage } from "../modal/SweetAlerts";
export const CallButton = () => {
	const [phoneList, setPhoneList] = useState(false);
	const togglePhoneList = () => setPhoneList(!phoneList);
	const {
		cellPhone,
		extraPhone,
		homePhone,
		workPhone,
	} = useCurrentCustomerStore();

	if (!cellPhone && !extraPhone && !homePhone && !workPhone) {
		return (
			<div
				className="btn btn-md btn-info onMobileScreen"
				onClick={() => {
					showSwalMessage("", "Please enter the phone number", "");
				}}
			>
				<i className="nc-icon nc-mobile"></i> Call
			</div>
		);
	} else {
		return (
			<>
				<div
					className="btn btn-md btn-info onMobileScreen"
					onClick={togglePhoneList}
				>
					<i className="nc-icon nc-mobile"></i>
					Call
				</div>
				<>
					<ChildrenModal
						modalTitle="Contact List"
						modal={phoneList}
						toggle={togglePhoneList}
					>
						<PhoneList />
					</ChildrenModal>
				</>
			</>
		);
	}
};
const PhoneList = () => {
	const {
		cellPhone,
		extraPhone,
		homePhone,
		workPhone,
	} = useCurrentCustomerStore();
	const headers = ["Contact Type", "Contact Number"];
	let contactLists = [];
	if (cellPhone) {
		contactLists.push({
			type: "Cell Phone",
			value: cellPhone,
			formattedValue: (
				<NumberFormat
					value={cellPhone}
					format="+1 (###) ###-####"
					allowEmptyFormatting
					mask="_"
					isNumericString={true}
					displayType="text"
				/>
			),
		});
	}
	if (extraPhone) {
		contactLists.push({
			type: "Extra Phone",
			value: extraPhone,
			formattedValue: (
				<NumberFormat
					value={extraPhone}
					format="+1 (###) ###-####"
					allowEmptyFormatting
					mask="_"
					isNumericString={true}
					displayType="text"
				/>
			),
		});
	}
	if (homePhone) {
		contactLists.push({
			type: "Home Phone",
			value: homePhone,
			formattedValue: (
				<NumberFormat
					value={homePhone}
					format="+1 (###) ###-####"
					allowEmptyFormatting
					mask="_"
					isNumericString={true}
					displayType="text"
				/>
			),
		});
	}
	if (workPhone) {
		contactLists.push({
			type: "Work Phone",
			value: workPhone,
			formattedValue: (
				<NumberFormat
					value={workPhone}
					format="+1 (###) ###-####"
					allowEmptyFormatting
					mask="_"
					isNumericString={true}
					displayType="text"
				/>
			),
		});
	}
	const content = contactLists.map((contact) => (
		<tr key={contact.ID}>
			<td>{contact.type}</td>
			<td>{contact.formattedValue}</td>
			<td className="d-flex justify-content-center">
				<a
					href={"tel:" + contact.value}
					className="btn btn-md btn-info onMobileScreen"
				>
					Call
				</a>
			</td>
		</tr>
	));
	return (
		<Table striped hover>
			<thead>
				<tr>
					{headers.map((key, i) => (
						<th key={i}>{key}</th>
					))}
				</tr>
			</thead>
			<tbody>{content}</tbody>
		</Table>
	);
};
export const TextButton = () => {
	const { cellPhone } = useCurrentCustomerStore();
	if (!cellPhone) {
		return (
			<div
				className="btn-round btn btn-md btn-info onMobileScreen"
				onClick={() => {
					showSwalMessage("", "Please enter the cellphone number", "");
				}}
			>
				<i className="nc-icon nc-chat-33"></i> Text
			</div>
		);
	} else {
		return (
			<a
				href={"sms:" + cellPhone}
				className="btn-round btn btn-md btn-info onMobileScreen"
			>
				<i className="nc-icon nc-chat-33"></i> Text
			</a>
		);
	}
};
