import React from "react";
import {
	Card,
	CardBody,
	CardText,
	CardTitle,
	Col,
	Label,
	Row,
} from "reactstrap";
import useCurrentSaleStore from "store/SaleStore";
import useCurrentDealStore from "store/DealStore";
import NumberFormat from "react-number-format";
import ChildrenModal from "component/dc/modal/ChildrenModal";
import shallow from "zustand/shallow";

// Net trade calculations are done here
const NetTradeModal = ({ modal, toggle }) => {
	// Store values
	const { totalNetTrade } = useCurrentSaleStore(
		(state) => ({
			totalNetTrade: state.totalNetTrade,
		}),
		shallow
	);
	const { trades } = useCurrentDealStore(
		(state) => ({
			trades: state.trades,
		}),
		shallow
	);

	const columns = [
		{
			value: "acv",
			label: "ACV",
		},
		{
			value: "allowance",
			label: "Allowance",
		},
		{
			value: "payoff",
			label: "Payoff",
		},
	];

	// Returns input fields and a text field that shows the total of the inputs
	return (
		<ChildrenModal
			modal={modal}
			toggle={toggle}
			modalTitle="Net Trade"
			size="lg"
			children={
				<div>
					{trades.map((obj) => (
						<Card>
							<CardBody>
								<CardTitle tag="h5">
									{obj?.car?.year +
										" " +
										obj?.car?.make +
										" " +
										obj?.car?.model}
								</CardTitle>
								<Row>
									{columns.map((element) => (
										<Col>
											<CardText>
												<Col>
													<Label>{element.label}</Label>
												</Col>
												<Col>
													<NumberFormat
														value={obj[element.value]}
														thousandSeparator={true}
														decimalScale={2}
														fixedDecimalScale={true}
														prefix={"$"}
														isNumericString={true}
														displayType="text"
													/>
												</Col>
											</CardText>
										</Col>
									))}
									<Col>
										<CardText>
											<Col>
												<Label>Net Trade</Label>
											</Col>
											<Col>
												<NumberFormat
													value={obj.allowance - obj.payoff}
													thousandSeparator={true}
													decimalScale={2}
													fixedDecimalScale={true}
													prefix={"$"}
													isNumericString={true}
													displayType="text"
												/>
											</Col>
										</CardText>
									</Col>
								</Row>
							</CardBody>
						</Card>
					))}
					<br />
					<Row>
						<Card className="w-100">
							<CardBody>
								<Col>
									<h5>Total Net Trade</h5>
								</Col>
								<Col sm="5">
									<CardText tag="h5">
										<NumberFormat
											value={totalNetTrade}
											thousandSeparator={true}
											decimalScale={2}
											fixedDecimalScale={true}
											prefix={"$"}
											isNumericString={true}
											displayType="text"
										/>
									</CardText>
								</Col>
							</CardBody>
						</Card>
					</Row>
				</div>
			}
		/>
	);
};

export default NetTradeModal;
