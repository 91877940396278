import React, { useState, createContext } from "react";
import shallow from "zustand/shallow";
import useCurrentDealStore from "../../../store/DealStore";

export const FormsEmailContext = createContext();

const FormsEmailProvider = ({ children }) => {
	const { buyerEmail } = useCurrentDealStore(
		(state) => ({
			buyerEmail: state.buyer.email,
		}),
		shallow
	);

	const dealerEmail = JSON.parse(sessionStorage.getItem("user"))?.locations
		?.email;
	//	const dealerEmail = JSON.parse(sessionStorage.getItem("user"))?.email;
	// this can then contain a pdfRequest or a lenderPackRequest, add it at the end before making the final email request
	const [emailRequest, setEmailRequest] = useState({
		from: dealerEmail,
		to: buyerEmail || "",
		subject: "",
		body: "",
		fileName: null,
		cc: null,
		bcc: null,
	});

	const context = {
		emailRequest,
		setEmailRequest,
	};
	return (
		<FormsEmailContext.Provider value={context}>
			{children}
		</FormsEmailContext.Provider>
	);
};

export default FormsEmailProvider;
