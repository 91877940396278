import React, { useState } from "react";

import OwnersInformation from "./OwnersInformation";
import MainInformation from "./MainInformation";
import BusinessInformation from "./BusinessInformation";
import ProfileInformation from "./ProfileInformation";
import Questionnaire from "./Questionnaire";

import { failAlert } from "../../../../../component/dc/modal/SweetAlerts";

import { successAlertCustom } from "../../../../../component/dc/modal/SweetAlerts";
import { createPepperPayMerchant } from "../../../../../api/dealerIntegrationsApi";
import { useFromUser } from "../../../../../store/LocalStorageHelper";
import dayjs from "dayjs";
import { useFormContext } from "react-hook-form";
import Swal from "sweetalert2";

const Summary = ({
	setCurrentForm,
	previousForm,
	mainInfo,
	business,
	legal,
	ownersInfo,
	driverLicenseInfo,
	addressInfo,
	profileInfo,
	creditAccountInfo,
	debitAccountInfo,
	backgroundInfo,
	questionnaire,
	addressProductHeld,
	fulfillmentHouseProvide,
	percentageSalesFrom,
	termsAndConditions,
	setMainInfo,
	setBusiness,
	setLegal,
	setOwnersInfo,
	setDriverLicenseInfo,
	setAddressInfo,
	setProfileInfo,
	setCreditAccountInfo,
	setDebitAccountInfo,
	setBackgroundInfo,
	setQuestionnaire,
	setAddressProductHeld,
	setFulfillmentHouseProvide,
	setPercentageSalesFrom,
	setTermsAndConditions,
	owner2,
	toggle,
}) => {
	const locationID = useFromUser("locationID");
	// Local state
	const [showSubmitProgress, setShowSubmitProgress] = useState(false);
	console.log(showSubmitProgress);

	const methods = useFormContext();

	const loading = () => {
		Swal.fire({
			title: "Sending application...",
			showConfirmButton: false,
			willOpen() {
				Swal.showLoading();
			},
			didClose() {
				Swal.hideLoading();
			},
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
		});
	};

	// Create a Digitzs account with the information provided in the sign up form
	const handleSubmit = () => {
		setShowSubmitProgress(true);
		loading();
		let owners = [];
		owners.push({
			address: {
				address1: addressInfo.address1,
				address2: addressInfo.address2,
				city: addressInfo.city,
				state: addressInfo.state,
				zip: addressInfo.zip,
			},
			dateOfBirth: dayjs(ownersInfo.dateOfBirth).format("MM/DD/YYYY"),
			driverLicense: {
				expiration: dayjs(driverLicenseInfo.dlExpiration).format("MM/DD/YYYY"),
				number: driverLicenseInfo.dlNumber,
				state: driverLicenseInfo.dlState,
			},
			email: ownersInfo.email,
			firstName: ownersInfo.firstName,
			homePhone: String(ownersInfo.homePhone),
			isControlProng: ownersInfo.isControlProng,
			isSigner: ownersInfo.isSigner,
			lastName: ownersInfo.lastName,
			middleName: ownersInfo.middleName,
			mobilePhone: String(ownersInfo.mobilePhone),
			ownershipPercentage: ownersInfo.ownershipPercentage,
			ssn: ownersInfo.ssn,
			title: ownersInfo.title,
		});

		if (owner2.hasSecondOwner) {
			owners.push({
				address: {
					address1: owner2.o2Address1,
					address2: owner2.o2Address2,
					city: owner2.o2City,
					state: owner2.o2State,
					zip: owner2.o2Zip,
				},
				dateOfBirth: dayjs(owner2.o2DateOfBirth).format("MM/DD/YYYY"),
				driverLicense: {
					expiration: dayjs(owner2.o2DlExpiration).format("MM/DD/YYYY"),
					number: owner2.o2DlNumber,
					state: owner2.o2DlState,
				},
				email: owner2.o2Email,
				firstName: owner2.o2FirstName,
				homePhone: String(owner2.o2HomePhone),
				isControlProng: owner2.o2IsControlProng,
				isSigner: owner2.o2IsSigner,
				lastName: owner2.o2LastName,
				middleName: owner2.o2MiddleName,
				mobilePhone: String(owner2.o2MobilePhone),
				ownershipPercentage: owner2.o2OwnershipPercentage,
				ssn: owner2.o2Ssn,
				title: owner2.o2Title,
			});
		}
		let addressProductHeld = {};
		if (questionnaire.productStoreAboveAddress) {
			addressProductHeld = {
				state: addressProductHeld.phState,
				street: addressProductHeld.phStreet,
				// city: addressProductHeld.phCity,
				zipCode: addressProductHeld.phZipCode,
			};
		} else {
			// stored at business address
			addressProductHeld = {
				state: business.buState,
				street: business.buAddress1,
				// city: addressProductHeld.phCity,
				zipCode: business.buZip,
			};
		}

		let merchant = {
			userEmail: mainInfo.userEmail,
			userFullName: mainInfo.userFullName,
			acceptAeo: mainInfo.acceptAeo,
			acceptEbt: mainInfo.acceptEbt,
			acceptEbtNumber: mainInfo.acceptEbtNumber,
			acceptPinDeb: mainInfo.acceptPinDeb,
			acceptPinlessDeb: mainInfo.acceptPinlessDeb,
			acceptVisa: mainInfo.acceptVisa,
			acceptingBankcards: mainInfo.acceptingBankcards,
			acceptingBankcardsExplain: mainInfo.acceptingBankcardsExplain,
			billingFrequency: mainInfo.billingFrequency,
			business: {
				contactName: business.contactName,
				contactPhone: String(business.contactPhone),
				doingBusinessAs: {
					address: {
						address1: business.buAddress1,
						address2: business.buAddress2,
						city: business.buCity,
						state: business.buState,
						zip: business.buZip,
					},
					email: business.buEmail,
					fax: String(business.buFax),
					name: business.buName,
					phone: String(business.buPhone),
				},
				legal: {
					address: {
						address1: legal.leAddress1,
						address2: legal.leAddress2,
						city: legal.leCity,
						state: legal.leState,
						zip: legal.leZip,
					},
					email: legal.leEmail,
					fax: String(legal.leFax),
					name: legal.leName,
					phone: String(legal.lePhone),
				},
				mailingType: business.mailingType,
			},
			isPciCompliant: mainInfo.isPciCompliant,
			locationAreaZoned: mainInfo.locationAreaZoned,
			locationBuildingType: mainInfo.locationBuildingType,
			locationMerchant: mainInfo.locationMerchant,
			locationSquareFootage: mainInfo.locationSquareFootage,
			owners,
			profile: {
				averageAmexMonthlySalesVolume:
					profileInfo.averageAmexMonthlySalesVolume,
				averageMonthlySalesVolume: profileInfo.averageMonthlySalesVolume,
				averageTicketSize: profileInfo.averageTicketSize,
				businessType: "Retail",
				creditBankingInformation: {
					accountNumber: creditAccountInfo.creditAccountNumber,
					accountOwnership: creditAccountInfo.creditAccountOwnership,
					accountType: creditAccountInfo.creditAccountType,
					authorizedFirstName: creditAccountInfo.creditAuthorizedFirstName,
					authorizedLastName: creditAccountInfo.creditAuthorizedLastName,
					routingNumber: creditAccountInfo.creditRoutingNumber,
				},
				debitBankingInformation: {
					accountNumber: debitAccountInfo.debitAccountNumber,
					accountOwnership: debitAccountInfo.debitAccountOwnership,
					accountType: debitAccountInfo.debitAccountType,
					authorizedFirstName: debitAccountInfo.debitAuthorizedFirstName,
					authorizedLastName: debitAccountInfo.debitAuthorizedLastName,
					routingNumber: debitAccountInfo.debitRoutingNumber,
				},
				federalTaxId: profileInfo.federalTaxId,
				highestTicketSize: profileInfo.highestTicketSize,
				mccSicCode: profileInfo.mccSicCode,
				monthsInBusiness: profileInfo.monthsInBusiness,
				numberOfLocations: profileInfo.numberOfLocations,
				ownersBackground: {
					bankruptcyEverTerminated: backgroundInfo.bankruptcyEverTerminated,
					bankruptcyFiled: backgroundInfo.bankruptcyFiled,
					bankruptcyFiledYear: backgroundInfo.bankruptcyFiledYear,
				},
				ownershipType: profileInfo.ownershipType,
				typeOfGoodsServices: profileInfo.typeOfGoodsServices,
				yearInBusiness: profileInfo.yearInBusiness,
			},
			questionnaire: {
				addressProductHeld,
				advertise: questionnaire.advertise,
				businessPercentageSell: questionnaire.businessPercentageSell,
				currentCardBrandProcessor: questionnaire.currentCardBrandProcessor,
				customerCharged: questionnaire.customerCharged,
				deliverMerchandiseCustomer: questionnaire.deliverMerchandiseCustomer,
				describeProductService: questionnaire.describeProductService,
				dollarAmountChargebacks: questionnaire.dollarAmountChargebacks,
				fulfillmentHouse: questionnaire.fulfillmentHouse,
				fulfillmentHouseProvide: {
					address: fulfillmentHouseProvide.fulfillAddress,
					companyName: fulfillmentHouseProvide.fulfillCompanyName,
					telephone: String(fulfillmentHouseProvide.fulfillTelephone),
				},
				manyChargebacksPreviousYear: questionnaire.manyChargebacksPreviousYear,
				ownProductInventory: questionnaire.ownProductInventory,
				percentageSalesFrom: {
					cardPresent: percentageSalesFrom.cardPresent,
					internet: percentageSalesFrom.internet,
					mail: percentageSalesFrom.mail,
					telephone: percentageSalesFrom.telephone,
				},
				productStoreAboveAddress: questionnaire.productStoreAboveAddress,
				publicPercentageSell: questionnaire.publicPercentageSell,
				retailLocation: true,
				sell: questionnaire.sell,
				sellProductService: questionnaire.sellProductService,
				serverLocation: questionnaire.serverLocation,
				termsAndConditions: {
					corporationNameMatchApplicationName:
						termsAndConditions.corporationNameMatchApplicationName,
					currencyDollar: termsAndConditions.currencyDollar,
					dataShare: termsAndConditions.dataShare,
					membershipAnyType: termsAndConditions.membershipAnyType,
					negativeOptionBilling: termsAndConditions.negativeOptionBilling,
					privacyPolicy: termsAndConditions.privacyPolicy,
					purchasePage: termsAndConditions.purchasePage,
					refundPolicy: termsAndConditions.refundPolicy,
					secureProcessing: termsAndConditions.secureProcessing,
					shippingPolicy: termsAndConditions.shippingPolicy,
				},
				webSite: questionnaire.webSite,
			},
		};
		console.log(merchant);
		if (legal.leName === "") {
			console.log(legal.leName);
			methods.setError("business.legal.name", {
				message: "Field can't be empty",
			});
			failAlert("Please review application and correct errors");
			return;
		}
		createPepperPayMerchant(locationID, merchant).then(
			() => {
				setShowSubmitProgress(false);
				toggle();
				Swal.close();
				successAlertCustom("Successfully created PepperPay account");
			},
			(err) => {
				setShowSubmitProgress(false);
				console.log(err.response.data.content);
				const errorsArr = err.response?.data?.content;
				if (errorsArr) {
					Swal.close();
					errorsArr.forEach((e) => {
						methods.setError(e.source, { message: e.message });
					});
					failAlert("Please review application and correct errors");
				} else if (err.response?.data?.message) {
					Swal.close();
					failAlert(err.response?.data?.message);
				} else {
					Swal.close();
					failAlert("An unexpected error occurred, no feedback given");
				}
			}
		);
	};

	return (
		<div>
			<h3 className="col-12 section-title mb-3">Summary</h3>
			<MainInformation
				mainInfo={mainInfo}
				setMainInfo={setMainInfo}
				business={business}
				setBusiness={setBusiness}
				setCurrentForm={setCurrentForm}
				nextForm=""
				readOnly={true}
				disabled={true}
			/>
			<BusinessInformation
				mainInfo={mainInfo}
				setMainInfo={setMainInfo}
				profileInfo={profileInfo}
				setProfileInfo={setProfileInfo}
				business={business}
				legal={legal}
				setBusiness={setBusiness}
				setLegal={setLegal}
				setCurrentForm={setCurrentForm}
				nextForm=""
				previousForm=""
				readOnly={true}
				disabled={true}
			/>
			<OwnersInformation
				mainInfo={mainInfo}
				ownersInfo={ownersInfo}
				driverLicenseInfo={driverLicenseInfo}
				addressInfo={addressInfo}
				setMainInfo={setMainInfo}
				setOwnersInfo={setOwnersInfo}
				setDriverLicenseInfo={setDriverLicenseInfo}
				setAddressInfo={setAddressInfo}
				setCurrentForm={setCurrentForm}
				owner2={owner2}
				nextForm=""
				previousForm=""
				readOnly={true}
				disabled={true}
			/>
			<ProfileInformation
				profileInfo={profileInfo}
				creditAccountInfo={creditAccountInfo}
				debitAccountInfo={debitAccountInfo}
				backgroundInfo={backgroundInfo}
				setProfileInfo={setProfileInfo}
				setCreditAccountInfo={setCreditAccountInfo}
				setDebitAccountInfo={setDebitAccountInfo}
				setBackgroundInfo={setBackgroundInfo}
				setCurrentForm={setCurrentForm}
				nextForm=""
				previousForm=""
				readOnly={true}
				disabled={true}
			/>
			<Questionnaire
				questionnaire={questionnaire}
				addressProductHeld={addressProductHeld}
				fulfillmentHouseProvide={fulfillmentHouseProvide}
				percentageSalesFrom={percentageSalesFrom}
				termsAndConditions={termsAndConditions}
				setQuestionnaire={setQuestionnaire}
				setAddressProductHeld={setAddressProductHeld}
				setFulfillmentHouseProvide={setFulfillmentHouseProvide}
				setPercentageSalesFrom={setPercentageSalesFrom}
				settermsAndConditions={setTermsAndConditions}
				setCurrentForm=""
				nextForm=""
				previousForm=""
				readOnly={true}
				disabled={true}
			/>
			<div className="col-12">
				<button
					className="previous-button"
					onClick={() => setCurrentForm(previousForm)}
				>
					Previous
				</button>
				<button type="submit" className="submit-button" onClick={handleSubmit}>
					Submit
				</button>
			</div>
		</div>
	);
};
export default Summary;
