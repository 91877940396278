// Constants
export const MAX_FILE_SIZE = 1048576; // Size in bytes. This is equivalent to 1048.576 KB or 1MB
export const MIN_WIDTH = 240;
export const MAX_WIDTH = 2400;
export const MIN_HEIGHT = 135;
export const MAX_HEIGHT = 1350;

// Returns true if image is portrait
export const isImagePortrait = (width, height) => width < height;

// Returns true if file is bigger than 1MB
export const isFileSizeTooLarge = (size) => size > MAX_FILE_SIZE;

// Returns true if image is too large. This is an aspect ratio of 16:9
export const isImageTooLarge = (width, height) =>
	width > MAX_WIDTH || height > MAX_HEIGHT;

// Returns true if image is too small. This is an aspect ratio of 16:9
export const isImageTooSmall = (width, height) =>
	width < MIN_WIDTH || height < MIN_HEIGHT;

// Checks the actual file type uploaded and verifies that it is either
// PNG or JPEG/JPG
export const actualFileType = (header) => {
	let realMimeType;

	switch (header) {
		case "89504e47":
			realMimeType = "image/png";
			break;
		case "ffd8ffDB":
		case "ffd8ffe0":
		case "ffd8ffe1":
		case "ffd8ffe2":
		case "ffd8ffe3":
		case "ffd8ffe8":
			realMimeType = "image/jpeg";
			break;
		default:
			realMimeType = "";
			break;
	}

	return realMimeType;
};

// Returns an array of errors explaining why image wasn't accepted
export const getErrors = (isPortrait) => {
	let errors = [];

	if (isPortrait) {
		errors.push("Only landscape photos are accepted");
	}

	return errors;
};

// export const getErrors = (
// 	isPortrait
// 	// isFileLarge,
// 	// isResOver,
// 	// isResBelow,
// 	// width,
// 	// height,
// 	// size
// ) => {
// 	// const formattedSize =
// 	// 	size < 1024
// 	// 		? `${size}  Bytes`
// 	// 		: size >= 1024 && size < 1048576
// 	// 		? `${(size / 1024).toFixed(2)}KB`
// 	// 		: `${(size / 1048576).toFixed(2)}MB`;
// 	let errors = [];

// 	if (isPortrait) {
// 		errors.push("Only landscape photos are accepted");
// 	}

// 	// if (isFileLarge) {
// 	// 	errors.push(
// 	// 		`File size must be ${(MAX_FILE_SIZE / 1024).toFixed(
// 	// 			2
// 	// 		)}KB or smaller. File selected for upload was ${formattedSize}`
// 	// 	);
// 	// }

// 	// if (isResOver || isResBelow) {
// 	// 	errors.push(
// 	// 		`Image resolution must be between ${MIN_WIDTH}x${MIN_HEIGHT} and ${MAX_WIDTH}x${MAX_HEIGHT}.
// 	// 		Resolution for image selected for upload was ${width}x${height}. `
// 	// 	);
// 	// }

// 	return errors;
// };
