import React, { useState, useEffect } from "react";
import { Form } from "reactstrap";

import useCurrentCustomerStore from "store/CustomerStore";
import InputWrapper from "view/formValidation/InputWrapper";

import {
	StateCodeByStateName,
	generationType,
	genderType,
	CountryCodes,
	driverLicenseOption,
	militaryType,
	canadaStateOption,
} from "Constants";
import { EditableSelectorElement } from "view/formValidation/InputElement";
import { SelectorElement } from "../../../../view/formValidation/InputElement";
import { AdSource } from "Constants";
import InputElement, {
	PhoneInputElement,
	SsnInputElement,
} from "view/formValidation/InputElement";

import moment from "moment";
import shallow from "zustand/shallow";

import { useFormContext } from "react-hook-form";
import { useFromLocations } from "store/LocalStorageHelper";

const CustomerDetails = () => {
	// Customer store
	const {
		firstName,
		middleName,
		lastName,
		gender,
		generation,
		age,
		birthDate,
		email,
		cellPhone,
		extraPhone,
		homePhone,
		workPhone,
		license,
		licExpires,
		licState,
		SSN,
		source,
		military,
		licType,
		licCountry,
		//customerNumber,
		//editCustomerNumber,
		editFirstName,
		editMiddleName,
		editLastName,
		editGender,
		editGeneration,
		editAge,
		editBirthDate,
		editEmail,
		editCellPhone,
		editExtraPhone,
		editHomePhone,
		editWorkPhone,
		editLicense,
		editLicExpires,
		editLicState,
		editSSN,
		editSource,
		editMilitary,
		editLicType,
		editLicCountry,
	} = useCurrentCustomerStore(
		(state) => ({
			firstName: state.firstName,
			middleName: state.middleName,
			lastName: state.lastName,
			gender: state.gender,
			generation: state.generation,
			age: state.age,
			birthDate: state.birthDate,
			email: state.email,
			cellPhone: state.cellPhone,
			extraPhone: state.extraPhone,
			homePhone: state.homePhone,
			workPhone: state.workPhone,
			license: state.license,
			licExpires: state.licExpires,
			licState: state.licState,
			SSN: state.SSN,
			source: state.source,
			customerNumber: state.customerNumber,
			military: state.military,
			type: state.type,
			licType: state.licType,
			licCountry: state.licCountry,
			notes: state.notes,
			editFirstName: state.editFirstName,
			editMiddleName: state.editMiddleName,
			editLastName: state.editLastName,
			editGender: state.editGender,
			editGeneration: state.editGeneration,
			editAge: state.editAge,
			editBirthDate: state.editBirthDate,
			editEmail: state.editEmail,
			editCellPhone: state.editCellPhone,
			editExtraPhone: state.editExtraPhone,
			editHomePhone: state.editHomePhone,
			editWorkPhone: state.editWorkPhone,
			editLicense: state.editLicense,
			editLicExpires: state.editLicExpires,
			editLicState: state.editLicState,
			editSSN: state.editSSN,
			editSource: state.editSource,
			editCustomerNumber: state.editCustomerNumber,
			editMilitary: state.editMilitary,
			editType: state.editType,
			editNotes: state.editNotes,
			editLicType: state.editLicType,
			editLicCountry: state.editLicCountry,
		}),
		shallow
	);

	const state = useFromLocations("state");

	//get list of country state
	const [countryState, setCountryState] = useState(StateCodeByStateName);

	// Calculates the age if it's empty and birth date is added first
	const calculateAge = () => {
		if (birthDate != null) {
			const admission = moment(birthDate, "YYYY-MM-DD");
			const discharge = moment(new Date(), "YYYY-MM-DD");
			editAge(discharge.diff(admission, "years") || age);
		}
	};
	// Get validation context
	const methods = useFormContext();

	useEffect(() => {
		if (licCountry === "USA") {
			setCountryState(StateCodeByStateName);
			editLicState(state);
		} else if (licCountry === "CAN") setCountryState(canadaStateOption);
		else setCountryState([]);
		// eslint-disable-next-line
	}, [licCountry]);

	const personal = [
		<InputElement
			{...methods}
			value={firstName}
			onChange={(e) => editFirstName(e.target.value)}
			name="firstName"
			label="First Name"
			type="text"
			labelClass="required"
		/>,
		<InputElement
			{...methods}
			value={lastName}
			onChange={(e) => editLastName(e.target.value)}
			name="lastName"
			label="Last Name"
			type="text"
			labelClass="required"
		/>,
		<InputElement
			{...methods}
			value={middleName}
			onChange={(e) => editMiddleName(e.target.value)}
			name="middleName"
			label="Middle Name"
			type="text"
		/>,
		<SelectorElement
			{...methods}
			name="generation"
			label="Generation"
			value={generation}
			onChange={(e) => editGeneration(e.target.value)}
			options={generationType}
		/>,
		<SelectorElement
			{...methods}
			name="military"
			label="Military"
			value={military}
			onChange={(e) => editMilitary(e.target.value)}
			options={militaryType}
		/>,
		<SelectorElement
			{...methods}
			name="gender"
			label="Gender"
			value={gender}
			onChange={(e) => editGender(e.target.value)}
			options={genderType}
		/>,
		<InputElement
			{...methods}
			value={birthDate}
			onChange={(e) => editBirthDate(e.target.value)}
			onBlur={calculateAge}
			name="birthDate"
			label="Birth Date"
			type="date"
		/>,
		<SsnInputElement
			{...methods}
			name="ssn"
			label="Social Security Number"
			value={SSN}
			onChange={(e) => {
				editSSN(e);
			}}
		/>,

		// <InputElement
		// 	{...methods}
		// 	value={customerNumber}
		// 	onChange={(e) => editCustomerNumber(e.target.value)}
		// 	name="customerNumber"
		// 	label="Customer Number"
		// 	type="text"
		// />,
		// <InputElement
		// 	{...methods}
		// 	value={age}
		// 	onChange={(e) => editAge(e.target.value)}
		// 	name="age"
		// 	label="Days"
		// 	type="number"
		// />,

		<PhoneInputElement
			{...methods}
			value={cellPhone}
			onChange={editCellPhone}
			name="cellPhone"
			label="Cell Phone"
		/>,

		<PhoneInputElement
			{...methods}
			value={homePhone}
			onChange={editHomePhone}
			name="homePhone"
			label="Primary Phone"
		/>,
		<PhoneInputElement
			{...methods}
			value={extraPhone}
			onChange={editExtraPhone}
			name="extraPhone"
			label="Extra Phone"
		/>,
		<PhoneInputElement
			{...methods}
			value={workPhone}
			onChange={editWorkPhone}
			name="workPhone"
			label="Work Phone"
		/>,
		<InputElement
			{...methods}
			value={email}
			onChange={(e) => editEmail(e.target.value)}
			name="email"
			label="Email"
			type="text"
		/>,
		<SelectorElement
			{...methods}
			value={licType}
			onChange={(e) => {
				editLicType(e.target.value);
			}}
			name="licType"
			label="ID Type"
			options={driverLicenseOption}
		/>,

		<SelectorElement
			{...methods}
			value={licCountry}
			onChange={(e) => {
				editLicCountry(e.target.value);
			}}
			name="licCountry"
			label="ID Country"
			options={CountryCodes}
		/>,
		<SelectorElement
			{...methods}
			readOnly={licCountry !== "USA" && licCountry !== "CAN"}
			name="licState"
			label="ID State"
			value={licState || state}
			onChange={(e) => {
				editLicState(e.target.value);
			}}
			options={countryState}
		/>,
		<InputElement
			{...methods}
			value={license}
			onChange={(e) => editLicense(e.target.value)}
			name="license"
			label="ID Number"
			type="text"
		/>,
		<InputElement
			{...methods}
			value={licExpires}
			onChange={(e) => {
				console.log(typeof licExpires);
				editLicExpires(e.target.value);
			}}
			name="licExpires"
			label="Expires on"
			type="date"
		/>,

		<EditableSelectorElement
			label="Source"
			value={source}
			onChange={(e) => editSource(e?.toLowerCase())}
			options={AdSource}
		/>,
	];
	return (
		<Form>
			<InputWrapper formTitle="Personal" inputComponents={personal} />
		</Form>
	);
};

export default CustomerDetails;
