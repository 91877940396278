import create from "zustand";

const useCurrentSettingStore = create((set) => ({
	taxDefaults: {},
	profitSettings: {
		// defaults and expected values
		// isDocFeeProfit: 2,
		// isAftMktProfit: 1,
		// isServConProfit: 2,
		// isGapProfit: 2,
		// isMiscProfit: 1,
		// isBankFeeProfit: 1,
		// isReserveProfit: 0,
		// isDealerSmogProfit: 1,
	},
	commissionSettings: {},

	editTaxDefaults: (e) => set(() => ({ taxDefaults: e })),

	editProfitSettings: (e) => set(() => ({ profitSettings: e })),

	editCommissionSettings: (e) => set(() => ({ commissionSettings: e })),

	setSettingFields: (res) =>
		set((state) => ({
			...res,
		})),

	reset: () =>
		set({
			taxDefaults: {},
			profitSettings: {
				// defaults and expected values
				// isDocFeeProfit: 2,
				// isAftMktProfit: 1,
				// isServConProfit: 2,
				// isGapProfit: 2,
				// isMiscProfit: 1,
				// isBankFeeProfit: 1,
				// isReserveProfit: 0,
				// isDealerSmogProfit: 1,
			},
			commissionSettings: {},
		}),
}));

export default useCurrentSettingStore;
