import React from "react";
import Public from "layout/Public.js";
import Dev from "layout/Dev";
import { Route, Redirect } from "react-router-dom";
import { authenticated } from "auth/Authentication";

const PrivateRoute = (props, { children, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(prop) =>
				authenticated() ? (
					props.devRoute ? (
						<Dev {...prop} />
					) : (
						<Public {...prop} />
					)
				) : (
					<Redirect to="/login" />
				)
			}
		/>
	);
};

export default PrivateRoute;
