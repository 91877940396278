import React, { useEffect, useState } from "react";
import {
	Card,
	Row,
	CardTitle,
	Col,
	CardHeader,
	Form,
	Label,
	CardBody,
	Button,
	CardText,
	CardFooter,
	FormGroup,
	Input,
} from "reactstrap";
import shallow from "zustand/shallow";
import dayjs from "dayjs";
import NumberFormat from "react-number-format";

import useCurrentSaleStore from "../../store/SaleStore";
import { DealStatus } from "../../Constants";
import useCalculateSales from "../../helperFunction/deal/useCalculateSales";
import useCurrentDealStore from "../../store/DealStore";
import tabOnEnter from "../../helperFunction/tabOnEnter";
import ContractInfo from "../../component/dc/deal/dealDetails/ContractInfoDetail";
import LabelAndInput from "../../component/dc/deal/sale/LabelAndInput";
import InsuranceAndGAP from "../../component/dc/deal/sale/saleComponents/InsuranceAndGAP";
import ServiceContract from "../../component/dc/deal/sale/saleComponents/ServiceContract";
import Aftermarket from "../../component/dc/deal/sale/saleComponents/Aftermarket";
import DownPayment from "../../component/dc/deal/sale/saleComponents/DownPayment";
import Taxes from "../../component/dc/deal/sale/saleComponents/Taxes";
import DMVFee from "../../component/dc/deal/sale/saleComponents/DMVFee";
import SellerFees from "../../component/dc/deal/sale/saleComponents/SellerFees";
import { ZipInputElement } from "../formValidation/InputElement";
import { useFormContext } from "react-hook-form";
import ZipCityTable from "../../component/dc/address/ZipCityTable";
import ChildrenModal from "../../component/dc/modal/ChildrenModal";
import { showApiError } from "../../helperFunction/ApiErrorHandler";
import { retrieveFailAlert } from "../../component/dc/modal/SweetAlerts";
import { getSettings } from "../../api/SettingsAPI";
import PrepaidFinanceCharge from "../../component/dc/deal/finance/PrepaidFinanceCharge";
import AdjustmentAPR from "../../component/dc/deal/finance/AdjustmentAPR";
import { useFromUser } from "../../store/LocalStorageHelper";
import useCurrentRecapStore from "../../store/RecapStore";
import NumberSelector from "../../component/dc/selector/NumberSelector";
import FinanceDaysToPayInput from "../../component/dc/deal/finance/FinanceDaysToPayInput";
import FinanceAPRInput from "../../component/dc/deal/finance/FinanceAPRInput";
import FinanceTermInput from "../../component/dc/deal/finance/FinanceTermInput";
import OpenModalButton from "../../component/dc/deal/OpenModalButton";
import PaymentOptionsModal from "../../component/dc/deal/paymentOptions/modal/PaymentOptionsModal";
import useSetDeal from "../../helperFunction/deal/useSetDeal";
import InventoryListModal from "../../component/dc/deal/inventory/InventoryListModal";
import TaxCountySelector from "../../component/dc/deal/statusBar/TaxCountySelector";
import {
	//createFileAndDownload,
	displayError,
	displayPdf,
	formLoading,
	getQuickQuote,
} from "../../api/FormsAPI";
import { getBuildDealTemplates } from "api/DealAPI";
//import DealTemplateList from "../settings/settingsTabs/dealTemplates/DealTemplateList";
import useCurrentSettingStore from "../../store/SettingStore";
import Swal from "sweetalert2";
import useBuildDeal from "helperFunction/deal/useBuildDeal";

export const QuickQuoteDeal = () => {
	const today = dayjs().format("YYYY-MM-DD");
	const dealNum = dayjs().format("MMDDYY-HHmmss");

	const {
		taxDefaults,
		commissionSettings,
		profitSettings,
		setSettingFields,
	} = useCurrentSettingStore((state) => state, shallow);
	const [templates, setTemplates] = useState([]);
	const [selectedIndex, setSelectedIndex] = useState(-1); // The current selected template index from the template list
	const [zip, setZip] = useState("");

	const [cityModal, setCityModal] = useState(false);
	const [addressData, setAddressData] = useState({});

	// Toggles the modal
	const toggleCityModal = () => setCityModal(!cityModal);

	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const userID = useFromUser("ID");
	const dcLoginID = useFromUser("ID");

	console.log(userID);

	const methods = useFormContext();
	//recap Store
	const { resetRecap } = useCurrentRecapStore(
		(state) => ({
			resetRecap: state.reset,
		}),
		shallow
	);

	// Sale store
	const {
		resetSale,
		editDealNumber,
		editLoanDate,
		editFirstDueDate,
		editFinalDueDate,
		editTaxRate,
		editBankFee,
	} = useCurrentSaleStore(
		(state) => ({
			resetSale: state.reset,
			editDealNumber: state.editDealNumber,
			editLoanDate: state.editLoanDate,
			editFirstDueDate: state.editFirstDueDate,
			editFinalDueDate: state.editFinalDueDate,
			editTaxRate: state.editTaxRate,
			editBankFee: state.editBankFee,
		}),
		shallow
	);
	// Deal store,
	const { resetDeal, currentAddress, editCurrentAddress } = useCurrentDealStore(
		(state) => ({
			resetDeal: state.reset,
			currentAddress: state?.buyerAddress || {},
			editCurrentAddress: state.editBuyerAddress,
		}),
		shallow
	);

	const {
		editDealSettings,
		editGapCoName,
		vehicle,
		editIsLoading,
		editSalesmanRecNum,
		editSalesPeople,
		editLenders,
		editTradeVendors,
		editWarrantyVendors,
		editSellerFeeTypes,
		editDmvFeeTypes,
		editGapCompanies,
		editCredit700Lenders,
		editCountyRecNum,
		editTrades,
	} = useCurrentDealStore((state) => state, shallow);

	const getDeal = useBuildDeal();
	const setDeal = useSetDeal();

	const handleCurrentZipChange = (zip) => {
		setAddressData({
			zip,
			address: {
				city: currentAddress.city,
				state: currentAddress.state,
				zip,
			},
		});
		toggleCityModal();
	};

	// TODO link to special endpoint that generates form from DealObj in request body
	const handlePrint = () => {
		formLoading("PDF");
		const deal = getDeal();
		console.log(deal);
		getQuickQuote(deal).then(
			(response) => {
				displayPdf(response);
				Swal.close();
			},
			(err) => {
				displayError(err);
			}
		);
	};

	const initializeNewDeal = (res) => {
		console.log(res[0].data.content);
		console.log(res[1].data.content);
		console.log(res[2].data.content);

		let settingsRes = res[1].data.content;
		const newDealRes = res[2].data.content;

		editSalesPeople(newDealRes.salesPeople);
		editLenders(newDealRes.lenders);
		editTradeVendors(newDealRes.tradeVendors);
		editWarrantyVendors(newDealRes.warrantyVendors);
		editSellerFeeTypes(newDealRes.sellerFeeTypes);
		editDmvFeeTypes(newDealRes.dmvFeeTypes);
		editGapCompanies(newDealRes.gapCompanies);
		editCredit700Lenders(newDealRes.credit700Lenders);
		settingsRes.taxDefaults.taxRegion = 0;
		editDealSettings(settingsRes);
		editDealNumber(dealNum);
		editLoanDate(today);
		editFirstDueDate(today);
		editFinalDueDate(today);
		editIsLoading(false);
		editSalesmanRecNum(dcLoginID);
		editBankFee(0);
		let tempTrade = { allowance: 0, payoff: 0 };
		editTrades([tempTrade]);

		editCountyRecNum(settingsRes.taxDefaults.countyRecNum);
		editTaxRate(settingsRes.taxDefaults.taxRate);
	};

	const setTemplate = (obj) => {
		// doing this to conform to setDeal rules
		let deal = {};
		let data = {};
		let content = {};

		deal.data = data;
		deal.data.content = content;
		deal.data.content = obj.deal;
		deal.data.content.buyerAddress = currentAddress;
		deal.data.content.vehicle = vehicle;
		deal.data.content.deal.sale.loanDate = today;
		deal.data.content.deal.sale.firstDueDate = today;
		deal.data.content.deal.salesPersonRecnum = userID;
		deal.data.content.deal.dealSettings = JSON.stringify({
			taxDefaults,
			commissionSettings,
			profitSettings,
		});
		setDeal(deal);
		editCountyRecNum(taxDefaults.countyRecNum);
		editTaxRate(taxDefaults.taxRate);
		editGapCoName(obj.gapCoName);
		editBankFee(0);
		let tempTrade = { allowance: 0, payoff: 0 };
		editTrades([tempTrade]);
	};
	// Set the store values to the selected template, else empty
	useEffect(() => {
		if (Number(selectedIndex) !== -1) {
			setTemplate(templates[selectedIndex]);
		} else {
			resetDeal();
			resetSale();
			resetRecap();
		}
		// eslint-disable-next-line
	}, [selectedIndex]);

	useEffect(() => {
		resetDeal();
		resetSale();
		resetRecap();

		Promise.all([
			getBuildDealTemplates(dealerID, locationID),
			getSettings(dealerID, locationID),
		]).then(
			(res) => {
				const data = res[0].data.content.templates;
				setSettingFields(res[1].data.content);
				editDealSettings(res[1].data.content);
				setTemplates(data);
				if (data.length === 0) {
					// TODO this is broken, need to call blank deal too
					initializeNewDeal(res);
				} else if (data.length >= 1) {
					setSelectedIndex(0);
					// for some reason this causes an infinite loop even though api is called only once
					//setTemplate(data[0]);
				}
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);

		return () => {
			console.log("resetting");
			resetDeal();
			resetSale();
			resetRecap();
		};
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<div className="pt-3 pb-3 mobileHeading">
				<Col className="text-center">
					<CardTitle>
						{" "}
						<h2>Deal Quote</h2>
					</CardTitle>
				</Col>
			</div>
			<Row>
				<div className="col-lg-4">
					<DealTemplateList
						templates={templates}
						selected={selectedIndex}
						setSelected={setSelectedIndex}
					/>
				</div>
				<div className="col-lg-4">
					<ZipInputElement
						{...methods}
						value={zip}
						onChange={setZip}
						openZipModal={handleCurrentZipChange}
						name="currentZip"
						label="ZipCode"
						disableValidation
						wholeRow
					/>
					<Label>
						{currentAddress.zip !== undefined &&
							currentAddress.city +
								" " +
								currentAddress.state +
								" " +
								currentAddress.zip}
					</Label>
				</div>

				<div className="col-lg-4">
					<Label>
						Stock
						<Input value={vehicle?.stockNo} />
						{vehicle?.year !== undefined &&
							vehicle?.year + " " + vehicle?.make + " " + vehicle?.model}
						<InventoryListModal modalTitle="Select Contact" />
					</Label>
				</div>

				<ChildrenModal
					modal={cityModal}
					toggle={toggleCityModal}
					modalTitle="Cities"
				>
					<ZipCityTable
						data={addressData}
						toggle={toggleCityModal}
						address={currentAddress}
						setAddress={editCurrentAddress}
						isDeal={true}
						isBuyer={true}
					/>
				</ChildrenModal>
			</Row>
			<Row>
				<div className="col-md-4">
					<Card>
						<Sale />
					</Card>
				</div>
				<div className="col-md-4">
					<Card>
						<TermsTable />
					</Card>
				</div>
				<div className="col-md-4">
					<Card>
						<Summary />
						<CardFooter>
							<Button
								onClick={() => {
									handlePrint();
								}}
							>
								Print
							</Button>
						</CardFooter>
					</Card>
				</div>
			</Row>
		</>
	);
};
export default QuickQuoteDeal;

const Sale = () => {
	const {
		price,
		trade,
		payoff,
		manufacturerRebate,
		editPrice,
		editManufacturerRebate,
	} = useCurrentSaleStore(
		(state) => ({
			price: state.price,
			manufacturerRebate: state.manufacturerRebate,
			editPrice: state.editPrice,
			editManufacturerRebate: state.editManufacturerRebate,
			trade: state.trade,
			payoff: state.payoff,
		}),
		shallow
	);

	const { type, trades, editTrades, calculate } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
			// taxRegion: state?.deal?.dealSettings?.taxDefaults?.taxRegion,
			trades: state.trades,
			editTrades: state.editTrades,
			calculate: state.calculate,
		}),
		shallow
	);

	const handleTrade = (val) => {
		console.log(trades);
		let tempTrade = { ...trades[0] };
		console.log(tempTrade);
		tempTrade.allowance = val;
		editTrades([tempTrade]);
		calculate();
	};

	const handlePayoff = (val) => {
		console.log(trades);
		let tempTrade = { ...trades[0] };
		console.log(tempTrade);
		tempTrade.payoff = val;
		editTrades([tempTrade]);
		calculate();
	};

	useCalculateSales();

	return (
		<div>
			<CardHeader>
				<CardTitle tag="h3">Quote Deal</CardTitle>
			</CardHeader>
			<div className="card-body pb-3" onKeyDown={tabOnEnter}>
				<Form className="deal-sale">
					<Row>
						<div className="col-xl-6 pl-2 pr-0">
							<LabelAndInput
								name="dealPrice"
								value={price}
								label="Price"
								onChange={editPrice}
								btnClass="w-100 btn-md btn-primary  btn"
								buttonLabel="true"
							/>
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<DMVFee />
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<SellerFees />
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<InsuranceAndGAP />
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<ServiceContract />
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<Aftermarket />
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<ContractInfo />
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<LabelAndInput
								name="dealMFR"
								className="text-danger bold"
								disabled={type !== DealStatus.PENDING}
								buttonLabel={true}
								value={manufacturerRebate}
								label="MFR Rebate"
								onChange={editManufacturerRebate}
								sign="-"
								btnClass="w-100 btn-md btn-primary btn px-0"
							/>
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<LabelAndInput
								name="trade"
								className="text-danger bold"
								buttonLabel={true}
								value={trade}
								label="Trade-In"
								onChange={handleTrade}
								sign="-"
								btnClass="w-100 btn-md btn-primary btn px-0"
							/>
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<LabelAndInput
								name="payoff"
								className="text-primary bold"
								buttonLabel={true}
								value={payoff}
								label="Payoff"
								onChange={handlePayoff}
								sign="+"
								btnClass="w-100 btn-md btn-primary btn px-0"
							/>
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<DownPayment />
						</div>
						{/* <div className="col-xl-6 pl-2 pr-0">
							<DeferredPickup />
						</div> */}
						<div className="col-xl-6 pl-2 pr-0">
							<Taxes />
						</div>
						<div className="col-12 pl-2 pr-0">
							<hr className="my-2" />
							<TaxCountySelector />
						</div>
					</Row>
				</Form>
			</div>
		</div>
	);
};
const TermsTable = () => {
	const [showProfit, setShowProfit] = useState(false);

	const toggle = () => {
		setShowProfit(!showProfit);
	};

	const { loanPeriod, editLoanPeriod, bankFee } = useCurrentSaleStore(
		(state) => ({
			loanPeriod: state.loanPeriod,
			editLoanPeriod: state.editLoanPeriod,
			bankFee: state.bankFee,
		}),
		shallow
	);
	//recap Store
	const { frontendProfit, backendProfit } = useCurrentRecapStore(
		(state) => ({
			frontendProfit: state.frontendProfit,
			backendProfit: state.backendProfit,
		}),
		shallow
	);

	// Deal store
	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);
	//toggle payment option
	const [paymentOptions, setPaymentOptions] = useState(false);
	const togglePayment = () => setPaymentOptions(!paymentOptions);

	return (
		<div>
			<CardHeader>
				<CardTitle tag="h3">Payments</CardTitle>
			</CardHeader>
			<CardBody className="pb-0" onKeyDown={tabOnEnter}>
				<Form className="deal-terms-table">
					<Row className="mx-0">
						<Col xl="5" className="mb-2">
							<Label className="">Period</Label>
							<NumberSelector
								name="dealLoanPeriod"
								readOnly={type !== DealStatus.PENDING}
								value={loanPeriod}
								selectedOption={loanPeriod}
								handleChange={(e) => {
									editLoanPeriod(e);
								}}
								selectType={"loanPeriod"}
								default={true}
							/>
						</Col>
						<Col xl="5" className="mb-2">
							<Label value="">Days to 1st Pmt</Label>
							<FinanceDaysToPayInput />
						</Col>
						<Col xl="3" className="mb-2">
							<FinanceAPRInput />
						</Col>
						<Col xl="3" className="mb-2">
							<Label value="">Term</Label>
							<FinanceTermInput />
						</Col>
					</Row>

					{bankFee > 0 && (
						<Row>
							<Col xl="4" className="mb-2">
								<Label value="">Adj </Label>
								<AdjustmentAPR />
							</Col>
							<Col xl="6" className="mb-2">
								<Label value="">Prepaid Finance Charge</Label>
								<PrepaidFinanceCharge />
							</Col>
						</Row>
					)}

					<hr className="my-2" />
					<Col>
						<Row className="mb-3">
							<div className={!showProfit ? "mx-auto" : "row"}>
								<Button className="px-4 " color="primary" onClick={toggle}>
									QP
								</Button>
								{showProfit && (
									<CardText
										className={
											frontendProfit + backendProfit > 0
												? "text-default px-4 pt-2"
												: "text-danger px-4 pt-2"
										}
										tag="h5"
									>
										<NumberFormat
											value={frontendProfit + backendProfit}
											thousandSeparator={true}
											decimalScale={2}
											fixedDecimalScale={true}
											prefix={"$"}
											isNumericString={true}
											displayType="text"
										/>
									</CardText>
								)}
								<OpenModalButton
									buttonText="Payment Options"
									onClick={togglePayment}
									iconClass="nc-icon nc-refresh-69"
									className="btn btn-lg btn-link"
								/>
								<ChildrenModal
									modalTitle="Payment Options"
									modal={paymentOptions}
									toggle={togglePayment}
									children={<PaymentOptionsModal toggle={togglePayment} />}
									size="lg"
								/>
							</div>
						</Row>
					</Col>
				</Form>
				<CardFooter />
			</CardBody>
		</div>
	);
};

const Summary = () => {
	const {
		firstDueDate,
		finalDueDate,
		payment,
		// finalPmt,
		term,
		amtFinanced,
		financeCharge,
		loan,
		balloonDate,
		balloonTerm,
		balloon,
		hasBalloon,
		// onePay,
	} = useCurrentSaleStore((state) => state, shallow);
	console.log(financeCharge);
	const CurrencyDisplay = ({ value, className = "bold" }) => (
		<NumberFormat
			readOnly={true}
			value={value}
			thousandSeparator={true}
			decimalScale={2}
			fixedDecimalScale={true}
			prefix={"$"}
			isNumericString={true}
			customInput={Input}
			className={className}
		/>
	);

	const summaryRow1 = [
		{
			label: "First Due Date",
			value: (
				<Input
					className="bold"
					value={firstDueDate ? dayjs(firstDueDate).format("MM/DD/YYYY") : ""}
					disabled
				/>
			),
		},
		{
			label: "Final Due Date",
			value: (
				<Input
					className="bold"
					value={finalDueDate ? dayjs(finalDueDate).format("MM/DD/YYYY") : ""}
					disabled
				/>
			),
		},
		{
			label: (term > 1 ? term : 1) + " Payments of",
			value: (
				<CurrencyDisplay value={payment} className="regular-payment bold" />
			),
		},
	];

	const balloonSummaryRow = [
		{
			label: "First Due Date",
			value: (
				<Input
					className="bold"
					value={firstDueDate ? dayjs(firstDueDate).format("MM/DD/YYYY") : ""}
					disabled
				/>
			),
		},
		{
			label: "Balloon Date",
			value: (
				<Input
					className="bold"
					value={balloonDate ? dayjs(balloonDate).format("MM/DD/YYYY") : ""}
					disabled
				/>
			),
		},
		{
			label: (balloonTerm >= 1 ? balloonTerm : 0) + " Payments",
			value: <CurrencyDisplay value={payment} className="text-success bold" />,
		},
		{
			label: "Balloon Payment",
			value: <CurrencyDisplay value={balloon} />,
		},
	];

	const summaryRow2 = [
		// {
		// 	label: "Out the Door Price",
		// 	value: <CurrencyDisplay value={onePay} />,
		// },
		{
			label: "Amount Financed",
			value: <CurrencyDisplay value={amtFinanced} className="bold" />,
		},
		{
			label: "Finance Charge",
			value: <CurrencyDisplay value={financeCharge} />,
		},
	];

	const summaryRow3 = [
		{
			label: "Total of Payments",
			value: <CurrencyDisplay value={loan} />,
		},
	];

	let row1 = summaryRow1;

	if (hasBalloon) {
		row1 = balloonSummaryRow;
	}

	return (
		<div className="deal-summary">
			<CardHeader>
				<CardTitle tag="h3">Deal Summary</CardTitle>
			</CardHeader>
			<CardBody className="pb-3">
				<Row>
					{row1.map((obj) => (
						<>
							<Col className="mb-2 col-6 col-xxl-6">
								<Label value="">{obj.label}</Label>
								{obj.value}
							</Col>
						</>
					))}
				</Row>
				<hr className="my-2" />
				{summaryRow2.map((obj) => (
					<div>
						<Row className="">
							<Col xl="6">
								<Label className="pt-2">{obj.label}</Label>
							</Col>
							<Col xl="6">{obj.value}</Col>
						</Row>
					</div>
				))}

				<hr className="my-2" />
				{summaryRow3.map((obj) => (
					<Row>
						<Col className="my-2 col-6 px-0 text-center">
							<h6>{obj.label}</h6>
						</Col>
						<Col className="my-2 col-6 px-0 text-center deal-total">
							{obj.value}
						</Col>
					</Row>
				))}
			</CardBody>
		</div>
	);
};

const DealTemplateList = ({ templates, selected, setSelected }) => {
	const options = templates.map((obj, idx) => (
		<option key={obj.template.ID} value={idx}>
			{obj.template.name}
		</option>
	));

	return (
		<div className="d-flex justify-content-center mt-2 mb-5">
			<FormGroup className="col">
				<Label>Deal Template</Label>
				<Input
					onChange={(e) => setSelected(e.target.value)}
					type="select"
					value={selected}
					className="bold"
				>
					{options != null && options.length > 0 && options}
				</Input>
			</FormGroup>
		</div>
	);
};
