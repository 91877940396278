import React, { useContext, useEffect, useState } from "react";
import { Button } from "reactstrap";
import { displayPdf, displayError, formLoading } from "api/FormsAPI";
import { FormOrReportRequest } from "./RequestModal";
import swal from "sweetalert2";
import {
	createFileAndDownload,
	//createPdfAndDownload,
	getPdf,
	mergePack,
	zipLenderPack,
	zipPack,
} from "../../../api/FormsAPI";
import DynamicModal from "../modal/DynamicModal";
import SendEmailInWizard from "../integration/mailgun/SendEmailInWizard";
import { failAlert, saveSuccessAlert } from "../modal/SweetAlerts";
import { sendMailgunEmail } from "../../../api/dealerIntegrationsApi";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";
import WizardProvider from "../modal/WizardContext";
import FormsReportsProvider, {
	FormsReportsContext,
} from "./FormsReportsContext";
import { PackRequestContext } from "./PackContexts";
import useCurrentDealStore from "../../../store/DealStore";
import shallow from "zustand/shallow";
import { useFromUser } from "../../../store/LocalStorageHelper";
import { FormsEmailContext } from "./FormsEmailContext";

export const PdfRequestButton = ({ dealID, inventoryID, dcReport }) => {
	const [openRequestModal, setOpenRequestModal] = useState(false);
	const { pdfRequest, setPdfRequest } = useContext(FormsReportsContext);
	// Function to open the modal
	const toggle = () => {
		setOpenRequestModal(!openRequestModal);
	};
	// double parsing again
	const param = JSON.parse(dcReport.parameters)?.parameters || [];
	// console.log(dcReport);
	// console.log(param);
	useEffect(() => {
		setPdfRequest((prevState) => {
			return {
				...prevState,
				dealID,
				inventoryID,
				dcReportID: dcReport.id,
				dcReport,
			};
		});
		// eslint-disable-next-line
	}, []);

	return (
		<>
			{openRequestModal ? (
				<FormOrReportRequest
					modal={openRequestModal}
					toggle={toggle}
					parameters={dcReport.parameters}
					dcReport={dcReport}
				/>
			) : null}

			{param.length > 0 ? (
				<Button
					color="primary"
					onClick={() => {
						// toggle the modal for getting parameters for the pdf
						toggle();
					}}
				>
					<i className="nc-icon nc-zoom-split" /> View
				</Button>
			) : (
				<Button
					color="primary"
					onClick={() => {
						//let id = "";
						// if (dealID !== null) {
						// 	id = dealID;
						// } else if (inventoryID !== null) {
						// 	id = inventoryID;
						// }
						//const fileName = dcReport.title + "-" + id + ".pdf";
						formLoading("PDF");
						getPdf(pdfRequest).then(
							(response) => {
								//createPdfAndDownload(response, fileName);
								displayPdf(response);
								swal.close();
							},
							(err) => {
								displayError(err);
							}
						);
					}}
				>
					<i className="nc-icon nc-zoom-split" /> View
				</Button>
			)}
		</>
	);
};

export const EmailPdfRequestButton = ({ dealID, inventoryID, dcReport }) => {
	const [openModal, setOpenModal] = useState(false);
	// console.log(dcReport);
	const paramsToRender = JSON.parse(dcReport.parameters)?.parameters || [];
	const { pdfRequest, setPdfRequest } = useContext(FormsReportsContext);
	const { emailRequest, setEmailRequest } = useContext(FormsEmailContext);

	const toggle = () => {
		setOpenModal(!openModal);
	};
	// console.log(emailRequest);
	// Calls mailgun api to send the email
	const submit = () => {
		const email = {
			...emailRequest,
			pdfRequest,
		};
		formLoading("Email");
		// console.log(email);
		email.dealID = dealID;
		sendMailgunEmail(email).then(
			(res) => {
				swal.close();
				toggle();
				saveSuccessAlert(res.data.message);
			},
			(err) => {
				swal.close();
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}
			}
		);
	};

	useEffect(() => {
		setPdfRequest((prevState) => {
			return {
				...prevState,
				dealID,
				inventoryID,
				dcReportID: dcReport.id,
				dcReport,
			};
		});
		setEmailRequest((prevState) => {
			return { ...prevState, fileName: dcReport.title };
		});
		// eslint-disable-next-line
	}, []);

	let components = [];
	if (paramsToRender.length > 0) {
		components = [
			<FormOrReportRequest
				parameters={dcReport.parameters}
				isWizard={true}
				dcReport={dcReport}
			/>,
			<SendEmailInWizard submit={submit} />,
		];
	} else {
		components = [<SendEmailInWizard submit={submit} />];
	}
	//console.log(components);
	return (
		<>
			<div className="btn-round btn btn-md btn-info" onClick={toggle}>
				{" "}
				<i className="nc-icon nc-send" /> Email
			</div>
			<WizardProvider>
				<DynamicModal
					components={components}
					toggle={toggle}
					modal={openModal}
				/>
			</WizardProvider>
		</>
	);
};

export const PackRequestButton = ({
	packObj,
	dealID,
	merge = false,
	zip = false,
	attach = false,
}) => {
	const [openModal, setOpenModal] = useState(false);
	const [paramsToRender, setParamsToRender] = useState({});
	const [dcReports, setDcReports] = useState({});
	const { lenderPackRequest, setLenderPackRequest } = useContext(
		PackRequestContext
	);

	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const { inventoryID } = useCurrentDealStore(
		(state) => ({
			inventoryID: state.deal.carRecNum,
		}),
		shallow
	);

	const toggle = () => {
		setOpenModal(!openModal);
	};
	// console.log(packObj);
	// Calls mailgun api to send the email
	const submit = () => {
		if (merge) {
			formLoading("Form");
			mergePack(lenderPackRequest).then(
				(response) => {
					displayPdf(response);
					swal.close();
				},
				(err) => {
					swal.close();
					swal.fire("Error!", err.message, "error");
				}
			);
		} else if (zip) {
			formLoading("Zip");
			zipPack(lenderPackRequest).then(
				(response) => {
					const fileName = packObj.pack.packName + "-" + dealID + ".zip";
					createFileAndDownload(response.data, fileName);
					swal.close();
				},
				(err) => {
					swal.close();
					swal.fire("Error!", err.message, "error");
				}
			);
		} else if (attach) {
			formLoading("Zip");
			zipLenderPack(lenderPackRequest).then(
				(response) => {
					const fileName = "LenderPack-" + dealID + ".zip";
					createFileAndDownload(response.data, fileName);
					swal.close();
					toggle();
				},
				(err) => {
					swal.close();
					swal.fire("Error!", err.message, "error");
				}
			);
		}
		toggle();
	};

	useEffect(() => {
		let paramsList = {};
		setParamsToRender({});
		packObj.forms.forEach((form) => {
			paramsList[form.id] = { dealerID, locationID };
			const formParameters = JSON.parse(form.parameters)?.parameters || [];
			if (formParameters.length > 0) {
				setParamsToRender((prevState) => {
					return { ...prevState, [form.id]: formParameters };
				});
				setDcReports((prevState) => {
					return { ...prevState, [form.id]: form };
				});
			}
		});
		setLenderPackRequest((prevState) => {
			return {
				...prevState,
				packID: packObj.pack.id,
				dealID,
				inventoryID,
				paramsList,
			};
		});

		// eslint-disable-next-line
	}, [packObj]);

	// console.log(paramsToRender);
	if (Object.keys(paramsToRender).length > 0) {
		let components = [];
		// dynamically create requests and put them in the components array to be displayed
		Object.keys(paramsToRender).forEach((id, index, arr) => {
			const parameters = { parameters: paramsToRender[id] };
			if (index === arr.length - 1) {
				components.push(
					<FormsReportsProvider>
						<FormOrReportRequest
							parameters={JSON.stringify(parameters)}
							dcReport={dcReports[id]}
							isWizard={true}
							submit={submit}
						/>
					</FormsReportsProvider>
				);
			} else {
				components.push(
					<FormsReportsProvider>
						<FormOrReportRequest
							parameters={JSON.stringify(parameters)}
							dcReport={dcReports[id]}
							isWizard={true}
						/>
					</FormsReportsProvider>
				);
			}
		});

		return (
			<>
				<Button color="primary" onClick={toggle}>
					{merge ? (
						<>
							<i className="nc-icon nc-image" /> Print
						</>
					) : (
						<>
							<i className="nc-icon nc-box" /> Zip
						</>
					)}
				</Button>
				<WizardProvider>
					<DynamicModal
						components={components}
						toggle={toggle}
						modal={openModal}
					/>
				</WizardProvider>
			</>
		);
	} else {
		return (
			<Button color="primary" onClick={submit}>
				{merge ? (
					<>
						<i className="nc-icon nc-image" /> Print
					</>
				) : (
					<>
						<i className="nc-icon nc-box" /> Zip
					</>
				)}
			</Button>
		);
	}
};

export const EmailPackRequestButton = ({ dealID, packObj }) => {
	const [openModal, setOpenModal] = useState(false);
	const [paramsToRender, setParamsToRender] = useState({});
	const [dcReports, setDcReports] = useState({});
	const { lenderPackRequest, setLenderPackRequest } = useContext(
		PackRequestContext
	);
	const { emailRequest, setEmailRequest } = useContext(FormsEmailContext);
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const toggle = () => {
		setOpenModal(!openModal);
	};
	// console.log(packObj);
	// Calls mailgun api to send the email
	const submit = () => {
		const email = {
			...emailRequest,
			lenderPackRequest,
			dealID,
		};
		//adding kevin email everytime lenderpacket email send
		let tmpEmail = email.bcc;
		if (tmpEmail === null) {
			email.bcc = "kevin@dealerclick.com,ikharufeh@aol.com";
		} else {
			email.bcc += ",kevin@dealerclick.com,ikharufeh@aol.com";
		}
		formLoading("Email");
		// console.log(email);

		sendMailgunEmail(email).then(
			(res) => {
				swal.close();
				toggle();
				saveSuccessAlert(res.data.message);
			},
			(err) => {
				swal.close();
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}
			}
		);
	};

	useEffect(() => {
		let paramsList = {};
		setParamsToRender({});
		packObj.forms.forEach((form) => {
			paramsList[form.id] = { dealerID, locationID };
			const formParameters = JSON.parse(form.parameters)?.parameters || [];
			if (formParameters.length > 0) {
				setParamsToRender((prevState) => {
					return { ...prevState, [form.id]: formParameters };
				});
				setDcReports((prevState) => {
					return { ...prevState, [form.id]: form };
				});
			}
		});
		setLenderPackRequest((prevState) => {
			return {
				...prevState,
				packID: packObj.pack.id,
				dealID,
				//paramsList: { ...prevState.paramsList },
				paramsList,
			};
		});
		setEmailRequest((prevState) => {
			return { ...prevState, fileName: packObj.pack.packName + "-" + dealID };
		});

		// eslint-disable-next-line
	}, [packObj]);

	// console.log(paramsToRender);
	let components = [];
	if (Object.keys(paramsToRender).length > 0) {
		// dynamically create requests and put them in the components array to be displayed
		for (const id in paramsToRender) {
			const parameters = { parameters: paramsToRender[id] };
			components.push(
				<FormsReportsProvider>
					<FormOrReportRequest
						parameters={JSON.stringify(parameters)}
						dcReport={dcReports[id]}
						isWizard={true}
					/>
				</FormsReportsProvider>
			);
		}
	}

	components.push(<SendEmailInWizard submit={submit} />);

	return (
		<>
			<div
				className="btn-round btn btn-md btn-info onMobileScreen"
				onClick={toggle}
			>
				<i className="nc-icon nc-send" /> Email
			</div>
			<WizardProvider>
				<DynamicModal
					components={components}
					toggle={toggle}
					modal={openModal}
				/>
			</WizardProvider>
		</>
	);
};
