import React from "react";
import {
	Form,
	FormGroup,
	Label,
	Row,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Col,
} from "reactstrap";

import useCurrentSaleStore from "store/SaleStore";
import useCurrentDealStore from "store/DealStore";
import useCurrentRecapStore from "store/RecapStore";
import Select from "react-select";
import InputElement, {
	IntegerInputElement,
	CurrencyInputElement,
} from "view/formValidation/InputElement";
import { useForm, FormProvider } from "react-hook-form";
import { DealStatus } from "Constants";
import shallow from "zustand/shallow";

const GAPForm = () => {
	// Sale Store
	const { gap, gapTerm, editGapTerm, editGap } = useCurrentSaleStore(
		(state) => ({
			gap: state.gap,
			gapTerm: state.gapTerm,
			editGapTerm: state.editGapTerm,
			editGap: state.editGap,
		}),
		shallow
	);
	// Deal Store
	const {
		insGapName,
		insGapRecNum,
		type,
		editInsGapName,
		editInsGapRecNum,
		gapCompanies,
	} = useCurrentDealStore(
		(state) => ({
			insGapName: state.deal.insGapName,
			insGapRecNum: state.deal.insGapRecNum,
			type: state.deal.type,
			editInsGapName: state.editInsGapName,
			editInsGapRecNum: state.editInsGapRecNum,
			gapCompanies: state.gapCompanies,
		}),
		shallow
	);
	const { gapInsCost, editGapInsCost } = useCurrentRecapStore((state) => ({
		gapInsCost: state.gapInsCost,
		editGapInsCost: state.editGapInsCost,
	}));

	const gapComList = gapCompanies.map((obj) => {
		return {
			label: obj.name,
			value: obj.ID,
			gapCo: obj,
		};
	});

	// Define form validation parameters
	const methods = useForm();

	return (
		<Card>
			<CardHeader>
				<CardTitle tag="h3">Gap Insurance</CardTitle>
			</CardHeader>
			<CardBody>
				<FormProvider {...methods}>
					<Form>
						<Col>
							<Row>
								<FormGroup className={"col"}>
									<Label>Company</Label>
									<Select
										isDisabled={type !== DealStatus.PENDING}
										value={{
											value: insGapRecNum,
											label: gapComList.filter(
												(e) => e.value === insGapRecNum
											)[0]?.label,
										}}
										options={gapComList}
										onChange={(e) => {
											editInsGapRecNum(e.value);
										}}
									/>
								</FormGroup>
							</Row>
							<Row>
								<InputElement
									{...methods}
									readOnly={type !== DealStatus.PENDING}
									value={insGapName}
									onChange={(e) => {
										editInsGapName(e.target.value);
									}}
									name="insGapName"
									label="Gap Name"
									type="text"
									wholeRow={true}
								/>
							</Row>
							<Row>
								<IntegerInputElement
									readOnly={type !== DealStatus.PENDING}
									value={gapTerm}
									onChange={editGapTerm}
									name="gapTerm"
									label="Term in Months"
									type="number"
									wholeRow={true}
								/>
							</Row>
							<Row>
								<CurrencyInputElement
									readOnly={type !== DealStatus.PENDING}
									value={gapInsCost}
									onChange={editGapInsCost}
									name="gapInsCost"
									label="Cost"
									type="number"
									wholeRow={true}
								/>
							</Row>
							<Row>
								<CurrencyInputElement
									readOnly={type !== DealStatus.PENDING}
									value={gap}
									onChange={editGap}
									name="gap"
									label="Amount"
									type="number"
									wholeRow={true}
								/>
							</Row>
						</Col>
					</Form>
				</FormProvider>
			</CardBody>
		</Card>
	);
};

export default GAPForm;
