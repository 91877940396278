import React, { useContext, useEffect } from "react";
import { NavLink, NavItem, TabContent, TabPane, Nav, Button } from "reactstrap";
import classnames from "classnames";
import ChildrenModal from "../modal/ChildrenModal";
import { WizardContext } from "./WizardContext";
import { useFormContext } from "react-hook-form";

const TabItem = ({ tabName, onClick, setActive }) => {
	return (
		<NavItem>
			<NavLink
				data-toggle="tab"
				role="tablist"
				className={setActive}
				onClick={onClick}
			>
				{tabName}
			</NavLink>
		</NavItem>
	);
};

const DynamicModal = ({ components = [], toggle, modal }) => {
	const { activeTab, switchTab, setLength } = useContext(WizardContext);
	// All the nav tabs we need
	const navTabs = components.map((tab, index) => (
		<TabItem
			id={index}
			key={index}
			tabName={index + 1}
			setActive={classnames({ active: activeTab === index })}
			onClick={() => {
				if (index < activeTab) {
					switchTab(index);
				}
			}}
		/>
	));

	// Content for each tab
	const navTabContents = components.map((component, index) => (
		<TabPane id={index} key={index} tabId={index}>
			{component}
		</TabPane>
	));

	useEffect(() => {
		setLength(navTabContents.length);
		// eslint-disable-next-line
	}, [components]);

	return (
		<ChildrenModal
			toggle={toggle}
			size="lg"
			modal={modal}
			onClosed={() => {
				switchTab(0);
			}}
		>
			<div className="w-100">
				<Nav
					className="nav-pills nav nav-pills-primary justify-content-center"
					role="tablist"
				>
					{navTabs}
				</Nav>
				<TabContent activeTab={activeTab}>{navTabContents}</TabContent>
			</div>
		</ChildrenModal>
	);
};

export default DynamicModal;

export const WizardButtons = ({ submit }) => {
	const { activeTab, previous, next, length } = useContext(WizardContext);
	const methods = useFormContext();
	return (
		<>
			<Button readOnly={activeTab === 0} onClick={previous}>
				Previous
			</Button>
			{activeTab !== length - 1 && (
				<Button onClick={methods.handleSubmit(next)}>Next</Button>
			)}
			{activeTab === length - 1 && (
				<Button onClick={methods.handleSubmit(submit)}>Submit</Button>
			)}
		</>
	);
};
