/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)

* Coded by DealerClick Software **RoarinLion))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import App from "App";

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
