import useCurrentLoginStore from "store/LoginStore";

import shallow from "zustand/shallow";

export const useBuildLoginInfo = () => {
	const {
		ID,
		firstName,
		lastName,
		email,
		dealerLoginID,
		companyName,
		middleName,
		title,
		commissionPercent,
		commissionFixedAmount,
		commissionType,
		commissionMinimum,
		personnelType,
		driversLicense,
		cellPhone,
		phone,
		salesLicense,
		street,
		city,
		zip,
		state,
		active,
		activeDate,
		inactiveDate,
		lastLoginDate,
		profilePicture,
		username,
		userPrivileges,
		messagingNumber,
		locationAddress,
		locationUnit,
		locationCity,
		locationCountry,
		locationDateInactive,
		locationDealerNum,
		locationExpirationDate,
		locationFax,
		locationPhone,
		locationState,
		locationUpdatedBy,
		locationUpdatedDate,
		locationZipCode,
		locationID,
		locationCellphone,
		locationFirstName,
		locationLastName,
		locationEmail,
		locationCloudUserCount,
		dealerID,
		dealerCompanyName,
		dealerSignUpDate,
		dealerActive,
		dealerUpdatedBy,
		dealerUpdatedDate,
		dealerResellerLoginID,
		dealerWebsite,
		dealerDateInactive,
		dealerLogoUrl,
		dealerDeactiveReason,
		dealerDealerZip,
		dealerDealerType,
		dealerBillable,
		dealerCcOnFile,
		dealerAutomatedBilling,
		dealerDealerToDealerOnly,
	} = useCurrentLoginStore(
		(state) => ({
			ID: state.ID,
			firstName: state.firstName,
			lastName: state.lastName,
			email: state.email,
			dealerLoginID: state.dealerLoginID,
			companyName: state.companyName,
			middleName: state.middleName,
			title: state.title,
			commissionPercent: state.commissionPercent,
			commissionFixedAmount: state.commissionFixedAmount,
			commissionType: state.commissionType,
			commissionMinimum: state.commissionMinimum,
			personnelType: state.personnelType,
			driversLicense: state.driversLicense,
			cellPhone: state.cellPhone,
			phone: state.phone,
			salesLicense: state.salesLicense,
			street: state.street,
			city: state.city,
			zip: state.zip,
			state: state.state,
			active: state.active,
			activeDate: state.activeDate,
			inactiveDate: state.inactiveDate,
			lastLoginDate: state.lastLoginDate,
			profilePicture: state.profilePicture,
			username: state.username,
			userPrivileges: state.userPrivileges,
			messagingNumber: state.messagingNumber,
			locationAddress: state.locationAddress,
			locationUnit: state.locationUnit,
			locationCity: state.locationCity,
			locationCountry: state.locationCountry,
			locationDateInactive: state.locationDateInactive,
			locationDealerNum: state.locationDealerNum,
			locationExpirationDate: state.locationExpirationDate,
			locationFax: state.locationFax,
			locationPhone: state.locationPhone,
			locationState: state.locationState,
			locationUpdatedBy: state.locationUpdatedBy,
			locationUpdatedDate: state.locationUpdatedDate,
			locationZipCode: state.locationZipCode,
			locationID: state.locationID,
			locationCellphone: state.locationCellphone,
			locationFirstName: state.locationFirstName,
			locationLastName: state.locationLastName,
			locationEmail: state.locationEmail,
			locationCloudUserCount: state.locationCloudUserCount,
			dealerID: state.dealerID,
			dealerCompanyName: state.dealerCompanyName,
			dealerSignUpDate: state.dealerSignUpDate,
			dealerActive: state.dealerActive,
			dealerUpdatedBy: state.dealerUpdatedBy,
			dealerUpdatedDate: state.dealerUpdatedDate,
			dealerResellerLoginID: state.dealerResellerLoginID,
			dealerWebsite: state.dealerWebsite,
			dealerDateInactive: state.dealerDateInactive,
			dealerLogoUrl: state.dealerLogoUrl,
			dealerDeactiveReason: state.dealerDeactiveReason,
			dealerDealerZip: state.dealerDealerZip,
			dealerDealerType: state.dealerDealerType,
			dealerBillable: state.dealerBillable,
			dealerCcOnFile: state.dealerCcOnFile,
			dealerAutomatedBilling: state.dealerAutomatedBilling,
			dealerDealerToDealerOnly: state.dealerDealerToDealerOnly,
		}),
		shallow
	);

	const loginInfo = {
		dcLogin: {
			ID,
			dealerID,
			locationID,
			firstName,
			lastName,
			email,
			dealerLoginID,
			companyName,
			middleName,
			title,
			commissionPercent,
			commissionFixedAmount,
			commissionType,
			commissionMinimum,
			personnelType,
			driversLicense,
			cellPhone,
			phone,
			salesLicense,
			street,
			city,
			zip,
			state,
			active,
			activeDate,
			inactiveDate,
			lastLoginDate,
			username,
			userPrivileges: JSON.stringify(userPrivileges) || "",
		},
		locations: {
			ID: locationID,
			address: locationAddress,
			unit: locationUnit,
			city: locationCity,
			country: locationCountry,
			dateInactive: locationDateInactive,
			dealerNum: locationDealerNum,
			expirationDate: locationExpirationDate,
			fax: locationFax,
			phone: locationPhone,
			state: locationState,
			updatedBy: locationUpdatedBy,
			updatedDate: locationUpdatedDate,
			zipCode: locationZipCode,
			cellphone: locationCellphone,
			firstName: locationFirstName,
			lastName: locationLastName,
			email: locationEmail,
			cloudUserCount: locationCloudUserCount,
		},
		dealers: {
			ID: dealerID,
			companyName: dealerCompanyName,
			signUpDate: dealerSignUpDate,
			active: dealerActive,
			updatedBy: dealerUpdatedBy,
			updatedDate: dealerUpdatedDate,
			resellerLoginID: dealerResellerLoginID,
			website: dealerWebsite,
			dateInactive: dealerDateInactive,
			logoUrl: dealerLogoUrl,
			deactiveReason: dealerDeactiveReason,
			dealerZip: dealerDealerZip,
			dealerType: dealerDealerType,
			billable: dealerBillable,
			ccOnFile: dealerCcOnFile,
			automatedBilling: dealerAutomatedBilling,
			dealerToDealerOnly: dealerDealerToDealerOnly,
		},
		profileData: profilePicture,
		messagingNumber,
	};

	const buildLoginInfo = () => {
		return loginInfo;
	};

	return buildLoginInfo;
};

export default useBuildLoginInfo;
