/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)

* Coded by DealerClick Software **RoarinLion))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";

import ReactTable from "./ReactTable";

import NumberFormat from "react-number-format";

const ContactTable = ({ data, setMessageCustomers }) => {
	const phoneCell = (props) => (
		<div>
			<span style={{ fontWeight: "bold" }}>
				{props.row.original.homePhone != null && (
					<a href={"tel:" + props.row.original.homePhone}>
						{" "}
						<NumberFormat
							value={props.row.original.homePhone}
							format="+1 (###) ###-####"
							isNumericString={true}
							displayType="text"
						/>
					</a>
				)}
			</span>
		</div>
	);

	const emailCell = (props) => (
		<div>
			<span style={{ fontWeight: "bold" }}>
				<a href={"mailto:" + props.row.original.email}>
					{props.row.original.email}
				</a>
			</span>
		</div>
	);
	const nameCell = (props) => (
		<div>
			<span style={{ fontWeight: "bold" }}>{props.row.original.name}</span>
		</div>
	);
	const licenseCell = (props) => (
		<div>
			<span style={{ fontWeight: "bold" }}>{props.row.original.license}</span>
		</div>
	);

	return (
		<Row>
			<Col md="12">
				<Card>
					<CardBody>
						<ReactTable
							checkboxColumn={setMessageCustomers != null}
							setSelectedRows={setMessageCustomers}
							data={data}
							columns={[
								{
									Header: "Name",
									accessor: "name",
									className: "customer-name",
									Cell: nameCell,
								},
								{
									Header: "Phone",
									accessor: "homePhone",
									Cell: phoneCell,
									className: "customer-phone",
								},
								{
									Header: "Email",
									accessor: "email",
									Cell: emailCell,
									className: "customer-email",
								},
								{
									Header: "License",
									accessor: "license",
									className: "customer-license",
									Cell: licenseCell,
								},
								{
									Header: "Actions",
									accessor: "Actions",
									disableFilters: true,
								},
							]}
							/*
                      You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                    */
							className="-striped -highlight primary-pagination"
						/>
					</CardBody>
				</Card>
			</Col>
		</Row>
	);
};

export default ContactTable;
