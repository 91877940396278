// import React from "react";
import useCurrentSaleStore from "../../store/SaleStore";
import useCurrentDealStore from "../../store/DealStore";
import useCurrentRecapStore from "../../store/RecapStore";
import { useFromUser, useUser } from "../../store/LocalStorageHelper";
import shallow from "zustand/shallow";
import { version } from "../../version";

export const useBuildDeal = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const { dealers, locations } = useUser();
	const {
		price,
		dealerSmog,
		docFee,
		gap,
		warranty,
		aftmktNonTaxable,
		aftmkt,
		trade,
		payoff,
		down,
		saleType,
		servContractTaxRate,
		stateSmog,
		warrantyTax,
		userTax1,
		userTax2,
		userTax3,
		userTax4,
		taxType,
		tax,
		loanDate,
		firstDueDate,
		finalDueDate,
		loanPeriod,
		term,
		apr,
		payment,
		finalPmt,
		amtFinanced,
		financeCharge,
		loan,
		license,
		licenseFee,
		licensePct,
		registration,
		registrationFee,
		registrationPct,
		titleFee,
		filingFee,
		smogExemption,
		weightFee,
		tireFee,
		bpa,
		totalDMVFees,
		totalSellerFees,
		totalNetTrade,
		manufacturerRebate,
		totalPickupPmts,
		gapTerm,
		aftmktCost,
		fiDiscount,
		dealNumber,
		taxableAmount,
		salesTax,
		totalTaxable,
		daysToPay,
		taxRate,
		bankFee,
		discount,
		addOn,
		rollPreValue,
		rollBack,
		balloon,
		balloonTerm,
		balloonDate,
		hasBalloon,
		onePay,
		collisionTotPremium,
		vit,
		vitRate,
		creditLifeFlag,
		disabilityFlag,
		lifePremium,
		disabilityPremium,
		fixedAmountTax,
		tradeTaxCredit,
	} = useCurrentSaleStore((state) => state, shallow);
	const {
		buyer,
		coBuyer,
		vehicle,
		county,
		miscSellerFees,
		sellerFeeTypes,
		miscDMVFees,
		dmvFeeTypes,
		aftermarket,
		warranty: warrantyList,
		pickupPmts,
		commissions,
		trades,
		lenders,
		lender,
		salesPeople,
		buyerAddress,
		buyerMailAddress,
		coBuyerAddress,
		coBuyerMailAddress,
		buyerEmploymentHistory,
		coBuyerEmploymentHistory,
		gapCoName,
		gapCompanies,
		warrantyVendors,
		tradeVendors,
		contractInfo,
		shippingCompanies,
		downPayments,
		collisionInsurance,
		credit700Lenders,
		vehicleRecon,
	} = useCurrentDealStore((state) => state, shallow);

	const {
		ID,
		buyerRecNum,
		coBuyerRecNum,
		saleRecNum,
		recapRecNum,
		carRecNum,
		lenderRecNum,
		insGapRecNum,
		insCreditLifeRecNum,
		leaseRecNum,
		salesmanRecNum,
		type,
		createdOn,
		soldDate,
		countyRecNum,
		dealSettings,
		isTemplate,
		notes,
		insGapName,
		deliveredDate,
		createdById,
		createdBy,
		modifiedBy,
		modifiedById,
		modifiedOn,
		titleDate,
		repoDate,
		fundedDate,
		isFunded,
		shippingRecNum,
	} = useCurrentDealStore((state) => state.deal, shallow);

	const {
		isRecapped,
		recapDate,
		carProfit,
		reconProfit,
		aftmktProfit,
		totalTradeAllowance,
		totalTradeACV,
		totalTradeProfit,
		totalTradePayoff,
		totalDown,
		VSICost,
		miscCost,
		miscProfit,
		gapInsCost,
		gapInsProfit,
		warrantyCost,
		warrantyProfit,
		interestCost,
		interestProfit,
		interestNetProfit,
		additionalProfit,
		dlrParticipation,
		reserve,
		frontendProfit,
		backendProfit,
		lenderFee,
		incentive,
		finderReward,
		fiComm,
		salesComm,
		totalComm,
		profitComm,
		grossProfit,
		netProfit,
		fiGross,
		salesGross,
		totalGross,
		commGross,
		lenderPaid,
		collisionTotCost,
		collisionTotProfit,
		creditLifeCost,
		disabCost,
		credLifeProfit,
		disabProfit,
	} = useCurrentRecapStore((state) => state, shallow);

	const dealObj = {
		deal: {
			ID,
			buyerRecNum,
			coBuyerRecNum,
			saleRecNum,
			recapRecNum,
			carRecNum,
			lenderRecNum: isNaN(lenderRecNum) ? null : lenderRecNum,
			insGapRecNum,
			insCreditLifeRecNum,
			leaseRecNum,
			salesmanRecNum: isNaN(salesmanRecNum) ? null : salesmanRecNum,
			shippingRecNum: isNaN(shippingRecNum) ? null : shippingRecNum,
			type,
			createdOn,
			soldDate,
			countyRecNum,
			dealSettings: JSON.stringify(dealSettings),
			isTemplate,
			insGapName,
			notes: notes?.length > 0 ? JSON.stringify(notes) : "[]",
			deliveredDate,
			createdById,
			createdBy,
			modifiedBy,
			modifiedById,
			modifiedOn,
			titleDate,
			repoDate,
			fundedDate,
			isFunded,
			dcversion: "v" + version,
			sale: {
				ID: saleRecNum,
				price: parseFloat(price),
				dealerSmog: parseFloat(dealerSmog),
				docFee: parseFloat(docFee),
				gap: parseFloat(gap),
				warranty: parseFloat(warranty),
				aftmktNonTaxable: parseFloat(aftmktNonTaxable),
				aftmkt: parseFloat(aftmkt),
				trade: parseFloat(trade),
				payoff: parseFloat(payoff),
				down: parseFloat(down),
				saleType,
				servContractTaxRate: parseFloat(servContractTaxRate),
				stateSmog: parseFloat(stateSmog),
				warrantyTax: parseFloat(warrantyTax),
				userTax1: parseFloat(userTax1),
				userTax2: parseFloat(userTax2),
				userTax3: parseFloat(userTax3),
				userTax4: parseFloat(userTax4),
				taxType,
				tax: parseFloat(tax),
				vit: parseFloat(vit),
				vitRate: parseFloat(vitRate),
				loanDate,
				firstDueDate,
				finalDueDate,
				loanPeriod,
				term,
				apr: parseFloat(apr),
				payment: parseFloat(payment),
				finalPmt: parseFloat(finalPmt),
				amtFinanced: parseFloat(amtFinanced),
				financeCharge: parseFloat(financeCharge),
				loan: parseFloat(loan),
				license: parseFloat(license),
				licenseFee: parseFloat(licenseFee),
				licensePct: parseFloat(licensePct),
				registration: parseFloat(registration),
				registrationFee: parseFloat(registrationFee),
				registrationPct: parseFloat(registrationPct),
				titleFee: parseFloat(titleFee),
				filingFee: parseFloat(filingFee),
				smogExemption: parseFloat(smogExemption),
				weightFee: parseFloat(weightFee),
				tireFee: parseFloat(tireFee),
				bpa: parseFloat(bpa),
				totalDMVFees: parseFloat(totalDMVFees),
				rebate: parseFloat(manufacturerRebate),
				totalSellerFees: parseFloat(totalSellerFees),
				totalNetTrade: parseFloat(totalNetTrade),
				totalPickupPmts: parseFloat(totalPickupPmts),
				gapTerm,
				aftmktCost: parseFloat(aftmktCost),
				fiDiscount: parseFloat(fiDiscount),
				dealNumber,
				taxableAmount: parseFloat(taxableAmount),
				salesTax: parseFloat(salesTax),
				taxRate: parseFloat(taxRate),
				totalTaxable: parseFloat(totalTaxable),
				daysToPay,

				dealerID,
				locationID,
				bankFee: parseFloat(bankFee),
				discount,
				addOn: parseFloat(addOn),
				rollPreValue,
				rollBack,
				balloon,
				balloonTerm,
				balloonDate,
				hasBalloon,
				onePay: parseFloat(onePay),
				collisionTotPremium,
				creditLifeFlag,
				disabilityFlag,
				lifePremium: isNaN(lifePremium) ? 0 : parseFloat(lifePremium),
				disabilityPremium: isNaN(disabilityPremium)
					? 0
					: parseFloat(disabilityPremium),
				fixedAmountTax: parseFloat(fixedAmountTax),
				tradeTaxCredit: parseFloat(tradeTaxCredit),
			},
			recap: {
				ID: recapRecNum,
				isRecapped,
				recapDate,
				carProfit: parseFloat(carProfit),
				reconProfit: parseFloat(reconProfit),
				aftmktProfit: parseFloat(aftmktProfit),
				totalTradeAllowance: parseFloat(totalTradeAllowance),
				totalTradeACV: parseFloat(totalTradeACV),
				totalTradeProfit: parseFloat(totalTradeProfit),
				totalTradePayoff: parseFloat(totalTradePayoff),
				totalDown: parseFloat(totalDown),
				VSICost: parseFloat(VSICost),
				miscCost: parseFloat(miscCost),
				miscProfit: parseFloat(miscProfit),
				gapInsCost: parseFloat(gapInsCost),
				gapInsProfit: parseFloat(gapInsProfit),
				warrantyCost: parseFloat(warrantyCost),
				warrantyProfit: parseFloat(warrantyProfit),
				interestCost: parseFloat(interestCost),
				interestProfit: parseFloat(interestProfit),
				interestNetProfit: parseFloat(interestNetProfit),
				additionalProfit: parseFloat(additionalProfit),
				dlrParticipation,
				reserve: parseFloat(reserve),
				frontendProfit: parseFloat(frontendProfit),
				backendProfit: parseFloat(backendProfit),
				lenderFee: parseFloat(lenderFee),
				incentive: parseFloat(incentive),
				finderReward: parseFloat(finderReward),
				fiComm: parseFloat(fiComm),
				salesComm: parseFloat(salesComm),
				totalComm: parseFloat(totalComm),
				profitComm: parseFloat(profitComm),
				grossProfit: parseFloat(grossProfit),
				netProfit: parseFloat(netProfit),
				fiGross: parseFloat(fiGross),
				salesGross: parseFloat(salesGross),
				totalGross: parseFloat(totalGross),
				commGross: parseFloat(commGross),
				creditLifeCost: parseFloat(creditLifeCost),
				disabCost: parseFloat(disabCost),
				credLifeProfit: parseFloat(credLifeProfit),
				disabProfit: parseFloat(disabProfit),

				lenderPaid,
				collisionTotCost,
				collisionTotProfit,

				dealerID,
				locationID,
			},
			dealerID,
			locationID,
		},
		buyer,
		coBuyer,
		vehicle,
		county,
		miscSellerFees,
		miscDMVFees,
		sellerFeeTypes,
		dmvFeeTypes,
		aftermarket,
		collisionInsurance,
		warranty: warrantyList,
		pickupPmts,
		commissions,
		trades,
		lender,
		lenders,
		salesPeople,
		buyerAddress,
		buyerMailAddress,
		coBuyerAddress,
		coBuyerMailAddress,
		buyerEmploymentHistory,
		coBuyerEmploymentHistory,
		gapCoName,
		contractInfo,
		gapCompanies,
		warrantyVendors,
		tradeVendors,
		shippingCompanies,
		downPayments,
		credit700Lenders,
		vehicleRecon,
		dealer: dealers,
		location: locations,
	};
	//return dealObj;
	const getDeal = () => {
		return dealObj;
	};

	return getDeal;
};

export default useBuildDeal;
