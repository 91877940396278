import React, { useState, useEffect } from "react";
import { Col, TabContent, TabPane, Nav } from "reactstrap";
import Commission from "../../component/dc/deal/commission/Commission";
import LenderIntegrations from "../../component/dc/integration/LenderIntegrations";
import Forms from "../../component/dc/formsReports/Forms";
import useBuildDeal from "../../helperFunction/deal/useBuildDeal";
import FairFaxTab from "../../component/dc/deal/FairFaxTab";
import DealSettings from "../../component/dc/deal/dealSettings/DealSettings";
import useIsOwnerOrAdmin from "../settings/settingsTabs/userManagement/useIsOwnerOrAdmin";
import UnauthorizedContent from "../../component/dc/access/UnauthorizedContent";
import TradeTab from "../../component/dc/deal/trade/TradeTab";
import FormsModal from "../../component/dc/formsReports/FormsModal";
import DealActionBar from "./DealActionBar";
import { DealContent } from "./DealContent";
import ShippingForm from "../../component/dc/deal/shipping/ShippingForm";
import TemplateVariableInit from "../../helperFunction/TemplateVariableInit";
import { TemplateVariableProvider } from "../../helperFunction/TemplateVariableContext";
import classnames from "classnames";
import ChildrenModal from "../../component/dc/modal/ChildrenModal";
import NavTab from "../../component/dc/tab/NavTab";
import { saveDeal } from "../../api/DealAPI";
import useSetDeal from "../../helperFunction/deal/useSetDeal";
import {
	saveFailAlert,
	saveSuccessAlert,
} from "../../component/dc/modal/SweetAlerts";
import { showApiError } from "../../helperFunction/ApiErrorHandler";
import useCurrentDealStore from "store/DealStore";
import shallow from "zustand/shallow";
import { DealStatus } from "../../Constants";
import DealTitle from "./DealTitle";

const IN_DEVELOPMENT = process.env.NODE_ENV === "development";

const DealTabs = () => {
	const getDeal = useBuildDeal();
	const isOwnerOrAdmin = useIsOwnerOrAdmin();
	// // this was getting trade stuff before
	const [openForm, setOpenForm] = useState(false);
	const toggleOpenForm = () => {
		setOpenForm(!openForm);
	};
	const [tabComponents, setTabComponents] = useState([]);
	const tabs = [
		"Desking",
		"Trade",
		"Lenders",
		"Forms",
		"eTags / Titling",
		"Commissions",
		"Shipping",
		"Deal Settings",
	];
	const tab = ["Desking"];

	const { buyerRecNum } = useCurrentDealStore(
		(state) => ({
			buyerRecNum: state.deal.buyerRecNum,
			//editLicense: state?.vehicle?.editLicense,
		}),
		shallow
	);

	useEffect(() => {
		console.log(buyerRecNum);
		if (isOwnerOrAdmin !== null && !isOwnerOrAdmin) {
			setTabComponents([
				<DealContent />,
				<TradeTab />,
				<LenderIntegrations />,
				<Forms />,
				<FairFaxTab />,
				<UnauthorizedContent />,
				<ShippingForm />,
				<UnauthorizedContent />,
			]);
		} else {
			setTabComponents([
				<DealContent />,
				<TradeTab />,
				<LenderIntegrations />,
				<Forms />,
				<FairFaxTab />,
				<Commission />,
				<ShippingForm />,
				<DealSettings />,
			]);
		}

		// eslint-disable-next-line
	}, [isOwnerOrAdmin, buyerRecNum]);

	return (
		<div>
			<TemplateVariableProvider>
				<TemplateVariableInit isDeal children={<DealActionBar />} />
			</TemplateVariableProvider>
			<DealTabsHolder
				className="pt-5"
				tabs={buyerRecNum === null ? tab : tabs}
				tabComponents={tabComponents}
				modalIndex={3}
				BodyComponent={<FormsModal toggle={toggleOpenForm} />}
				toggleOpen={toggleOpenForm}
				open={openForm}
			/>
			{IN_DEVELOPMENT && (
				<Col sm="1">
					<button
						style={{
							position: "fixed",
							top: "4rem",
							right: "2rem",
							zIndex: "9999",
						}}
						className="btn-sm"
						onClick={() => {
							const deal = getDeal();
							console.log(deal);
						}}
					>
						JSON
					</button>
				</Col>
			)}
		</div>
	);
};

export default DealTabs;

const DealTabsHolder = ({
	tabs,
	tabComponents,
	modalIndex,
	BodyComponent,
	toggleOpen,
	open,
}) => {
	const [activeTab, setActiveTab] = useState(0);

	// Toggles between different tabs
	const toggle = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};
	const setDeal = useSetDeal();
	const getDeal = useBuildDeal();
	const [openModal, setOpenModal] = useState(false);

	const save = (d) => {
		saveDeal(d).then(
			(res) => {
				setDeal(res);
				saveSuccessAlert();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	// All the nav tabs we need
	const navTabs = tabs.map((tab, index) => (
		<NavTab
			id={index}
			key={index}
			tabName={tab}
			setActive={classnames({ active: activeTab === index })}
			onClick={() => {
				if (index === modalIndex) {
					toggleOpen();
					setOpenModal(true);
					toggle(index);
				} else {
					toggle(index);
					const deal = getDeal();
					if (deal.deal.type === DealStatus.PENDING) {
						save(deal);
					}
				}
			}}
		/>
	));

	// Content for each tab
	const navTabContents = tabComponents.map((component, index) => (
		<TabPane id={index} key={index} tabId={index}>
			{component}
		</TabPane>
	));
	return (
		<>
			<div>
				{" "}
				{openModal ? (
					<ChildrenModal
						toggle={toggleOpen}
						modal={open}
						children={BodyComponent}
						size="m"
						hideX={true}
					/>
				) : null}
			</div>
			<DealTitle />
			<div className="w-100">
				<Nav
					className="nav-pills nav nav-pills-primary justify-content-center"
					role="tablist"
				>
					{navTabs}
				</Nav>
				<TabContent activeTab={activeTab}>{navTabContents}</TabContent>
			</div>
		</>
	);
};
