import React from "react";

import { CardFooter, UncontrolledTooltip, Row } from "reactstrap";

const CardTooltip = ({ iconId, header, content, footerContent }) => {
	return (
		<CardFooter className="text-center">
			<Row style={{ marginLeft: "0", marginRight: "0" }}>
				<p
					className="col-md-6"
					style={{
						textDecoration: "underline",
						color: "gray",
						textAlign: "left",
					}}
				/>
				<p className="col" style={{ textAlign: "right" }}>
					<i id={iconId} className="nc-icon nc-alert-circle-i text-success" />
				</p>
				<UncontrolledTooltip
					placement="top"
					target={iconId}
					delay={0}
					trigger="hover"
				>
					<div style={{ minHeight: "200px", minwidth: "400px" }}>
						<br />
						<h6>{header}</h6>
						<br />
						<p>{content}</p>
						<br />
						<p>{footerContent}</p>
					</div>
				</UncontrolledTooltip>
			</Row>
		</CardFooter>
	);
};

export default CardTooltip;
