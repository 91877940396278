import React, { useState } from "react";
import { Input, Row } from "reactstrap";
import ServiceContractModal from "../modal/service/ServiceContractModal";
import OpenTotalModal from "../OpenTotalModal";
import useCurrentSaleStore from "store/SaleStore";
import NumberFormat from "react-number-format";
import shallow from "zustand/shallow";

const ServiceContract = () => {
	const [openWarrantyModal, setOpenWarrantyModal] = useState(false);

	const { warranty } = useCurrentSaleStore(
		(state) => ({
			warranty: state.warranty,
		}),
		shallow
	);

	// Function to open the modal
	const toggle = () => {
		setOpenWarrantyModal(!openWarrantyModal);
	};

	// Returns the total field in the sale page with the modal to open up the fields to edit the total value
	return (
		<Row>
			{openWarrantyModal ? (
				<ServiceContractModal modal={openWarrantyModal} toggle={toggle} />
			) : null}
			<div className="col-sm-6 px-0">
				<OpenTotalModal
					btnClass="w-100 btn btn-primary btn-md px-0"
					label="Warranty"
					toggle={toggle}
				/>
			</div>
			<div className="col-sm-6 pr-0 dealInputs moneyIn pl-2">
				<NumberFormat
					value={warranty}
					thousandSeparator={true}
					decimalScale={2}
					fixedDecimalScale={true}
					prefix={"$"}
					isNumericString={true}
					readOnly={true}
					customInput={Input}
					className="bold"
				/>
			</div>
		</Row>
	);
};

export default ServiceContract;
