import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

import NumberFormat from "react-number-format";

const DigitzsTransactionNumberField = ({ label, value, prefix, suffix }) => {
	return (
		<FormGroup className="col-lg-3">
			<Label>{label}</Label>
			<NumberFormat
				className="form-control  bold"
				readOnly={true}
				value={value}
				thousandSeparator={true}
				fixedDecimalScale={true}
				decimalScale={2}
				displayType="text"
				prefix={prefix}
				suffix={suffix}
				customInput={Input}
			/>
		</FormGroup>
	);
};

export default DigitzsTransactionNumberField;
