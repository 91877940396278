import React, { useState } from "react";
import { Row, Input } from "reactstrap";
import useCurrentSaleStore from "store/SaleStore";
import NumberFormat from "react-number-format";
import OpenTotalModal from "../OpenTotalModal";
import TaxModal from "../modal/TaxSummary/TaxModal";

const Taxes = () => {
	const { tax } = useCurrentSaleStore((state) => ({
		tax: state.tax,
	}));
	const [openModal, setOpenModal] = useState(false);

	const toggle = () => {
		setOpenModal(!openModal);
	};

	return (
		<div>
			<Row>
				<TaxModal modal={openModal} toggle={toggle} />
				<div className="col-sm-6 px-0">
					<OpenTotalModal
						btnClass="w-100 btn-md btn btn btn-primary btn-md px-0"
						label="Taxes"
						toggle={toggle}
					/>
				</div>
				<div className="col-sm-6 pr-0 dealInputs pl-2">
					<NumberFormat
						value={tax}
						thousandSeparator={true}
						decimalScale={2}
						fixedDecimalScale={true}
						prefix={"$"}
						isNumericString={true}
						readOnly={true}
						customInput={Input}
						className="bold"
					/>
				</div>
			</Row>
		</div>
	);
};

export default Taxes;
