import React, { useState } from "react";
import { Row } from "reactstrap";
import shallow from "zustand/shallow";

import { DealStatus } from "../../../../Constants";
import useCurrentDealStore from "../../../../store/DealStore";
import DealInventoryListData from "./DealInventoryListData";
import OpenModalButton from "../OpenModalButton";
import InventoryDetailsModal from "./InventoryDetailsModal";

import ChildrenModal from "../../../../component/dc/modal/ChildrenModal";
const InventoryListModal = () => {
	const [addModal, setAddModal] = useState(false);
	const [editModal, setEditModal] = useState(false);

	const toggleAdd = () => {
		setAddModal(!addModal);
	};

	const toggleEdit = () => {
		setEditModal(!editModal);
	};

	const { type, vehicle } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
			vehicle: state.vehicle,
		}),
		shallow
	);

	return (
		<div>
			{(vehicle?.inventoryID == null && (
				<OpenModalButton
					buttonClass="btn-sm"
					buttonColor="primary"
					onClick={toggleAdd}
					modalTitle="Select Vehicle"
					buttonText="Select"
					icon={true}
					iconClass="nc-icon nc-tap-01"
				/>
			)) || (
				<Row className="old-row">
					<div className="mx-auto">
						{type === DealStatus.PENDING ? (
							<span>
								<OpenModalButton
									buttonClass="btn-sm"
									onClick={toggleAdd}
									buttonColor="primary"
									buttonText="Select"
									iconClass="nc-icon nc-tap-01"
								/>
							</span>
						) : null}
						<OpenModalButton
							buttonClass="btn-sm btn"
							onClick={toggleEdit}
							buttonColor="primary"
							buttonText={type !== DealStatus.PENDING ? "View" : "Edit"}
							iconClass={
								type !== DealStatus.PENDING
									? "nc-icon nc-tap-01"
									: "nc-icon nc-align-center"
							}
						/>
					</div>
				</Row>
			)}
			{type === DealStatus.PENDING && (
				<ChildrenModal
					modal={addModal}
					toggle={toggleAdd}
					modalTitle="Select Vehicle"
					children={<DealInventoryListData toggle={toggleAdd} />}
				/>
			)}
			<ChildrenModal
				modal={editModal}
				toggle={toggleEdit}
				modalTitle="Edit Current Vehicle"
				children={<InventoryDetailsModal isDeal={true} toggle={toggleEdit} />}
			/>
		</div>
	);
};

export default InventoryListModal;
