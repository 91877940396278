import React, { useState, useEffect } from "react";

/**
 * Draws a check mark, to be used on images that are 'selected'
 */
const CheckMark = ({ selected }) => (
	<div
		style={
			selected
				? { left: "12px", top: "12px", position: "absolute", zIndex: "1" }
				: { display: "none" }
		}
	>
		<svg
			style={{ fill: "white", position: "absolute" }}
			width="24px"
			height="24px"
		>
			<circle cx="12.5" cy="12.2" r="8.292" />
		</svg>
		<svg
			style={{ fill: "#06befa", position: "absolute" }}
			width="24px"
			height="24px"
		>
			<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
		</svg>
	</div>
);

/**
 * Animates and draws a check mark over images that are selected or removes
 * them if picture is unselected
 */
const SelectedImage = ({
	index,
	photo,
	margin,
	direction,
	top,
	left,
	selected,
	setClickedPhoto,
	slide,
}) => {
	// Local state
	const [isSelected, setIsSelected] = useState(selected);

	// Image properties
	const imgStyle = {
		transition:
			"transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s",
	};

	const selectedImgStyle = {
		transform: "translateZ(0px) scale3d(0.9, 0.9, 1)",
		transition:
			"transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s",
	};

	const cont = {
		backgroundColor: "#eee",
		cursor: "pointer",
		overflow: "hidden",
		position: "relative",
	};

	// Calculate x,y scale
	const sx = (100 - (30 / photo.width) * 100) / 100;
	const sy = (100 - (30 / photo.height) * 100) / 100;
	selectedImgStyle.transform = `translateZ(0px) scale3d(${sx || 0.95}, ${
		sy || 0.95
	}, 1)`;

	if (direction === "column") {
		cont.position = "absolute";
		cont.left = left;
		cont.top = top;
	}

	// Marks an image as selected and updates its selected index to true or false
	const handleOnClick = () => {
		setIsSelected(!isSelected);
		setClickedPhoto(index);
	};

	useEffect(() => {
		setIsSelected(selected);
	}, [selected]);

	return (
		<div
			style={{
				margin,
				width: photo.width || "350px",
				height: photo.height || "240px",
				...cont,
			}}
			className={!isSelected ? "not-selected" : ""}
		>
			<CheckMark selected={isSelected ? true : false} />
			<img
				width={photo.width || "350px"}
				height={photo.height || "240px"}
				alt={photo.alt}
				style={
					isSelected ? { ...imgStyle, ...selectedImgStyle } : { ...imgStyle }
				}
				src={photo.src}
				srcSet={photo.srcSet}
				onClick={handleOnClick}
				onDoubleClick={() => slide(index)}
			/>
			<style>{`.not-selected:hover{outline:3px solid #59c2ff}`}</style>
		</div>
	);
};

export default SelectedImage;
