import React, { useState } from "react";
import { Button } from "reactstrap";

import Attachment from "./Attachment";
import ChildrenModal from "../modal/ChildrenModal";

/**
 * The main component to be used in places where we want users to be able to use
 * attachments. The button opens up the main modal containing all the attachment
 * functionality.
 *
 * @param {String} type Required. Must be either 'deal', 'inventory', or 'customer
 * @param {number} relatedID Required. Deal, Inventory, or Customer ID
 */
const AttachmentModal = ({ type, relatedID }) => {
	const [attachmentModal, setAttachmentModal] = useState(false);

	const toggleAttachmentModal = () => setAttachmentModal(!attachmentModal);

	return (
		<>
			<Button onClick={toggleAttachmentModal}>Attachments</Button>
			<ChildrenModal
				modalTitle="Attachments"
				modal={attachmentModal}
				toggle={toggleAttachmentModal}
				size="lg"
			>
				<Attachment relatedID={relatedID} type={type} />
			</ChildrenModal>
		</>
	);
};

export default AttachmentModal;
