/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 DealerClick (https://dealerclick.com)

* Coded by DealerClick Software ))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { Card, Row, Col, CardTitle } from "reactstrap";

import CardContent from "../card/CardContent";
import CardContentBottom from "../card/CardContentBottom";

import { getInventoryStatusList } from "api/DealAPI";
import { retrieveFailAlert } from "component/dc/modal/SweetAlerts";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";
import { useFromUser } from "store/LocalStorageHelper";
import { NavLink } from "react-router-dom";

const InventoryHeader = ({ statusList, setStatusList }) => {
	const [totalInv, setTotalInv] = useState([]);
	const locationID = useFromUser("locationID");

	const [available, setAvailable] = useState(0);
	const [pending, setPending] = useState(0);
	const [sold, setSold] = useState(0);
	const [soldNoDeal, setSoldNoDeal] = useState(0);
	const [deleted, setDeleted] = useState(0);
	const [trade, setTrade] = useState(0);
	const [hold, setHold] = useState(0);
	const [appraisal, setAppraisal] = useState(0);

	const [status, setStatus] = useState("Available & Pending");

	const dataFormat = (content, setHook) => {
		var total = 0;
		const container = Object.keys(content).map((key) => {
			total = total + content[key].numOfType;
			if (content[key].typeDesc === "Available") {
				setAvailable(content[key].numOfType);
			}
			if (content[key].typeDesc === "Pending") {
				setPending(content[key].numOfType);
			}
			if (content[key].typeDesc === "Deleted") {
				setDeleted(content[key].numOfType);
			}
			if (content[key].typeDesc === "Sold") {
				setSold(content[key].numOfType);
			}
			if (content[key].typeDesc === "Trade") {
				setTrade(content[key].numOfType);
			}
			if (content[key].typeDesc === "Hold") {
				setHold(content[key].numOfType);
			}
			if (content[key].typeDesc === "Appraisal") {
				setAppraisal(content[key].numOfType);
			}

			if (content[key].typeDesc === "Sold Without Deal") {
				setSoldNoDeal(content[key].numOfType);
			}

			return (
				<div>
					{content[key].typeDesc} : {content[key].numOfType}
				</div>
			);
		});
		setHook(total);
		console.log(totalInv);
		return container;
	};

	const getInvListData = (LocationID) => {
		console.log(LocationID);
		if (locationID !== "") {
			getInventoryStatusList(LocationID).then(
				(response) => {
					console.log(response.data.content);
					dataFormat(response.data.content, setTotalInv);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}
	};

	useEffect(() => {
		getInvListData(locationID);
		// eslint-disable-next-line
	}, []);

	return (
		<div className="pt-3 mb-3 mobileHeading">
			<Row className="mx-0">
				<Col className="pb-3 text-center">
					<CardTitle>
						<h2>Inventory - {status}</h2>
					</CardTitle>
				</Col>
			</Row>
			<Row className="mx-0">
				{/* Card 1 */}
				<Col
					lg="3"
					md="6"
					sm="6"
					onClick={() => {
						setStatusList("Available,Pending");
						setStatus("Available & Pending");
					}}
				>
					<Card
						className={
							status === "Available & Pending"
								? "card-stats-active"
								: "card-stats"
						}
					>
						<NavLink
							to="/inventory-list"
							activeClassName=""
							className="text-dark"
						>
							<CardContent
								iconClass="icon-big text-center nc-icon nc-delivery-fast text-success"
								title1="Available/Pending"
								title2={available + pending}
								check={status === "Available & Pending"}
							/>
						</NavLink>
						<CardContentBottom
							navClass="col-xs-3"
							routePath="/inventory-add"
							iconClass="nc-icon nc-simple-add"
							iconText=" Add New"
						/>
					</Card>
				</Col>

				{/* Card 3 */}
				<Col lg="3" md="6" sm="6">
					<Card
						className={status === "Sold" ? "card-stats-active" : "card-stats"}
						onClick={() => {
							setStatusList("Sold,Sold Without Deal");
							setStatus("Sold");
						}}
					>
						<NavLink
							to="/inventory-list"
							activeClassName=""
							className="text-dark"
						>
							<CardContent
								iconClass="icon-big text-center nc-icon nc-delivery-fast text-info"
								title1="Sold"
								title2={sold + soldNoDeal}
								check={status === "Sold"}
							/>
						</NavLink>
						<CardContentBottom
							navClass="col-xs-3"
							routePath="/inventory-list"
							iconClass="nc-icon nc-minimal-down"
							iconText=" Sold Report"
						/>
					</Card>
				</Col>
				{/* Card 2 */}
				<Col
					lg="3"
					md="6"
					sm="6"
					onClick={() => {
						setStatusList("Trade,Hold,Deleted");
						setStatus("Trade & Hold");
					}}
				>
					<Card
						className={
							status === "Trade & Hold" ? "card-stats-active" : "card-stats"
						}
					>
						<NavLink
							to="/inventory-list"
							activeClassName=""
							className="text-dark"
						>
							<CardContent
								iconClass="icon-big text-center nc-icon nc-delivery-fast text-warning"
								title1="Trade/Hold/Deleted"
								title2={trade + hold + deleted}
								check={status === "Trade & Hold & Deleted"}
							/>
						</NavLink>
						<CardContentBottom
							navClass="col-xs-3"
							routePath="/inventory-list"
							iconClass="nc-icon nc-minimal-down"
							iconText=" Inventory report"
						/>
					</Card>
				</Col>

				{/* Card 4 */}
				<Col
					lg="3"
					md="6"
					sm="6"
					onClick={() => {
						setStatusList("Appraisal"); //deleted,DELETED
						setStatus("Appraisal");
					}}
				>
					<Card
						className={
							status === "Appraisal" ? "card-stats-active" : "card-stats"
						}
					>
						<NavLink
							to="/inventory-list"
							activeClassName=""
							className="text-dark"
						>
							<CardContent
								iconClass="icon-big text-center nc-icon nc-delivery-fast text-danger"
								title1="Appraisal"
								title2={appraisal}
								check={status === "Appraisal"}
							/>
						</NavLink>
						<CardContentBottom
							navClass="col-xs-3"
							routePath="/inventory-list"
							iconClass="nc-icon nc-minimal-down"
							iconText=" Appraisal Report"
						/>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default InventoryHeader;
