import axios from "axios";

import { updateJwtToken } from "../Authorization";
import { handledGeneralApiError } from "../../helperFunction/ApiErrorHandler";

/**
 * Builds the Axios instance we need to handle http response
 *
 * @param {import("axios").AxiosRequestConfig} axiosConfig - Object containing any request
 * configurations. Click the "AxiosRequestConfig" to see the configurations. At minimum,
 * the baseURL key in this object is required.
 *
 * @returns {import("axios").AxiosInstance} Which is used to make the get/post requests
 */
const getResponseInstance = (axiosConfig) => {
	if (
		axiosConfig == null ||
		axiosConfig.baseURL == null ||
		axiosConfig.baseURL === ""
	) {
		throw new Error("Configuration must include baseURL");
	}

	// Initialize Axios configurations
	const instance = axios.create({ ...axiosConfig });

	// Interceptor for handling Http response
	instance.interceptors.response.use(
		(res) => {
			const newJwt = res.headers.authorization;

			if (newJwt != null && newJwt !== "") {
				updateJwtToken(newJwt);
			}

			return res;
		},
		(err) =>
			Promise.reject({
				...err,
				isGeneralError: handledGeneralApiError(err), // True if general api error handled
			})
	);

	return instance;
};

export default getResponseInstance;
