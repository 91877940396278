import React, { useState } from "react";
import { Form, Container, Col, Button } from "reactstrap";

import InputElement from "view/formValidation/InputElement";
import InputWrapper from "view/formValidation/InputWrapper";
import NewUserPassword from "./NewUserPassword";
import PasswordChecklist from "./PasswordCheckList";

import { activeDC2Owner } from "../../../api/LoginAPI";
import { failAlert } from "component/dc/modal/SweetAlerts";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";
import { successAlertCustom } from "component/dc/modal/SweetAlerts";
import { useHistory } from "react-router-dom";
import { PATHS } from "Constants";

import { useFormContext } from "react-hook-form";

const NewUserForm = () => {
	const [dealerID, setDealerID] = useState(null);
	const [locationID, setLocationID] = useState(null);
	const [accessCode, setAccessCode] = useState(null);
	const [email, setEmail] = useState(null);
	const [username, setUsername] = useState(null);
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState(null);

	const history = useHistory();

	// Get validation context
	const methods = useFormContext();

	// Call api to activate the new user account
	const saveUser = () => {
		const newUser = {
			dealerID,
			locationID,
			username: username.trim(),
			password,
			accessCode,
			email,
		};

		activeDC2Owner(newUser).then(
			(res) => {
				console.log(res);
				successAlertCustom("Successfully activated new account");
				history.push(PATHS.LOGIN);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, failAlert, err.response.data.message);
				}
			}
		);
	};

	return (
		<>
			<Container>
				<Col className="ml-auto mr-auto content" lg="6" md="6">
					<Form className="form">
						<InputWrapper
							formTitle="Activate Account"
							centerTitle
							inputComponents={[
								<hr className="col-lg-8" />,
								<InputElement
									{...methods}
									colSize="col-lg-6 mt-3"
									value={accessCode}
									onChange={(e) => setAccessCode(e.target.value)}
									name="accessCode"
									label="Access Code"
								/>,
								<div className="col-lg-6" />,
								<InputElement
									{...methods}
									colSize="col-lg-6"
									value={dealerID}
									onChange={(e) => setDealerID(e.target.value)}
									name="dealerID"
									label="Dealer ID"
									type="number"
								/>,
								<InputElement
									{...methods}
									colSize="col-lg-6"
									value={locationID}
									onChange={(e) => setLocationID(e.target.value)}
									name="locationID"
									label="Location ID"
									type="number"
								/>,
								<InputElement
									{...methods}
									colSize="col-lg-12"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									name="email"
									label="Email"
									placeholder="Enter a valid email address"
								/>,
								<hr className="col-lg-8" />,
								<InputElement
									{...methods}
									colSize="col-lg-6 mt-3"
									value={username}
									onChange={(e) => setUsername(e.target.value)}
									name="username"
									label="Username"
									type="username"
								/>,
								<NewUserPassword
									{...methods}
									colSize="col-lg-12"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									name="password"
									label="Password"
								/>,
								<PasswordChecklist password={password} />,
								<NewUserPassword
									{...methods}
									colSize="col-lg-12"
									value={confirmPassword}
									onChange={(e) => setConfirmPassword(e.target.value)}
									name="confirmPassword"
									label="Confirm Password"
								/>,
							]}
							buttons={
								<div className="d-flex justify-content-center mt-4">
									<Button
										className="btn btn-default"
										color="primary"
										onClick={methods.handleSubmit(saveUser)}
									>
										Activate
									</Button>
								</div>
							}
						/>
					</Form>
				</Col>
			</Container>
		</>
	);
};

export default NewUserForm;
