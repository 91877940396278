import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { Button, CardText, Col, Input, Row, Form, Label } from "reactstrap";
import shallow from "zustand/shallow";
import ChildrenModal from "component/dc/modal/ChildrenModal";
import useCurrentSaleStore from "../../../../store/SaleStore";
import {
	CurrencyInput,
	PercentInput,
} from "../../../../view/formValidation/InputElement";
import useCurrentDealStore from "../../../../store/DealStore";
import useCurrentRecapStore from "../../../../store/RecapStore";
import NumberFormat from "react-number-format";

const LenderFeeCalculator = () => {
	const { totalLenderFee } = useCurrentRecapStore((state) => state, shallow);
	const [open, setOpen] = useState(false);

	const toggle = () => {
		setOpen(!open);
	};

	return (
		<Row className="p-2">
			<Col md="6">
				<CardText>
					<Button
						className="active px-1 btn-round btn-md btn btn-outline-secondary w-100 my-0"
						onClick={toggle}
					>
						Lender Fee (-)
					</Button>
				</CardText>

				<ChildrenModal
					modal={open}
					toggle={toggle}
					size="s"
					modalTitle="Lender Fees"
					children={<LenderFeeForm />}
				/>
			</Col>
			<CurrencyInput
				value={totalLenderFee}
				readOnly
				disableValidation
				wholeRow
			/>
		</Row>
	);
};

export default LenderFeeCalculator;

export const LenderFeeForm = () => {
	const {
		amtFinanced,
		fiDiscount,
		discount,
		//editFiDiscount,
		editDiscount,
	} = useCurrentSaleStore((state) => state, shallow);
	const {
		lenderFee,
		editLenderFee,
		lenderPaid,
		editLenderPaid,
		netCheckToDealer,
		totalLenderFee,
	} = useCurrentRecapStore((state) => state, shallow);
	const { lender } = useCurrentDealStore((state) => state, shallow);

	useEffect(() => {
		console.log(lenderPaid);
		if (lenderPaid === null) {
			const today = dayjs().format("YYYY-MM-DD");
			editLenderPaid(today);
		}
		//"1899-12-31"
		if (lenderPaid === "1899-12-31") {
			editLenderPaid("");
		}
		// eslint-disable-next-line
	}, []);
	// useEffect(() => {
	// 	console.log(discount);

	// 	// eslint-disable-next-line
	// }, [discount]);

	const handleBlur = (e) => {
		console.log(e.target.value);
		editDiscount(
			1 -
				(parseFloat(e.target.value.replace("$", "")) + lenderFee) / amtFinanced
		);
	};

	return (
		<Form>
			<Row>
				<Col>
					<Label className="h6">{lender?.name || "No Lender"}</Label>
				</Col>
			</Row>
			<Row>
				<Col md="6">
					<label>Lender Discount Rate (%)</label>
				</Col>
				<PercentInput
					value={discount}
					onChange={editDiscount}
					disableValidation
					wholeRow
				/>
				<Col md="6">
					<label>Amount Financed</label>
				</Col>
				<CurrencyInput
					value={amtFinanced}
					readOnly
					disableValidation
					wholeRow
				/>
				<Col md="6">
					<label>Lender Discount</label>
				</Col>
				<CurrencyInput
					value={fiDiscount}
					//					onChange={editFiDiscount}
					readOnly
					disableValidation
					wholeRow
				/>
				<Col md="6">
					<label>Lender Fee</label>
				</Col>
				<CurrencyInput
					value={lenderFee}
					onChange={editLenderFee}
					disableValidation
					wholeRow
				/>
				<Col md="6">
					<label>Total Lender Fee</label>
				</Col>
				<CurrencyInput
					value={totalLenderFee}
					readOnly
					disableValidation
					wholeRow
				/>
				<Col md="6">
					<label>Net Check To Dealer</label>
				</Col>
				<Col md="6">
					<NumberFormat
						name="netCheckToDealer"
						decimalScale={2}
						prefix={"$"}
						fixedDecimalScale={true}
						isNumericString={true}
						customInput={Input}
						onFocus={(e) => e.target.select()}
						value={netCheckToDealer}
						onBlur={handleBlur}
					/>
				</Col>

				{/* <CurrencyInput
					value={netCheckToDealer}
					onFocus={(e) => e.target.select()}
					disableValidation
					wholeRow
				/> */}
				<Col md="6">
					<label>Check Received</label>
				</Col>
				<Col md="6">
					<Input
						type="date"
						name="lenderPaid"
						value={lenderPaid}
						onChange={(e) => editLenderPaid(e.target.value)}
					/>
				</Col>
			</Row>
		</Form>
	);
};
