import { defaultPictureURL } from "Constants";

// Function to convert base64 url to a Blob object
export const dataURItoBlob = (dataURI) => {
	// Convert base64/URLEncoded data component to raw binary data held in a string
	let byteString;

	try {
		if (dataURI.split(",")[0].indexOf("base64") >= 0) {
			byteString = atob(dataURI.split(",")[1]);
		} else {
			byteString = unescape(dataURI.split(",")[1]);
		}

		// Separate out the mime component
		let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

		// Write the bytes of the string to a typed array
		let ia = new Uint8Array(byteString.length);

		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		return new Blob([ia], { type: mimeString });
	} catch (e) {
		console.log(e);
	}
};

// Returns a form data object containing the blob file(s) after they were
// converted from base64 to blobs
export const getFormData = (photos) => {
	let formData = new FormData();

	for (let [key, value] of Object.entries(photos)) {
		formData.append(String(key), dataURItoBlob(value));
	}

	return formData;
};

// Returns the images object, to be used by the gallery library. Need to
// append the lastmod string to the URLs or else the images won't update.
export const createPhotosObj = (photoUrls) => {
	let currentTime = Date.now();

	let arrayObj = photoUrls.map((url, index) => ({
		src: `${url}?lastmod=${currentTime}`,
		srcSet: `${url}?lastmod=${currentTime} 300w`,
		sizes: ["(min-width: 480px) 80vw,(min-width: 1024px) 50vw,100vw"],
		key: String(index),
		width: "200px",
		height: "100px",
		alt: `Photo ${index + 1}`,
	}));

	return arrayObj;
};

// Returns the first image url from the images array, else default image
export const getThumbnailURL = (imagesArr) => {
	if (imagesArr == null || imagesArr.length < 1) {
		return defaultPictureURL;
	}

	return `${imagesArr[0]}?lastmod=${Date.now()}`;
};

// Takes in an object containing keys as array indexes and true/false as
// the values indicating whether to delete the photo in that index. Returns
// the indexes to delete
export const getDeletedIndexes = (obj) => {
	let deletes = [];

	for (const [key, value] of Object.entries(obj)) {
		if (value === true) {
			deletes.push(Number(key));
		}
	}

	return deletes;
};

// Takes in an array of objects where the object's key pointing to the older image
// index is now the index of the current array index. Returns an array containing
// the reordered indexes starting from 0 to array.length - 1.
export const getReorders = (arrObj) => {
	let reorders = [];

	for (let i = 0; i < arrObj.length; i++) {
		reorders.push(Number(arrObj[i].key));
	}

	return reorders;
};

// Checks that a reordered array contains at least one group of pictures that
// have had their indexes switched. Returns true if they have, false otherwise
export const isReordered = (arr) => {
	for (let i = 0; i < arr.length; i++) {
		if (i !== arr[i]) {
			return true;
		}
	}

	return false;
};
