import React from "react";
import { Card, CardTitle, CardBody } from "reactstrap";
import dayjs from "dayjs";
import { LoanPeriod } from "Constants.js";
import { ReviewRow } from "component/dc/deal/review/Review";
import useCurrentSaleStore from "store/SaleStore";
import useCurrentRecapStore from "store/RecapStore";
import shallow from "zustand/shallow";
//import useCurrentDealStore from "store/DealStore";

const ReviewFinance = () => {
	const {
		// docFee,
		// dealerSmog,
		// gap,
		// // stateSmog,
		// totalSellerFees,
		amtFinanced,
		apr,
		financeCharge,
		loan,
		term,
		payment,
		finalPmt,
		loanDate,
		daysToPay,
		firstDueDate,
		finalDueDate,
		loanPeriod,
		onePay,
	} = useCurrentSaleStore(
		(state) => ({
			// price: state.price,
			// aftmkt: state.aftmkt,
			// docFee: state.docFee,
			// dealerSmog: state.dealerSmog,
			// tax: state.tax,
			// warranty: state.warranty,
			// totalDMVFees: state.totalDMVFees,
			// gap: state.gap,
			// stateSmog: state.stateSmog,
			// totalSellerFees: state.totalSellerFees,
			amtFinanced: state.amtFinanced,
			apr: state.apr,
			financeCharge: state.financeCharge,
			loan: state.loan,
			term: state.term,
			payment: state.payment,
			finalPmt: state.finalPmt,
			loanDate: state.loanDate,
			daysToPay: state.daysToPay,
			firstDueDate: state.firstDueDate,
			finalDueDate: state.finalDueDate,
			loanPeriod: state.loanPeriod,
			onePay: state.onePay,
		}),
		shallow
	);

	const { totalDown } = useCurrentRecapStore(
		(state) => ({
			totalDown: state.totalDown,
		}),
		shallow
	);
	// const { cancellationAmt, cancellationAccepted } = useCurrentDealStore(
	// 	(state) => ({
	// 		cancellationAmt: state?.contractInfo?.cancellationAmt,
	// 		cancellationAccepted: state?.contractInfo?.cancellationAccepted,
	// 	}),
	// 	shallow
	// );
	// const [cancellationFee, setcancellationFee] = useState(
	// 	cancellationAccepted ? parseFloat(cancellationAmt) : 0
	// );

	const getLoanPeriod = () => {
		console.log(loanPeriod);
		const periodLabel = LoanPeriod.filter(
			(element) => element.value === loanPeriod
		);
		return periodLabel[0]?.label;
	};

	const periodString = getLoanPeriod();
	//	const [totalCashPrice, setTotalCashPrice] = useState(0);

	// const calcTotalCashPrice = () => {
	// 	if (cancellationAccepted) setcancellationFee(parseFloat(cancellationAmt));
	// 	else setcancellationFee(0);
	// 	console.log(
	// 		parseFloat(onePay),
	// 		parseFloat(docFee),
	// 		parseFloat(dealerSmog),
	// 		parseFloat(gap),
	// 		parseFloat(totalSellerFees),
	// 		cancellationFee
	// 	);
	// 	const total =
	// 		parseFloat(onePay) -
	// 		(parseFloat(docFee) + parseFloat(dealerSmog)) +
	// 		parseFloat(gap) +
	// 		parseFloat(totalSellerFees) +
	// 		cancellationFee;
	// 	parseFloat(total).toFixed(2);
	// 	setTotalCashPrice(total);
	// };

	// 	useEffect(() => {
	// //		calcTotalCashPrice();
	// 		// eslint-disable-next-line
	// 	}, []);

	return (
		<Card>
			<CardBody>
				<CardTitle tag="h6">Financing</CardTitle>

				<ReviewRow isCurrency={true} label="Total Price" value={onePay} />
				<h7 className="text-danger">
					<ReviewRow isCurrency={true} label="Total Down" value={totalDown} />
				</h7>
				<h6>
					<ReviewRow
						isCurrency={true}
						label="Amt Financed"
						value={amtFinanced}
						fontSize={"65%"}
					/>
				</h6>
				<ReviewRow />
				<ReviewRow isPercent={true} label="APR" value={apr} />
				<ReviewRow
					isCurrency={true}
					label="Finance Charge"
					value={financeCharge}
					fontSize={"65%"}
				/>
				<ReviewRow isCurrency={true} label="Total Payments" value={loan} />
				<ReviewRow
					isCurrency={true}
					label={term - 1 + " Payments of"}
					value={payment}
				/>
				<ReviewRow isCurrency={true} label="Final Payment" value={finalPmt} />

				<ReviewRow
					label="Sale Date"
					value={dayjs(loanDate).format("MM/DD/YYYY")}
				/>
				<ReviewRow label="Days to pay" value={daysToPay} />
				<ReviewRow
					label="First Due Date"
					value={dayjs(firstDueDate).format("MM/DD/YYYY")}
				/>
				<ReviewRow
					label="Final Due Date"
					value={dayjs(finalDueDate).format("MM/DD/YYYY")}
				/>
				<ReviewRow label="Period" value={periodString} />
			</CardBody>
		</Card>
	);
};

export default ReviewFinance;
