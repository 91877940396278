import React, { useEffect, useState } from "react";
import { Col, CardBody, FormGroup, Modal, Form, Button } from "reactstrap";

import {
	getCustomEquipmentList,
	saveToCustomEquipmentList,
} from "../../../api/InventoryAPI";
import { useFormContext } from "react-hook-form";
import InputElement from "../../../view/formValidation/InputElement";
import useCurrentVehicleStore from "../../../store/InventoryStore";
import shallow from "zustand/shallow";
import { retrieveFailAlert } from "../modal/SweetAlerts";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";

/*
  Database values come in as comma separated string
  to populate the multiselector, the string needs to be split and then stored in the following format:
  [{ value: , label: }]
  In order to be saved in the database, they have to be in the comma separated format again.
*/
const InventoryCustomEquipment = ({
	changeHandler,
	selectedEquip,
	disabled,
}) => {
	const { customEquipList, editCustomEquipList } = useCurrentVehicleStore(
		(state) => state,
		shallow
	);

	const [newEquipDescription, setNewEquipDescription] = useState("");
	const [newEquipItemCode, setNewEquipItemCode] = useState("");
	const [modalIsOpen, setModalIsOpen] = useState(false);

	const dealerInfo = JSON.parse(sessionStorage.getItem("user"));
	const dealerID = dealerInfo.dealerID;
	const locationID = dealerInfo.locationID;

	const methods = useFormContext();
	console.log(customEquipList);
	useEffect(() => {
		getCustomEquipList();
		// eslint-disable-next-line
	}, []);

	if (!customEquipList) {
		return null; // or return <div>No options available</div> or similar
	}

	let d = disabled;
	if (customEquipList === "" || customEquipList === null) {
		d = true;
	}

	// Convert selectedEquip to an array
	console.log(selectedEquip);
	const selectedEquipArray = selectedEquip ? selectedEquip.split(",") : []; // split with comma and space
	const handleCheckboxChange = (event, value) => {
		const newSelectedEquipArray = event.target.checked
			? [...selectedEquipArray, value]
			: selectedEquipArray.filter((item) => item !== value);
		console.log(newSelectedEquipArray.join(","));
		// Convert the array back to a string
		changeHandler(newSelectedEquipArray.join(",")); // join with comma and space
	};
	const columnSize = customEquipList.length / 5;
	const columns = [];
	for (let i = 0; i < customEquipList.length; i += columnSize) {
		columns.push(customEquipList.slice(i, i + columnSize));
	}

	const handleAddEquipment = () => {
		// Construct the request body
		const body = {
			dealerID,
			locationID,
			isHidden: false,
			description: newEquipDescription,
			itemCode: newEquipItemCode,
			specialCode: "C",
		};

		// Call your API function
		saveToCustomEquipmentList(body)
			.then((response) => {
				// Clear the input fields on success
				setNewEquipDescription("");
				setNewEquipItemCode("");
				getCustomEquipList();
				// You might also want to update your optionalEquip here
			})
			.catch((error) => {
				// Handle any errors here
				console.error(error);
			});
	};
	// // Fetch the equipment data on component mount
	// useEffect(() => {
	// 	loadEquipmentData()
	// 		.then(data => {
	// 			setOptionalEquip(data);
	// 		})
	// 		.catch(error => {
	// 			// Handle any errors here
	// 			console.error(error);
	// 		});
	// }, []);

	const getCustomEquipList = () => {
		const customEquipList = [];
		getCustomEquipmentList(locationID, false).then(
			(res) => {
				const resp = res.data.content;
				for (const key in resp) {
					customEquipList.push({
						label: resp[key].description,
						value: resp[key].description,
					});
				}
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
		editCustomEquipList(customEquipList);
	};

	return (
		<CardBody>
			<Col>
				<FormGroup>
					<div>
						<div className="checkbox-columns">
							{columns.map((column, columnIndex) => (
								<div key={`column-${columnIndex}`} className="checkbox-column">
									{column.map((option, index) => (
										<div key={index} className="checkbox-item">
											<input
												type="checkbox"
												id={`${option.value}-${columnIndex}-${index}`}
												value={option.value}
												disabled={d}
												checked={selectedEquipArray.includes(option.value)}
												onChange={(e) => handleCheckboxChange(e, option.value)}
											/>
											<label
												htmlFor={`${option.value}-${columnIndex}-${index}`}
											>
												{option.label}
											</label>
										</div>
									))}
								</div>
							))}
						</div>
						{/* "Add More Equipment" button that opens the modal */}
						<div className="row mx-0">
							<Button
								className="btn btn-primary btn-sm"
								onClick={() => setModalIsOpen(true)}
							>
								<i className="nc-icon nc-simple-add"></i> Add More Equipment
							</Button>
							<div className="col-md-6 text-right"></div>
						</div>
					</div>
				</FormGroup>
			</Col>
			<Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
				<CardBody>
					<Col>
						<FormGroup>
							<Form>
								{/* New input fields for the new equipment's details */}
								<div>
									<InputElement
										{...methods}
										value={newEquipDescription}
										name="newEquipDescription"
										label="Description"
										type="text"
										wholeRow="true"
										onChange={(e) => setNewEquipDescription(e.target.value)}
									/>
								</div>
								<div
									className="btn btn-md btn-primary"
									onClick={() => {
										handleAddEquipment();
										setModalIsOpen(false);
									}}
								>
									<i className="nc-icon nc-align-center"></i>
									<span className="noMobile">Add Equipment</span>
								</div>

								<div
									className="btn btn-md btn-danger"
									onClick={() => setModalIsOpen(false)}
								>
									<i className="nc-icon nc-align-center"></i>
									<span className="noMobile">Close</span>
								</div>
							</Form>
						</FormGroup>
					</Col>
				</CardBody>
			</Modal>
		</CardBody>
	);
};

export default InventoryCustomEquipment;
