import React, { useState } from "react";

// reactstrap components
import {
	Card,
	CardBody,
	CardHeader,
	ListGroup,
	ListGroupItem,
	Collapse,
	Col,
	Row,
} from "reactstrap";

/* 
  Displays the standard equipment and specifications of the vehicle 
  First must parse JSON string into object, then iterate through it
*/
const InventoryStandardEquipment = (props) => {
	let standardObj = "";
	try {
		standardObj = JSON.parse(props.standardEquip);
	} catch (e) {
		console.log(e.message);
	}
	let standard = "";
	let standardEnd = "";

	// This iterates through the JSON object to display ListGroupItems
	// for standard equipment
	if (standardObj !== null && standardObj !== "") {
		let halfSize = standardObj.length / 2;
		standard = Object.keys(standardObj.slice(0, halfSize)).map((key) => (
			<div key={standardObj[key].Key}>
				<br></br>
				<h6>{standardObj[key].Key}</h6>
				<ListGroup>
					{Object.keys(standardObj[key].Value).map((key2, i) => (
						<ListGroupItem key={i}>
							{standardObj[key].Value[key2].Name +
								" " +
								standardObj[key].Value[key2].Value}
						</ListGroupItem>
					))}
				</ListGroup>
			</div>
		));
		let standEnd = standardObj.slice(halfSize, standardObj.length - 1);
		standardEnd = Object.keys(standEnd).map((key) => (
			<div key={standEnd[key].Key}>
				<br></br>
				<h6>{standEnd[key].Key}</h6>
				<ListGroup>
					{Object.keys(standEnd[key].Value).map((key2, i) => (
						<ListGroupItem key={i}>
							{standEnd[key].Value[key2].Name +
								" " +
								standEnd[key].Value[key2].Value}
						</ListGroupItem>
					))}
				</ListGroup>
			</div>
		));
	}

	const [open, setOpen] = useState(false);

	const handleCollapse = () => {
		setOpen(!open);
	};

	return (
		<Card>
			<CardHeader onClick={handleCollapse} className="cursor-pointer">
				<h3>Standard Equipment </h3> <i className="nc-icon nc-minimal-down" />
			</CardHeader>
			<CardBody>
				<Collapse role="tabpanel" isOpen={open}>
					<Row>
						<Col>
							<CardBody>{standard}</CardBody>
						</Col>
						<Col>
							<CardBody>{standardEnd}</CardBody>
						</Col>
					</Row>
				</Collapse>
			</CardBody>
		</Card>
	);
};

export default InventoryStandardEquipment;
