import React from "react";
import { Form } from "reactstrap";

import useCurrentSaleStore from "store/SaleStore";
import LenderWrapper from "../LenderWrapper";

import { IntegerInputElement } from "view/formValidation/InputElement";
import { PercentInputElement } from "view/formValidation/InputElement";
import { CurrencyInputElement } from "view/formValidation/InputElement";

import { useFormContext } from "react-hook-form";
import shallow from "zustand/shallow";

const UACDeal = () => {
	const {
		apr,
		price,
		totalNetTrade,
		tax,
		down,
		amtFinanced,
		term,
		payment,
		dealerSmog,
		docFee,
		gap,
		warranty,
		aftmkt,
		license,
		registration,
		titleFee,
		tireFee,
	} = useCurrentSaleStore(
		(state) => ({
			apr: state.apr,
			price: state.price,
			totalNetTrade: state.totalNetTrade,
			tax: state.tax,
			down: state.down,
			amtFinanced: state.amtFinanced,
			term: state.term,
			payment: state.payment,
			dealerSmog: state.dealerSmog,
			docFee: state.docFee,
			gap: state.gap,
			warranty: state.warranty,
			aftmkt: state.aftmkt,
			license: state.license,
			registration: state.registration,
			titleFee: state.titleFee,
			tireFee: state.tireFee,
		}),
		shallow
	);

	// Get validation context
	const methods = useFormContext();

	return (
		<Form id="uacDeal">
			<LenderWrapper cardId="uacDeal" cardTitle="Deal Information">
				<CurrencyInputElement
					{...methods}
					colSize="col-lg-3"
					value={price}
					readOnly={true}
					name="uacPrice"
					label="Price"
				/>
				<CurrencyInputElement
					{...methods}
					colSize="col-lg-3"
					value={amtFinanced}
					readOnly={true}
					name="uacAmtFinanced"
					label="Amount Financed"
				/>
				<IntegerInputElement
					{...methods}
					readOnly={true}
					colSize="col-lg-3"
					value={term}
					name="uacTerm"
					label="Term"
				/>
				<PercentInputElement
					{...methods}
					readOnly={true}
					colSize="col-lg-3"
					value={apr}
					name="uacApr"
					label="APR (%)"
				/>
				<CurrencyInputElement
					{...methods}
					colSize="col-lg-3"
					value={payment}
					readOnly={true}
					name="uacMonthlyPayment"
					label="Monthly Payment"
				/>
				<CurrencyInputElement
					{...methods}
					colSize="col-lg-3"
					value={tax}
					readOnly={true}
					name="uacSalesTax"
					label="Sales Tax"
				/>
				<CurrencyInputElement
					{...methods}
					colSize="col-lg-3"
					value={totalNetTrade}
					readOnly={true}
					name="uacTrade"
					label="Net Trade"
				/>
				<CurrencyInputElement
					{...methods}
					colSize="col-lg-3"
					value={down}
					readOnly={true}
					name="uacDow"
					label="Down Payment"
				/>
				<CurrencyInputElement
					{...methods}
					colSize="col-lg-3"
					value={dealerSmog}
					readOnly={true}
					name="uacDealerSmogFee"
					label="Dealer Smog"
				/>
				<CurrencyInputElement
					{...methods}
					colSize="col-lg-3"
					value={docFee}
					readOnly={true}
					name="uacDocFee"
					label="Doc Fee"
				/>
				<CurrencyInputElement
					{...methods}
					colSize="col-lg-3"
					value={gap}
					readOnly={true}
					name="uacGapFee"
					label="GAP"
				/>
				<CurrencyInputElement
					{...methods}
					colSize="col-lg-3"
					value={titleFee}
					readOnly={true}
					name="uacTitleFee"
					label="Title Fee"
				/>
				<CurrencyInputElement
					{...methods}
					colSize="col-lg-3"
					value={tireFee}
					readOnly={true}
					name="uacTireFee"
					label="Tire Fee"
				/>
				<CurrencyInputElement
					{...methods}
					colSize="col-lg-3"
					value={warranty}
					readOnly={true}
					name="uacWarrantyFee"
					label="Total Warranty"
				/>
				<CurrencyInputElement
					{...methods}
					colSize="col-lg-3"
					value={aftmkt}
					readOnly={true}
					name="uacAftMktFee"
					label="Total Aftermarket"
				/>
				<CurrencyInputElement
					{...methods}
					colSize="col-lg-3"
					value={tax}
					readOnly={true}
					name="uacTaxFee"
					label="Total Taxes"
				/>
				<CurrencyInputElement
					{...methods}
					colSize="col-lg-3"
					value={license}
					readOnly={true}
					name="uacLicenseFee"
					label="License"
				/>
				<CurrencyInputElement
					{...methods}
					colSize="col-lg-3"
					value={registration}
					readOnly={true}
					name="uacRegistrationFee"
					label="Registration"
				/>
				<p className="col col-12">
					*Deal information must be updated in the specific Deal fields / tabs
				</p>
			</LenderWrapper>
		</Form>
	);
};

export default UACDeal;
