import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Label } from "reactstrap";
import useCurrentDealStore from "store/DealStore";
import AddVendorModal from "component/dc/modal/AddVendorModal";
import { failAlert } from "component/dc/modal/SweetAlerts";
import { saveVendor } from "api/VendorsAPI";
import { vendorTypesOBJ } from "Constants.js";
import { DealStatus } from "Constants";
import shallow from "zustand/shallow";
import useCurrentSaleStore from "../../../../store/SaleStore";

const LenderDetail = () => {
	// Local state
	const [lenderList, setLenderList] = useState([]);
	const [openAddLenderModal, setOpenAddLenderModal] = useState(false);
	// Deal store
	const {
		lenders,
		editLenders,
		editLenderRecNum,
		editLender,
	} = useCurrentDealStore((state) => state, shallow);

	const { type, lenderRecNum } = useCurrentDealStore(
		(state) => state.deal,
		shallow
	);

	const { editBankFee, editDiscount } = useCurrentSaleStore(
		(state) => state,
		shallow
	);
	const getLendersList = () => {
		let list = [];
		for (const key in lenders) {
			list.push({
				label: lenders[key].name,
				value: lenders[key].ID,
				lender: lenders[key],
			});
		}
		list.push({ label: "None", value: null, lender: null });
		setLenderList(list);
	};

	useEffect(() => {
		getLendersList();
		// eslint-disable-next-line
	}, []);

	// Function to open the modal
	const toggleLenderModal = () => setOpenAddLenderModal(!openAddLenderModal);

	// Updates store with the lender selected or opens modal to add new lender
	const selectLender = (option) => {
		const value = option.value;

		console.log(option);
		if (value === "ADD") {
			toggleLenderModal();
			console.log(value);
		} else {
			editLenderRecNum(value);
			editLender(option.lender);
			if (option.lender === null) {
				editBankFee(0);
				editDiscount(0);
			} else {
				if (option.lender?.bankFee !== null) {
					editBankFee(option.lender.bankFee || 0);
				} else {
					editBankFee(0);
				}
				if (option.lender?.discountRate !== null) {
					editDiscount(option.lender.discountRate || 0);
				} else {
					editDiscount(0);
				}
			}
		}
	};

	// Saves the new lender and add it to the lender list in deal store
	const saveNewLender = (lender) => {
		if (lender != null) {
			const isHidden = false;
			//in order to save add isHidden
			const buildLender = { ...lender, isHidden };
			saveVendor(buildLender).then(
				(res) => {
					const newLender = {
						value: res.data.content.ID,
						label: res.data.content.name,
						lender: res.data.content,
					};
					console.log(newLender);

					editLenders([...lenders, res.data.content]);
					setLenderList([...lenderList, newLender]);
					editLenderRecNum(newLender.value);
					if (newLender?.bankFee !== null) {
						editBankFee(newLender.bankFee || 0);
					} else {
						editBankFee(0);
					}
					if (newLender?.discountRate !== null) {
						editDiscount(newLender.discountRate || 0);
					} else {
						editDiscount(0);
					}
				},
				(e) => {
					console.log(e);
					failAlert("Failed to save new lender");
				}
			);
		}
	};

	return (
		<div>
			<Label>Lender</Label>
			<Select
				isDisabled={type !== DealStatus.PENDING}
				noOptionsMessage={() => "No Lenders available"}
				value={{
					value: lenderRecNum,
					label:
						lenderList.filter((element) => element.value === lenderRecNum)[0]
							?.label || "None",
				}}
				options={[...lenderList, { value: "ADD", label: "Add new lender" }]}
				onChange={(e) => selectLender(e)}
				className="bold"
			/>
			<AddVendorModal
				title="Add Lender"
				vendorType={vendorTypesOBJ.LENDER.value}
				vendorLabel={vendorTypesOBJ.LENDER.label}
				addVendor={saveNewLender}
				toggle={toggleLenderModal}
				modal={openAddLenderModal}
			/>
		</div>
	);
};

export default LenderDetail;
