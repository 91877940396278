import React, { useState, useEffect } from "react";
import {
	Button,
	FormGroup,
	Label,
	Input,
	Card,
	Col,
	CardBody,
	CardHeader,
	CardTitle,
	Row,
	Form,
} from "reactstrap";
import { TaxRegion } from "Constants.js";
import { saveDeal } from "api/DealAPI";
import { saveSuccessAlert } from "component/dc/modal/SweetAlerts";
import NumberFormat from "react-number-format";
import useCurrentDealStore from "store/DealStore";
import useBuildDeal from "helperFunction/deal/useBuildDeal";
import { retrieveFailAlert } from "component/dc/modal/SweetAlerts";
import { useFromUser } from "store/LocalStorageHelper";
import { getSettings } from "api/SettingsAPI";
import { DealStatus } from "Constants";
import useCurrentSaleStore from "store/SaleStore";
import tabOnEnter from "helperFunction/tabOnEnter";
import { showApiError } from "../../../../helperFunction/ApiErrorHandler";
import { saveFailAlert } from "../../../../component/dc/modal/SweetAlerts";
import shallow from "zustand/shallow";
import useSetDeal from "../../../../helperFunction/deal/useSetDeal";
import { TaxType } from "../../../../Constants";
import TaxCountySelector from "../../../../component/dc/deal/statusBar/TaxCountySelector";
import { TaxComponent } from "./TaxDefaultsSetting";
import { useCallback } from "react";
/**
 *
 */
const TaxDefaults = () => {
	const { taxRate, editTaxRate, editTaxType } = useCurrentSaleStore(
		(state) => ({
			taxRate: state.taxRate,
			editTaxRate: state.editTaxRate,
			editTaxType: state.editTaxType,
		}),
		shallow
	);

	const {
		taxDefaults,
		profitSettings,
		commissionSettings,
		editDealSettings,
		type,
		editCountyRecNum,
		countyRecNum,
	} = useCurrentDealStore(
		(state) => ({
			taxDefaults: state?.deal?.dealSettings.taxDefaults,
			profitSettings: state?.deal?.dealSettings.profitSettings,
			commissionSettings: state?.deal?.dealSettings.commissionSettings,
			editDealSettings: state.editDealSettings,
			type: state.deal.type,
			editCountyRecNum: state.editCountyRecNum,
			countyRecNum: state.deal.countyRecNum,
		}),
		shallow
	);

	const getDeal = useBuildDeal();
	const setDeal = useSetDeal();

	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const [isLoading, setIsLoading] = useState(true);

	// Hooks for taxDefaults store values
	const [aftMktTaxRate, setAftMktTaxRate] = useState(null);
	const [servContractTaxRate, setsServContractTaxRate] = useState(null);
	const [gapTaxRate, setGapTaxRate] = useState(null);
	const [docFeeTaxRate, setDocFeeTaxRate] = useState(null);
	const [dmvTaxRate, setDmvTaxRate] = useState(null);
	const [dealerSmogTaxRate, setDealerSmogTaxRate] = useState(null);
	const [taxCreditRate, setTaxCreditRate] = useState(null);
	const [taxCreditFixAmount, setTaxCreditFixAmount] = useState(null);
	const [maxTaxCredit, setMaxTaxCredit] = useState(null);
	const [vitRate, setVitRate] = useState(null);
	const [taxRegion, setTaxRegion] = useState(null);
	const [taxType, setTaxType] = useState(null);

	// Obj for creating components
	const ratesObj = [
		{
			name: "aftMktTaxRate",
			value: aftMktTaxRate,
			onChange: setAftMktTaxRate,
			rateLabel: "Aftermarket Tax Rate",
			dropdownLabel: "Tax Aftermarket",
		},
		{
			name: "servContractTaxRate",
			value: servContractTaxRate,
			onChange: setsServContractTaxRate,
			rateLabel: "Service Contract Tax Rate",
			dropdownLabel: "Tax Service Contract",
		},
		{
			name: "gapTaxRate",
			value: gapTaxRate,
			onChange: setGapTaxRate,
			rateLabel: "Gap Insurance Tax Rate",
			dropdownLabel: "Tax Gap Insurance",
		},
		{
			name: "docFeeTaxRate",
			value: docFeeTaxRate,
			onChange: setDocFeeTaxRate,
			rateLabel: "Doc Fee Tax Rate",
			dropdownLabel: "Tax Doc Fee",
		},
		{
			name: "dmvTaxRate",
			value: dmvTaxRate,
			onChange: setDmvTaxRate,
			rateLabel: "DMV Tax Rate",
			dropdownLabel: "Tax DMV Fees",
		},
		{
			name: "dealerSmogTaxRate",
			value: dealerSmogTaxRate,
			onChange: setDealerSmogTaxRate,
			rateLabel: "Dealer Smog Tax Rate",
			dropdownLabel: "Tax Dealer Smog",
		},
	];

	const updateStoreValue = (key, val) => {
		let oldSettings = { ...taxDefaults };
		oldSettings[key] = val;
		console.log(oldSettings);
		const settings = {
			profitSettings,
			taxDefaults: oldSettings,
			commissionSettings,
		};

		//update the deal settings obj and save the deal
		editDealSettings(settings);
	};

	const updateStores = () => {
		let oldSettings = { ...taxDefaults };
		oldSettings.aftMktTaxRate = aftMktTaxRate;
		oldSettings.servContractTaxRate = servContractTaxRate;
		oldSettings.gapTaxRate = gapTaxRate;
		oldSettings.docFeeTaxRate = docFeeTaxRate;
		oldSettings.dmvTaxRate = dmvTaxRate;
		oldSettings.dealerSmogTaxRate = dealerSmogTaxRate;
		oldSettings.taxRegion = parseInt(taxRegion);
		oldSettings.taxCreditRate = parseFloat(taxCreditRate);
		oldSettings.taxCreditFixAmount = parseFloat(taxCreditFixAmount);
		oldSettings.maxTaxCredit = parseFloat(maxTaxCredit);
		oldSettings.vitRate = parseFloat(vitRate);
		oldSettings.taxType = parseInt(taxType);
		oldSettings.taxRate = parseFloat(taxRate);
		oldSettings.countyRecNum = countyRecNum;

		console.log(oldSettings);

		const settings = {
			profitSettings,
			taxDefaults: oldSettings,
			commissionSettings,
		};

		//update the deal settings obj and save the deal
		editDealSettings(settings);

		return settings;
	};

	const updateLocalState = useCallback(() => {
		const {
			taxRegion: globalTaxRegion,
			taxType: globalTaxType,
			aftMktTaxRate: globalAftMktTaxRate,
			servContractTaxRate: globalServContractTaxRate,
			gapTaxRate: globalGapTaxRate,
			docFeeTaxRate: globalDocFeeTaxRate,
			dmvTaxRate: globalDmvTaxRate,
			dealerSmogTaxRate: globalDealerSmogTaxRate,
			taxCreditRate: globalTaxCreditRate,
			taxCreditFixAmount: globalTaxCreditFixAmount,
			maxTaxCredit: globalMaxTaxCredit,
			vitRate: globalVitRate,
		} = taxDefaults;

		setTaxRegion(globalTaxRegion);
		setTaxType(globalTaxType);
		//value(%) of specific rate
		setAftMktTaxRate(globalAftMktTaxRate);
		setsServContractTaxRate(globalServContractTaxRate);
		setGapTaxRate(globalGapTaxRate);
		setDocFeeTaxRate(globalDocFeeTaxRate);
		setDmvTaxRate(globalDmvTaxRate);
		setDealerSmogTaxRate(globalDealerSmogTaxRate);
		setTaxCreditRate(globalTaxCreditRate);
		setTaxCreditFixAmount(globalTaxCreditFixAmount);
		setMaxTaxCredit(globalMaxTaxCredit);
		setVitRate(globalVitRate);
	}, [taxDefaults]);

	const save = () => {
		const settings = updateStores();
		// zustand doesnt update state quick enough so manually add it
		const deal = getDeal();
		deal.deal.dealSettings = JSON.stringify(settings);
		console.log(settings.taxDefaults);
		saveDeal(deal).then(
			(response) => {
				setDeal(response);
				saveSuccessAlert();
				console.log("Deal saved with ID: " + response.data.content.deal.ID);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	const TaxDefaultSaveButton = () => {
		return (
			<Button
				readOnly={type !== DealStatus.PENDING}
				color="success"
				onClick={() => {
					save();
				}}
				size="md"
			>
				<i className="nc-icon nc-cloud-download-93"></i> Save
			</Button>
		);
	};

	const getData = () => {
		getSettings(dealerID, locationID).then(
			(res) => {
				console.log(res);
				setTaxRegion(res.data.content.taxDefaults.taxRegion);
				editCountyRecNum(countyRecNum);
				setIsLoading(false);
				setTaxCreditRate(res.data.content.taxDefaults.taxCreditRate);
				setTaxCreditFixAmount(res.data.content.taxDefaults.taxCreditFixAmount);
				setMaxTaxCredit(res.data.content.taxDefaults.maxTaxCredit);
				setVitRate(res.data.content.taxDefaults.vitRate);
				setTaxType(res.data.content.taxDefaults.taxType);

				//save();
			},
			(err) => {
				console.log(err);

				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	const UseDefaults = () => {
		return (
			<>
				<Button
					className="pull-right"
					readOnly={type !== DealStatus.PENDING}
					color="primary"
					onClick={() => {
						getData();
					}}
					size="md"
				>
					<i className="nc-icon nc-check-2"></i> Use Defaults
				</Button>
			</>
		);
	};

	const taxComponents = ratesObj.map((obj) => {
		return <TaxComponent obj={obj} updateStore={updateStoreValue} />;
	});

	const regionDropdown = (
		<>
			<Col md="3">
				<Label>Tax Type</Label>
				<Input
					className="bold"
					name="selectTaxType"
					type="select"
					value={taxType}
					onChange={(e) => {
						console.log(e.target.value);
						setTaxType(parseInt(e.target.value));
						editTaxType(parseInt(e.target.value));
						updateStoreValue("taxType", parseInt(e.target.value));
					}}
				>
					<option value={TaxType[0].value}>{TaxType[0].label}</option>
					<option value={TaxType[1].value}>{TaxType[1].label}</option>
				</Input>
			</Col>
			<Col md="3">
				<Label>Select Tax Region</Label>
				<Input
					className="bold"
					name="selectTaxRegion"
					type="select"
					value={taxRegion}
					onChange={(e) => {
						setTaxRegion(parseInt(e.target.value));
						updateStoreValue("taxRegion", parseInt(e.target.value));
					}}
				>
					<option value={TaxRegion[0].value}>{TaxRegion[0].label}</option>
					<option value={TaxRegion[1].value}>{TaxRegion[1].label}</option>
					<option value={TaxRegion[2].value}>{TaxRegion[2].label}</option>
				</Input>
			</Col>

			<Col md="3">
				<TaxCountySelector />
			</Col>
			<Col md="3">
				<Label>Tax Rate (%)</Label>
				<NumberFormat
					className="bold"
					name="settingsTaxRate"
					value={taxRate * 100}
					readOnly={
						// eslint-disable-next-line
						taxRegion === 2 && countyRecNum === null ? false : true
					}
					decimalScale={4}
					fixedDecimalScale={true}
					onFocus={(e) => e.target.select()}
					isNumericString={true}
					customInput={Input}
					onValueChange={(values) => {
						if (values.value === "") {
							editTaxRate(0);
							updateStoreValue("taxRate", 0);
						} else {
							editTaxRate(values.floatValue / 100);
							updateStoreValue("taxRate", values.floatValue / 100);
						}
						if (values.value !== taxRate * 100) {
							console.log(countyRecNum);
						}
					}}
				/>
			</Col>
		</>
	);

	const taxCredit = (
		<>
			<Col md="3">
				<FormGroup>
					<Label>Trade In Tax Credit Rate (%)</Label>
					<NumberFormat
						className="bold"
						name="taxCreditRate"
						value={taxCreditRate * 100}
						decimalScale={4}
						fixedDecimalScale={true}
						onFocus={(e) => e.target.select()}
						isNumericString={true}
						customInput={Input}
						onValueChange={(values) => {
							if (values.value === "") {
								setTaxCreditRate(0);
								updateStoreValue("taxCreditRate", 0);
							} else {
								setTaxCreditRate(values.floatValue / 100);
								updateStoreValue("taxCreditRate", values.floatValue / 100);
							}
						}}
					/>
				</FormGroup>
			</Col>
			<Label>+</Label>
			<Col md="3">
				<FormGroup>
					<Label>Tax Credit Fixed Amount</Label>
					<NumberFormat
						className="bold"
						value={taxCreditFixAmount}
						name="taxCreditFixAmount"
						thousandSeparator={true}
						decimalScale={0}
						prefix={"$"}
						fixedDecimalScale={true}
						onFocus={(e) => e.target.select()}
						isNumericString={true}
						customInput={Input}
						onValueChange={(values) => {
							if (values.value === "") {
								setTaxCreditFixAmount(0);
								updateStoreValue("taxCreditFixAmount", 0);
							} else {
								setTaxCreditFixAmount(values.floatValue);
								updateStoreValue("taxCreditFixAmount", values.floatValue);
							}
						}}
					/>
				</FormGroup>
			</Col>
			<Col md="3">
				<FormGroup>
					<Label>Maximum Tax Credit</Label>
					<NumberFormat
						className="bold"
						value={maxTaxCredit}
						name="maxTaxCredit"
						thousandSeparator={true}
						decimalScale={0}
						prefix={"$"}
						fixedDecimalScale={true}
						onFocus={(e) => e.target.select()}
						isNumericString={true}
						customInput={Input}
						onValueChange={(values) => {
							if (values.value === "") {
								setMaxTaxCredit(0);
								updateStoreValue("maxTaxCredit", 0);
							} else {
								setMaxTaxCredit(values.floatValue);
								updateStoreValue("maxTaxCredit", values.floatValue);
							}
						}}
					/>
				</FormGroup>
			</Col>
		</>
	);
	const otherTax = (
		<>
			<Col md="3">
				<FormGroup>
					<Label>Vehicle Inventory TaxRate (VIT)</Label>
					<NumberFormat
						className="bold"
						name="vitRate"
						value={vitRate}
						decimalScale={8}
						fixedDecimalScale={true}
						onFocus={(e) => e.target.select()}
						isNumericString={true}
						customInput={Input}
						onValueChange={(values) => {
							if (values.value === "") {
								setVitRate(0);
								updateStoreValue("vitRate", 0);
							} else {
								setVitRate(values.floatValue);
								updateStoreValue("vitRate", values.floatValue);
							}
						}}
					/>
				</FormGroup>
			</Col>
		</>
	);

	useEffect(() => {
		updateLocalState();
		// eslint-disable-next-line
	}, [JSON.stringify(taxDefaults)]);

	return (
		<div onKeyDown={tabOnEnter}>
			{isLoading && (
				<Form>
					<Row className="mx-0 pb-2">
						<Col>
							<TaxDefaultSaveButton />
						</Col>
						<Col>
							<UseDefaults />
						</Col>
					</Row>
					<Card>
						<CardHeader>
							<CardTitle tag="h3">Tax Settings</CardTitle>
						</CardHeader>
						<CardBody>
							<Col>
								<Row className="mx-0">{regionDropdown}</Row>
								<Row className="mx-0">{taxComponents}</Row>
								<Row className="mx-0">{taxCredit}</Row>
								<Row className="mx-0">{otherTax}</Row>
							</Col>
						</CardBody>
					</Card>
				</Form>
			)}
		</div>
	);
};

export default TaxDefaults;
