/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)

* Coded by DealerClick Software **RoarinLion))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import { Button, Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import shallow from "zustand/shallow";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider } from "react-hook-form";

import useCurrentVehicleStore from "../../../store/InventoryStore";
import InventoryCostsTable from "../../../component/dc/table/InventoryCostsTable";
import { DealStatus } from "../../../Constants";
import ChildrenModal from "../../../component/dc/modal/ChildrenModal";
import InventoryCostForm from "./InventoryCostForm";
import InventoryTotalCostDisplay from "./InventoryTotalCostDisplay";
import InventoryFlooringDetail from "./InventoryFlooringDetail";

//functional component to only handle props and change values onchange => save changes to api (not done yet)
const InventoryCostsTab = () => {
	// Vehicle store
	const { costHist, status } = useCurrentVehicleStore(
		(state) => ({
			costHist: state.costHist,
			status: state.status,
		}),
		shallow
	);

	const [showAddCost, setShowAddCost] = useState(false);
	const [currentIndex, setCurrentIndex] = useState(costHist.length);

	// Toggles the add cost modal
	const toggleAddCost = () => setShowAddCost((prev) => !prev);

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
	});

	const schema = yup.object().shape({
		cost: yup.string().required(),
	});
	const schema2 = yup.object().shape({});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	const methods2 = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema2),
	});
	//	const descriptionlist = getDescriptionlist(locationID);
	return (
		<div className="">
			<FormProvider {...methods2}>
				<InventoryTotalCostDisplay />

				<Button
					className="btn btn-secondary btn-sm"
					onClick={toggleAddCost}
					readOnly={status === DealStatus.SOLD || status === DealStatus.DELETED}
				>
					<i className="nc-icon nc-simple-add" />
					Add Cost
				</Button>
				<Card>
					<CardHeader>
						<CardTitle>
							<h3>Costs</h3>
						</CardTitle>
					</CardHeader>
					<CardBody>
						<InventoryCostsTable
							setCurrentIndex={setCurrentIndex}
							toggle={toggleAddCost}
						/>
					</CardBody>
				</Card>
			</FormProvider>
			<InventoryFlooringDetail />
			<FormProvider {...methods}>
				<ChildrenModal
					modal={showAddCost}
					toggle={toggleAddCost}
					modalTitle="Vehicle Cost"
				>
					<InventoryCostForm
						currentIndex={currentIndex}
						setCurrentIndex={setCurrentIndex}
						toggle={toggleAddCost}
					/>
				</ChildrenModal>
			</FormProvider>
		</div>
	);
};
export default InventoryCostsTab;
