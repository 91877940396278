import React from "react";
import { CardHeader, CardTitle, Form, Row } from "reactstrap";
// import { calculateTaxes } from "helperFunction/deal/SaleFunctions";
import useCurrentSaleStore from "../../../../store/SaleStore";
import LabelAndInput from "./LabelAndInput";
import DMVFee from "./saleComponents/DMVFee";
import NetTrade from "./saleComponents/NetTrade";
import SellerFees from "./saleComponents/SellerFees";
import Taxes from "./saleComponents/Taxes";
import InsuranceAndGAP from "./saleComponents/InsuranceAndGAP";
import ServiceContract from "./saleComponents/ServiceContract";
import Aftermarket from "./saleComponents/Aftermarket";
import DownPayment from "./saleComponents/DownPayment";
import DeferredPickup from "./saleComponents/DeferredPickup";
import { DealStatus } from "Constants";
// import useCalculateSales from "helperFunction/deal/useCalculateSales";
import useCurrentDealStore from "store/DealStore";
//import TaxDetail from "component/dc/deal/dealDetails/TaxDetail";
import TaxCountySelector from "../statusBar/TaxCountySelector";
import tabOnEnter from "helperFunction/tabOnEnter";
import ContractInfo from "component/dc/deal/dealDetails/ContractInfoDetail";
import shallow from "zustand/shallow";
const Sale = () => {
	const {
		price,
		manufacturerRebate,
		editPrice,
		editManufacturerRebate,
	} = useCurrentSaleStore(
		(state) => ({
			price: state.price,
			manufacturerRebate: state.manufacturerRebate,
			editPrice: state.editPrice,
			editManufacturerRebate: state.editManufacturerRebate,
		}),
		shallow
	);

	const { type, taxRegion } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
			taxRegion: state?.deal?.dealSettings?.taxDefaults?.taxRegion,
		}),
		shallow
	);

	// useCalculateSales();
	return (
		<div>
			<CardHeader>
				<CardTitle tag="h3">{type} Deal</CardTitle>
			</CardHeader>
			<div className="card-body pb-3" onKeyDown={tabOnEnter}>
				<Form className="deal-sale">
					<Row>
						<div className="col-xl-6 pl-2 pr-0">
							<LabelAndInput
								name="dealPrice"
								disabled={type !== DealStatus.PENDING}
								value={price}
								label="Price"
								onChange={editPrice}
								btnClass="w-100 btn-md btn-primary  btn"
								buttonLabel="true"
							/>
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<DMVFee />
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<SellerFees />
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<InsuranceAndGAP />
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<ServiceContract />
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<Aftermarket />
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<ContractInfo />
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<NetTrade />
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<DownPayment />
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<LabelAndInput
								name="dealMFR"
								className="text-danger bold"
								disabled={type !== DealStatus.PENDING}
								buttonLabel={true}
								value={manufacturerRebate}
								label="MFR Rebate"
								onChange={editManufacturerRebate}
								sign="-"
								btnClass="w-100 btn-md btn-primary btn px-0"
							/>
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<DeferredPickup />
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<Taxes />
						</div>
						<div className="col-12 pl-2 pr-0">
							<hr className="my-2" />
							<TaxCountySelector localTaxRegion={taxRegion} />
						</div>
					</Row>
				</Form>
			</div>
		</div>
	);
};

export default Sale;
