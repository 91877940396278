import React, { useState, useEffect } from "react";
import { CardBody, Card, Row, Col } from "reactstrap";
import UAClogo from "../../assets/img/UACC.svg";
import WelcomeSection from "./welcomeSection";
import { getPartnerSend } from "api/dealerIntegrationsApi";
import { useFromUser } from "store/LocalStorageHelper";
import { showApiError } from "helperFunction/ApiErrorHandler";
import {
	retrieveFailAlert,
	dealerBondAlert,
} from "component/dc/modal/SweetAlerts";
import FlooringRatioTable from "../../component/dc/table/FlooringRatioTable";
import { getDealerInsuranceInfo } from "../../api/dealerIntegrationsApi";
import dayjs from "dayjs";
import SignUps from "../settings/settingsTabs/signUp/SignUps";
import useCurrentDealerLinkStore from "../../store/DealerLinkStore";
import shallow from "zustand/shallow";

const DashboardSection = () => {
	const { uacdealerID } = useCurrentDealerLinkStore((state) => state, shallow);
	const locationID = useFromUser("locationID");
	const [uacc, setUacc] = useState(0);

	useEffect(() => {
		getDealerInsuranceInfo(locationID).then((res) => {
			console.log(res.data.content);

			const dlrInfo = res.data.content;
			// setDbiExpDate(dlrInfo.dbiExpDate);
			// setBuiExpDate(dlrInfo.buiExpDate);
			console.log(dlrInfo.dbiExpDate);
			if (
				dlrInfo.dbiExpDate === null ||
				dlrInfo.dbiExpDate === "" ||
				dayjs(dlrInfo.dbiExpDate).isValid === false ||
				dlrInfo.buiExpDate === null ||
				dlrInfo.buiExpDate === "" ||
				dayjs(dlrInfo.buiExpDate).isValid === false
			)
				dealerBondAlert();
		});
		getPartnerSend(locationID).then(
			(res) => {
				setUacc(res.data.content.useUACC);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
		// eslint-disable-next-line
	}, [locationID]);
	return (
		<div>
			<WelcomeSection />
			<FlooringRatioTable />
			<Row>
				<Col>
					<h3 className="mb-3">Partners</h3>
					<Row>
						{uacc === 1 && uacdealerID.length > 0 && (
							<Col lg="2" md="2" sm="2">
								<Card
									className="mx-2"
									onClick={(e) => {
										e.preventDefault();
										window.open("https://thefastlaneuat.unitedautocredit.net/");
									}}
								>
									<CardBody className="text-center">
										<img src={UAClogo} alt="UAC Logo" width="" height="" />
										<br />
										<div className="btn btn-info btn-sm">Go to Fast Lane</div>
									</CardBody>
								</Card>
							</Col>
						)}
					</Row>
					<SignUps />
				</Col>
			</Row>
		</div>
	);
};
export default DashboardSection;
