import ChildrenModal from "component/dc/modal/ChildrenModal";
import React, { useState } from "react";
import { Button, CardText, Col, Row, Form, Label } from "reactstrap";
import shallow from "zustand/shallow";
import useCurrentRecapStore from "../../../../store/RecapStore";
import useCurrentSaleStore from "../../../../store/SaleStore";
import {
	CurrencyInput,
	PercentInput,
} from "../../../../view/formValidation/InputElement";

const ReserveCalculator = ({ disabled = false }) => {
	const [open, setOpen] = useState(false);

	const toggle = () => {
		setOpen(!open);
	};
	const { reserve, editReserve } = useCurrentRecapStore(
		(state) => state,
		shallow
	);

	return (
		<Row className="p-2">
			<Col md="6">
				<CardText>
					<Button
						className="active px-1 btn-round btn-md btn btn-outline-secondary w-100 my-0"
						onClick={toggle}
					>
						Dealer Reserve
					</Button>
				</CardText>
				<ChildrenModal
					modal={open}
					toggle={toggle}
					size="s"
					modalTitle="Dealer Reserve"
					children={<ReserveCalculatorModal />}
				/>
			</Col>
			<CurrencyInput
				disabled={disabled}
				readOnly={disabled}
				value={reserve}
				onChange={editReserve}
				disableValidation
				wholeRow
			/>
		</Row>
	);
};

export default ReserveCalculator;

const ReserveCalculatorModal = () => {
	const {
		reserve,
		reservePercent,
		editReserve,
		editReservePercent,
	} = useCurrentRecapStore((state) => state, shallow);
	const { amtFinanced } = useCurrentSaleStore(
		(state) => ({
			amtFinanced: state.amtFinanced,
		}),
		shallow
	);

	return (
		<Form>
			<Row>
				<Col md="6">
					<label>Amount Financed</label>
				</Col>
				<CurrencyInput
					value={amtFinanced}
					readOnly
					disableValidation
					wholeRow
				/>
				<Col md="6">
					<Label>Reserve %</Label>
				</Col>
				<PercentInput
					value={reservePercent}
					onChange={editReservePercent}
					disableValidation
					wholeRow
				/>
				<Col md="6">
					<Label>Reserve Amount</Label>
				</Col>
				<CurrencyInput
					value={reserve}
					onChange={editReserve}
					readOnly
					disableValidation
					wholeRow
				/>
			</Row>
		</Form>
	);
};
