import React, { useEffect, useState } from "react";
import { Button, Table } from "reactstrap";
import {
	failAlert,
	loading,
	retrieveFailAlert,
	successAlertCustom,
} from "../../../../modal/SweetAlerts";
import {
	getTerminals,
	processTerminalTransaction,
} from "../../../../../../api/dealerIntegrationsApi";
import { useFromUser } from "../../../../../../store/LocalStorageHelper";
import { CurrencyInput } from "../../../../../../view/formValidation/InputElement";
import { showApiError } from "../../../../../../helperFunction/ApiErrorHandler";
import Swal from "sweetalert2";

const ProcessTerminal = ({ setTerminalOpen }) => {
	const [terminals, setTerminals] = useState([]);
	const [amount, setAmount] = useState(0.0);
	const locationID = useFromUser("locationID");

	const handleSubmit = (terminalId) => {
		loading("Processing...");
		// call our backend to process transaction
		const body = {
			amount: amount * 100,
			terminalId,
		};
		if (amount <= 0) {
			failAlert("Amount must be greater than 0");
			return;
		}
		processTerminalTransaction(locationID, body).then(
			(res) => {
				Swal.close();
				successAlertCustom("Successfully processed transaction!");
				setTerminalOpen(false);
			},
			(err) => {
				Swal.close();
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}
			}
		);
	};

	const tableText = ["#", "Terminal", "Status", ""];

	const tableHead = tableText.map((key, index) => <th key={index}>{key}</th>);

	const rows = terminals.map((obj, index) => (
		<tr key={index}>
			<th scope="row">{index + 1}</th>
			<td> {obj.description}</td>
			<td>{obj.status}</td>
			<td>
				<Button
					onClick={() => {
						handleSubmit(obj.terminalId);
					}}
				>
					Submit
				</Button>
			</td>
		</tr>
	));

	useEffect(() => {
		getTerminals(locationID).then(
			(res) => {
				setTerminals(res.data.content);
			},
			(err) => {
				console.log(err);
				setTerminalOpen(false);
				retrieveFailAlert(err.response.data.message);
			}
		);

		// eslint-disable-next-line
	}, []);

	return (
		<>
			<CurrencyInput
				value={amount}
				onChange={setAmount}
				wholeRow
				label="Amount"
				disableValidation
				allowNegative={false}
			/>
			<Table striped bordered hover>
				<thead>
					<tr>{tableHead}</tr>
				</thead>
				<tbody>{rows}</tbody>
			</Table>
		</>
	);
};

export default ProcessTerminal;
