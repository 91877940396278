import React from "react";
import { CardText, Row, Col, Button } from "reactstrap";
import useCurrentSaleStore from "store/SaleStore";
import {
	calculateFinalDueDate,
	calculatePayment,
	calculateFinalPayment,
	calculateFinanceCharge,
} from "helperFunction/deal/SaleFunctions";
import dayjs from "dayjs";
import NumberFormat from "react-number-format";
import useCurrentDealStore from "store/DealStore";
import { DealStatus } from "Constants";
import shallow from "zustand/shallow";

const PaymentOption = (props) => {
	const {
		loanPeriod,
		apr,
		loanDate,
		firstDueDate,
		amtFinanced,
		editTerm,
		bankFee,
		monthlyTax,
	} = useCurrentSaleStore(
		(state) => ({
			loanPeriod: state.loanPeriod,
			apr: state.apr,
			loanDate: state.loanDate,
			firstDueDate: state.firstDueDate,
			amtFinanced: state.amtFinanced,
			editTerm: state.editTerm,
			bankFee: state.bankFee,
			deferredTax: state.deferredTax,
			monthlyTax: monthlyTax,
		}),
		shallow
	);

	// Deal store
	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);

	const generateTerm = (rowNum) => {
		let term = null;
		// initialize to a year then add the product of rowNum and 6 month period
		switch (loanPeriod) {
			// Weekly - 52 times a year
			case 0:
				term = 0;
				term = term + rowNum * 52;
				break;

			// Bi-Weekly - 26 times a year
			case 1:
				term = 0;
				term = term + rowNum * 26;
				break;

			// Semi-Monthly - 24 times a year
			case 2:
				term = 0;
				term = term + rowNum * 24;
				break;

			// Monthly - 12 times a year
			case 3:
				term = 0;
				term = term + rowNum * 12;
				break;

			// Annually - 1 time a year
			case 4:
				term = 0;
				term = rowNum;
				break;

			// Cash - N/A
			case 5:
				term = 1;
				break;

			// Bi-Annually - 2 times a year
			case 6:
				term = 0;
				term = term + rowNum * 2;
				break;

			default:
				break;
		}
		return term;
	};

	const term = generateTerm(props.rowNum);
	const finalDueDate = dayjs(
		calculateFinalDueDate(loanPeriod, term, firstDueDate)
	).format("MM/DD/YYYY");
	const payment = calculatePayment(
		apr,
		loanPeriod,
		term,
		amtFinanced,
		loanDate,
		firstDueDate,
		bankFee
	);
	const finalPayment = calculateFinalPayment(apr, payment, term, amtFinanced);
	const financeCharge = calculateFinanceCharge(
		amtFinanced,
		payment,
		finalPayment,
		term
	);

	return (
		<tr>
			<Row className="py-1 px-2 mx-0">
				<Col sm="7">
					<Row>
						<Col xs="3">
							<CardText>{term}</CardText>
						</Col>
						<Col xs="4">
							<CardText>
								<NumberFormat
									value={payment}
									thousandSeparator={true}
									decimalScale={2}
									fixedDecimalScale={true}
									prefix={"$"}
									isNumericString={true}
									displayType="text"
								/>
							</CardText>
						</Col>
						<Col xs="5">
							<CardText>
								<NumberFormat
									value={financeCharge}
									thousandSeparator={true}
									decimalScale={2}
									fixedDecimalScale={true}
									prefix={"$"}
									isNumericString={true}
									displayType="text"
								/>
							</CardText>
						</Col>
					</Row>
				</Col>
				<Col sm="5">
					<Row>
						<Col xs="8">
							<CardText>{finalDueDate}</CardText>
						</Col>
						<Col xs="4">
							{" "}
							<Button
								readOnly={type !== DealStatus.PENDING}
								size="sm"
								onClick={() => {
									editTerm(term);
									//calculateCompoundInterest();
									//storeCalculateFinalDueDate();
									props.toggle();
								}}
							>
								<i className="nc-icon nc-tap-01" /> Select
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</tr>
	);
};

export default PaymentOption;
