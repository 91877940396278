import React, { useEffect } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import shallow from "zustand/shallow";

import { DealStatus } from "../../../../Constants";
import useCurrentDealStore from "../../../../store/DealStore";
import InventoryListModal from "../../../../component/dc/deal/inventory/InventoryListModal";
import Camera from "../../../../assets/img/imgPlaceholder.png";
import dayjs from "dayjs";
//import NumberFormat from "react-number-format";

const DealInventoryHeader = () => {
	const { vehicle, type } = useCurrentDealStore(
		(state) => ({
			vehicle: state.vehicle,
			type: state.deal.type,
		}),
		shallow
	);
	console.log(vehicle);
	//const [setAge] = useState();
	useEffect(() => {
		if (vehicle !== null && vehicle?.dateIn !== null) {
			console.log(vehicle?.dateIn);
			console.log(vehicle?.dateOut);
			const today = dayjs();
			//			const start = dayjs(vehicle.dateIn).format("MM/DD/YYYY");
			console.log(vehicle.dateOut);
			const end =
				vehicle?.dateOut === null ||
				vehicle?.dateOut === "" ||
				vehicle?.dateOut <= vehicle?.dateIn
					? today
					: dayjs(vehicle.dateOut);
			console.log(end.format("MM/DD/YYYY"));
			//			const diff = end.diff(start, "day");
			//			setAge(diff);
			//			if (vehicle.dateIn === vehicle.dateOut) setAge(1);
		}
		//setAge(100);
		// eslint-disable-next-line
	}, []);

	return (
		<div>
			{(type === DealStatus.PENDING && vehicle?.inventoryID == null && (
				<Row>
					<Col className="px-0">
						<Col className="pb-3 pt-2 px-0">
							<h3 className="my-0 ">Vehicle</h3>
						</Col>
						<Col className="pb-1 px-0 text-center">
							<InventoryListModal modalTitle="Select Contact" />
						</Col>
					</Col>
				</Row>
			)) ||
				(vehicle?.inventoryID != null && (
					<div className="deal-vehicle">
						<div className="mb-2 text-center">
							<h3 className="mb-3">
								{vehicle.year + " " + vehicle.make + " " + vehicle.model}
							</h3>
							<img
								className="dealImg mb-2 zoom"
								src={`${vehicle.thumbnail}?lastmod=${Date.now()}`}
								onError={(e) => {
									e.target.onerror = null;
									e.target.src = Camera;
								}}
								alt="Car"
							/>
							{/* {
								<Row>
									<Col sm="12">
										<Label>
											<NumberFormat
												value={vehicle.odometerIn}
												thousandSeparator={true}
												fixedDecimalScale={true}
												isNumericString={true}
												displayType="text"
											/>{" "}
											Miles
										</Label>
									</Col>

									<Col sm="12">
										<Label>{age} Days</Label>
									</Col>
								</Row>
							} */}
							<Row className="text-center">
								<Col sm="12" md="6" lg="6" xl="6">
									<Label>
										Stock
										<Input disabled value={vehicle.stockNo} className="bold" />
									</Label>
								</Col>
								<Col sm="12" md="6" lg="6" xl="6">
									<Label>
										VIN
										<Input disabled value={vehicle.vin} className="bold" />
									</Label>
								</Col>
							</Row>
						</div>
						<Col md="12" className="text-center mt-half">
							<InventoryListModal modalTitle="Select Contact" />
						</Col>
					</div>
				))}

			{type !== DealStatus.PENDING && vehicle?.inventoryID == null && (
				<p className="d-flex text-danger">No Vehicle was added to this deal</p>
			)}
		</div>
	);
};

export default DealInventoryHeader;
