import React from "react";
import { Col, Row } from "reactstrap";
import AutoCheckButton from "component/dc/inventory/AutocheckButton";

import CarfaxReportButton from "component/dc/inventory/carfax/CarfaxReportButton";
import logo from "../../../../src/assets/img/autodatadirect.jpg"; //"C:/JavaProjects/dc2/src/api/assets/img/AutoCheck-logo.png";

import { Input } from "reactstrap";

const InventoryHistoryTab = () => {
	// Form Names

	return (
		<div className="text-center">
			<Row>
				<Col>
					<CarfaxReportButton />
				</Col>
				<Col>
					<AutoCheckButton />
				</Col>

				<Col>
					<div>
						<Input
							type="image"
							onClick={() => {
								window.open(
									"https://login.add123.com/#/?bypass_when_logged_in=true"
								);
							}}
							src={logo}
							alt="AutoData"
							style={{ height: "200px", objectFit: "contain" }}
							className="historyButtons zoom card p-2"
						/>
					</div>
				</Col>
				<Col></Col>
			</Row>
		</div>
	);
};

export default InventoryHistoryTab;
