import React, { useState, useEffect } from "react";
import { Button, Form } from "reactstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider } from "react-hook-form";
import useCurrentDealStore from "../../../../../../store/DealStore";
import { useFromUser } from "../../../../../../store/LocalStorageHelper";
import { saveSuccessAlert } from "component/dc/modal/SweetAlerts";
import InputElement, {
	CurrencyInputElement,
	SelectorElement,
} from "../../../../../../view/formValidation/InputElement";
import InputWrapper from "../../../../../../view/formValidation/InputWrapper";
import { DealStatus, paymentMethod } from "../../../../../../Constants";
import shallow from "zustand/shallow";
import dayjs from "dayjs";

export const MoneyDownForm = ({ currentIndex, setCurrentIndex, toggle }) => {
	// Deal store
	const {
		downPayments,
		editDownPayments,
		type,
		calculate,
	} = useCurrentDealStore(
		(state) => ({
			downPayments: state.downPayments,
			editDownPayments: state.editDownPayments,
			type: state.deal.type,
			calculate: state.calculate,
		}),
		shallow
	);

	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const [receiptNo, setReceiptNo] = useState("");
	const [datePaid, setDatePaid] = useState("");
	const [amtPaid, setAmtPaid] = useState(0);
	const [checkNo, setCheckNo] = useState("");
	const [method, setMethod] = useState("");
	const [pmtType, setpmtType] = useState("");
	const [status, setStatus] = useState("0");

	const [statusReason, setStatusReason] = useState("");
	const [note, setNote] = useState("");
	const [description, setDescription] = useState("");
	const [exportedToQB, setExportedToQB] = useState("");
	const [isExportToQB, setIsExportToQB] = useState(0);
	const [appVersion, setAppVersion] = useState("");
	const [hideCheck, sethideCheck] = useState(true);
	const [buttonText, setButtonText] = useState("Add");

	const changeText = (text) => setButtonText(text);

	const clearFields = () => {
		changeText("Add Fee");
		setReceiptNo("");
		setDatePaid("");
		setAmtPaid("");
		setCheckNo("");
		setMethod("");
		setpmtType("");
		setStatusReason("");
		setStatus("");
		setNote("");
		setDescription("");
		setExportedToQB("");
		setIsExportToQB(0);
		setAppVersion("");
		setCurrentIndex(downPayments.length);
	};

	const addFee = () => {
		const getID = () => {
			if (downPayments[currentIndex] === undefined) {
				return null;
			} else if (downPayments[currentIndex].id === undefined) {
				return null;
			} else {
				return downPayments[currentIndex].id;
			}
		};
		const id = getID();
		const newDown = {
			id,
			receiptNo,
			datePaid,
			amtPaid: parseFloat(amtPaid),
			checkNo,
			method,
			pmtMode: 8,
			pmtType,
			status,
			statusReason,
			note,
			description,
			exportedToQB,
			isExportToQB,
			createOn: dayjs().format("YYYY-MM-DD"),
			appVersion,
			locationID,
			dealerID,
			currentIndex,
		};
		console.log(newDown);
		console.log("Current index is: " + currentIndex);

		const downList = () => {
			let obj = downPayments;
			obj[currentIndex] = newDown;
			return obj;
		};
		const newDownObj = downList();
		editDownPayments(newDownObj);
		clearFields();
		saveSuccessAlert();
		calculate();
		toggle();
	};

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
			length: "Required format is YYYY/MM/DD",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({
		recieptNo: yup.string().max(50).min(1).required(),
		description: yup.string().required().max(50),
		pmtType: yup.string().required().max(50),
		pmtMode: yup.string().required().max(50),
		note: yup.string().required().max(50).min(1).nullable(),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});
	useEffect(() => {
		if (currentIndex < downPayments.length) {
			setReceiptNo(downPayments[currentIndex]?.receiptNo);
			setDatePaid(downPayments[currentIndex]?.datePaid);
			setAmtPaid(downPayments[currentIndex]?.amtPaid);
			setCheckNo(downPayments[currentIndex]?.checkNo);
			setMethod(downPayments[currentIndex]?.method);
			setpmtType(downPayments[currentIndex]?.pmtType);
			setNote(downPayments[currentIndex]?.note);
			setDescription(downPayments[currentIndex]?.description);
			setExportedToQB(downPayments[currentIndex]?.exportedToQB);
			setIsExportToQB(downPayments[currentIndex]?.isExportToQB);
			setAppVersion(downPayments[currentIndex]?.appVersion);
			setStatus(downPayments[currentIndex]?.status);
			changeText("Save Fee");
		}

		return clearFields;
		// eslint-disable-next-line
	}, [currentIndex]);

	const handleMethod = (e) => {
		setMethod(e);
		if (
			e === "check" ||
			e === "cashierCheck" ||
			e === "echeck" ||
			e === "other"
		)
			sethideCheck(false);
		else sethideCheck(true);
	};
	const getRandomId = (min = 1000, max = 500000) => {
		min = Math.ceil(min);
		max = Math.floor(max);
		const num = Math.floor(Math.random() * (max - min + 1)) + min;

		if (receiptNo === null || receiptNo === "") {
			setReceiptNo(num);
		}
	};
	useEffect(() => {
		getRandomId();
		// eslint-disable-next-line
	}, [receiptNo]);

	return (
		<>
			<FormProvider className="" {...methods}>
				<Form>
					<InputWrapper
						formTitle="Add Payment"
						inputComponents={[
							<CurrencyInputElement
								readOnly={type !== DealStatus.PENDING}
								value={amtPaid}
								onChange={setAmtPaid}
								name="amtPaid"
								label="Amount"
								type="number"
							/>,
							<InputElement
								readOnly={type !== DealStatus.PENDING}
								{...methods}
								value={datePaid}
								onChange={(e) => {
									setDatePaid(e.target.value);
								}}
								name="datepaid"
								label="Date Paid"
								type="date"
							/>,

							<SelectorElement
								{...methods}
								name="method"
								label="Methods"
								value={method}
								onChange={(e) => handleMethod(e.target.value)}
								options={paymentMethod}
							/>,

							<InputElement
								readOnly={type !== DealStatus.PENDING}
								{...methods}
								value={receiptNo}
								onChange={(e) => {
									setReceiptNo(e.target.value);
								}}
								name="recieptNo"
								label="Receipt No"
								type="text"
							/>,

							<InputElement
								readOnly={hideCheck}
								{...methods}
								value={checkNo}
								onChange={(e) => {
									setCheckNo(e.target.value);
								}}
								name="checkNo"
								label="Check No"
								type="text"
							/>,

							<InputElement
								readOnly={type !== DealStatus.PENDING}
								{...methods}
								value={description}
								onChange={(e) => {
									setDescription(e.target.value);
								}}
								name="description"
								label="Description"
								type="text"
							/>,
							<InputElement
								readOnly={type !== DealStatus.PENDING}
								{...methods}
								value={note}
								onChange={(e) => {
									setNote(e.target.value);
								}}
								name="note"
								label="Note"
								type="text"
							/>,
						]}
						buttons={
							<div className="d-flex justify-content-center mt-2">
								<Button color="primary" onClick={addFee}>
									{buttonText === "Save Fee" ? (
										<i class="nc-icon nc-check-2"></i>
									) : (
										<i class="nc-icon nc-simple-add"></i>
									)}
									{buttonText}
								</Button>
								<div className="btn btn-link btn-md" onClick={clearFields}>
									Reset
								</div>
							</div>
						}
					/>
				</Form>
			</FormProvider>
		</>
	);
};

export default MoneyDownForm;
