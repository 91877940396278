import getAxiosInstance from "./interceptors/buildReqAndResInstance";

import { userBaseUrl } from "./Constants";
// import { LOCAL } from "./Constants";

const instance = getAxiosInstance({
	baseURL: userBaseUrl + "/integrations/kbb/",
});
// const localInstance = getAxiosInstance({
// 	baseURL: LOCAL + "/kbb/",
// });

export const decodeVin = async (vin) => {
	return await instance.get(`decodeVin/${vin}`);
};
export const getKbbValues = async (body) => {
	return await instance.post(`getKbbValues`, body);
};
