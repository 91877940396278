import { savePartnerLinks } from "../../../../../api/dealerIntegrationsApi";
import { getPartnerLinks } from "../../../../../api/dealerIntegrationsApi";
import { showApiError } from "../../../../../helperFunction/ApiErrorHandler";
import React, { useEffect, useState } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { useFromUser } from "../../../../../store/LocalStorageHelper";
import InputElement from "../../../../../view/formValidation/InputElement";
import InputWrapper from "../../../../../view/formValidation/InputWrapper";
import useCurrentDealerLinkStore from "../../../../../store/DealerLinkStore";
import useBuildDealerLink from "../../../../../helperFunction/BuildDealerLinkObj";
import { retrieveFailAlert } from "../../../../../component/dc/modal/SweetAlerts";
import { saveSuccessAlert } from "../../../../../component/dc/modal/SweetAlerts";
import shallow from "zustand/shallow";

const ThirdPartyCredentials = () => {
	const locationID = useFromUser("locationID");
	const getDealerLink = useBuildDealerLink();
	//fields outside of dealerpartner link
	const [instavinUser, setInstavinUser] = useState("");
	const [instavinPwd, setInstaPwd] = useState("");

	const {
		lotVantageAPIToken,
		googleBaseUser,
		googleBasePwd,
		fairfaxKey,
		fairfaxUser,
		carfaxUser,
		carfaxPwd,
		carfaxAllowPurchase,
		autoCheckSID,
		cbccustomerID,
		cbcuserID,
		cbcpassword,
		pepperPayEmail,
		credit700Pwd,
		credit700User,
		credit700PreUser,
		credit700PrePwd,
		editLotVantageAPIToken,
		editGoogleBaseUser,
		editGoogleBasePwd,
		editFairfaxKey,
		editFairfaxUser,
		editCarfaxUser,
		editCarfaxPwd,
		editAutoCheckSID,
		editCbccustomerID,
		editCbcuserID,
		editCbcpassword,
		editPepperPayEmail,
		editCredit700Pwd,
		editCredit700User,
		editCredit700PreUser,
		editCredit700PrePwd,
		editCarfaxAllowPurchase,
		setDealerLinkFields,
	} = useCurrentDealerLinkStore((state) => state, shallow);

	useEffect(() => {
		getPartnerLinks(locationID).then((res) => {
			const r = res.data.content;
			console.log(r);
			setDealerLinkFields(r.dealerPartnerLink);
			setInstaPwd(r.instavinPwd);
			setInstavinUser(r.instavinUser);
		});
		// eslint-disable-next-line
	}, []);

	const saveInfo = () => {
		const dealerPartnerLink = getDealerLink();

		const dealerPartner = { dealerPartnerLink, instavinUser, instavinPwd };
		savePartnerLinks(dealerPartner).then(
			(res) => {
				console.log(res);
				saveSuccessAlert();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	const handleCheckBox = (e) => {
		if (e === true) {
			editCarfaxAllowPurchase(1);
		} else if (e === false) {
			editCarfaxAllowPurchase(0);
		} else {
			editCarfaxAllowPurchase(0);
		}
	};

	return (
		<>
			<Form>
				<InputWrapper
					formTitle="3rd Party Credentials"
					inputComponents={[
						<InputElement
							disabled
							value={lotVantageAPIToken}
							onChange={(e) => editLotVantageAPIToken(e.target.value)}
							label="Lot Vantange API"
							disableValidation={true}
						/>,
						<InputElement
							disabled
							value={autoCheckSID}
							onChange={(e) => editAutoCheckSID(e.target.value)}
							label="Autocheck SID"
							disableValidation={true}
						/>,
						<InputElement
							disabled
							value={googleBaseUser}
							onChange={(e) => editGoogleBaseUser(e.target.value)}
							label="Google User"
							disableValidation={true}
						/>,
						<InputElement
							disabled
							value={googleBasePwd}
							onChange={(e) => editGoogleBasePwd(e.target.value)}
							label="Google Password"
							disableValidation={true}
						/>,
						<InputElement
							disabled
							value={fairfaxKey}
							onChange={(e) => editFairfaxKey(e.target.value)}
							label="Fairfax Key"
							disableValidation={true}
						/>,
						<InputElement
							disabled
							value={fairfaxUser}
							onChange={(e) => editFairfaxUser(e.target.value)}
							label="FairFax User"
							disableValidation={true}
						/>,
						<InputElement
							disabled
							value={instavinUser}
							onChange={(e) => setInstavinUser(e.target.value)}
							label="Instavin Username"
							disableValidation={true}
						/>,
						<InputElement
							disabled
							value={instavinPwd}
							onChange={(e) => setInstaPwd(e.target.value)}
							label="Instavin Password"
							disableValidation={true}
						/>,
						<InputElement
							disabled
							value={carfaxUser}
							onChange={(e) => editCarfaxUser(e.target.value)}
							label="Carfax User"
							disableValidation={true}
						/>,
						<InputElement
							disabled
							value={carfaxPwd}
							onChange={(e) => editCarfaxPwd(e.target.value)}
							label="Carfax Password"
							disableValidation={true}
						/>,
						<FormGroup className="col-sm-4 ml-3 mt-3">
							<Input
								disabled
								checked={carfaxAllowPurchase}
								onChange={(e) => handleCheckBox(!carfaxAllowPurchase)}
								type="checkbox"
							/>
							<Label>Allow Carfax Auto Purchase</Label>
						</FormGroup>,
						<InputElement
							disabled
							value={cbccustomerID}
							onChange={(e) => editCbccustomerID(e.target.value)}
							label="CBC Customer ID"
							disableValidation={true}
						/>,
						<InputElement
							disabled
							value={cbcuserID}
							onChange={(e) => editCbcuserID(e.target.value)}
							label="CBC User ID"
							disableValidation={true}
						/>,
						<InputElement
							disabled
							value={cbcpassword}
							onChange={(e) => editCbcpassword(e.target.value)}
							label="CBC Password"
							disableValidation={true}
						/>,
						<InputElement
							disabled
							value={pepperPayEmail}
							onChange={(e) => editPepperPayEmail(e.target.value)}
							label="PepperPay Email"
							disableValidation={true}
						/>,
						<InputElement
							disabled
							value={credit700User}
							onChange={(e) => editCredit700User(e.target.value)}
							label="700Credit Username"
							disableValidation={true}
						/>,
						<InputElement
							disabled
							value={credit700Pwd}
							onChange={(e) => editCredit700Pwd(e.target.value)}
							label="700Credit Password"
							disableValidation={true}
						/>,
						<InputElement
							disabled
							value={credit700PreUser}
							onChange={(e) => editCredit700PreUser(e.target.value)}
							label="700Credit Username (Pre-Screen)"
							disableValidation={true}
						/>,
						<InputElement
							disabled
							value={credit700PrePwd}
							onChange={(e) => editCredit700PrePwd(e.target.value)}
							label="700Credit Password (Pre-Screen)"
							disableValidation={true}
						/>,
					]}
				/>
			</Form>
			<div className="btn btn-success btn-lg float-button" onClick={saveInfo}>
				<i className="nc-icon nc-check-2" /> Save
			</div>
		</>
	);
};

export default ThirdPartyCredentials;
