import getAxiosInstance from "./interceptors/buildReqAndResInstance";

import { userBaseUrl } from "./Constants";
// import { LOCAL } from "./Constants";
// const localInstance = getAxiosInstance({
// 	baseURL: LOCAL + "/creditApp/",
// });

const instance = getAxiosInstance({
	baseURL: userBaseUrl + "/customer/creditApp/",
	//baseURL: LOCAL + "/creditApp/",
});

// Get CreditApp
export const getCreditAppDetails = async (ID) => {
	return await instance.get(`${ID}`);
};

// Get list
export const getCreditAppList = async (locationID) => {
	return await instance.get(`list/${locationID}`);
};
// Get list by status
export const getCreditAppListByStatus = async (locationID, status) => {
	return await instance.get(`list/${locationID}/${status}`);
};
// Delete list
export const deleteCreditApps = async (IDs) => {
	return await instance.get(`delete?IDs=${IDs}`);
};
// Update status
export const updateCreditAppStatus = async (ID, status) => {
	return await instance.get(`updateStatus/${ID}?status=${status}`);
};
// save As Customers
export const saveAsCustomer = async (ID) => {
	return await instance.get(`saveAsCustomer/${ID}`);
};
// save CreditApp
export const saveCreditApp = async (creditApp) => {
	return await instance.post(`save`, creditApp);
};
