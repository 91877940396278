import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";

import useCurrentCustomerStore from "../../../../store/CustomerStore";
import InputElement, {
	ZipInputElement,
} from "../../../formValidation/InputElement";
import InputWrapper from "../../../formValidation/InputWrapper";

import { stateOptions } from "../../../../Constants";
import { SelectorElement } from "../../../formValidation/InputElement";

import shallow from "zustand/shallow";

import { useFormContext } from "react-hook-form";

const MailingAddress = ({ getMailCities, sameAsCurrent }) => {
	// Local states
	const [mailStreet, setMailStreet] = useState("");
	const [mailUnit, setMailUnit] = useState("");
	const [mailCity, setMailCity] = useState("");
	const [mailState, setMailState] = useState("");
	const [mailZip, setMailZip] = useState("");
	const [mailCounty, setMailCounty] = useState("");

	// Customer store
	const { mailingAddress, currentAddress } = useCurrentCustomerStore(
		(state) => ({
			mailingAddress: state.mailingAddress,
			currentAddress: state.currentAddress,
		}),
		shallow
	);

	// Updates the mailing address object in customer store when the
	// input fields and selected city when zip field is focused out
	const handleZipChange = (zip) => {
		const updatedAddress = {
			street: methods.getValues("mailStreet"),
			unit: methods.getValues("mailUnit"),
			city: methods.getValues("mailCity"),
			state: methods.getValues("mailState"),
			zip: methods.getValues("mailZip"),
			county: methods.getValues("mailCounty"),
		};

		getMailCities(zip, updatedAddress);
	};

	// Sets local states to store value
	useEffect(() => {
		setMailStreet(mailingAddress.street || "");
		setMailUnit(mailingAddress.unit || "");
		setMailCity(mailingAddress.city || "");
		setMailState(mailingAddress.state || "");
		setMailZip(mailingAddress.zip || "");
		setMailCounty(mailingAddress.county || "");
	}, [mailingAddress]);

	// If current address same as mailing is checked, set the local states to current address
	useEffect(() => {
		if (sameAsCurrent) {
			setMailStreet(currentAddress.street || "");
			setMailUnit(currentAddress.unit || "");
			setMailCity(currentAddress.city || "");
			setMailState(currentAddress.state || "");
			setMailZip(currentAddress.zip || "");
			setMailCounty(currentAddress.county || "");
		}
		// eslint-disable-next-line
	}, [sameAsCurrent]);

	// Get validation context
	const methods = useFormContext();

	return (
		<Form>
			<InputWrapper
				formTitle="Mailing Address"
				inputComponents={[
					<InputElement
						{...methods}
						value={mailStreet}
						onChange={(e) => {
							setMailStreet(e.target.value);
						}}
						name="mailStreet"
						label="Street Address"
						type="text"
					/>,
					<InputElement
						{...methods}
						value={mailUnit}
						onChange={(e) => {
							setMailUnit(e.target.value);
						}}
						name="mailUnit"
						label="Apt/Unit/Bld"
						type="text"
					/>,
					<InputElement
						{...methods}
						value={mailCity}
						onChange={(e) => {
							setMailCity(e.target.value);
						}}
						name="mailCity"
						label="City"
						type="text"
					/>,
					<SelectorElement
						{...methods}
						name="mailState"
						label="State"
						disable={false}
						value={mailState}
						onChange={(e) => setMailState(e.target.value)}
						options={stateOptions}
					/>,
					<ZipInputElement
						{...methods}
						value={mailZip}
						onChange={setMailZip}
						openZipModal={handleZipChange}
						name="mailZip"
						label="Zip"
					/>,
					<InputElement
						{...methods}
						value={mailCounty}
						onChange={(e) => setMailCounty(e.target.value)}
						name="mailCounty"
						label="County"
						type="text"
					/>,
				]}
			/>
		</Form>
	);
};

export default MailingAddress;
