import React from "react";
import { Row, Table, Button } from "reactstrap";
import useCurrentDealStore from "../../../../../../store/DealStore";
import { confirmDeleteAlert } from "component/dc/modal/SweetAlerts";
import { CurrencyDisplayElement } from "component/dc/label/DisplayElement";
import dayjs from "dayjs";
import { DealStatus } from "Constants";
import {
	deleteSuccessAlert,
	deleteFailAlert,
} from "component/dc/modal/SweetAlerts";
import { deletePickupPayment } from "api/DealAPI";
import { showApiError } from "../../../../../../helperFunction/ApiErrorHandler";
import shallow from "zustand/shallow";
import { saveDeal } from "../../../../../../api/DealAPI";
import useSetDeal from "../../../../../../helperFunction/deal/useSetDeal";
import useBuildDeal from "../../../../../../helperFunction/deal/useBuildDeal";
import { useFromUser } from "../../../../../../store/LocalStorageHelper";
import {
	//createFileAndDownload,
	displayError,
	displayPdf,
	formLoading,
	getPdf,
} from "../../../../../../api/FormsAPI";
import Swal from "sweetalert2";
import { saveFailAlert } from "../../../../modal/SweetAlerts";

const DownPaymentTable = ({ toggle, setCurrentIndex }) => {
	const { pickupPmts, type, editPickupPmts, calculate } = useCurrentDealStore(
		(state) => ({
			pickupPmts: state.pickupPmts,
			type: state.deal.type,
			editPickupPmts: state.editPickupPmts,
			calculate: state.calculate,
		}),
		shallow
	);
	const pickupPmtsHead = [
		"#",
		"date Due",
		"amount",
		"date Paid",
		"amount Paid",
		"receipt #",
		"Actions",
	];
	const toggleModal = (currentID, currentIndex) => {
		confirmDeleteAlert(removePickup, currentID, currentIndex);
	};
	const removePickup = (ID, index) => {
		console.log("Removing pickup payment with ID: " + ID);
		if (ID) {
			deletePickupPayment(ID).then(
				(response) => {
					console.log(response);
					deleteSuccessAlert();
				},
				(err) => {
					console.error(err);

					if (!err.isGeneralError) {
						showApiError(err, deleteFailAlert);
					}
				}
			);
		}
		console.log(index);
		let obj = pickupPmts.filter((item) => item.currentIndex !== index);
		editPickupPmts(obj);
		calculate();
		setCurrentIndex(obj.length);
	};

	const tableHead = pickupPmtsHead.map((key) => <th>{key}</th>);

	const rows = pickupPmts.map((key, index) => (
		<tr key={index}>
			<th scope="row">{index + 1}</th>
			<td>{dayjs(key.dateDue).format("MM/DD/YYYY")}</td>
			<td>{<CurrencyDisplayElement val={key.amount} />}</td>
			<td>{dayjs(key.datePaid).format("MM/DD/YYYY")}</td>
			<td>{<CurrencyDisplayElement val={key.amountPaid} />}</td>
			<td>{key.receiptNo}</td>
			<td className="text-center">
				<Button
					readOnly={type !== DealStatus.PENDING}
					className="btn-sm"
					color="primary"
					onClick={() => {
						setCurrentIndex(index);
						toggle();
					}}
				>
					Edit
				</Button>
				<Button
					readOnly={type !== DealStatus.PENDING}
					className="btn-sm"
					color="danger"
					onClick={() => {
						toggleModal(key.ID, key.currentIndex);
					}}
				>
					X
				</Button>
				<ReceiptButton paymentId={key.ID} />
			</td>
		</tr>
	));
	return (
		<Row>
			<Table striped bordered hover>
				<thead>
					<tr>{tableHead}</tr>
				</thead>
				<tbody>{rows}</tbody>
			</Table>
		</Row>
	);
};

export default DownPaymentTable;

const ReceiptButton = ({ paymentId }) => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const getDeal = useBuildDeal();
	const setDeal = useSetDeal();

	const handlePrint = () => {
		const deal = getDeal();
		const dealID = deal.deal.ID;
		const body = {
			dealID: dealID,
			inventoryID: null,
			// 313 is ID for pickup payment receipt in dcReport
			dcReportID: 313,
			params: { dealerID: dealerID, locationID: locationID, paymentId },
		};
		formLoading("PDF");
		saveDeal(deal).then(
			(res) => {
				setDeal(res);
				//saveSuccessAlert();
				body.dealID = res.data.content.deal.ID;
				getPdf(body).then(
					(response) => {
						// const fileName = "ST_DEAL_REVIEW_" + dealID + ".pdf";
						// createFileAndDownload(response, fileName);
						displayPdf(response);
						Swal.close();
					},
					(err) => {
						displayError(err);
					}
				);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	return (
		<Button
			className="btn-sm"
			onClick={() => {
				handlePrint();
			}}
		>
			Print
		</Button>
	);
};
