import React from "react";

const Loading = () => {
	return (
		<div
			className="content text-center"
			style={{
				minWidth: "100vh",
				minHeight: "100vh",
			}}
		>
			<div className="spinner-grow text-warning" role="status"></div>
		</div>
	);
};

export default Loading;
