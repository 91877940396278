import React, { useEffect, useState } from "react";
import {
	Label,
	Input,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	FormGroup,
} from "reactstrap";
import shallow from "zustand/shallow";
import InputElement, {
	CurrencyInputElement,
	PercentInputElement,
} from "../../../view/formValidation/InputElement";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import useCurrentVehicleStore from "../../../store/InventoryStore";
import Select from "react-select";

const InventoryFlooringDetail = () => {
	// Define form validation parameters

	const schema = yup.object().shape({
		cost: yup.string().required(),
	});
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	// Vehicle store
	const {
		dateFloored,
		flooringInt,
		flooringComp,
		flooringAmt,
		flooringValue,
		flooringAdminFee,
		flooringCheckNo,
		isFloored,
		flooringStopDate,

		editFlooringComp,
		editIsFloored,
		editFlooringInt,
		editFlooringAmt,
		editFlooringValue,
		editFlooringAdminFee,
		editFlooringCheckNo,
		editDateFloored,
		editFlooringStopDate,
		flooringVendorsList,
	} = useCurrentVehicleStore(
		(state) => ({
			dateFloored: state.dateFloored,
			flooring: state.flooring,
			flooringInt: state.flooringInt,
			flooringComp: state.flooringComp,
			useFlooring: state.useFlooring,
			flooringAmt: state.flooringAmt,
			flooringValue: state.flooringValue,
			flooringAdminFee: state.flooringAdminFee,
			flooringPaidOn: state.flooringPaidOn,
			flooringCheckNo: state.flooringCheckNo,
			isFloored: state.isFloored,
			flooringStopDate: state.flooringStopDate,

			editFlooringComp: state.editFlooringComp,
			editIsFloored: state.editIsFloored,
			editFlooringInt: state.editFlooringInt,
			editUseFlooring: state.editUseFlooring,
			editFlooringAmt: state.editFlooringAmt,
			editFlooringValue: state.editFlooringValue,
			editFlooringAdminFee: state.editFlooringAdminFee,
			editFlooringCheckNo: state.editFlooringCheckNo,
			editTotalFlooring: state.editTotalFlooring,
			editFlooringPaidOn: state.editFlooringPaidOn,
			editDateFloored: state.editDateFloored,
			editFlooringStopDate: state.editFlooringStopDate,
			flooringVendorsList: state.flooringVendorsList,
		}),
		shallow
	);
	console.log(isFloored);
	const [vendorList, setVendorList] = useState([]);
	useEffect(() => {
		const vendorOptions = flooringVendorsList.map((vendor) => {
			return { value: vendor.ID, label: vendor.name };
		});

		setVendorList(vendorOptions);
		// eslint-disable-next-line
	}, []);

	return (
		<>
			{" "}
			<Col></Col>
			<Col>
				<FormGroup className="col-lg-3 pt-2">
					<CardTitle tag="h3">
						<Input
							id="isFloored"
							name={isFloored}
							checked={isFloored}
							// onChange={(e) => {
							// 	console.log(e.target.value);
							// 	editIsFloored(e.target.value);
							// }}
							onChange={(e) => {
								console.log(e.target.value);
								console.log(isFloored);
								editIsFloored(!isFloored);
							}}
							className="form-control  bold"
							type="checkbox"
						/>
						Is Floored
					</CardTitle>
				</FormGroup>
			</Col>
			{isFloored && (
				<Card>
					<CardHeader>
						<CardTitle tag="h5">Flooring Information</CardTitle>
					</CardHeader>

					<Col>
						<Row className="mx-0">
							<CardBody>
								{/* <Col className="ml-auto mr-auto col-md-8"> */}
								<Row>
									<Controller
										as={
											<FormGroup className="col-md-3">
												<Label>Flooring Company</Label>
												<Select
													noOptionsMessage={() => "No vendors available"}
													value={{
														value: flooringComp,
														label: flooringComp,
													}}
													options={[...vendorList]}
													// { value: "ADD", label: "Add new vendor" },
													onChange={(e) => editFlooringComp(e.label)}
												/>
												{/* <AddVendorModal
												title="Add Vendor"
												vendorType={vendorTypesOBJ.FLOORING_VENDOR.value}
												vendorLabel={vendorTypesOBJ.VENDOR.label}
												addVendor={saveNewVendor}
												toggle={toggleVendorModal}
												modal={openAddVendorModal}
											/> */}
											</FormGroup>
										}
										name="vendorList"
										//									defaultValue={vendorRecNum || "0"}
									/>
									,
									<CurrencyInputElement
										value={flooringValue}
										onChange={editFlooringValue}
										name="flooringValue"
										label="Amount Floored"
										type="Currency"
									/>
									,
									<CurrencyInputElement
										value={flooringAdminFee}
										onChange={editFlooringAdminFee}
										name="flooringAdminFee"
										label="Setup Fee"
										type="Currency"
									/>
									,
									<PercentInputElement
										value={flooringInt}
										onChange={editFlooringInt}
										name="flooringInt"
										label="Annual (APR)"
										decimalScale={4}
									/>
									,
									<InputElement
										{...methods}
										value={flooringCheckNo}
										onChange={(e) => {
											editFlooringCheckNo(e.target.value);
										}}
										name="flooringCheckNo"
										label="Check No"
										type="text"
									/>
									,
									<CurrencyInputElement
										value={flooringAmt}
										onChange={editFlooringAmt}
										name="flooringAmt"
										label="Flooring Fee"
										type="Currency"
									/>
									,
									<InputElement
										{...methods}
										value={dateFloored}
										onChange={(e) => {
											editDateFloored(e.target.value);
										}}
										name="date"
										label="Date"
										type="date"
									/>
									,
									<InputElement
										{...methods}
										value={flooringStopDate}
										onChange={(e) => {
											editFlooringStopDate(e.target.value);
										}}
										name="flooringStopDate"
										label="End Date"
										type="date"
									/>
									,
								</Row>
								{/* </Col> */}
								<Col className="ml-auto mr-auto">
									<Row></Row>
								</Col>
							</CardBody>
						</Row>
					</Col>
				</Card>
			)}
		</>
	);
};

export default InventoryFlooringDetail;
