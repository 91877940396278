import React, { useState } from "react";
import { Col, Row, Table, Button, CardText } from "reactstrap";

import useCurrentDealStore from "../../../../../../store/DealStore";
import {
	confirmDeleteMessage,
	saveFailAlert,
	showSwalMessage,
	updateSuccessAlert,
} from "../../../../modal/SweetAlerts";
import { CurrencyDisplayElement } from "../../../../label/DisplayElement";
import ChildrenModal from "component/dc/modal/ChildrenModal";
import shallow from "zustand/shallow";
import MoneyDownForm from "./MoneyDownForm";
import { paymentMethod } from "Constants";
import ProcessPepperPay from "./ProcessPepperPay";
import pepperpayLogo from "assets/img/pepperpay.svg";
import useCurrentDealerLinkStore from "../../../../../../store/DealerLinkStore";
import { saveDeal } from "../../../../../../api/DealAPI";
import useSetDeal from "../../../../../../helperFunction/deal/useSetDeal";
import useBuildDeal from "../../../../../../helperFunction/deal/useBuildDeal";
import { useFromUser } from "../../../../../../store/LocalStorageHelper";
import {
	//createFileAndDownload,
	displayError,
	displayPdf,
	formLoading,
	getPdf,
} from "../../../../../../api/FormsAPI";
import { showApiError } from "../../../../../../helperFunction/ApiErrorHandler";
import Swal from "sweetalert2";
import ProcessTerminal from "./ProcessTerminal";
import NumberFormat from "react-number-format";
import useCurrentSaleStore from "../../../../../../store/SaleStore";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";

const MoneyDownTable = () => {
	const { down } = useCurrentSaleStore(
		(state) => ({
			down: state.down,
		}),
		shallow
	);

	const { pepperPayEmail } = useCurrentDealerLinkStore(
		(state) => state,
		shallow
	);

	const { downPayments, editDownPayments, calculate } = useCurrentDealStore(
		(state) => ({
			downPayments: state.downPayments,
			editDownPayments: state.editDownPayments,
			calculate: state.calculate,
		}),
		shallow
	);
	const [isOpen, setIsOpen] = useState(false);
	const togglePP = () => {
		if (pepperPayEmail !== null && pepperPayEmail !== "") {
			setIsOpen(!isOpen);
		} else {
			showSwalMessage("", "To signup for Pepperpay, go to settings", "");
		}
	};

	const [terminalOpen, setTerminalOpen] = useState(false);
	const toggleTerminal = () => {
		if (pepperPayEmail !== null && pepperPayEmail !== "") {
			setTerminalOpen(!terminalOpen);
		} else {
			showSwalMessage("", "To signup for Pepperpay, go to settings", "");
		}
	};

	const Head = [
		"#",
		"Date",
		"Receipt No",
		"Amount",
		"Methods",
		//		"Description",
		"Status",
		"Actions",
	];

	const changeToVoid = (ID, index) => {
		console.log("change status with ID: " + ID);
		let updatedObj = [...downPayments];
		if (ID) {
			updatedObj.forEach((e) => {
				if (e.id === ID) {
					e.status = "1";
				}
			});
		} else {
			updatedObj.forEach((e) => {
				if (e.currentIndex === index) {
					e.status = "1";
				}
			});
		}
		editDownPayments(updatedObj);
		setCurrentIndex(updatedObj.length);
		calculate();
		updateSuccessAlert();
	};

	const [currentIndex, setCurrentIndex] = useState(downPayments?.length);
	const [showAdd, setShowAdd] = useState(false);
	const toggle = () => setShowAdd(!showAdd);

	const toggleModal = (currentID, currentIndex) => {
		let confirmVoid = "Void";
		confirmDeleteMessage(confirmVoid).then((res) => {
			if (confirmVoid === res) {
				changeToVoid(currentID, currentIndex);
			}
		});
	};

	const tableHead = Head.map((key, index) => <th key={index}>{key}</th>);

	const rows = downPayments.map((key, index) => (
		<tr key={index}>
			<th scope="row">{index + 1}</th>
			<td> {key.datePaid}</td>
			<td>{key.receiptNo}</td>
			<td>{<CurrencyDisplayElement val={key.amtPaid} />}</td>
			<td>{paymentMethod.filter((e) => key.method === e.value)[0]?.label}</td>
			{/* <td>{key.description}</td> */}
			<td>{key.status === "1" ? "Void" : "Active"}</td>

			<td>
				<div className="d-flex justify-content-center">
					<div
						className="btn btn-md btn-primary"
						onClick={() => {
							setCurrentIndex(index);
							toggle();
						}}
					>
						<i className="nc-icon nc-align-center"></i>
						<span className="noMobile">Edit</span>
					</div>
					{key.status !== "1" && (
						<div
							className="btn btn-md btn-link btn-link-danger text-danger ml-2"
							onClick={() => {
								console.log(key);
								console.log(key.id + " " + index);
								toggleModal(key.id, key.currentIndex);
							}}
						>
							Void
						</div>
					)}
					<ReceiptButton paymentId={key.id} />
				</div>
			</td>
		</tr>
	));
	return (
		<>
			<ChildrenModal modal={showAdd} toggle={toggle} modalTitle="Down">
				<MoneyDownForm
					currentIndex={currentIndex}
					setCurrentIndex={setCurrentIndex}
					toggle={toggle}
				/>
			</ChildrenModal>

			<Table striped bordered hover>
				<thead>
					<tr>{tableHead}</tr>
				</thead>
				<tbody>{rows}</tbody>
			</Table>
			<Card>
				<CardBody>
					<Row>
						<Col sm="6">
							<CardText tag="h3" className="text-center">
								Total Down
							</CardText>
						</Col>
						<Col sm="6">
							<CardText tag="h3" className="text-center">
								<NumberFormat
									value={down}
									thousandSeparator={true}
									decimalScale={2}
									fixedDecimalScale={true}
									prefix={"$"}
									isNumericString={true}
									readOnly={true}
									displayType="text"
								/>
							</CardText>
						</Col>
					</Row>
				</CardBody>
			</Card>

			<Row>
				<Col>
					<Button className="btn btn-secondary btn-md" onClick={toggle}>
						<i className="nc-icon nc-simple-add" />
						Add
					</Button>
				</Col>
				<Col></Col>

				<Col className="thumbnail">
					<img src={pepperpayLogo} alt={"PepperPay"} />
				</Col>
				<Col>
					<Button onClick={togglePP}>Process</Button>
				</Col>
				<Col>
					<Button onClick={toggleTerminal}>Terminal</Button>
				</Col>

				<ChildrenModal size="md" modal={isOpen} toggle={togglePP}>
					<ProcessPepperPay setIsOpen={setIsOpen} />
				</ChildrenModal>
				<ChildrenModal size="md" modal={terminalOpen} toggle={toggleTerminal}>
					<ProcessTerminal setTerminalOpen={setTerminalOpen} />
				</ChildrenModal>
			</Row>
		</>
	);
};
export default MoneyDownTable;

const ReceiptButton = ({ paymentId }) => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const getDeal = useBuildDeal();
	const setDeal = useSetDeal();

	const handlePrint = () => {
		const deal = getDeal();
		const dealID = deal.deal.ID;
		const body = {
			dealID: dealID,
			inventoryID: null,
			// 311 is ID for down payment receipt in dcReport
			dcReportID: 311,
			params: { dealerID: dealerID, locationID: locationID, paymentId },
		};
		formLoading("PDF");
		saveDeal(deal).then(
			(res) => {
				setDeal(res);
				//saveSuccessAlert();
				body.dealID = res.data.content.deal.ID;
				getPdf(body).then(
					(response) => {
						// const fileName = "ST_DEAL_REVIEW_" + dealID + ".pdf";
						// createFileAndDownload(response, fileName);
						displayPdf(response);
						Swal.close();
					},
					(err) => {
						displayError(err);
					}
				);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	return (
		<Button
			onClick={() => {
				handlePrint();
			}}
		>
			Print
		</Button>
	);
};
