import React from "react";
import NavBar from "component/dc/tab/NavTabsHolder";
import FormsReportsList from "./FormsReportsList";
import {
	getAllStandardForms,
	getAllStateForms,
	getAllCustomForms,
	getAllOkiForms,
} from "../../../api/FormsAPI";
import { useFromUser } from "store/LocalStorageHelper";
import PacksList from "./PacksList";
import { PackEditProvider } from "./PackContexts";
import { getAllInventoryForms } from "api/FormsAPI";
import useCurrentDealStore from "store/DealStore";
import shallow from "zustand/shallow";

const Forms = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const params = { dealerID, locationID };

	const { carRecNum } = useCurrentDealStore(
		(state) => ({
			carRecNum: state.deal.carRecNum,
		}),
		shallow
	);
	const tabs = [
		"State Forms",
		"Standard Forms",
		"OKI/Dot Matrix Forms",
		"Requested Forms",
		"Packs",
	];

	const tabComponents = [
		<FormsReportsList
			listTitle="State Forms"
			apiFunction={getAllStateForms}
			apiParams={params}
			searchable={true}
		/>,
		<FormsReportsList
			listTitle="Standard Forms"
			apiFunction={getAllStandardForms}
			apiParams={params}
			searchable={true}
		/>,
		<FormsReportsList
			listTitle="OKI/Dot Matrix Forms"
			apiFunction={getAllOkiForms}
			apiParams={params}
			searchable={true}
		/>,
		<FormsReportsList
			listTitle="Requested Forms"
			apiFunction={getAllCustomForms}
			apiParams={params}
			searchable={true}
		/>,
		<PackEditProvider>
			<PacksList />
		</PackEditProvider>,
	];
	const invTabs = [
		"State Forms",
		"Standard Forms",
		"Inventory Forms",
		"OKI/Dot Matrix Forms",
		"Requested Forms",
		"Packs",
	];

	const invTabComponents = [
		<FormsReportsList
			listTitle="State Forms"
			apiFunction={getAllStateForms}
			apiParams={params}
			searchable={true}
		/>,
		<FormsReportsList
			listTitle="Standard Forms"
			apiFunction={getAllStandardForms}
			apiParams={params}
			searchable={true}
		/>,
		<FormsReportsList
			listTitle="Inventory Forms"
			apiFunction={getAllInventoryForms}
			apiParams={params}
			searchable={true}
			isDeal={true}
		/>,
		<FormsReportsList
			listTitle="OKI/Dot Matrix Forms"
			apiFunction={getAllOkiForms}
			apiParams={params}
			searchable={true}
		/>,
		<FormsReportsList
			listTitle="Requested Forms"
			apiFunction={getAllCustomForms}
			apiParams={params}
			searchable={true}
		/>,
		<PackEditProvider>
			<PacksList />
		</PackEditProvider>,
	];
	return (
		<div className="p-3 justify-content-center align-items-center forms-table">
			<NavBar
				navClass="nav-pills nav-pills-primary justify-content-center nav"
				tabs={carRecNum === null ? tabs : invTabs}
				tabComponents={carRecNum === null ? tabComponents : invTabComponents}
			/>
		</div>
	);
};

export default Forms;
