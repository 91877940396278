import React, { useContext } from "react";
import { Col, Row } from "reactstrap";

import { useFromUser } from "store/LocalStorageHelper";
import { KbbContext } from "../KbbContext";

import dayjs from "dayjs";

const KbbFooter = () => {
	// User store
	const city = useFromUser("locations").city;

	// KBB context
	const { dcTableKbb } = useContext(KbbContext);

	// Valid date
	const valuationDate = dayjs(dcTableKbb.bookDate) || dayjs();
	const startDate = valuationDate.format("MM/DD/YYYY");
	const endDate = valuationDate.add(7, "day").format("MM/DD/YYYY");

	return (
		<Row md="12">
			<Col
				md="12"
				className="d-flex justify-content-center book-sheet-footer-margin"
			>
				<p>DEALERCLICK</p>
			</Col>
			<Col
				md="12"
				className="d-flex justify-content-center book-sheet-footer-margin"
			>
				<p>
					{`${startDate} - ${endDate}`} Kelley Blue Book values for {city} Area
				</p>
			</Col>
			<Col
				md="12"
				className="d-flex justify-content-center book-sheet-footer-margin"
			>
				<p>
					Values are subjective opinions. Kelley Blue Book assumes no
					responsibility for errors or omissions
				</p>
			</Col>
			<Col md="12" className="d-flex justify-content-center">
				<p>© Copyright Kelley Blue Book 2010, all rights reserved</p>
			</Col>
		</Row>
	);
};

export default KbbFooter;
