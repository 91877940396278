import React from "react";
import { Col, Form } from "reactstrap";

import tabOnEnter from "helperFunction/tabOnEnter";

import { HorizontalInputElement } from "view/formValidation/InputElement";

import { useFormContext } from "react-hook-form";

const DigitzsBankInformation = ({
	bankInfo,
	setBankInfo,
	setCurrentForm,
	nextForm,
	previousForm,
}) => {
	const methods = useFormContext();

	// Handler for the next button click. Sets the fields to the personal information
	// object and shows the next form tab
	const handleNext = async () => {
		const isValid = await methods.trigger();

		if (!isValid) {
			return;
		}

		setCurrentForm(nextForm);
	};

	return (
		<div>
			<Form onKeyDown={tabOnEnter} className="row">
				<h3 className="col-12 section-title mb-3">Bank Information</h3>
				<Col className="mt-2" sm={12}>
					<HorizontalInputElement
						{...methods}
						value={bankInfo.bankName}
						onChange={(e) => setBankInfo.setBankName(e.target.value)}
						name="bankName"
						label="Bank Name"
						type="text"
						labelClass="col-sm-3"
					/>
				</Col>
				<Col className="mt-2" sm={12}>
					<HorizontalInputElement
						{...methods}
						value={bankInfo.accountType}
						type="select"
						labelClass="col-sm-3"
						onChange={(e) => setBankInfo.setAccountType(e.target.value)}
						name="accountType"
						label="Account Type"
						options={[
							{ label: "Checking", value: "checking" },
							{ label: "Saving", value: "saving" },
						]}
					/>
				</Col>
				<Col className="mt-2" sm={12}>
					<HorizontalInputElement
						{...methods}
						value={bankInfo.accountOwnership}
						type="select"
						labelClass="col-sm-3"
						onChange={(e) => setBankInfo.setAccountOwnership(e.target.value)}
						name="accountOwnership"
						label="Account Used For"
						options={[
							{ label: "Business", value: "business" },
							{ label: "Personal", value: "personal" },
						]}
					/>
				</Col>
				<Col className="mt-2" sm={12}>
					<HorizontalInputElement
						{...methods}
						value={bankInfo.accountName}
						onChange={(e) => setBankInfo.setAccountName(e.target.value)}
						name="accountName"
						label="Account Name"
						type="text"
						labelClass="col-sm-3"
						placeholder="Name associated with the account"
					/>
				</Col>
				<Col className="mt-2" sm={12}>
					<HorizontalInputElement
						{...methods}
						value={bankInfo.accountNumber}
						onChange={(e) => setBankInfo.setAccountNumber(e.target.value)}
						name="accountNumber"
						label="Account Number"
						type="text"
						labelClass="col-sm-3"
					/>
				</Col>
				<Col className="mt-2" sm={12}>
					<HorizontalInputElement
						{...methods}
						value={bankInfo.routingNumber}
						onChange={(e) => setBankInfo.setRoutingNumber(e.target.value)}
						name="routingNumber"
						label="Routing Number"
						type="number"
						labelClass="col-sm-3"
					/>
				</Col>
			</Form>
			<button
				className="previous-button"
				onClick={() => setCurrentForm(previousForm)}
			>
				Previous
			</button>
			<button className="next-with-previous" onClick={handleNext}>
				Next
			</button>
		</div>
	);
};

export default DigitzsBankInformation;
