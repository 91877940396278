import React from "react";
import { Col, Input, Label, Row } from "reactstrap";

import useCurrentSaleStore from "store/SaleStore";

import NumberFormat from "react-number-format";
import useCurrentDealStore from "store/DealStore";
import shallow from "zustand/shallow";
import { DealStatus } from "Constants";
import {
	CurrencyInput,
	PercentInput,
} from "../../../../../../view/formValidation/InputElement";

// Price component in the dmv modal, including the license and registration
const DMVPriceSection = () => {
	// Deal store
	const { type, isTemplate } = useCurrentDealStore(
		(state) => ({ type: state.deal.type, isTemplate: state.deal.isTemplate }),
		shallow
	);

	// Store values
	const { price, editPrice } = useCurrentSaleStore(
		(state) => ({
			price: state.price,
			editPrice: state.editPrice,
		}),
		shallow
	);

	return (
		<div className="pb-2">
			{!isTemplate && (
				<Row className="mx-auto pt-2">
					<Col sm="2">
						<Label className="text-center pt-2">Price</Label>
					</Col>
					<Col sm="3">
						<NumberFormat
							name="dmvPrice"
							readOnly={type !== DealStatus.PENDING}
							value={price}
							decimalScale={2}
							prefix={"$"}
							fixedDecimalScale={true}
							isNumericString={true}
							customInput={Input}
							onFocus={(e) => e.target.select()}
							onValueChange={(values) => editPrice(values.value)}
						/>
					</Col>
				</Row>
			)}
			<Row>
				<Col sm="6"></Col>
				<Col sm="3">
					<Label className="text-center">Fixed Amount</Label>
				</Col>
				<Col sm="3">
					<Label className="text-center">Percent of Price</Label>
				</Col>
			</Row>
			<DMVLicenseFee />
			<br />
			<DMVRegistrationFee />
		</div>
	);
};

export default DMVPriceSection;

// Calculate the DMV license fee in the DMV Fees modal
const DMVLicenseFee = () => {
	// Deal store
	const { type } = useCurrentDealStore(
		(state) => ({ type: state.deal.type }),
		shallow
	);

	// Store values
	const {
		license,
		licenseFee,
		licensePct,
		editLicenseFee,
		editLicensePct,
	} = useCurrentSaleStore(
		(state) => ({
			price: state.price,
			license: state.license,
			licenseFee: state.licenseFee,
			licensePct: state.licensePct,
			editLicense: state.editLicense,
			editLicenseFee: state.editLicenseFee,
			editLicensePct: state.editLicensePct,
		}),
		shallow
	);

	return (
		<div>
			<Row>
				<Col sm="2">
					<Label className="text-center pt-2">LicenseFee</Label>
				</Col>
				<Col sm="3">
					<NumberFormat
						name="dmvLicense"
						disabled
						value={license}
						decimalScale={2}
						prefix={"$"}
						customInput={Input}
						fixedDecimalScale
						isNumericString
					/>
				</Col>
				<Col sm="1" className="text-center pt-1">
					<Label>=</Label>
				</Col>
				<Col sm="3">
					<CurrencyInput
						readOnly={type !== DealStatus.PENDING}
						value={licenseFee}
						onChange={editLicenseFee}
						name="dmvLicenseFee"
						disableValidation
						wholeRow
					/>
				</Col>
				<Col sm="3">
					<PercentInput
						readOnly={type !== DealStatus.PENDING}
						value={licensePct}
						onChange={editLicensePct}
						name="dmvLicensePct"
						disableValidation
						wholeRow
					/>
				</Col>
			</Row>
		</div>
	);
};

// Calculate the DMV registration fee in the DMV Fees modal
const DMVRegistrationFee = () => {
	// Deal store
	const { type } = useCurrentDealStore(
		(state) => ({ type: state.deal.type }),
		shallow
	);

	// Store values
	const {
		registration,
		registrationFee,
		registrationPct,
		editRegistrationFee,
		editRegistrationPct,
	} = useCurrentSaleStore(
		(state) => ({
			price: state.price,
			registration: state.registration,
			registrationFee: state.registrationFee,
			registrationPct: state.registrationPct,
			editRegistration: state.editRegistration,
			editRegistrationFee: state.editRegistrationFee,
			editRegistrationPct: state.editRegistrationPct,
		}),
		shallow
	);

	return (
		<div>
			<Row>
				<Col sm="2">
					<Label className="text-center pt-2">Registration</Label>
				</Col>
				<Col sm="3">
					<NumberFormat
						name="dmvRegistration"
						disabled
						value={registration}
						decimalScale={2}
						prefix={"$"}
						customInput={Input}
						fixedDecimalScale
						isNumericString
					/>
				</Col>
				<Col sm="1" className="text-center pt-1">
					<Label>=</Label>
				</Col>
				<Col sm="3">
					<CurrencyInput
						readOnly={type !== DealStatus.PENDING}
						value={registrationFee}
						onChange={editRegistrationFee}
						name="dmvRegistrationFee"
						disableValidation
						wholeRow
					/>
				</Col>
				<Col sm="3">
					<PercentInput
						readOnly={type !== DealStatus.PENDING}
						value={registrationPct}
						onChange={editRegistrationPct}
						name="dmvRegistrationPct"
						disableValidation
						wholeRow
					/>
				</Col>
			</Row>
		</div>
	);
};
