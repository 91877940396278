import React from "react";
import { Button } from "reactstrap";
import { deleteCreditApps } from "api/CreditAppApi";
import { confirmDeleteMessage } from "component/dc/modal/SweetAlerts";

const DeleteCreditAppButton = ({ IDs, toggle }) => {
	const callConfirmDelete = () => {
		let confirmDelete = "Cancel";
		confirmDeleteMessage("Yes, Delete it").then((res) => {
			confirmDelete = res;
			if (confirmDelete === "Yes, Delete it") {
				console.log(IDs);
				deleteCreditApps(IDs);
				toggle();
				// TODO refresh headercards here
			}
		});
	};

	return (
		<div className="col text-center pt-2">
			<hr />
			<Button
				className="btn btn-danger btn-md mt-2"
				onClick={callConfirmDelete}
			>
				<i className="nc-icon nc-simple-remove"></i> Delete Credit Application
			</Button>
		</div>
	);
};

export default DeleteCreditAppButton;
