import React, { memo, useEffect, useRef, useState } from "react";
import {
	Input,
	FormGroup,
	Label,
	CardText,
	Col,
	Row,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
} from "reactstrap";

import CreatableSelect from "react-select/creatable";
import NumberFormat from "react-number-format";

import { ErrorMessage } from "@hookform/error-message";
import { Controller, useFormContext } from "react-hook-form";
import ReactSelect from "react-select";

const InputElement = memo(
	({
		register,
		errors,
		clearErrors,
		disabled = false,
		readOnly,
		label,
		name,
		value,
		labelClass,
		onChange,
		onBlur,
		type,
		colSize = "col-sm-3",
		wholeRow = false,
		placeholder,
		autoComplete,
		disableValidation,
	}) => {
		return (
			<FormGroup className={wholeRow ? "col" : colSize}>
				<Label className={labelClass}>{label}</Label>
				<Input
					min={type === "date" ? "1800-01-01" : undefined}
					max={type === "date" ? "9999-12-31" : undefined}
					className="bold"
					autoComplete={autoComplete}
					disabled={disabled}
					readOnly={readOnly}
					placeholder={placeholder}
					style={type === "date" ? { backgroundImage: "none" } : {}}
					name={name}
					innerRef={disableValidation ? undefined : register}
					onChange={(e) => {
						if (!disableValidation) {
							clearErrors(name);
						}

						onChange(e);
					}}
					onBlur={onBlur}
					type={type}
					value={value == null ? "" : value}
					invalid={disableValidation ? undefined : errors[name] ? true : false}
				/>
				{!disableValidation && (
					<ErrorMessage
						name={name}
						render={({ message }) => <p className="error_text">{message}</p>}
					/>
				)}
			</FormGroup>
		);
	},
	(prevProps, nextProps) => {
		if (prevProps.readOnly !== nextProps.readOnly) {
			return false;
		} else if (prevProps.disabled !== nextProps.disabled) {
			return false;
		} else {
			return prevProps.value === nextProps.value;
		}
	}
);

export const SelectInput = memo(
	({
		register,
		errors,
		clearErrors,
		name,
		label,
		labelClass,
		value,
		onChange,
		options,
		colSize = "col-sm-3",
		disabled,
		readOnly,
		disableValidation,
	}) => {
		const [selectLabel, setSelectLabel] = useState(
			options.find((option) => option.value === value)?.label || ""
		);
		return (
			<FormGroup className={colSize}>
				<Label className={labelClass}>{label}</Label>
				<ReactSelect
					className="bold"
					isDisabled={disabled}
					readOnly={readOnly}
					type="select"
					value={{
						value,
						label: selectLabel,
					}}
					onChange={(e) => {
						if (!disableValidation) {
							clearErrors(name);
						}
						setSelectLabel(e.label);
						onChange(e);
					}}
					options={options}
					name={name}
					innerRef={disableValidation ? undefined : register}
					invalid={disableValidation ? undefined : errors[name] ? true : false}
				/>
				{!disableValidation && (
					<ErrorMessage
						name={name}
						render={({ message }) => <p className="error_text">{message}</p>}
					/>
				)}
			</FormGroup>
		);
	},
	(prevProps, nextProps) => {
		if (prevProps.readOnly !== nextProps.readOnly) {
			return false;
		} else if (prevProps.options !== nextProps.options) {
			return false;
		} else {
			return prevProps.value === nextProps.value;
		}
	}
);

export const SelectorElement = memo(
	({
		register,
		errors,
		clearErrors,
		name,
		label,
		labelClass,
		value,
		onChange,
		options,
		colSize = "col-sm-3",
		disabled,
		readOnly,
		disableValidation,
		selectText,
	}) => {
		const optionList = options.map((option, index) => (
			<option
				key={`${option}_${index}`}
				value={option.value}
				readOnly={readOnly}
			>
				{option.label}
			</option>
		));

		return (
			<FormGroup className={colSize}>
				<Label className={labelClass}>{label}</Label>
				<Input
					className="bold"
					disabled={disabled}
					readOnly={readOnly}
					type="select"
					value={value == null ? "" : value}
					onChange={(e) => {
						if (!disableValidation) {
							clearErrors(name);
						}

						onChange(e);
					}}
					name={name}
					innerRef={disableValidation ? undefined : register}
					invalid={disableValidation ? undefined : errors[name] ? true : false}
				>
					<option key="default" value="" disabled hidden>
						{selectText}
					</option>
					{optionList}
				</Input>
				{!disableValidation && (
					<ErrorMessage
						name={name}
						render={({ message }) => <p className="error_text">{message}</p>}
					/>
				)}
			</FormGroup>
		);
	},
	(prevProps, nextProps) => {
		if (prevProps.readOnly !== nextProps.readOnly) {
			return false;
		} else if (prevProps.options !== nextProps.options) {
			return false;
		} else {
			return prevProps.value === nextProps.value;
		}
	}
);

export const LabelElement = ({
	title,
	text,
	style,
	titleColSize,
	textColSize,
	colClass,
	color,
}) => {
	return (
		<Row style={style}>
			<Col className={colClass} md={titleColSize}>
				<CardText>{title}</CardText>
			</Col>
			<Col className={colClass} md={textColSize}>
				<NumberFormat
					style={{ color }}
					value={text}
					thousandSeparator={true}
					decimalScale={2}
					fixedDecimalScale={true}
					prefix={"$"}
					isNumericString={true}
					displayType="text"
				/>
			</Col>
		</Row>
	);
};

/**
 * Use CurrencyInput Insted of CurrencyInputElement
 * @param {*} param0
 * @returns
 */
export const CurrencyInputElement = ({
	label,
	name,
	placeholder,
	value,
	colSize = "col-sm-3",
	onChange,
	labelClass,
	wholeRow = false,
	disabled = false,
	readOnly = false,
}) => {
	const methods = useFormContext();
	//const money = /^\d*\.?\d{1,2}$/;

	return (
		<FormGroup className={wholeRow ? "col" : colSize}>
			<Label className={labelClass}>{label}</Label>
			<NumberFormat
				disabled={disabled}
				value={value}
				name={name}
				placeholder={placeholder}
				className="form-control bold"
				innerRef={methods.register}
				invalid={methods.errors[name] ? true : false}
				readOnly={readOnly}
				thousandSeparator={true}
				decimalScale={2}
				fixedDecimalScale={true}
				onFocus={(e) => e.target.select()}
				prefix={"$"}
				isNumericString={true}
				customInput={Input}
				onValueChange={(values) => {
					if (values.value == null || values.value === "") {
						onChange(0);
					} else {
						onChange(values.floatValue);
					}
				}}
			/>
			<ErrorMessage
				name={name}
				render={({ message }) => <p className="error_text">{message}</p>}
			/>
		</FormGroup>
	);
};

/**
 * Generic currency input field with optional validation
 * Use This Insted of CurrencyInputElement
 * @param {*} param0
 * @returns
 */
export const CurrencyInput = ({
	label,
	name,
	placeholder,
	value,
	colSize = "col-sm-3",
	onChange,
	labelClass,
	wholeRow = false,
	disabled = false,
	readOnly = false,
	register,
	errors,
	clearErrors,
	disableValidation = false,
	allowNegative = true,
}) => {
	return (
		<FormGroup className={wholeRow ? "col" : colSize}>
			{label && <Label className={labelClass}>{label}</Label>}
			<span onFocus={(e) => e.target.select()}>
				<NumberFormat
					disabled={disabled}
					value={value}
					name={name}
					placeholder={placeholder}
					className="form-control bold"
					innerRef={disableValidation ? undefined : register}
					invalid={disableValidation ? undefined : errors[name] ? true : false}
					readOnly={readOnly}
					thousandSeparator={true}
					decimalScale={2}
					fixedDecimalScale={true}
					prefix={"$"}
					isNumericString={true}
					customInput={Input}
					onValueChange={(values) => {
						if (!disableValidation) {
							clearErrors(name);
						}
						if (onChange !== undefined) {
							if (values.value === "" || values.value == null) {
								onChange(0);
							} else {
								if (!allowNegative && values.floatValue < 0) {
									onChange(0);
								} else {
									onChange(values.floatValue);
								}
							}
						}
					}}
				/>
			</span>
			{!disableValidation && (
				<ErrorMessage
					name={name}
					render={({ message }) => <p className="error_text">{message}</p>}
				/>
			)}
		</FormGroup>
	);
};

export const IntegerInputElement = ({
	disabled = false,
	label,
	name,
	labelClass,
	colSize = "col-sm-3",
	value,
	onChange,
	wholeRow = false,
	readOnly = false,
	thousandSeparator = true,
}) => {
	const methods = useFormContext();
	//const re = /^\d{1,5}$/;

	return (
		<FormGroup className={wholeRow ? "col" : colSize}>
			<Label className={labelClass}>{label}</Label>
			<NumberFormat
				disabled={disabled}
				value={value}
				name={name}
				className="form-control  bold"
				innerRef={methods.register}
				invalid={methods.errors[name] ? true : false}
				readOnly={readOnly}
				onFocus={(e) => e.target.select()}
				thousandSeparator={thousandSeparator}
				decimalScale={0}
				fixedDecimalScale={true}
				isNumericString={true}
				customInput={Input}
				onValueChange={(values) => {
					if (values.value === "" || values.value === "0") {
						onChange(1);
					} else {
						onChange(values.floatValue);
					}
				}}
			/>
			<ErrorMessage
				name={name}
				render={({ message }) => <p className="error_text">{message}</p>}
			/>
		</FormGroup>
	);
};

export const IntegerInput = ({
	disabled = false,
	label,
	name,
	labelClass,
	colSize = "col-sm-3",
	value,
	onChange,
	wholeRow = false,
	readOnly = false,
	thousandSeparator = true,
	disableValidation = false,
	register,
	errors,
	clearErrors,
}) => {
	return (
		<FormGroup className={wholeRow ? "col" : colSize}>
			{label && <Label className={labelClass}>{label}</Label>}
			<span onFocus={(e) => e.target.select()}>
				<NumberFormat
					disabled={disabled}
					value={value}
					name={name}
					className="form-control  bold"
					innerRef={disableValidation ? undefined : register}
					invalid={disableValidation ? undefined : errors[name] ? true : false}
					readOnly={readOnly}
					thousandSeparator={thousandSeparator}
					decimalScale={0}
					fixedDecimalScale={true}
					isNumericString={true}
					customInput={Input}
					onValueChange={(values) => {
						if (!disableValidation) {
							clearErrors(name);
						}
						if (onChange !== undefined) {
							if (values.value === "" || values.value === "0") {
								onChange(1);
							} else {
								onChange(values.floatValue);
							}
						}
					}}
				/>
			</span>
			{!disableValidation && (
				<ErrorMessage
					name={name}
					render={({ message }) => <p className="error_text">{message}</p>}
				/>
			)}
		</FormGroup>
	);
};

export const PercentInputElement = ({
	label,
	name,
	value,
	labelClass,
	onChange,
	wholeRow = false,
	colSize = "col-sm-3",
	disabled = false,
	readOnly = false,
	decimalScale = 2,
}) => {
	const methods = useFormContext();

	//const re = /^\d*\.?\d{1,2}$/;
	return (
		<FormGroup className={wholeRow ? "col" : colSize}>
			<Label className={labelClass}>{label}</Label>
			<NumberFormat
				disabled={disabled}
				value={value * 100}
				name={name}
				className="form-control  bold"
				innerRef={methods.register}
				invalid={methods.errors[name] ? true : false}
				readOnly={readOnly}
				decimalScale={decimalScale}
				fixedDecimalScale={true}
				onFocus={(e) => e.target.select()}
				isNumericString={true}
				customInput={Input}
				suffix="%"
				onValueChange={(values) => {
					if (values.value === "" || values.floatValue > 100) {
						onChange(0);
					} else {
						onChange(values.floatValue / 100);
					}
				}}
			/>
			<ErrorMessage
				name={name}
				render={({ message }) => <p className="error_text">{message}</p>}
			/>
		</FormGroup>
	);
};

/**
 * Generic percent input field with optional validation
 *
 * @param {*} param0
 * @returns
 */
export const PercentInput = ({
	label,
	name,
	value,
	labelClass,
	onChange,
	wholeRow = false,
	colSize = "col-sm-3",
	disabled = false,
	readOnly = false,
	disableValidation = false,
	register,
	errors,
	clearErrors,
	onBlur,
}) => {
	return (
		<FormGroup className={wholeRow ? "col" : colSize}>
			{label && <Label className={labelClass}>{label}</Label>}
			<span onFocus={(e) => e.target.select()}>
				<NumberFormat
					disabled={disabled}
					value={value * 100}
					name={name}
					className="form-control  bold"
					innerRef={disableValidation ? undefined : register}
					invalid={disableValidation ? undefined : errors[name] ? true : false}
					readOnly={readOnly}
					decimalScale={2}
					fixedDecimalScale={true}
					isNumericString={true}
					customInput={Input}
					suffix="%"
					onBlur={onBlur}
					onValueChange={(values) => {
						if (!disableValidation) {
							clearErrors(name);
						}
						if (values.value === "" || values.floatValue > 100) {
							onChange(0);
						} else {
							onChange(values.floatValue / 100);
						}
					}}
				/>
			</span>
			{!disableValidation && (
				<ErrorMessage
					name={name}
					render={({ message }) => <p className="error_text">{message}</p>}
				/>
			)}
		</FormGroup>
	);
};

/**
 * Generic percent input field with optional validation,
 * field is actually an integer and not a decimal
 *
 * @param {*} param0
 * @returns
 */
export const PercentInputPepperPay = ({
	label,
	name,
	value,
	labelClass,
	onChange,
	wholeRow = false,
	colSize = "col-sm-3",
	disabled = false,
	readOnly = false,
	disableValidation = false,
	register,
	errors,
	clearErrors,
	onBlur,
}) => {
	return (
		<FormGroup className={wholeRow ? "col" : colSize}>
			{label && <Label className={labelClass}>{label}</Label>}
			<span onFocus={(e) => e.target.select()}>
				<NumberFormat
					disabled={disabled}
					value={value}
					name={name}
					className="form-control  bold"
					innerRef={disableValidation ? undefined : register}
					invalid={disableValidation ? undefined : errors[name] ? true : false}
					readOnly={readOnly}
					isNumericString={true}
					customInput={Input}
					suffix="%"
					decimalScale={0}
					fixedDecimalScale={true}
					onBlur={onBlur}
					onValueChange={(values) => {
						if (!disableValidation) {
							clearErrors(name);
						}
						if (values.value === "" || values.floatValue > 100) {
							onChange(0);
						} else {
							onChange(values.floatValue);
						}
					}}
				/>
			</span>
			{!disableValidation && (
				<ErrorMessage
					name={name}
					render={({ message }) => <p className="error_text">{message}</p>}
				/>
			)}
		</FormGroup>
	);
};

export const PhoneInputElement = memo(
	({
		register,
		errors,
		clearErrors,
		label,
		colSize = "col-sm-3",
		name,
		labelClass,
		value,
		onChange,
		wholeRow = false,
		disabled,
		disableValidation,
		readOnly,
		onBlur,
	}) => {
		return (
			<FormGroup className={wholeRow ? "col" : colSize}>
				<Label className={labelClass}>{label}</Label>
				<NumberFormat
					readOnly={readOnly}
					disabled={disabled}
					value={value}
					format="+1 (###) ###-####"
					allowEmptyFormatting={false}
					mask="_"
					name={name}
					className="form-control bold"
					innerRef={disableValidation ? undefined : register}
					invalid={disableValidation ? undefined : errors[name] ? true : false}
					onFocus={(e) => e.target.select()}
					isNumericString={true}
					customInput={Input}
					onValueChange={(values) => {
						if (!disableValidation) {
							clearErrors(name);
						}

						onChange(values.floatValue);
					}}
					onBlur={onBlur}
				/>
				{!disableValidation && (
					<ErrorMessage
						name={name}
						render={({ message }) => <p className="error_text">{message}</p>}
					/>
				)}
			</FormGroup>
		);
	},
	(prevProps, nextProps) => {
		if (prevProps.disabled !== nextProps.disabled) {
			return false;
		} else {
			return prevProps.value === nextProps.value;
		}
	}
);
export const WorkPhoneInputElement = memo(
	({
		register,
		errors,
		clearErrors,
		label,
		colSize = "col-sm-3",
		name,
		labelClass,
		value,
		Extention,
		onChange,
		wholeRow = false,
		disabled,
		disableValidation,
		readOnly,
		onBlur,
	}) => {
		return (
			<FormGroup className={wholeRow ? "col" : colSize}>
				<Label className={labelClass}>{label}</Label>
				<NumberFormat
					readOnly={readOnly}
					disabled={disabled}
					value={value}
					format="+1 (###) ###-#### Ext: ######"
					allowEmptyFormatting={false}
					mask="_"
					name={name}
					className="form-control bold"
					innerRef={disableValidation ? undefined : register}
					invalid={disableValidation ? undefined : errors[name] ? true : false}
					onFocus={(e) => e.target.select()}
					isNumericString={true}
					customInput={Input}
					onValueChange={(values) => {
						if (!disableValidation) {
							clearErrors(name);
						}

						onChange(values.floatValue);
					}}
					onBlur={onBlur}
				/>
				{!disableValidation && (
					<ErrorMessage
						name={name}
						render={({ message }) => <p className="error_text">{message}</p>}
					/>
				)}
			</FormGroup>
		);
	},
	(prevProps, nextProps) => {
		if (prevProps.disabled !== nextProps.disabled) {
			return false;
		} else {
			return prevProps.value === nextProps.value;
		}
	}
);

export const SsnInputElement = memo(
	({
		register,
		errors,
		clearErrors,
		colSize = "col-sm-3",
		label,
		name,
		labelClass,
		value,
		onChange,
		wholeRow = false,
		disabled,
	}) => {
		const [showSSN, setShowSSN] = useState(false);
		return (
			<FormGroup className={wholeRow ? "col" : colSize}>
				<Label className={labelClass}>{label}</Label>
				<InputGroup>
					<NumberFormat
						className="bold"
						disabled={disabled}
						value={value}
						allowEmptyFormatting={false}
						format="###-##-####"
						name={name}
						displayType="number"
						innerRef={register}
						invalid={errors[name] ? true : false}
						onFocus={(e) => e.target.select()}
						customInput={Input}
						onValueChange={(values) => {
							clearErrors(name);
							onChange(values.value);
						}}
						type={!showSSN ? "password" : "text"}
					/>
					<InputGroupAddon addonType="append">
						<InputGroupText>
							<i
								className="fa fa-eye"
								onClick={() => setShowSSN((prev) => !prev)}
							/>
						</InputGroupText>
					</InputGroupAddon>
				</InputGroup>
				<ErrorMessage
					name={name}
					render={({ message }) => <p className="error_text">{message}</p>}
				/>
			</FormGroup>
		);
	},
	(prevProps, nextProps) => prevProps.value === nextProps.value
);

export const FormattedText = ({
	className,
	value,
	format,
	prefix,
	fixedDecScale,
	color,
	fontSize,
}) => {
	return (
		<NumberFormat
			style={{ color, fontSize }}
			className={className}
			value={value}
			format={format}
			thousandSeparator={true}
			decimalScale={2}
			fixedDecimalScale={fixedDecScale}
			prefix={prefix}
			displayType="text"
		/>
	);
};

export const HorizontalInputElement = memo(
	({
		register,
		errors,
		clearErrors,
		label,
		name,
		labelClass,
		value,
		onChange,
		type,
		placeholder,
		options = [],
	}) => {
		const optionList = options.map((option, index) => (
			<option key={`${option}_${index}`} value={option.value}>
				{option.label}
			</option>
		));

		return (
			<FormGroup row>
				<Label className={labelClass}>{label}</Label>
				<Col sm={4}>
					{(type === "select" && (
						<div>
							<Input
								className="form-control  bold"
								placeholder={placeholder}
								name={name}
								innerRef={register}
								onChange={(e) => {
									clearErrors(name);
									onChange(e);
								}}
								type={type}
								value={value || ""}
								invalid={errors[name] ? true : false}
							>
								<option readOnly={true} key="default" value="" />
								{optionList}
							</Input>
							<ErrorMessage
								name={name}
								render={({ message }) => (
									<p className="error_text">{message}</p>
								)}
							/>
						</div>
					)) || (
						<div>
							<Input
								className="form-control  bold"
								placeholder={placeholder}
								name={name}
								innerRef={register}
								onChange={(e) => {
									clearErrors(name);
									onChange(e);
								}}
								type={type}
								value={value || ""}
								invalid={errors[name] ? true : false}
							/>
							<ErrorMessage
								name={name}
								render={({ message }) => (
									<p className="error_text">{message}</p>
								)}
							/>
						</div>
					)}
				</Col>
			</FormGroup>
		);
	},
	(prevProps, nextProps) => prevProps.value === nextProps.value
);

export const EditableSelectorElement = memo(
	({
		label,
		value,
		onChange,
		labelClass,
		options,
		colSize = "col-lg-2",
		disable,
		isMulti,
	}) => {
		return (
			<FormGroup className={colSize}>
				<Label className={labelClass}>{label}</Label>
				<CreatableSelect
					className="bold"
					isDisabled={disable}
					value={{
						value,
						label: value
							?.toLowerCase()
							?.split(" ")
							?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
							?.join(" "),
					}}
					onChange={(e) => onChange(e.value)}
					isMulti={isMulti}
					options={options}
				/>
			</FormGroup>
		);
	},
	(prevProps, nextProps) => {
		if (prevProps.readOnly !== nextProps.readOnly) {
			return false;
		} else if (prevProps.options !== nextProps.options) {
			return false;
		} else {
			return prevProps.value === nextProps.value;
		}
	}
);

export const InventoryEditableSelectorElement = memo(
	({
		label,
		value,
		onChange,
		labelClass,
		options,
		colSize = "col-sm-3",
		disable,
		isMulti,
		name,
		control,
		defaultValue,
		clearErrors,
	}) => {
		return (
			<FormGroup className={colSize}>
				<Label className={labelClass}>{label}</Label>
				<Controller
					control={control}
					name={name}
					defaultValue={defaultValue}
					render={(props) => (
						<CreatableSelect
							onChange={(e) => {
								props.onChange(e.value);
								onChange(e.value);
								clearErrors(name);
							}}
							value={{
								value: value == null ? "" : value,
								label: value
									/*?.toLowerCase()*/
									?.split(" ")
									/* ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))*/
									?.join(" "),
							}}
							options={options}
							isDisabled={disable}
							isMulti={isMulti}
							className="bold" //"inventory-select"
						/>
					)}
				/>
				<ErrorMessage
					name={name}
					render={({ message }) => <p className="error_text">{message}</p>}
				/>
			</FormGroup>
		);
	},
	(prevProps, nextProps) => {
		if (prevProps.readOnly !== nextProps.readOnly) {
			return false;
		} else if (prevProps.options !== nextProps.options) {
			return false;
		} else {
			return prevProps.value === nextProps.value;
		}
	}
);

export const SimpleSelectorElement = memo(
	({
		label,
		value,
		onChange,
		options,
		colSize = "col-lg-2",
		disable = true,
		disabled,
		readOnly,
		name,
	}) => {
		const optionList = options.map((option, index) => (
			<option
				key={`${option}_${index}`}
				value={option.value}
				readOnly={readOnly}
			>
				{option.label}
			</option>
		));

		return (
			<FormGroup className={colSize}>
				<Label>{label}</Label>
				<Input
					className="bold"
					name={name}
					disabled={disabled}
					readOnly={readOnly}
					type="select"
					value={value}
					onChange={(e) => onChange(e)}
				>
					<option readOnly={disable} key="default" value="" />
					{optionList}
				</Input>
			</FormGroup>
		);
	},
	(prevProps, nextProps) => {
		if (prevProps.readOnly !== nextProps.readOnly) {
			return false;
		} else {
			return prevProps.value === nextProps.value;
		}
	}
);

export const CheckBoxElement = ({
	register,
	errors,
	clearErrors,
	disabled = false,
	readOnly,
	label,
	name,
	labelClass,
	onChange,
	colSize = "col-sm-3",
	wholeRow = false,
	checked,
	disableValidation,
}) => {
	console.log(checked);
	return (
		<div className="checkbox-box">
			<div className={wholeRow ? "col" : colSize}>
				<Label className={labelClass}>
					<Input
						checked={checked}
						disabled={disabled}
						readOnly={readOnly}
						name={name}
						innerRef={disableValidation ? undefined : register}
						onChange={(e) => {
							if (!disableValidation) {
								clearErrors(name);
							}
							onChange(e);
						}}
						className="form-control  bold"
						type="checkbox"
						invalid={
							disableValidation ? undefined : errors[name] ? true : false
						}
					/>
					{!disableValidation && (
						<ErrorMessage
							name={name}
							render={({ message }) => <p className="error_text">{message}</p>}
						/>
					)}

					{label}
				</Label>
			</div>
		</div>
	);
};

export const MultiCheckboxes = ({
	options,
	onChange,
	labelClass,
	colSize = "col-sm-6 px-5",
}) => {
	const checkboxes = options.map((obj, index) => (
		<FormGroup key={`${obj.val}_${index}`} className={colSize}>
			<Input
				className="bold"
				id={obj.label}
				checked={obj.checked}
				type="checkbox"
				value={obj.value}
				onChange={(e) => {
					e.persist();
					obj.checked = !obj.checked;
					onChange(e);
				}}
			/>
			<Label className={labelClass} for={obj.label}>
				{obj.label}
			</Label>
		</FormGroup>
	));

	return checkboxes;
};

export default InputElement;

export const ZipInputElement = memo(
	({
		register,
		errors,
		clearErrors,
		disabled = false,
		readOnly,
		label,
		name,
		value,
		labelClass,
		onChange,
		colSize = "col-sm-3",
		wholeRow = false,
		disableValidation,
		openZipModal,
	}) => {
		return (
			<FormGroup className={wholeRow ? "col" : colSize}>
				<Label className={labelClass}>{label}</Label>
				<Input
					autoComplete="new-password"
					disabled={disabled}
					readOnly={readOnly}
					name={name}
					innerRef={disableValidation ? undefined : register}
					onChange={(e) => {
						const value = e.target.value.replace(/[^\d]/g, "");

						if (!disableValidation) {
							clearErrors(name);
						}

						onChange(value);

						if (value != null && value.length === 5) {
							openZipModal(value);
						}
					}}
					className="form-control  bold"
					maxLength={5}
					value={value == null ? "" : value}
					invalid={disableValidation ? undefined : errors[name] ? true : false}
				/>
				{!disableValidation && (
					<ErrorMessage
						name={name}
						render={({ message }) => <p className="error_text">{message}</p>}
					/>
				)}
			</FormGroup>
		);
	},
	(prevProps, nextProps) => {
		if (prevProps.readOnly !== nextProps.readOnly) {
			return false;
		} else if (prevProps.disabled !== nextProps.disabled) {
			return false;
		} else {
			return prevProps.value === nextProps.value;
		}
	}
);

export const TextAreaInput = memo(
	({
		// register,
		errors,
		clearErrors,
		label,
		name,
		value,
		inputClass = "bold",
		labelClass,
		onChange,
		onClick,
		colSize = "col-sm-3",
		placeholder,
		disableValidation,
	}) => {
		const ref = useRef();

		// Resizes textarea to show content
		const resizeTextArea = (e) => {
			const element = e.target;
			console.log(e);
			console.log(e.target);
			element.style.height = "auto";
			element.style.height = element.scrollHeight + "px";
		};

		const autoResize = () => {
			ref.current.focus();
		};

		useEffect(() => {
			autoResize();
		}, [value]);

		return (
			<FormGroup className={colSize}>
				<Label className={labelClass}>{label}</Label>
				<Input
					className={inputClass}
					type="textarea"
					placeholder={placeholder}
					name="messageBody"
					innerRef={ref}
					onChange={(e) => {
						if (!disableValidation) {
							clearErrors(name);
						}
						onChange(e);
						resizeTextArea(e);
					}}
					onClick={onClick}
					onFocus={resizeTextArea}
					value={value == null ? "" : value}
					invalid={disableValidation ? undefined : errors[name] ? true : false}
				/>
				{!disableValidation && (
					<ErrorMessage
						name={name}
						render={({ message }) => <p className="error_text">{message}</p>}
					/>
				)}
			</FormGroup>
		);
	},
	(prevProps, nextProps) => {
		if (prevProps.readOnly !== nextProps.readOnly) {
			return false;
		} else if (prevProps.disabled !== nextProps.disabled) {
			return false;
		} else {
			return prevProps.value === nextProps.value;
		}
	}
);
