import React from "react";
import { Form } from "reactstrap";

import InputElement from "view/formValidation/InputElement";
import LenderWrapper from "../LenderWrapper";

import { newOrUsed } from "Constants";
import { IntegerInputElement } from "view/formValidation/InputElement";
import { SelectorElement } from "view/formValidation/InputElement";

import { useFormContext } from "react-hook-form";

const UACTrades = ({ index, trade, onInputChange }) => {
	// Handles the input changes
	const handleInputChange = (e) => {
		const { name, value } = e.target;

		onInputChange(name, value, index);
	};

	// Get validation context
	const methods = useFormContext();

	return (
		<Form id={`trade_${index}`}>
			<LenderWrapper
				cardId={`trade_${index}`}
				cardTitle={`Trade #${index + 1}`}
			>
				<InputElement
					{...methods}
					colSize="col-lg-3"
					value={trade.vin}
					onChange={handleInputChange}
					name={`vin_trade${index}`}
					label="VIN"
					type="text"
				/>
				<IntegerInputElement
					{...methods}
					colSize="col-lg-3"
					value={trade.odometerIn}
					onChange={(e) => {
						const name = `odometerIn_trade${index}`;

						onInputChange(name, e, index);
					}}
					name={`odometerIn_trade${index}`}
					label="Odometer"
					type="text"
				/>
				<InputElement
					{...methods}
					colSize="col-lg-3"
					value={trade.year}
					onChange={handleInputChange}
					name={`year_trade${index}`}
					label="Vehicle Year"
					type="number"
				/>
				<InputElement
					{...methods}
					colSize="col-lg-3"
					value={trade.make}
					onChange={handleInputChange}
					name={`make_trade${index}`}
					label="Vehicle Make"
					type="text"
				/>
				<InputElement
					{...methods}
					colSize="col-lg-3"
					value={trade.model}
					onChange={handleInputChange}
					name={`model_trade${index}`}
					label="Vehicle Model"
					type="text"
				/>
				<InputElement
					{...methods}
					colSize="col-lg-3"
					value={trade.trim}
					onChange={handleInputChange}
					name={`trim_trade${index}`}
					label="Vehicle Trim"
					type="text"
				/>
				<SelectorElement
					{...methods}
					colSize="col-lg-3"
					name={`newUsed_trade${index}`}
					label="Vehicle Status"
					value={trade.newUsed}
					onChange={handleInputChange}
					options={newOrUsed}
				/>
			</LenderWrapper>
		</Form>
	);
};

export default UACTrades;
