import React from "react";

import { useMultiImageData } from "./MultiImageContext";

// Component containing all the image files selected for upload
const MultiImageFiles = () => {
	// Multi image context
	const {
		setSelectedImage,
		setSelectedImageName,
		setSelectedImageIndex,
		imageKeys,
		setImageKeys,
		setHideCrop,
		croppedImages,
		setCroppedImages,
		imageSources,
		setImageSources,
		fileUploads,
		setFileUploads,
		croppedUploads,
		setCroppedUploads,
	} = useMultiImageData();

	// Removes an uploaded image from the container and updates appropriate states
	const removeImage = (fileName) => {
		const modifiedImages = Object.keys(imageSources)
			.filter((key) => key !== fileName)
			.reduce((obj, key) => {
				obj[key] = imageSources[key];

				return obj;
			}, {});

		const modifiedFileUploads = Object.keys(fileUploads)
			.filter((key) => key !== fileName)
			.reduce((obj, key) => {
				obj[key] = fileUploads[key];

				return obj;
			}, {});

		const modifiedCroppedUploads = Object.keys(croppedUploads)
			.filter((key) => key !== fileName)
			.reduce((obj, key) => {
				obj[key] = croppedUploads[key];

				return obj;
			}, {});

		const modifiedCroppedImages = croppedImages.filter(
			(name) => name !== fileName
		);

		const updatedImageKeys = imageKeys.filter((name) => name !== fileName);

		setCroppedImages(modifiedCroppedImages);
		setImageSources(modifiedImages);
		setFileUploads(modifiedFileUploads);
		setCroppedUploads(modifiedCroppedUploads);
		setImageKeys(updatedImageKeys);
	};

	// Opens the cropping feature with the selected image
	const handleCrop = (fileName, imageFile, index) => {
		setHideCrop(false);
		setSelectedImageIndex(index);
		setSelectedImageName(fileName);
		setSelectedImage(imageFile);
	};

	// Removes an image file from the container containing the image
	// files selected for upload
	const handleDelete = (fileName, index) => {
		setSelectedImageIndex(index);
		setSelectedImage(null);
		setSelectedImageName(null);
		removeImage(fileName);
	};

	return (
		<>
			{Object.entries(imageSources).map((img, index) => (
				<div className="multi-image-container" key={img[0]}>
					{!croppedImages.includes(img[0]) && (
						<i
							onClick={() => handleCrop(img[0], img[1], index)}
							className="fa fa-exclamation-circle multi-image-uncropped"
						/>
					)}
					<img
						src={img[1]}
						alt="fileUpload"
						onClick={() => handleCrop(img[0], img[1], index)}
					/>
					<div
						className="multi-image-delete"
						onClick={() => {
							handleDelete(img[0], index);
						}}
					/>
				</div>
			))}
		</>
	);
};

export default MultiImageFiles;
