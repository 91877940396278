import React from "react";
import { Form } from "reactstrap";

import tabOnEnter from "../../../../../helperFunction/tabOnEnter";

import { useFormContext } from "react-hook-form";
import InputElement, {
	PhoneInputElement,
} from "../../../../formValidation/InputElement";

const MainInformation = ({
	mainInfo,
	setMainInfo,
	business,
	setBusiness,
	setCurrentForm,
	nextForm,
	disabled,
}) => {
	// Handler for the next button click. Sets the fields to the personal information
	// objects and shows the next form tab
	const handleNext = () => {
		setCurrentForm(nextForm);
	};
	const methods = useFormContext();
	console.log(setBusiness);
	return (
		<div>
			<Form className="row" onKeyDown={tabOnEnter}>
				<h3 className="col-12 section-title mb-3">Contact Information</h3>
				<InputElement
					{...methods}
					disabled={disabled}
					value={mainInfo.userEmail}
					onChange={(e) => setMainInfo?.setUserEmail(e.target.value)}
					name="userEmail"
					label="User Email"
					type="text"
					labelClass="required"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					value={mainInfo.userFullName}
					onChange={(e) => {
						setMainInfo.setUserFullName(e.target.value);
						setBusiness.setContactName(e.target.value);
					}}
					name="userFullName"
					label="User Full Name"
					type="text"
					labelClass="required"
				/>
				<PhoneInputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={business.contactPhone}
					onChange={(e) => setBusiness?.setContactPhone(e)}
					name="contactPhone"
					label="Contact Phone"
					labelClass="required"
				/>
			</Form>

			{!disabled && (
				<button
					className="next-button"
					onClick={(e) => {
						e.preventDefault();
						handleNext(e);
					}}
				>
					Next
				</button>
			)}
		</div>
	);
};
export default MainInformation;
