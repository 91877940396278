import React from "react";
import { Input } from "reactstrap";
import useCurrentSaleStore from "store/SaleStore";
import NumberFormat from "react-number-format";
import shallow from "zustand/shallow";
//import OpenTotalModal from "../sale/OpenTotalModal";
// import useCurrentDealStore from "store/DealStore";
// import { DealStatus } from "Constants";

const PrepaidFinanceCharge = () => {
	// Sale store
	const { bankFee } = useCurrentSaleStore(
		(state) => ({
			bankFee: state.bankFee,
		}),
		shallow
	);
	console.log(bankFee);

	return (
		<NumberFormat
			name="bankFee"
			readOnly={true} //{type !== DealStatus.PENDING}
			value={bankFee}
			thousandSeparator={true}
			decimalScale={2}
			fixedDecimalScale={true}
			onFocus={(e) => e.target.select()}
			prefix={"$"}
			isNumericString={true}
			customInput={Input}
		/>
	);
};

export default PrepaidFinanceCharge;
