import React, { useState } from "react";
import {
	Card,
	CardBody,
	CardText,
	Row,
	Col,
	CardHeader,
	Button,
	Label,
} from "reactstrap";
import useCurrentRecapStore from "../../../../store/RecapStore";
import useCurrentSaleStore from "../../../../store/SaleStore";
import useCurrentDealStore from "../../../../store/DealStore";
import shallow from "zustand/shallow";
import ChildrenModal from "../../modal/ChildrenModal";
import { CurrencyDisplayElement } from "../../label/DisplayElement";

const RecapMainCard = () => {
	const {
		carProfit,
		aftmktProfit,
		totalTradeAllowance,
		totalTradeACV,
		totalTradeProfit,
		totalDown,
		miscCost,
		miscProfit,
		gapInsCost,
		gapInsProfit,
		warrantyCost,
		warrantyProfit,
		editMiscCost,
		official,
		collisionTotCost,
		collisionTotProfit,
		creditLifeCost,
		disabCost,
		credLifeProfit,
		disabProfit,
	} = useCurrentRecapStore((state) => state, shallow);

	const {
		isAftMktProfit,
		isMiscProfit,
		isGapProfit,
		isServConProfit,
		totalCost,
	} = useCurrentDealStore(
		(state) => ({
			isAftMktProfit: state?.deal?.dealSettings?.profitSettings?.isAftMktProfit,
			isMiscProfit: state?.deal?.dealSettings?.profitSettings?.isMiscProfit,
			isGapProfit: state?.deal?.dealSettings?.profitSettings?.isGapProfit,
			isServConProfit:
				state?.deal?.dealSettings?.profitSettings?.isServConProfit,
			totalCost: state?.vehicle?.totalCost,
		}),
		shallow
	);

	const {
		price,
		aftmkt,
		aftmktCost,
		totalSellerFees,
		gap,
		warranty,
		collisionTotPremium,
		lifePremium,
		disabilityPremium,
	} = useCurrentSaleStore((state) => state, shallow);

	return (
		<Card>
			<div className="m-3">
				<CardHeader>
					<h3>Recap</h3>
					<hr />
				</CardHeader>
				<CardBody>
					<Row>
						<Col md="2"></Col>
						<Col md="10">
							<Row>
								<Col md="3">
									<h6>Sale Amount</h6>
								</Col>
								<Col md="3">
									<h6>Front Cost</h6>
								</Col>
								<Col md="3">
									<h6>Back Cost</h6>
								</Col>
								<Col md="3">
									<h6>Profit</h6>
								</Col>
							</Row>
						</Col>
					</Row>
					<RecapRow
						component={<VehicleBreakdown />}
						label={"Vehicle"}
						amount={price}
						costVal={totalCost}
						profit={carProfit}
						cost={1}
						prefix={"$"}
					/>
					<RecapRow
						component={<AftermarketBreakdown />}
						label={"Aftermarket"}
						amount={aftmkt}
						costVal={aftmktCost}
						profit={aftmktProfit}
						cost={isAftMktProfit}
					/>
					<RecapRow
						component={<TradeBreakdown />}
						label={"Trade"}
						amount={totalTradeACV}
						costVal={totalTradeAllowance}
						profit={totalTradeProfit}
						cost={1}
					/>
					<RecapRow
						component={<DownBreakdown />}
						label={"Down"}
						amount={totalDown}
						costVal={0}
						profit={0}
						cost={1}
					/>
					<RecapRow
						component={<OfficialFeesBreakdown />}
						label={"Official Fees"}
						amount={official}
						costVal={0}
						profit={0}
						cost={1}
					/>
					<RecapRow
						component={<SellerFeesBreakdown />}
						label={"Seller Fees"}
						amount={totalSellerFees}
						costVal={miscCost}
						profit={miscProfit}
						onChange={editMiscCost}
						cost={isMiscProfit}
					/>
					<RecapRow
						component={<GapInsuranceBreakdown />}
						label={"GAP/Ins."}
						amount={gap + collisionTotPremium + lifePremium + disabilityPremium}
						costVal={gapInsCost + collisionTotCost + creditLifeCost + disabCost}
						profit={
							gapInsProfit + collisionTotProfit + credLifeProfit + disabProfit
						}
						cost={isGapProfit}
					/>
					<RecapRow
						component={<ServiceContractBreakdown />}
						label={"Service Contract"}
						amount={warranty}
						costVal={warrantyCost}
						profit={warrantyProfit}
						cost={isServConProfit}
					/>
				</CardBody>
			</div>
		</Card>
	);
};

export default RecapMainCard;

const RecapRow = ({
	label,
	amount,
	costVal,
	profit,
	cost = 1,
	component = <></>,
}) => {
	const [open, setOpen] = useState(false);

	const toggle = () => {
		setOpen(!open);
	};

	return (
		<Row className="p-1">
			<Col md="2">
				<CardText>
					<Button
						className="active px-1 btn-round btn-sm btn btn-outline-secondary w-100 my-0"
						onClick={toggle}
					>
						{label}
					</Button>
				</CardText>
				<ChildrenModal
					modal={open}
					toggle={toggle}
					size="md"
					modalTitle={label}
					children={component}
				/>
			</Col>
			<Col md="10">
				<Row className="p-2">
					<Col md="3">
						<CurrencyDisplayElement val={amount} />
					</Col>
					<Col md="3">
						{cost === 1 && <CurrencyDisplayElement val={costVal} />}
					</Col>
					<Col md="3">
						{cost === 2 && <CurrencyDisplayElement val={costVal} />}
					</Col>
					<Col md="3">
						<CurrencyDisplayElement val={profit} />
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

const OfficialFeesBreakdown = () => {
	const {
		titleFee,
		filingFee,
		smogExemption,
		weightFee,
		tireFee,
		bpa,
		tax,
		license,
		registration,
	} = useCurrentSaleStore((state) => state, shallow);

	const { dmvFeeTypes, miscDMVFees } = useCurrentDealStore(
		(state) => state,
		shallow
	);

	const { official } = useCurrentRecapStore((state) => state, shallow);
	const [totalCost, setTotalCost] = useState(0);
	const optionList = [];
	for (const key in dmvFeeTypes) {
		optionList.push({
			label: dmvFeeTypes[key].description,
			value: dmvFeeTypes[key].type,
		});
	}

	const rows = [
		{ label: "License", value: license },
		{ label: "Registration", value: registration },
		{ label: "Title Fee", value: titleFee },
		{ label: "Filing Fee", value: filingFee },
		{ label: "Smog Exemption", value: smogExemption },
		{ label: "Weight Fee", value: weightFee },
		{ label: "Tire Fee", value: tireFee },
		{ label: "BPA", value: bpa },
		{ label: "Tax", value: tax },
	];

	const DmvFees = () => {
		let total = 0;
		const fees = miscDMVFees.map((key) => {
			total += key.cost;
			return (
				<Row>
					<Col>
						<Label>
							{optionList.filter((row) => row.value === key.type)[0]?.label}
						</Label>
					</Col>
					<Col>
						<CurrencyDisplayElement val={key.amount} />
					</Col>
					<Col>
						<CurrencyDisplayElement val={key.cost} />
					</Col>
				</Row>
			);
		});
		setTotalCost(total);
		return fees;
	};

	return (
		<Card>
			<CardBody>
				<CardText>
					<Row>
						<Col>
							<Label className="h6">Fee</Label>
						</Col>
						<Col>
							<Label className="h6">Amount</Label>
						</Col>
						<Col>
							<Label className="h6">Cost</Label>
						</Col>
					</Row>
					<hr />
					<>
						{rows.map((obj) => (
							<Row>
								<Col>
									<Label>{obj.label}</Label>
								</Col>
								<Col>
									<CurrencyDisplayElement val={obj.value} />
								</Col>
								<Col>N/A</Col>
							</Row>
						))}
					</>
					<hr />
					<DmvFees />
					<hr />
					<Row>
						<Col>
							<Label className="h6">Total</Label>
						</Col>
						<Col>
							<CurrencyDisplayElement val={official} />
						</Col>
						<Col>
							<CurrencyDisplayElement val={totalCost} />
						</Col>
					</Row>
				</CardText>
			</CardBody>
		</Card>
	);
};

const VehicleBreakdown = () => {
	const { price } = useCurrentSaleStore((state) => state, shallow);
	const {
		cost,
		totalOtherCost,
		totalRecon,
		flooring,
		pack,
		totalCost,
	} = useCurrentDealStore(
		(state) => ({
			cost: state.vehicle?.cost || 0,
			totalOtherCost: state.vehicle?.totalOtherCost || 0,
			totalRecon: state.vehicle?.totalRecon || 0,
			totalCost: state.vehicle?.totalCost || 0,
			pack: state.vehicle?.pack || 0,
			flooring: state.vehicle?.flooring || 0,
		}),
		shallow
	);

	const rows = [
		{ label: "Price", amount: price, cost: cost },
		{ label: "Total Recon", amount: 0, cost: totalRecon },
		{ label: "Total Flooring", amount: 0, cost: flooring },
		{ label: "Total Other", amount: 0, cost: totalOtherCost },
		{ label: "Pack", amount: 0, cost: pack },
	];

	return (
		<Card>
			<CardBody>
				<CardText>
					<Row>
						<Col>
							<Label className="h6">Fee</Label>
						</Col>
						<Col>
							<Label className="h6">Amount</Label>
						</Col>
						<Col>
							<Label className="h6">Cost</Label>
						</Col>
					</Row>
					<hr />
					<>
						{rows.map((obj) => (
							<Row>
								<Col>
									<Label>{obj.label}</Label>
								</Col>
								<Col>
									<CurrencyDisplayElement val={obj.amount} />
								</Col>
								<Col>
									<CurrencyDisplayElement val={obj.cost} />
								</Col>
							</Row>
						))}
					</>
					<hr />
					<Row>
						<Col>
							<Label className="h6">Total</Label>
						</Col>
						<Col>
							<CurrencyDisplayElement val={price} />
						</Col>
						<Col>
							<CurrencyDisplayElement val={totalCost} />
						</Col>
					</Row>
				</CardText>
			</CardBody>
		</Card>
	);
};

const AftermarketBreakdown = () => {
	const { aftermarket } = useCurrentDealStore((state) => state, shallow);
	const [totalCost, setTotalCost] = useState(0);
	const [totalPrice, setTotalPrice] = useState(0);
	const AfterMarketFees = () => {
		let tempTotalCost = 0;
		let tempTotalPrice = 0;
		const fees = aftermarket.map((key) => {
			tempTotalPrice += key.price * key.qty;
			tempTotalCost += key.cost * key.qty;
			return (
				<Row>
					<Col>
						<Label>{key.description}</Label>
					</Col>
					<Col>
						<CurrencyDisplayElement val={key.price * key.qty} />
					</Col>
					<Col>
						<CurrencyDisplayElement val={key.cost * key.qty} />
					</Col>
				</Row>
			);
		});
		setTotalCost(tempTotalCost);
		setTotalPrice(tempTotalPrice);
		return fees;
	};

	return (
		<Card>
			<CardBody>
				<CardText>
					<Row>
						<Col>
							<Label className="h6">Fee</Label>
						</Col>
						<Col>
							<Label className="h6">Amount</Label>
						</Col>
						<Col>
							<Label className="h6">Cost</Label>
						</Col>
					</Row>
					<hr />
					<AfterMarketFees />
					<hr />
					<Row>
						<Col>
							<Label className="h6">Total</Label>
						</Col>
						<Col>
							<CurrencyDisplayElement val={totalPrice} />
						</Col>
						<Col>
							<CurrencyDisplayElement val={totalCost} />
						</Col>
					</Row>
				</CardText>
			</CardBody>
		</Card>
	);
};

const TradeBreakdown = () => {
	const { trades } = useCurrentDealStore((state) => state, shallow);
	const [totalAcv, setTotalAcv] = useState(0);
	const [allowance, setAllowance] = useState(0);
	const [payoff, setPayoff] = useState(0);

	const Trades = () => {
		let tempAcv = 0;
		let tempAllowance = 0;
		let tempPayoff = 0;
		const fees = trades.map((key) => {
			tempAcv += key.acv;
			tempAllowance += key.allowance;
			tempPayoff += key.payoff;
			return (
				<Row>
					<Col>
						<Label>
							{key?.car?.year + " " + key?.car?.make + " " + key?.car?.model}
						</Label>
					</Col>
					<Col>
						<CurrencyDisplayElement val={key.acv} />
					</Col>
					<Col>
						<CurrencyDisplayElement val={key.allowance} />
					</Col>
					<Col>
						<CurrencyDisplayElement val={key.payoff} />
					</Col>
				</Row>
			);
		});

		setTotalAcv(tempAcv);
		setAllowance(tempAllowance);
		setPayoff(tempPayoff);

		return fees;
	};

	return (
		<Card>
			<CardBody>
				<CardText>
					<Row>
						<Col>
							<Label className="h6">Car</Label>
						</Col>
						<Col>
							<Label className="h6">ACV</Label>
						</Col>
						<Col>
							<Label className="h6">Allowance</Label>
						</Col>
						<Col>
							<Label className="h6">Payoff</Label>
						</Col>
					</Row>
					<hr />
					<Trades />
					<hr />
					<Row>
						<Col>
							<Label className="h6">Total</Label>
						</Col>
						<Col>
							<CurrencyDisplayElement val={totalAcv} />
						</Col>
						<Col>
							<CurrencyDisplayElement val={allowance} />
						</Col>
						<Col>
							<CurrencyDisplayElement val={payoff} />
						</Col>
					</Row>
				</CardText>
			</CardBody>
		</Card>
	);
};

const DownBreakdown = () => {
	const { down, manufacturerRebate, totalNetTrade } = useCurrentSaleStore(
		(state) => state,
		shallow
	);

	const { pickupPmts, downPayments } = useCurrentDealStore(
		(state) => state,
		shallow
	);

	const { totalDown } = useCurrentRecapStore((state) => state, shallow);

	const rows = [
		{ label: "Down Payment", value: down },
		{ label: "Mfr. Rebate", value: manufacturerRebate },
		{ label: "Net Trade", value: totalNetTrade },
	];

	const AllDown = () => {
		const fees = downPayments.map((key, i) => {
			return (
				<Row>
					<Col>
						<Label>Other {i + 1}</Label>
					</Col>
					<Col>
						<CurrencyDisplayElement val={key.amtPaid} />
					</Col>
				</Row>
			);
		});
		return fees;
	};

	const AllDeferred = () => {
		const fees = pickupPmts.map((key, i) => {
			return (
				<Row>
					<Col>
						<Label>Deferred {i + 1}</Label>
					</Col>
					<Col>
						<CurrencyDisplayElement val={key.amount} />
					</Col>
				</Row>
			);
		});
		return fees;
	};

	return (
		<Card>
			<CardBody>
				<CardText>
					<Row>
						<Col>
							<Label className="h6">Type</Label>
						</Col>
						<Col>
							<Label className="h6">Amount</Label>
						</Col>
					</Row>
					<hr />
					<>
						{rows.map((obj) => (
							<Row>
								<Col>
									<Label>{obj.label}</Label>
								</Col>
								<Col>
									<CurrencyDisplayElement val={obj.value} />
								</Col>
							</Row>
						))}
					</>
					<hr />
					<AllDown />
					<hr />
					<AllDeferred />
					<Row>
						<Col>
							<Label className="h6">Total</Label>
						</Col>
						<Col>
							<CurrencyDisplayElement val={totalDown} />
						</Col>
					</Row>
				</CardText>
			</CardBody>
		</Card>
	);
};

const SellerFeesBreakdown = () => {
	const {
		dealerSmog,
		docFee,
		stateSmog,
		totalSellerFees,
	} = useCurrentSaleStore((state) => state, shallow);

	const { miscSellerFees } = useCurrentDealStore((state) => state, shallow);

	const [totalCost, setTotalCost] = useState(0);

	const rows = [
		{ label: "Dealer Smog", value: dealerSmog },
		{ label: "Doc Fee", value: docFee },
		{ label: "State Smog", value: stateSmog },
	];

	const AllMiscFees = () => {
		let tempCost = 0;
		const fees = miscSellerFees.map((key) => {
			tempCost += key.cost;
			return (
				<Row>
					<Col>
						<Label>{key.description}</Label>
					</Col>
					<Col>
						<CurrencyDisplayElement val={key.amount} />
					</Col>
					<Col>
						<CurrencyDisplayElement val={key.cost} />
					</Col>
				</Row>
			);
		});
		setTotalCost(tempCost);
		return fees;
	};

	return (
		<Card>
			<CardBody>
				<CardText>
					<Row>
						<Col>
							<Label className="h6">Fee</Label>
						</Col>
						<Col>
							<Label className="h6">Amount</Label>
						</Col>
						<Col>
							<Label className="h6">Cost</Label>
						</Col>
					</Row>
					<hr />
					<>
						{rows.map((obj) => (
							<Row>
								<Col>
									<Label>{obj.label}</Label>
								</Col>
								<Col>
									<CurrencyDisplayElement val={obj.value} />
								</Col>
								<Col>
									<Label>N/A</Label>
								</Col>
							</Row>
						))}
					</>
					<hr />
					<AllMiscFees />
					<hr />
					<Row>
						<Col>
							<Label className="h6">Total</Label>
						</Col>
						<Col>
							<CurrencyDisplayElement val={totalSellerFees} />
						</Col>
						<Col>
							<CurrencyDisplayElement val={totalCost} />
						</Col>
					</Row>
				</CardText>
			</CardBody>
		</Card>
	);
};

const ServiceContractBreakdown = () => {
	const { warranty } = useCurrentSaleStore((state) => state, shallow);
	const { warrantyList } = useCurrentDealStore(
		(state) => ({
			warrantyList: state.warranty,
		}),
		shallow
	);
	const { warrantyCost } = useCurrentRecapStore((state) => state, shallow);

	const AllServConFees = () => {
		const fees = warrantyList.map((key) => {
			return (
				<Row>
					<Col>
						<Label>{key.description}</Label>
					</Col>
					<Col>
						<CurrencyDisplayElement val={key.amount} />
					</Col>
					<Col>
						<CurrencyDisplayElement val={key.cost} />
					</Col>
				</Row>
			);
		});
		return fees;
	};

	return (
		<Card>
			<CardBody>
				<CardText>
					<Row>
						<Col>
							<Label className="h6">Fee</Label>
						</Col>
						<Col>
							<Label className="h6">Amount</Label>
						</Col>
						<Col>
							<Label className="h6">Cost</Label>
						</Col>
					</Row>
					<hr />
					<AllServConFees />
					<hr />
					<Row>
						<Col>
							<Label className="h6">Total</Label>
						</Col>
						<Col>
							<CurrencyDisplayElement val={warranty} />
						</Col>
						<Col>
							<CurrencyDisplayElement val={warrantyCost} />
						</Col>
					</Row>
				</CardText>
			</CardBody>
		</Card>
	);
};

const GapInsuranceBreakdown = () => {
	// const {
	// 	bodyInjuryPremium,
	// 	collisionPremium,
	// 	compPremium,
	// 	medicalPremium,
	// 	otherPremium,
	// 	propertyPremium,
	// } = useCurrentDealStore((state) => state?.collisionInsurance, shallow);
	const {
		collisionTotPremium,
		gap,
		lifePremium,
		disabilityPremium,
	} = useCurrentSaleStore((state) => state, shallow);
	const {
		gapInsCost,
		collisionTotCost,
		creditLifeCost,
		disabCost,
	} = useCurrentRecapStore((state) => state, shallow);

	// const rows = [
	// 	{ label: "Body injury Premium", value: bodyInjuryPremium || 0 },
	// 	{ label: "Collision Premium", value: collisionPremium || 0 },
	// 	{ label: "Comp. Premium", value: compPremium || 0 },
	// 	{ label: "Medical Premium", value: medicalPremium || 0 },
	// 	{ label: "Other Premium", value: otherPremium || 0 },
	// 	{ label: "Property Premium", value: propertyPremium || 0 },
	// ];

	return (
		<Card>
			<CardBody>
				<CardText>
					<Row>
						<Col>
							<Label className="h6">Fee</Label>
						</Col>
						<Col>
							<Label className="h6">Amount</Label>
						</Col>
						<Col>
							<Label className="h6">Cost</Label>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<Label>Gap</Label>
						</Col>
						<Col>
							<CurrencyDisplayElement val={gap} />
						</Col>
						<Col>
							<CurrencyDisplayElement val={gapInsCost} />
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<Label>Collision</Label>
						</Col>
						<Col>
							<CurrencyDisplayElement val={collisionTotPremium} />
						</Col>
						<Col>
							<CurrencyDisplayElement val={collisionTotCost} />
						</Col>
					</Row>
					<hr />

					<Row>
						<Col>
							<Label>Credit Life</Label>
						</Col>
						<Col>
							<CurrencyDisplayElement val={lifePremium} />
						</Col>
						<Col>
							<CurrencyDisplayElement val={creditLifeCost} />
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<Label>Disability</Label>
						</Col>
						<Col>
							<CurrencyDisplayElement val={disabilityPremium} />
						</Col>
						<Col>
							<CurrencyDisplayElement val={disabCost} />
						</Col>
					</Row>
					<hr />

					<Row>
						<Col>
							<Label className="h6">Total</Label>
						</Col>
						<Col>
							<CurrencyDisplayElement
								val={
									gap + collisionTotPremium + lifePremium + disabilityPremium
								}
							/>
						</Col>
						<Col>
							<CurrencyDisplayElement
								val={gapInsCost + collisionTotCost + creditLifeCost + disabCost}
							/>
						</Col>
					</Row>
				</CardText>
			</CardBody>
		</Card>
	);
};
