import React, { useEffect, useState } from "react";
import shallow from "zustand/shallow";
import { Card, CardBody, Row } from "reactstrap";

import history from "../../helperFunction/History";
import useCurrentCustomerStore from "../../store/CustomerStore";
import useCurrentDealStore from "../../store/DealStore";
import useRoute from "../../helperFunction/UseRoute";
import CustomerTable from "../../component/dc/table/CustomerTable";
import Loading from "../../component/dc/load/Loading";
import { failAlert } from "../../component/dc/modal/SweetAlerts";
import { getAllCustomerInfo, getCustomerList } from "../../api/CustomerAPI";
import { getCustomersCurrentAddress } from "../../api/CustomerAPI";
import { showApiError } from "../../helperFunction/ApiErrorHandler";
import { retrieveFailAlert } from "../../component/dc/modal/SweetAlerts";
import { updateSoldCustomer } from "../../api/CustomerAPI";
import { useFromUser } from "../../store/LocalStorageHelper";
import { CustomerStatus } from "../../Constants";
import { PATHS } from "../../Constants";
import DealTemplateModal from "../../view/settings/settingsTabs/dealTemplates/DealTemplateModal";
import ChildrenModal from "../../component/dc/modal/ChildrenModal";
// import { getCustomerListByStatus } from "../../api/CustomerAPI";
import useCurrentSaleStore from "../../store/SaleStore";
import Twilio from "../../component/dc/integration/twilio/Twilio";
import MailgunEmail from "../../component/dc/integration/mailgun/MailgunEmail";
//import NavTabsHolder from "../../component/dc/tab/NavTabsHolder";
import OnlineLeadListPage from "./leads/OnlineLeadListPage";
import CustomerHeader from "./CustomerHeader";
import { getCreditAppListByStatus } from "../../api/CreditAppApi";
import { getLeadListByStatus } from "../../api/LeadApi";
import TwilioSendMessage from "../../component/dc/integration/twilio/TwilioSendMessage";
import { vendorTypesOBJ } from "Constants";
import { getVendorListByType } from "api/VendorsAPI";
import VendorsTable from "component/dc/table/VendorTable";
import OnlineCreditAppListPage from "./creditApps/OnlineCreditAppListPage";

const CustomerListData = ({
	isDeal = false,
	toggle,
	editCustomer,
	editAddress,
	editRecNum,
	initStatus,
}) => {
	// Session storage
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	//toggle template modal
	const [templateModal, setTemplateModal] = useState(false);
	const toggleTemplate = () => setTemplateModal(!templateModal);

	// Routes
	const customerDetailsRoute = useRoute(PATHS.CUSTOMER_DETAIL);
	const leadDetailsRoute = useRoute(PATHS.LEAD_DETAIL);

	// Local states
	const [allCustomers, setAllCustomers] = useState([]);
	//const [allLostCustomers, setAllLostCustomers] = useState([]);
	const [leadCount, setLeadCount] = useState(0);
	const [creditAppCount, setCreditAppCount] = useState(0);
	const [loadingList, setLoadingList] = useState(true);
	const [customer, setCustomer] = useState({});
	const [allMessageCustomers, setAllMessageCustomers] = useState([]);
	const [messageCustomers, setMessageCustomers] = useState([]);
	const [messageLeads, setMessageLeads] = useState([]);
	const [messageCreditApps, setMessageCreditApps] = useState([]);
	const [status, setStatus] = useState(initStatus || "Leads");
	const [viewTextModal, setViewTextModal] = useState(false);
	const [textHistoryNumber, setTextHistoryNumber] = useState();
	const [customerID, setCustomerID] = useState(null);
	const [lenderCount, setLenderCount] = useState(0);

	// Text message modal
	const toggleViewTextModal = () => setViewTextModal(!viewTextModal);

	// Customer store
	const { editID } = useCurrentCustomerStore(
		(state) => ({
			editID: state.editID,
		}),
		shallow
	);

	//deal store
	const {
		buyer,
		coBuyer,
		editBuyerRecNum,
		editBuyer,
		editBuyerAddress,
	} = useCurrentDealStore(
		(state) => ({
			buyer: state.buyer,
			coBuyer: state.coBuyer,
			editBuyerRecNum: state.editBuyerRecNum,
			editBuyer: state.editBuyer,
			editBuyerAddress: state.editBuyerAddress,
		}),
		shallow
	);

	//customer store
	const { saleType } = useCurrentSaleStore(
		(state) => ({
			saleType: state.saleType,
		}),
		shallow
	);

	// Formats data for the list table
	const dataFormat = (content) => {
		const container = Object.keys(content).map((key) => {
			let container = {};

			container.ID = content[key].ID;
			container.firstName = content[key].firstName;
			container.lastName = content[key].lastName;
			container.name = `${content[key].firstName || ""} ${
				content[key].lastName
			}`;
			container.homePhone = content[key].homePhone;
			container.cellPhone = content[key].cellPhone;
			container.email = content[key].email;
			container.license =
				content[key].license != null ? content[key].license : "";
			container.customerType = content[key].type;
			container.Actions = (
				<Row>
					<div
						className="zoom btn-md btn btn-link btn-link-success"
						onClick={() => {
							setTextHistoryNumber(content[key].cellPhone);
							setCustomerID(content[key].ID);
							toggleViewTextModal();
						}}
					>
						<i className="icon-big text-center nc-icon nc-chat-33 text-default" />
						<span className="noMobile">Chat</span>
					</div>
					{isDeal ? null : (
						<div
							className="zoom btn-md btn btn-primary"
							onClick={() => {
								handleEdit(content[key]);
							}}
						>
							<i className="icon-big text-center nc-icon nc-zoom-split text-default"></i>{" "}
							<span className="noMobile"> View</span>
						</div>
					)}
					<div
						className="zoom btn-md btn btn-link btn-link-success"
						//disable the button when it is wholeSale deal and customer type is not business on deal page
						readOnly={
							isDeal
								? (saleType === 1 || saleType === 2) &&
								  (content[key].type === 0 || content[key].type === null)
								: null
						}
						onClick={() => {
							checkHandleDeal(content[key]);
							console.log(saleType + content[key].type);
						}}
					>
						<i className="icon-big text-center nc-icon nc-briefcase-24"></i>{" "}
						<span className="noMobile"> Deal</span>{" "}
					</div>
				</Row>
			);
			return container;
		});
		return container;
	};

	// Returns true if selected customer is in a sold deal
	const shouldCloneCustomer = (status) => status === CustomerStatus.SOLD;

	// Calls api to get all current customer info and then api to clone the info as new entries
	const getNewCustomer = async (currentID) => {
		try {
			const allInfo = await getAllCustomerInfo(currentID);
			const newCustomerInfo = await updateSoldCustomer(allInfo.data.content);
			console.log(newCustomerInfo);
			//setCustomer(newCustomerInfo);
			return newCustomerInfo.data.content.customer;
		} catch (err) {
			if (!err.isGeneralError) {
				showApiError(err, retrieveFailAlert);
			}
		}
	};

	// Handles the edit click
	const handleEdit = async (customer) => {
		let { ID: customerID } = customer;

		if (shouldCloneCustomer(customer.customerStatus)) {
			let newCust = await getNewCustomer(customerID);

			customerID = newCust?.ID;
		}

		editID(customerID);

		if (status === "Leads") {
			history.push(leadDetailsRoute, { ID: customerID });
		} else {
			history.push(customerDetailsRoute, { ID: customerID });
		}
	};

	//check the cobuyer and buyer is the same
	const checkHandleDeal = (customer) => {
		let canHandleDeal = true;
		if (isDeal) {
			if (buyer?.ID) {
				if (
					buyer?.originalID === customer.originalID ||
					coBuyer?.originalID === customer.originalID
				) {
					failAlert("Already selected as Buyer or Co-Buyer");
					canHandleDeal = false;
				}
			}
		}
		if (canHandleDeal) handleDeal(customer);
	};

	// Calls endpoint to clone customer information and then route to deal add page
	const handleDeal = async (customer) => {
		let currentCustomer = { ...customer };

		if (shouldCloneCustomer(customer.customerStatus)) {
			currentCustomer = await getNewCustomer(customer.ID);
		}

		setCustomer(currentCustomer);
		getAddress(currentCustomer);

		if (!isDeal) {
			toggleTemplate();
			editBuyerRecNum(currentCustomer.ID);
		} else {
			editRecNum(currentCustomer.ID);
			toggle();
		}
	};

	// Gets the customer or lead list data and and calls function to format it for the table
	const handleListResponse = (data, setData) => {
		const result = dataFormat(data);
		console.log(result);
		let res = result;
		if (saleType === 1 || saleType === 2) {
			res = result.filter((item) => item.customerType === 1);
		}
		setData(res);

		setLoadingList(false);
	};

	const getCustomers = () => {
		console.log("ID: " + dealerID + " " + locationID + " ");

		if (dealerID != null && locationID != null) {
			getCustomerList(dealerID, locationID).then(
				(response) =>
					handleListResponse(response.data.content.customers, setAllCustomers),
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}
	};

	// Gets a list of all the Leads for this dealer and location
	const getOnlineLeads = () => {
		getLeadListByStatus(locationID, 0).then(
			(response) => setLeadCount(response.data.content.NumOfRecord),
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	// Gets a list of all the Lenders for  location
	const getLendersList = () => {
		getVendorListByType(locationID, vendorTypesOBJ.LENDER.value).then(
			(response) => {
				console.log(response);
				setLenderCount(response.data.content.length);
			}
		);
	};

	// Gets a list of all the CreditApps for this dealer and location
	const getCreditApps = () => {
		getCreditAppListByStatus(locationID, 1).then(
			(response) => setCreditAppCount(response.data.content.CreditApp.length),
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	// Gets customers current address
	const getAddress = (customer) => {
		if (customer.ID != null) {
			getCustomersCurrentAddress(customer.ID).then(
				(res) => {
					if (!isDeal) {
						editBuyerRecNum(customer.ID);
						editBuyer(customer);
						editBuyerAddress(res.data.content);
					} else {
						editRecNum(customer.ID);
						editAddress(res.data.content);
						editCustomer(customer);
					}
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		} else {
			failAlert("Failed to add customer to deal. Please try again later.");
		}
	};

	useEffect(() => {
		setAllMessageCustomers([
			...messageCustomers,
			...messageLeads,
			...messageCreditApps,
		]);
		console.log([...messageCustomers, ...messageLeads, ...messageCreditApps]);
	}, [messageCustomers, messageLeads, messageCreditApps]);

	useEffect(() => {
		if (status === "Customers") {
			setLoadingList(true);
			getCustomers();
		} else if (status === "Lost") {
			setLoadingList(true);
		}
		// eslint-disable-next-line
	}, [status]);
	const getAll = () => {
		getCustomers();
		getOnlineLeads();
		getCreditApps();
		getLendersList();
	};

	useEffect(() => {
		if (isDeal) {
			getCustomers();
		} else {
			getAll();
		}
		// eslint-disable-next-line
	}, []);

	return (
		<div className="content">
			{loadingList && <Loading />}
			{!loadingList && (
				<>
					{!isDeal && (
						<>
							<div className="pl-3 pb-3 d-flex justify-content-center align-items-center">
								<Twilio
									blastMessage
									customers={allMessageCustomers}
									setCustomers={setAllMessageCustomers}
								/>
								<MailgunEmail
									blastMessage
									customers={allMessageCustomers}
									setCustomers={setAllMessageCustomers}
								/>
							</div>
							<CustomerHeader
								status={status}
								setStatus={setStatus}
								leadCount={leadCount}
								creditAppCount={creditAppCount}
								customerCount={allCustomers.length}
								lenderCount={lenderCount}
							/>
							{status === "Leads" && (
								<OnlineLeadListPage
									setMessageCustomers={setMessageLeads}
									getAll={getOnlineLeads}
								/>
							)}
							{status === "Credit Apps" && (
								<OnlineCreditAppListPage
									setMessageCustomers={setMessageCreditApps}
									getAll={getCreditApps}
								/>
							)}
							{status === "Customers" && (
								<CustomerTable
									data={allCustomers}
									setMessageCustomers={setMessageLeads}
								/>
							)}
							{status === "Lenders" && (
								<Card>
									<CardBody>
										<VendorsTable refresh={false} type={30} showChat={true} />
									</CardBody>
								</Card>
							)}
						</>
					)}

					{isDeal && (
						<CustomerTable
							data={allCustomers}
							setMessageCustomers={setMessageCustomers}
						/>
					)}
				</>
			)}
			{!isDeal && (
				<ChildrenModal
					modalTitle="Deal Templates"
					modal={templateModal}
					toggle={toggleTemplate}
				>
					<DealTemplateModal customer={customer} />
				</ChildrenModal>
			)}
			<ChildrenModal
				size="lg"
				modal={viewTextModal}
				toggle={toggleViewTextModal}
				modalTitle="Send Message"
			>
				<TwilioSendMessage
					sendToNumber={textHistoryNumber}
					customerID={customerID}
				/>
			</ChildrenModal>
		</div>
	);
};

export default CustomerListData;
