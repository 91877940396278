import React, { useState } from "react";

import Pepperpay from "./pepperpay/Pepperpay";
import Digitzs from "./digitzs/Digitzs";
import { Card, CardBody, Col, Row } from "reactstrap";
import { getDigitzsMerchant } from "api/PaymentsAPI";
import {
	contactUAC,
	failAlert,
	retrieveFailAlert,
	saveSuccessAlert,
} from "../../../../component/dc/modal/SweetAlerts";
import { showApiError } from "../../../../helperFunction/ApiErrorHandler";
import { useFromUser } from "store/LocalStorageHelper";
import ChildrenModal from "../../../../component/dc/modal/ChildrenModal";
import useCurrentDealerLinkStore from "../../../../store/DealerLinkStore";
import shallow from "zustand/shallow";
import useCurrentLoginStore from "../../../../store/LoginStore";
import {
	getPartnerSend,
	linkUACDealer,
} from "../../../../api/dealerIntegrationsApi";
import pepperpayLogo from "assets/img/pepperpay.svg";
import UAClogo from "assets/img/UACC.svg";
import DigitzsImg from "assets/img/digitzs-final-logo.png";
import AULButton from "./aul/AUL";
import credit700Logo from "assets/img/700CreditLogo2.png";
import { useEffect } from "react";

const SignUps = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const [uacc, setUacc] = useState(0);

	const {
		uacdealerID,
		editUacdealerID,
		pepperPayEmail,
	} = useCurrentDealerLinkStore((state) => state, shallow);
	const { taxID } = useCurrentLoginStore((state) => state, shallow);
	const [digitzsModal, setDigitzsModal] = useState(false);
	const [pepperpayModal, setPepperpayModal] = useState(false);

	const toggleDigitzsModalApi = () => {
		getDigitzsMerchant(dealerID, locationID).then(
			(res) => {
				if (res.data.content !== null && res.data.content.merchantId != null) {
					failAlert(
						"Digitzs account already exists for this dealership with merchant ID: " +
							res.data.content.merchantId
					);
					return;
				}
				setDigitzsModal(!digitzsModal);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
					setDigitzsModal(!digitzsModal);
				}
			}
		);
	};

	const togglePepperpay = () => {
		setPepperpayModal(!pepperpayModal);
	};

	// LocationIDs for internal dealerships
	// const showPepperPay = locationID === 7347 || locationID === 5456;

	const toggleUacApi = () => {
		if (!taxID || taxID === "") {
			failAlert("No taxID found, contact DealerClick to add taxID...");
		} else {
			console.log(taxID);
			linkUACDealer(locationID).then(
				(res) => {
					editUacdealerID(res.data.UaccDealerId);
					saveSuccessAlert("UAC linked!");
				},
				(err) => {
					contactUAC(err.response.data.message);
				}
			);
		}
	};

	const toggleDigitzsModal = () => setDigitzsModal(!digitzsModal);
	const togglePepperpayModal = () => setPepperpayModal(!pepperpayModal);
	const commitData = () => {
		window.open(
			"https://www.700credit.com/dealerclick",
			"_blank",
			"noopener,noreferrer"
		);
	};
	useEffect(() => {
		getPartnerSend(locationID).then(
			(res) => {
				setUacc(res.data.content.useUACC);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
		// eslint-disable-next-line
	}, [locationID]);
	console.log(uacdealerID.length);
	return (
		<div classname="d-flex flex-column justify-content-center align-items-center">
			<Row>
				<ListItemModal
					modalListTitle="Digitzs"
					modalButtonTitle="Sign Up"
					modalTitle="Digitzs Sign Up"
					modal={digitzsModal}
					toggle={toggleDigitzsModalApi}
					imageLogo={DigitzsImg}
					numCol={3}
					disabled={true}
				>
					<Digitzs toggle={toggleDigitzsModal} />
				</ListItemModal>

				<ListItemModal
					modalListTitle="UACC"
					modalButtonTitle="Sign Up"
					toggle={toggleUacApi}
					imageLogo={UAClogo}
					disabled={uacdealerID !== "" && uacdealerID !== null && uacc === 1}
				/>
				<ListItemModal
					modalListTitle="Pepperpay"
					modalButtonTitle="Sign Up"
					modalTitle="Pepperpay Sign Up"
					modal={pepperpayModal}
					toggle={togglePepperpay}
					imageLogo={pepperpayLogo}
					disabled={pepperPayEmail !== "" && pepperPayEmail !== null}
				>
					<Pepperpay toggle={togglePepperpayModal} />
				</ListItemModal>
				<ListItemModal
					modalListTitle="700Credit"
					modalButtonTitle="Sign Up"
					toggle={commitData}
					imageLogo={credit700Logo}
					disabled={false}
				/>

				<Col lg="2" md="2" sm="2">
					<Card>
						<AULButton />
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default SignUps;

const ListItemModal = ({
	modal,
	toggle,
	modalListTitle,
	modalButtonTitle,
	modalTitle,
	children,
	disabled = false,
	imageLogo = "",
	numCol = 2,
}) => {
	return (
		!disabled && (
			<Col lg={numCol} md={numCol} sm={numCol}>
				<Card className="mx-2" onClick={toggle}>
					<CardBody className="text-center">
						{imageLogo !== "" && (
							<img alt={modalListTitle} src={imageLogo} width="" height="" />
						)}
						<br />
						<div className="btn btn-info btn-sm">SignUp</div>
					</CardBody>
				</Card>

				<ChildrenModal
					toggle={toggle}
					modal={modal}
					children={children}
					modalTitle={modalTitle}
				/>
			</Col>
		)

		// <div className="row row-sm-12 d-flex justify-content-center align-items-center">
		// 	{imageLogo !== "" && (
		// 		<div className="col-sm-3 m-0 p-0">
		// 			<div className="miniLogo">
		// 				<img className="pr-2" src={imageLogo} alt="react-logo" />
		// 			</div>
		// 		</div>
		// 	)}
		// 	{imageLogo === "" && (
		// 		<h4 className="col-sm-3 m-0 p-0">{modalListTitle}</h4>
		// 	)}

		// 	<div className="col-sm-2 m-0 p-0">
		// 		<Button size="md" onClick={toggle} disabled={disabled}>
		// 			{modalButtonTitle}
		// 		</Button>
		// 	</div>
		// 	<ChildrenModal
		// 		toggle={toggle}
		// 		modal={modal}
		// 		children={children}
		// 		modalTitle={modalTitle}
		// 	/>
		// </div>
	);
};
