import React, { useEffect, useState } from "react";
import { Button, Card } from "reactstrap";

import { getTwilioMessageTemplates } from "../../../../api/TwilioApi";
import { failAlert, showSwalMessage } from "../../modal/SweetAlerts";
import { sendMailgunBlastEmail } from "../../../../api/dealerIntegrationsApi";
import { showApiError } from "../../../../helperFunction/ApiErrorHandler";
import InputElement, {
	SelectorElement,
} from "../../../../view/formValidation/InputElement";

import { object, setLocale, string } from "yup";
import { yupResolver } from "@hookform/resolvers";
import { FormProvider, useForm } from "react-hook-form";
import { MessageTemplateVariablesAndInput } from "../../../../view/communication/template/MessageTemplateVariables";

const MailgunSendBlastEmail = ({ customers, setCustomers, toggle }) => {
	// Get logged in user's email
	const dealerEmail = JSON.parse(sessionStorage.getItem("user"))?.locations
		?.email;
	//	const dealerEmail = JSON.parse(sessionStorage.getItem("user"))?.email;
	const user = JSON.parse(sessionStorage.getItem("user"));
	const locationID = user?.locationID;

	const IN_DEVELOPMENT = process.env.NODE_ENV === "development";

	// Local states
	const [body, setBody] = useState("");
	const [subject, setSubject] = useState("");
	const [templates, setTemplates] = useState([]);
	const [templateOptions, setTemplateOptions] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState(null);

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
	});

	// Define rules for each input field
	const schema = object().shape({
		blastMailgunFromEmail: string().required(
			"If missing, update in the Settings page"
		),
		//messageBody: string().required(),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	const generateEmailList = () => {
		console.log(customers);
		return customers.map((customer, index) => ({
			ID: customer.ID || customer.customerID || index,
			to: customer.email || "",
			from: dealerEmail,
			subject,
			body,
			customerID: customer.ID || customer.customerID || index,
		}));
	};

	// Calls mailgun api to send the email
	const sendEmail = () => {
		const emails = generateEmailList();

		sendMailgunBlastEmail(emails).then(
			(res) => getBlastErrors(res.data),
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}
			}
		);
	};

	// Checks that all messages were sent successfully and removes
	// the successful messages from the customers array and adds an
	// error message to each request that fail and their specific reason
	const getBlastErrors = (data) => {
		const errors = data.content;
		const unsuccessfulMessages = customers.filter(
			(customer) => errors[customer.ID] != null
		);
		const customersWithErrors = unsuccessfulMessages.map((customer) => ({
			...customer,
			error: errors[customer.ID],
		}));

		if (customersWithErrors.length > 0) {
			showSwalMessage("Warning", data.message, "warning");
		} else {
			toggle();
			showSwalMessage("Success", data.message, "success");
		}

		setCustomers(customersWithErrors);
	};

	useEffect(() => {
		getTwilioMessageTemplates(locationID).then(
			(res) => {
				const data = res.data.content;
				const options = [];
				let templateList = {};

				for (let i = 0; i < data.length; i++) {
					const option = {
						label: data[i].name,
						value: data[i].ID,
					};

					options.push(option);
					templateList[data[i].ID] = { ...data[i] };
				}

				setTemplateOptions(options);
				setTemplates(templateList);
			},
			(err) => console.log(err)
		); // eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (selectedTemplate != null) {
			setBody(templates?.[selectedTemplate]?.body || "");
			setSubject(templates?.[selectedTemplate]?.subject || "");
		}
		// eslint-disable-next-line
	}, [selectedTemplate]);

	return (
		<FormProvider {...methods}>
			<Card>
				<div className="row row-12 mt-2">
					<InputElement
						{...methods}
						readOnly
						colSize="col-lg-6"
						value={dealerEmail}
						name="blastMailgunFromEmail"
						label="Your Email"
					/>
					{templateOptions.length > 0 && (
						<SelectorElement
							{...methods}
							colSize="col-lg-6"
							value={selectedTemplate}
							name="blastTwilioMessageTemplateOptions"
							label="Message Template"
							onChange={(e) => setSelectedTemplate(e.target.value)}
							options={templateOptions}
						/>
					)}
				</div>
				<div className="row row-12">
					<InputElement
						{...methods}
						colSize="col-lg-12"
						value={subject}
						onChange={(e) => setSubject(e.target.value)}
						name="blastMailgunSubject"
						label="Subject"
					/>
				</div>
				<MessageTemplateVariablesAndInput body={body} updateBody={setBody} />
				<div className="col-lg-12 d-flex justify-content-center align-items-center mb-2">
					<Button onClick={methods.handleSubmit(sendEmail)}>Send</Button>
					{IN_DEVELOPMENT && (
						<Button onClick={() => console.log(generateEmailList())}>
							JSON
						</Button>
					)}
				</div>
			</Card>
		</FormProvider>
	);
};

export default MailgunSendBlastEmail;
