/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)

* Coded by DealerClick Software **RoarinLion))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import NavBar from "component/dc/tab/NavTabsHolder";
import FormsReportsList from "../../component/dc/formsReports/FormsReportsList";
import {
	getINV_AllReports,
	getMGMNT_AllReports,
	getSALES_AllReports,
	getCUSTOM_AllReports,
} from "api/FormsAPI";
import { useFromUser } from "store/LocalStorageHelper";
import { Col } from "reactstrap";
import { TemplateVariableProvider } from "../../helperFunction/TemplateVariableContext";

const ReportsPage = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const params = { dealerID, locationID };

	const tabs = ["Inventory", "Management", "Sales", "Requested"];

	const tabComponents = [
		<FormsReportsList
			listTitle="Inventory Reports"
			apiFunction={getINV_AllReports}
			apiParams={params}
			searchable={true}
		/>,
		<FormsReportsList
			listTitle="Management Reports"
			apiFunction={getMGMNT_AllReports}
			apiParams={params}
			searchable={true}
		/>,
		<FormsReportsList
			listTitle="Sales Reports"
			apiFunction={getSALES_AllReports}
			apiParams={params}
			searchable={true}
		/>,
		<FormsReportsList
			listTitle="Requested Reports"
			apiFunction={getCUSTOM_AllReports}
			apiParams={params}
			searchable={true}
		/>,
	];
	return (
		<TemplateVariableProvider>
			{/*No template variable initializer needed */}
			<div className="mx-0 content justify-content-center align-items-center">
				<Col>
					<NavBar
						navClass="nav-pills nav-pills-primary justify-content-center nav"
						tabs={tabs}
						tabComponents={tabComponents}
					/>
				</Col>
			</div>
		</TemplateVariableProvider>
	);
};
export default ReportsPage;
