import React, { useState } from "react";
import { Button, Form } from "reactstrap";

import tabOnEnter from "../../../helperFunction/tabOnEnter";
import DeleteAttachment from "./DeleteAttachment";
import InputWrapper from "../../../view/formValidation/InputWrapper";

import { ATTACHMENT_CATEGORY } from "../../../Constants";
import { saveFailAlert, saveSuccessAlert } from "../modal/SweetAlerts";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";
import { updateAttachmentInfo } from "../../../api/AttachmentsAPI";
import InputElement, {
	SelectorElement,
} from "../../../view/formValidation/InputElement";

import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers";
import { FormProvider, useForm } from "react-hook-form";

/**
 * Component to update a DB file object's information and downloading the
 * actual file.
 *
 * @param {object} file DB attachment object
 * @param {Function} toggleDetailsModal Closes the current details modal
 * @param {React.SetStateAction} setRefreshAttachments Required. Sets state to refresh table data
 */
const AttachmentFileDetails = ({
	file,
	toggleDetailsModal,
	setRefreshAttachments,
}) => {
	// Local states
	const [category, setCategory] = useState(file.category);
	const [description, setDescription] = useState(file.description);

	// Saves a new attachment file info for a file if it doesn't exist
	// Or updates the existing file info for a file
	const saveFileInfo = () => {
		const data = { ...file, category, description };
		updateAttachmentInfo(data).then(
			() => handleResponse(),
			(err) => handleError(err)
		);
	};

	// On successful Api response, close all modals
	const handleResponse = () => {
		saveSuccessAlert();
		toggleDetailsModal();
		setRefreshAttachments((prev) => !prev);
	};

	// Handle Api error
	const handleError = (err) => {
		if (!err.isGeneralError) {
			showApiError(err, saveFailAlert);
		}
	};

	// Define rules for each input field
	const schema = object().shape({
		attachmentCategory: string().required("Required"),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<FormProvider {...methods}>
			<Form onKeyDown={tabOnEnter}>
				<InputWrapper
					inputComponents={[
						<SelectorElement
							{...methods}
							colSize="col-md-3"
							value={category}
							onChange={(e) => setCategory(e.target.value)}
							name="attachmentCategory"
							label="Category"
							options={ATTACHMENT_CATEGORY}
						/>,
						<InputElement
							{...methods}
							colSize="col-md-3"
							disabled
							value={file.fileName}
							name="attachmentFileName"
							label="File Name"
						/>,
						<InputElement
							{...methods}
							colSize="col-md-12"
							value={description}
							onChange={(e) => setDescription(e.target.value)}
							name="attachmentFileDescription"
							label="File Description"
							type="textarea"
						/>,
					]}
					buttons={
						file.canEdit && (
							<div className="d-flex justify-content-between col my-2">
								<DeleteAttachment
									dcAttachmentId={file.ID}
									toggleDetailsModal={toggleDetailsModal}
									setRefreshAttachments={setRefreshAttachments}
								/>
								<Button
									color="primary"
									onClick={methods.handleSubmit(saveFileInfo)}
								>
									Save
								</Button>
							</div>
						)
					}
				/>
			</Form>
		</FormProvider>
	);
};

export default AttachmentFileDetails;
