/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)

* Coded by DealerClick Software **Peyman Sabery))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import NumberFormat from "react-number-format";
import ReactTable from "./ReactTable";
import { LeadColumnFilter } from "component/dc/table/SelectColumnFilter";

const LeadTable = ({ data, setMessageCustomers }) => {
	const Cell = (props) => (
		<div>
			<span style={{ fontWeight: "bold" }}>
				<div>
					{props.row.original.lastName} {props.row.original.firstName}
				</div>
				<div>{props.row.original.origin}</div>
				<div style={{ wordBreak: "break-all" }}>{props.row.original.email}</div>
				<div>
					P:{" "}
					<NumberFormat
						value={props.row.original.phone}
						format="+1 (###) ###-####"
						isNumericString={true}
						displayType="text"
					/>
				</div>
				<div>
					C:{" "}
					<NumberFormat
						value={props.row.original.cellPhone}
						format="+1 (###) ###-####"
						isNumericString={true}
						displayType="text"
					/>
				</div>
				{/* <div>Date Generated : {props.row.original.dateGenerated}</div> */}
			</span>
		</div>
	);
	const vehicleCell = (props) => (
		<div>
			<span style={{ fontWeight: "bold" }}>
				<div>{props.row.original.vehicle}</div>
				<div>
					{props.row.original.stockNumber != null && (
						<div>Stock#: {props.row.original.stockNumber}</div>
					)}
				</div>
				<div>
					{props.row.original.vin != null && (
						<div>VIN: {props.row.original.vin}</div>
					)}
				</div>
			</span>
		</div>
	);
	// const lookinCell = (props) => (
	// 	<div>
	// 		<div>{props.row.original.vehicle}</div>
	// 		<div>
	// 			{props.row.original.make != null && (
	// 				<div>Make: {props.row.original.make}</div>
	// 			)}
	// 		</div>
	// 		<div>
	// 			{props.row.original.Model != null && (
	// 				<div>Model: {props.row.original.Model}</div>
	// 			)}
	// 		</div>
	// 		<div>
	// 			{props.row.original.Year != null && (
	// 				<div>Year: {props.row.original.Year}</div>
	// 			)}
	// 		</div>
	// 	</div>
	// );
	const statusCell = (props) => (
		<div>
			<span style={{ fontWeight: "bold" }}>{props.row.original.status}</span>
		</div>
	);
	const DateCell = (props) => (
		<div>
			<span style={{ fontWeight: "bold" }}>
				{props.row.original.dateGenerated}
			</span>
		</div>
	);

	return (
		<div>
			<Row>
				<Col md="12">
					<Card>
						<CardBody>
							<ReactTable
								className="-striped -highlight primary-pagination"
								checkboxColumn={setMessageCustomers != null}
								setSelectedRows={setMessageCustomers}
								data={data}
								columns={[
									{
										Header: "Photo",
										accessor: "thumbnail",
										disableFilters: true,
										className: "vehicle-photo",
									},
									{
										id: "leadDetails",
										Header: "Lead",
										accessor: "leadDetails",
										style: { whiteSpace: "unset" },
										Cell: Cell,
									},

									{
										id: "vehicleDetails",
										Header: "Vehicle",
										accessor: "vehicleDetails",
										style: { whiteSpace: "unset" },
										className: "vehicle-details",

										Cell: vehicleCell,
									},

									{
										id: "dateGenerated",
										Header: "Generated",
										accessor: "dateGenerated",

										Cell: DateCell,
									},
									{
										id: "status",
										Header: "status",
										accessor: "status",
										Filter: LeadColumnFilter,
										filter: "includes",

										Cell: statusCell,
									},

									// {
									// 	Header: "LookingFor",
									// 	accessor: "lookingFor",
									// 	style: { whiteSpace: "unset" },
									// 	Cell: lookinCell,
									// 	className: "vehicle-details",
									// },
									{
										Header: "Actions",
										accessor: "Actions",
										disableFilters: true,
									},
								]}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default LeadTable;
