import React, { useState } from "react";
import { Form } from "reactstrap";

import ChildrenModal from "../../modal/ChildrenModal";
import InputElement from "../../../../view/formValidation/InputElement";
import LenderWrapper from "../LenderWrapper";
import ZipCityTable from "../../address/ZipCityTable";

import { stateOptions, HousingStatus } from "../../../../Constants";
import { ZipInputElement } from "../../../../view/formValidation/InputElement";
import {
	CurrencyInputElement,
	SelectorElement,
} from "../../../../view/formValidation/InputElement";

import { useFormContext } from "react-hook-form";

const UACApplicantAddress = ({
	fields,
	setters,
	prefix,
	formId,
	cardTitle,
}) => {
	const [cityModal, setCityModal] = useState(false);
	const [addressData, setAddressData] = useState({});

	// Toggles the modal
	const toggleCityModal = () => setCityModal(!cityModal);

	const handleZipChange = (zip) => {
		setAddressData({
			zip,
			address: {
				city: fields.city,
				state: fields.state,
				zip,
			},
		});
		toggleCityModal();
	};

	const setAddressFields = (address) => {
		setters.setCity(address.city);
		setters.setState(address.state);
		setters.setZip(address.zip);
	};

	// Get validation context
	const methods = useFormContext();

	return (
		<Form id={formId}>
			<LenderWrapper cardId={formId} cardTitle={cardTitle}>
				<InputElement
					{...methods}
					colSize="col-lg-3"
					value={fields.street}
					onChange={(e) => setters.setStreet(e.target.value)}
					name={`${prefix}_street`}
					label="Street Address"
					type="text"
				/>
				<InputElement
					{...methods}
					colSize="col-lg-3"
					value={fields.unit}
					onChange={(e) => setters.setUnit(e.target.value)}
					name={`${prefix}_unit`}
					label="Apt/Unit/Bld"
					type="text"
				/>
				<InputElement
					{...methods}
					colSize="col-lg-3"
					value={fields.city}
					onChange={(e) => setters.setCity(e.target.value)}
					name={`${prefix}_city`}
					label="City"
					type="text"
				/>
				<SelectorElement
					{...methods}
					colSize="col-lg-3"
					name={`${prefix}_state`}
					label="State"
					disable={true}
					value={fields.state}
					onChange={(e) => setters.setState(e.target.value)}
					options={stateOptions}
				/>
				<ZipInputElement
					{...methods}
					colSize="col-lg-3"
					value={fields.zip}
					onChange={(val) => setters.setZip(val)}
					openZipModal={handleZipChange}
					name={`${prefix}_zip`}
					label="Zip"
				/>
				<SelectorElement
					{...methods}
					colSize="col-lg-3"
					name={`${prefix}_ownership`}
					label="Rent or Own"
					disable={true}
					value={fields.ownership}
					onChange={(e) => setters.setHousingStatus(e.target.value)}
					options={HousingStatus}
				/>
				<CurrencyInputElement
					{...methods}
					colSize="col-lg-3"
					value={fields.monthlyPayment}
					onChange={setters.setHousingPayment}
					name={`${prefix}_monthlyPayment`}
					label="Monthly Payment"
				/>
				<InputElement
					{...methods}
					colSize="col-lg-3"
					value={fields.months}
					onChange={(e) => setters.setMonthsAtAddress(e.target.value)}
					name={`${prefix}_months`}
					label="Months at Address"
					type="number"
				/>
				<InputElement
					{...methods}
					colSize="col-lg-3"
					value={fields.years}
					onChange={(e) => setters.setYearsAtAddress(e.target.value)}
					name={`${prefix}_years`}
					label="Years at Address"
					type="number"
				/>
				<ChildrenModal
					modal={cityModal}
					toggle={toggleCityModal}
					modalTitle="Cities"
				>
					<ZipCityTable
						data={addressData}
						toggle={toggleCityModal}
						address={{
							city: fields.city,
							state: fields.state,
							zip: fields.zip,
						}}
						setAddress={setAddressFields}
					/>
				</ChildrenModal>
			</LenderWrapper>
		</Form>
	);
};

export default UACApplicantAddress;
