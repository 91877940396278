import React, { useEffect, useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";

import useCurrentDealStore from "../../../../store/DealStore";
import useCurrentSaleStore from "../../../../store/SaleStore";

import { getZipAndCustomTaxRate } from "api/DealAPI";
import { retrieveFailAlert } from "../../modal/SweetAlerts";
import { useFromUser } from "store/LocalStorageHelper";
import { DealStatus } from "Constants";

import dayjs from "dayjs";
import { showApiError } from "../../../../helperFunction/ApiErrorHandler";
import shallow from "zustand/shallow";

// Selector for displaying and choosing the tax rate
const TaxCountySelector = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const dealerZip = useFromUser("locations")["zipCode"];
	const dealerCity = useFromUser("locations")["city"];
	console.log(dealerZip);

	const [taxRatesList, setTaxRatesList] = useState([]);

	// Deal //
	const {
		type,
		county,
		editCounty,
		countyRecNum,
		editCountyRecNum,
		editDealSettings,
		taxDefaults,
		profitSettings,
		commissionSettings,
		buyerCity,
		buyerZip,
	} = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
			county: state.county,
			editCounty: state?.editCounty,
			countyRecNum: state.deal.countyRecNum,
			editCountyRecNum: state.editCountyRecNum,
			editDealSettings: state.editDealSettings,
			taxDefaults: state?.deal?.dealSettings.taxDefaults,
			profitSettings: state?.deal?.dealSettings.profitSettings,
			commissionSettings: state?.deal?.dealSettings.commissionSettings,
			buyerCity: state?.buyerAddress?.city,
			buyerZip: state?.buyerAddress?.zip,
		}),
		shallow
	);

	// Sale store
	const { editTaxRate } = useCurrentSaleStore(
		(state) => ({
			editTaxRate: state.editTaxRate,
		}),
		shallow
	);
	// Gets the tax rate by zip
	const ZipAndCustomTaxRate = (zip) => {
		console.log(zip);
		console.log(countyRecNum);
		let cRecnum = countyRecNum == null ? 0 : countyRecNum;
		//		getTaxRateByZip(zip).then(
		getZipAndCustomTaxRate(dealerID, locationID, zip, cRecnum).then(
			(res) => {
				console.log(res.data.content);
				const formattedRatesList = formatTaxRates(res.data.content);
				setTaxRatesList(formattedRatesList);
				// skip selection handling since manual rate has been entered
				if (!(taxDefaults?.taxRegion === 2 && taxDefaults?.taxRate > 0)) {
					// find the rate in the list matching the zip/city combination
					if (taxDefaults?.taxRegion === 1 || taxDefaults?.taxRegion === 0) {
						getCountyRecNum(taxDefaults?.taxRegion, formattedRatesList);
					}
					// this may never happen but just in case set to none on manual county/rate
					else {
						handleSelect(null, formattedRatesList);
					}
				}
			},
			(err) => {
				console.log(err);

				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	// Create the html option tags with the rates array of objects
	const optionList = taxRatesList.map((rate) => (
		<option key={rate.value} value={rate.value}>
			{rate.label}
		</option>
	));

	// Create the html option tags with the rates array of objects
	// Format the rates into a JSX options, to be used in a selector
	const formatTaxRates = (taxRates) => {
		const options = taxRates.map((tax) => {
			const rate = Number(parseFloat(tax.taxRate) * 100).toFixed(4);
			return {
				city: tax.city,
				label: `${tax.zip} - ${tax.city}, ${tax.state} - ${rate}%`,
				value: tax.recNum,
				taxRate: tax.taxRate,
				obj: tax,
			};
		});
		const optionsWithNone = [
			...options,
			{
				city: "",
				label: "None",
				value: "",
				taxRate: 0,
				obj: null,
			},
		];
		return optionsWithNone;
	};

	const handleSelect = (ID, list) => {
		console.log(ID);
		const localTaxRatesList = list ? list : taxRatesList;
		if (ID === "" || ID === null) {
			setNullsToStore();
		} else if (ID) {
			// select does not register null as a value its returning the label instead
			const rateObj = localTaxRatesList.find(
				(rate) => rate.value === Number(ID)
			);
			if (rateObj) {
				editCountyRecNum(Number(ID));
				editCounty(rateObj.obj);
				editTaxRate(rateObj.taxRate);
				let newTaxDefaults = { ...taxDefaults };
				newTaxDefaults.countyRecNum = Number(ID);
				newTaxDefaults.taxRate = rateObj.taxRate;
				const settings = {
					profitSettings,
					taxDefaults: newTaxDefaults,
					commissionSettings,
				};
				editDealSettings(settings);
			}
		}
	};

	const setNullsToStore = () => {
		editCountyRecNum(null);
		editCounty(null);
		editTaxRate(0);
		let newTaxDefaults = { ...taxDefaults };
		newTaxDefaults.countyRecNum = null;
		newTaxDefaults.taxRate = 0;
		const settings = {
			profitSettings,
			taxDefaults: newTaxDefaults,
			commissionSettings,
		};
		editDealSettings(settings);
	};

	const getCountyRecNum = (taxRegion, taxRatesList) => {
		let xCity = "";
		if (taxRegion === 0 && buyerZip != null && buyerZip.length === 5) {
			console.log("Customer Zip Region");
			console.log(buyerCity);
			xCity = buyerCity;
		} else if (taxRegion === 2 && countyRecNum === null) {
			xCity = "";
		} else if (taxRegion === 1 && dealerZip != null) {
			console.log(dealerZip);
			xCity = dealerCity;
		} else {
			console.log(dealerID, locationID, dealerZip);
		}
		console.log({ xCity });
		const taxRateValue = taxRatesList.find(
			(taxRatesList) => taxRatesList.city === xCity
		);
		console.log({ taxRateValue });
		if (!taxRateValue) {
			setNullsToStore();
		} else if (taxRateValue.value === "") {
			setNullsToStore();
		} else {
			handleSelect(Number(taxRateValue.value), taxRatesList);
		}
		// console.log(taxRateValue.length);
		console.log(countyRecNum);
	};

	useEffect(() => {
		let zips = dealerZip;
		if (buyerZip != null && buyerZip.length === 5) {
			zips = zips + "," + buyerZip;
		}
		ZipAndCustomTaxRate(zips);
		// eslint-disable-next-line
	}, [taxDefaults.taxRegion, buyerZip]);

	return (
		<FormGroup className="row-12">
			<Label>
				{"Zip County Rate Last Updated: " +
					dayjs(county?.startDate).format("MM/DD/YYYY")}
			</Label>
			<Input
				className="bold"
				name="taxRate"
				disabled={type !== DealStatus.PENDING || taxDefaults.taxRegion !== 2}
				type="select"
				value={countyRecNum === null ? "" : countyRecNum}
				onChange={(e) => {
					handleSelect(e.target.value);
				}}
			>
				{optionList}
			</Input>
		</FormGroup>
	);
};

export default TaxCountySelector;
