import React, { useState, useEffect } from "react";
import {
	Row,
	Col,
	Card,
	CardBody,
	CardTitle,
	Table,
	CardHeader,
} from "reactstrap";
import useCurrentDealStore from "store/DealStore";
import {
	EmailPdfRequestButton,
	PdfRequestButton,
} from "../../../component/dc/formsReports/FormsReportsButtons";
import ReactTable from "../table/ReactTable";
import useCurrentVehicleStore from "../../../store/InventoryStore";
import FormsReportsProvider from "./FormsReportsContext";
import { PackRequestProvider } from "./PackContexts";
import shallow from "zustand/shallow";
import FormsEmailProvider from "./FormsEmailContext";

const FormsReportsList = ({
	isPack = false,
	forms,
	listTitle,
	apiFunction,
	apiParams,
	searchable = false,
	isDeal = false,
	isInv = false,
	// used for trade inv id
	tradeInventoryID = null,
}) => {
	const { dealID, dealInventoryID } = useCurrentDealStore(
		(state) => ({
			dealID: state.deal.ID,
			dealInventoryID: state?.vehicle?.inventoryID,
		}),
		shallow
	);
	const { invID } = useCurrentVehicleStore(
		(state) => ({
			invID: state.inventoryID,
		}),
		shallow
	);
	const [inventoryID, setInventoryID] = useState(null);

	const [form, setForm] = useState([]);
	const [data, setData] = useState([]);
	// const [open, setOpen] = useState(true);

	// const handleCollapse = () => {
	// 	setOpen(!open);
	// };

	const getData = () => {
		console.log(apiParams);
		if (isPack) {
			setForm(forms);
		} else {
			apiFunction(apiParams).then((res) => {
				setForm(res.data.content);
			});
		}
	};

	useEffect(() => {
		getData();
		if (isDeal) {
			setInventoryID(dealInventoryID);
		} else if (isInv) {
			console.log(tradeInventoryID);
			if (tradeInventoryID != null) setInventoryID(tradeInventoryID);
			else setInventoryID(invID);
		} else {
			setInventoryID(null);
		}
		// eslint-disable-next-line
	}, []);

	console.log(form);
	//let params = { dealerID, locationID };

	const skeleton = (title, component) => {
		return (
			<tr>
				<td>
					<Row>
						<Col>{title}</Col>
						<Col xs="2">{component}</Col>
					</Row>
				</td>
			</tr>
		);
	};

	const formsView = form.map((element) => {
		return skeleton(
			element.id + " - " + element.title,
			<FormsReportsProvider>
				<PackRequestProvider>
					<PdfRequestButton
						dealID={dealID}
						inventoryID={inventoryID}
						dcReport={element}
					/>
				</PackRequestProvider>
			</FormsReportsProvider>
		);
	});

	const columns = React.useMemo(
		() => [
			{
				Header: "Title",
				accessor: "title",
				id: "id",
			},
			{
				Header: "",
				accessor: "actions",
				disableFilters: true,
			},
		],
		[]
	);

	const formatData = (form) =>
		form.map((element) => {
			return {
				title: element.id + " - " + element.title,
				actions: (
					<>
						<FormsReportsProvider>
							<PackRequestProvider>
								<FormsEmailProvider>
									<EmailPdfRequestButton
										dealID={dealID}
										inventoryID={inventoryID}
										dcReport={element}
									/>
								</FormsEmailProvider>
							</PackRequestProvider>
						</FormsReportsProvider>
						<FormsReportsProvider>
							<PackRequestProvider>
								<PdfRequestButton
									dealID={dealID}
									inventoryID={inventoryID}
									dcReport={element}
								/>
							</PackRequestProvider>
						</FormsReportsProvider>
					</>
				),
			};
		});

	useEffect(() => {
		const asyncData = formatData(form);
		setData(asyncData);
		// eslint-disable-next-line
	}, [form]);

	if (searchable) {
		return (
			<Row>
				<Col className="px-0">
					<Card>
						<CardHeader>
							<CardTitle tag="h3">{listTitle}</CardTitle>
						</CardHeader>
						<CardBody className="pb-4">
							<div className="forms col">
								<ReactTable data={data} columns={columns} />
							</div>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);
	} else {
		return (
			<Card>
				<CardHeader>
					<CardTitle tag="h3">{listTitle}</CardTitle>
				</CardHeader>
				<CardBody className="pb-4">
					<Table className="mt-4">
						<tbody>{formsView}</tbody>
					</Table>
				</CardBody>
			</Card>
		);
	}
};

export default FormsReportsList;
