import React, { useEffect, useState } from "react";
import { Input, Button, Row, Col, FormGroup, Form } from "reactstrap";

import useCurrentVehicleStore from "store/InventoryStore";

import { convertToReactSelect } from "component/dc/selector//helperFunction/SelectorFormat.js";
import { decodeVIN } from "api/VINDecoderAPI";
import { failAlert } from "component/dc/modal/SweetAlerts";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { getVehicleSettings, getNewStockNo } from "../../../api/InventoryAPI";
import { useFromUser } from "../../../store/LocalStorageHelper";

/**
 * Component to take a user entered VIN and decode it
 */
const VINDecoderForm = ({ useGlobalVin, updateStore, toggle }) => {
	// Vehicle store
	const {
		vin,
		editSelectedEquip,
		editStockNo,
		editVin,
		editType,
		editYear,
		editMake,
		editModel,
		editTrim,
		editDoors,
		editEngine,
		editOptionalEquip,
		editTransmission,
		editStandardEquip,
		editSpecificationEquip,
		editIsVinLoading,
		editStyle,
		editStyleList,
		editColorList,
		editIntColorList,
		editMpgHwy,
		editMpgCity,
		editNumCylinders,
		editFuelType,
		editDriveTrain,
		editWeight,
	} = useCurrentVehicleStore();
	const locationID = useFromUser("locationID");
	const [inventorySettings, setInventorySettings] = useState({});

	// Local states
	const [enteredVin, setEnteredVin] = useState(
		useGlobalVin === true ? vin : ""
	);
	const getVehicleDefaults = () => {
		getVehicleSettings(locationID).then(
			(res) => {
				console.log(res);
				setInventorySettings(res.data.content);
			},
			(err) => {
				console.log(err);
			}
		);
	};

	const setNewStocNo = (vin) => {
		console.log(inventorySettings.isRunningStockNo);
		if (inventorySettings.isRunningStockNo) {
			getNewStockNo(locationID).then(
				(res) => {
					console.log(res.data.content);
					editStockNo(res.data.content);
				},
				(err) => {
					console.log(err);
				}
			);
		} else {
			console.log(vin);
			editStockNo(vin.substring(vin.length - 6, vin.length));
			console.log(vin.substring(vin.length - 6, vin.length));
		}
	};

	// API call
	const getData = () => {
		editIsVinLoading(true);
		decodeVIN(enteredVin).then(
			(res) => {
				let content = res.data.content;

				createStyleList(content);
				setStore(content[0], enteredVin);
				editIsVinLoading(false);
				console.log(content);
				editStyle(content[0].styleName);

				if (toggle) {
					toggle();
				}
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}
			}
		);
	};

	// Style list which controls changing vehicle details based on the details received
	// for each style from the decode api
	const createStyleList = (styles) => {
		let styleList = [];

		styles.forEach((style) => {
			styleList.push({
				label: style.styleName,
				value: style.styleName,
				data: style,
			});
		});

		editStyleList(styleList);
	};

	// Sets the vehicle store
	const setStore = (style, vin) => {
		if (updateStore) {
			setNewStocNo(vin);
			//editStockNo(vin.substring(vin.length - 6, vin.length));
		}

		editSelectedEquip("");
		editVin(vin);
		editType(style.vehicleType);
		editYear(style.year);
		editMake(style.make);
		editModel(style.model);
		if (style.trim === "Base") {
			editTrim("");
		} else {
			editTrim(style.trim);
		}
		editDoors(style.doors);
		editEngine(style.engine);
		editTransmission(style.transmission);
		editSpecificationEquip(style.specifications);
		editStandardEquip(style.installedEquipment);
		editOptionalEquip(convertToReactSelect(style.optionalEquipment));
		editColorList(style.exteriorColors);
		editIntColorList(style.interiorColors);
		editMpgHwy(style.mpghwy);
		editMpgCity(style.mpgcity);
		editNumCylinders(style.cylinders);
		editFuelType(style.fuelType);
		const specObj = JSON.parse(style.specifications);
		let specifications = "";
		let findDriveType = "";
		let findWeight = "";
		console.log(specObj);
		if (specObj !== null && specObj !== "") {
			Object.keys(specObj).forEach((key) => {
				console.log(key);
				console.log(specObj[key].Key);
				if (specObj[key].Key === "Drive Type") {
					specifications = specObj[key].Value;
					Object.keys(specifications).forEach((key) => {
						findDriveType = specifications[key];
						if (findDriveType.Name === "Drive Type") {
							editDriveTrain(findDriveType.Value);
						}
					});
				}
				if (specObj[key].Key === "Measurements of Weight") {
					specifications = specObj[key].Value;
					Object.keys(specifications).forEach((key) => {
						findWeight = specifications[key];
						if (findWeight.Name === "Curb Weight") {
							editWeight(findWeight.Value);
						}
					});
				}
			});
		}
	};

	//get drive type and curb weight from specification Equipment
	// useEffect(() => {
	// 	let specObj = "";
	// 	let specifications = "";
	// 	try {
	// 		specObj = JSON.parse(specificationEquip);
	// 		console.log(specObj);
	// 	} catch (e) {
	// 		console.log(e.message);
	// 	}
	// 	let findDriveType = "";
	// 	let findWeight = "";
	// 	if (specObj !== null && specObj !== "") {
	// 		Object.keys(specObj).forEach((key) => {
	// 			if (specObj[key].Key === "Drive Type") {
	// 				specifications = specObj[key].Value;
	// 				Object.keys(specifications).forEach((key) => {
	// 					findDriveType = specifications[key];
	// 					if (findDriveType.Name === "Drive Type") {
	// 						editDriveTrain(findDriveType.Value);
	// 					}
	// 				});
	// 			}
	// 			if (specObj[key].Key === "Measurements of Weight") {
	// 				specifications = specObj[key].Value;
	// 				Object.keys(specifications).forEach((key) => {
	// 					findWeight = specifications[key];
	// 					if (findWeight.Name === "Curb Weight") {
	// 						editWeight(findWeight.Value);
	// 					}
	// 				});
	// 			}
	// 		});
	// 	}
	// }, [editWeight, specificationEquip, editDriveTrain]);

	// Changes the local entered VIN variable and removes error
	const handleInputChange = (e) => {
		setEnteredVin(e.target.value.toUpperCase());
	};

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			length: "VIN must contain a combination of 17 letters and numbers",
			matches: "Only number and letters allowed",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({
		vin: yup
			.string()
			.required()
			.length(17)
			.matches(/^[a-zA-Z0-9]*$/),
	});

	// Define form validation parameters
	const { register, handleSubmit, errors } = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});
	useEffect(() => {
		getVehicleDefaults();
		// eslint-disable-next-line
	}, []);
	return (
		<Form onSubmit={(e) => e.preventDefault()}>
			<Col id="vinDecoder">
				<Row className="mx-0">
					<Col className="col-8 px-0">
						<FormGroup className="pt-1">
							<Input
								maxLength="17"
								innerRef={register}
								placeholder="Enter VIN"
								value={enteredVin}
								onChange={(e) => handleInputChange(e)}
								invalid={errors.vin ? true : false}
								name="vin"
							/>
							<ErrorMessage
								errors={errors}
								name="vin"
								render={({ message }) => (
									<p className="error_text">{message}</p>
								)}
							/>
						</FormGroup>
					</Col>
					<Col className="col-4 px-0">
						<FormGroup className="ml-2">
							<Button
								onClick={handleSubmit(getData)}
								className="btn-primary btn btn-md"
							>
								Decode
							</Button>
							<Button
								//onClick={handleSubmit(getData)}
								className="btn-primary btn btn-sm noDesktop"
							>
								Scan
							</Button>
						</FormGroup>
					</Col>
				</Row>
			</Col>
		</Form>
	);
};

export default VINDecoderForm;
