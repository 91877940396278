import getAxiosInstance from "./interceptors/buildReqAndResInstance";

import { userBaseUrl } from "./Constants";

// const IN_DEVELOPMENT = process.env.NODE_ENV === "development";

const instance = getAxiosInstance({
	baseURL: userBaseUrl + "/integrations/",
});

export const getDigitzsMerchant = async (dealerID, locationID) => {
	return await instance.get(`digitzs/merchant/${dealerID}/${locationID}`);
};

export const getDigitzsPayments = async (customerID) => {
	return await instance.get(`digitzs/payments/${customerID}`);
};

export const createDigitzsMerchant = async (body) => {
	return await instance.post(
		// `digitzs/merchant/create`,
		`digitzs/test/merchant/create`,
		body
	);

	// let testUrl = `${userBaseUrl}/payments/digitzs/test/merchant/create`;
	// let url = `${userBaseUrl}/payments/digitzs/merchant/create`;

	//

	// if (IN_DEVELOPMENT) {
	// 	return await axios.post(testUrl, body, getAuthHeader());
	// }

	// return await axios.post(url, body, getAuthHeader());
};

export const processDigitzsTransaction = async (body) => {
	return await instance.post(
		// `digitzs/payment/process`,
		`digitzs/test/payment/process`,
		body
	);
};

export const refundDigitzsTransaction = async (body) => {
	return await instance.post(
		// `digitzs/payment/refund`,
		`digitzs/test/payment/refund`,
		body
	);
};

// Gets transaction info from Digitzs
export const getDigitzsTransaction = async (id) => {
	return await instance.get(
		// `digitzs/payment/${id}`
		`digitzs/test/payment/${id}`
	);
};

// Gets all transaction info from Digitzs
export const getDigitzsMerchantTransactions = async (dealerID, locationID) => {
	return await instance.get(
		// `digitzs/transactions/${dealerID}/${locationID}`
		`digitzs/test/transactions/${dealerID}/${locationID}`
	);
};
