import React from "react";
import { Form } from "reactstrap";

import useCurrentCustomerStore from "store/CustomerStore";
import InputWrapper from "view/formValidation/InputWrapper";

import { EditableSelectorElement } from "view/formValidation/InputElement";

import { AdSource } from "Constants";
import InputElement, {
	PhoneInputElement,
} from "view/formValidation/InputElement";

import shallow from "zustand/shallow";

import { useFormContext } from "react-hook-form";

const BusinessDetails = () => {
	// Customer store
	const {
		firstName,
		lastName,
		email,
		cellPhone,
		extraPhone,
		workPhone,
		source,
		customerNumber,
		editFirstName,
		editLastName,
		editEmail,
		editCellPhone,
		editExtraPhone,
		editWorkPhone,
		editSource,
		editCustomerNumber,
		license,
		editLicense,
	} = useCurrentCustomerStore(
		(state) => ({
			firstName: state.firstName,
			lastName: state.lastName,
			email: state.email,
			cellPhone: state.cellPhone,
			extraPhone: state.extraPhone,
			workPhone: state.workPhone,
			source: state.source,
			customerNumber: state.customerNumber,
			type: state.type,
			notes: state.notes,
			editFirstName: state.editFirstName,
			editLastName: state.editLastName,
			editEmail: state.editEmail,
			editCellPhone: state.editCellPhone,
			editExtraPhone: state.editExtraPhone,
			editHomePhone: state.editHomePhone,
			editWorkPhone: state.editWorkPhone,
			editSource: state.editSource,
			editCustomerNumber: state.editCustomerNumber,
			editType: state.editType,
			editNotes: state.editNotes,
			license: state.license,
			editLicense: state.editLicense,
		}),
		shallow
	);

	// Get validation context
	const methods = useFormContext();

	return (
		<Form>
			<InputWrapper
				formTitle="Business"
				inputComponents={[
					<InputElement
						{...methods}
						label="Business Name"
						value={lastName}
						onChange={(e) => editLastName(e.target.value)}
						name="lastName"
						type="text"
						labelClass="required"
					/>,
					<InputElement
						{...methods}
						value={firstName}
						onChange={(e) => editFirstName(e.target.value)}
						name="contact"
						label="Contact"
						type="text"
					/>,

					<InputElement
						{...methods}
						value={customerNumber}
						onChange={(e) => editCustomerNumber(e.target.value)}
						name="customerNumber"
						label="EIN"
						type="text"
					/>,

					<InputElement
						{...methods}
						value={license}
						onChange={(e) => editLicense(e.target.value)}
						name="license"
						label="License"
						type="text"
					/>,

					<PhoneInputElement
						{...methods}
						value={cellPhone}
						onChange={editCellPhone}
						name="cellPhone"
						label="Cell Phone"
					/>,

					<PhoneInputElement
						{...methods}
						value={extraPhone}
						onChange={editExtraPhone}
						name="extraPhone"
						label="Extra Phone"
					/>,
					<PhoneInputElement
						{...methods}
						value={workPhone}
						onChange={editWorkPhone}
						name="workPhone"
						label="Work Phone"
					/>,
					<InputElement
						{...methods}
						value={email}
						onChange={(e) => editEmail(e.target.value)}
						name="email"
						label="Email"
						type="text"
					/>,
					<EditableSelectorElement
						label="Source"
						value={source}
						onChange={(e) => editSource(e?.toLowerCase())}
						options={AdSource}
					/>,
				]}
			/>
		</Form>
	);
};

export default BusinessDetails;
