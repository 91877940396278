/*!
=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)

* Coded by DealerClick Software **RoarinLion))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState } from "react";
import InventoryHeader from "component/dc/header/InventoryHeader";
import InventoryListData from "../../component/dc/inventory/InventoryListData";

const InventoryListPage = () => {
	const [statusList, setStatusList] = useState("Available,Pending");

	return (
		<div>
			<InventoryHeader statusList={statusList} setStatusList={setStatusList} />
			<InventoryListData statusList={statusList} />
		</div>
	);
};
export default InventoryListPage;
