import React from "react";
import { Button } from "reactstrap";

import { FormattedText } from "view/formValidation/InputElement";

const DigitzsPaymentsData = ({ payments, handleDetails }) => {
	return payments.map((obj, index) => (
		<tr key={obj.ID}>
			<th scope="row">{index + 1}</th>
			<td>{obj.dmsTimestamp}</td>
			<td>
				{
					<FormattedText
						value={obj.gross / 100}
						fixedDecScale={true}
						prefix={"$"}
					/>
				}
			</td>
			<td>
				{
					<FormattedText
						value={obj.net / 100}
						fixedDecScale={true}
						prefix={"$"}
					/>
				}
			</td>
			<td>
				{
					<FormattedText
						value={obj.grossMinusNet / 100}
						fixedDecScale={true}
						prefix={"$"}
					/>
				}
			</td>
			<td className="d-flex justify-content-center">
				<Button
					onClick={() => handleDetails(obj.transactionId, obj.isRefunded)}
				>
					View
				</Button>
			</td>
		</tr>
	));
};

export default DigitzsPaymentsData;
