import React, { createContext, useContext, useState } from "react";

// Checks that a component higher up in the component is wrapped with
// the DataProvider before using the context. Takes care of having
// to do undefined checks for context data
export const createDataContext = () => {
	const context = createContext(undefined);

	const useMultiImageDataContext = () => {
		const c = useContext(context);

		if (!c) {
			throw new Error(
				"useMultiImageDataContext is not inside a Provider with a value. Check that a component " +
					"is wrapped with the DataProvider."
			);
		}

		return c;
	};

	return [useMultiImageDataContext, context.Provider];
};

// Get the context and provider
const [useMultiImageData, CtxProvider] = createDataContext();

// Custom hook to retrieve context states
export { useMultiImageData };

// Data provider for MultiImageUploader component
export const MultiImageProvider = ({ children }) => {
	// States
	const [selectedImage, setSelectedImage] = useState(null);
	const [selectedImageName, setSelectedImageName] = useState(null);
	const [selectedImageIndex, setSelectedImageIndex] = useState(0);
	const [imageKeys, setImageKeys] = useState([]);
	const [hideCrop, setHideCrop] = useState(true);
	const [croppedImage, setCroppedImage] = useState(null);
	const [croppedImages, setCroppedImages] = useState([]);
	const [imageSources, setImageSources] = useState({});
	const [fileUploads, setFileUploads] = useState({});
	const [croppedUploads, setCroppedUploads] = useState({});
	const [uploadErrors, setUploadErrors] = useState({});

	return (
		<CtxProvider
			value={{
				selectedImage,
				setSelectedImage,
				selectedImageName,
				setSelectedImageName,
				selectedImageIndex,
				setSelectedImageIndex,
				imageKeys,
				setImageKeys,
				hideCrop,
				setHideCrop,
				croppedImage,
				setCroppedImage,
				croppedImages,
				setCroppedImages,
				imageSources,
				setImageSources,
				fileUploads,
				setFileUploads,
				croppedUploads,
				setCroppedUploads,
				uploadErrors,
				setUploadErrors,
			}}
		>
			{children}
		</CtxProvider>
	);
};
