import React, { useState } from "react";
import { Table } from "reactstrap";

import ChildrenModal from "component/dc/modal/ChildrenModal";
import DigitzsPaymentsData from "./DigitzsPaymentsData";
import DigitzsPaymentTableHeader from "./DigitzsPaymentTableHeader";
import DigitzsTransactionDetails from "./DigitzsTransactionDetails";

import { getDigitzsTransaction } from "api/PaymentsAPI";
import { failAlert } from "component/dc/modal/SweetAlerts";
import { sortDates, sortNames } from "component/dc/note/NoteFunctions";
import { ASC } from "component/dc/note/NoteConstants";
import { DSC } from "component/dc/note/NoteConstants";

const DigitzsPaymentsTable = ({ payments, editPayments }) => {
	const [detailsModal, setDetailsModal] = useState(false);
	const [transactionDetails, setTransactionDetails] = useState(null);
	const [showProgress, setShowProgress] = useState(false);

	// Toggler for toggling modal
	const toggleModal = () => setDetailsModal(!detailsModal);

	// Edits the payments field with the sorted version
	const sortPayments = (order, field, isDateSort) =>
		isDateSort
			? editPayments(sortDates(payments, order, field))
			: editPayments(sortNames(payments, order, field));

	// Calls Digitzs API to get transaction details for a specific payment
	const handleDetails = (transactionId, isRefunded) => {
		toggleModal();
		setShowProgress(true);

		getDigitzsTransaction(transactionId).then(
			(res) => {
				setShowProgress(false);
				setTransactionDetails({ ...res.data.content, isRefunded });
			},
			(err) => {
				setShowProgress(false);
				failAlert(
					err?.response?.data?.message || "Failed to get transaction details"
				);
			}
		);
	};

	return (
		<Table striped bordered hover responsive>
			<thead>
				<tr className="align-top">
					<th>#</th>
					<DigitzsPaymentTableHeader
						header="Transaction Date"
						sortAsc={() => sortPayments(ASC, "dmsTimestamp", true)}
						sortDesc={() => sortPayments(DSC, "dmsTimestamp", true)}
					/>
					<DigitzsPaymentTableHeader
						header="Gross"
						sortAsc={() => sortPayments(ASC, "gross", false)}
						sortDesc={() => sortPayments(DSC, "gross", false)}
					/>
					<DigitzsPaymentTableHeader
						header="Net"
						sortAsc={() => sortPayments(ASC, "net", false)}
						sortDesc={() => sortPayments(DSC, "net", false)}
					/>
					<DigitzsPaymentTableHeader
						header="Gross - Net"
						sortAsc={() => sortPayments(ASC, "grossMinusNet", false)}
						sortDesc={() => sortPayments(DSC, "grossMinusNet", false)}
					/>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<DigitzsPaymentsData
					handleDetails={handleDetails}
					payments={payments}
				/>
				<ChildrenModal
					modal={detailsModal}
					toggle={toggleModal}
					modalButtonTitle="Refund"
					modalTitle="Transaction Details"
				>
					<DigitzsTransactionDetails
						showProgress={showProgress}
						toggle={toggleModal}
						data={transactionDetails}
						payments={payments}
						editPayments={editPayments}
					/>
				</ChildrenModal>
			</tbody>
		</Table>
	);
};

export default DigitzsPaymentsTable;
