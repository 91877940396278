import React, { useState } from "react";
import { Card, CardBody, CardText, Col, Row, Button } from "reactstrap";
import useCurrentSaleStore from "store/SaleStore";
import DownPaymentForm from "component/dc/deal/sale/modal/downPayment/DownPaymentForm";
import NumberFormat from "react-number-format";
import ChildrenModal from "component/dc/modal/ChildrenModal";
import DownPaymentTable from "./DownPaymentTable";
import useCurrentDealStore from "store/DealStore";
import { DealStatus } from "Constants";
import shallow from "zustand/shallow";

const DownPaymentModal = ({ modal, toggle }) => {
	const { totalPickupPmts } = useCurrentSaleStore(
		(state) => ({
			totalPickupPmts: state.totalPickupPmts,
		}),
		shallow
	);
	const { pickupPmts, type } = useCurrentDealStore(
		(state) => ({
			pickupPmts: state.pickupPmts,
			type: state.deal.type,
		}),
		shallow
	);
	const [currentIndex, setCurrentIndex] = useState(pickupPmts.length);
	const [open, setOpen] = useState(false);

	const toggleOpen = () => setOpen(!open);

	return (
		<ChildrenModal
			modal={modal}
			toggle={toggle}
			modalTitle="Deferred Payments"
			size="lg"
			children={
				<>
					<ChildrenModal
						modal={open}
						toggle={toggleOpen}
						size="lg"
						children={
							<DownPaymentForm
								currentIndex={currentIndex}
								setCurrentIndex={setCurrentIndex}
								toggle={toggleOpen}
							/>
						}
					/>
					<DownPaymentTable
						setCurrentIndex={setCurrentIndex}
						toggle={toggleOpen}
					/>
					<Card>
						<CardBody>
							<Row>
								<Col sm="6">
									<CardText tag="h3" className="text-center">
										Total Deferred
									</CardText>
								</Col>
								<Col sm="6">
									<CardText tag="h3" className="text-center">
										<NumberFormat
											value={totalPickupPmts}
											thousandSeparator={true}
											decimalScale={2}
											fixedDecimalScale={true}
											prefix={"$"}
											isNumericString={true}
											readOnly={true}
											displayType="text"
										/>
									</CardText>
								</Col>
							</Row>
						</CardBody>
					</Card>

					<div className="col pb-3">
						<div className="col">
							<Button
								className="btn btn-primary btn-md"
								readOnly={type !== DealStatus.PENDING}
								onClick={toggleOpen}
							>
								<i className="nc-icon nc-simple-add"></i> Add Deferred
							</Button>
						</div>
					</div>
				</>
			}
		/>
	);
};

export default DownPaymentModal;
