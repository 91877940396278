import React from "react";
import { Modal, ModalBody } from "reactstrap";
//import Draggable from "react-draggable";
const ChildrenModal = ({
	modal,
	toggle,
	modalTitle,
	children,
	onClosed,
	size = "xl",
	hideX = false,
}) => {
	return (
		// <Draggable enableUserSelectHack={false}>
		<Modal
			toggle={toggle}
			isOpen={modal}
			size={size}
			backdrop="static"
			onClosed={onClosed}
		>
			{!hideX ? (
				<div className="modal-header justify-content-center">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={toggle}
					>
						<span aria-hidden="true">×</span>
					</button>
					<h5 className="modal-title">{modalTitle}</h5>
				</div>
			) : null}
			<ModalBody>{children}</ModalBody>
		</Modal>
		// </Draggable>
	);
};

export default ChildrenModal;
