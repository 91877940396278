import React from "react";
import { FormGroup, Input } from "reactstrap";
import { OnlineLeadStatus } from "../../../Constants";

function SelectColumnFilter({
	column: { filterValue, setFilter, preFilteredRows, id },
}) {
	// Calculate the options for filtering
	// using the preFilteredRows
	const options = React.useMemo(() => {
		const options = new Set();
		preFilteredRows.forEach((row) => {
			if (row.values[id] !== " ") {
				options.add(row.values[id]);
			}
		});
		return [...options.values()];
	}, [id, preFilteredRows]);

	// Render a multi-select box
	return (
		<FormGroup>
			<Input
				type="select"
				value={filterValue}
				onChange={(e) => {
					setFilter(e.target.value || undefined);
				}}
			>
				<option value="">All</option>
				{options.map((option, i) => (
					<option key={i} value={option}>
						{option}
					</option>
				))}
			</Input>
		</FormGroup>
	);
}

export default SelectColumnFilter;

export const LeadColumnFilter = ({
	column: { filterValue, setFilter, preFilteredRows, id },
}) => {
	// Render a multi-select box
	return (
		<FormGroup>
			<Input
				type="select"
				value={filterValue}
				onChange={(e) => {
					setFilter(e.target.value || undefined);
				}}
			>
				<option value="">All</option>
				{OnlineLeadStatus.map((obj) => (
					<option value={obj.label}>{obj.label}</option>
				))}
			</Input>
		</FormGroup>
	);
};

export const CreditAppColumnFilter = ({
	column: { filterValue, setFilter, preFilteredRows, id },
}) => {
	// Render a multi-select box
	return (
		<FormGroup>
			<Input
				type="select"
				value={filterValue}
				onChange={(e) => {
					setFilter(e.target.value || undefined);
				}}
			>
				<option value="">All</option>
				<option value="Active">Active</option>
				<option value="Inactive">Inactive</option>
			</Input>
		</FormGroup>
	);
};
