import React from "react";
import { Spinner } from "reactstrap";

const CenteredLoadingSpinner = () => {
	return (
		<div
			className="d-flex justify-content-center align-items-center"
			style={{ height: "50vh" }}
		>
			<Spinner style={{ width: "3rem", height: "3rem" }} color="primary" />
		</div>
	);
};

export default CenteredLoadingSpinner;
