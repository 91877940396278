/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)

* Coded by DealerClick Software **RoarinLion))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Login from "view/login/Login.js";
import Dashboard from "view/dashboard/Dashboard";
import InventoryAddPage from "view/inventory/InventoryAddPage";
import InventoryListPage from "view/inventory/InventoryListPage";
import CustomerDetailPage from "view/contact/CustomerDetailPage";
import CustomerListPage from "view/contact/CustomerListPage";
import CustomerAddPage from "view/contact/CustomerAddPage";
import InventoryDetailsPage from "view/inventory/InventoryDetailPage";
import DealListPage from "view/deal/DealListPage";
import DealAddPage from "view/deal/DealAddPage";
import DealDetailPage from "view/deal/DealDetailPage";
import SettingsPage from "view/settings/SettingsPage";
import ReportsPage from "view/report/ReportsPage";
import InventoryAdvertisingPage from "view/inventory/InventoryAdvertisingPage";
import InventoryAppraisalPage from "view/inventory/InventoryAppraisalPage";
import VendorPage from "view/settings/vendor/VendorPage";
import { PATHS } from "../Constants.js";
import PaymentTerminalPage from "view/payment/PaymentTerminalPage";
//import RecentMessages from "../view/contact/RecentMessages.js";
import FormGen from "view/formValidation/FormGen";
import { QuickQuoteDeal } from "../view/deal/QuickQuoteDeal.js";

const publicRoutes = [
	{
		path: PATHS.DASHBOARD,
		name: "Dashboard",
		icon: "nc-icon nc-layout-11",
		component: Dashboard,
		layout: "",
	},
	{
		path: PATHS.LOGIN,
		name: "Login",
		icon: "nc-icon nc-simple-add",
		component: Login,
		layout: "",
		invisible: true,
	},
	{
		collapse: true,
		name: "Deals",
		icon: "nc-icon nc-briefcase-24",
		state: "dealCollapse",
		views: [
			{
				path: PATHS.DEAL_LIST,
				name: "View Deals",
				icon: "nc-icon nc-bullet-list-67",
				component: DealListPage,
				layout: "",
			},
			{
				path: PATHS.DEAL_ADD,
				name: "New Deal",
				icon: "nc-icon nc-simple-add",
				component: DealAddPage,
				layout: "",
			},
			{
				path: PATHS.QuikQuote,
				name: "Quik Quote",
				icon: "nc-icon nc-sound-wave",
				component: QuickQuoteDeal,
				layout: "",
			},
			{
				path: PATHS.DEAL_DETAIL,
				name: "Deal Detail",
				component: DealDetailPage,
				layout: "",
				invisible: true,
			},
		],
	},
	{
		collapse: true,
		name: "CRM",
		icon: "nc-icon nc-single-02",
		state: "contactCollapse",
		views: [
			{
				path: PATHS.CUSTOMER_LIST,
				name: "View List",
				icon: "nc-icon nc-bullet-list-67",
				component: CustomerListPage,
				layout: "",
			},
			{
				path: PATHS.LEAD_ADD,
				name: "Add Customer",
				icon: "nc-icon nc-simple-add",
				component: CustomerAddPage,
				layout: "",
			},
			{
				path: PATHS.CUSTOMER_DETAIL,
				name: "Customer Detail",
				component: CustomerDetailPage,
				layout: "",
				invisible: true,
			},
			{
				path: PATHS.LEAD_DETAIL,
				name: "Lead Detail",
				component: CustomerDetailPage,
				layout: "",
				invisible: true,
			},
		],
	},
	// {
	// 	collapse: true,
	// 	name: "CRM",
	// 	icon: "fa fa-comments",
	// 	state: "communicationsCollapse",
	// 	views: [
	// 		{
	// 			path: PATHS.MESSAGING,
	// 			name: "Message",
	// 			icon: "nc-icon nc-bullet-list-67",
	// 			component: CommunicationListPage,
	// 			layout: "",
	// 		},
	// 	],
	// },
	{
		collapse: true,
		name: "Inventory",
		icon: "nc-icon nc-delivery-fast",
		state: "inventoryCollapse",
		views: [
			{
				path: PATHS.INVENTORY_LIST,
				name: "View Inventory",
				icon: "nc-icon nc-bullet-list-67",
				component: InventoryListPage,
				layout: "",
			},
			{
				path: PATHS.INVENTORY_ADD,
				name: "Add Inventory",
				icon: "nc-icon nc-simple-add",
				component: InventoryAddPage,
				layout: "",
			},
			{
				path: PATHS.INVENTORY_ADVERTISING,
				name: "Advertising",
				icon: "nc-icon nc-world-2",
				component: InventoryAdvertisingPage,
				layout: "",
			},
			{
				path: PATHS.INVENTORY_APPRAISAL,
				name: "Appraisal",
				icon: "nc-icon nc-tag-content",
				component: InventoryAppraisalPage,
				layout: "",
			},
			{
				path: PATHS.INVENTORY_DETAIL,
				name: "Inventory Details",
				component: InventoryDetailsPage,
				layout: "",
				invisible: true,
			},
		],
	},

	{
		path: PATHS.REPORTS,
		name: "Reports",
		icon: "nc-icon nc-chart-bar-32",
		component: ReportsPage,
		layout: "",
	},
	{
		path: PATHS.VENDORS,
		name: "Companies",
		icon: "nc-icon nc-circle-10",
		component: VendorPage,
		layout: "",
	},
	{
		path: PATHS.PAYMENT_TERMINAL,
		name: "Payments",
		icon: "nc-icon nc-credit-card",
		component: PaymentTerminalPage,
		layout: "",
	},
	{
		path: PATHS.SETTINGS,
		name: "Settings",
		icon: "nc-icon nc-settings-gear-65",
		component: SettingsPage,
		layout: "",
	},
	{
		path: PATHS.FormGen,
		name: "FormGen",
		icon: "nc-icon nc-layout-11",
		component: FormGen,
		layout: "",
		invisible: true,
	},
];

export default publicRoutes;
