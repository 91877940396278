import React, { useCallback, useEffect, useState } from "react";
import { Button } from "reactstrap";

import AttachmentFileDetails from "./AttachmentFileDetails";
import AttachmentsTable from "../table/AttachmentsTable";
import ChildrenModal from "../modal/ChildrenModal";
import DownloadAttachmentButton from "./DownloadAttachmentButton";
import UploadAttachmentButton from "./UploadAttachmentButton";

import { retrieveFailAlert } from "../modal/SweetAlerts";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";
import {
	getDealAttachmentsList,
	getDealersAttachmentsList,
} from "../../../api/AttachmentsAPI";

import dayjs from "dayjs";

/**
 * Component containing all the attachment UI and functionality
 *
 * @param {number} relatedID Required. Deal, Inventory, or Customer ID
 * @param {String} type Required. Must be one of: 'deal', 'inventory' or 'customer
 */
const Attachment = ({ relatedID, type }) => {
	// Local states
	const [attachments, setAttachments] = useState([]);
	const [detailsData, setDetailsData] = useState({});
	const [refreshAttachments, setRefreshAttachments] = useState(null);
	const [uploadModal, setUploadModal] = useState(false);
	const [detailsModal, setDetailsModal] = useState(false);

	// Toggler for the upload modal
	const toggleUploadModal = () => setUploadModal(!uploadModal);

	// Toggler for the file details modal
	const toggleDetailsModal = () => setDetailsModal(!detailsModal);

	// Converts the response object into the React Table data array and sets the state
	// In cases where a file may exist but no DB entry for file exists, sets the table fields
	// appropriately so that it can be saved as a new entry
	const createAndSetTableData = (obj) => {
		const tableData = obj.map((key) => {
			return {
				...key,
				createdOn:
					key?.createdOn != null
						? dayjs(key.createdOn).format("MM/DD/YYYY [at] hh:mmA")
						: null,
				button: (
					<div className="row d-flex justify-content-center align-items-center">
						<Button
							block
							onClick={() => {
								setDetailsData({
									...key,
									createdOn: key?.createdOn || null,
									canEdit: true,
								});
								toggleDetailsModal();
							}}
						>
							Edit
						</Button>
						<DownloadAttachmentButton
							dcAttachmentId={key.ID}
							fileName={key.fileName}
							fileExtension={key.extension}
						/>
					</div>
				),
			};
		});

		setAttachments(tableData);
	};

	// This shows all the attachments related to this deal
	// Converts the response object into the React Table data array and sets the state
	// In cases where a file may exist but no DB entry for file exists, sets the table fields
	// appropriately so that it can be saved as a new entry
	const createAndSetDealTableData = (obj) => {
		let tableData = [];
		console.log(obj);
		Object.keys(obj).forEach((str) =>
			obj[str].forEach((listElement) => {
				tableData.push({
					...listElement,
					createdOn:
						listElement?.createdOn != null
							? dayjs(listElement.createdOn).format("MM/DD/YYYY [at] hh:mmA")
							: null,
					button: (
						<div>
							<Button
								block
								onClick={() => {
									setDetailsData({
										...listElement,
										createdOn: listElement?.createdOn || null,
										canEdit: listElement.type === "deal",
									});
									toggleDetailsModal();
								}}
							>
								{listElement.type === "deal" ? (
									"Edit"
								) : (
									<>
										<i class="icon-big text-center nc-icon nc-zoom-split text-default" />{" "}
										View
									</>
								)}
							</Button>
							<DownloadAttachmentButton
								dcAttachmentId={listElement.ID}
								fileName={listElement.fileName}
								fileExtension={listElement.extension}
							/>
						</div>
					),
				});
			})
		);

		setAttachments(tableData);
	};

	// Get the attachment files information
	const getAttachments = useCallback(() => {
		getDealersAttachmentsList(type, relatedID).then(
			(res) => createAndSetTableData(res.data.content),
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
		// eslint-disable-next-line
	}, []);

	// Gets all the attachment files information for a deal
	const getDealAttachments = useCallback(() => {
		relatedID != null &&
			getDealAttachmentsList(relatedID).then(
				(res) => createAndSetDealTableData(res.data.content),
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		// eslint-disable-next-line
	}, []);

	// Initialize table data on load
	useEffect(() => {
		if (type === "deal") {
			getDealAttachments();
		} else {
			getAttachments();
		}
		// eslint-disable-next-line
	}, []);

	// Call api to get attachments list again as information changed
	useEffect(() => {
		if (refreshAttachments !== null) {
			if (type === "deal") {
				getDealAttachments();
			} else {
				getAttachments();
			}
		}
		// eslint-disable-next-line
	}, [refreshAttachments]);

	return (
		<div>
			<Button
				className="btn btn-primary btn-md ml-3 mb-3"
				onClick={toggleUploadModal}
			>
				<i className="nc-icon nc-simple-add" /> Add Attachments
			</Button>
			{attachments.length > 0 && (
				<AttachmentsTable data={attachments} type={type} />
			)}
			<ChildrenModal
				size="lg"
				modalTitle="Upload Attachments"
				modal={uploadModal}
				toggle={toggleUploadModal}
			>
				<UploadAttachmentButton
					relatedID={relatedID}
					type={type}
					setRefreshAttachments={setRefreshAttachments}
					toggle={toggleUploadModal}
				/>
			</ChildrenModal>
			<ChildrenModal size="lg" modal={detailsModal} toggle={toggleDetailsModal}>
				<AttachmentFileDetails
					file={detailsData}
					toggleDetailsModal={toggleDetailsModal}
					setRefreshAttachments={setRefreshAttachments}
				/>
			</ChildrenModal>
		</div>
	);
};

export default Attachment;
