import React, { useState } from "react";
import { saveVendor } from "../../../api/VendorsAPI";
import { vendorTypes, stateOptions } from "Constants";
import { saveFailAlert } from "../../../component/dc/modal/SweetAlerts";
import { saveSuccessAlert } from "component/dc/modal/SweetAlerts";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";
import { object, string } from "yup";
import InputElement, {
	PhoneInputElement,
	SelectorElement,
	CurrencyInputElement,
	PercentInput,
} from "view/formValidation/InputElement";
import tabOnEnter from "helperFunction/tabOnEnter";
import InputWrapper from "view/formValidation/InputWrapper";
import { useFromUser } from "store/LocalStorageHelper";
import { Form, Button, Col, Row } from "reactstrap";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

const VenderCompanyForm = ({
	useType = 10,
	vendorType,
	toggle,
	setOption,
	list,
	editList,
	editRecNum,
}) => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	//const [ID, setID] = useState(null);
	const [name, setName] = useState("");
	const [contact1, setContact1] = useState("");
	const [phone1, setPhone1] = useState("");
	const [street, setStreet] = useState("");
	const [state, setState] = useState("");
	const [city, setCity] = useState("");
	const [zip, setZip] = useState("");
	const [email, setEmail] = useState("");
	const [website, setWebsite] = useState("");
	const [lenderNo, setLenderNo] = useState("");
	//const [updating, setUpdating] = useState(false);

	//for lender
	const [bankFee, setBankFee] = useState("");
	const [lenderFee, setLenderFee] = useState("");
	const [lateFee, setLateFee] = useState("");
	const [discountRate, setDiscountRate] = useState("");
	const [reserve, setReserve] = useState("");
	const [taxId, setTaxId] = useState("");
	const [securityId, setsecurityID] = useState("");
	const [default_, setDefault] = useState(0);
	const [inHouse, setInHouse] = useState(0);
	const [profitOnly, setProfitOnly] = useState(0);
	const [dealerPart, setDealerPart] = useState("");
	const [notes, setNotes] = useState("");

	const [lineOfCredit, setLineOfCredit] = useState(0);

	// const clearFields = () => {
	// 	setID("");
	// 	setName("");
	// 	setContact1("");
	// 	setPhone1("");
	// 	setStreet("");
	// 	setState("");
	// 	setCity("");
	// 	setZip("");
	// 	setEmail("");
	// 	setWebsite("");
	// 	setUpdating(false);
	// 	setBankFee("");
	// 	setLenderFee("");
	// 	setLateFee("");
	// 	setDiscountRate("");
	// 	setReserve("");
	// 	setTaxId("");
	// 	setsecurityID("");
	// 	setDefault(0);
	// 	setInHouse(0);
	// 	setProfitOnly(0);
	// 	setDealerPart("");
	// 	setNotes("");
	// 	setLineOfCredit(0);
	// };

	// const editFunctions = (vender) => {
	// 	setID(vender.ID);
	// 	setName(vender.name);
	// 	setContact1(vender.contact1);
	// 	setCity(vender.city);
	// 	setPhone1(vender.phone1);
	// 	setStreet(vender.street);
	// 	setState(vender.state);
	// 	setZip(vender.zip);
	// 	setEmail(vender.email);
	// 	setWebsite(vender.website);
	// 	setUpdating(true);
	// 	setBankFee(vender.bankFee);
	// 	setLenderFee(vender.lenderFee);
	// 	setLateFee(vender.lateFee);
	// 	setDiscountRate(vender.discountRate);
	// 	setReserve(vender.reserve);
	// 	setTaxId(vender.taxId);
	// 	setsecurityID(vender.securityId);
	// 	setDefault(vender.default_);
	// 	setInHouse(vender.inHouse);
	// 	setProfitOnly(vender.profitOnly);
	// 	setDealerPart(vender.dealerPart);
	// 	setNotes(vender.notes);
	// 	setLineOfCredit(vender.lineOfCredit);
	// };

	const addVendor = () => {
		let newVendor = {
			//ID,
			name,
			contact1,
			phone1,
			street,
			state,
			city,
			zip,
			email,
			website,
			vendorType: useType,
			dealerID,
			locationID,
			isHidden: false,
			notes,
			lenderNo,
		};
		if (useType === 30) {
			newVendor = {
				...newVendor,
				bankFee,
				lenderFee,
				lateFee,
				discountRate,
				reserve,
				taxId,
				securityId,
				default_,
				inHouse,
				profitOnly,
				dealerPart,
			};
		}
		if (useType === 70) {
			newVendor = {
				...newVendor,
				lineOfCredit,
			};
		}
		console.log(newVendor);
		saveVendor(newVendor).then(
			(res) => {
				console.log(res.data.content);
				const obj = res.data.content;
				const newList = [...list, obj];
				editList(newList);
				editRecNum(obj.ID);

				setOption({
					value: obj.ID,
					label: obj.name,
					vendor: obj,
				});
				saveSuccessAlert();
				toggle();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	//Add schema
	const schema = object().shape({ [`${vendorType}Name`]: string().required() });
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<FormProvider {...methods}>
			<Form onKeyDown={tabOnEnter}>
				<InputWrapper
					formTitle={vendorTypes[useType] + " Information"}
					inputComponents={[
						<InputElement
							{...methods}
							value={name}
							onChange={(e) => {
								setName(e.target.value);
							}}
							name={`${vendorType}Name`}
							label="Name"
							type="text"
						/>,
						<InputElement
							{...methods}
							value={contact1}
							onChange={(e) => {
								setContact1(e.target.value);
							}}
							name={`${vendorType}Contact1`}
							label="Contact"
							type="text"
						/>,
						<PhoneInputElement
							{...methods}
							value={phone1}
							onChange={setPhone1}
							name={`${vendorType}Phone1`}
							label="Phone"
						/>,
						<InputElement
							{...methods}
							value={lenderNo}
							onChange={(e) => {
								setLenderNo(e.target.value);
							}}
							name={`${vendorType}LenderNo`}
							label="License"
							type="text"
						/>,
						<InputElement
							{...methods}
							value={street}
							onChange={(e) => {
								setStreet(e.target.value);
							}}
							name={`${vendorType}Street`}
							label="Street"
							type="text"
						/>,
						<InputElement
							{...methods}
							value={city}
							onChange={(e) => {
								setCity(e.target.value);
							}}
							name={`${vendorType}City`}
							label="City"
							type="text"
						/>,
						<SelectorElement
							{...methods}
							name={`${vendorType}State`}
							label="State"
							value={state}
							onChange={(e) => setState(e.target.value)}
							options={stateOptions}
						/>,
						<InputElement
							{...methods}
							value={zip}
							onChange={(e) => {
								setZip(e.target.value.replace(/[^\d{5}]$/, "").substr(0, 5));
							}}
							name={`${vendorType}Zip`}
							label="Zip"
							type="text"
						/>,
						<InputElement
							{...methods}
							value={email}
							onChange={(e) => {
								setEmail(e.target.value);
							}}
							name={`${vendorType}Email`}
							label="Email"
							type="text"
						/>,
						<InputElement
							{...methods}
							value={website}
							onChange={(e) => {
								setWebsite(e.target.value);
							}}
							name={`${vendorType}Website`}
							label="Website"
							type="text"
						/>,
						<>
							{useType === 70 && (
								<CurrencyInputElement
									value={lineOfCredit}
									name="lineOfCredit"
									label="Line Of Credit"
									type="text"
									onChange={(e) => {
										setLineOfCredit(e);
									}}
								/>
							)}
						</>,

						<InputElement
							{...methods}
							wholeRow={true}
							value={notes}
							name="notes"
							label="Notes"
							type="textarea"
							onChange={(e) => {
								setNotes(e.target.value);
							}}
						/>,
						<div>
							{useType === 30 && (
								<Form onKeyDown={tabOnEnter}>
									<InputWrapper
										formTitle="Lender Page"
										inputComponents={[
											<CurrencyInputElement
												value={bankFee}
												name="bankfee"
												label="Bank Fee (Added to Seller Fees)"
												type="text"
												onChange={setBankFee}
											/>,
											<CurrencyInputElement
												value={lenderFee}
												onChange={setLenderFee}
												name="LenderFee"
												label="Lender Fee (Added to Recap Cost)"
												type="text"
											/>,
											<CurrencyInputElement
												value={lateFee}
												onChange={setLateFee}
												name="LateFee"
												label="Late Fee (Disclousure)"
												type="text"
											/>,

											<PercentInput
												{...methods}
												value={discountRate}
												onChange={setDiscountRate}
												name={`${vendorType}Discount`}
												label="Discount (%)"
												type="text"
											/>,
											<PercentInput
												{...methods}
												value={reserve}
												onChange={setReserve}
												name={`${vendorType}Reserve `}
												label="Reserve (%)"
												type="text"
											/>,
											<PercentInput
												{...methods}
												value={dealerPart}
												onChange={(e) => {
													setDealerPart(e);
												}}
												name={`${vendorType}DealerPart`}
												label="Dealer Participation (%)"
												type="text"
											/>,

											<InputElement
												{...methods}
												value={taxId}
												onChange={(e) => {
													setTaxId(e.target.value);
												}}
												name={`${vendorType}TaxId`}
												label="Tax ID / ELT"
												type="text"
											/>,
											<InputElement
												{...methods}
												value={securityId}
												onChange={(e) => {
													setsecurityID(e.target.value);
												}}
												name={`${vendorType}securityId`}
												label="Security Party Number / Security ID"
												type="text"
											/>,

											<Row>
												<Col md="12">
													<input
														type="checkbox"
														checked={profitOnly}
														onChange={(e) => {
															const tempValue = e.target.checked ? 1 : 0;
															setProfitOnly(tempValue);
														}}
													/>
													<label className="checkbox-spacing">
														Allow Profit only on Recap
													</label>
												</Col>
												<Col md="12">
													<input
														type="checkbox"
														checked={default_}
														onChange={(e) => {
															const tempValue = e.target.checked ? 1 : 0;
															setDefault(tempValue);
														}}
													/>
													<label className="checkbox-spacing">
														{" "}
														Default Lender
													</label>
												</Col>
												<Col md="12">
													<input
														type="checkbox"
														checked={inHouse}
														onChange={(e) => {
															const tempValue = e.target.checked ? 1 : 0;
															setInHouse(tempValue);
														}}
													/>
													<label className="checkbox-spacing"> In-House</label>
												</Col>
											</Row>,
										]}
									/>
								</Form>
							)}
						</div>,
					]}
					buttons={
						<div className="w-100 text-center">
							<Button color="primary" onClick={methods.handleSubmit(addVendor)}>
								<i className="nc-icon nc-simple-add" />{" "}
								{/*updating ? "Update" : "Add"*/}
								Add
							</Button>
						</div>
					}
				/>
			</Form>
		</FormProvider>
	);
};
export default VenderCompanyForm;
