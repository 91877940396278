import React, { useState } from "react";
import {
	Row,
	Col,
	CardTitle,
	Label,
	CardBody,
	CardHeader,
	Form,
	CardText,
	Button,
	CardFooter,
} from "reactstrap";

import NumberSelector from "../../../../component/dc/selector/NumberSelector";
import FinanceAPRInput from "component/dc/deal/finance/FinanceAPRInput";
import AdjustmentAPR from "component/dc/deal/finance/AdjustmentAPR";
import FinanceTermInput from "../../../../component/dc/deal/finance/FinanceTermInput";
import FinanceDaysToPayInput from "component/dc/deal/finance/FinanceDaysToPayInput";
import useCurrentSaleStore from "store/SaleStore";
import RollModalBody from "./modal/RollModalBody";
import OpenModalButton from "../OpenModalButton";
import useCurrentDealStore from "store/DealStore";
import { DealStatus } from "Constants";
import PaymentOptionsModal from "./modal/PaymentOptionsModal";
import ChildrenModal from "../../modal/ChildrenModal";
import OpenModal from "./modal/OpenModal";
import tabOnEnter from "helperFunction/tabOnEnter";
import NumberFormat from "react-number-format";
import useCurrentRecapStore from "../../../../store/RecapStore";
import PrepaidFinanceCharge from "../finance/PrepaidFinanceCharge";
import shallow from "zustand/shallow";
import { confirmDeleteMessage } from "../../modal/SweetAlerts";
import BalloonModalBody from "./modal/BalloonModalBody";

const TermsTable = () => {
	const [showProfit, setShowProfit] = useState(false);

	const toggle = () => {
		setShowProfit(!showProfit);
	};

	const { loanPeriod, editLoanPeriod, bankFee } = useCurrentSaleStore(
		(state) => ({
			loanPeriod: state.loanPeriod,
			editLoanPeriod: state.editLoanPeriod,
			bankFee: state.bankFee,
		}),
		shallow
	);
	const { frontendProfit, backendProfit } = useCurrentRecapStore(
		(state) => ({
			frontendProfit: state.frontendProfit,
			backendProfit: state.backendProfit,
		}),
		shallow
	);

	// Deal store
	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);
	//toggle payment option
	const [paymentOptions, setPaymentOptions] = useState(false);
	const togglePayment = () => setPaymentOptions(!paymentOptions);

	return (
		<div>
			<CardHeader>
				<CardTitle tag="h3">Payments</CardTitle>
			</CardHeader>
			<CardBody className="pb-0" onKeyDown={tabOnEnter}>
				<Form className="deal-terms-table">
					<Row className="mx-0">
						<Col xl="5" className="mb-2">
							<Label className="">Period</Label>
							<NumberSelector
								name="dealLoanPeriod"
								readOnly={type !== DealStatus.PENDING}
								value={loanPeriod}
								selectedOption={loanPeriod}
								handleChange={(e) => {
									editLoanPeriod(e);
								}}
								selectType={"loanPeriod"}
								default={true}
							/>
						</Col>
						<Col xl="5" className="mb-2">
							<Label value="">Days to 1st Pmt</Label>
							<FinanceDaysToPayInput />
						</Col>
						<Col xl="3" className="mb-2">
							<FinanceAPRInput />
						</Col>
						<Col xl="3" className="mb-2">
							<Label value="">Term</Label>
							<FinanceTermInput />
						</Col>
					</Row>

					{bankFee > 0 && (
						<Row>
							<Col xl="4" className="mb-2">
								<Label value="">Adj APR (%)</Label>
								<AdjustmentAPR />
							</Col>
							<Col xl="6" className="mb-2">
								<Label value="">Prepaid Finance Charge</Label>
								<PrepaidFinanceCharge />
							</Col>
						</Row>
					)}

					<hr className="my-2" />
					<Row className="px-2">
						<Col xl="6">
							<BalloonButton />
						</Col>
						<Col xl="6">
							<RollButton />
						</Col>
					</Row>

					<hr className="my-2" />
					<Col>
						<Row className="mb-3">
							<div className={!showProfit ? "mx-auto" : "row"}>
								<Button className="px-4 " color="primary" onClick={toggle}>
									QP
								</Button>
								{showProfit && (
									<CardText
										className={
											frontendProfit + backendProfit > 0
												? "text-default px-4 pt-2"
												: "text-danger px-4 pt-2"
										}
										tag="h5"
									>
										<NumberFormat
											value={frontendProfit + backendProfit}
											thousandSeparator={true}
											decimalScale={2}
											fixedDecimalScale={true}
											prefix={"$"}
											isNumericString={true}
											displayType="text"
										/>
									</CardText>
								)}
								<OpenModalButton
									buttonText="Payment Options"
									onClick={togglePayment}
									iconClass="nc-icon nc-refresh-69"
									className="btn btn-lg btn-link"
								/>
								<ChildrenModal
									modalTitle="Payment Options"
									modal={paymentOptions}
									toggle={togglePayment}
									children={<PaymentOptionsModal toggle={togglePayment} />}
									size="lg"
								/>
							</div>
						</Row>
					</Col>
				</Form>
				<CardFooter />
			</CardBody>
		</div>
	);
};

export default TermsTable;

const RollButton = () => {
	const {
		rollBack,
		editPrice,
		rollPreValue,
		editRollPreValue,
		editRollBack,
		editTerm,
		editApr,
		editDown,
	} = useCurrentSaleStore((state) => state, shallow);

	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);

	const confirmRollBack = () => {
		confirmDeleteMessage("Roll deal back?").then((res) => {
			const confirmDelete = res;
			if (confirmDelete === "Roll deal back?") {
				handleRollBack();
			}
		});
	};

	const handleRollBack = () => {
		switch (Number.parseInt(rollBack)) {
			case 10:
				editPrice(rollPreValue);
				editRollPreValue(0);
				editRollBack(0);
				break;
			case 20:
				editDown(rollPreValue);
				editRollPreValue(0);
				editRollBack(0);
				break;
			case 40:
				editApr(rollPreValue);
				editRollPreValue(0);
				editRollBack(0);
				break;
			case 90:
				editTerm(rollPreValue);
				editRollPreValue(0);
				editRollBack(0);
				break;
			default:
				break;
		}
	};

	if (rollBack === 0 || rollBack === null) {
		return (
			<OpenModal
				BodyComponent={RollModalBody}
				className="btn w-100 btn-md"
				buttonText="Roll"
				size="lg"
			/>
		);
	} else {
		return (
			<Button
				readOnly={type !== DealStatus.PENDING}
				onClick={confirmRollBack}
				className="btn w-100 btn-md"
			>
				Rollback
			</Button>
		);
	}
};

const BalloonButton = () => {
	const {
		hasBalloon,
		editHasBalloon,
		editBalloonTerm,
		term,
	} = useCurrentSaleStore((state) => state, shallow);

	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);

	const confirmBalloonBack = () => {
		confirmDeleteMessage("Cancel balloon?").then((res) => {
			const confirmDelete = res;
			if (confirmDelete === "Cancel balloon?") {
				editHasBalloon(false);
				editBalloonTerm(term);
				// edit functions for negating balloon
			}
		});
	};

	if (hasBalloon === false || hasBalloon === null) {
		return (
			<OpenModal
				BodyComponent={BalloonModalBody}
				className="btn w-100 btn-md"
				buttonText="Balloon"
				size="md"
				readOnly={term === 1}
			/>
		);
	} else {
		return (
			<Button
				readOnly={type !== DealStatus.PENDING}
				onClick={confirmBalloonBack}
				className="btn w-100 btn-md"
			>
				Undo Balloon
			</Button>
		);
	}
};
