import React, { useState } from "react";
import { Card, CardHeader, Col, Form, Row, Input } from "reactstrap";

import ChildrenModal from "component/dc/modal/ChildrenModal";

import NumberFormat from "react-number-format";

import useCurrentDealStore from "../../../../store/DealStore";
import shallow from "zustand/shallow";
import { DealStatus } from "Constants";
import tabOnEnter from "helperFunction/tabOnEnter";
import OpenTotalModal from "component/dc/deal/sale/OpenTotalModal";
import { CheckBoxElement } from "../../../../view/formValidation/InputElement";

const ContractInfo = ({ modal, toggle }) => {
	const {
		contractInfo,
		cancellationAmt,
		cancellationAccepted,
		restockingFee,
		type,
		editContractInfo,
		editCancellationAccepted,
	} = useCurrentDealStore(
		(state) => ({
			editContractInfo: state?.editContractInfo,
			contractInfo: state?.contractInfo,
			cancellationAmt: state?.contractInfo?.cancellationAmt,
			cancellationAccepted: state?.contractInfo?.cancellationAccepted,
			restockingFee: state?.contractInfo?.restockingFee,
			type: state.deal.type,
			editCancellationAccepted: state.editCancellationAccepted,
		}),
		shallow
	);

	const editContractInfoObj = (field, val) => {
		console.log(contractInfo);
		console.log(val);
		console.log(val);
		let temp = { ...contractInfo };
		temp[field] = val;
		editContractInfo(temp);
	};

	return (
		<ChildrenModal
			modal={modal}
			toggle={toggle}
			size="lg"
			modalTitle="Contract Info Details"
			children={
				<Form onKeyDown={tabOnEnter}>
					<Card>
						<CardHeader>
							<div className="pb-2">
								<Row className="m-0 p-0">
									<Col sm="4">
										<div className="btn btn-md btn-primary disabled w-100">
											Cancellation Fee
										</div>
									</Col>
									<Col sm="2" className="pt-2">
										<NumberFormat
											name="cancellationAmt"
											readOnly={type === DealStatus.SOLD ? true : false}
											value={cancellationAmt}
											decimalScale={2}
											prefix={"$"}
											fixedDecimalScale={true}
											isNumericString={true}
											customInput={Input}
											onFocus={(e) => e.target.select()}
											className="bold"
											onValueChange={(values) =>
												editContractInfoObj(
													"cancellationAmt",
													values.floatValue
												)
											}
										/>
									</Col>
									{/* <Col sm="4">
										<div className="btn btn-md btn-primary disabled w-100">
											Contract Status
										</div>
									</Col> */}
									<Col sm="2">
										<CheckBoxElement
											disableValidation
											checked={cancellationAccepted}
											onChange={() => {
												editCancellationAccepted(!cancellationAccepted);
											}}
											name={cancellationAccepted}
											label="Accepted"
											labelClass="pl-4"
										/>
									</Col>
								</Row>
								<Row>
									<Col sm="4">
										<div className="btn btn-md btn-primary disabled w-100">
											Restocking Fee
										</div>
									</Col>
									<Col sm="2" className="pt-2">
										<NumberFormat
											name="restockingFee"
											readOnly={type === DealStatus.SOLD ? true : false}
											value={restockingFee}
											decimalScale={2}
											prefix={"$"}
											fixedDecimalScale={true}
											isNumericString={true}
											customInput={Input}
											onFocus={(e) => e.target.select()}
											className="bold"
											onValueChange={(values) =>
												editContractInfoObj("restockingFee", values.floatValue)
											}
										/>
									</Col>
								</Row>
							</div>
						</CardHeader>
					</Card>
				</Form>
			}
		/>
	);
};
const ContractInfoDetail = () => {
	const [openModal, setOpenModal] = useState(false);

	const { cancellationAmt, cancellationAccepted } = useCurrentDealStore(
		(state) => ({
			cancellationAmt: state?.contractInfo?.cancellationAmt,
			cancellationAccepted: state?.contractInfo?.cancellationAccepted,
		}),
		shallow
	);

	const toggle = () => {
		setOpenModal(!openModal);
	};

	return (
		(<div>Contract Info Detail</div>),
		(
			<Row>
				<ContractInfo modal={openModal} toggle={toggle} />
				<div className="col-sm-6 px-0">
					<OpenTotalModal
						btnClass="w-100 btn-md btn btn btn-primary btn-md px-0"
						label="Contract Info"
						toggle={toggle}
					/>
				</div>
				<div className="col-sm-6 pr-0 dealInputs pl-2">
					<NumberFormat
						value={cancellationAccepted ? cancellationAmt : 0}
						thousandSeparator={true}
						decimalScale={2}
						fixedDecimalScale={true}
						prefix={"$"}
						isNumericString={true}
						readOnly={true}
						customInput={Input}
						className="bold"
					/>
				</div>
			</Row>
		)
	);
};

export default ContractInfoDetail;
