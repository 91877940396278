import React from "react";
import { Card, Table } from "reactstrap";

import NumberFormat from "react-number-format";

const BlastMessageReceivers = ({ customers }) => {
	const tableData = customers.map((customer, id) => (
		<tr key={id}>
			<td>
				{customer.name || customer.firstName + customer.lastName}
				{customer.error != null && <p>{`*${customer.error}*`}</p>}
			</td>
			<td>{customer.email}</td>
			<td>
				<NumberFormat
					value={customer.cellPhone || customer.phone}
					format="+1 (###) ###-####"
					mask="_"
					isNumericString={true}
					displayType="text"
				/>
			</td>
		</tr>
	));

	return (
		<>
			{tableData.length > 0 && (
				<Card className="blast-message-participants">
					<Table bordered className="mt-2">
						<thead>
							<tr>
								<th>Name</th>
								<th>Email</th>
								<th>Cell Phone</th>
							</tr>
						</thead>
						<tbody>{tableData}</tbody>
					</Table>
				</Card>
			)}
		</>
	);
};

export default BlastMessageReceivers;
