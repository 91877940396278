import React, { useState, useEffect } from "react";
import {
	Button,
	Label,
	Card,
	Col,
	CardBody,
	CardHeader,
	CardTitle,
	Row,
	Form,
} from "reactstrap";
import { getVehicleSettings, saveVehicleSettings } from "api/InventoryAPI";
import { saveSuccessAlert } from "component/dc/modal/SweetAlerts";
import { retrieveFailAlert } from "component/dc/modal/SweetAlerts";
import { useFromUser } from "store/LocalStorageHelper";
import tabOnEnter from "helperFunction/tabOnEnter";
import { showApiError } from "../../../../helperFunction/ApiErrorHandler";
import { saveFailAlert } from "../../../../component/dc/modal/SweetAlerts";
import InputElement, {
	CheckBoxElement,
	CurrencyInput,
	IntegerInput,
	PercentInput,
} from "../../../formValidation/InputElement";
/**
 *
 */
const InventorySettings = () => {
	const [inventorySettings, setInventorySettings] = useState({});

	//const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	// Hooks for inventory Defaults store values
	const [isRunningStockNo, setIsRunningStockNo] = useState(
		inventorySettings?.isRunningStockNo
	);
	const [firstStockNo, setFirstStockNo] = useState(
		inventorySettings?.firstStockNo?.toString()
	);
	const [runningStockNoLength, setRunningStockNoLength] = useState(
		inventorySettings?.runningStockNoLength?.toString()
	);
	const [packPercent, setPackPercent] = useState(
		inventorySettings?.packPercent
	);
	const [packUsed, setPackUsed] = useState(inventorySettings?.packUsed);
	// const inventoryDefault = {
	// 	dealerID,
	// 	locationID,
	// 	isRunningStockNo,
	// 	firstStockNo,
	// 	runningStockNoLength,
	// 	packPercent,
	// 	packUsed,
	// };
	useEffect(() => {
		getData();
		// eslint-disable-next-line
	}, []);

	const save = () => {
		inventorySettings.isRunningStockNo = isRunningStockNo;
		inventorySettings.firstStockNo = firstStockNo;
		inventorySettings.runningStockNoLength = runningStockNoLength;
		inventorySettings.packPercent = packPercent;
		inventorySettings.packUsed = packUsed;
		saveVehicleSettings(inventorySettings).then(
			(response) => {
				setInventorySettings(inventorySettings);
				saveSuccessAlert();
				console.log(
					"Inventory Settings saved with ID: " + response.data.content.recnum
				);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	const getData = () => {
		getVehicleSettings(locationID).then(
			(res) => {
				console.log(res);
				setInventorySettings(res.data.content);
				setIsRunningStockNo(res.data.content.isRunningStockNo);
				setFirstStockNo(res.data.content.firstStockNo);
				setRunningStockNoLength(res.data.content.runningStockNoLength);
				setPackPercent(res.data.content.packPercent);
				// setPackNew(res.data.content.packNew);
				setPackUsed(res.data.content.packUsed);
			},
			(err) => {
				console.log(err);

				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};
	useEffect(() => {
		console.log(firstStockNo);
		//setRunningStockNoLength(firstStockNo);
		// eslint-disable-next-line
	}, [firstStockNo]);

	return (
		<div onKeyDown={tabOnEnter}>
			<Form>
				<Button
					color="success"
					onClick={() => {
						save();
					}}
					size="md"
				>
					<i className="nc-icon nc-cloud-download-93"></i> Save
				</Button>

				<Card>
					<CardHeader>
						<CardTitle tag="h3">Auto Stock Number</CardTitle>
					</CardHeader>
					<CardBody>
						<Col>
							<Row className="mx-0 pb-2">
								<CheckBoxElement
									disableValidation
									checked={isRunningStockNo}
									name="isRunningStockNo"
									onChange={() => setIsRunningStockNo(!isRunningStockNo)}
									label="Is Running StockNo?"
									labelClass="pl-4"
								/>
							</Row>
							<Row className="mx-0 pb-2">
								<InputElement
									readOnly={!isRunningStockNo}
									disableValidation
									colSize="col-lg-3"
									value={firstStockNo}
									onChange={(e) => {
										console.log(e.target.value.length);
										setRunningStockNoLength(e.target.value.length);
										setFirstStockNo(e.target.value);
									}}
									name="firstStockNo"
									label="First Stock Number"
									type="text"
								/>
								<IntegerInput
									readOnly={true}
									disableValidation
									value={runningStockNoLength}
									onChange={(e) => setRunningStockNoLength(e)}
									name="runningStockNoLength"
									label="Stock Number Length"
									type="number"
								/>
							</Row>
						</Col>
					</CardBody>
				</Card>
				<Card>
					<CardHeader>
						<CardTitle tag="h3">Pack setting</CardTitle>
					</CardHeader>
					<CardBody>
						<Col>
							<Row className="mx-0 pb-2">
								<Label> Pack = </Label>
								<PercentInput
									label="Percentage Of Purchase Price"
									value={packPercent}
									onChange={setPackPercent}
									disableValidation
								/>

								<Label> + </Label>
								<CurrencyInput
									value={packUsed}
									onChange={setPackUsed}
									name="packUsed"
									label="Fixed Amount"
									type="Currency"
									disableValidation
								/>
							</Row>
						</Col>
					</CardBody>
				</Card>
			</Form>
		</div>
	);
};

export default InventorySettings;
