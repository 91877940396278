import React, { useState, createContext } from "react";
import shallow from "zustand/shallow";
import useCurrentDealStore from "../../../store/DealStore";
import { useFromUser } from "../../../store/LocalStorageHelper";

export const FormsReportsContext = createContext();

const FormsReportsProvider = ({ children }) => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const { buyerEmail } = useCurrentDealStore(
		(state) => ({
			buyerEmail: state.buyer.email,
		}),
		shallow
	);
	// Object with params for a single dcReport entry
	const [params, setParams] = useState({ dealerID, locationID });
	const [pdfRequest, setPdfRequest] = useState({
		dealID: null,
		inventoryID: null,
		dcReportID: null,
		params,
		dcReport: null,
	});

	const dealerEmail = JSON.parse(sessionStorage.getItem("user"))?.locations
		?.email;
	//	const dealerEmail = JSON.parse(sessionStorage.getItem("user"))?.email;
	// this can then contain a pdfRequest or a lenderPackRequest, add it at the end before making the final email request
	const [emailRequest, setEmailRequest] = useState({
		from: dealerEmail,
		to: buyerEmail || "",
		subject: "",
		body: "",
		fileName: null,
		cc: null,
		bcc: null,
	});

	const context = {
		setParams,
		pdfRequest,
		setPdfRequest,
		emailRequest,
		setEmailRequest,
	};
	return (
		<FormsReportsContext.Provider value={context}>
			{children}
		</FormsReportsContext.Provider>
	);
};

export default FormsReportsProvider;
