export const login = (data) => {
	sessionStorage.setItem("user", data);
};

export const logout = () => {
	sessionStorage.removeItem("user");
};

export const authenticated = () => {
	console.log("Current User: ");
	console.log(JSON.parse(sessionStorage.getItem("user")));
	return sessionStorage.getItem("user") !== null;
};
