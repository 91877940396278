import { useEffect, useCallback } from "react";
import useCurrentSaleStore from "../../store/SaleStore";
import useCurrentDealStore from "../../store/DealStore";
import useCurrentRecapStore from "../../store/RecapStore";
import shallow from "zustand/shallow";

const useCalculateGlobalfield = () => {
	const {
		price,
		totalDMVFees,
		down,
		tax,
		manufacturerRebate,
		daysToPay,
		loanDate,
		loanPeriod,
		term,
		firstDueDate,
		apr,
		addOn,
		amtFinanced,
		docFee,
		dealerSmog,
		stateSmog,
		taxRate,
		bankFee,
		aftmktCost,
		fiDiscount,
		discount,
		rollBack,
		balloonTerm,
	} = useCurrentSaleStore((state) => state, shallow);

	const {
		bodyInjuryPremium,
		collisionPremium,
		compPremium,
		medicalPremium,
		otherPremium,
		propertyPremium,
	} = useCurrentDealStore(
		(state) => ({
			dmvTaxRate: state?.deal?.dealSettings?.taxDefaults?.dmvTaxRate,
			servContractTaxRate:
				state?.deal?.dealSettings?.taxDefaults?.servContractTaxRate,
			aftmktTaxRate: state?.deal?.dealSettings?.taxDefaults?.aftmktTaxRate,
			gapTaxRate: state?.deal?.dealSettings?.taxDefaults?.gapTaxRate,
			// taxRate: state?.deal?.dealSettings?.taxDefaults?.taxRate,
			docFeeTaxRate: state?.deal?.dealSettings?.taxDefaults?.docFeeTaxRate,
			dealerSmogTaxRate:
				state?.deal?.dealSettings?.taxDefaults?.dealerSmogTaxRate,
			taxCreditRate: state?.deal?.dealSettings?.taxDefaults?.taxCreditRate,
			vitRate: state?.deal?.dealSettings?.taxDefaults?.vitRate,

			bodyInjuryPremium: state.collisionInsurance?.bodyInjuryPremium,
			collisionPremium: state.collisionInsurance?.collisionPremium,
			compPremium: state.collisionInsurance?.compPremium,
			medicalPremium: state.collisionInsurance?.medicalPremium,
			otherPremium: state.collisionInsurance?.otherPremium,
			propertyPremium: state.collisionInsurance?.propertyPremium,
		}),
		shallow
	);

	const { cancellationAmt, cancellationAccepted } = useCurrentDealStore(
		(state) => state?.contractInfo,
		shallow
	);

	const { countyRecNum } = useCurrentDealStore((state) => state.deal, shallow);

	// profit settings
	const {
		isDocFeeProfit,
		isDealerSmogProfit,
		isReserveProfit,
		isServConProfit,
		isGapProfit,
		isAftMktProfit,
		isMiscProfit,
		isBankFeeProfit,
	} = useCurrentDealStore(
		(state) => state?.deal?.dealSettings?.profitSettings,
		shallow
	);

	// profit settings
	const {
		isDocFeeComm,
		isAftMktComm,
		isServConComm,
		isGapComm,
		isMiscComm,
		isBankFeeComm,
		isReserveComm,
		isSmogComm,
	} = useCurrentDealStore(
		(state) => state?.deal?.dealSettings?.commissionSettings,
		shallow
	);

	// used for commission calc
	const {
		//credLifeProfit,
		//disabProfit,
		fiGross,
		totalGross,
		salesGross,
		collisionTotCost,
	} = useCurrentRecapStore((state) => state, shallow);

	const {
		VSICost,
		miscCost,
		gapInsCost,
		warrantyCost,
		interestCost,
		dlrParticipation,
		additionalProfit,
		carProfit,
		reconProfit,
		aftmktProfit,
		totalTradeProfit,
		miscProfit,
		gapInsProfit,
		warrantyProfit,
		frontendProfit,
		backendProfit,
		lenderFee,
		incentive,
		reserve,
		totalDown,
		interestNetProfit,
		grossProfit,
		editGrossProfit,
		gpBeforeFees,
		gpBeforeReserve,
		reservePercent,
		totalLenderFee,
		editTotalLenderFee,
		editNetCheckToDealer,
		editOfficial,
		editGpBeforeFees,
		editGpBeforeReserve,
		editGpBeforeComm,
		editReceivable,
		editReserve,
	} = useCurrentRecapStore((state) => state, shallow);
	const { vehicle } = useCurrentDealStore((state) => state, shallow);

	const twoDecimal = (value) => {
		return parseFloat(parseFloat(value).toFixed(2));
	};

	const holdback = vehicle?.holdback || 0;
	const pack = vehicle?.pack || 0;

	const memoCalculateRecap = useCallback(() => {
		const calcOfficialFeesProfit = () => {
			const total = tax + totalDMVFees;
			editOfficial(total);
		};
		calcOfficialFeesProfit();

		const calcGPBeforeFees = () => {
			const total = parseFloat(frontendProfit) + parseFloat(backendProfit);
			const finalVal = twoDecimal(total);
			editGpBeforeFees(finalVal);
		};
		calcGPBeforeFees();
		const calcLenderFeeInfo = () => {
			editTotalLenderFee(lenderFee + fiDiscount);
			editNetCheckToDealer(amtFinanced - (lenderFee + fiDiscount));
		};
		calcLenderFeeInfo();

		const calcGPBeforeReserve = () => {
			const total =
				parseFloat(gpBeforeFees) -
				parseFloat(totalLenderFee) +
				parseFloat(incentive) +
				parseFloat(holdback) -
				parseFloat(pack);
			const finalVal = twoDecimal(total);
			editGpBeforeReserve(finalVal);
		};
		calcGPBeforeReserve();

		const calcGPBeforeComm = () => {
			console.log(gpBeforeReserve);
			console.log(reserve);
			const total = parseFloat(gpBeforeReserve) - parseFloat(reserve);
			const finalVal = twoDecimal(total);
			editGpBeforeComm(finalVal);
			editGrossProfit(finalVal);
			console.log(finalVal);
		};
		calcGPBeforeComm();

		const calcReceivable = () => {
			const total =
				parseFloat(amtFinanced) -
				(parseFloat(lenderFee) + parseFloat(fiDiscount)) +
				parseFloat(interestNetProfit) +
				parseFloat(totalDown);
			const finalVal = twoDecimal(total);
			editReceivable(finalVal);
		};
		calcReceivable();

		const calcReserve = () => {
			const resAmt = amtFinanced * reservePercent;
			editReserve(resAmt);
		};

		calcReserve();
	}, [
		editNetCheckToDealer,
		editTotalLenderFee,
		totalLenderFee,
		tax,
		totalDMVFees,
		amtFinanced,
		reserve,
		backendProfit,
		frontendProfit,
		gpBeforeFees,
		gpBeforeReserve,
		fiDiscount,
		interestNetProfit,
		incentive,
		totalDown,
		lenderFee,
		reservePercent,
		holdback,
		pack,
		editOfficial,
		editGpBeforeFees,
		editGpBeforeReserve,
		editGpBeforeComm,
		editGrossProfit,
		editReceivable,
		editReserve,
	]);

	useEffect(() => {
		memoCalculateRecap();
		// eslint-disable-next-line
	}, [
		price,
		down,
		tax,
		manufacturerRebate,
		daysToPay,
		loanDate,
		loanPeriod,
		term,
		firstDueDate,
		apr,
		addOn,
		taxRate,
		countyRecNum,
		cancellationAmt,
		cancellationAccepted,
		aftmktCost,
		docFee,
		dealerSmog,
		stateSmog,
		VSICost,
		miscCost,
		gapInsCost,
		warrantyCost,
		bankFee,
		interestCost,
		dlrParticipation,
		isDocFeeProfit,
		isDealerSmogProfit,
		isReserveProfit,
		isServConProfit,
		isGapProfit,
		isAftMktProfit,
		isMiscProfit,
		isBankFeeProfit,
		reservePercent,
		rollBack,
		balloonTerm,
		discount,
		bodyInjuryPremium,
		collisionPremium,
		compPremium,
		medicalPremium,
		otherPremium,
		propertyPremium,
		additionalProfit,
		reserve,
		grossProfit,
		salesGross,
		fiGross,
		totalGross,
		carProfit,
		reconProfit,
		totalTradeProfit,
		docFee,
		dealerSmog,
		reserve,
		warrantyProfit,
		gapInsProfit,
		aftmktProfit,
		miscProfit,
		lenderFee,
		incentive,
		gpBeforeReserve,
		isDocFeeProfit,
		isDealerSmogProfit,
		isReserveProfit,
		isServConProfit,
		isGapProfit,
		isAftMktProfit,
		isMiscProfit,
		isBankFeeProfit,
		isDocFeeComm,
		isAftMktComm,
		isServConComm,
		isGapComm,
		isMiscComm,
		isBankFeeComm,
		isReserveComm,
		isSmogComm,
		additionalProfit,
		collisionTotCost,
	]);
};

export default useCalculateGlobalfield;
