import getAxiosInstance from "./interceptors/buildReqAndResInstance";

import { userBaseUrl } from "./Constants";
// import { LOCAL } from "./Constants";
// const localInstance = getAxiosInstance({
// 	baseURL: LOCAL + "/lead/",
// });

const instance = getAxiosInstance({
	baseURL: userBaseUrl + "/customer/lead/",
});

// Get Lead
export const getLeadDetails = async (ID) => {
	return await instance.get(`${ID}`);
};

// Get Lead Notes
export const getLeadDealerNotes = async (ID) => {
	return await instance.get(`leadNotes/${ID}`);
};

// Get Lead list
export const getLeadList = async (locationID) => {
	return await instance.get(`list/${locationID}`);
};
// Get Lead list by status
export const getLeadListByStatus = async (locationID, status) => {
	return await instance.get(`list/${locationID}/${status}`);
};
// Delete Lead list
export const deleteLeads = async (IDs) => {
	return await instance.get(`delete?IDs=${IDs}`);
};
// Update Lead status
export const updateLeadStatus = async (ID, status) => {
	return await instance.get(`updateStatus/${ID}?status=${status}`);
};
// Update Lead status
export const updateLeadCustomerID = async (leadID, customerID) => {
	return await instance.get(
		`updateCustomerID?leadID=${leadID},customerID=${customerID}`
	);
};
// updateCustomerID
export const updateCustomerID = async (leadID, customerID) => {
	return await instance.get(
		`updateCustomerID?leadID=${leadID}&customerID=${customerID}`
	);
};
// save As Customers
export const saveAsCustomer = async (ID) => {
	return await instance.get(`saveAsCustomer/${ID}`);
};
// save Lead
export const saveLead = async (lead) => {
	return await instance.post(`save`, lead);
};
