import React from "react";
import { Form, FormGroup, Label, Row, Card, CardBody, Col } from "reactstrap";

import useCurrentSaleStore from "../../../../../../store/SaleStore";
import useCurrentDealStore from "store/DealStore";
import Select from "react-select";
import { CurrencyInputElement } from "../../../../../../view/formValidation/InputElement";
import { useForm, FormProvider } from "react-hook-form";
import { DealStatus } from "Constants";
import shallow from "zustand/shallow";
import { INS_TYPE, INS_TYPE_AH } from "../../../../../../Constants";
import { SelectorElement } from "../../../../../../view/formValidation/InputElement";
import useCurrentRecapStore from "../../../../../../store/RecapStore";

const CreditInsurance = () => {
	// Sale Store
	const {
		creditLifeFlag,
		disabilityFlag,
		lifePremium,
		disabilityPremium,
		editCreditLifeFlag,
		editDisabilityFlag,
		editLifePremium,
		editDisabilityPremium,
	} = useCurrentSaleStore(
		(state) => ({
			creditLifeFlag: state.creditLifeFlag,
			disabilityFlag: state.disabilityFlag,
			lifePremium: state.lifePremium,
			disabilityPremium: state.disabilityPremium,
			editCreditLifeFlag: state.editCreditLifeFlag,
			editDisabilityFlag: state.editDisabilityFlag,
			editLifePremium: state.editLifePremium,
			editDisabilityPremium: state.editDisabilityPremium,
		}),
		shallow
	);
	// Deal Store
	const {
		insCreditLifeRecNum,
		type,
		editInsCreditLifeRecNum,
		gapCompanies,
	} = useCurrentDealStore(
		(state) => ({
			insCreditLifeRecNum: state.deal.insCreditLifeRecNum,
			type: state.deal.type,
			editInsCreditLifeRecNum: state.editInsCreditLifeRecNum,
			gapCompanies: state.gapCompanies,
		}),
		shallow
	);

	const {
		creditLifeCost,
		disabCost,
		editCreditLifeCost,
		editDisabCost,
	} = useCurrentRecapStore(
		(state) => ({
			creditLifeCost: state.creditLifeCost,
			disabCost: state.disabCost,
			editCreditLifeCost: state.editCreditLifeCost,
			editDisabCost: state.editDisabCost,
		}),
		shallow
	);

	const gapComList = gapCompanies.map((obj) => {
		return {
			label: obj.name,
			value: obj.ID,
			gapCo: obj,
		};
	});

	// Define form validation parameters
	const methods = useForm();

	return (
		<Card>
			{/* <CardHeader>
				<CardTitle tag="h3">Credit Insurance</CardTitle>
			</CardHeader> */}
			<CardBody>
				<FormProvider {...methods}>
					<Form>
						<Col>
							<Row>
								<FormGroup className={"col"}>
									<Label>Company</Label>
									<Select
										isDisabled={type !== DealStatus.PENDING}
										value={{
											value: insCreditLifeRecNum,
											label: gapComList.filter(
												(e) => e.value === insCreditLifeRecNum
											)[0]?.label,
										}}
										options={gapComList}
										onChange={(e) => {
											editInsCreditLifeRecNum(e.value);
										}}
									/>
								</FormGroup>
							</Row>
							<Row>
								<SelectorElement
									{...methods}
									name="life"
									label="Life"
									value={creditLifeFlag}
									onChange={(e) => editCreditLifeFlag(e.target.value)}
									options={INS_TYPE}
									colSize="col-sm-4"
									disabled={type !== DealStatus.PENDING}
								/>

								<CurrencyInputElement
									readOnly={type !== DealStatus.PENDING}
									value={creditLifeCost}
									onChange={editCreditLifeCost}
									name="creditLifeCost"
									label="Cost"
									type="number"
									wholeRow={true}
									disableValidation
								/>
								<CurrencyInputElement
									readOnly={type !== DealStatus.PENDING}
									value={lifePremium}
									onChange={editLifePremium}
									name="lifePremium"
									label="Amount"
									type="number"
									wholeRow={true}
									disableValidation
								/>
							</Row>

							<Row>
								<SelectorElement
									{...methods}
									name="disability"
									label="Disability"
									value={disabilityFlag}
									onChange={(e) => editDisabilityFlag(e.target.value)}
									options={INS_TYPE_AH}
									colSize="col-sm-4"
									disabled={type !== DealStatus.PENDING}
								/>

								<CurrencyInputElement
									readOnly={type !== DealStatus.PENDING}
									value={disabCost}
									onChange={editDisabCost}
									name="disabCost"
									label="Cost"
									type="number"
									wholeRow={true}
									disableValidation
								/>

								<CurrencyInputElement
									readOnly={type !== DealStatus.PENDING}
									value={disabilityPremium}
									onChange={editDisabilityPremium}
									name="disabilityPremium"
									label="Amount"
									type="number"
									wholeRow={true}
									disableValidation
								/>
							</Row>
						</Col>
					</Form>
				</FormProvider>
			</CardBody>
		</Card>
	);
};

export default CreditInsurance;
