import React, { useRef, useState } from "react";

import "assets/css/file-uploader.css";

/**
 * Component used to upload file(s). All component props are required.
 *
 * @param {number} maxFileSize Max allowed file size of upload, in MBs
 * @param {React.SetStateAction} setUploads State setter for setting uploads
 * @param {String} accept String containing a list of acceptable MIME types
 * @param {Array} acceptArray Array of Strings containing a list of acceptable extensions
 * @param {String} iconClass Class name of the icon element
 * @param {boolean} hideIconText Whether to render the icon text or not
 * @param {boolean} single Whether to allow multiple file uploads
 */
const FileUploader = ({
	maxFileSize,
	setUploads,
	accept,
	acceptArray,
	iconClass,
	hideIconText,
	single,
}) => {
	// Local states
	const fileInputRef = useRef(null);
	const [uploadErrors, setUploadErrors] = useState([]);

	// Calls the onClick function of the file input element
	const updateInput = () => {
		fileInputRef.current.click();
		setUploadErrors([]);
	};

	// Called when file(s) have been selected for upload
	const uploadFile = (e) => {
		e.preventDefault();

		Object.entries(e.target.files).forEach((arr) => {
			const reader = new FileReader();
			const currentFile = arr[1];

			if (currentFile != null) {
				reader.readAsDataURL(currentFile);
				reader.onloadend = () => fileLoaded(currentFile);
				reader.onerror = (err) => fileError(err, e);
			}
		});
	};

	// Verifies file passes constraint(s)
	const fileLoaded = (file) => {
		const fileName = file.name;
		const extensionIndex = fileName.lastIndexOf(".");

		// Verifies file is one of these extensions
		if (
			extensionIndex === -1 ||
			!acceptArray.includes(fileName.substring(extensionIndex).toLowerCase())
		) {
			setUploadErrors((prev) => [
				...prev,
				`${fileName} must be one of these types: ` + accept,
			]);
		}
		// Verifies file is no more than this size
		else if (file.size / 1048576 > maxFileSize) {
			setUploadErrors((prev) => [
				...prev,
				`${fileName} cannot be bigger than ${maxFileSize}MB`,
			]);
		} else {
			setUploads((prev) => [...prev, file]);
		}
	};

	// Called when there is an error in the file upload process
	const fileError = (e, upload) => {
		if (e?.target?.error?.name === "NotReadableError") {
			setUploadErrors((prev) => [
				...prev,
				`${upload.name} could not be read, please try again`,
			]);
		} else {
			setUploadErrors((prev) => [
				...prev,
				`${e?.target?.error?.name || "Unexpected error"}`,
			]);
		}
	};

	return (
		<div>
			<div className="col-12 d-flex justify-content-center align-items-center">
				<div
					role="button"
					className="file-uploader-upload"
					onClick={updateInput}
				>
					<i className={iconClass == null ? "fa fa-upload" : iconClass} />
					{hideIconText !== true && <span>Select Files</span>}
					<input
						ref={fileInputRef}
						type="file"
						onChange={uploadFile}
						multiple={single == null ? true : false}
						value=""
						accept={accept}
					/>
				</div>
			</div>
			{uploadErrors.length > 0 && (
				<div className="col-12 file-uploader-list">
					<p className="file-uploader-list-title">
						Some Files Could Not Be Uploaded:
					</p>
					<ul>
						{uploadErrors.map((err, i) => (
							<li key={`${err[0]}_${i}`}>{err}</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};

export default FileUploader;
