import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import useCurrentVehicleStore from "../../../store/InventoryStore";
import shallow from "zustand/shallow";

const InventoryTitle = () => {
	// Form Names
	// Vehicle store
	const { stock, vin, year, make, model } = useCurrentVehicleStore(
		(state) => ({
			stock: state.stockNo,
			vin: state.vin,
			year: state.year,
			make: state.make,
			model: state.model,
		}),
		shallow
	);

	return (
		<div className="col pb-3">
			<Card>
				<CardHeader>
					<CardBody
						className="px-3"
						tag="h5"
						style={{ display: "flex", justifyContent: "space-between" }}
					>
						<span style={{ color: "blue", fontWeight: "bold" }}>
							{year} {make} {model}
						</span>
						<div>
							<span style={{ color: "black" }}>VIN: </span>
							<span style={{ color: "red" }}>{vin}</span>
							<span style={{ color: "black", marginLeft: "10px" }}>
								STOCK:{" "}
							</span>
							<span style={{ color: "green" }}>{stock}</span>
						</div>
					</CardBody>
				</CardHeader>
			</Card>
		</div>
	);
};

export default InventoryTitle;
