import React, { useState, useEffect, useRef } from "react";
import { Button, Row } from "reactstrap";
import IDPhoto from "../../../../../assets/img/imgPlaceholder.png";

import useCurrentDealStore from "store/DealStore";

import shallow from "zustand/shallow";
import { useFromUser } from "../../../../../store/LocalStorageHelper";
import { getCustomerPhoto } from "../../../../../api/AttachmentsAPI";

import Customer700CreditTab from "../../../../../view/contact/customerDetailTab/credit700/Customer700CreditTab";
import ChildrenModal from "../../../modal/ChildrenModal";
import { DealStatus } from "Constants";
import OpenModalButton from "../../OpenModalButton";
import ContactAddPage from "../../../../../view/contact/CustomerAddPage";
import CustomerListPage from "view/contact/CustomerListPage";
import CustomerDealDetails from "../CustomerDealDetails";
import Credit from "assets/img/700CreditLogo.png";

const BuyerHeader = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	// Deal store
	const { buyer, customerID } = useCurrentDealStore(
		(state) => ({
			buyer: state.buyer,
			buyerAddress: state.buyerAddress,
			customerID: state.deal.buyerRecNum,
		}),
		shallow
	);
	const [profilePictureSrc, setProfilePictureSrc] = useState(null);

	const { ID, firstName, lastName } = buyer || "";
	// Converts the bytes of a file
	const setCustomerPhotoSrc = (byteArray) => {
		console.log(byteArray);
		if (byteArray.byteLength > 0) {
			const blob2File = new Blob([byteArray]);
			const blobUrl = URL.createObjectURL(blob2File);

			setProfilePictureSrc(blobUrl);
			console.log(blobUrl);
		} else {
			setProfilePictureSrc(IDPhoto);
		}
	};

	useEffect(() => {
		if (customerID !== null) {
			getCustomerPhoto(dealerID, locationID, customerID).then(
				(res) => {
					setCustomerPhotoSrc(res.data);
				},
				(err) => console.log(err)
			);
		} else {
			setCustomerPhotoSrc(IDPhoto);
		}
	}, [customerID, dealerID, locationID]);

	return (
		<div>
			{(ID == null && (
				<>
					<Row>
						<BuyerModal />
					</Row>
				</>
			)) || (
				<Row className="">
					<div className="mx-auto w-100">
						<h4 className="text-center mb-2 mt-0">
							{firstName + " " + lastName}
						</h4>

						<img
							className="customer-profile pt-1 pb-3"
							src={profilePictureSrc}
							alt="customer-profile"
							onError={(e) => {
								e.target.onerror = null;
								e.target.src = IDPhoto;
							}}
						/>

						<BuyerModal />
					</div>
				</Row>
			)}
		</div>
	);
};

export default BuyerHeader;

const BuyerModal = () => {
	// Local states
	const isMounted = useRef(false);
	const [addModal, setAddModal] = useState(false);
	const [editModal, setEditModal] = useState(false);
	const [newModal, setNewModal] = useState(false);
	const [openRequestModal, setOpenRequestModal] = useState(false);
	// Function to open the modal
	const toggle = () => {
		setOpenRequestModal(!openRequestModal);
	};

	// Deal store
	const {
		buyerRecNum,
		type,
		editBuyer,
		editBuyerRecNum,
		editBuyerAddress,
		editBuyerMailAddress,
	} = useCurrentDealStore(
		(state) => ({
			buyerRecNum: state.deal.buyerRecNum,
			type: state.deal.type,
			editBuyer: state.editBuyer,
			editBuyerRecNum: state.editBuyerRecNum,
			editBuyerAddress: state.editBuyerAddress,
			editBuyerMailAddress: state.editBuyerMailAddress,
		}),
		shallow
	);

	// Modal toggle functions
	const toggleAdd = () => {
		if (isMounted.current) {
			setAddModal(!addModal);
		}
	};

	const toggleEdit = () => {
		setEditModal(!editModal);
	};

	const toggleNew = () => {
		setNewModal(!newModal);
	};

	const toggleDelete = () => {
		editBuyerRecNum(null);
		editBuyer({});
	};

	// Update deal store with new customer's info
	const updateNewCustomer = (data) => {
		editBuyerRecNum(data.customer.ID);
		editBuyer(data.customer);
		editBuyerAddress(data.currentAddress);
		editBuyerMailAddress(data.mailingAddress);
		toggleNew();
	};

	useEffect(() => {
		isMounted.current = true;

		return () => {
			isMounted.current = false;
		};
	}, []);

	return (
		<>
			{(type === DealStatus.PENDING && buyerRecNum == null && (
				<div className="mx-auto">
					<OpenModalButton
						buttonClass="btn-sm"
						buttonColor="secondary"
						onClick={toggleNew}
						buttonText="Add"
						iconClass="nc-icon nc-simple-add"
					/>
					<OpenModalButton
						buttonClass="btn-sm"
						buttonColor="secondary"
						onClick={toggleAdd}
						buttonText="Select"
						icon={true}
						iconClass="nc-icon nc-tap-01"
					/>
				</div>
			)) || (
				<div className="mx-auto">
					<OpenModalButton
						iconClass="nc-icon nc-align-center"
						buttonClass="btn-sm btn-primary"
						onClick={toggleEdit}
						buttonText={type === DealStatus.PENDING ? "Edit" : "View"}
					/>
					{type === DealStatus.PENDING && (
						<OpenModalButton
							buttonClass="btn-link-danger text-danger btn-sm text-danger"
							onClick={toggleDelete}
							iconClass="nc-icon nc-simple-remove"
							buttonColor="link"
							buttonText="Remove"
						/>
					)}
					<Button
						color="primary"
						className="btn-sm"
						onClick={() => {
							// toggle the modal for getting parameters for the pdf
							toggle();
						}}
					>
						<i className="nc-icon nc-zoom-split" /> Credit
					</Button>
					<ChildrenModal
						modalTitle={
							<img src={Credit} alt="700credit" className="rounded mb-3 pt-3" />
						}
						modal={openRequestModal}
						toggle={toggle}
					>
						<Customer700CreditTab id={buyerRecNum} />
					</ChildrenModal>
				</div>
			)}
			<ChildrenModal
				modal={newModal}
				toggle={toggleNew}
				children={
					<ContactAddPage
						updateDeal={updateNewCustomer}
						isDeal={true}
						isBuyer={true}
					/>
				}
			/>
			<ChildrenModal
				modal={addModal}
				toggle={toggleAdd}
				modalTitle="Select Contact"
				children={
					<CustomerListPage
						isDeal={true}
						toggle={toggleAdd}
						editCustomer={editBuyer}
						editAddress={editBuyerAddress}
						editMailAddress={editBuyerMailAddress}
						editRecNum={editBuyerRecNum}
					/>
				}
			/>
			<ChildrenModal
				modal={editModal}
				toggle={toggleEdit}
				modalTitle="Edit Current Contact"
				children={
					<CustomerDealDetails
						ID={buyerRecNum}
						editCustomer={editBuyer}
						editAddress={editBuyerAddress}
						editMailAddress={editBuyerMailAddress}
						editRecNum={editBuyerRecNum}
						toggle={toggleEdit}
						cloneCustomer={false}
						isBuyer={true}
					/>
				}
			/>
		</>
	);
};
