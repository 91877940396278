import React, { useContext, useEffect, useState } from "react";
import DealVINDecoderForm from "./DealVINDecoderForm";
import NavBar from "component/dc/tab/NavTabsHolder";
import { TradeContext } from "./TradeContext";
import TradeForm from "./TradeForm";
import { Col, Row } from "reactstrap";
import OpenModalButton from "../OpenModalButton";
import ChildrenModal from "component/dc/modal/ChildrenModal";
import TradeInventoryList from "./TradeInventoryList";
import tabOnEnter from "helperFunction/tabOnEnter";
import InventoryKbbTab from "../../../../view/inventory/inventoryDetailTab/value/kbb/InventoryKbbTab";
import Loading from "../../load/Loading";
import useCurrentDealStore from "../../../../store/DealStore";
import shallow from "zustand/shallow";
import { KbbContext } from "../../../../view/inventory/inventoryDetailTab/value/kbb/KbbContext";
import { useFromUser } from "../../../../store/LocalStorageHelper";
import { getAllTradeForms } from "../../../../api/FormsAPI";
import FormsReportsList from "../../formsReports/FormsReportsList";

const TradeModal = () => {
	const [add, setAdd] = useState(false);
	const toggleAdd = () => {
		setAdd(!add);
		setCurrentIndex(trades.length);
	};
	// Deal Store
	const { trades } = useCurrentDealStore(
		(state) => ({
			trades: state.trades,
		}),
		shallow
	);

	const {
		vin,
		stockNo,
		inventoryID,
		year,
		make,
		model,
		odometerIn,
		editOdometerIn,
		isLoading,
		currentIndex,
		setVehicleFields,
		editVin,
		editYear,
		editMake,
		editModel,
		setCurrentIndex,
		reset: resetTrade,
	} = useContext(TradeContext);

	const { setDcKbbObj, reset: resetKbb } = useContext(KbbContext);

	const vehicleKbbObj = {
		stockNo,
		vin,
		year,
		make,
		model,
		odometerIn,
		editOdometerIn,
		editVin,
		editYear,
		editMake,
		editModel,
	};

	const tabs = ["Trade Details", "Trade KBB", "Forms"];

	const tabComponents = [
		<div>
			<Row>
				<Col Col className="col text-left">
					<DealVINDecoderForm />
				</Col>
				<Col className="col text-right">
					<OpenModalButton
						buttonClass="btn-md"
						buttonColor="secondary"
						buttonText="Select From Inventory"
						onClick={toggleAdd}
						icon={true}
					/>
				</Col>
			</Row>
			<TradeForm />
		</div>,
		<InventoryKbbTab inventoryID={inventoryID} vehicleKbbObj={vehicleKbbObj} />,
		<TradeFormsTab tradeInventoryID={inventoryID} />,
	];

	useEffect(() => {
		setVehicleFields(trades?.[currentIndex]);
		console.log(trades?.[currentIndex]);
		if (trades.length !== currentIndex) {
			setDcKbbObj(trades?.[currentIndex]?.kbb);
		}
		return () => {
			resetTrade();
			resetKbb();
		};
		// eslint-disable-next-line
	}, [currentIndex]);

	if (isLoading) {
		return <Loading />;
	} else {
		return (
			<>
				<div onKeyDown={tabOnEnter}>
					<NavBar
						navClass="nav-pills-primary justify-content-center nav nav-pills"
						tabs={tabs}
						tabComponents={tabComponents}
					/>
				</div>
				<ChildrenModal
					modal={add}
					toggle={toggleAdd}
					children={<TradeInventoryList toggle={toggleAdd} />}
				/>
			</>
		);
	}
};

export default TradeModal;

const TradeFormsTab = ({ tradeInventoryID }) => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const params = { dealerID, locationID };
	return (
		<FormsReportsList
			listTitle="Trade Forms"
			apiFunction={getAllTradeForms}
			apiParams={params}
			searchable={true}
			isInv={true}
			tradeInventoryID={tradeInventoryID}
		/>
	);
};
