/*!
 
=========================================================
* DealerClick 2.0 - v1.0
=========================================================
 
* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)
 
* Coded by DealerClick Software **RoarinLion))
 
=========================================================
 
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 
*/
import React from "react";
import {
	Col,
	Table,
	Button,
	Card,
	CardHeader,
	CardTitle,
	CardBody,
} from "reactstrap";
import useCurrentCustomerStore from "store/CustomerStore";
import { deleteAddress } from "api/CustomerAPI";
import { showApiError } from "../../../../helperFunction/ApiErrorHandler";
import {
	deleteSuccessAlert,
	deleteFailAlert,
	confirmDeleteAlert,
} from "component/dc/modal/SweetAlerts";

import shallow from "zustand/shallow";

/**
 * @description This table generates a list of addresses
 * @param setCurrentIndex Used to index the store address history array
 */
const AddressHistoryTable = ({ disabled, setCurrentIndex, toggle }) => {
	// Headers
	const headers = [
		"#",
		"Street",
		"City",
		"State",
		"Zip",
		"Start Date",
		"End Date",
		"Actions",
	];

	// Customer store
	const { addressHist, editAddressHist } = useCurrentCustomerStore(
		(state) => ({
			addressHist: state.addressHist,
			editAddressHist: state.editAddressHist,
		}),
		shallow
	);

	// Modal to ask confirmation to delete an address
	const toggleDelete = (currentID) => {
		confirmDeleteAlert(removeAddress, currentID);
	};

	// Calls the delete address API
	const removeAddress = (ID) => {
		if (ID != null) {
			deleteAddress(ID).then(
				() => {
					let obj = addressHist.filter((item) => item.ID !== ID);
					editAddressHist(obj);
					setCurrentIndex(obj.length);
					deleteSuccessAlert();
				},
				(err) => {
					console.log(err);

					if (!err.isGeneralError) {
						showApiError(err, deleteFailAlert);
					}
				}
			);
		}
	};

	// Address table headers
	const head = headers.map((key) => <th key={key}>{key}</th>);

	// Address row items
	const rows = addressHist.map((key, index) => (
		<tr key={index}>
			<th scope="row">{index + 1}</th>
			<td>{key.street}</td>
			<td>{key.city}</td>
			<td>{key.state}</td>
			<td>{key.zip}</td>
			<td data-type="date" data-format-string="MM/DD/YYYY">
				{key.fromDate}
			</td>
			<td data-type="date" data-format-string="MM/DD/YYYY">
				{key.toDate}
			</td>
			<td className="text-right">
				<Button
					disabled={disabled}
					className="btn-sm"
					color="success"
					onClick={() => {
						console.log(index);
						setCurrentIndex(index);
						toggle();
					}}
				>
					Edit
				</Button>
				<Button
					disabled={disabled}
					className="btn-sm"
					color="danger"
					onClick={() => {
						toggleDelete(key.ID);
						console.log(index);
					}}
				>
					X
				</Button>
			</td>
		</tr>
	));

	return (
		<Card>
			<CardHeader>
				<CardTitle>
					<h3>Residential History</h3>
				</CardTitle>
			</CardHeader>
			<CardBody>
				<Col>
					<Table striped hover>
						<thead>
							<tr>{head}</tr>
						</thead>
						<tbody>{rows}</tbody>
					</Table>
				</Col>{" "}
			</CardBody>
		</Card>
	);
};
export default AddressHistoryTable;
