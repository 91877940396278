import React from "react";

import useBuildCustomer from "../../../helperFunction/BuildCustomerObj";
import useCurrentCustomerStore from "../../../store/CustomerStore";

import { saveCustomer } from "../../../api/CustomerAPI";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";
import { useFormContext } from "react-hook-form";
import { saveSuccessAlert, saveFailAlert } from "../../dc/modal/SweetAlerts";
import { useFromUser } from "../../../store/LocalStorageHelper";
import {
	buildCurrentAddress,
	buildMailingAddress,
	hasValidFields,
} from "./validAddressUtil";

import shallow from "zustand/shallow";

// Returns a button. When clicked, updates existing customer in the database
const UpdateCustomerButton = ({ isHeader = false }) => {
	// Local storage
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	// Customer information stored and retrieved from the store
	const customer = useBuildCustomer();

	// Customer store
	const {
		storeCurrentAddress,
		storeMailingAddress,
		editMailAddress,
		editCurrentAddress,
	} = useCurrentCustomerStore(
		(state) => ({
			storeCurrentAddress: state.currentAddress,
			storeMailingAddress: state.mailingAddress,
			editMailAddress: state.editMailingAddress,
			editCurrentAddress: state.editCurrentAddress,
		}),
		shallow
	);

	// Calls the save customer endpoint
	const save = (data) => {
		const message = "All required or all empty";

		const currentAddress = buildCurrentAddress(
			data,
			dealerID,
			locationID,
			storeCurrentAddress.ID
		);
		const mailingAddress = buildMailingAddress(
			data,
			dealerID,
			locationID,
			storeMailingAddress.ID
		);
		const validCurrentAddress = hasValidFields(currentAddress);
		const validMailingAddress = hasValidFields(mailingAddress);

		if (!validCurrentAddress || !validMailingAddress) {
			if (!validCurrentAddress) {
				methods.setError("currentCity", { message });
				methods.setError("currentState", { message });
				methods.setError("currentZip", { message });
				//methods.setError("currentCounty", { message });
				methods.setError("currentOwnership", { message });
			}

			if (!validMailingAddress) {
				methods.setError("mailCity", { message });
				methods.setError("mailState", { message });
				methods.setError("mailZip", { message });
				//methods.setError("mailCounty", { message });
			}

			return;
		}

		const updatedInfo = {
			customer,
			currentAddress,
			mailingAddress,
		};

		saveCustomer(updatedInfo).then(
			(res) => {
				const savedCurrentAddress = res.data.content.currentAddress;
				const savedMailingAddress = res.data.content.mailingAddress;
				editCurrentAddress(savedCurrentAddress);
				editMailAddress(savedMailingAddress);
				saveSuccessAlert();
			},
			(err) => {
				console.log(err);
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	const methods = useFormContext();

	return (
		<>
			{isHeader ? (
				<div
					className="btn btn-lg btn-success float-button"
					onClick={methods.handleSubmit(save)}
				>
					<i className="nc-icon nc-check-2" /> Save
				</div>
			) : (
				<div
					className="btn btn-lg btn-success float-button"
					onClick={methods.handleSubmit(save)}
				>
					<i className="nc-icon nc-check-2" /> Save Changes
				</div>
			)}
		</>
	);
};

export default UpdateCustomerButton;
