import React, { useEffect, useState } from "react";
import { Button, Card, CardBody } from "reactstrap";

import PreviewMessage from "../../../../view/communication/template/PreviewMessage";

import { failAlert, showSwalMessage } from "../../modal/SweetAlerts";
import {
	getTwilioMessageTemplates,
	sendTwilioBlastMessage,
} from "../../../../api/TwilioApi";
import {
	PhoneInputElement,
	SelectorElement,
} from "../../../../view/formValidation/InputElement";

import { object, setLocale, string } from "yup";
import { yupResolver } from "@hookform/resolvers";
import { FormProvider, useForm } from "react-hook-form";
import { showApiError } from "../../../../helperFunction/ApiErrorHandler";
import { MessageTemplateVariablesAndInput } from "../../../../view/communication/template/MessageTemplateVariables";

const TwilioSendBlastMessage = ({ customers, setCustomers, toggle }) => {
	// Local storage
	const user = JSON.parse(sessionStorage.getItem("user"));
	const dealerNumber = user?.messagingNumber;
	const locationID = user?.locationID;
	const IN_DEVELOPMENT = process.env.NODE_ENV === "development";

	// Local state
	const [messageBody, setMessageBody] = useState("");
	const [showPreview, setShowPreview] = useState(false);
	const [templates, setTemplates] = useState([]);
	const [templateOptions, setTemplateOptions] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState(null);

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Cannot be more that ${max} characters long",
		},
	});

	// Define rules for each input field
	const schema = object().shape({
		twilioFromNumber: string().required("Contact support if number is missing"),
		//messageBody: string().required().max(1600),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	const generateCustomerMsgList = () => {
		return customers.map((customer, index) => ({
			ID: customer.ID || customer.customerID || index,
			fromNumber: "+1" + dealerNumber,
			toNumber: "+1" + customer.cellPhone || customer.phone,
			messageBody,
			customerID: customer.ID || customer.customerID || index,
		}));
	};

	// Builds the list of messages to send
	const sendMessage = () => {
		if (customers.length < 1) {
			failAlert("Please select at least one customer");
			return;
		}

		const messages = generateCustomerMsgList();

		sendTwilioBlastMessage(messages).then(
			(res) => getBlastErrors(res.data),
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}
			}
		);
	};

	// Checks that all messages were sent successfully and removes
	// the successful messages from the customers array and adds an
	// error message to each request that fail and their specific reason
	const getBlastErrors = (data) => {
		const errors = data.content;
		const unsuccessfulMessages = customers.filter(
			(customer) => errors[customer.ID] != null
		);
		const customersWithErrors = unsuccessfulMessages.map((customer) => ({
			...customer,
			error: errors[customer.ID],
		}));

		if (customersWithErrors.length > 0) {
			showSwalMessage("Warning", data.message, "warning");
		} else {
			toggle();
			showSwalMessage("Success", data.message, "success");
		}

		setCustomers(customersWithErrors);
	};

	useEffect(() => {
		getTwilioMessageTemplates(locationID).then(
			(res) => {
				const data = res.data.content;
				const options = [];
				let templateList = {};

				for (let i = 0; i < data.length; i++) {
					const option = {
						label: data[i].name,
						value: data[i].ID,
					};

					options.push(option);
					templateList[data[i].ID] = { ...data[i] };
				}

				setTemplateOptions(options);
				setTemplates(templateList);
			},
			(err) => console.log(err)
		); // eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (selectedTemplate != null) {
			setMessageBody(templates?.[selectedTemplate]?.body || "");
			setShowPreview(false);
		}
		// eslint-disable-next-line
	}, [selectedTemplate]);

	return (
		<FormProvider {...methods}>
			<Card>
				<CardBody>
					{!showPreview && (
						<>
							<div className="row row-12">
								<PhoneInputElement
									{...methods}
									readOnly
									colSize="col-lg-6"
									value={dealerNumber}
									name="twilioFromNumber"
									label="Your Number (From Number)"
								/>
								{templateOptions.length > 0 && (
									<SelectorElement
										{...methods}
										colSize="col-lg-6"
										value={selectedTemplate}
										name="twilioMessageTemplateOptions"
										label="Message Template"
										onChange={(e) => setSelectedTemplate(e.target.value)}
										options={templateOptions}
									/>
								)}
							</div>
							<MessageTemplateVariablesAndInput
								body={messageBody}
								updateBody={setMessageBody}
							/>
						</>
					)}
					{showPreview && <PreviewMessage message={messageBody} />}
					<div className="col-lg-12 d-flex justify-content-center align-items-center">
						{messageBody.length > 0 && (
							<div className="d-flex justify-content-center align-items-center my-2">
								<Button onClick={() => setShowPreview(!showPreview)}>
									{!showPreview ? "Preview" : "Close"}
								</Button>
							</div>
						)}
						{!showPreview && (
							<>
								<div
									className="btn btn-md btn-info ml-3"
									onClick={methods.handleSubmit(sendMessage)}
								>
									<i className="fa fa-paper-plane" /> Send
								</div>
								{IN_DEVELOPMENT && (
									<Button
										onClick={() => console.log(generateCustomerMsgList())}
									>
										JSON
									</Button>
								)}
							</>
						)}
					</div>
				</CardBody>
			</Card>
		</FormProvider>
	);
};

export default TwilioSendBlastMessage;
