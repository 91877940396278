import React, { useState, useEffect } from "react";
import { Button, Form, Label, FormGroup } from "reactstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider } from "react-hook-form";
import { useFromUser } from "../../../../../../store/LocalStorageHelper";
import useCurrentDealStore from "../../../../../../store/DealStore";
import InputElement, {
	CurrencyInputElement,
	IntegerInputElement,
	SelectorElement,
} from "../../../../../../view/formValidation/InputElement";
import InputWrapper from "../../../../../../view/formValidation/InputWrapper";
import {
	DealStatus,
	isTaxableOption,
	vendorTypesOBJ,
} from "../../../../../../Constants";
import { getVendorListByType } from "api/VendorsAPI";
import Select from "react-select";
import shallow from "zustand/shallow";

export const AftermarketForm = (props) => {
	const { currentIndex, setCurrentIndex, toggle } = props;
	const {
		aftermarket,
		editAftermarket,
		dealRecNum,
		type,
		calculate,
	} = useCurrentDealStore(
		(state) => ({
			aftermarket: state.aftermarket,
			editAftermarket: state.editAftermarket,
			dealRecNum: state.deal.ID,
			type: state.deal.type,
			calculate: state.calculate,
		}),
		shallow
	);
	// state for vendor selector
	const [vendorList, setvendorList] = useState([]);
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const [description, setdescription] = useState("");
	const [price, setprice] = useState(0);
	const [qty, setqty] = useState(1);
	const [vendor, setvendor] = useState("");
	const [recNum, setrecNum] = useState(null);
	const [isTaxable, setisTaxable] = useState(false);
	const [cost, setcost] = useState(0);
	const [totalcost, setTotalcost] = useState(cost * qty);
	const [totalprice, setTotalprice] = useState(price * qty);

	const getVendors = () => {
		getVendorListByType(locationID, vendorTypesOBJ.AFTMKT_SUPPLIER.value).then(
			(res) => {
				setvendorList(res.data.content);
				console.log(res.data.content);
			},
			(error) => {
				console.log(error);
			}
		);
	};

	const clearFields = () => {
		setdescription("");
		setprice(0);
		setqty(0);
		setvendor("");
		setisTaxable(false);
		setcost(0);
		setTotalcost(0);
		setTotalprice(0);
		setCurrentIndex(aftermarket.length);
	};

	const addAftermarket = () => {
		const getID = () => {
			if (aftermarket[currentIndex] === undefined) {
				return null;
			} else if (aftermarket[currentIndex].ID === undefined) {
				return null;
			} else {
				return aftermarket[currentIndex].ID;
			}
		};
		const ID = getID();
		const newAftermarket = {
			description,
			price: parseFloat(price),
			qty: parseInt(qty),
			vendor,
			isTaxable: isTaxable || false,
			cost: parseFloat(cost),
			currentIndex,
			dealerID,
			locationID,
			ID,
			dealRecNum,
		};
		console.log(newAftermarket);
		console.log("Current index is: " + currentIndex);

		const aftermarketList = () => {
			let obj = aftermarket;
			obj[currentIndex] = newAftermarket;
			return obj;
		};
		const newAftermarketObj = aftermarketList();
		editAftermarket(newAftermarketObj);
		calculate();
		clearFields();
		toggle();
	};

	const taxableHandler = (value) => {
		if (value === "true") {
			setisTaxable(true);
		} else if (value === "false") {
			setisTaxable(false);
		} else {
			setisTaxable(false);
		}
	};

	useEffect(() => {
		getVendors();
		setdescription(aftermarket[currentIndex]?.description || "");
		setprice(aftermarket[currentIndex]?.price || "");
		setqty(aftermarket[currentIndex]?.qty || "");
		setvendor(aftermarket[currentIndex]?.vendor || "");
		setisTaxable(aftermarket[currentIndex]?.isTaxable);
		setcost(aftermarket[currentIndex]?.cost || "");
		setTotalcost(aftermarket[currentIndex]?.totalcost || "");
		setTotalprice(aftermarket[currentIndex]?.totalprice || "");

		return clearFields;
		// eslint-disable-next-line
	}, [currentIndex, aftermarket]);

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
			length: "Required format is YYYY/MM/DD",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({
		aftermarketDescription: yup.string().required().max(50),
		aftermarketPrice: yup.string().required().max(50),
		aftermarketVendor: yup.string().max(50),
		aftermarketCost: yup.string().required().max(50),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		setTotalcost(cost * qty);
		setTotalprice(price * qty);
	}, [cost, qty, price]);

	return (
		<>
			<FormProvider className="" {...methods}>
				<Form>
					<InputWrapper
						formTitle="Aftermarket Item"
						inputComponents={[
							<InputElement
								readOnly={type !== DealStatus.PENDING}
								{...methods}
								value={description}
								onChange={(e) => {
									setdescription(e.target.value);
								}}
								name="aftermarketDescription"
								label="Description"
								type="text"
								colSize="col-sm-12"
							/>,
							<FormGroup className="col-md-9" name="aftermarketVendor">
								<Label>Vendor</Label>
								<Select
									isDisabled={type !== DealStatus.PENDING}
									value={{ value: recNum, label: vendor }}
									options={vendorList.map((value) => ({
										value: value.ID,
										label: value.name,
									}))}
									onChange={(e) => {
										setvendor(e.label);
										setrecNum(e.value);
									}}
								/>
							</FormGroup>,
							<IntegerInputElement
								readOnly={type !== DealStatus.PENDING}
								value={qty}
								onChange={setqty}
								name="aftermarketQty"
								label="Quantity"
								type="number"
							/>,
							<CurrencyInputElement
								readOnly={type !== DealStatus.PENDING}
								value={cost}
								onChange={setcost}
								name="aftermarketCost"
								label="Unit Cost"
								type="number"
							/>,
							<CurrencyInputElement
								readOnly={type !== DealStatus.PENDING}
								value={price}
								onChange={setprice}
								name="aftermarketPrice"
								label="Unit Price"
								type="number"
							/>,
							<CurrencyInputElement
								readOnly={true}
								value={totalcost}
								name="Cost"
								label="Total Cost"
								type="number"
								onChange={setTotalcost}
							/>,
							<CurrencyInputElement
								readOnly={true}
								value={totalprice}
								name="Price"
								label="Total Price"
								type="number"
								onChange={setTotalprice}
							/>,

							<SelectorElement
								{...methods}
								readOnly={type !== DealStatus.PENDING}
								name="aftermarketTaxable"
								label="Taxable"
								value={isTaxable}
								onChange={(e) => taxableHandler(e.target.value)}
								options={isTaxableOption}
							/>,
						]}
						buttons={
							<div className="text-center">
								<Button
									readOnly={type !== DealStatus.PENDING}
									color="info"
									onClick={methods.handleSubmit(addAftermarket)}
								>
									<i></i> Save
								</Button>
							</div>
						}
					/>
				</Form>
			</FormProvider>
		</>
	);
};

export default AftermarketForm;
