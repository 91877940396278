import React, { memo, useMemo, useState, useContext } from "react";
import { Container, Row, Col, FormGroup, Input, Button } from "reactstrap";
import {
	useTable,
	useFilters,
	useSortBy,
	usePagination,
	useRowSelect,
	useFlexLayout,
} from "react-table";

import classnames from "classnames";
import matchSorter from "match-sorter";
import Select from "react-select";
import { savePack } from "../../../api/FormsAPI";
import { PackEditContext } from "./PackContexts";
import { saveFailAlert } from "../modal/SweetAlerts";

const CheckBoxReactTable = memo(
	({ columns, data, toggleEdit }) => {
		const {
			checkedFormsObj,
			pack,
			setPack,
			setForms,
			packsList,
			setPacksList,
		} = useContext(PackEditContext);
		const [numberOfRows, setNumberOfRows] = useState({
			value: 100,
			label: "100 rows",
		});

		const [pageSelect, setPageSelect] = useState({
			value: 0,
			label: "Page 1",
		});

		const fuzzyTextFilterFn = (rows, id, filterValue) => {
			return matchSorter(rows, filterValue, {
				keys: [(row) => row.values[id]],
			});
		};

		// Let the table remove the filter if the string is empty
		fuzzyTextFilterFn.autoRemove = (val) => !val;

		// Define a custom filter filter function!
		const filterGreaterThan = (rows, id, filterValue) => {
			return rows.filter((row) => {
				const rowValue = row.values[id];
				return rowValue >= filterValue;
			});
		};

		// This is an autoRemove method on the filter function that
		// when given the new filter value and returns true, the filter
		// will be automatically removed. Normally this is just an undefined
		// check, but here, we want to remove the filter if it's not a number
		filterGreaterThan.autoRemove = (val) => typeof val !== "number";

		//  Define a default UI for filtering
		const DefaultColumnFilter = ({
			column: { filterValue, preFilteredRows, setFilter },
		}) => {
			const count = preFilteredRows.length;

			return (
				<FormGroup>
					<Input
						value={filterValue || ""}
						placeholder={`Search ${count} records...`}
						type="text"
						onChange={(e) => {
							setFilter(e.target.value || undefined);
						}}
					/>
				</FormGroup>
			);
		};

		const filterTypes = useMemo(
			() => ({
				// Add a new fuzzyTextFilterFn filter type.
				fuzzyText: fuzzyTextFilterFn,
				// Or, override the default text filter to use
				// "startWith"
				text: (rows, id, filterValue) => {
					return rows.filter((row) => {
						const rowValue = row.values[id];

						return rowValue !== undefined
							? String(rowValue)
									.toLowerCase()
									.startsWith(String(filterValue).toLowerCase())
							: true;
					});
				},
			}),
			[]
		);

		const defaultColumn = useMemo(
			() => ({
				// Let's set up our default Filter UI
				Filter: DefaultColumnFilter,
			}),
			[]
		);

		const {
			getTableProps,
			getTableBodyProps,
			headerGroups,
			page,
			prepareRow,
			nextPage,
			pageOptions,
			previousPage,
			canPreviousPage,
			canNextPage,
			setPageSize,
			gotoPage,
			selectedFlatRows,
			setAllFilters,
			state: { filters },
			//state: { selectedRowIds },
		} = useTable(
			{
				columns,
				data,
				defaultColumn, // Be sure to pass the defaultColumn option
				filterTypes,
				initialState: {
					pageSize: 100,
					pageIndex: 0,
					selectedRowIds: checkedFormsObj,
				},
				getRowId: (row, relativeIndex) => {
					return row.id || relativeIndex;
				},
			},
			useFilters,
			useSortBy,
			usePagination,
			useRowSelect,
			useFlexLayout,
			(hooks) => {
				hooks.allColumns.push((columns) => [
					// Let's make a column for selection
					{
						id: "selection",
						// The header can use the table's getToggleAllRowsSelectedProps method
						// to render a checkbox
						Header: ({ getToggleAllRowsSelectedProps }) => (
							<div>
								<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
							</div>
						),
						// The cell can use the individual row's getToggleRowSelectedProps method
						// to the render a checkbox
						Cell: ({ row }) => (
							<div>
								<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
							</div>
						),
						width: 20,
						minWidth: 10,
						maxWidth: 20,
					},
					...columns,
				]);
			}
		);

		// resets the filter to and empty array
		const reset = () => {
			setAllFilters([]);
		};

		// gets the array of checked forms
		const getCheckedItems = () => {
			return selectedFlatRows.map((d) => d.original);
		};

		const saveData = (packObj) => {
			if (packObj.forms.length <= 20 && packObj.forms.length >= 2) {
				savePack(packObj).then(
					(res) => {
						const packObj = res.data.content;
						const savedPack = packObj.pack;
						setPack(savedPack);
						setForms(packObj.forms);
						// if not present then append else overwrite the entry
						if (
							packsList.find((o) => o.pack.id === savedPack.id) === undefined
						) {
							setPacksList((prevState) => {
								return [...prevState, packObj];
							});
						} else {
							setPacksList((prevState) => {
								console.log(prevState);
								const newstate = prevState.map((obj) =>
									obj.pack.id === savedPack.id ? packObj : obj
								);
								console.log(newstate);
								return newstate;
							});
						}
						toggleEdit();
					},
					(err) => {
						console.log(Object.keys(err));
						console.log(err);
						saveFailAlert(err.response.data.message);
					}
				);
			} else {
				saveFailAlert("Forms must be between 2 and 20 per pack.");
			}
		};

		// We don't want to render all of the rows for this example, so cap
		// it for this use case
		// const firstPageRows = rows.slice(0, 10);
		let pageSelectData = Array.apply(null, Array(pageOptions.length));

		const numberOfRowsData = [5, 10, 20, 25, 50, 100];

		//console.log(columns);

		return (
			<>
				{(filters.length === 0 && (
					<Button
						size="sm"
						color="primary"
						onClick={() => {
							const forms = getCheckedItems();
							console.log(pack);
							const packObj = { pack, forms };
							console.log(packObj);
							saveData(packObj);
						}}
					>
						Save
					</Button>
				)) || (
					<Button size="sm" color="primary" onClick={reset}>
						Clear Filter
					</Button>
				)}
				<div className="ReactTable -striped -highlight forms">
					<div className="pagination-top">
						<div className="-pagination">
							<div className="-previous">
								<button
									type="button"
									onClick={() => previousPage()}
									readOnly={!canPreviousPage}
									className="-btn"
								>
									<i className="fa fa-angle-double-left text-center" />
								</button>
							</div>
							<div className="-center">
								<Container>
									<Row className="justify-content-center">
										<Col>
											<Select
												className="react-select primary d-block"
												classNamePrefix="react-select"
												name="pageSelect"
												value={pageSelect}
												onChange={(value) => {
													gotoPage(value.value);
													setPageSelect(value);
												}}
												options={pageSelectData.map((_prop, key) => {
													return {
														value: key,
														label: "Page " + (key + 1),
													};
												})}
												placeholder="Choose Page"
											/>
										</Col>
										<Col>
											<Select
												className="react-select primary"
												classNamePrefix="react-select"
												name="numberOfRows"
												value={numberOfRows}
												onChange={(value) => {
													setPageSize(value.value);
													setNumberOfRows(value);
												}}
												options={numberOfRowsData.map((prop) => {
													return {
														value: prop,
														label: prop + " rows",
													};
												})}
												placeholder="Choose Rows"
											/>
										</Col>
									</Row>
								</Container>
							</div>

							<div className="-next">
								<button
									type="button"
									onClick={() => nextPage()}
									readOnly={!canNextPage}
									className="-btn"
								>
									<i className="fa fa-angle-double-right text-center" />
								</button>
							</div>
						</div>
					</div>
					<table {...getTableProps()} className="rt-table">
						<thead className={"rt-thead -header"}>
							{headerGroups.map((headerGroup) => (
								<tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
									{headerGroup.headers.map((column) => (
										<th
											key={column.id}
											className={classnames("rt-th-check rt-resizable-header", {
												"-cursor-pointer": column.canFilter,
												"-sort-asc": column.isSorted && !column.isSortedDesc,
												"-sort-desc": column.isSorted && column.isSortedDesc,
											})}
										>
											<div
												className="rt-resizable-header-content"
												{...column.getHeaderProps(
													column.getSortByToggleProps()
												)}
											>
												{column.render("Header")}
											</div>
											{column.canFilter ? column.render("Filter") : null}
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()} className="rt-tbody">
							{page.map((row, i) => {
								prepareRow(row);
								return (
									<tr
										{...row.getRowProps()}
										className={classnames(
											"rt-tr",
											{ " -odd": i % 2 === 0 },
											{ " -even": i % 2 === 1 }
										)}
									>
										{row.cells.map((cell) => {
											return (
												<td
													{...cell.getCellProps([
														{
															style: cell.column.style,
															className: `${
																cell.column.className != null
																	? cell.column.className + " "
																	: ""
															}rt-td-check singleCell`,
														},
													])}
												>
													{cell.render("Cell")}
												</td>
											);
										})}
									</tr>
								);
							})}
						</tbody>
					</table>
					<div className="pagination-bottom" />
				</div>
				{/** snippet for viewing selected items 
				<>
					<p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
					<pre>
						<code>
							{JSON.stringify(
								{
									selectedRowIds: selectedRowIds,
									"selectedFlatRows[].original": selectedFlatRows.map(
										(d) => d.original
									),
								},
								null,
								2
							)}
						</code>
					</pre>
				</>
                */}
			</>
		);
	},
	(prevProps, nextProps) =>
		JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data)
);

export default CheckBoxReactTable;

const IndeterminateCheckbox = React.forwardRef(
	({ indeterminate, ...rest }, ref) => {
		const defaultRef = React.useRef();
		const resolvedRef = ref || defaultRef;

		React.useEffect(() => {
			resolvedRef.current.indeterminate = indeterminate;
		}, [resolvedRef, indeterminate]);

		return (
			<>
				<input type="checkbox" ref={resolvedRef} {...rest} />
			</>
		);
	}
);
