import React, { useState, useCallback, useEffect } from "react";

import getCroppedImg from "./ImageCropperUtil";
import useGetKeyPress from "../../custom-hooks/useGetKeyPress";
import Cropper from "react-easy-crop";

import "assets/css/image-cropper.css";

/**
 * Image cropper to crop an image passed in to the component. To enable cropping for multiple images,
 * these props must be passed in: selectedImageIndex, totalImages, and setSelectedImageIndex. The parent
 * component must implement useEffect and listen to {@param cropImage} and {@param selectedImageIndex}
 * changes to implement the appropriate logic
 *
 * @required
 * @property {src} cropImage				Anything that can be put inside the src tag of an <image/>
 * @property {React.setState} setCropImage 	State setter for setting the cropped image. Setter is passed
 * 											the cropped image as a Blob object
 * @property {React.setState} setHideCrop 	State setter for hiding the cropper. Should be a boolean state
 * @property {function} resetCrop 			Callback function for when a cropped image is selected to be reset
 *
 * @requiredForMultipleCrops These props are needed in addition to the ones on the top
 * @property {React.state: number} selectedImageIndex	State specifying the index of the {@param cropImage}
 * @property {React.state: number} totalImages			Total number of images to crop
 * @property {React.setState} setSelectedImageIndex		State setter for updating {@param selectedImageIndex}
 */
const ImageCropper = ({
	cropImage,
	setCropImage,
	setHideCrop,
	resetCrop,
	selectedImageIndex,
	totalImages,
	setSelectedImageIndex,
}) => {
	const canShowPrevious =
		selectedImageIndex != null &&
		setSelectedImageIndex != null &&
		selectedImageIndex >= 1;

	const canShowNext =
		selectedImageIndex != null &&
		setSelectedImageIndex != null &&
		selectedImageIndex < totalImages - 1;

	// Cropper initial states
	const INITIAL_ZOOM = 1;
	const INITIAL_ROTATION = 0;

	// Local state
	const keyCode = useGetKeyPress();
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(INITIAL_ZOOM);
	const [rotation, setRotation] = useState(INITIAL_ROTATION);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

	// Called when cropping is complete
	const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	}, []);

	// Gets the cropped image as a blob file
	const showCroppedImage = useCallback(async () => {
		try {
			const cropped = await getCroppedImg(
				cropImage,
				croppedAreaPixels,
				rotation
			);

			setCropImage(cropped);
		} catch (e) {
			console.error(e);
		}
		// eslint-disable-next-line
	}, [croppedAreaPixels]);

	// Resets zoom and rotation to initial when crop image changes
	useEffect(() => {
		setZoom(INITIAL_ZOOM);
		setRotation(INITIAL_ROTATION);
	}, [cropImage]);

	// Performs certain actions based on which key is pressed
	useEffect(() => {
		// Closes cropper
		if (cropImage != null) {
			if (keyCode.keyString === "Escape") {
				setHideCrop(true);
			}
			// Shows previous image in cropper
			else if (keyCode.keyString === "ArrowLeft" && canShowPrevious) {
				setSelectedImageIndex(--selectedImageIndex);
			}
			// Shows next image in cropper
			else if (keyCode.keyString === "ArrowRight" && canShowNext) {
				setSelectedImageIndex(++selectedImageIndex);
			}
			// Calls callback function to reset crop
			else if (
				keyCode.keyString === "Delete" ||
				keyCode.keyString === "Backspace"
			) {
				resetCrop();
			}
			// Calls function to process cropped image
			else if (
				keyCode.keyString === "Enter" ||
				keyCode.keyString === "NumpadEnter"
			) {
				showCroppedImage();
			}
		}
		// eslint-disable-next-line
	}, [keyCode]);

	return (
		<div className="d-flex justify-content-center align-content-center">
			{cropImage != null && (
				<>
					<div className="image-cropper-container">
						<Cropper
							image={cropImage}
							crop={crop}
							zoom={zoom}
							rotation={rotation}
							onRotationChange={setRotation}
							aspect={16 / 9}
							onCropChange={setCrop}
							onCropComplete={onCropComplete}
							onZoomChange={setZoom}
						/>
						{canShowPrevious && (
							<i
								className="fa fa-angle-left image-cropper-previous"
								onClick={() => setSelectedImageIndex(--selectedImageIndex)}
							/>
						)}
						{canShowNext && (
							<i
								className="fa fa-angle-right image-cropper-next"
								onClick={() => setSelectedImageIndex(++selectedImageIndex)}
							/>
						)}
						<i className="fa fa-undo image-cropper-undo" onClick={resetCrop} />
						<div
							className="image-cropper-close"
							onClick={() => setHideCrop(true)}
						/>
						<div className="image-cropper-controls">
							<input
								className="image-cropper-range"
								type="range"
								value={rotation}
								min="0"
								max="360"
								onChange={(e) => setRotation(e.target.value)}
							/>
							<i
								className="fa fa-minus image-cropper-zoom"
								onClick={() => zoom !== 1 && setZoom(zoom - 1)}
							/>
							<i
								className="fa fa-plus image-cropper-zoom"
								onClick={() => zoom !== 3 && setZoom(zoom + 1)}
							/>
							<button
								onClick={showCroppedImage}
								className="image-cropper-button"
							>
								Crop
							</button>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default ImageCropper;
