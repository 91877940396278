import React, { useEffect, useState } from "react";
import { CardGroup, Row } from "reactstrap";
import { PackRequestProvider } from "../formsReports/PackContexts";
import {
	getPartnerLinks,
	getPartnerSend,
} from "../../../api/dealerIntegrationsApi";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";
import { useFromUser } from "../../../store/LocalStorageHelper";
import { retrieveFailAlert } from "../modal/SweetAlerts";
import LenderPacket from "./LenderPacket";
import UAC from "./uac/UAC";
import { credit700Lenders } from "../../../Constants";
import CudlLogo from "assets/img/cudl-logo-1.png";
import RouteOneLogo from "assets/img/routeone-logo.png";
import AppOneLogo from "assets/img/appone-logo.png";
import DealerTrackLogo from "assets/img/dealertrack-logo-v2.svg";
import Credit700 from "./Credit700/Credit700";
import useCurrentDealerLinkStore from "../../../store/DealerLinkStore";
import shallow from "zustand/shallow";

const LenderIntegrations = () => {
	const locationID = useFromUser("locationID");
	const [uacc, setUacc] = useState(0);
	const [has700Credit, setHas700Credit] = useState(false);

	const { setDealerLinkFields } = useCurrentDealerLinkStore(
		(state) => state,
		shallow
	);

	useEffect(() => {
		getPartnerSend(locationID).then(
			(res) => {
				setUacc(res.data.content.useUACC);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		getPartnerLinks(locationID).then(
			(res) => {
				setHas700Credit(res.data.content.isCreditSignup);
				setDealerLinkFields(res.data.content.dealerPartnerLink);
			},
			(err) => console.log(err)
		);
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<Row>
				<CardGroup>
					<div className="col-lg-3 text-center">
						<PackRequestProvider>
							<LenderPacket />
						</PackRequestProvider>
					</div>
					{uacc === 1 && (
						<div className="col-lg-3 text-center">
							<UAC />
						</div>
					)}
					<div className="col-lg-3 text-center">
						<Credit700
							los={credit700Lenders.cudl}
							logo={CudlLogo}
							has700={has700Credit}
						/>
					</div>
					<div className="col-lg-3 text-center">
						<Credit700
							los={credit700Lenders.routeOne}
							logo={RouteOneLogo}
							has700={has700Credit}
						/>
					</div>
					<div className="col-lg-3 text-center">
						<Credit700
							los={credit700Lenders.appOne}
							logo={AppOneLogo}
							has700={has700Credit}
						/>
					</div>
					<div className="col-lg-3 text-center">
						<Credit700
							los={credit700Lenders.dealerTrack}
							logo={DealerTrackLogo}
							has700={has700Credit}
						/>
					</div>
				</CardGroup>
			</Row>
		</>
	);
};

export default LenderIntegrations;
