import React, { useState } from "react";
import { Card, CardBody, CardText, Col, Row, Button } from "reactstrap";
import useCurrentSaleStore from "store/SaleStore";
import AftermarketForm from "component/dc/deal/sale/modal/aftermarket/AftermarketForm";
import NumberFormat from "react-number-format";
import ChildrenModal from "component/dc/modal/ChildrenModal";
import AftermarketTable from "./AftermarketTable";
import useCurrentDealStore from "store/DealStore";
import { DealStatus } from "Constants";
import shallow from "zustand/shallow";

const AftermarketModal = ({ modal, toggle }) => {
	// Store values
	const { aftmkt, aftmktCost } = useCurrentSaleStore(
		(state) => ({
			aftmkt: state.aftmkt,
			aftmktCost: state.aftmktCost,
		}),
		shallow
	);
	const { aftermarket, type } = useCurrentDealStore(
		(state) => ({
			aftermarket: state.aftermarket,
			type: state.deal.type,
		}),
		shallow
	);

	const [currentIndex, setCurrentIndex] = useState(aftermarket.length);
	const [open, setOpen] = useState(false);

	const toggleOpen = () => setOpen(!open);

	return (
		<ChildrenModal
			modal={modal}
			toggle={toggle}
			size="lg"
			modalTitle="Aftermarket Items"
			children={
				<>
					<ChildrenModal
						modal={open}
						toggle={toggleOpen}
						size="lg"
						children={
							<AftermarketForm
								currentIndex={currentIndex}
								setCurrentIndex={setCurrentIndex}
								toggle={toggleOpen}
							/>
						}
					/>
					<AftermarketTable
						setCurrentIndex={setCurrentIndex}
						toggle={toggleOpen}
					/>
					<Card>
						<CardBody>
							<Row>
								<Col>
									<CardText tag="h5" className="text-center mt-0">
										Total Aftermarket
									</CardText>
								</Col>
								<Col>
									<CardText tag="h5" className="text-center mt-0">
										<NumberFormat
											value={aftmkt}
											thousandSeparator={true}
											decimalScale={2}
											fixedDecimalScale={true}
											prefix={"$"}
											isNumericString={true}
											displayType="text"
										/>
									</CardText>
								</Col>
								<Col>
									<CardText tag="h5" className="text-center mt-0">
										Total Cost
									</CardText>
								</Col>
								<Col>
									<CardText tag="h5" className="text-center mt-0">
										<NumberFormat
											value={aftmktCost}
											thousandSeparator={true}
											decimalScale={2}
											fixedDecimalScale={true}
											prefix={"$"}
											isNumericString={true}
											displayType="text"
										/>
									</CardText>
								</Col>
							</Row>
						</CardBody>
					</Card>
					<div className="col pb-3">
						<div className="col">
							<Button
								className="btn btn-primary btn-md"
								readOnly={type !== DealStatus.PENDING}
								onClick={toggleOpen}
							>
								<i className="nc-icon nc-simple-add"></i> Add Aftermarket
							</Button>
						</div>
					</div>
				</>
			}
		/>
	);
};

export default AftermarketModal;
