import React, { useEffect, useState } from "react";

import { submitFairfax } from "api/dealerIntegrationsApi";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";
import { failAlert } from "../modal/SweetAlerts";

import { useFromUser } from "store/LocalStorageHelper";
import useCurrentDealStore from "store/DealStore";

import { getFairfax } from "api/dealerIntegrationsApi";
import {
	Col,
	Row,
	Card,
	Button,
	CardBody,
	CardTitle,
	CardHeader,
} from "reactstrap";
import dayjs from "dayjs";
import shallow from "zustand/shallow";
import fairfaxlogo from "../../../assets/img/fairfaxsoftware-logo.png";

export const FairFaxTab = () => {
	const { ID, license } = useCurrentDealStore(
		(state) => ({
			ID: state.deal.ID,
			license: state?.vehicle?.license,
			//editLicense: state?.vehicle?.editLicense,
		}),
		shallow
	);
	const [ROS, setROS] = useState("");
	const [Tag, setTag] = useState("");
	const [ROSNumber, setROSNumber] = useState("");
	const [TLPNumber, setTLPNumber] = useState("");
	const [ExpirationDate, setExpirationDate] = useState("");

	const commitData = () => {
		if (ID > 0) {
			getFairfax(ID).then(
				(response) => {
					console.log(response);
					console.log(response.data.content[0]?.ros);
					//	setDealerKey(response.data.content[0].dealerKey);
					//	setDealerID(response.data.content[0].dealerID);
					setROS(response.data.content[0]?.ros);
					setTag(response.data.content[0]?.tag);
					setROSNumber(response.data.content[0]?.rosnumber);
					setTLPNumber(response.data.content[0]?.tlpnumber);
					setExpirationDate(response.data.content[0]?.expirationDate);
				},
				(error) => console.log(error)
			);
		}
	};

	useEffect(() => {
		commitData();
		// eslint-disable-next-line
	}, []);

	const locationID = useFromUser("locationID");

	const openInNewTab = (url) => {
		const newWindow = window.open(url, "_blank", "noopener,noreferrer");
		if (newWindow) newWindow.opener = null;
	};

	const submitData = () => {
		submitFairfax(locationID, ID).then(
			(response) => {
				console.log(response);
				if (response.data.status === "OK") {
					//if (response.data.content.ROSNumber !== "") {

					openInNewTab(response.data.content.ros);
					openInNewTab(response.data.content.tag);
					commitData();
				} else {
					showApiError(
						response.data.content,
						failAlert,
						response.data.content.desc
					);
				}
			},
			(err) => {
				console.log(err.response);
				showApiError(err, failAlert, err.response);
			}
		);
	};

	return (
		<>
			<div className="col-12">
				<Row>
					<div className="col-6 px-0">
						<a href={ROS}>
							<div className="btn btn-sm btn-primary">
								<i className="nc-icon nc-paper" /> ROS Form
							</div>
						</a>
					</div>
					<div className="col-6 px-0">
						<a href={Tag} className="pull-right">
							<div className="btn btn-sm btn-primary">
								<i className="nc-icon nc-paper" /> Tag Form
							</div>
						</a>
					</div>
				</Row>
				<Card>
					<CardHeader>
						<CardTitle tag="h3">
							<img
								alt="Lender Packet"
								className="lenderImg"
								src={fairfaxlogo}
							/>
						</CardTitle>
					</CardHeader>
					<CardBody>
						<Row className="mt-4">
							<Col md="3" className="text-center">
								<h6>License Plate </h6>
								{license}
							</Col>
							<Col md="3" className="text-center">
								<h6>ROS Number</h6>
								{ROSNumber}
							</Col>
							<Col md="3" className="text-center">
								<h6>TLP Number</h6>
								{TLPNumber}
							</Col>
							<Col md="3" className="text-center">
								<h6>Expiration Date</h6>
								{dayjs(ExpirationDate).format("MM/DD/YYYY")}
							</Col>
						</Row>
						<Col className="text-center mt-3">
							<hr />
							<Button
								className="mx-auto mt-2 mb-2"
								type="submit"
								//color="white"
								onClick={submitData}
							>
								<i className="nc-icon nc-cloud-upload-94" /> Submit Application
							</Button>
						</Col>
					</CardBody>
				</Card>
			</div>
		</>
	);
};
export default FairFaxTab;
