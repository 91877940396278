import { DealStatus } from "Constants";
import React, { useEffect } from "react";
import { Input } from "reactstrap";
import useCurrentDealStore from "store/DealStore";
import useCurrentSaleStore from "store/SaleStore";
import shallow from "zustand/shallow";

const FinanceTermInput = () => {
	const {
		// amtFinanced,
		loanPeriod,
		term,
		editTerm,
		// calculateCompoundInterest,
		// calculateFinalDueDate,
	} = useCurrentSaleStore(
		(state) => ({
			loanPeriod: state.loanPeriod,
			term: state.term,
			editTerm: state.editTerm,
		}),
		shallow
	);

	// Deal store
	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);

	const re = /^\d{1,3}$/;
	useEffect(() => {
		if (Number(loanPeriod) === 5) {
			editTerm(1);
		}
	}, [loanPeriod, editTerm]);

	return (
		<Input
			name="financeTerm"
			type="number"
			readOnly={Number(loanPeriod) === 5 || type !== DealStatus.PENDING}
			onFocus={(e) => e.target.select()}
			value={term === 0 ? 1 : term}
			className="bold"
			onBlur={(e) => {
				let val = e.target.value;

				if (val === "" || val === 0) {
					editTerm(term);
				} else {
					editTerm(e.target.valueAsNumber);
				}
				// if (amtFinanced !== 0) {
				// 	calculateCompoundInterest();
				// }
				// calculateFinalDueDate();
			}}
			onChange={(e) => {
				let changed = e.target.value;

				if (changed === "") {
					editTerm(term);
				} else if (changed.match(re)) {
					editTerm(e.target.valueAsNumber);
				}

				// if (amtFinanced !== 0) {
				// 	calculateCompoundInterest();
				// }
				// calculateFinalDueDate();
			}}
		/>
	);
};

export default FinanceTermInput;
