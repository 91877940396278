import React from "react";
import { Card, CardTitle, CardBody } from "reactstrap";
import { ReviewRow } from "component/dc/deal/review/Review";
import useCurrentRecapStore from "store/RecapStore";
import useCurrentSaleStore from "store/SaleStore";
import shallow from "zustand/shallow";

const ReviewDown = () => {
	const {
		totalTradeAllowance,
		totalTradePayoff,
		totalDown,
	} = useCurrentRecapStore(
		(state) => ({
			totalTradeAllowance: state.totalTradeAllowance,
			totalTradePayoff: state.totalTradePayoff,
			totalDown: state.totalDown,
		}),
		shallow
	);

	const {
		totalNetTrade,
		totalPickupPmts,
		manufacturerRebate,
		down,
	} = useCurrentSaleStore(
		(state) => ({
			totalNetTrade: state.totalNetTrade,
			totalPickupPmts: state.totalPickupPmts,
			manufacturerRebate: state.manufacturerRebate,
			down: state.down,
		}),
		shallow
	);

	return (
		<Card>
			<CardBody>
				<CardTitle tag="h6">Total Down</CardTitle>
				<ReviewRow
					isCurrency={true}
					label="Trade"
					value={totalTradeAllowance}
				/>
				<ReviewRow isCurrency={true} label="Pay-Off" value={totalTradePayoff} />
				<ReviewRow isCurrency={true} label="Net Trade" value={totalNetTrade} />
				<ReviewRow
					isCurrency={true}
					label="Total Pickups"
					value={totalPickupPmts}
				/>
				<ReviewRow
					isCurrency={true}
					label="Rebate"
					value={manufacturerRebate}
				/>
				<ReviewRow isCurrency={true} label="Money Down" value={down} />
				<h6>
					<ReviewRow isCurrency={true} label="Total Down" value={totalDown} />
				</h6>
			</CardBody>
		</Card>
	);
};

export default ReviewDown;
