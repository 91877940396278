import React from "react";
import { Form } from "reactstrap";

import InputElement from "view/formValidation/InputElement";
import LenderWrapper from "../LenderWrapper";

import { stateOptions } from "Constants";
import { CurrencyInputElement } from "view/formValidation/InputElement";
import { SelectorElement } from "view/formValidation/InputElement";

import { useFormContext } from "react-hook-form";
import { IncomeType } from "Constants";
import { EmployerType } from "Constants";
import { PhoneInputElement } from "view/formValidation/InputElement";

const UACApplicantIncomes = ({ prefix, index, employment, onInputChange }) => {
	// Handles the input changes
	const handleInputChange = (e) => {
		const { name, value } = e.target;

		onInputChange(name, value, index);
	};

	// Get validation context
	const methods = useFormContext();

	return (
		<Form id={`${prefix}Employment_${index}`}>
			<LenderWrapper
				cardId={`${prefix}Employment_${index}`}
				cardTitle={`Employment #${index + 1}`}
			>
				<SelectorElement
					{...methods}
					colSize="col-lg-3"
					name={`salaryType_${prefix}${index}`}
					label="Income Type"
					value={employment.salaryType}
					onChange={(e) => {
						const name = `salaryType_${prefix}${index}`;
						const val = e.target.value;

						onInputChange(name, val, index);
					}}
					options={IncomeType}
				/>
				<SelectorElement
					{...methods}
					colSize="col-lg-3"
					name={`employmentStatus_${prefix}${index}`}
					label="Employment Type"
					value={employment.employmentStatus}
					onChange={(e) => {
						const name = `employmentStatus_${prefix}${index}`;
						const val = e.target.value;

						onInputChange(name, val, index);
					}}
					options={EmployerType}
				/>
				<CurrencyInputElement
					{...methods}
					colSize="col-lg-3"
					name={`salary_${prefix}${index}`}
					label="Monthly Income"
					value={employment.salary}
					onChange={(e) => {
						const name = `salary_${prefix}${index}`;

						onInputChange(name, e, index);
					}}
					type="number"
				/>
				<InputElement
					{...methods}
					colSize="col-lg-3"
					value={employment.company}
					onChange={handleInputChange}
					name={`company_${prefix}${index}`}
					label="Company or Employer Name"
					type="text"
				/>
				<InputElement
					{...methods}
					colSize="col-lg-3"
					value={employment.position}
					onChange={handleInputChange}
					name={`position_${prefix}${index}`}
					label="Job Title"
					type="text"
				/>
				<InputElement
					{...methods}
					colSize="col-lg-3"
					value={employment.years}
					onChange={handleInputChange}
					name={`years_${prefix}${index}`}
					label="Employment Year(s)"
					type="number"
				/>
				<InputElement
					{...methods}
					colSize="col-lg-3"
					value={employment.months}
					onChange={handleInputChange}
					name={`months_${prefix}${index}`}
					label="Employment Month(s)"
					type="number"
				/>
				<PhoneInputElement
					{...methods}
					colSize="col-lg-3"
					value={employment.phone}
					onChange={(e) => {
						const name = `phone_${prefix}${index}`;

						onInputChange(name, e, index);
					}}
					name={`phone_${prefix}${index}`}
					label="Business Phone Number"
				/>
				<InputElement
					{...methods}
					colSize="col-lg-3"
					value={employment.street}
					onChange={handleInputChange}
					name={`street_${prefix}${index}`}
					label="Company Street Address"
					type="text"
				/>
				<InputElement
					{...methods}
					colSize="col-lg-3"
					value={employment.city}
					onChange={handleInputChange}
					name={`city_${prefix}${index}`}
					label="Company City"
					type="text"
				/>
				<SelectorElement
					{...methods}
					colSize="col-lg-3"
					name={`state_${prefix}${index}`}
					label="Company State"
					disable={false}
					value={employment.state}
					onChange={handleInputChange}
					options={stateOptions}
				/>
				<InputElement
					{...methods}
					colSize="col-lg-3"
					value={employment.zip}
					onChange={handleInputChange}
					name={`zip_${prefix}${index}`}
					label="Company Zip"
					type="text"
				/>
			</LenderWrapper>
		</Form>
	);
};

export default UACApplicantIncomes;
