import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";

import useCurrentDealStore from "../../../../store/DealStore";
import CenteredLoadingSpinner from "../../load/CenteredLoadingSpinner";
import LenderWrapper from "../LenderWrapper";

import { getUACAppStatus } from "../../../../api/dealerIntegrationsApi";
import { showApiError } from "../../../../helperFunction/ApiErrorHandler";
import { retrieveFailAlert, successAlertCustom } from "../../modal/SweetAlerts";
//import { FormattedText } from "../../../../view/formValidation/InputElement";
import { PATHS } from "Constants";

import dayjs from "dayjs";
import useCurrentSaleStore from "store/SaleStore";
import { useHistory } from "react-router-dom";
import { getAvailableVehicleByVin } from "api/InventoryAPI";
import { useFromUser } from "store/LocalStorageHelper";
import shallow from "zustand/shallow";

// HTML markup should be refactored
const UACApplicationStatus = () => {
	//	const today = dayjs().format("MMM D, YYYY [at] h:mm a");
	//	const fontSize = "1.53em";

	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const history = useHistory();
	const desking = PATHS.DEAL_DETAIL;
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	// Deal store
	const { ID, editVehicle, editCarRecNum } = useCurrentDealStore(
		(state) => ({
			ID: state.deal.ID,
			editVehicle: state.editVehicle,
			editCarRecNum: state.editCarRecNum,
		}),
		shallow
	);

	const {
		editDown,
		editTerm,
		editApr,
		editDocFee,
		editGap,
		editPrice,
		editLicenseFee,
	} = useCurrentSaleStore(
		(state) => ({
			editDown: state.editDown,
			editTerm: state.editTerm,
			editApr: state.editApr,
			editDocFee: state.editDocFee,
			editGap: state.editGap,
			editPrice: state.editPrice,
			editLicenseFee: state.editLicenseFee,
		}),
		shallow
	);

	// Get the application status data
	useEffect(() => {
		console.log(ID);
		getUACAppStatus(ID).then(
			(res) => {
				console.log(res);

				if (res.data.content?.resultCode === "ERROR") {
					setData(null);
				} else if (res.status === 200) {
					setData(res.data);
				}

				setIsLoading(false);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
		// eslint-disable-next-line
	}, []);
	const checkInventoryStatus = () => {
		const vin = data.content.application.vehicles[0].VIN;
		getAvailableVehicleByVin(vin, locationID, dealerID).then(
			(res) => {
				console.log(res.data.content);
				editCarRecNum(res.data.content.inventoryID);
				editVehicle(res.data.content);
				updateDeal();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};
	const updateDeal = () => {
		console.log(data);
		const deal = data.content.application.deal;
		console.log(deal);
		editApr(deal.Rate / 100);
		editDown(deal.CashDownPayment);
		editTerm(deal.Term);
		editDocFee(deal.Fees.DocFee);
		editGap(deal.Fees.GAP);
		editPrice(deal.Price);
		editLicenseFee(deal.Fees.License);
		successAlertCustom("Successfully update the deal.");
		history.push(desking);
	};
	const vehicle = data?.content.application.vehicles[0];
	return (
		<div>
			{!isLoading != null &&
				data !== null &&
				data.content?.resultCode !== "ERROR" && (
					<div>
						<Row sm="12" className="mt-2">
							<Col sm="6">
								<h5>Application ID</h5>
							</Col>
							<Col sm="6">
								<h5>
									{data.content?.status != null
										? data?.dcApplication?.applicationID
										: ""}
								</h5>
							</Col>
						</Row>

						<Row sm="12" className="mt-2">
							<Col sm="6">
								<h5>Application Status</h5>
							</Col>
							<Col sm="6">
								<h5>
									{data.content?.status != null
										? data.content.status.charAt(0).toUpperCase() +
										  data.content.status.slice(1)
										: ""}
								</h5>
							</Col>
						</Row>
						<Row sm="12" className="mt-2">
							<Col sm="6">
								<h5>Application Link</h5>
							</Col>
							<Col sm="6">
								<h5>
									<a
										href={data.content.dealURL}
										target="_blank"
										rel="noopener noreferrer"
									>
										View Application
									</a>
								</h5>
							</Col>
						</Row>
						<Row sm="12" className="mt-2">
							<Col sm="6">
								<h5>Last Updated</h5>
							</Col>
							<Col sm="6">
								<h5>
									{dayjs(data?.dcApplication?.lastUpdate)
										.format("MM/DD/YYYY")
										.toUpperCase()}
								</h5>
							</Col>
						</Row>
						<Row sm="12" className="mt-2">
							<Col sm="6">
								<h5>Vehicle</h5>
							</Col>
							<Col sm="6">
								<h5>
									{vehicle.Year +
										" " +
										vehicle.Make +
										" " +
										vehicle.Model +
										" " +
										vehicle.VIN}
								</h5>
							</Col>
						</Row>
						<Row sm="12" className="mt-2">
							<Button onClick={checkInventoryStatus}>Update Deal </Button>
						</Row>

						{/* {data.offers != null &&
						data.offers[0] !== "" &&
						data.offers.map((offer, i) => (
							<LenderWrapper
								key={i}
								cardId={`uacOffers${i}`}
								cardTitle={`Offer ${i + 1}`}
							>
								<Col sm="6">
									<h5>Dealer Net Check</h5>
								</Col>
								<Col sm="6">
									<FormattedText
										fontSize={fontSize}
										value={offer.netCheck || 0}
										fixedDecScale={true}
										prefix={"$"}
									/>
								</Col>
								<Col sm="6">
									<h5>Amount Financed</h5>
								</Col>
								<Col sm="6">
									<FormattedText
										fontSize={fontSize}
										value={offer.amountReceived || 0}
										fixedDecScale={true}
										prefix={"$"}
									/>
								</Col>
								<Col sm="6">
									<h5>Term</h5>
								</Col>
								<Col sm="6">
									<FormattedText fontSize={fontSize} value={offer.term || 0} />
								</Col>
								<Col sm="6">
									<h5>Monthly Payment</h5>
								</Col>
								<Col sm="6">
									<FormattedText
										fontSize={fontSize}
										value={offer.monthlyPayment || 0}
										fixedDecScale={true}
										prefix={"$"}
									/>
								</Col>
							</LenderWrapper>
						))} */}
						{data.content?.stipulations != null &&
							data.content?.stipulations[0] !== "" &&
							data.content?.stipulations.map((stipulation, i) => (
								<LenderWrapper
									key={i}
									cardId={`uacStipulations${i}`}
									cardTitle={`Stipulation ${i + 1}`}
								>
									<Col sm="6">
										<h5>Status</h5>
									</Col>
									<Col sm="6">
										<h5>{stipulation.status || ""}</h5>
									</Col>
									<Col sm="6">
										<h5>Code</h5>
									</Col>
									<Col sm="6">
										<h5>{stipulation.code || ""}</h5>
									</Col>
									<Col sm="6">
										<h5>Received</h5>
									</Col>
									<Col sm="6">
										<h5>{`${stipulation.received}`}</h5>
									</Col>
									<Col sm="6">
										<h5>Description</h5>
									</Col>
									<Col sm="6">
										<h5>{stipulation.description || ""}</h5>
									</Col>
								</LenderWrapper>
							))}
						{/* {data.reasonsDeclined != null && (
							<LenderWrapper
								cardId={`uacReasonsDeclined`}
								cardTitle="Reasons Declined"
							>
								{data.reasonsDeclined.map((declined, i) => (
									<Col sm="12" key={i}>
										<h5>- {declined}</h5>
									</Col>
								))}
							</LenderWrapper>
						)} */}
					</div>
				)}
			{!isLoading && data === null && (
				<h5>Failed to retrieve status information...</h5>
			)}
			{isLoading && <CenteredLoadingSpinner />}
		</div>
	);
};

export default UACApplicationStatus;
