import React from "react";

import useBuildLoginInfo from "helperFunction/useBuildLoginInfo";
import useCurrentLoginStore from "store/LoginStore";
import useIsOwnerOrAdmin from "../useIsOwnerOrAdmin";

import { saveFailAlert } from "../../../../../component/dc/modal/SweetAlerts";
import { saveSuccessAlert } from "component/dc/modal/SweetAlerts";
import { saveUserDetails } from "../../../../../api/LoginAPI";
import { showApiError } from "../../../../../helperFunction/ApiErrorHandler";

import { useFormContext } from "react-hook-form";

const SaveProfile = ({ imageFile }) => {
	const sessionStorageLoginData = JSON.parse(sessionStorage.getItem("user"));
	const buildLoginInfo = useBuildLoginInfo();
	const isOwnerOrAdmin = useIsOwnerOrAdmin();
	const setLoginFields = useCurrentLoginStore((state) => state.setLoginFields);

	// Saves the logged in user's account info
	const saveInfo = () => {
		var formData = new FormData();
		const loginInfo = buildLoginInfo();
		formData.append("image", imageFile);
		formData.append(
			"profileData",
			loginInfo.profilePicture != null ? "" : null
		);
		formData.append(
			"user",
			new Blob(
				[
					JSON.stringify({
						...loginInfo.dcLogin,
					}),
				],
				{
					type: "application/json",
				}
			)
		);

		saveUserDetails(formData).then(
			(res) => {
				const data = res.data.content;
				const updatedSessionLogin = {
					...sessionStorageLoginData,
					...data.user,
					profileData: data.profileData,
				};

				setLoginFields(
					data.user,
					loginInfo.locations,
					loginInfo.dealers,
					data.profileData
				);

				sessionStorage.setItem("user", JSON.stringify(updatedSessionLogin));
				saveSuccessAlert(res.data.message);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	const methods = useFormContext();

	return (
		<>
			{isOwnerOrAdmin && (
				<div
					className="btn btn-success btn-lg float-button"
					onClick={methods.handleSubmit(saveInfo)}
				>
					<i className="nc-icon nc-check-2" /> Save
				</div>
			)}
		</>
	);
};

export default SaveProfile;
