import React, { useState } from "react";
import {
	Form,
	Button,
	Card,
	CardBody,
	Col,
	Row,
	CardTitle,
	CardHeader,
} from "reactstrap";

import { saveFailAlert } from "../../../component/dc/modal/SweetAlerts";
import { saveSuccessAlert } from "component/dc/modal/SweetAlerts";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";
import { stateOptions } from "Constants.js";
import { useFromUser } from "store/LocalStorageHelper";
import InputElement, {
	PhoneInputElement,
	SelectorElement,
	CurrencyInputElement,
} from "view/formValidation/InputElement";
import tabOnEnter from "helperFunction/tabOnEnter";
import InputWrapper from "view/formValidation/InputWrapper";
import VendorTable from "../../../component/dc/table/VendorTable";
import { saveVendor } from "api/VendorsAPI";
import { vendorTypes } from "Constants";

import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider } from "react-hook-form";
import ChildrenModal from "component/dc/modal/ChildrenModal";
import { PercentInput } from "../../formValidation/InputElement";

const VendorSettings = ({ useType = 10, vendorType }) => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const [open, setOpen] = useState(false);
	const toggle = () => {
		setOpen(!open);
	};

	const [ID, setID] = useState(null);
	const [name, setName] = useState("");
	const [contact1, setContact1] = useState("");
	const [phone1, setPhone1] = useState("");
	const [street, setStreet] = useState("");
	const [state, setState] = useState("");
	const [city, setCity] = useState("");
	const [zip, setZip] = useState("");
	const [email, setEmail] = useState("");
	const [website, setWebsite] = useState("");
	const [lenderNo, setLenderNo] = useState("");

	const [updating, setUpdating] = useState(false);
	const [refresh, setRefresh] = useState(false);
	//const [type, setType] = useState(useType);

	//for lender
	const [bankFee, setBankFee] = useState("");
	const [lenderFee, setLenderFee] = useState("");
	const [lateFee, setLateFee] = useState("");
	const [discountRate, setDiscountRate] = useState("");
	const [reserve, setReserve] = useState("");
	const [taxId, setTaxId] = useState("");
	const [securityId, setsecurityID] = useState("");
	const [default_, setDefault] = useState(0);
	const [inHouse, setInHouse] = useState(0);
	const [profitOnly, setProfitOnly] = useState(0);
	const [dealerPart, setDealerPart] = useState("");
	const [notes, setNotes] = useState("");

	const [lineOfCredit, setLineOfCredit] = useState(0);

	const clearFields = () => {
		setID("");
		setName("");
		setContact1("");
		setPhone1("");
		setStreet("");
		setState("");
		setCity("");
		setZip("");
		setEmail("");
		setWebsite("");
		setUpdating(false);
		setBankFee("");
		setLenderFee("");
		setLateFee("");
		setDiscountRate("");
		setReserve("");
		setTaxId("");
		setsecurityID("");
		setDefault(0);
		setInHouse(0);
		setProfitOnly(0);
		setDealerPart("");
		setNotes("");
		setLineOfCredit(0);
		setLenderNo("");
	};

	const editFunctions = {
		setID,
		setName,
		setContact1,
		setCity,
		setPhone1,
		setStreet,
		setState,
		setZip,
		setEmail,
		setWebsite,
		setUpdating,
		setBankFee,
		setLenderFee,
		setLateFee,
		setDiscountRate,
		setReserve,
		setTaxId,
		setsecurityID,
		setDefault,
		setInHouse,
		setProfitOnly,
		setDealerPart,
		setNotes,
		setLineOfCredit,
		setLenderNo,
	};

	const addVendor = () => {
		let newVendor = {
			ID,
			name,
			contact1,
			phone1,
			street,
			state,
			city,
			zip,
			email,
			website,
			vendorType: useType,
			dealerID,
			locationID,
			isHidden: false,
			notes,
			lenderNo,
		};
		if (useType === 30) {
			newVendor = {
				...newVendor,
				bankFee,
				lenderFee,
				lateFee,
				discountRate,
				reserve,
				taxId,
				securityId,
				default_,
				inHouse,
				profitOnly,
				dealerPart,
			};
		}
		if (useType === 70) {
			newVendor = {
				...newVendor,
				lineOfCredit,
			};
		}
		console.log(newVendor);
		saveVendor(newVendor).then(
			(res) => {
				console.log(res.data.content);
				setRefresh(true);
				setRefresh(false);
				saveSuccessAlert();
				clearFields();
				toggle();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	setLocale({
		mixed: {
			required: "Required",
		},
	});

	//Add schema
	const schema = object().shape({ [`${vendorType}Name`]: string().required() });

	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<div className="row mx-0 p-3">
			<FormProvider {...methods}>
				<ChildrenModal
					modal={open}
					toggle={toggle}
					children={
						<Form onKeyDown={tabOnEnter}>
							<InputWrapper
								formTitle={vendorTypes[useType] + " Information"}
								inputComponents={[
									<InputElement
										{...methods}
										value={name}
										onChange={(e) => {
											setName(e.target.value);
										}}
										name={`${vendorType}Name`}
										label="Name"
										type="text"
									/>,
									<InputElement
										{...methods}
										value={contact1}
										onChange={(e) => {
											setContact1(e.target.value);
										}}
										name={`${vendorType}Contact1`}
										label="Contact"
										type="text"
									/>,
									<InputElement
										{...methods}
										value={lenderNo}
										onChange={(e) => setLenderNo(e.target.value)}
										name="license"
										label="License"
									/>,

									<PhoneInputElement
										{...methods}
										value={phone1}
										onChange={setPhone1}
										name={`${vendorType}Phone1`}
										label="Phone"
									/>,
									<InputElement
										{...methods}
										value={street}
										onChange={(e) => {
											setStreet(e.target.value);
										}}
										name={`${vendorType}Street`}
										label="Street"
										type="text"
									/>,
									<InputElement
										{...methods}
										value={city}
										onChange={(e) => {
											setCity(e.target.value);
										}}
										name={`${vendorType}City`}
										label="City"
										type="text"
									/>,
									<SelectorElement
										{...methods}
										name={`${vendorType}State`}
										label="State"
										value={state}
										onChange={(e) => setState(e.target.value)}
										options={stateOptions}
									/>,
									<InputElement
										{...methods}
										value={zip}
										onChange={(e) => {
											setZip(
												e.target.value.replace(/[^\d{5}]$/, "").substr(0, 5)
											);
										}}
										name={`${vendorType}Zip`}
										label="Zip"
										type="text"
									/>,
									<InputElement
										{...methods}
										value={email}
										onChange={(e) => {
											setEmail(e.target.value);
										}}
										name={`${vendorType}Email`}
										label="Email"
										type="text"
									/>,
									<InputElement
										{...methods}
										value={website}
										onChange={(e) => {
											setWebsite(e.target.value);
										}}
										name={`${vendorType}Website`}
										label="Website"
										type="text"
									/>,
									<>
										{useType === 70 && (
											<CurrencyInputElement
												value={lineOfCredit}
												name="lineOfCredit"
												label="Line Of Credit"
												type="text"
												onChange={(e) => {
													setLineOfCredit(e);
												}}
											/>
										)}
									</>,

									<InputElement
										{...methods}
										wholeRow={true}
										value={notes}
										name="notes"
										label="Notes"
										type="textarea"
										onChange={(e) => {
											setNotes(e.target.value);
										}}
									/>,
									<div>
										{useType === 30 && (
											<Form onKeyDown={tabOnEnter}>
												<InputWrapper
													formTitle="Lender Page"
													inputComponents={[
														<CurrencyInputElement
															value={bankFee}
															name="bankfee"
															label="Bank Fee (Added to Seller Fees)"
															type="text"
															onChange={setBankFee}
														/>,
														<CurrencyInputElement
															value={lenderFee}
															onChange={setLenderFee}
															name="LenderFee"
															label="Lender Fee (Added to Recap Cost)"
															type="text"
														/>,
														<CurrencyInputElement
															value={lateFee}
															onChange={setLateFee}
															name="LateFee"
															label="Late Fee (Disclousure)"
															type="text"
														/>,

														<PercentInput
															{...methods}
															value={discountRate}
															onChange={setDiscountRate}
															name={`${vendorType}Discount`}
															label="Discount (%)"
															type="text"
														/>,
														<PercentInput
															{...methods}
															value={reserve}
															onChange={setReserve}
															name={`${vendorType}Reserve `}
															label="Reserve (%)"
															type="text"
														/>,
														<PercentInput
															{...methods}
															value={dealerPart}
															onChange={(e) => {
																setDealerPart(e);
															}}
															name={`${vendorType}DealerPart`}
															label="Dealer Participation (%)"
															type="text"
														/>,

														<InputElement
															{...methods}
															value={taxId}
															onChange={(e) => {
																setTaxId(e.target.value);
															}}
															name={`${vendorType}TaxId`}
															label="Tax ID / ELT"
															type="text"
														/>,
														<InputElement
															{...methods}
															value={securityId}
															onChange={(e) => {
																setsecurityID(e.target.value);
															}}
															name={`${vendorType}securityId`}
															label="Security Party Number / Security ID"
															type="text"
														/>,

														<Row>
															<Col md="12">
																<input
																	type="checkbox"
																	checked={profitOnly}
																	onChange={(e) => {
																		const tempValue = e.target.checked ? 1 : 0;
																		setProfitOnly(tempValue);
																	}}
																/>
																<label className="checkbox-spacing">
																	Allow Profit only on Recap
																</label>
															</Col>
															<Col md="12">
																<input
																	type="checkbox"
																	checked={default_}
																	onChange={(e) => {
																		const tempValue = e.target.checked ? 1 : 0;
																		setDefault(tempValue);
																	}}
																/>
																<label className="checkbox-spacing">
																	{" "}
																	Default Lender
																</label>
															</Col>
															<Col md="12">
																<input
																	type="checkbox"
																	checked={inHouse}
																	onChange={(e) => {
																		const tempValue = e.target.checked ? 1 : 0;
																		setInHouse(tempValue);
																	}}
																/>
																<label className="checkbox-spacing">
																	{" "}
																	In-House
																</label>
															</Col>
														</Row>,
													]}
												/>
											</Form>
										)}
									</div>,
								]}
								buttons={
									<div className="w-100 text-center">
										<Button
											color="primary"
											onClick={methods.handleSubmit(addVendor)}
										>
											<i className="nc-icon nc-simple-add" />{" "}
											{updating ? "Update" : "Add"}
										</Button>
									</div>
								}
							/>
						</Form>
					}
				/>
			</FormProvider>
			<div className="col pb-5">
				<div className="row mx-0">
					<Button className="btn btn-primary btn-sm" onClick={toggle}>
						<i className="nc-icon nc-simple-add"></i> Add {vendorType}
					</Button>
					<div className="col-md-6 text-right"></div>
				</div>

				<Card>
					<CardHeader>
						<CardTitle>
							<h3 className="text-capitalize">{vendorType}</h3>
						</CardTitle>
					</CardHeader>
					<CardBody>
						<VendorTable
							refresh={refresh}
							type={useType}
							editFields={editFunctions}
							setRefresh={setRefresh}
							toggle={toggle}
						/>
					</CardBody>
				</Card>
			</div>
		</div>
	);
};

export default VendorSettings;
