import React from "react";
import { Button } from "reactstrap";

import PrintReview from "../../../../component/dc/deal/review/PrintReview";
import { getPdf } from "api/FormsAPI";
import useBuildDeal from "helperFunction/deal/useBuildDeal";
import { useFromUser } from "store/LocalStorageHelper";
import { createFileAndDownload, displayPdf } from "../../../../api/FormsAPI";
import swal from "sweetalert2";
import { displayError } from "api/FormsAPI";
import { formLoading } from "api/FormsAPI";
import useSetDeal from "../../../../helperFunction/deal/useSetDeal";
import { saveDeal } from "../../../../api/DealAPI";
import { saveFailAlert, saveSuccessAlert } from "../../modal/SweetAlerts";
import { showApiError } from "../../../../helperFunction/ApiErrorHandler";

const ReviewWrapper = ({ toggle }) => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const getDeal = useBuildDeal();
	const setDeal = useSetDeal();

	const handlePrint = () => {
		const deal = getDeal();
		const dealID = deal.deal.ID;
		const body = {
			dealID: dealID,
			inventoryID: null,
			// 225 is ID for deal review in dcReport
			dcReportID: 225,
			params: { dealerID: dealerID, locationID: locationID },
		};
		formLoading("PDF");
		saveDeal(deal).then(
			(res) => {
				setDeal(res);
				saveSuccessAlert();
				body.dealID = res.data.content.deal.ID;
				getPdf(body).then(
					(response) => {
						const fileName = "ST_DEAL_REVIEW_" + dealID + ".pdf";
						createFileAndDownload(response, fileName);
						displayPdf(response);
						swal.close();
					},
					(err) => {
						displayError(err);
					}
				);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	return (
		<div>
			<PrintReview />
			<Button
				onClick={() => {
					handlePrint();
					toggle();
				}}
			>
				Print
			</Button>
		</div>
	);
};

export default ReviewWrapper;
