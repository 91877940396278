import useCurrentDealerLinkStore from "../store/DealerLinkStore";
import { useFromUser } from "../store/LocalStorageHelper";
import shallow from "zustand/shallow";

export const useBuildDealerLink = () => {
	const locationID = useFromUser("locationID");

	const {
		lotVantageAPIToken,
		googleBaseUser,
		googleBasePwd,
		fairfaxKey,
		fairfaxUser,
		carfaxUser,
		carfaxPwd,
		autoCheckSID,
		cbccustomerID,
		cbcuserID,
		cbcpassword,
		credit700Pwd,
		credit700User,
		credit700PreUser,
		credit700PrePwd,
		carfaxAllowPurchase,
	} = useCurrentDealerLinkStore((state) => state, shallow);

	const dealerLink = {
		lotVantageAPIToken,
		googleBaseUser,
		googleBasePwd,
		fairfaxKey,
		fairfaxUser,
		carfaxUser,
		carfaxPwd,
		autoCheckSID,
		cbccustomerID,
		cbcuserID,
		cbcpassword,
		credit700Pwd,
		credit700User,
		credit700PreUser,
		credit700PrePwd,
		locationID,
		carfaxAllowPurchase,
	};
	const getDealerLink = () => {
		return dealerLink;
	};
	return getDealerLink;
};
export default useBuildDealerLink;
