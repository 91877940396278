import React, { useState } from "react";
import { Input, Row } from "reactstrap";
import useCurrentSaleStore from "store/SaleStore";
import AftermarketModal from "../modal/aftermarket/AftermarketModal";
import OpenTotalModal from "../OpenTotalModal";
import NumberFormat from "react-number-format";
import shallow from "zustand/shallow";

const Aftermarket = () => {
	const [openAftermarketModal, setopenAftermarketModal] = useState(false);
	const { aftmkt } = useCurrentSaleStore(
		(state) => ({
			aftmkt: state.aftmkt,
		}),
		shallow
	);

	// Function to open the modal
	const toggle = () => {
		setopenAftermarketModal(!openAftermarketModal);
	};

	// Returns the total field in the sale page with the modal to open up the fields to edit the total value
	return (
		<Row>
			{openAftermarketModal ? (
				<AftermarketModal modal={openAftermarketModal} toggle={toggle} />
			) : null}
			<div className="col-sm-6 px-0">
				<OpenTotalModal
					btnClass="w-100 btn btn-primary btn-md px-0"
					label="Aftermarket"
					toggle={toggle}
				/>
			</div>
			<div className="col-sm-6 pr-0 dealInputs moneyIn pl-2">
				<NumberFormat
					value={aftmkt}
					thousandSeparator={true}
					decimalScale={2}
					fixedDecimalScale={true}
					prefix={"$"}
					isNumericString={true}
					readOnly={true}
					customInput={Input}
					onClick={toggle}
					className="bold"
				/>
			</div>
		</Row>
	);
};

export default Aftermarket;
