/*!
 
=========================================================
* DealerClick 2.0 - v1.0
=========================================================
 
* Product Page: https://dealerclick.com
* Copyright 2020 DealerClick (https://dealerclick.com)
 
* Coded by DealerClick Software ))
 
=========================================================
 
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 
*/
import React, { useEffect, useState } from "react";
import {
	Col,
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	FormGroup,
	Form,
	Label,
} from "reactstrap";
import Select from "react-select";
import { Controller, useFormContext } from "react-hook-form";
import CreatableSelect from "react-select/creatable";

import useCurrentVehicleStore from "../../../store/InventoryStore";
import InventoryOptionalEquipment from "../../../component/dc/selector/InventoryOptionalEquipment";
import InventoryStandardEquipment from "../../../component/dc/selector/InventoryStandardEquipment";
import InputWrapper from "../../../view/formValidation/InputWrapper";
import {
	vehicleTypeOptions,
	fuelTypeList,
	conditionOptions,
	vendorTypesOBJ,
	vehicleStatus,
	newOrUsed,
	ActualMileageFlagOption,
	smogCheck,
	StateCodeByStateName,
	driveType,
	purchaseType,
	titleBrands,
	vehicleStatusList,
} from "../../../Constants";
import InputElement, {
	CurrencyInputElement,
	SelectorElement,
	InventoryEditableSelectorElement,
	CheckBoxElement,
	//	CheckBoxElement,
} from "../../../view/formValidation/InputElement";
import { convertToReactSelect } from "../../../component/dc/selector/helperFunction/SelectorFormat";
import { retrieveFailAlert } from "../../../component/dc/modal/SweetAlerts";
import InventoryDeleteButton from "../../../component/dc/inventory/InventoryDeleteButton";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";
import {
	getInventorySpecificMake,
	getInventoryModelList,
	getInventoryMakeList,
	getVehicleSettings,
	getNewStockNo,
} from "../../../api/InventoryAPI";
import InventorySpecificEquipment from "../../../component/dc/selector/InventorySpecificEquipment";
import tabOnEnter from "../../../helperFunction/tabOnEnter";
import { useFromUser } from "store/LocalStorageHelper";
import dayjs from "dayjs";
import SiriusXMButton from "../../../component/dc/inventory/SiriusXMButton";
import TradeInfo from "component/dc/deal/trade/TradeInfo";
import shallow from "zustand/shallow";
import InventoryGpsInformation from "./InventoryGpsInformation";
import VenderCompanyForm from "../../settings/vendor/VenderCompanyForm";
import ChildrenModal from "../../../component/dc/modal/ChildrenModal";
import InventoryCustomEquipment from "../../../component/dc/selector/InventoryCustomEquipment";

const InventoryDetailsTab = ({ isDeal = false, hideDelete = false }) => {
	const locationID = useFromUser("locationID");
	const [inventorySettings, setInventorySettings] = useState({});

	const vehicleLocationsList = useCurrentVehicleStore(
		(state) => state.locationList
	);
	const vehicleBuyersList = useCurrentVehicleStore((state) => state.buyersList);
	const [yearList, setYearList] = useState([]);
	const [statusList, setStatusList] = useState(vehicleStatusList);

	const firstName = useFromUser("firstName");
	const lastName = useFromUser("lastName");
	const dcLoginID = useFromUser("ID");
	const today = dayjs().format("YYYY-MM-DD");
	// Vehicle store
	const {
		inventoryID,
		stockNo,
		vin,
		year,
		make,
		model,
		trim,
		odometerIn,
		color,
		intColor,
		condition,
		transmission,
		doors,
		engine,
		mPGHwy,
		mPGCity,
		weight,
		description,
		customEquip,
		selectedEquip,
		optionalEquip,
		type,
		askingPrice,
		onlinePrice,
		wholesalePrice,
		dateIn,
		standardEquip,
		specificationEquip,
		downPayment,
		msrp,
		weightFee,
		//		isDraft,
		datePaid,
		//PaymentType,
		//checkNo,
		newUsed,
		license,
		licenseExpires,
		vendorRecNum,
		vendorsList,
		style,
		styleList,
		colorList,
		intColorList,
		status,
		buyer,
		location,
		numCylinders,
		odometerOut,
		makeList,
		modelList,
		reportOfSale,
		driveTrain,
		fuelType,
		actualMileageFlag,
		titleNo,
		titleDateOfIssue,
		newTitleReceivedDate,
		newTitleNumber,
		titleState,
		newTitleState,
		titleInDate,
		smogCheckDate,
		isSmogCheck,
		cost,
		tradeLenderRecNum,
		acv,
		allowance,
		payoff,
		tradeLenderList,
		isTradeIn,
		paymentMethod,
		titleBrand,
		videoURL,
		isAdvertised,
		axelCount,
		tag,
		editTag,
		editAxelCount,
		editIsAdvertised,
		editVideoURL,
		editPaymentMethod,
		editTitleBrand,
		editTradeLenderList,
		editFuelType,
		editDriveTrain,
		editReportOfSale,
		editBuyer,
		editLocation,
		editStockNo,
		editVin,
		editYear,
		editMake,
		editModel,
		editTrim,
		editOdometerIn,
		editColor,
		editIntColor,
		editCondition,
		editTransmission,
		editDoors,
		editEngine,
		editMpgHwy,
		editMpgCity,
		editWeight,
		editDescription,
		editCustomEquip,
		editSelectedEquip,
		editType,
		editAskingPrice,
		editOnlinePrice,
		editWholesalePrice,
		editDateIn,
		editDownPayment,
		editMsrp,
		editWeightFee,
		//		editIsDraft,
		editDatePaid,
		editNewUsed,
		editLicense,
		editLicenseExpires,
		editVendorRecNum,
		editStyle,
		editStandardEquip,
		editSpecificationEquip,
		editOptionalEquip,
		editStatus,
		editNumCylinders,
		editOdometerOut,
		editMakeList,
		editModelList,
		editActualMileageFlag,
		editTitleNo,
		editTitleDateOfIssue,
		editNewTitleReceivedDate,
		editNewTitleNumber,
		editTitleInDate,
		editTitleState,
		editNewTitleState,
		editIsSmogCheck,
		editSmogCheckDate,
		editCreatedOn,
		editCreatedByID,
		editModifiedOn,
		editModifiedByID,
		editOpenedBy,
		editCost,
		editTradeLenderRecNum,
		editAcv,
		editAllowance,
		editPayoff,
		editVendorsList,
	} = useCurrentVehicleStore((state) => state, shallow);

	// Define custom error messages for different error types
	const methods = useFormContext();

	// TradeLender (lienholder) handlers
	const [openAddLenderModal, setOpenAddLenderModal] = useState(false);
	const toggleLenderModal = () => setOpenAddLenderModal(!openAddLenderModal);
	const tradeLendersListMap = tradeLenderList.map((obj) => {
		return {
			label: obj.name,
			value: obj.ID,
			vendor: obj,
		};
	});
	const [lenderOption, setLenderOption] = useState(
		tradeLendersListMap.find(
			(element) => element.value === tradeLenderRecNum
		) || { value: null, label: "None" }
	);
	const selectLender = (option) => {
		const value = option.value;

		console.log(option);
		if (value === "ADD") {
			toggleLenderModal();
			console.log(value);
		} else {
			editTradeLenderRecNum(value);
			setLenderOption(option);
		}
	};

	// Shows a list of all the styles taken from VIN decode and changes vehicle
	// details based on style selected or show the already saved style from DB
	const hasStyle = () =>
		(styleList != null && styleList.length !== 0 && (
			<SelectorElement
				readOnly={
					status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
				}
				{...methods}
				name="style"
				label="Style"
				value={style}
				onChange={(e) => editStyle(e.target.value)}
				options={styleList}
			/>
		)) || (
			<InputElement
				readOnly={
					status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
				}
				{...methods}
				value={style}
				name="style"
				label="Style"
				type="text"
				onChange={(e) => editStyle(e.target.value)}
			/>
		);

	const createColorList = (colorLists) => {
		let colorList = [];

		console.log(colorLists);
		colorLists.forEach((color) => {
			colorList.push({
				label: color,
				value: color,
			});
		});
		console.log(colorList);
		return colorList;
	};
	const getMakeList = () => {
		const makes = [];
		getInventoryMakeList().then(
			(res) => {
				const resp = res.data.content;
				for (const key in resp) {
					makes.push({ label: resp[key], value: resp[key] });
				}
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
		editMakeList(makes);
	};

	const getVehicleDefaults = () => {
		getVehicleSettings(locationID).then(
			(res) => {
				console.log(res);
				setInventorySettings(res.data.content);
			},
			(err) => {
				console.log(err);

				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	const updateList = () => {
		if (
			status === vehicleStatus.AVAILABLE ||
			status === vehicleStatus.HOLD ||
			status === vehicleStatus.SOLDWITHOUTDEAL ||
			status === vehicleStatus.DELETED
		) {
			// filtered list must include the current status
			const filteredList = vehicleStatusList.filter(
				(option) =>
					option.value === vehicleStatus.AVAILABLE ||
					option.value === vehicleStatus.HOLD ||
					option.value === vehicleStatus.SOLDWITHOUTDEAL ||
					option.value === vehicleStatus.DELETED
			);
			setStatusList(filteredList);
		} else if (
			status === vehicleStatus.APPRAISAL ||
			status === vehicleStatus.TRADE
		) {
			// filtered list must include the current status
			const filteredList = vehicleStatusList.filter(
				(option) =>
					option.value === vehicleStatus.AVAILABLE ||
					option.value === vehicleStatus.APPRAISAL ||
					option.value === vehicleStatus.TRADE ||
					option.value === vehicleStatus.DELETED
			);
			setStatusList(filteredList);
		} else if (
			status === vehicleStatus.SOLD ||
			status === vehicleStatus.PENDING
		) {
			setStatusList(vehicleStatusList);
		} else {
			setStatusList(vehicleStatusList);
		}
	};

	useEffect(() => {
		updateList();
		// eslint-disable-next-line
	}, [status]);

	useEffect(() => {
		getMakeList();
		getVehicleDefaults();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const models = [];
		if (make.length === 0) {
			getInventoryModelList().then(
				(res) => {
					const resp = res.data.content;
					for (const key in resp) {
						models.push({ label: resp[key], value: resp[key] });
					}
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		} else {
			getInventorySpecificMake(make).then(
				(res) => {
					const resp = res.data.content;
					for (const key in resp) {
						models.push({ label: resp[key], value: resp[key] });
					}
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}
		editModelList(models);
		// eslint-disable-next-line
	}, [make]);
	// isRunningStockNo,
	// firstStockNo,
	// runningStockNoLength,
	// packPercent,
	// packUsed,

	// Use the decoded list of styles to update vehicle details on style selection
	const setNewStocNo = () => {
		console.log(inventorySettings.isRunningStockNo);
		if (inventorySettings.isRunningStockNo) {
			getNewStockNo(locationID).then(
				(res) => {
					console.log(res);
					editStockNo(res);
				},
				(err) => {
					console.log(err);
				}
			);
		} else {
			editStockNo(vin.substring(vin.length - 6, vin.length));
		}
	};
	const changeDetails = () => {
		console.log(stockNo, vin);
		if (stockNo == null && vin != null && vin.length > 5) {
			console.log(inventorySettings);
			setNewStocNo();
			//				editStockNo(vin.substring(vin.length - 6, vin.length));
		}

		for (let i = 0; i < styleList.length; i++) {
			if (style === styleList[i].data.styleName) {
				editSpecificationEquip("");
				editType(styleList[i].data.vehicleType);
				editYear(styleList[i].data.year);
				editMake(styleList[i].data.make);
				editModel(styleList[i].data.model);
				if (styleList[i].data.trim !== "Base") editTrim(styleList[i].data.trim);
				editMsrp(styleList[i].data.msrp);
				editDoors(styleList[i].data.doors);
				editEngine(styleList[i].data.engine);
				editTransmission(styleList[i].data.transmission);
				editSpecificationEquip(styleList[i].data.specifications);
				editStandardEquip(styleList[i].data.installedEquipment);
				editOptionalEquip(
					convertToReactSelect(styleList[i].data.optionalEquipment)
				);

				return;
			}
		}
	};

	// Shows a list of all the exterior colors taken from VIN decoder, needs style
	const hasColor = () =>
		(colorList != null && colorList.length !== 0 && (
			<SelectorElement
				readOnly={
					status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
				}
				{...methods}
				name="color"
				label="Exterior Color"
				value={color}
				onChange={(e) => editColor(e.target.value)}
				options={createColorList(colorList)}
			/>
		)) || (
			<InputElement
				readOnly={
					status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
				}
				{...methods}
				value={color}
				name="color"
				label="Exterior Color"
				type="text"
				onChange={(e) => editColor(e.target.value)}
			/>
		);

	// Shows a list of all the interior colors taken from VIN decoder, needs style
	const hasIntColor = () =>
		(intColorList != null && intColorList.length !== 0 && (
			<SelectorElement
				readOnly={
					status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
				}
				{...methods}
				name="intColor"
				label="Interior Color"
				value={intColor}
				onChange={(e) => editIntColor(e.target.value)}
				options={createColorList(intColorList)}
			/>
		)) || (
			<InputElement
				readOnly={
					status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
				}
				{...methods}
				value={intColor}
				name="intColor"
				label="Interior Color"
				type="text"
				onChange={(e) => editIntColor(e.target.value)}
			/>
		);

	// Vendor handlers
	const toggleVendorModal = () => setOpenAddVendorModal(!openAddVendorModal);
	const [openAddVendorModal, setOpenAddVendorModal] = useState(false);
	const vendorsListMap = vendorsList.map((obj) => {
		return {
			label: obj.name,
			value: obj.ID,
			vendor: obj,
		};
	});
	const [vendorOption, setVendorOption] = useState(
		vendorsListMap.find((element) => element.value === vendorRecNum) || {
			value: null,
			label: "None",
		}
	);
	// Updates store with the vendor selected or opens modal to add new vendor
	const selectVendor = (option) => {
		const value = option.value;

		if (value === "ADD") {
			toggleVendorModal();
		} else {
			editVendorRecNum(value);
			setVendorOption(option);
		}
	};

	// //get drive type and curb weight from specification Equipment
	// useEffect(() => {
	// 	let specObj = "";
	// 	let specifications = "";
	// 	try {
	// 		specObj = JSON.parse(specificationEquip);
	// 		console.log(specObj);
	// 	} catch (e) {
	// 		console.log(e.message);
	// 	}
	// 	let findDriveType = "";
	// 	let findWeight = "";
	// 	if (specObj !== null && specObj !== "") {
	// 		Object.keys(specObj).forEach((key) => {
	// 			if (specObj[key].Key === "Drive Type") {
	// 				specifications = specObj[key].Value;
	// 				Object.keys(specifications).forEach((key) => {
	// 					findDriveType = specifications[key];
	// 					if (findDriveType.Name === "Drive Type") {
	// 						editDriveTrain(findDriveType.Value);
	// 					}
	// 				});
	// 			}
	// 			if (specObj[key].Key === "Measurements of Weight") {
	// 				specifications = specObj[key].Value;
	// 				Object.keys(specifications).forEach((key) => {
	// 					findWeight = specifications[key];
	// 					if (findWeight.Name === "Curb Weight") {
	// 						editWeight(findWeight.Value);
	// 					}
	// 				});
	// 			}
	// 		});
	// 	}
	// }, [editWeight, specificationEquip, editDriveTrain]);

	// const { control } = useForm();
	useEffect(() => {
		if (styleList != null && styleList.length !== 0) {
			changeDetails();
		}
		//edit more information
		editModifiedOn(today);
		editModifiedByID(dcLoginID);
		editOpenedBy(lastName + ", " + firstName);
		editCreatedByID(dcLoginID);
		editCreatedOn(today);
		// eslint-disable-next-line
	}, [style]);

	useEffect(() => {
		console.log(stockNo);
		if (
			(stockNo == null || stockNo === "") &&
			vin != null &&
			vin.length > 5 &&
			status !== vehicleStatus.Appraisal
		) {
			setNewStocNo();
			//editStockNo(vin.substring(vin.length - 6, vin.length));
		}

		editStatus(status === null || status === "" ? "Available" : status);
		// eslint-disable-next-line
	}, [status, editStatus]);

	//generate year list
	useEffect(() => {
		const currentYear = new Date().getFullYear() + 1;
		const yearStart = 1900;
		const getYearList = [];
		for (let i = currentYear; i >= yearStart; i--) {
			getYearList.push({ label: i.toString(), value: i.toString() });
		}
		setYearList(getYearList);
	}, []);
	console.log(isTradeIn, stockNo, inventoryID);
	console.log(lenderOption);
	console.log(vendorOption);
	return (
		// must have class content
		<>
			<div className="col">
				{isTradeIn === true && (
					<TradeInfo
						tradeLenderRecNum={tradeLenderRecNum}
						editTradeLenderRecNum={editTradeLenderRecNum}
						acv={acv}
						allowance={allowance}
						payoff={payoff}
						editAcv={editAcv}
						editAllowance={editAllowance}
						editPayoff={editPayoff}
						type={status}
						tradeVendors={tradeLenderList}
						editTradeVendors={editTradeLenderList}
						status={status}
					/>
				)}
				<Form className="pt-2" onKeyDown={tabOnEnter}>
					<InputWrapper
						formTitle="General"
						inputComponents={[
							<InputElement
								{...methods}
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								value={stockNo}
								name="stockNo"
								label="Stock Number"
								type="text"
								onChange={(e) => editStockNo(e.target.value)}
							/>,
							<SelectorElement
								{...methods}
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								name="newUsed"
								label="New or Used"
								value={newUsed || "Used"}
								onChange={(e) => editNewUsed(e.target.value)}
								options={newOrUsed}
							/>,

							<InputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={vin}
								name="vin"
								label="VIN"
								type="text"
								onChange={(e) => {
									editVin(e.target.value);
								}}
							/>,
							<InventoryEditableSelectorElement
								{...methods}
								disable={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								value={year}
								name="year"
								label="Year"
								type="number"
								onChange={(e) => {
									editYear(e);
								}}
								defaultValue={year}
								options={yearList}
								key={year}
							/>,
							<InventoryEditableSelectorElement
								disable={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								label="Make"
								value={make}
								onChange={(e) => editMake(e)}
								options={makeList}
								name="make"
								key={make}
								defaultValue={make}
							/>,
							<InventoryEditableSelectorElement
								disable={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								label="Model"
								value={model}
								onChange={(e) => editModel(e)}
								options={modelList}
								name="model"
								key={model}
								defaultValue={model}
							/>,

							hasStyle(),
							<InputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={trim}
								name="trim"
								label="Trim"
								type="text"
								onChange={(e) => {
									editTrim(e.target.value);
								}}
							/>,
							<SelectorElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={driveTrain}
								name="DriveType"
								label="Drive Type"
								type="text"
								onChange={(e) => {
									editDriveTrain(e.target.value);
								}}
								options={driveType}
							/>,

							<SelectorElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								name="fuelType"
								label="Fuel Type"
								value={fuelType}
								onChange={(e) => {
									editFuelType(e.target.value);
								}}
								options={fuelTypeList}
							/>,

							<InputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={numCylinders}
								name="numCylinders"
								label="Cylinders"
								type="number"
								onChange={(e) => {
									editNumCylinders(e.target.value);
								}}
							/>,
							<InputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={engine}
								name="engine"
								label="Engine"
								type="text"
								onChange={(e) => {
									editEngine(e.target.value);
								}}
							/>,
							<SelectorElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								name="type"
								label="Vehicle Type"
								value={type}
								onChange={(e) => editType(e.target.value)}
								options={vehicleTypeOptions}
							/>,

							<InputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={transmission}
								name="transmission"
								label="Transmission"
								type="text"
								onChange={(e) => {
									editTransmission(e.target.value);
								}}
							/>,
							<InputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={doors}
								name="doors"
								label="Doors"
								type="text"
								onChange={(e) => {
									editDoors(e.target.value);
								}}
							/>,

							<SelectorElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								name="actualMileage"
								label="Actual Mileage"
								value={actualMileageFlag}
								onChange={(e) => editActualMileageFlag(e.target.value)}
								options={ActualMileageFlagOption}
							/>,
							<SelectorElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								name="condition"
								label="Condition"
								value={condition}
								onChange={(e) => editCondition(e.target.value)}
								options={conditionOptions}
							/>,
							hasColor(),
							hasIntColor(),

							<InputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={mPGHwy}
								name="mPGHwy"
								label="MPG HWY"
								type="text"
								onChange={(e) => {
									editMpgHwy(e.target.value);
								}}
							/>,
							<InputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={mPGCity}
								name="mPGCity"
								label="MPG City"
								type="text"
								onChange={(e) => {
									editMpgCity(e.target.value);
								}}
							/>,

							<InputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={weight}
								name="weight"
								label="Curb Weight (lbs)"
								type="number"
								onChange={(e) => {
									editWeight(e.target.value);
								}}
							/>,
							<InputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={axelCount}
								name="axelCount"
								label="Axel Count"
								type="number"
								onChange={(e) => {
									editAxelCount(e.target.value);
								}}
							/>,
						]}
					/>
				</Form>
				<Form onKeyDown={tabOnEnter}>
					<InputWrapper
						formTitle="Purchase Info"
						inputComponents={[
							<Controller
								as={
									<FormGroup className="col-md-2">
										<div>
											<Label>Buyer</Label>
											<CreatableSelect
												isDisabled={
													status === vehicleStatus.SOLD ||
													status === vehicleStatus.DELETED
												}
												value={{ value: buyer, label: buyer }}
												options={vehicleBuyersList.map((value) => ({
													value: value,
													label: value,
												}))}
												onChange={(e) => {
													console.log(e);
													editBuyer(e.value);
												}}
												className="bold"
											/>
										</div>
									</FormGroup>
								}
								name="BuyerList"
								defaultValue={buyer === null || buyer === "" ? "0" : buyer}
							/>,
							<div className="col-sm-3">
								<Label>Vendor/Purchase</Label>
								<Select
									isDisabled={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									noOptionsMessage={() => "No vendors available"}
									value={vendorOption}
									options={[
										...vendorsListMap,
										{ value: "ADD", label: "Add new vendor" },
									]}
									onChange={(e) => selectVendor(e)}
									className="bold"
								/>
								<ChildrenModal
									modal={openAddVendorModal}
									toggle={toggleVendorModal}
									children={
										<VenderCompanyForm
											useType={vendorTypesOBJ.VENDOR.value}
											vendorType={vendorTypesOBJ.VENDOR.label}
											toggle={toggleVendorModal}
											setOption={setVendorOption}
											list={vendorsList}
											editList={editVendorsList}
											editRecNum={editVendorRecNum}
										/>
									}
								/>
							</div>,
							<InputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={dateIn}
								onChange={(e) => editDateIn(e.target.value)}
								name="dateIn"
								label="Date In"
								type="date"
							/>,
							<InputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={datePaid}
								onChange={(e) => editDatePaid(e.target.value)}
								name="datePaid"
								label="Date Paid"
								type="date"
							/>,
							<SelectorElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								name="purchaseType"
								label="Method of Purchase"
								value={paymentMethod}
								onChange={(e) => editPaymentMethod(e.target.value)}
								options={purchaseType}
							/>,

							<CurrencyInputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								value={cost}
								name="cost"
								label="Vehicle Cost"
								type="text"
								onChange={(e) => {
									console.log(e);
									editCost(e);
								}}
							/>,
							<InputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={odometerIn}
								name="odometerIn"
								label="Odometer In"
								type="number"
								onChange={(e) => {
									editOdometerIn(e.target.value);
								}}
							/>,
							<InputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={odometerOut}
								name="odometerOut"
								label="Odometer Out"
								type="number"
								onChange={(e) => {
									editOdometerOut(e.target.value);
								}}
							/>,

							<CurrencyInputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								value={allowance}
								name="allowance"
								label="Allowance"
								type="number"
								onChange={editAllowance}
								className="bold"
							/>,
							<CurrencyInputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								value={acv}
								name="acv"
								label="Acv"
								type="number"
								onChange={editAcv}
								className="bold"
							/>,
							<CurrencyInputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								value={payoff}
								name="payoff"
								label="Payoff"
								type="number"
								onChange={editPayoff}
								className="bold"
							/>,
							<div className="col-sm-3">
								<Label>LienHolder</Label>
								<Select
									noOptionsMessage={() => "No Lenders available"}
									isDisabled={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									value={lenderOption}
									options={[
										...tradeLendersListMap,
										{ value: "ADD", label: "Add new lender" },
									]}
									onChange={(e) => selectLender(e)}
									className="bold"
								/>
								<ChildrenModal
									modal={openAddLenderModal}
									toggle={toggleLenderModal}
									children={
										<VenderCompanyForm
											useType={vendorTypesOBJ.LENDER.value}
											vendorType={vendorTypesOBJ.LENDER.label}
											toggle={toggleLenderModal}
											setOption={setLenderOption}
											list={tradeLenderList}
											editList={editTradeLenderList}
											editRecNum={editTradeLenderRecNum}
										/>
									}
								/>
							</div>,
						]}
					/>
				</Form>

				<Form onKeyDown={tabOnEnter}>
					<InputWrapper
						formTitle="Advertising"
						inputComponents={[
							<SelectorElement
								{...methods}
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.PENDING
								}
								value={status}
								name="status"
								label="Status"
								type="text"
								onChange={(e) => {
									editStatus(e.target.value);
								}}
								options={statusList}
							/>,

							<CurrencyInputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								value={msrp}
								name="msrp"
								label="MSRP"
								type="text"
								onChange={(e) => {
									editMsrp(e);
								}}
							/>,
							<CurrencyInputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								value={askingPrice}
								name="price"
								label="Asking Price"
								type="text"
								onChange={(e) => {
									editAskingPrice(e);
								}}
							/>,
							<CurrencyInputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								value={onlinePrice}
								name="internet price"
								label="Internet Price"
								type="text"
								onChange={(e) => {
									editOnlinePrice(e);
								}}
							/>,
							<CurrencyInputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								value={wholesalePrice}
								name="Wholesale price"
								label="Wholesale Price"
								type="text"
								onChange={(e) => {
									editWholesalePrice(e);
								}}
							/>,

							<CurrencyInputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								value={downPayment}
								name="downPayment"
								label="Down Payment"
								type="text"
								onChange={(e) => {
									editDownPayment(e);
								}}
							/>,
							<InputElement
								{...methods}
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								colSize="col-lg-4"
								value={videoURL}
								onChange={(e) => {
									editVideoURL(e.target.value);
								}}
								name="videoURL"
								label="Video URL"
								type="text"
							/>,
							<CheckBoxElement
								{...methods}
								disabled={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								checked={isAdvertised}
								onChange={() => editIsAdvertised(!isAdvertised)}
								name="isAdvertised"
								label="Is Advertised"
								labelClass="pl-4 required"
							/>,
							<InputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={tag}
								name="tag"
								label="Tagline(/Highlight)"
								type="text"
								colSize="col-sm-6"
								onChange={(e) => {
									editTag(e.target.value);
								}}
							/>,
						]}
					/>
				</Form>
				<Form onKeyDown={tabOnEnter}>
					<InputWrapper
						formTitle="Vehicle Details"
						inputComponents={[
							<Controller
								as={
									<FormGroup className="col">
										<div>
											<Label>Location</Label>
											<CreatableSelect
												isDisabled={
													status === vehicleStatus.SOLD ||
													status === vehicleStatus.DELETED
												}
												value={{ value: location, label: location }}
												options={vehicleLocationsList.map((value) => ({
													value: value,
													label: value,
												}))}
												onChange={(e) => {
													console.log(e);
													editLocation(e.value);
												}}
											/>
										</div>
									</FormGroup>
								}
								name="locationList"
								defaultValue={
									location === null || location === "" ? "0" : location
								}
							/>,
							<InputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={license}
								name="license"
								label="License Plate"
								type="text"
								onChange={(e) => {
									editLicense(e.target.value);
								}}
							/>,

							<InputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={licenseExpires}
								name="licenseExpires"
								label="License Exp. Date"
								type="date"
								onChange={(e) => {
									editLicenseExpires(e.target.value);
								}}
							/>,
							<InputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={titleInDate}
								name="titleInDate"
								label="Date Title In"
								type="date"
								onChange={(e) => {
									editTitleInDate(e.target.value);
								}}
							/>,
							<InputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={titleNo}
								name="titleNo"
								label="Title No"
								type="text"
								onChange={(e) => {
									editTitleNo(e.target.value);
								}}
							/>,

							<SelectorElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={titleState}
								name="titleStateIssue"
								label="Title State Issue"
								type="text"
								onChange={(e) => {
									editTitleState(e.target.value);
								}}
								options={StateCodeByStateName}
							/>,
							<InputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={newTitleReceivedDate}
								name="newTitleReceivedDate"
								label="New Title Date In"
								type="date"
								onChange={(e) => {
									editNewTitleReceivedDate(e.target.value);
								}}
							/>,

							<InputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={newTitleNumber}
								name="newTitleNumber"
								label="New Title No"
								type="text"
								onChange={(e) => {
									editNewTitleNumber(e.target.value);
								}}
							/>,
							<SelectorElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={newTitleState}
								name="newTitleState"
								label="New Title State Issue"
								type="text"
								onChange={(e) => {
									editNewTitleState(e.target.value);
								}}
								options={StateCodeByStateName}
							/>,
							<InputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={titleDateOfIssue}
								name="titleDateOfIssue"
								label="Title Date of Issue"
								type="date"
								onChange={(e) => {
									editTitleDateOfIssue(e.target.value);
								}}
							/>,
							<SelectorElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								name="titleBrand"
								label="Title Brand"
								value={titleBrand}
								onChange={(e) => editTitleBrand(e.target.value)}
								options={titleBrands}
							/>,

							<CurrencyInputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								value={weightFee}
								name="weightFee"
								label="Weight Fee"
								type="text"
								onChange={(e) => {
									editWeightFee(e);
								}}
							/>,
							<InputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={smogCheckDate}
								name="smogCheckDate"
								label="Smog Check Date"
								type="date"
								onChange={(e) => {
									editSmogCheckDate(e.target.value);
								}}
							/>,

							<SelectorElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								name="isSmogCheck"
								label="Smog Check"
								value={isSmogCheck}
								onChange={(e) => editIsSmogCheck(e.target.value)}
								options={smogCheck}
							/>,
							<InputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								value={reportOfSale}
								name="reportOfSale"
								label="Report Of Sale"
								type="text"
								onChange={(e) => {
									editReportOfSale(e.target.value);
								}}
							/>,
							<FormGroup className="col-lg-3 pt-2">
								{/* <CheckBoxElement
									{...methods}
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									checked={isDraft}
									name="isDraft"
									onChange={() => editIsDraft(!isDraft)}
									label="Is Draft?"
									labelClass="pl-4"
								/> */}
							</FormGroup>,
							<FormGroup className="col">
								<InputElement
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									{...methods}
									wholeRow={true}
									value={description}
									name="description"
									label="Description"
									type="textarea"
									onChange={(e) => {
										editDescription(e.target.value);
									}}
								/>
							</FormGroup>,
						]}
					/>
				</Form>
				<InventoryGpsInformation />
				<Col>
					<InventorySpecificEquipment specificationEquip={specificationEquip} />
				</Col>
				<Col>
					<InventoryStandardEquipment standardEquip={standardEquip} />
				</Col>
				<Col>
					<Card>
						<CardHeader>
							<CardTitle tag="h3">Optional Equipment</CardTitle>
						</CardHeader>
						<Col>
							<CardBody>
								<Col>
									<FormGroup>
										<InventoryOptionalEquipment
											changeHandler={editSelectedEquip}
											optionalEquip={optionalEquip}
											selectedEquip={selectedEquip}
											readOnly={
												status === vehicleStatus.SOLD ||
												status === vehicleStatus.DELETED
											}
										/>
									</FormGroup>
								</Col>
							</CardBody>
						</Col>
						<Col md="2" sm="2" xs="2">
							<SiriusXMButton />
						</Col>
					</Card>
				</Col>
				<Col>
					<Card>
						<CardHeader>
							<CardTitle tag="h3">Custom Equipment</CardTitle>
						</CardHeader>
						<Col>
							<InventoryCustomEquipment
								changeHandler={editCustomEquip}
								selectedEquip={customEquip}
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
							/>
						</Col>
					</Card>
				</Col>

				<hr />
				<div className="w-100">
					<div className="mx-0 pb-3">
						<div className="text-center">
							<div>
								{!hideDelete && (
									<InventoryDeleteButton isDeal={isDeal} redirect={true} />
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default InventoryDetailsTab;
