import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import history from "helperFunction/History";
import Dev from "layout/Dev.js";
import DevLogin from "view/login/DevLogin.js";
import Login from "./view/login/Login.js";
import NewUser from "./view/login/new-user/NewUser";
import PrivateRoute from "PrivateRoute.js";
import Public from "layout/Public.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "assets/css/paper-dashboard.css";
import "assets/css/custom.css";
import "assets/css/dark.css";
import "assets/css/error.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import ForgottenPassword from "view/login/forgettenPassword/ForgottenPassword";
import BackDoorLoginForm from "view/login/BackDoorLoginForm";
import CustomerUploadForm from "./view/login/CustomerUploadForm";
import FormGen from "view/formValidation/FormGen";

// Disable the console logs statements in production
if (process.env.NODE_ENV !== "development") {
	console.log = () => {};
}

const App = () => {
	return (
		<Router history={history}>
			<Switch>
				<Redirect exact strict from="/" to="/login" />
				<Route
					exact
					path="/dev/login"
					render={(props) => <DevLogin {...props} />}
				/>
				<PrivateRoute
					devRoute={true}
					path="/dev"
					render={(props) => <Dev {...props} />}
				/>
				<Route exact path="/login" render={(props) => <Login {...props} />} />
				<Route
					exact
					path="/new-user"
					render={(props) => <NewUser {...props} />}
				/>
				<Route
					exact
					path="/forgotten-password"
					render={(props) => <ForgottenPassword {...props} />}
				/>
				<Route
					exact
					path="/backdoor_login"
					render={(props) => <BackDoorLoginForm {...props} />}
				/>
				<Route
					exact
					path="/formgen"
					render={(props) => <FormGen {...props} />}
				/>
				<Route
					exact
					path="/external-upload"
					render={(props) => <CustomerUploadForm {...props} />}
				/>
				<PrivateRoute
					devRoute={false}
					path="/"
					render={(props) => <Public {...props} />}
				/>
			</Switch>
		</Router>
	);
};

export default App;
