import React from "react";
import { Card, CardBody, Col, Row, Input, Label, Form } from "reactstrap";
import DealInventoryHeader from "../../component/dc/deal/inventory/DealInventoryHeader";
import Sale from "../../component/dc/deal/sale/Sale";
import Summary from "../../component/dc/deal/Summary";
import TermsTable from "../../component/dc/deal/paymentOptions/TermsTable";
//import useCurrentRecapStore from "../../store/RecapStore";

import { DealStatus } from "../../Constants";
import { DeleteButton } from "../../component/dc/deal/button/DealButton";
import LenderDetail from "../../component/dc/deal/dealDetails/LenderDetail";
import useCurrentDealStore from "../../store/DealStore";
import useCurrentSaleStore from "../../store/SaleStore";

import DealSalesmanDropDown from "../../component/dc/deal/dealDetails/DealSalesmanDropdown";

import { SaleType } from "../../Constants.js";
import tabOnEnter from "../../helperFunction/tabOnEnter";

import DealBuyerSwitchButton from "./DealBuyerSwitchButton";
import shallow from "zustand/shallow";

export const DealContent = () => {
	//const textStyle = { fontSize: "1.2rem" };

	// const [showProfit, setShowProfit] = useState(false);

	// const toggle = () => {
	// 	setShowProfit(!showProfit);
	// };

	// this was getting trade stuff before
	const { type, ID } = useCurrentDealStore(
		(state) => ({
			ID: state.deal.ID,
			carRecNum: state.deal.carRecNum,
			type: state.deal.type,
		}),
		shallow
	);

	const {
		loanDate,
		editLoanDate,
		dealNumber,
		editDealNumber,
		saleType,

		//editSaleType,
	} = useCurrentSaleStore(
		(state) => ({
			loanDate: state.loanDate,
			editLoanDate: state.editLoanDate,
			dealNumber: state.dealNumber,
			editDealNumber: state.editDealNumber,
			saleType: state.saleType,

			//		editSaleType: state.editSaleType,
		}),
		shallow
	);
	const typeOfSale = SaleType[saleType].label;
	console.log(saleType);
	return (
		<>
			<Row className="">
				<div className="col-md-4">
					<Col className="text-center px-0">
						<DealBuyerSwitchButton saleType={saleType} />
					</Col>
				</div>
				<div className="col-md-4">
					<Card className="pb-2">
						<CardBody>
							<DealInventoryHeader />
						</CardBody>
					</Card>
				</div>
				<div className="col-md-4">
					<Card className="py-2">
						<CardBody>
							<Col className="pb-3  px-0">
								<button
									type="button"
									className="active px-1 btn-round btn-md btn btn-outline-secondary w-100 my-0"
								>
									{typeOfSale} {type}
								</button>
							</Col>
							<Form>
								<Row className="mx-0 pt-0" onKeyDown={tabOnEnter}>
									<div className="col-xl-6 mb-2">
										<Label>Deal Number</Label>
										<Input
											className="bold"
											readOnly={type !== DealStatus.PENDING}
											type="text"
											name="dealNumber"
											value={dealNumber}
											onChange={(e) => editDealNumber(e.target.value)}
										/>
									</div>
									<div className="col-xl-6 mb-2">
										<Label>Date of Sale</Label>
										<Input
											className="bold"
											readOnly={type !== DealStatus.PENDING}
											type="date"
											name="dealLoanDate"
											value={loanDate}
											onChange={(e) => editLoanDate(e.target.value)}
										/>
									</div>
									<div className="col-xl-12 mb-2">
										<LenderDetail />
									</div>
									<div className="col-xl-12 mb-2">
										<DealSalesmanDropDown />
									</div>
									{/* <Col>
										<Label>Type of Deal</Label>
										<CardText>
											<Input disabled value={typeOfSale} />
										</CardText>
									</Col> */}
								</Row>
							</Form>
						</CardBody>
					</Card>
				</div>
			</Row>

			<Row className="pt-2">
				<div className="col-md-4">
					<Card>
						<Sale />
					</Card>
				</div>
				<div className="col-md-4">
					<Card>
						<TermsTable />
					</Card>
				</div>
				<div className="col-md-4">
					<Card>
						<Summary />
					</Card>
				</div>
			</Row>
			<div className="text-center mt-5 pt-5">
				<hr />
				{ID > 0 && <DeleteButton />}
			</div>
		</>
	);
};
