//* Coded by DealerClick Software *Peyman*))

import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "reactstrap";
import Credit700Table from "./Credit700Table";
import {
	submitCreditCheck,
	submitPrescreen,
} from "../../../../api/dealerIntegrationsApi";
import useCurrentCustomerStore from "store/CustomerStore";
import { failAlert, successAlertCustom } from "component/dc/modal/SweetAlerts";
import { credit700Bureaus, credit700Products } from "../../../../Constants";
import shallow from "zustand/shallow";
import { getAllCustomerInfo } from "api/CustomerAPI";
import { showApiError } from "helperFunction/ApiErrorHandler";
import { retrieveFailAlert } from "component/dc/modal/SweetAlerts";
import Experian from "assets/img/experianLogo.png";
import Equifax from "assets/img/equifaxLogo.png";
import Transunion from "assets/img/transUnionLogo.png";

const Customer700CreditTab = ({ bureau, id }) => {
	const CreditListHeader = [
		"#",
		"BUREAU",
		"Type",
		"Score",
		"Date",
		"Description",
	];
	const [selectedOption, setSelectedOption] = useState(bureau);

	const {
		credit700Hist,
		editCredit700Hist,
		setCustomerFields,
		firstName,
		lastName,
	} = useCurrentCustomerStore(
		(state) => ({
			credit700Hist: state.credit700Hist,
			editCredit700Hist: state.editCredit700Hist,
			setCustomerFields: state.setCustomerFields,
			firstName: state.firstName,
			lastName: state.lastName,
		}),
		shallow
	);

	const commitData = (productType) => {
		let apiCall = () => {};
		if (productType === credit700Products.credit) {
			apiCall = submitCreditCheck;
		} else if (productType === credit700Products.prescreen) {
			apiCall = submitPrescreen;
		}
		apiCall(id, selectedOption).then(
			(response) => {
				console.log(response);
				const arr = [...credit700Hist];
				arr[arr.length] = response.data.content;
				editCredit700Hist(arr);
				successAlertCustom(response.data.message);
			},
			(error) => {
				console.log(error.response);
				failAlert(error.response.data.message);
			}
		);
	};
	const updateStore = (data) => {
		console.log(data);
		setCustomerFields(data.customer);
		editCredit700Hist(data.credit700);
	};
	const getData = (currentID) => {
		if (currentID != null) {
			getAllCustomerInfo(currentID).then(
				(res) => updateStore(res.data.content),
				(err) => {
					console.log(err);

					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}
	};

	useEffect(() => {
		getData(id);
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<Card>
				<div className="text-center mb-2">
					{/* {<img src={Credit} alt="700credit" className="rounded mb-3 pt-3" />} */}
					<h3>
						{firstName} {lastName}
					</h3>
				</div>
				<form>
					<div className="radio bg-light pb-2 pt-3">
						<Row className="px-0 text-center">
							<Col>
								<label className="col-xl-6">
									<input
										type="radio"
										value={credit700Bureaus.transUnion}
										checked={selectedOption === credit700Bureaus.transUnion}
										onChange={(e) => {
											setSelectedOption(e.target.value);
										}}
									/>
									<img src={Transunion} alt="transunion" className="rounded " />
								</label>
							</Col>
							<Col>
								<label className="col-xl-6">
									<input
										type="radio"
										value={credit700Bureaus.experian}
										checked={selectedOption === credit700Bureaus.experian}
										onChange={(e) => {
											setSelectedOption(e.target.value);
										}}
										src={Experian}
									/>
									<img src={Experian} alt="Experian" className="rounded" />
								</label>
							</Col>
							<Col>
								<label className="col-xl-6">
									<input
										type="radio"
										value={credit700Bureaus.equifax}
										checked={selectedOption === credit700Bureaus.equifax}
										onChange={(e) => {
											setSelectedOption(e.target.value);
										}}
										src={Equifax}
									/>
									<img src={Equifax} alt="Equifax" className="rounded" />
								</label>
							</Col>
						</Row>
					</div>
					<Row className="px-0 text-center my-3">
						<div className="col-6">
							<Button
								className="pull-right"
								color="primary"
								onClick={() => {
									console.log(selectedOption);
									commitData(credit700Products.prescreen);
								}}
							>
								PRESCREEN (SOFT-PULL)
							</Button>
						</div>
						<div className="col-6">
							<Button
								className="pull-left"
								color="primary"
								onClick={() => {
									console.log(selectedOption);
									commitData(credit700Products.credit);
								}}
							>
								FULL CREDIT REPORT (Hard PULL)
							</Button>
						</div>
					</Row>
					<Credit700Table head={CreditListHeader} />
				</form>
			</Card>
		</>
	);
};
export default Customer700CreditTab;
