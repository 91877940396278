import React, { useState } from "react";
import { Button, CardTitle, FormGroup, Input, Label } from "reactstrap";

import CenteredLoadingSpinner from "component/dc/load/CenteredLoadingSpinner";
import DigitzsTransactionInputField from "./DigitzsTransactionInputField";
import DigitzsTransactionNumberField from "./DigitzsTransactionNumberField";

import { twoOptionsAlert } from "component/dc/modal/SweetAlerts";
import { refundDigitzsTransaction } from "api/PaymentsAPI";
import { successAlertCustom } from "component/dc/modal/SweetAlerts";
import { failAlert } from "component/dc/modal/SweetAlerts";

import dayjs from "dayjs";

import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

const DigitzsTransactionDetails = ({
	toggle,
	data,
	payments,
	editPayments,
	showProgress,
}) => {
	// Local states
	const [miscData, setMiscData] = useState("");
	const [showRefundProgress, setShowRefundProgress] = useState(false);

	// Updates the payments table to show this specific object has been refunded
	const updatePaymentsTable = () => {
		const updatedPayments = payments.map((payment) => {
			if (payment.transactionId === data.id) {
				payment.isRefunded = true;
			}

			return payment;
		});

		editPayments(updatedPayments);
	};

	// If user confirms they want to refund a payment, continues to
	// call Digitzs API for process refund
	const refundPayment = async () => {
		const didConfirm = await twoOptionsAlert(
			"Are you sure you want to refund this payment?",
			"Yes",
			"Cancel"
		);

		if (didConfirm === "Yes") {
			setShowRefundProgress(true);

			const refundData = {
				miscData: miscData,
				paymentType: "cardRefund",
				merchantId: data.merchantId,
				transaction: {
					amount: data.transaction.amount,
					currency: data.transaction.currency,
					invoice: data.transaction.invoice,
				},
				originalTransaction: {
					id: data.id,
				},
			};

			refundDigitzsTransaction(refundData).then(
				(res) => {
					setShowRefundProgress(false);
					updatePaymentsTable();
					toggle();
					successAlertCustom(res.data.message);
				},
				(err) => {
					setShowRefundProgress(false);
					failAlert(err?.response?.data?.message || "Failed to refund payment");
				}
			);
		}
	};

	// Validation schema
	const validations = object().shape({
		miscData: string().required().max(50),
	});

	// React hook forms validation methods
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(validations),
	});

	return (
		<div>
			{(showProgress && <CenteredLoadingSpinner />) ||
				(showRefundProgress && <CenteredLoadingSpinner />) ||
				(data != null && (
					<div className="row">
						<CardTitle tag="h3" className="col-12 mb-3">
							Transaction Information
						</CardTitle>
						<FormGroup className="col-lg-3">
							<Label>Transaction Date</Label>
							<Input
								readOnly={true}
								value={dayjs(data.created).format("MMM D, YYYY [at] h:mm: a")}
							/>
						</FormGroup>
						<DigitzsTransactionNumberField
							prefix="$"
							label="Gross"
							value={data.transaction.gross / 100}
						/>
						<DigitzsTransactionNumberField
							prefix="$"
							label="Net"
							value={data.transaction.net / 100}
						/>
						<DigitzsTransactionNumberField
							prefix="$"
							label="Fee"
							value={data.transaction.fee / 100}
						/>
						<DigitzsTransactionNumberField
							prefix="$"
							label="Gross - Net"
							value={data.transaction.grossMinusNet / 100}
						/>
						<DigitzsTransactionNumberField
							suffix="%"
							label="Rate"
							value={data.transaction.rate}
						/>
						<CardTitle tag="h3" className="col-12 my-3">
							Customer Billing Information
						</CardTitle>
						<DigitzsTransactionInputField
							label="Customer Name"
							value={data.card.holder}
						/>
						<DigitzsTransactionInputField
							label="Card Number"
							value={`XXXX XXXX XXXX ${data.card.number.substring(
								data.card.number.length - 4
							)}`}
						/>
						<DigitzsTransactionInputField
							label="Billing Address"
							value={`${data.billingAddress.line1} ${data.billingAddress.line2}`}
						/>
						<DigitzsTransactionInputField
							label="Billing City"
							value={data.billingAddress.city}
						/>
						<DigitzsTransactionInputField
							label="Billing State"
							value={data.billingAddress.state}
						/>
						<DigitzsTransactionInputField
							label="Billing Zip"
							value={data.billingAddress.zip}
						/>
						<DigitzsTransactionInputField
							colSize="col-lg-12"
							type="textarea"
							label="Additional Information"
							value={data.miscData}
						/>
						{!data.isRefunded && (
							<FormGroup className="col-12">
								<Label>Reason for Refund</Label>
								<Input
									innerRef={register}
									name="miscData"
									type="textarea"
									invalid={errors["miscData"] ? true : false}
									value={miscData}
									onChange={(e) => setMiscData(e.target.value)}
								/>
								{errors["miscData"] && <p className="error_text">Required</p>}
							</FormGroup>
						)}
						{data.isRefunded && (
							<p style={{ color: "red" }} className="col-12 mt-2 mb-3">
								*This payment has been refunded
							</p>
						)}
						<div className="col-12 d-flex justify-content-between mt-3">
							<Button onClick={toggle}>Close</Button>
							<Button
								onClick={handleSubmit(refundPayment)}
								readOnly={data.isRefunded}
								color="secondary"
							>
								Refund
							</Button>
						</div>
					</div>
				))}
		</div>
	);
};

export default DigitzsTransactionDetails;
