import React, { useState, useEffect } from "react";
import { Table, Button } from "reactstrap";

import NumberFormat from "react-number-format";

import { getVendorListByType, deleteVendorByHidden } from "api/VendorsAPI";
import { retrieveFailAlert } from "../modal/SweetAlerts";
import { scrollTop } from "../button/ScrollToTop";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";
import { useFromUser } from "store/LocalStorageHelper";
import {
	confirmDeleteAlert,
	deleteSuccessAlert,
	deleteFailAlert,
} from "component/dc/modal/SweetAlerts";
import CenteredLoadingSpinner from "../load/CenteredLoadingSpinner";
import ChildrenModal from "../modal/ChildrenModal";
import TwilioSendMessage from "../integration/twilio/TwilioSendMessage";

const VendorsTable = ({
	refresh,
	type,
	editFields,
	setRefresh,
	toggle,
	showChat,
}) => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	const locationID = useFromUser("locationID");
	const [leadID, setLeadID] = useState(null);
	const [textHistoryNumber, setTextHistoryNumber] = useState();
	const toggleViewTextModal = () => setViewTextModal(!viewTextModal);
	const [viewTextModal, setViewTextModal] = useState(false);

	const getData = () => {
		console.log(type);
		getVendorListByType(locationID, type).then(
			(res) => {
				console.log(type);
				console.log(res.data.content);
				setData(res.data.content);
				setLoading(false);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	const removeVendor = (ID) => {
		console.log("Removing Cost with ID: " + ID);

		deleteVendorByHidden(ID).then(
			(res) => {
				setRefresh(true);
				setRefresh(false);
				console.log(res.data.content);
				deleteSuccessAlert();
			},
			(error) => {
				console.log(error);
				deleteFailAlert();
			}
		);
	};

	// Modal
	const toggleModal = (currentID) => {
		confirmDeleteAlert(removeVendor, currentID);
	};

	useEffect(() => {
		console.log(type);
		getData();
		// eslint-disable-next-line
	}, [type, refresh]);

	const tableData = data.map((obj, index) => (
		<>
			<tr key={index}>
				<th scope="row">{index + 1}</th>
				<td>{obj.name}</td>
				<td>{obj.contact1}</td>
				<td>
					<NumberFormat
						value={obj.phone1}
						format="+1 (###) ###-####"
						isNumericString={true}
						displayType="text"
					/>
				</td>
				{/* <td>{obj.street}</td> */}
				<td>{obj.city}</td>
				<td>{obj.state}</td>
				<td>{obj.zip}</td>
				<td>{obj.email}</td>
				{/* <td>{obj.website}</td> */}
				<td>
					{obj.vendorType !== 31 && !showChat && (
						<>
							<Button
								color="primary"
								onClick={() => {
									console.log(obj);
									editFields.setID(obj.ID);
									editFields.setName(obj.name);
									editFields.setContact1(obj.contact1);
									editFields.setCity(obj.city);
									editFields.setPhone1(obj.phone1);
									editFields.setStreet(obj.street);
									editFields.setState(obj.state);
									editFields.setEmail(obj.email);
									editFields.setZip(obj.zip);
									editFields.setWebsite(obj.website);
									editFields.setLenderNo(obj.lenderNo);
									editFields.setUpdating(true);
									if (type === 70) {
										editFields.setLineOfCredit(obj.lineOfCredit || "");
									}
									if (type === 30) {
										editFields.setBankFee(obj.bankFee || "");
										editFields.setLenderFee(obj.lenderFee || "");
										editFields.setLateFee(obj.lateFee || "");
										editFields.setDiscountRate(obj.discountRate);
										editFields.setReserve(obj.reserve);
										editFields.setTaxId(obj.taxId);
										editFields.setsecurityID(obj.securityId);
										editFields.setDefault(obj.default_);
										editFields.setInHouse(obj.inHouse);
										editFields.setProfitOnly(obj.profitOnly);
										editFields.setDealerPart(obj.dealerPart);
										editFields.setNotes(obj.notes);
									}
									toggle();
									scrollTop();
								}}
							>
								<i className="nc-icon nc-align-center" /> Edit
							</Button>
							<Button
								className="btn-link-danger btn-link text-danger"
								onClick={() => {
									console.log(obj.ID + " " + index);
									toggleModal(obj.ID);
								}}
							>
								<i className="nc-icon nc-simple-remove" /> Remove
							</Button>
						</>
					)}
					{showChat && (
						<Button
							className="zoom btn-md btn btn-link btn-link-success"
							onClick={() => {
								console.log(obj);
								setTextHistoryNumber(obj.phone1 || "");
								setLeadID(obj.ID);
								toggleViewTextModal();
							}}
						>
							<i className="icon-big text-center nc-icon nc-chat-33 text-default" />
							<span className="noMobile"> Chat </span>
						</Button>
					)}
				</td>
			</tr>
		</>
	));

	return !loading ? (
		<>
			<ChildrenModal
				size="lg"
				modal={viewTextModal}
				toggle={toggleViewTextModal}
				modalTitle="Send Message"
			>
				<TwilioSendMessage
					sendToNumber={textHistoryNumber}
					customerID={leadID}
					personType="Lender"
				/>
			</ChildrenModal>
			<Table striped bordered hover>
				<thead>
					<tr>
						<th>#</th>
						<th>Name</th>
						<th>Contact</th>
						<th>Phone</th>
						{/* <th>Street</th> */}
						<th>City</th>
						<th>State</th>
						<th>Zip</th>
						<th>Email</th>
						{/* <th>Website</th> */}
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>{tableData}</tbody>
			</Table>
		</>
	) : (
		<>
			<CenteredLoadingSpinner />
		</>
	);
};

export default VendorsTable;
