import React from "react";
import { Form } from "reactstrap";

import useCurrentCustomerStore from "../../../../store/CustomerStore";
import InputElement from "../../../formValidation/InputElement";
import InputWrapper from "../../../formValidation/InputWrapper";

import { stateOptions } from "Constants";
import {
	PhoneInputElement,
	SelectorElement,
} from "../../../formValidation/InputElement";

import { useFormContext } from "react-hook-form";

import shallow from "zustand/shallow";

const CustomerLandlordForm = () => {
	// Customer store
	const {
		llCity,
		llName,
		llPhone,
		llState,
		llStreet,
		llUnit,
		llZip,
		editLlCity,
		editLlName,
		editLlPhone,
		editLlState,
		editLlStreet,
		editLlUnit,
		editLlZip,
	} = useCurrentCustomerStore(
		(state) => ({
			llCity: state.llCity,
			llName: state.llName,
			llPhone: state.llPhone,
			llState: state.llState,
			llStreet: state.llStreet,
			llUnit: state.llUnit,
			llZip: state.llZip,
			editLlCity: state.editLlCity,
			editLlName: state.editLlName,
			editLlPhone: state.editLlPhone,
			editLlState: state.editLlState,
			editLlStreet: state.editLlStreet,
			editLlUnit: state.editLlUnit,
			editLlZip: state.editLlZip,
		}),
		shallow
	);

	// Get validation context
	const methods = useFormContext();

	return (
		<Form>
			<InputWrapper
				formTitle="Landlord/Lender"
				inputComponents={[
					<InputElement
						{...methods}
						value={llName}
						onChange={(e) => editLlName(e.target.value)}
						name="llName"
						label="Name"
						type="text"
					/>,
					<InputElement
						{...methods}
						value={llStreet}
						onChange={(e) => editLlStreet(e.target.value)}
						name="llStreet"
						label="Street"
						type="text"
					/>,
					<InputElement
						{...methods}
						value={llUnit}
						onChange={(e) => editLlUnit(e.target.value)}
						name="llUnit"
						label="Apt/Unit/Bld"
						type="text"
					/>,
					<InputElement
						{...methods}
						value={llZip}
						onChange={(e) => editLlZip(e.target.value.replace(/[^\d]/g, ""))}
						name="llZip"
						label="Zip"
						type="text"
					/>,
					<InputElement
						{...methods}
						value={llCity}
						onChange={(e) => editLlCity(e.target.value)}
						name="llCity"
						label="City"
						type="text"
					/>,
					<SelectorElement
						{...methods}
						name="llState"
						label="State"
						value={llState}
						onChange={(e) => editLlState(e.target.value)}
						options={stateOptions}
					/>,
					<PhoneInputElement
						{...methods}
						value={llPhone}
						onChange={editLlPhone}
						name="llPhone"
						label="Phone Number"
					/>,
				]}
			/>
		</Form>
	);
};

export default CustomerLandlordForm;
