import React from "react";

import { failAlert } from "component/dc/modal/SweetAlerts";
import { useMultiImageData } from "./MultiImageContext";

// Component containing the button which calls a callback function
// for what to do with the images that are ready to be uploaded
const MultiImageUploadButton = ({ handleUpload, hideUploadButton }) => {
	// Multi image context
	const { imageKeys, croppedUploads, setHideCrop } = useMultiImageData();

	const upload = () => {
		if (Object.keys(croppedUploads).length !== imageKeys.length) {
			failAlert(
				"All images with the warning icon must be cropped to the required aspect ratio"
			);

			return;
		}

		setHideCrop(true);
		handleUpload();
	};

	return (
		<>
			{imageKeys.length > 0 && !hideUploadButton && (
				<div className="d-flex justify-content-center align-items-center col-12">
					<button className="multi-image-upload-button" onClick={upload}>
						Upload
					</button>
				</div>
			)}
		</>
	);
};

export default MultiImageUploadButton;
