import React, { useState } from "react";
import {
	Col,
	Row,
	Card,
	CardHeader,
	CardTitle,
	CardBody,
	Collapse,
} from "reactstrap";

const LenderWrapper = ({ children, cardTitle, cardId }) => {
	const [open, setOpen] = useState(true);

	return (
		<div
			aria-multiselectable={true}
			className="card-collapse"
			id="accordion"
			role="tablist"
		>
			<Card className="card-plain">
				<CardHeader role="tab">
					<a
						aria-expanded={open}
						data-parent="#accordion"
						data-toggle="collapse"
						href={cardId}
						onClick={(e) => {
							e.preventDefault();
							setOpen(!open);
						}}
					>
						<CardTitle tag="h4">
							{cardTitle}
							<i className="nc-icon nc-minimal-down" />
						</CardTitle>
					</a>
				</CardHeader>
				<Collapse role="tabpanel" isOpen={open}>
					<CardBody>
						<Col>
							<Row>{children}</Row>
						</Col>
					</CardBody>
				</Collapse>
			</Card>
		</div>
	);
};

export default LenderWrapper;
