import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";

import { useFromUser } from "../../../store/LocalStorageHelper";
import { getVehicleListByStatus } from "../../../api/InventoryAPI";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";
import { retrieveFailAlert } from "../modal/SweetAlerts";
import dayjs from "dayjs";
import Camera from "assets/img/imgPlaceholder.png";
import Loading from "component/dc/load/Loading";
import InventoryTable from "../table/InventoryTable";
import OpenModalButton from "../deal/OpenModalButton";
import ChildrenModal from "../modal/ChildrenModal";

const InventorySlelectList = ({ editCarRecNum }) => {
	const [addModal, setAddModal] = useState(false);
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const [data, setData] = useState([]);
	const [loadingList, setLoadingList] = useState(true);

	const toggleAdd = () => {
		console.log("toggle");
		console.log(addModal);
		setAddModal(!addModal);
	};

	const getListData = () => {
		console.log("ID: " + dealerID + " " + locationID);
		const status = "Available,Pending";
		if (dealerID !== "" && locationID !== "") {
			getVehicleListByStatus(dealerID, locationID, status).then(
				(response) => {
					console.log(response.data.content);
					const result = dataFormat(response.data.content);
					setData(result);
					setLoadingList(false);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}
	};
	const accessHandler = (vehicle) => {
		editCarRecNum(vehicle.inventoryID);
	};

	const dataFormat = (content) => {
		const container = Object.keys(content).map((key) => {
			let container = {};
			let thumbnail = content[key].thumbnail;

			container.thumbnail = (
				<img
					className="zoom"
					alt="vehicle"
					src={`${content[key].thumbnail}?lastmod=${Date.now()}`}
					onError={(e) => {
						e.target.onerror = null;
						e.target.src = Camera;
					}}
				/>
			);
			container.thumbnailURL = thumbnail;
			container.inventoryID = content[key].inventoryID;
			container.stockNo = content[key].stockNo;
			container.year = content[key].year;
			container.make = content[key].make;
			container.model = content[key].model;
			container.vin = content[key].vin;
			container.status = content[key].vehicleStatus;
			container.stockAndVin =
				content[key].stockNo +
				" " +
				dayjs(content[key].dateIn).format("MM/DD/YYYY");

			// const vehicle = [
			// 	content[key].year,
			// 	content[key].make,
			// 	content[key].model,
			// ];
			container.vehicle =
				content[key].year + " " + content[key].make + " " + content[key].model;
			container.vehicleSearch =
				container.vehicle + content[key].vin + content[key].license;
			container.downPmt = content[key].downPmt;
			//			container.vehicle = vehicle.join("\n");
			const toDay = new Date();
			const start = content[key].dateIn;
			container.start = dayjs(content[key].dateIn).format("MM/DD/YYYY");

			let end = content[key].dateOut === null ? toDay : content[key].dateOut;
			end = dayjs(end);
			if (content[key].dateOut === null) {
				container.end = "";
			} else {
				container.end = end.format("MM/DD/YYYY");
			}

			const age = end.diff(start, "day");
			container.age = age;

			container.askingPrice =
				"$" +
				parseFloat(content[key].price)
					.toFixed(2)
					.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

			container.Actions = (
				<>
					<Button
						className="zoom btn-md btn btn-link btn-link-success"
						onClick={() => {
							accessHandler(content[key]);
							console.log("before toggle");
							//toggleAdd();
							setAddModal(false);
							console.log("after toggle");
						}}
					>
						<i className="icon-big text-center nc-icon nc-briefcase-24" />
						<span className="noMobile"> Select </span>
					</Button>
				</>
			);
			return container;
		});
		return container;
	};
	const InventoryListData = () => {
		console.log(loadingList);
		return (
			<div className="">
				{loadingList && <Loading />}
				{!loadingList && <InventoryTable data={data} />}
			</div>
		);
	};

	useEffect(() => {
		setLoadingList(true);
		getListData();
		// eslint-disable-next-line
	}, []);

	return (
		<div>
			<OpenModalButton
				buttonClass="btn-md"
				buttonColor="primary"
				onClick={toggleAdd}
				modalTitle="Select Vehicle"
				buttonText="Select Vehicle from Inventory"
				icon={true}
				iconClass="nc-icon nc-tap-01"
			/>
			<ChildrenModal
				modal={addModal}
				toggle={toggleAdd}
				modalTitle="Select Vehicle"
				children={<InventoryListData />}
			/>
		</div>
	);
};

export default InventorySlelectList;
