import React from "react";
import { Row, Col } from "reactstrap";
import ReviewVehicle from "./ReviewVehicle";
import ReviewCustomer from "./ReviewCustomer";
import ReviewCash from "./ReviewCash";
import ReviewDown from "./ReviewDown";
import ReviewFinance from "./ReviewFinance";

const PrintReview = React.forwardRef((_props, ref) => {
	return (
		<div ref={ref}>
			<Row>
				<Col xs="6">
					<Row>
						<Col>
							<h5>
								<ReviewCustomer />
							</h5>
						</Col>
					</Row>
					<Row>
						<Col>
							<h5>
								<ReviewCash />
							</h5>
						</Col>
					</Row>

					<Row>
						<Col>
							<h5>
								<ReviewDown />
							</h5>
						</Col>
					</Row>
				</Col>
				<Col>
					<Row>
						<Col>
							<h5>
								<ReviewVehicle />
							</h5>
						</Col>
					</Row>
					<Row>
						<Col>
							<h5>
								<ReviewFinance />
							</h5>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
});

export default PrintReview;
