import React, { useContext, useEffect, useState } from "react";
import Recap from "../../component/dc/deal/recap/Recap";
import ReviewWrapper from "../../component/dc/deal/review/ReviewWrapper";
import ChildrenModal from "../../component/dc/modal/ChildrenModal";
import {
	SaveButton,
	TransferToFundedButton,
	TransferToSoldButton,
	UnwindButton,
} from "../../component/dc/deal/button/DealButton";
import AttachmentModal from "../../component/dc/attachment/AttachmentModal";
import Twilio from "../../component/dc/integration/twilio/Twilio";
import useCurrentDealStore from "../../store/DealStore";
import { Button } from "reactstrap";
import NotesButton from "../../component/dc/deal/statusBar/notes/NotesButton";
import shallow from "zustand/shallow";
import { DealStatus } from "Constants";
import VoidButton from "component/dc/deal/voidRepoStatus/VoidButton";
import useIsOwnerOrAdmin from "view/settings/settingsTabs/userManagement/useIsOwnerOrAdmin";
import MailgunEmail from "../../component/dc/integration/mailgun/MailgunEmail";
import { TemplateVariableContext } from "../../helperFunction/TemplateVariableContext";
import useBuildDeal from "../../helperFunction/deal/useBuildDeal";
import useSetDeal from "../../helperFunction/deal/useSetDeal";
import { saveDeal } from "../../api/DealAPI";
import {
	saveFailAlert,
	saveSuccessAlert,
} from "../../component/dc/modal/SweetAlerts";
import { showApiError } from "../../helperFunction/ApiErrorHandler";

const DealActionBar = () => {
	const isOwnerOrAdmin = useIsOwnerOrAdmin();
	const { setRequestDocs } = useContext(TemplateVariableContext);

	const customerPhoneNumber = useCurrentDealStore(
		(state) => state.buyer.cellPhone
	);

	const { type, ID, email } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
			ID: state.deal.ID,
			email: state.buyer.email,
		}),
		shallow
	);

	const [showRecap, setShowRecap] = useState(false);
	const toggleRecap = () => {
		setShowRecap(!showRecap);
	};
	const [showReview, setShowReview] = useState(false);
	const toggleReview = () => {
		setShowReview(!showReview);
	};

	useEffect(() => {
		setRequestDocs(true);
		// eslint-disable-next-line
	}, []);

	return (
		<div>
			<div className="action-bar pt-4 mt-5 mx-0 px-0">
				<div className="mx-auto">
					<div className="text-center">
						<Button
							className="btn-info btn-md onMobileScreen"
							onClick={toggleReview}
						>
							<i className="nc-icon nc-single-copy-04"></i> Review
						</Button>
						<ChildrenModal
							size="lg"
							modal={showReview}
							toggle={toggleReview}
							modalTitle={"Deal Review"}
							children={<ReviewWrapper toggle={toggleReview} />}
						/>
						<Button
							className="btn-info btn-md mx-1 onMobileScreen"
							onClick={toggleRecap}
						>
							<i className="nc-icon nc-single-copy-04"></i> Recap
						</Button>
						<AutoSaveWrapper
							children={
								<Twilio sendToNumber={customerPhoneNumber} dealID={ID} />
							}
						/>
						<AutoSaveWrapper
							children={<MailgunEmail customerEmail={email} />}
						/>
						<ChildrenModal
							modal={showRecap}
							toggle={toggleRecap}
							children={<Recap />}
						/>

						{type === DealStatus.FUNDED && isOwnerOrAdmin && <VoidButton />}
						{type === DealStatus.SOLD && <UnwindButton />}
						{type === DealStatus.PENDING && <TransferToSoldButton />}
						{type === DealStatus.SOLD && <TransferToFundedButton />}

						<AttachmentModal relatedID={ID} type="deal" />
						<NotesButton />
						<SaveButton />
					</div>
				</div>
			</div>
			<hr className="w-75 mt-3" />
		</div>
	);
};

export default DealActionBar;

// used to save the deal before rendering the component
const AutoSaveWrapper = ({ children }) => {
	const setDeal = useSetDeal();
	const getDeal = useBuildDeal();
	const save = (d) => {
		saveDeal(d).then(
			(res) => {
				setDeal(res);
				saveSuccessAlert();
			},
			(err) => {
				showApiError(
					err,
					saveFailAlert,
					"Deal failed to save... Accurate deal information will not be populated in text or email. Please add a buyer to save a valid deal."
				);
			}
		);
	};

	return (
		<span
			onClick={() => {
				const deal = getDeal();
				save(deal);
			}}
		>
			{children}
		</span>
	);
};
