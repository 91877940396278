import React, { useEffect, useState, useCallback, useContext } from "react";
import { Card, Button, CardBody } from "reactstrap";
import InputElement, {
	SelectorElement,
} from "../../../../view/formValidation/InputElement";

import { object, setLocale, string } from "yup";
import { yupResolver } from "@hookform/resolvers";
import { FormProvider, useForm } from "react-hook-form";
import { getTwilioMessageTemplates } from "../../../../api/TwilioApi";
import { useFromUser } from "../../../../store/LocalStorageHelper";
import { WizardButtons } from "../../modal/DynamicModal";
import { getVendorListByType } from "api/VendorsAPI";
import { vendorTypesOBJ } from "Constants";
import { MessageTemplateVariablesAndInput } from "../../../../view/communication/template/MessageTemplateVariables";
import { FormsEmailContext } from "../../formsReports/FormsEmailContext";

const SendEmailInWizard = ({ submit }) => {
	const locationID = useFromUser("locationID");
	const IN_DEVELOPMENT = process.env.NODE_ENV === "development";
	// Local states
	const [templates, setTemplates] = useState([]);
	const [templateOptions, setTemplateOptions] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [lenderList, setlenderList] = useState([]);
	const [lenderOption, setlenderOption] = useState([]);
	const [lenderValue, setlenderValue] = useState(null);

	const { emailRequest, setEmailRequest } = useContext(FormsEmailContext);
	const updateEmailRequest = (value, field) => {
		setEmailRequest((prevState) => {
			return { ...prevState, [field]: value };
		});
	};

	//build Lender dropdown
	const getLendersList = () => {
		getVendorListByType(
			locationID,
			vendorTypesOBJ.DEALERCLICK_LENDER.value
		).then((res) => {
			const lenders = res.data.content;
			let option = [];
			let list = {};

			for (const key in lenders) {
				option.push({
					label: lenders[key].name,
					value: lenders[key].ID,
				});
				list[lenders[key].ID] = { ...lenders[key] };
			}
			if (lenders.length === 0) option.push({ label: "None", value: null });
			setlenderOption(option);
			setlenderList(list);
		});
	};

	const getTemplates = useCallback(() => {
		getTwilioMessageTemplates(locationID).then(
			(res) => {
				const data = res.data.content;
				const options = [];
				let templateList = {};

				for (let i = 0; i < data.length; i++) {
					const option = {
						label: data[i].name,
						value: data[i].ID,
					};

					options.push(option);
					templateList[data[i].ID] = { ...data[i] };
				}

				setTemplateOptions(options);
				setTemplates(templateList);
			},
			(err) => console.log(err)
		);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		getTemplates();
		getLendersList();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (selectedTemplate != null) {
			updateEmailRequest(templates?.[selectedTemplate]?.body || "", "body");
			updateEmailRequest(
				templates?.[selectedTemplate]?.subject || "",
				"subject"
			);
		}
		// eslint-disable-next-line
	}, [selectedTemplate]);

	useEffect(() => {
		if (lenderValue != null) {
			updateEmailRequest(lenderList?.[lenderValue]?.email || "", "to");
		}
		// eslint-disable-next-line
	}, [lenderValue]);

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
	});

	// Define rules for each input field
	const schema = object().shape({
		mailgunFromEmail: string().required(
			"If missing, update in the Settings page"
		),
		mailgunToEmail: string().required(),
		// messageBody: string().required(),
		// mailgunFileName: string().matches(/^.|\/|\\/, {
		// 	message:
		// 		"Attachment filename cannot contain any of the following characters: '.', '/', '\\'",
		// }),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<FormProvider {...methods}>
			<Card>
				<CardBody>
					<div className="row row-12 mt-2">
						<SelectorElement
							{...methods}
							colSize="col-lg-6"
							value={lenderValue}
							name="lender"
							label="Lender"
							options={lenderOption}
							onChange={(e) => {
								setlenderValue(e.target.value);
							}}
						/>
						<div className="col-12"></div>
						<InputElement
							{...methods}
							readOnly
							colSize="col-lg-6"
							value={emailRequest.from}
							name="mailgunFromEmail"
							label="Your Email"
						/>

						<InputElement
							{...methods}
							colSize="col-lg-6"
							value={emailRequest.to}
							onChange={(e) => {
								updateEmailRequest(e.target.value, "to");
							}}
							name="mailgunToEmail"
							label="Email To"
						/>
						<InputElement
							{...methods}
							colSize="col-lg-6"
							value={emailRequest.cc}
							onChange={(e) => {
								updateEmailRequest(e.target.value, "cc");
							}}
							name="cc"
							label="Cc (Mutiple Emails separated by comma)"
						/>
						<InputElement
							{...methods}
							colSize="col-lg-6"
							value={emailRequest.bcc}
							onChange={(e) => {
								updateEmailRequest(e.target.value, "bcc");
							}}
							name="bcc"
							label="Bcc (Mutiple Emails separated by comma)"
						/>
						{templateOptions.length > 0 && (
							<SelectorElement
								{...methods}
								colSize="col-lg-6"
								value={selectedTemplate}
								name="twilioMessageTemplateOptions"
								label="Message Template"
								onChange={(e) => setSelectedTemplate(e.target.value)}
								options={templateOptions}
							/>
						)}
					</div>
					<div className="row row-12">
						<InputElement
							{...methods}
							colSize="col-lg-12"
							value={emailRequest.subject}
							onChange={(e) => updateEmailRequest(e.target.value, "subject")}
							name="mailgunSubject"
							label="Subject"
						/>
					</div>
					<MessageTemplateVariablesAndInput
						body={emailRequest.body}
						updateBody={updateEmailRequest}
						field="body"
					/>
					{IN_DEVELOPMENT && (
						<Button
							onClick={() => {
								console.log({
									...emailRequest,
								});
							}}
						>
							JSON
						</Button>
					)}
					<WizardButtons submit={submit} />
				</CardBody>
			</Card>
		</FormProvider>
	);
};

export default SendEmailInWizard;
