import getAxiosInstance from "./interceptors/buildReqAndResInstance";

import { userBaseUrl } from "./Constants";

const instance = getAxiosInstance({
	baseURL: userBaseUrl,
});

export const getTrades = async (dealID) => {
	return await instance.get(`/deal/trades/${dealID}`);
};

export const saveTrades = async (trade) => {
	return await instance.post(`/deal/trades/save`, trade);
};

export const deleteTrades = async (tradeID, inventoryRecNum) => {
	return await instance.post(
		`/deal/trades/delete/${tradeID}/${inventoryRecNum}`,
		null
	);
};
