import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
	Card,
	CardBody,
	Form,
	InputGroup,
	Container,
	CardHeader,
	CardTitle,
	Col,
} from "reactstrap";

import Footer from "layout/Footer.js";

import { failAlert } from "component/dc/modal/SweetAlerts";
import { login } from "../../auth/Authentication";
import { loginFailAlert } from "component/dc/modal/SweetAlerts";
import { PATHS } from "Constants";
import { getBackDoorUserDetails } from "api/LoginAPI";
// import { getUserDetails } from "api/LoginAPI";
// import { successAlertCustom } from "component/dc/modal/SweetAlerts";
// import { showApiError } from "helperFunction/ApiErrorHandler";
// import { retrieveFailAlert } from "component/dc/modal/SweetAlerts";

const BackDoorLoginForm = () => {
	const location = useLocation();

	const params = new URLSearchParams(location.search);
	const dealerid = params.get("dealerid");
	const locationid = params.get("locationid");
	const suporterEmail = params.get("email");

	const [dealerID, setDealerID] = useState(dealerid);
	const [locationID, setLocationID] = useState(locationid);
	const [password, setPassword] = useState("");
	const [email, setEmail] = useState(suporterEmail);
	const [username, setUsername] = useState(suporterEmail);
	const history = useHistory();

	const handleSubmitClick = (e) => {
		e.preventDefault();
		getBackDoorUserDetails({
			dealerID,
			locationID,
			username,
			password,
		}).then(
			(res) => {
				if (res.status === 200) {
					let resp = res.data.content;

					const data = {
						...resp.dcLogin,
						locations: resp.locations,
						dealers: resp.dealers,
						jws: resp.jws,
						profileData: resp.profileData,
						messagingNumber: resp.phoneNumber,
					};

					login(JSON.stringify(data));

					if (resp.dcLogin.lastLoginDate === null) {
						history.push(PATHS.UPDATE_PASSWORD);
					} else {
						history.push(PATHS.DASHBOARD);
					}
				}
			},
			(error) => {
				if (error?.response?.data?.message) {
					failAlert(error.response.data.message);
				} else {
					loginFailAlert();
				}
			}
		);
	};
	useEffect(() => {
		setDealerID(dealerid);
		setLocationID(locationid);
		setEmail(suporterEmail);
		setUsername(suporterEmail);
	}, [dealerid, locationid, suporterEmail]);

	return (
		<>
			<div className="login-page">
				<Container className="py-5">
					<Col className="ml-auto mr-auto vertical-align" lg="4" md="6">
						<Form action="" className="form mt-10" method="">
							<Card className="card-login">
								<CardHeader>
									<CardTitle>
										<h3 className="text-center">BackDoor Login</h3>
									</CardTitle>
								</CardHeader>

								<CardBody className="px-4">
									<InputGroup className="mt-2">
										<input
											type="text"
											className="form-control"
											id="dealerID"
											placeholder="Dealer ID"
											value={dealerID}
											onChange={(e) => setDealerID(e.target.value)}
										/>
									</InputGroup>
									<InputGroup className="mt-2">
										<input
											type="text"
											className="form-control"
											id="locationID"
											placeholder="Location ID"
											value={locationID}
											onChange={(e) => setLocationID(e.target.value)}
										/>
									</InputGroup>
									<InputGroup className="mt-2">
										<input
											type="text"
											className="form-control"
											id="email"
											placeholder="Email"
											value={email}
											onChange={(e) => {
												setEmail(e.target.value);
												setUsername(e.target.value);
											}}
										/>
									</InputGroup>
									<InputGroup className="mt-2">
										<input
											type="text"
											className="form-control"
											id="password"
											placeholder="Password"
											value={password}
											onChange={(e) => setPassword(e.target.value)}
										/>
									</InputGroup>

									<div className="text-center mt-3">
										<div
											type="submit"
											className="btn btn-primary mx-auto"
											onClick={handleSubmitClick}
										>
											<i className="nc-icon nc-spaceship" /> Log In
										</div>
									</div>
									<hr className="mt-2 pb-2" />

									{/* <div
										type="submit"
										className="button-link"
										onClick={backDoorPass}
									>
										Request Password
									</div> */}
								</CardBody>
							</Card>
						</Form>
					</Col>
				</Container>
			</div>
			<Footer />
		</>
	);
};

export default BackDoorLoginForm;
