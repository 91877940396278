import React, { useContext } from "react";

import useCurrentVehicleStore from "../../../store/InventoryStore";
import useRoute from "../../../helperFunction/UseRoute";
import useCurrentDealStore from "../../../store/DealStore";
import useBuildVehicle from "../../../helperFunction/BuildInventoryObj";

import { saveFailAlert } from "../modal/SweetAlerts";
import {
	saveNewVehicleWithImages,
	saveVehicle,
} from "../../../api/InventoryAPI";
import {
	saveSuccessAlert,
	failAlert,
} from "../../../component/dc/modal/SweetAlerts";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useMultiImageData } from "../image/multi/MultiImageContext";
import { PATHS } from "../../../Constants";

import shallow from "zustand/shallow";
import { KbbContext } from "../../../view/inventory/inventoryDetailTab/value/kbb/KbbContext";
import { useSetInventory } from "./useSaveInventory";

export const InventorySaveButton = ({ toggle = false }) => {
	// Vehicle obj
	const getVehicle = useBuildVehicle();
	const setVehicle = useSetInventory();

	// Deal store
	const { vehicle, editVehicle } = useCurrentDealStore(
		(state) => ({ vehicle: state.vehicle, editVehicle: state.editVehicle }),
		shallow
	);

	const { getDcKbbObj } = useContext(KbbContext);

	// Saves a vehicle without any images (can be new or existing)
	const saveData = () => {
		const inventoryVehicle = getVehicle();
		console.log(inventoryVehicle);
		const kbb = getDcKbbObj();
		const request = {
			vehicle: inventoryVehicle,
			kbb: kbb,
		};
		saveVehicle(request).then(
			(response) => {
				console.log(response);
				setVehicle(response);
				if (vehicle.inventoryID != null) {
					const v = response.data.content.vehicle;
					editVehicle({ ...v });
				}

				saveSuccessAlert();
				if (toggle) {
					toggle();
				}
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	const methods = useFormContext();

	return (
		<>
			<div
				class="btn btn-lg btn-success float-button"
				onClick={methods.handleSubmit(
					() => {
						saveData();
					},
					(err) => {
						console.log(err);
					}
				)}
			>
				<i class="nc-icon nc-check-2"></i>&nbsp;Save
			</div>
		</>
	);
};

export default InventorySaveButton;

export const NewInventorySaveButton = () => {
	// Multi image context
	const { imageKeys, croppedUploads } = useMultiImageData();
	// Vehicle obj
	const getVehicle = useBuildVehicle();
	const history = useHistory();
	const newRoute = useRoute(PATHS.INVENTORY_DETAIL);
	// Vehicle store
	const { editInventoryID } = useCurrentVehicleStore((state) => state, shallow);
	// Adds the images and vehicle save object to a FormData and then calls api to save
	// new vehicle with image using the FormData obj
	const saveVehicleWithImage = () => {
		const formData = new FormData();
		const croppedKeys = Object.keys(croppedUploads);
		const inventoryVehicle = getVehicle();
		// Add image files to payload
		if (imageKeys.length > 0) {
			// Check all images are cropped to aspect ratio
			if (croppedKeys.length !== imageKeys.length) {
				failAlert(
					"All images with the warning icon must be cropped to the required aspect ratio"
				);

				return;
			}

			const imageFilesToArray = Object.values(croppedUploads);

			for (const val of imageFilesToArray) {
				formData.append("imageFiles", val);
			}
		}

		formData.append(
			"vehicle",
			new Blob([JSON.stringify(inventoryVehicle)], {
				type: "application/json",
			})
		);

		saveNewVehicleWithImages(formData).then(
			(response) => {
				console.log(response);
				editInventoryID(response.data.content.vehicle.inventoryID);
				history.push(newRoute, {
					ID: response.data.content.vehicle.inventoryID,
				});
				saveSuccessAlert();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	const methods = useFormContext();
	return (
		<div>
			<div
				className="btn btn-lg btn-success float-button"
				onClick={methods.handleSubmit(saveVehicleWithImage)}
			>
				<i className="nc-icon nc-check-2"></i>&nbsp;Save
			</div>
		</div>
	);
};
