import React, { useState } from "react";
import { Input, Row } from "reactstrap";
import useCurrentSaleStore from "store/SaleStore";
import OpenTotalModal from "../OpenTotalModal";
import NumberFormat from "react-number-format";
import useCurrentDealStore from "store/DealStore";
import shallow from "zustand/shallow";
import MoneyDownModal from "../modal/moneyDown/MoneyDownModal";
import { DealStatus } from "../../../../../Constants";

const DownPayment = () => {
	const [open, setOpen] = useState(false);
	// Sale store
	const { down, editDown } = useCurrentSaleStore(
		(state) => ({
			down: state.down,
			editDown: state.editDown,
		}),
		shallow
	);

	// Deal store
	const { downPayments, type } = useCurrentDealStore(
		(state) => ({
			downPayments: state.downPayments,
			type: state.deal.type,
		}),
		shallow
	);
	const toggle = () => {
		setOpen(!open);
	};
	// Returns the total field in the sale page with the modal to open up the fields to edit the total value
	// Modal removed to seperate deferred payments from down payment
	return (
		<Row>
			<MoneyDownModal modal={open} toggle={toggle} />
			<div className="col-sm-6 px-0">
				<OpenTotalModal
					btnClass="w-100 btn-md btn btn-primary px-0"
					label="Money Down"
					toggle={toggle}
				/>
			</div>
			<div className="col-sm-6 pr-0 dealInputs pl-2">
				<NumberFormat
					name="dealMoneyDown"
					disabled={downPayments.length > 0 || type !== DealStatus.PENDING}
					value={down}
					className="text-danger bold"
					thousandSeparator={true}
					decimalScale={2}
					fixedDecimalScale={true}
					onFocus={(e) => e.target.select()}
					prefix={"$"}
					isNumericString={true}
					customInput={Input}
					onValueChange={(values) => {
						if (values.value === "") {
							editDown(0);
						} else {
							editDown(values.floatValue);
						}
					}}
				/>
			</div>
		</Row>
	);
};

export default DownPayment;
