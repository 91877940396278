/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)

* Coded by DealerClick Software **RoarinLion))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";

import useCurrentDealStore from "../../store/DealStore";
import useRoute from "../../helperFunction/UseRoute";
import history from "../../helperFunction/History";
import DealTable from "../../component/dc/table/DealTable";
import { showApiError } from "../../helperFunction/ApiErrorHandler";
import {
	retrieveFailAlert,
	failAlert,
} from "../../component/dc/modal/SweetAlerts";
import { useFromUser } from "../../store/LocalStorageHelper";
import { getSettings } from "../../api/SettingsAPI";
import DealHeader from "../../component/dc/header/DealHeader";
import { getDealListByStatus, getDealDetails } from "../../api/DealAPI";
import Loading from "../../component/dc/load/Loading";
import { LoanPeriod, PATHS, SaleType } from "../../Constants";
import Camera from "../../assets/img/imgPlaceholder.png";
import useSetDeal from "../../helperFunction/deal/useSetDeal";
import shallow from "zustand/shallow";
import dayjs from "dayjs";
const DealListPage = () => {
	// Routing
	const [statusList, setStatusList] = useState("Pending");
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const dcLoginID = useFromUser("ID");
	const firstName = useFromUser("firstName");
	const lastName = useFromUser("lastName");
	const homeRoute = useRoute(PATHS.DASHBOARD);
	const dealDetailPage = useRoute(PATHS.DEAL_DETAIL);
	const setDeal = useSetDeal();

	// Local state
	const [data, setData] = useState([]);
	const [loadingList, setLoadingList] = useState(true);
	const currencyFormatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD", // Specify the currency code
	});
	// Deal store,
	const {
		updateSettings,
		editModifiedBy,
		editModifiedById,
	} = useCurrentDealStore(
		(state) => ({
			updateSettings: state.updateSettings,
			editModifiedBy: state.editModifiedBy,
			editModifiedById: state.editModifiedById,
		}),
		shallow
	);

	const dataFormat = (content) => {
		console.log(content);
		const container = Object.keys(content).map((key) => {
			let container = {};

			container.thumbnail = (
				<div
					onClick={() => {
						getDealData(content[key].dealID);
					}}
				>
					<img
						className="zoom"
						alt="vehicle"
						src={
							content[key].thumbnail
								? `${content[key].thumbnail}?lastmod=${Date.now()}`
								: Camera
						}
						onError={(e) => {
							e.target.onerror = null;
							e.target.src = Camera;
						}}
						width="250"
						height="150"
					/>
				</div>
			);

			container.hasTrade = content[key]?.hasTrade ? true : false;
			container.buyerID = content[key].buyerID;
			container.coBuyerID = content[key].coBuyerID;
			container.inventoryID = content[key].inventoryID;
			container.status = content[key].status;
			container.phone = content[key].phone != null ? content[key].phone : "";
			container.fullName = content[key].name;
			container.email = content[key].email;
			container.customerDetails =
				container.fullName +
				" " +
				container.email +
				" " +
				container.phone +
				" " +
				(content[key]?.hasTrade ? "Has Trades" : "") +
				" " +
				(content[key].coBuyerID ? "Has coBuyer" : "");
			container.vehicle = content[key].vehicle;
			container.stock = content[key].stock;
			container.vin = content[key].vin;
			container.vehicleStatus = content[key].vehicleStatus;
			console.log(content[key].vehicle);
			console.log(content[key].inventoryID);
			container.year = content[key].year;
			container.make = content[key].make;
			container.model = content[key].model;
			if (content[key].inventoryID !== null) {
				container.vehicleDetails =
					container.make +
					" " +
					container.model +
					" " +
					container.year +
					" " +
					container.stock +
					" " +
					container.vin +
					" " +
					container.vehicleStatus;
			} else {
				container.vehicleDetails = "";
			}
			console.log(container.vehicleDetails);

			container.loanDate = content[key].loanDate
				? content[key].loanDate.substr(0, 10)
				: (container.loanDate = null);

			container.saleType =
				SaleType[content[key].saleType]?.label || "Unknown type";
			container.dealNumber = content[key].dealNumber;
			container.term = content[key].term;
			container.payment = content[key].payment;
			container.loanPeriod = LoanPeriod[content[key].loanPeriod]?.label;
			container.deal =
				container.status +
				" " +
				dayjs(container.loanDate).format("MM/DD/YYYY") +
				" " +
				container.saleType +
				" " +
				container.dealNumber +
				" " +
				container.term +
				" " +
				currencyFormatter.format(container.payment) +
				" " +
				container.loanPeriod;
			container.Actions = (
				<>
					<div className="text-center">
						<div
							className="btn-md btn btn-primary"
							onClick={() => {
								getDealData(content[key].dealID);
							}}
						>
							<i className="icon-big text-center nc-icon nc-zoom-split text-default"></i>
							<span className="noMobile"> View</span>
						</div>
					</div>
				</>
			);
			return container;
		});
		return container;
	};

	// Gets a list of all the deals for this dealer and location
	const getListData = (statusList) => {
		console.log("ID: " + dealerID + " " + locationID + " " + statusList);
		if (dealerID !== undefined && locationID !== undefined) {
			getDealListByStatus(dealerID, locationID, statusList).then(
				(response) => {
					console.log(response);
					const result = dataFormat(response.data.content);
					setData(result);
					setLoadingList(false);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
						history.push(homeRoute);
					}
				}
			);
		}
	};

	// Gets the deal data and routes to detail page
	const getDealData = (id) => {
		Promise.all([getSettings(dealerID, locationID), getDealDetails(id)]).then(
			(res) => {
				console.log("getData() has finished");
				console.log(res);
				console.log("Getting Deal details for: " + id);
				const defaultSettings = res[0].data.content;
				const settingsFromDeal = JSON.parse(
					res[1].data.content.deal.dealSettings
				);
				setDeal(res[1]);
				updateSettings(defaultSettings, settingsFromDeal);
				editModifiedBy(lastName + ", " + firstName);
				editModifiedById(dcLoginID);

				const resSaleType = res[1].data.content.deal.sale.saleType;
				// only open retail and wholesale deals
				if (
					resSaleType === SaleType[0].value ||
					resSaleType === SaleType[1].value ||
					resSaleType === SaleType[2].value
				) {
				} else {
					failAlert(
						"This type of deal is under construction. DealerClick is working on it right now."
					);
				}
				history.push(dealDetailPage, { ID: id });
				console.log("getData() has finished");
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	useEffect(() => {
		console.log("statusList: " + statusList);
		setLoadingList(true);
		getListData(statusList);

		// eslint-disable-next-line
	}, [statusList]);

	return (
		<div>
			<DealHeader setStatusList={setStatusList} />
			{loadingList && <Loading />}
			{!loadingList && <DealTable data={data} />}
		</div>
	);
};

export default DealListPage;
