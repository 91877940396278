import React from "react";
import { Card, CardTitle, CardBody } from "reactstrap";
import { ReviewRow } from "component/dc/deal/review/Review";
import useCurrentSaleStore from "store/SaleStore";
import shallow from "zustand/shallow";
import useCurrentDealStore from "store/DealStore";

const ReviewCash = () => {
	const {
		price,
		aftmkt,
		docFee,
		dealerSmog,
		tax,
		warranty,
		totalDMVFees,
		onePay,
		stateSmog,
		gap,
		totalSellerFees,
		collisionTotPremium,
		lifePremium,
		disabilityPremium,
	} = useCurrentSaleStore(
		(state) => ({
			price: state.price,
			aftmkt: state.aftmkt,
			docFee: state.docFee,
			dealerSmog: state.dealerSmog,
			tax: state.tax,
			warranty: state.warranty,
			totalDMVFees: state.totalDMVFees,
			onePay: state.onePay,
			stateSmog: state.stateSmog,
			totalSellerFees: state.totalSellerFees,
			gap: state.gap,
			collisionTotPremium: state.collisionTotPremium,
			lifePremium: state.lifePremium,
			disabilityPremium: state.disabilityPremium,
		}),
		shallow
	);

	const { cancellationAmt, cancellationAccepted } = useCurrentDealStore(
		(state) => ({
			cancellationAmt: state?.contractInfo?.cancellationAmt,
			cancellationAccepted: state?.contractInfo?.cancellationAccepted,
		}),
		shallow
	);
	// const [totalCashPrice, setTotalCashPrice] = useState(0);
	// const [cancellationFee, setcancellationFee] = useState(
	// 	cancellationAccepted ? parseFloat(cancellationAmt) : 0
	// );

	// const calcTotalCashPrice = () => {
	// 	if (cancellationAccepted) setcancellationFee(parseFloat(cancellationAmt));
	// 	else setcancellationFee(0);
	// 	const total =
	// 		parseFloat(onePay) -
	// 		(parseFloat(docFee) + parseFloat(dealerSmog)) +
	// 		parseFloat(gap) +
	// 		parseFloat(totalSellerFees) +
	// 		cancellationFee;
	// 	parseFloat(total).toFixed(2);
	// 	setTotalCashPrice(total);
	// };

	// useEffect(() => {
	// 	calcTotalCashPrice();
	// 	// eslint-disable-next-line
	// }, []);

	return (
		<Card>
			<CardBody>
				<CardTitle tag="h6">Total Price</CardTitle>
				<ReviewRow isCurrency={true} label="Price" value={price} />
				<ReviewRow isCurrency={true} label="DMV Fees" value={totalDMVFees} />
				<ReviewRow isCurrency={true} label="Aftermarket" value={aftmkt} />
				<ReviewRow isCurrency={true} label="Dealer Smog" value={dealerSmog} />
				<ReviewRow isCurrency={true} label="Smog Cert" value={stateSmog} />
				<ReviewRow isCurrency={true} label="Doc Fees" value={docFee} />
				<ReviewRow
					isCurrency={true}
					label="Seller Fees"
					value={totalSellerFees - (stateSmog + docFee + dealerSmog)}
				/>
				<ReviewRow
					colSize={"7"}
					fontSize={"65%"}
					isCurrency={true}
					label="Service Contract"
					value={warranty}
				/>
				<ReviewRow
					isCurrency={true}
					label="Insurance"
					value={gap + collisionTotPremium + lifePremium + disabilityPremium}
				/>
				<ReviewRow
					isCurrency={true}
					label="Cancellation Fee"
					value={cancellationAccepted ? cancellationAmt : 0}
					fontSize={"63%"}
				/>
				<ReviewRow isCurrency={true} label="Tax" value={tax} />
				<h6>
					<ReviewRow isCurrency={true} label="Total Price" value={onePay} />
				</h6>
			</CardBody>
		</Card>
	);
};

export default ReviewCash;
