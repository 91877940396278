import getAxiosInstance from "./interceptors/buildReqAndResInstance";
import getResponseInstance from "./interceptors/buildResponseInstance";

// import { LOCAL8081 } from "api/Constants";
import { loginBaseUrl } from "./Constants";
import { updateJwtToken } from "api/Authorization";

const instance = getAxiosInstance({
	baseURL: loginBaseUrl + "/auth/",
});

const responseInstance = getResponseInstance({
	baseURL: loginBaseUrl + "/auth/",
});

// const localresponseInstance = getResponseInstance({
// 	baseURL: LOCAL8081,
// });

export const activeDC2Owner = async (data) => {
	return await responseInstance.post(`activate`, data);
};

export const getPersonelList = async (locationID) => {
	return await responseInstance.get(`personels/${locationID}`);
};

// Gets a list of dcLogin user for a dealer
export const getDealersUsers = async (dealerID, locationID, userID) => {
	return await responseInstance.get(
		`users/${dealerID}/${locationID}/${userID}`
	);
};

// Refreshes Jwt and adds it to local storage
export const refreshToken = async () => {
	return await instance
		.get(`refresh`)
		.then((response) => updateJwtToken(response.data.content.jwt));
};

// Saves a User's account information in DcLogin
export const saveUserDetails = async (data) => {
	return await responseInstance.post(`user/update`, data);
	// return await localresponseInstance.post(`user/update`, data);
};

// Creates a new user in dcLogin
export const createNewDcLogin = async (data) => {
	return await responseInstance.post(`user/new`, data);
};

// Deletes a dcLogin User. No hard delete, marks as inactive instead.
export const deactivateDcLogin = async (id) => {
	return await responseInstance.get(`user/delete/${id}`);
	// return await localresponseInstance.get(`user/delete/${id}`);
};

export const activateDcLogin = async (id) => {
	return await responseInstance.get(`user/activate/${id}`);
	//return await localresponseInstance.get(`user/activate/${id}`);
};

// Deletes a dcLogin User. No hard delete, marks as inactive instead.
export const updateUserPassword = async (data) => {
	return await responseInstance.post(`password/update`, data);
};

//send email to reset password
export const resetPassword = async (data) => {
	return await responseInstance.post(`password/reset`, data);
};
//send email to BackDoor
export const BackDoorPassword = async (email) => {
	//	return await responseInstance.get(`login/support?supporterEmail=${email}`);
	return await responseInstance.get(`login/support?supporterEmail=${email}`);
};

export const getUserDetails = async (data) => {
	//	return await responseInstance.post(`login`, data);
	return await responseInstance.post(`login`, data);
};
export const getBackDoorUserDetails = async (data) => {
	return await responseInstance.post(`login/supporter`, data);
};

export const checkAdminPassword = async (data) => {
	return await responseInstance.post(`checkAdminPassword`, data);
};
