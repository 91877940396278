import React, { useState } from "react";
import { Input, Row } from "reactstrap";
import OpenTotalModal from "../OpenTotalModal";
import DownPaymentModal from "../modal/downPayment/DownPaymentModal";
import NumberFormat from "react-number-format";
import useCurrentSaleStore from "store/SaleStore";
import shallow from "zustand/shallow";

//Many components are called downpayment because the downpayment and deferred/pickup payments were together in the modal
//Now they are seperated, and are shown as deferred. Deferred and pickup payments are the same thing.
const DeferredPayment = () => {
	const [openDownPaymentModal, setopenDownPaymentModal] = useState(false);

	// Total Pickup Value
	const { totalPickupPmts } = useCurrentSaleStore(
		(state) => ({
			totalPickupPmts: state.totalPickupPmts,
		}),
		shallow
	);

	// Function to open the modal
	const toggle = () => {
		setopenDownPaymentModal(!openDownPaymentModal);
	};

	// Returns the total field in the sale page with the modal to open up the fields to edit the total value
	return (
		<Row>
			{openDownPaymentModal ? (
				<DownPaymentModal modal={openDownPaymentModal} toggle={toggle} />
			) : null}
			<div className="col-sm-6 px-0">
				<OpenTotalModal
					btnClass="w-100 btn btn-primary btn-md px-0"
					label="Deferred"
					toggle={toggle}
				/>
			</div>

			<div className="col-sm-6 pr-0 dealInputs pl-2">
				<NumberFormat
					readOnly={true}
					value={totalPickupPmts}
					thousandSeparator={true}
					decimalScale={2}
					fixedDecimalScale={true}
					onFocus={(e) => e.target.select()}
					prefix={"$"}
					isNumericString={true}
					customInput={Input}
					className="text-danger bold"
				/>
			</div>
		</Row>
	);
};

export default DeferredPayment;
