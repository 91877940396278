import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Row } from "reactstrap";

import InventoryTable from "component/dc/table/InventoryTable";
import Loading from "component/dc/load/Loading";

import { showApiError } from "../../../../helperFunction/ApiErrorHandler";
import { retrieveFailAlert } from "component/dc/modal/SweetAlerts";
import { useFromUser } from "store/LocalStorageHelper";
import { getVehicleListByStatus } from "api/InventoryAPI";
import { TradeContext } from "component/dc/deal/trade/TradeContext";
import { failAlert } from "component/dc/modal/SweetAlerts";
import Camera from "assets/img/imgPlaceholder.png";
import useCurrentDealStore from "../../../../store/DealStore";
import shallow from "zustand/shallow";
import { KbbContext } from "../../../../view/inventory/inventoryDetailTab/value/kbb/KbbContext";
import { getAllInvDetails } from "../../../../api/InventoryAPI";

const TradeInventoryList = ({ toggle }) => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const [data, setData] = useState([]);
	const [loadingList, setLoadingList] = useState(true);

	const { vehicle, inventoryID } = useCurrentDealStore(
		(state) => ({
			vehicle: state.vehicle,
			inventoryID: state.vehicle?.inventoryID,
		}),
		shallow
	);

	const {
		editVin,
		editInventoryID,
		editYear,
		editMake,
		editModel,
		editTrim,
		editTransmission,
		editDoors,
		editEngine,
		editType,
		editMpgHwy,
		editMpgCity,
		editDateIn,
		editStockNo,
		editColor,
		editIntColor,
		editAskingPrice,
	} = useContext(TradeContext);

	const { setDcKbbObj } = useContext(KbbContext);

	//check if the deal and trade vehicle is the same
	const checkDealAndTradeVehicle = (vehicle) => {
		if (inventoryID === vehicle.inventoryID) {
			failAlert("Deal and Trade Vehicle cannot be the same.");
		} else {
			addInventoryToTrade(vehicle);
			toggle();
		}
	};

	//Add vehicle to trade form
	const addInventoryToTrade = (vehicle) => {
		console.log(vehicle.stockNo);
		editInventoryID(vehicle.inventoryID);
		editVin(vehicle.vin);
		editYear(vehicle.year.substring(0, 4));
		editMake(vehicle.make);
		editModel(vehicle.model);
		editTrim(vehicle.trim);
		editDateIn(vehicle.dateIn);
		editStockNo(vehicle.stockNo);
		editColor(vehicle.exteriorColor);
		editIntColor(vehicle.interiorColor);
		editAskingPrice(vehicle.askingPrice);

		//use api to retrieve all the vehicle information
		getAllInvDetails(dealerID, locationID, vehicle.inventoryID).then(
			(res) => {
				console.log(res.data.content.vehicle);
				editTransmission(res.data.content.vehicle.transmission);
				editDoors(res.data.content.vehicle.doors);
				editEngine(res.data.content.vehicle.engine);
				editType(res.data.content.vehicle.type);
				editMpgHwy(res.data.content.vehicle.mPGHwy);
				editMpgCity(res.data.content.vehicle.mPGCity);
				setDcKbbObj(res.data.content.kbb);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	const dataFormat = (content) => {
		const container = Object.keys(content).map((key) => {
			let container = {};
			let thumbnail = content[key].thumbnail;

			container.thumbnail = (
				<img
					className="zoom"
					alt="vehicle"
					src={`${content[key].thumbnail}?lastmod=${Date.now()}`}
					onError={(e) => {
						e.target.onerror = null;
						e.target.src = Camera;
					}}
				/>
			);
			container.thumbnailURL = thumbnail;
			container.inventoryID = content[key].inventoryID;
			container.stockNo = content[key].stockNo;
			container.year = content[key].year.substring(0, 4);
			container.make = content[key].make;
			container.model = content[key].model;
			container.vin = content[key].vin;
			container.status = content[key].vehicleStatus;
			container.stockAndVin = content[key].stockNo + " " + content[key].vin;
			const vehicleStr = [
				content[key].year.substring(0, 4),
				content[key].make,
				content[key].model,
			];
			container.vehicle = vehicleStr.join("\n");

			container.askingPrice =
				"$" +
				parseFloat(content[key].price)
					.toFixed(2)
					.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

			container.Actions = (
				<Col>
					<Row sm="12" style={{ display: "flex", justifyContent: "center" }}>
						<Button
							style={{ height: "40px", width: "150px" }}
							size="sm"
							onClick={() => {
								console.log(content[key]);
								if (vehicle === null) {
									addInventoryToTrade(content[key]);
									toggle();
								} else {
									checkDealAndTradeVehicle(content[key]);
								}
							}}
						>
							Add to trade
						</Button>
					</Row>
				</Col>
			);
			return container;
		});
		return container;
	};

	const getListData = () => {
		console.log("ID: " + dealerID + " " + locationID);
		const status = "Available,Trade";
		if (dealerID !== "" && locationID !== "") {
			getVehicleListByStatus(dealerID, locationID, status).then(
				(response) => {
					console.log(response.data.content);
					const result = dataFormat(response.data.content);
					setData(result);
					setLoadingList(false);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}
	};

	useEffect(() => {
		setLoadingList(true);
		getListData();
		// eslint-disable-next-line
	}, []);

	return (
		<div className="">
			{loadingList && <Loading />}
			{!loadingList && <InventoryTable data={data} />}
		</div>
	);
};

export default TradeInventoryList;
