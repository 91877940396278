import React, { useState } from "react";
import { Row, Col, CardText, Card, CardBody, Button } from "reactstrap";
import useCurrentSaleStore from "store/SaleStore";
import ServiceContractForm from "component/dc/deal/sale/modal/service/ServiceContractForm";
import NumberFormat from "react-number-format";
import ChildrenModal from "component/dc/modal/ChildrenModal";
import useCurrentDealStore from "store/DealStore";
import ServiceContractTable from "./ServiceContractTable";
import { DealStatus } from "Constants";
import shallow from "zustand/shallow";
import AULButton from "../../../../../../view/settings/settingsTabs/signUp/aul/AUL";

// DMV fees calculations are done here
const ServiceContractModal = ({ modal, toggle }) => {
	const { warranty } = useCurrentSaleStore(
		(state) => ({
			warranty: state.warranty,
		}),
		shallow
	);

	const { warrantyList, type, warrantyVendors } = useCurrentDealStore(
		(state) => ({
			warrantyList: state.warranty,
			type: state.deal.type,
			warrantyVendors: state.warrantyVendors,
		}),
		shallow
	);

	const [currentIndex, setCurrentIndex] = useState(warrantyList.length);
	const [open, setOpen] = useState(false);

	const toggleOpen = () => setOpen(!open);
	// Returns input fields and a text field that shows the total of the inputs

	const vendorOption = warrantyVendors.map((obj) => {
		return {
			label: obj.name,
			value: obj.ID,
			vendor: obj,
		};
	});
	return (
		<ChildrenModal
			toggle={toggle}
			modal={modal}
			modalTitle="Service Contracts"
			size="lg"
			children={
				<>
					<ChildrenModal
						modal={open}
						toggle={toggleOpen}
						size="lg"
						children={
							<ServiceContractForm
								currentIndex={currentIndex}
								setCurrentIndex={setCurrentIndex}
								toggle={toggleOpen}
								vendorOption={vendorOption}
							/>
						}
					/>
					<ServiceContractTable
						setCurrentIndex={setCurrentIndex}
						toggle={toggleOpen}
						vendorOption={vendorOption}
					/>
					<Card>
						<CardBody>
							<Row>
								<Col sm="6">
									<CardText tag="h5" className="text-center">
										Total Service Contracts
									</CardText>
								</Col>
								<Col sm="6">
									<CardText tag="h5" className="text-center">
										<NumberFormat
											value={warranty}
											thousandSeparator={true}
											decimalScale={2}
											fixedDecimalScale={true}
											prefix={"$"}
											isNumericString={true}
											displayType="text"
										/>
									</CardText>
								</Col>
							</Row>
						</CardBody>
					</Card>
					<div className="col pb-3">
						<div className="col">
							<Button
								className="btn btn-primary btn-md"
								readOnly={type !== DealStatus.PENDING}
								onClick={toggleOpen}
							>
								<i className="nc-icon nc-simple-add"></i> Add Service Contract
							</Button>
							<AULButton />
						</div>
					</div>
				</>
			}
		/>
	);
};

export default ServiceContractModal;
