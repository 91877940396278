import { twoOptionsAlert } from "component/dc/modal/SweetAlerts";
import useBuildDeal from "helperFunction/deal/useBuildDeal";
import React, { useState } from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";
import dayjs from "dayjs";
import { DealStatus } from "Constants";
import { saveDeal } from "api/DealAPI";
import { useFromUser } from "store/LocalStorageHelper";
import { showApiError } from "helperFunction/ApiErrorHandler";
import { failAlert } from "component/dc/modal/SweetAlerts";
import useCurrentDealStore from "store/DealStore";
import shallow from "zustand/shallow";
import useSetDeal from "helperFunction/deal/useSetDeal";
import { TIME_FORMAT } from "component/dc/note/NoteConstants";
const VoidRepoForm = ({ toggleAdminForm, toggle }) => {
	const getDeal = useBuildDeal();
	const [detail, setDetail] = useState("");
	const today = dayjs().format("YYYY-MM-DD");
	const todayNote = dayjs().format(TIME_FORMAT);
	const ID = useFromUser("ID");
	const firstname = useFromUser("firstName");
	const lastname = useFromUser("lastName");
	const setDeal = useSetDeal();

	const { notes, editNotes } = useCurrentDealStore(
		(state) => ({
			notes: state.deal.notes,
			editNotes: state.editNotes,
		}),
		shallow
	);
	const handleVoid = async () => {
		const option = await twoOptionsAlert(
			"Are you sure you want to transfer deal to void? You won't be able to redo it.",
			"Yes",
			"Cancel"
		);

		if (option === "Cancel") {
			toggleAdminForm();
			return;
		}
		if (!detail || detail === " ") {
			return;
		}
		let newNotes = notes;
		let createdOn, createdBy, modifiedBy;

		createdOn = todayNote;
		createdBy = lastname + ", " + firstname;
		modifiedBy = lastname + ", " + firstname;

		const entry = {
			createdOn,
			createdBy,
			modifiedOn: today,
			modifiedBy,
			description: detail,
		};
		newNotes[notes?.length] = entry;
		editNotes(newNotes);
		const deal = getDeal();
		let modifiedDeal = { ...deal };
		modifiedDeal.deal.type = DealStatus.VOID;
		modifiedDeal.deal.repoDate = today;
		modifiedDeal.deal.modifiedBy = lastname + ", " + firstname;
		modifiedDeal.deal.modifiedById = ID;
		const modifiedNotes = notes.length > 0 ? JSON.stringify(newNotes) : "[]";
		modifiedDeal.deal.notes = modifiedNotes;

		// TODO: need set the void car as availible car
		saveDeal(modifiedDeal).then(
			(res) => {
				setDeal(res);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}
			}
		);
		toggleAdminForm();
		toggle();
	};
	return (
		<div>
			<FormGroup className="col-12">
				<Label>Reason</Label>
				<Input
					name="voidDetail"
					type="textarea"
					value={detail}
					onChange={(e) => {
						setDetail(e.target.value);
					}}
				/>
				<div className="text-center">
					<Button color="danger" onClick={handleVoid}>
						Void
					</Button>
				</div>
			</FormGroup>
		</div>
	);
};

export default VoidRepoForm;
