import React, { useState } from "react";
import { Form, Label, CardBody } from "reactstrap";
import Select from "react-select";
import { useForm, FormProvider } from "react-hook-form";
import { vehicleStatus } from "Constants";
import { saveVendor } from "api/VendorsAPI";
import AddVendorModal from "component/dc/modal/AddVendorModal";
import { vendorTypesOBJ } from "Constants";
import { failAlert } from "component/dc/modal/SweetAlerts";
import InputWrapper from "view/formValidation/InputWrapper";
import { CurrencyInputElement } from "view/formValidation/InputElement";

const TradeInfo = ({
	tradeLenderRecNum,
	editTradeLenderRecNum,
	acv,
	allowance,
	payoff,
	editAcv,
	editAllowance,
	editPayoff,
	tradeVendors,
	editTradeVendors,
	status,
}) => {
	console.log(tradeVendors);
	console.log(payoff, tradeLenderRecNum);

	// Saves the new lender and add it to the lender list in deal store
	const saveNewLender = (lender) => {
		if (lender != null) {
			const isHidden = false;
			//in order to save add isHidden
			const buildLender = { ...lender, isHidden };
			saveVendor(buildLender).then(
				(res) => {
					const newLender = {
						value: res.data.content.ID,
						label: res.data.content.name,
						lender: res.data.content,
					};
					console.log(newLender);

					editTradeVendors([...tradeVendors, res.data.content]);

					editTradeVendors([...tradeVendors, newLender]);
					editTradeLenderRecNum(newLender.value);
				},
				(e) => {
					console.log(e);
					failAlert("Failed to save new lender");
				}
			);
		}
	};
	const [openAddLenderModal, setOpenAddLenderModal] = useState(false);
	// Function to open the modal
	const toggleLenderModal = () => setOpenAddLenderModal(!openAddLenderModal);
	// Updates store with the lender selected or opens modal to add new lender
	// const selectLender = (option) => {
	// 	const value = option.value;

	// 	console.log(option);
	// 	if (value === "ADD") {
	// 		toggleLenderModal();
	// 		console.log(value);
	// 	} else {
	// 		editTradeLenderRecNum(value);
	// 	}
	// };
	const selectLender = (option) => {
		const value = option.value;

		console.log(option);
		if (value === "ADD") {
			toggleLenderModal();
			console.log(value);
		} else {
			editTradeLenderRecNum(value);
			setLenderOption(option);
		}
	};
	// const tradeVendorsList = tradeVendors.map((obj) => {
	// 	return {
	// 		label: obj.name,
	// 		value: obj.ID,
	// 		vendor: obj,
	// 	};
	// });
	// Define form validation parameters
	const methods = useForm();
	const tradeLendersListMap = tradeVendors.map((obj) => {
		return {
			label: obj.name,
			value: obj.ID,
			vendor: obj,
		};
	});
	const [lenderOption, setLenderOption] = useState(
		tradeLendersListMap.find(
			(element) => element.value === tradeLenderRecNum
		) || { value: null, label: "None" }
	);

	return (
		<CardBody className="my-2">
			<FormProvider {...methods}>
				<Form>
					<InputWrapper
						formTitle="Trade Info"
						inputComponents={[
							<CurrencyInputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								value={allowance}
								name="allowance"
								label="Allowance"
								type="number"
								onChange={editAllowance}
							/>,
							<CurrencyInputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								value={acv}
								name="acv"
								label="Acv"
								type="number"
								onChange={editAcv}
							/>,
							<CurrencyInputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								value={payoff}
								name="payoff"
								label="Payoff"
								type="number"
								onChange={editPayoff}
							/>,
							<div className="col-sm-3">
								<Label>LienHolder</Label>
								<Select
									className="bold"
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									noOptionsMessage={() => "No Lenders available"}
									value={
										lenderOption
										// 	{
										// 	value: tradeLenderRecNum,
										// 	label:
										// 		tradeVendorsList.filter(
										// 			(element) => element.value === tradeLenderRecNum
										// 		)[0]?.label || "None",
										// }
									}
									options={[
										...tradeLendersListMap,
										{ value: "ADD", label: "Add new lender" },
									]}
									onChange={(e) => selectLender(e)}
								/>
								<AddVendorModal
									title="Add Lender"
									vendorType={vendorTypesOBJ.LENDER.value}
									vendorLabel={vendorTypesOBJ.LENDER.label}
									addVendor={saveNewLender}
									toggle={toggleLenderModal}
									modal={openAddLenderModal}
								/>
							</div>,
						]}
					/>
				</Form>
			</FormProvider>
		</CardBody>
	);
};

export default TradeInfo;
