import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";

import ReactTable from "./ReactTable";

const CountyTaxTable = ({ data, viewData }) => {
	const ActionCell = ({ row }) => (
		<div
			className="zoom btn-md btn btn-primary"
			onClick={() => viewData(row.original)}
		>
			<i className="icon-big text-center nc-icon nc-zoom-split text-default"></i>
			<span className="noMobile"> View</span>
		</div>
	);

	return (
		<div>
			<Row>
				<Col md="12">
					<Card>
						<CardBody>
							<ReactTable
								data={data}
								columns={[
									{
										Header: "Print As",
										accessor: "name",
										style: { whiteSpace: "unset" },
									},
									{
										Header: "Show As",
										accessor: "showAs",
										style: { whiteSpace: "unset" },
									},
									{
										Header: "Tax Rate",
										accessor: (row) =>
											`${Number(row.taxRate * 100).toFixed(4)}%`,
									},
									{
										Header: "State",
										accessor: "state",
									},
									{
										Header: "City",
										accessor: "city",
										style: { whiteSpace: "unset" },
									},
									{
										Header: "Zip",
										accessor: "zip",
									},
									{
										Header: "Custom",
										id: "isCustom",
										accessor: (row) => {
											if (row.isCustom == null || row.isCustom === false) {
												return "No";
											} else if (row.isCustom === true) {
												return "Yes";
											}

											return `${row.isCustom}`;
										},
									},
									{
										Header: "Default",
										id: "isDefault",
										accessor: (row) => {
											if (row.isDefault == null || row.isDefault === false) {
												return "No";
											} else if (row.isDefault === true) {
												return "Yes";
											}

											return `${row.isDefault}`;
										},
									},
									{
										Header: "",
										accessor: "recNum",
										Cell: ActionCell,
										disableFilters: true,
									},
								]}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default CountyTaxTable;
