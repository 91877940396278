/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 DealerClick (https://dealerclick.com)

* Coded by DealerClick Software ))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useState, useEffect } from "react";
import { Card, Row, Col, CardTitle, CardFooter } from "reactstrap";

import CardContent from "../card/CardContent";
import CardContentBottom from "../card/CardContentBottom";

import { retrieveFailAlert } from "component/dc/modal/SweetAlerts";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";
import { useFromUser } from "store/LocalStorageHelper";
import { NavLink, useHistory } from "react-router-dom";
import {
	getDealsStatusList,
	getInventoryStatusList,
	//	getNumberOfCustomers,
} from "api/DealAPI";
import { getCreditAppListByStatus } from "api/CreditAppApi";
import { getLeadListByStatus } from "api/LeadApi";

const DashboardHeader = () => {
	const history = useHistory();
	//const [customerData, setcustomerData] = useState([]);
	// const [leads, setLeads] = useState(0);
	const [availableVehicle, setAvailable] = useState(0);
	const [pendingVehicle, setPending] = useState(0);
	const [pendingDeal, setPendingDeal] = useState(0);
	const [status, setStatus] = useState("Leads");

	const [invData, setInvData] = useState([]);
	const [data, setData] = useState([]);
	const [totalDeal, setTotalDeal] = useState([]);
	const [totalInv, setTotalInv] = useState([]);
	// const [totalCustomer, setTotalCustomer] = useState([]);
	const [onlineLead, setOnlineLead] = useState(0);
	const [creditApp, setCreditApp] = useState(0);
	const locationID = useFromUser("locationID");

	const dataFormat = (content, setHook, tabBox) => {
		var total = 0;
		const container = Object.keys(content).map((key) => {
			total = total + content[key].numOfType;
			// if (content[key].typeDesc === "Leads") {
			// 	setLeads(content[key].numOfType);
			// }
			if ((content[key].typeDesc === "Pending") & (tabBox === "Deal")) {
				setPendingDeal(content[key].numOfType);
			}
			if ((content[key].typeDesc === "Pending") & (tabBox === "Vehicle")) {
				setPending(content[key].numOfType);
			}
			if ((content[key].typeDesc === "Available") & (tabBox === "Vehicle")) {
				setAvailable(content[key].numOfType);
			}

			return (
				<li key={key.toString()}>
					<div>
						{content[key].typeDesc} : {content[key].numOfType}
					</div>
				</li>
			);
		});
		setHook(total);
		return container;
	};

	const getListData = (LocationID) => {
		console.log(LocationID);
		if (locationID !== "") {
			getDealsStatusList(LocationID).then(
				(response) => {
					console.log(response.data.content);
					const result = dataFormat(
						response.data.content,
						setTotalDeal,
						"Deal"
					);
					setData(result);
					console.log(totalDeal);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}
	};
	const getInvListData = (LocationID) => {
		console.log(LocationID);
		if (locationID !== "") {
			getInventoryStatusList(LocationID).then(
				(response) => {
					console.log(response.data.content);
					const result = dataFormat(
						response.data.content,
						setTotalInv,
						"Vehicle"
					);
					setInvData(result);
					console.log(totalInv);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}
	};
	// const NumberOfCustomers = (LocationID) => {
	// 	console.log(LocationID);
	// 	if (locationID !== "") {
	// 		getNumberOfCustomers(LocationID).then(
	// 			(response) => {
	// 				console.log(response.data.content);
	// 				const result = dataFormat(
	// 					response.data.content,
	// 					setTotalCustomer,
	// 					"Customer"
	// 				);
	// 				console.log(result);
	// 				//setcustomerData(result);
	// 			},
	// 			(err) => {
	// 				if (!err.isGeneralError) {
	// 					showApiError(err, retrieveFailAlert);
	// 				}
	// 			}
	// 		);
	// 	}
	// };

	useEffect(() => {
		getListData(locationID);
		getInvListData(locationID);
		// NumberOfCustomers(locationID);

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		//value 1 = active status
		getCreditAppListByStatus(locationID, 1).then(
			(res) => {
				setCreditApp(res.data.content.NumOfRecord);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
		//value 0 in onlineLead is active
		getLeadListByStatus(locationID, 0).then(
			(res) => {
				setOnlineLead(res.data.content.NumOfRecord);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	}, [creditApp, onlineLead, locationID]);

	return (
		<div className="pt-3 mobileHeading">
			<div style={{ display: "none" }}>
				{data}
				{invData} NEEDS TO BE FIXED
			</div>
			{/* Card 1 */}

			<Col className="pb-3 text-center">
				<CardTitle>
					<h2>Dashboard</h2>
				</CardTitle>
			</Col>

			<Row className="mx-0">
				<Col
					lg="3"
					md="6"
					sm="6"
					onClick={() => {
						setStatus("Leads");
					}}
				>
					<Card
						className={status === "Leads" ? "card-stats-active" : "card-stats"}
					>
						<NavLink
							to="/customer-list"
							activeClassName=""
							className="text-dark"
						>
							<CardContent
								iconClass="icon-big text-center nc-icon nc-laptop text-info"
								title1="Online Leads"
								title2={onlineLead}
								check={status === "Leads"}
							/>
						</NavLink>
						<CardContentBottom
							navClass="col-xs-3"
							routePath="/lead-add"
							iconClass="nc-icon nc-simple-add"
							iconText=" Add Contact"
						/>
					</Card>
				</Col>
				{/* Card 2 */}
				<Col
					lg="3"
					md="6"
					sm="6"
					onClick={() => {
						setStatus("Contacts");
					}}
				>
					<Card
						className={
							status === "Contacts" ? "card-stats-active" : "card-stats"
						}
					>
						<span
							onClick={() => {
								history.push("/customer-list", { status: "Credit Apps" });
							}}
							to="/customer-list"
							className="text-dark"
						>
							<CardContent
								iconClass="icon-big text-center icon-warning nc-icon nc-single-02 text-info"
								title1="Credit Apps"
								title2={creditApp}
							/>
						</span>
						<hr />
						<CardFooter className="pt-3">
							<Row style={{ marginRight: "0", marginLeft: "0" }}>
								<div
									className="btn btn-sm btn-link"
									onClick={() => {
										history.push("/customer-list", { status: "Credit Apps" });
									}}
								>
									<i className="nc-icon nc-zoom-split" />
									View
								</div>
							</Row>
						</CardFooter>
					</Card>
				</Col>

				{/* Card 3 */}
				<Col
					lg="3"
					md="6"
					sm="6"
					onClick={() => {
						setStatus("Pending Deal");
					}}
				>
					<Card
						className={
							status === "Pending Deal" ? "card-stats-active" : "card-stats"
						}
					>
						<NavLink to="/deal-list" activeClassName="" className="text-dark">
							<CardContent
								iconClass="icon-big text-center nc-icon nc-briefcase-24 text-success"
								title1="Pending Deals"
								title2={pendingDeal} //{totalDeal}
							/>
						</NavLink>
						<CardContentBottom
							navClass="col-xs-3"
							routePath="/deal-add"
							iconClass="nc-icon nc-simple-add"
							iconText=" Add Deal"
						/>
					</Card>
				</Col>

				{/* Card 4 */}
				<Col
					lg="3"
					md="6"
					sm="6"
					onClick={() => {
						setStatus("Inventory");
					}}
				>
					<Card
						className={
							status === "Inventory" ? "card-stats-active" : "card-stats"
						}
					>
						<NavLink
							to="/inventory-list"
							activeClassName=""
							className="text-dark"
						>
							<CardContent
								iconClass="icon-big text-center nc-icon nc-delivery-fast text-warning	"
								title1="Total Inventory"
								title2={pendingVehicle + availableVehicle} //totalInv
							/>
						</NavLink>
						<CardContentBottom
							navClass="col-xs-3"
							routePath="/inventory-add"
							iconClass="nc-icon nc-simple-add"
							iconText=" Add Vehicle"
						/>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default DashboardHeader;
