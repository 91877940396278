import React from "react";
import { CardBody, CardTitle, Row, Col } from "reactstrap";

function CardContent({ title1, title2, iconClass }) {
	return (
		<CardBody>
			<Row>
				<Col md="3" xs="5">
					<i className={iconClass} />
				</Col>
				<Col md="9" xs="7">
					<div className="numbers">
						<p className={"card-category"}>{title1}</p>
						<CardTitle tag="p">{title2}</CardTitle>
					</div>
				</Col>
			</Row>
		</CardBody>
	);
}

export default CardContent;
