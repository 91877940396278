import React, { useEffect, useState } from "react";
import {
	Input,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	Label,
	CardText,
	CardTitle,
} from "reactstrap";
import NumberFormat from "react-number-format";
import { DealStatus } from "../../../Constants";
import useCurrentVehicleStore from "../../../store/InventoryStore";
import shallow from "zustand/shallow";
import { getVehicleSettings } from "api/InventoryAPI";
import { useFromUser } from "store/LocalStorageHelper";

const InventoryTotalCostDisplay = () => {
	const packID = "costDisplayPack";

	const locationID = useFromUser("locationID");

	const [packPercent, setPackPercent] = useState(0);
	const [packUsed, setPackUsed] = useState(0);
	const invDefault = () => {
		getVehicleSettings(locationID).then(
			(res) => {
				setPackPercent(res.data.content.packPercent);
				setPackUsed(res.data.content.packUsed);
			},
			(err) => {
				console.log(err);
			}
		);
	};

	// Vehicle store
	const {
		status,
		totalRecon,
		totalCost,
		flooring,
		totalOtherCost,
		pack,
		cost,
		editPack,
		editCost,
		editTotalCost,
	} = useCurrentVehicleStore(
		(state) => ({
			status: state.status,
			totalRecon: state.totalRecon,
			totalCost: state.totalCost,
			flooring: state.flooring,
			totalOtherCost: state.totalOtherCost,
			pack: state.pack,
			cost: state.cost,
			editPack: state.editPack,
			editCost: state.editCost,
			editTotalCost: state.editTotalCost,
		}),
		shallow
	);
	useEffect(() => {
		editTotalCost(
			parseFloat(cost) +
				parseFloat(pack) +
				totalOtherCost +
				totalRecon +
				flooring
		);

		// eslint-disable-next-line
	}, [pack, cost]);
	useEffect(() => {
		invDefault();
		// eslint-disable-next-line
	}, []);

	console.log(flooring);
	console.log(
		parseFloat(cost),
		parseFloat(pack),
		totalOtherCost,
		totalRecon,
		flooring
	);
	console.log(totalCost);

	return (
		<div>
			<Col>
				<Card>
					<CardHeader>
						<CardTitle>
							<h3>Total Costs</h3>
						</CardTitle>
					</CardHeader>
					<CardBody className="px-3">
						<Row className="px-3">
							<Col>
								<Label>Vehicle Cost</Label>
								<NumberFormat
									onKeyDown={(e) => {
										if (e.keyCode === 13) {
											const element = document.getElementById(packID);

											e.preventDefault();
											element.focus();
											element.select();
										}
									}}
									readOnly={
										status === DealStatus.SOLD || status === DealStatus.DELETED
									}
									className="bold"
									value={cost}
									name="cost"
									thousandSeparator={true}
									decimalScale={2}
									prefix={"$"}
									fixedDecimalScale={true}
									onFocus={(e) => e.target.select()}
									isNumericString={true}
									customInput={Input}
									onValueChange={(values) => {
										if (values.value === "") {
											editCost(0);
										} else {
											editCost(values.value);
										}
										editPack(packUsed + values.value * packPercent);
									}}
								/>
							</Col>
							<Col>
								<Label>Pack</Label>
								<NumberFormat
									readOnly={
										status === DealStatus.SOLD || status === DealStatus.DELETED
									}
									id={packID}
									className="form-control bold"
									value={pack}
									name="pack"
									thousandSeparator={true}
									decimalScale={2}
									prefix={"$"}
									fixedDecimalScale={true}
									onFocus={(e) => e.target.select()}
									isNumericString={true}
									customInput={Input}
									onValueChange={(values) => {
										if (values.value === "") {
											editPack(0);
										} else {
											editPack(values.value);
										}
									}}
								/>
							</Col>

							<Col>
								<Label>Total flooring</Label>
								<CardText>
									<NumberFormat
										className="form-control bold"
										value={flooring}
										thousandSeparator={true}
										decimalScale={2}
										fixedDecimalScale={true}
										prefix={"$"}
										isNumericString={true}
										displayType="text"
										customInput={Input}
										readOnly
									/>
								</CardText>
							</Col>
							<Col>
								<Label>Other Costs</Label>
								<CardText>
									<NumberFormat
										className="form-control bold"
										value={totalOtherCost}
										thousandSeparator={true}
										decimalScale={2}
										fixedDecimalScale={true}
										prefix={"$"}
										isNumericString={true}
										displayType="text"
										customInput={Input}
										readOnly
									/>
								</CardText>
							</Col>
							<Col>
								<Label>Total Recon</Label>
								<CardText>
									<NumberFormat
										className="form-control bold"
										value={totalRecon}
										thousandSeparator={true}
										decimalScale={2}
										fixedDecimalScale={true}
										prefix={"$"}
										isNumericString={true}
										displayType="text"
										customInput={Input}
										readOnly
									/>
								</CardText>
							</Col>
							<Col>
								<Label>Total Cost</Label>
								<NumberFormat
									className="form-control h6 text-danger"
									value={totalCost}
									thousandSeparator={true}
									decimalScale={2}
									fixedDecimalScale={true}
									prefix={"$"}
									isNumericString={true}
									displayType="text"
									customInput={Input}
									readOnly
								/>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Col>
		</div>
	);
};

export default InventoryTotalCostDisplay;
