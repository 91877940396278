import React from "react";
import { CardBody, CardTitle, Col, Row } from "reactstrap";
//import WelcomeImage from "../../assets/img/home-cta.png";

const WelcomeSection = () => {
	const [show, toggleShow] = React.useState(true);
	return (
		<Col className="pb-4">
			<div onClick={() => toggleShow(!show)}>
				{show ? <p className="toggle-btn">Close X</p> : ""}
			</div>
			{show && (
				<div className="border border-warning mt-3">
					<CardBody>
						<CardTitle className="mt-0">
							Welcome to DealerClick 2.0!{" "}
							<span aria-label="party emoji" role="img">
								&#127881;
							</span>
						</CardTitle>
						<Row>
							{/* <Col md="6">
								<CardText>Here are the latest updates:</CardText>
								<ul>
									<li>Implemented something cool.</li>
									<li>Fixed something.</li>
									<li>Implemented something cool.</li>
									<li>Fixed something.</li>
									<li>Implemented something cool.</li>
								</ul>
							</Col> */}
							{/* <Col md="6">
								<CardImg
									onClick={(e) => {
										e.preventDefault();
										window.open("https://learn.dealerclick.com/");
									}}
									className="mb-2 cursor-pointer"
									alt="CTA"
									top
									width="100%"
									src={WelcomeImage}
								/>
							</Col> */}
						</Row>
					</CardBody>
				</div>
			)}
		</Col>
	);
};

export default WelcomeSection;
