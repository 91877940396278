import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";

import { TemplateVariableContext } from "../../../helperFunction/TemplateVariableContext";
import { TextAreaInput } from "../../formValidation/InputElement";

export const MessageTemplateVariablesAndInput = ({
	body,
	updateBody,
	field,
}) => {
	const { templateVars } = useContext(TemplateVariableContext);

	// Appends the template variable at the current cursor position or selection
	const appendTemplateVariable = (templateVariable) => {
		let txtarea = document.getElementsByName("messageBody")[0];
		let start = txtarea.selectionStart;
		let end = txtarea.selectionEnd;
		let finText =
			txtarea.value.substring(0, start) +
			templateVariable +
			txtarea.value.substring(end);
		updateBody(finText, field);
		txtarea.focus();
		console.log(start + " - " + end);
		txtarea.setSelectionRange(start, end);
	};

	const methods = useFormContext();
	return (
		<>
			<TextAreaInput
				{...methods}
				colSize="col-lg-12"
				inputClass="text-messages-body"
				placeholder="Type a message..."
				value={body}
				onChange={(e) => {
					updateBody(e.target.value, field);
				}}
				name="messageBody"
				disableValidation
			/>
			<div className="col-lg-12">
				<ul className="message-template-list mb-4">
					{templateVars !== null &&
						Object.entries(templateVars).map((entry) => (
							<li
								key={entry[0]}
								onClick={() => appendTemplateVariable(entry[0])}
							>
								{entry[1]}
							</li>
						))}
				</ul>
			</div>
		</>
	);
};
