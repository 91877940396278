import React, { useState, useEffect } from "react";
import { Form, Button } from "reactstrap";

import tabOnEnter from "../../../../helperFunction/tabOnEnter";
import useCurrentCustomerStore from "../../../../store/CustomerStore";
import useCurrentDealStore from "../../../../store/DealStore";
import ChildrenModal from "../../../../component/dc/modal/ChildrenModal";
import InputElement from "../../../formValidation/InputElement";
import InputWrapper from "../../../formValidation/InputWrapper";
import ZipCityTable from "../../../../component/dc/address/ZipCityTable";

import { showApiError } from "../../../../helperFunction/ApiErrorHandler";
import { saveReferences } from "../../../../api/CustomerAPI";
import { stateOptions, DealStatus } from "../../../../Constants";
import { useFromUser } from "../../../../store/LocalStorageHelper";
import {
	PhoneInputElement,
	SelectorElement,
	ZipInputElement,
} from "../../../formValidation/InputElement";
import {
	saveFailAlert,
	saveSuccessAlert,
} from "../../../../component/dc/modal/SweetAlerts";

import { useFormContext } from "react-hook-form";

import shallow from "zustand/shallow";

const CustomerReference = ({ currentIndex, setCurrentIndex, toggle }) => {
	// Local storage
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	// Deal store
	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);
	// Customer store
	const { custRecNum, references, editReferences } = useCurrentCustomerStore(
		(state) => ({
			custRecNum: state.ID,
			references: state.references,
			editReferences: state.editReferences,
		}),
		shallow
	);

	const [cityModal, setCityModal] = useState(false);
	const [addressData, setAddressData] = useState({});
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [middleName, setMiddleName] = useState("");
	const [relationship, setRelationship] = useState("");
	const [known, setKnown] = useState("");
	const [street, setStreet] = useState("");
	const [homePhone, setHomePhone] = useState("");
	const [city, setCity] = useState("");
	const [zip, setZip] = useState("");
	const [state, setState] = useState("");

	// Toggles the modal
	const toggleCityModal = () => setCityModal(!cityModal);

	const handleZipChange = (fieldZip) => {
		setAddressData({
			zip: fieldZip,
			address: {
				city,
				state,
			},
		});
		toggleCityModal();
	};

	const setAddressFields = (address) => {
		setCity(address.city);
		setState(address.state);
	};

	const clearFields = () => {
		setFirstName("");
		setLastName("");
		setMiddleName("");
		setRelationship("");
		setKnown("");
		setStreet("");
		setHomePhone("");
		setCity("");
		setZip("");
		setState("");
		setCurrentIndex(references.length);
	};

	// Returns an updated store object with the new reference
	const addToReferencesList = (reference, id) => {
		let obj = references;
		reference.ID = id;
		obj[currentIndex] = reference;

		return obj;
	};

	// Save new reference
	const newReference = {
		ID: references[currentIndex]?.ID || null,
		city,
		firstName,
		homePhone,
		known,
		lastName,
		middleName,
		relationship,
		state,
		street,
		zip,
		dealerID,
		locationID,
		custRecNum,
	};

	// Calls api to add the reference to database or update if it exists
	// If updating an sold customer's reference, just add the new obj to the store reference list
	const addReference = () => {
		saveReferences(newReference).then(
			(response) => {
				if (currentIndex != null) {
					editReferences(
						addToReferencesList(newReference, response.data.content.ID)
					);
				}
				clearFields();
				saveSuccessAlert();
				toggle();
			},
			(err) => {
				console.log(err);

				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	// Get validation context
	const methods = useFormContext();

	useEffect(() => {
		setFirstName(references[currentIndex]?.firstName || "");
		setLastName(references[currentIndex]?.lastName || "");
		setMiddleName(references[currentIndex]?.middleName || "");
		setRelationship(references[currentIndex]?.relationship || "");
		setKnown(references[currentIndex]?.known || "");
		setStreet(references[currentIndex]?.street || "");
		setHomePhone(references[currentIndex]?.homePhone || "");
		setCity(references[currentIndex]?.city || "");
		setZip(references[currentIndex]?.zip || "");
		setState(references[currentIndex]?.state || "");
		return clearFields;
		// eslint-disable-next-line
	}, [currentIndex]);

	return (
		<Form onKeyDown={tabOnEnter}>
			<InputWrapper
				formTitle={"Reference"}
				inputComponents={[
					<InputElement
						{...methods}
						value={firstName}
						onChange={(e) => setFirstName(e.target.value)}
						name="firstNameReference"
						label="First Name"
						type="text"
					/>,
					<InputElement
						{...methods}
						colSize="col-sm-2"
						value={middleName}
						onChange={(e) => setMiddleName(e.target.value)}
						name="middleNameReference"
						label="Middle Name"
						type="text"
					/>,
					<InputElement
						{...methods}
						value={lastName}
						onChange={(e) => setLastName(e.target.value)}
						name="lastNameReference"
						label="Last Name"
						type="text"
					/>,
					<InputElement
						{...methods}
						value={relationship}
						onChange={(e) => setRelationship(e.target.value)}
						name="relationshipReference"
						label="Relationship"
						type="text"
					/>,
					<InputElement
						{...methods}
						value={known}
						onChange={(e) => {
							setKnown(e.target.value.replace(/[^\d{2}]$/, "").substr(0, 2));
						}}
						name="knownReference"
						label="Time known in years"
						placeholder="ex: 2y"
						type="number"
					/>,
					<InputElement
						{...methods}
						value={street}
						onChange={(e) => setStreet(e.target.value)}
						name="streetReference"
						label="Street Address"
						type="text"
					/>,
					<InputElement
						{...methods}
						value={city}
						onChange={(e) => setCity(e.target.value)}
						name="cityReference"
						label="City"
						type="text"
					/>,
					<SelectorElement
						{...methods}
						name="stateReference"
						label="State"
						value={state}
						onChange={(e) => setState(e.target.value)}
						options={stateOptions}
					/>,
					<ZipInputElement
						{...methods}
						value={zip}
						onChange={setZip}
						openZipModal={handleZipChange}
						name="zipReference"
						label="Zip"
					/>,
					<PhoneInputElement
						{...methods}
						value={homePhone}
						onChange={setHomePhone}
						name="phoneReference"
						label="Phone"
					/>,
				]}
				buttons={
					<div className="d-flex justify-content-center">
						<Button
							readOnly={type !== DealStatus.PENDING}
							className="mr-1"
							color="primary"
							onClick={methods.handleSubmit(addReference)}
						>
							Add
						</Button>
					</div>
				}
			/>
			<ChildrenModal
				modal={cityModal}
				toggle={toggleCityModal}
				modalTitle="Cities"
			>
				<ZipCityTable
					data={addressData}
					toggle={toggleCityModal}
					address={{
						city,
						state,
						zip,
					}}
					setAddress={setAddressFields}
				/>
			</ChildrenModal>
		</Form>
	);
};

export default CustomerReference;
