import React from "react";
import { Input } from "reactstrap";
import siriusXMImage from "assets/img/Inventory-Advertising/siriusXM.png";

export const SiriusXMButton = () => {
	const commitData = () => {
		window.open(
			"https://care.siriusxm.com/vinlookup_view.action;",
			"_blank",
			"noopener,noreferrer"
		);
	};

	return (
		<div>
			<Input
				type="image"
				onClick={commitData}
				src={siriusXMImage}
				alt="SiriusXM"
				className="SiriusXM zoom card p-2"
			/>
		</div>
	);
};
export default SiriusXMButton;
