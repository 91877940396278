import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import dayjs from "dayjs";

import PreviewMessage from "../../../../view/communication/template/PreviewMessage";

import {
	getTwilioMessages,
	getTwilioMessageTemplates,
	sendTwilioMessage,
} from "../../../../api/TwilioApi";
import { failAlert, saveSuccessAlert } from "../../modal/SweetAlerts";
import { showApiError } from "../../../../helperFunction/ApiErrorHandler";
import {
	PhoneInputElement,
	SelectorElement,
} from "../../../../view/formValidation/InputElement";

import { object, setLocale, string } from "yup";
import { yupResolver } from "@hookform/resolvers";
import { FormProvider, useForm } from "react-hook-form";
import { getTwilioMsgBody } from "api/TwilioApi";
import { MessageTemplateVariablesAndInput } from "../../../../view/communication/template/MessageTemplateVariables";

const TwilioSendMessage = ({
	sendToNumber,
	dealID = null,
	customerID = null,
	personType = "Customer",
}) => {
	// Local storage
	const user = JSON.parse(sessionStorage.getItem("user"));
	const dealerNumber = user?.messagingNumber;
	const locationID = user?.locationID;
	const IN_DEVELOPMENT = process.env.NODE_ENV === "development";

	// Local state
	const [toNumber, setToNumber] = useState(sendToNumber || "");
	const [messageBody, setMessageBody] = useState("");
	const [messageHistory, setMessageHistory] = useState(null);
	const [previewMessage, setPreviewMessage] = useState("");
	const [showPreview, setShowPreview] = useState(false);
	const [templates, setTemplates] = useState([]);
	const [templateOptions, setTemplateOptions] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState(null);

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Cannot be more that ${max} characters long",
		},
	});

	// Define rules for each input field
	const schema = object().shape({
		twilioFromNumber: string().required("Contact support if number is missing"),
		twilioToNumber: string().required(),
		//messageBody: string().required().max(1600),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	console.log(methods.getValues("twilioToNumber"));
	console.log(methods.getValues("messageBody"));

	const buildMessage = () => {
		return {
			fromNumber: "+1" + dealerNumber,
			recipients: ["+1" + toNumber],
			messageBody: previewMessage === "" ? messageBody : previewMessage,
			customerID,
			personType,
			dealID,
		};
	};
	// Check that the send to number is valid before sending a message
	const sendMessage = () => {
		console.log(toNumber);
		if (!isToNumberValid()) return;

		const message = buildMessage();
		sendTwilioMessage(message).then(
			() => {
				saveSuccessAlert("Sent!");
				setMessageBody("");
				getMsgHistory();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}

				console.log(err);
			}
		);
	};

	const isToNumberValid = () => {
		if (typeof toNumber === "number") {
			setToNumber(toNumber.toString());
			if (toNumber.toString().length !== 10) {
				methods.setError("twilioToNumber", {
					message: "Must be a 10 digit number",
				});
				return false;
			}
		} else {
			if (toNumber.length !== 10) {
				methods.setError("twilioToNumber", {
					message: "Must be a 10 digit number",
				});

				return false;
			}
		}
		return true;
	};

	// Gets the messages between two numbers and uses that to display the message history component
	const getMsgHistory = () => {
		const msgHistory = {
			fromNumber: "+1" + dealerNumber,
			recipients: ["+1" + toNumber],
		};
		getTwilioMessages(msgHistory).then(
			(res) => setMessageHistory(res.data.content.messages),
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}
			}
		);
	};

	const fetchHistory = () => {
		if (!isToNumberValid()) return;
		else {
			getMsgHistory();
		}
	};

	// Gets the messages between two numbers and uses that to display the message history component
	const getTemplates = () => {
		getTwilioMessageTemplates(locationID).then(
			(res) => {
				const data = res.data.content;
				const options = [];
				let templateList = {};

				for (let i = 0; i < data.length; i++) {
					const option = {
						label: data[i].name,
						value: data[i].ID,
					};

					options.push(option);
					templateList[data[i].ID] = { ...data[i] };
				}

				setTemplateOptions(options);
				setTemplates(templateList);
			},
			(err) => console.log(err)
		);
	};

	useEffect(() => {
		getMsgHistory();
		getTemplates();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const msg = {
			dealID,
			customerID,
			messageBody,
			personType,
		};
		if (showPreview === true) {
			getTwilioMsgBody(msg).then(
				(res) => {
					setPreviewMessage(res.data.content);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, failAlert);
					}
				}
			);
		} else {
			setPreviewMessage("");
		}
		// eslint-disable-next-line
	}, [dealID, customerID, showPreview, personType]);

	useEffect(() => {
		if (selectedTemplate != null) {
			setMessageBody(templates?.[selectedTemplate]?.body || "");
			setShowPreview(false);
		}
		// eslint-disable-next-line
	}, [selectedTemplate]);

	return (
		<FormProvider {...methods}>
			<Card>
				<CardBody>
					<TwilioMessageHistory messages={messageHistory} />
					{!showPreview && (
						<div className="">
							<div className="row row-12">
								<PhoneInputElement
									{...methods}
									readOnly
									colSize="col-lg-6"
									value={dealerNumber}
									name="twilioFromNumber"
									label="Your Number"
								/>
								<PhoneInputElement
									{...methods}
									colSize="col-lg-6"
									value={toNumber}
									onChange={setToNumber}
									onBlur={fetchHistory}
									name="twilioToNumber"
									label="Customer Number"
								/>
							</div>
							{templateOptions.length > 0 && (
								<SelectorElement
									{...methods}
									colSize="col-lg-6"
									value={selectedTemplate}
									name="twilioMessageTemplateOptions"
									label="Message Template"
									onChange={(e) => setSelectedTemplate(e.target.value)}
									options={templateOptions}
								/>
							)}
							<MessageTemplateVariablesAndInput
								{...methods}
								body={messageBody}
								updateBody={setMessageBody}
							/>
						</div>
					)}
					{showPreview && <PreviewMessage message={previewMessage} />}
					<div className="col-lg-12 d-flex justify-content-center align-items-center">
						{messageBody.length > 0 && (
							<div className="d-flex justify-content-center align-items-center my-2">
								<Button onClick={() => setShowPreview(!showPreview)}>
									{!showPreview ? "Preview" : "Close"}
								</Button>
							</div>
						)}
						{!showPreview && (
							<>
								<div
									className="btn btn-md btn-info ml-3"
									onClick={methods.handleSubmit(sendMessage)}
								>
									<i className="fa fa-paper-plane" /> Send
								</div>
								{IN_DEVELOPMENT && (
									<Button onClick={() => console.log(buildMessage())}>
										JSON
									</Button>
								)}
							</>
						)}
					</div>
				</CardBody>
			</Card>
		</FormProvider>
	);
};

export default TwilioSendMessage;

const TwilioMessageHistory = ({ messages }) => {
	if (messages != null && messages.length > 0) {
		return (
			<>
				<hr className="my-5" />
				<div className="text-messages-container">
					<div className="text-messages">
						{messages.map((msg, id) => (
							<div
								key={id}
								className={
									msg.direction === "INBOUND"
										? "text-messages-received"
										: "text-messages-sent"
								}
							>
								<div>{msg.body}</div>
								<p className="text-messages-time">
									{dayjs(msg.dateSent).format("MMM D, YYYY [at] h:mmA")}
								</p>
							</div>
						))}
					</div>
				</div>
			</>
		);
	} else {
		return (
			<CardTitle>
				<div className="h3 text-primary">No text message history...</div>
			</CardTitle>
		);
	}
};
