import React from "react";

import useCurrentVehicleStore from "../../../store/InventoryStore";
import useRoute from "../../../helperFunction/UseRoute";

import {
	checkValidateDelete,
	deleteVehicle,
	removeVehicleFromDeal,
} from "../../../api/InventoryAPI";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../../Constants";
import {
	successAlertCustom,
	confirmDeleteMessage,
	deleteFailAlertWithText,
	twoOptionsAlert,
} from "../../../component/dc/modal/SweetAlerts";

import shallow from "zustand/shallow";

export const InventoryDeleteButton = ({ redirect = false }) => {
	const history = useHistory();
	const newRoute = useRoute(PATHS.INVENTORY_LIST);

	// Vehicle store
	const { inventoryID } = useCurrentVehicleStore(
		(state) => ({
			inventoryID: state.inventoryID,
		}),
		shallow
	);
	/*
show an option message when vehicle is in pending deal or reconditioning 
*/
	const DeleteVehicle = () => {
		let answer = "Cancel";
		confirmDeleteMessage("Yes, Delete it").then((res) => {
			answer = res;
			if (answer === "Yes, Delete it") {
				checkValidateDelete(inventoryID).then(
					(res) => {
						console.log(res);
						const r = res.data.content;
						const dealID = r.dealId;
						if (dealID.length > 0) {
							twoOptionsAlert(
								"This vehicle associate with pending deal, do you still want to delete it?",
								"Yes, Delete it",
								"Cancel"
							).then((res) => {
								answer = res;
								if (answer === "Yes, Delete it") {
									removeVehicleFromDeal(inventoryID, dealID).then(
										(res) => {
											if (redirect) {
												history.push(newRoute);
											}

											successAlertCustom(
												"Your vehicle's status has been changed to deleted."
											);
										},
										(error) => {
											if (!error.isGeneralError) {
												console.log(error);

												deleteFailAlertWithText(error?.response?.data?.message);
											}
										}
									);
								}
							});
						} else if (r.isRecon && dealID.length > 0) {
							twoOptionsAlert(
								"This vehicle has been reconditioned and it is in pending deal, do you still want to delete it?",
								"Yes, Delete it",
								"Cancel"
							).then((res) => {
								answer = res;
								if (answer === "Yes, Delete it") {
									removeVehicleFromDeal(inventoryID, dealID).then(
										(res) => {
											if (redirect) {
												history.push(newRoute);
											}

											successAlertCustom(
												"Your vehicle's status has been changed to deleted."
											);
										},
										(error) => {
											if (!error.isGeneralError) {
												console.log(error);

												deleteFailAlertWithText(error?.response?.data?.message);
											}
										}
									);
								}
							});
						} else {
							if (r.isRecon) {
								twoOptionsAlert(
									"This vehicle has been reconditioned, do you still want to delete it?",
									"Yes, Delete it",
									"Cancel"
								).then((res) => {
									answer = res;
									if (answer === "Yes, Delete it") {
										deleteVehicle(inventoryID).then(
											(res) => {
												if (redirect) {
													history.push(newRoute);
												}

												successAlertCustom(
													"Your vehicle's status has been changed to deleted."
												);
											},
											(error) => {
												if (!error.isGeneralError) {
													console.log(error);

													deleteFailAlertWithText(
														error?.response?.data?.message
													);
												}
											}
										);
									}
								});
							} else {
								deleteVehicle(inventoryID).then(
									(response) => {
										if (redirect) {
											history.push(newRoute);
										}

										successAlertCustom(
											"Your vehicle's status has been changed to deleted."
										);
									},
									(error) => {
										if (!error.isGeneralError) {
											console.log(error);

											deleteFailAlertWithText(error?.response?.data?.message);
										}
									}
								);
							}
						}
					},
					(error) => {
						if (!error.isGeneralError) {
							console.log(error);

							deleteFailAlertWithText(error?.response?.data?.message);
						}
					}
				);
			}
		});
	};
	return (
		<div>
			<div
				type="submit"
				className="btn btn-md btn-danger"
				onClick={() => DeleteVehicle()}
			>
				<i className="nc-icon nc-simple-remove"></i> Delete Vehicle
			</div>
		</div>
	);
};

export default InventoryDeleteButton;
