import React, { useEffect, useState } from "react";
import { Button, Form } from "reactstrap";

import tabOnEnter from "../../../helperFunction/tabOnEnter";
import InputElement from "../../formValidation/InputElement";
import InputWrapper from "../../formValidation/InputWrapper";
import { MessageTemplateVariablesAndInput } from "./MessageTemplateVariables";

import { object, setLocale, string } from "yup";
import { yupResolver } from "@hookform/resolvers";
import { FormProvider, useForm } from "react-hook-form";

const MessageTemplateDetails = ({ template, setTemplate, saveTemplate }) => {
	const [name, setName] = useState(template?.name || "");
	const [body, setBody] = useState(template?.body || "");
	const [subject, setSubject] = useState(template?.subject || "");

	// Updates the template object with local variables and calls save api
	const saveData = () => {
		const updatedTemplate = { ...template, name, body, subject };
		saveTemplate(updatedTemplate);
	};

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
		},
	});

	// Define rules for each input field
	const schema = object().shape({
		messageTemplateName: string().required().max(50),
		//messageBody: string().required().max(1600),
		messageTemplateSubject: string().max(50),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		setName(template?.name);
		setBody(template?.body);
		setSubject(template?.subject);
	}, [template]);

	return (
		<FormProvider {...methods}>
			<Form onKeyDown={tabOnEnter}>
				<InputWrapper
					inputComponents={[
						<InputElement
							{...methods}
							colSize="col-lg-4"
							value={name}
							onChange={(e) => setName(e.target.value)}
							name="messageTemplateName"
							label="Template Name"
							type="text"
						/>,
						<InputElement
							{...methods}
							colSize="col-lg-6"
							value={subject}
							onChange={(e) => setSubject(e.target.value)}
							name="messageTemplateSubject"
							label="Subject"
							type="text"
						/>,
						<MessageTemplateVariablesAndInput
							body={body}
							updateBody={setBody}
						/>,
					]}
					buttons={
						<div className="d-flex justify-content-center">
							<Button color="primary" onClick={methods.handleSubmit(saveData)}>
								Save
							</Button>
							<Button color="danger" onClick={() => setTemplate({})}>
								Clear
							</Button>
						</div>
					}
				/>
			</Form>
		</FormProvider>
	);
};

export default MessageTemplateDetails;
