import React from "react";
import { NavLink } from "react-router-dom";

import { CardFooter, Row } from "reactstrap";

function CardContentBottom({ navClass, routePath, iconClass, iconText }) {
	return (
		<>
			<hr />
			<CardFooter className="pt-3">
				<Row style={{ marginRight: "0", marginLeft: "0" }}>
					<NavLink className={navClass} to={routePath}>
						<div className="btn btn-sm btn-link">
							<i className={iconClass} />
							{iconText}
						</div>
					</NavLink>
				</Row>
			</CardFooter>
		</>
	);
}

export default CardContentBottom;
