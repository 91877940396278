import React, { useContext, useEffect } from "react";
import { Button } from "reactstrap";
import TradeTable from "./TradeTable";
import { FormProvider } from "react-hook-form";
import TradeModal from "./TradeModal";
import TradeProvider, { TradeContext } from "./TradeContext";
import useCurrentDealStore from "store/DealStore";
import shallow from "zustand/shallow";
import {
	KbbContext,
	KbbValuesProvider,
} from "../../../../view/inventory/inventoryDetailTab/value/kbb/KbbContext";
import ChildrenModal from "../../modal/ChildrenModal";

const TradeTab = () => {
	return (
		<div>
			<FormProvider>
				<TradeProvider>
					<KbbValuesProvider>
						<div className="col">
							<TradeComponentWrapper />
						</div>
					</KbbValuesProvider>
				</TradeProvider>
			</FormProvider>
		</div>
	);
};

export default TradeTab;

/**
 * Context is provided above so it can be utilized/initialized here
 */
const TradeComponentWrapper = () => {
	// Deal Store
	const { trades } = useCurrentDealStore(
		(state) => ({
			trades: state.trades,
		}),
		shallow
	);
	const { modalActive, toggleTradeModal, setCurrentIndex, reset } = useContext(
		TradeContext
	);
	const { reset: resetKbb } = useContext(KbbContext);
	useEffect(() => {
		setCurrentIndex(trades.length);
		// eslint-disable-next-line
	}, []);

	const add = () => {
		setCurrentIndex(trades.length);
		toggleTradeModal();
	};
	return (
		<>
			<Button className="mb-2 btn btn-primary btn-sm" onClick={add}>
				<i className="nc-icon nc-simple-add"></i> Add Trade
			</Button>
			<ChildrenModal
				modal={modalActive}
				toggle={toggleTradeModal}
				modalTitle={"Add Trade"}
				children={<TradeModal />}
				onClosed={() => {
					reset();
					resetKbb();
				}}
			/>
			<TradeTable />
		</>
	);
};
