import React, { useEffect, useState, useCallback } from "react";

import { Form, Button, FormGroup } from "reactstrap";
import { useFromUser } from "store/LocalStorageHelper";
import {
	CurrencyInput,
	PercentInput,
} from "../../../../view/formValidation/InputElement";
import InputWrapper from "view/formValidation/InputWrapper";
import Selector from "../../../../component/dc/selector/Selector";
import useCurrentDealStore from "../../../../store/DealStore";
import useCurrentRecapStore from "../../../../store/RecapStore";
import { commTypeList } from "Constants";
import DealSalesmanDropDown from "../dealDetails/DealSalesmanDropdown";
const CommissionForm = ({ currentIndex, setCurrentIndex, toggle }) => {
	const {
		dealRecNum,
		commissions,
		editCommissions,
		salesPeople,
		calculate,
	} = useCurrentDealStore((state) => ({
		dealRecNum: state.deal.ID,
		commissions: state.commissions,
		editCommissions: state.editCommissions,
		salesPeople: state.salesPeople,
		calculate: state.calculate,
	}));
	const { fiGross, salesGross, totalGross, grossProfit } = useCurrentRecapStore(
		(state) => ({
			fiGross: state.fiGross,
			salesGross: state.salesGross,
			totalGross: state.totalGross,
			grossProfit: state.grossProfit,
		})
	);

	// Local storage
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const [personRecNum, setPersonRecNum] = useState(null);
	const [commType, setCommType] = useState(10);
	const [commAmount, setCommAmount] = useState(0);
	const [bonus, setBonus] = useState(0);
	const [other, setOther] = useState(0);
	const [commPercent, setCommPercent] = useState(0);
	const [totalComm, setTotalComm] = useState(0);
	const [commissionable, setCommissionable] = useState(0);
	const [buttonText, setButtonText] = useState("Add Commission");
	const [minCommission, setMinCommission] = useState(0);
	const [fixedAmount, setFixedAmount] = useState(0);

	const changeText = (text) => setButtonText(text);

	// Clears local states
	const clearFields = () => {
		changeText("Add Commission");
		setPersonRecNum(0);
		setCommType(10);
		setFixedAmount(0);
		setCommAmount(0);
		setBonus(0);
		setOther(0);
		setCommPercent(0);
		setCurrentIndex(commissions.length);
	};

	const getID = () => {
		if (commissions[currentIndex] === undefined) {
			return null;
		} else if (commissions[currentIndex].id === undefined) {
			return null;
		} else {
			return commissions[currentIndex].id;
		}
	};
	console.log(commissions);
	// Toggles the add commission modal

	const addCommission = () => {
		const id = getID();
		const newCommission = {
			id,
			personRecNum,
			commType,
			commAmount: parseFloat(commAmount),
			bonus: parseFloat(bonus),
			other: parseFloat(other),
			commPercent: parseFloat(commPercent),
			fixedAmount: parseFloat(fixedAmount),
			totalComm: parseFloat(totalComm),
			dealRecNum,
			dealerID,
			locationID,
		};
		console.log(newCommission);

		const commList = () => {
			let obj = [...commissions];
			//newCommission.ID = response.data.content.ID;
			obj[currentIndex] = newCommission;
			return obj;
		};
		const newCommissions = commList();
		editCommissions(newCommissions);
		clearFields();
		calculate();
		toggle();
	};

	// eslint-disable-next-line
	const calculateCommission = useCallback(() => {
		let amount = 0;
		console.log(Number(commType));
		console.log(Number(commType) === commTypeList.SALE.value);
		console.log(Number(salesGross * commPercent));

		if (Number(commType) === commTypeList.SALE.value) {
			amount = twoDecimal(salesGross * commPercent);
			setCommissionable(salesGross);
		} else if (Number(commType) === commTypeList.FI.value) {
			amount = twoDecimal(fiGross * commPercent);
			setCommissionable(fiGross);
		} else if (Number(commType) === commTypeList.TOTAL.value) {
			amount = twoDecimal(totalGross * commPercent);
			setCommissionable(totalGross);
		} else if (Number(commType) === commTypeList.NETPROFIT.value) {
			amount = twoDecimal(grossProfit * commPercent);
			setCommissionable(grossProfit);
		}

		if (fixedAmount > 0) amount += twoDecimal(fixedAmount);
		if (amount < minCommission) amount = twoDecimal(minCommission);
		setCommAmount(amount);

		let totalCommission =
			parseFloat(amount) + parseFloat(bonus) + parseFloat(other);

		setTotalComm(totalCommission);
		return totalCommission;
	});
	const twoDecimal = (value) => {
		return parseFloat(parseFloat(value).toFixed(2));
	};

	useEffect(() => {
		calculateCommission();
		calculate();
		// eslint-disable-next-line
	}, [commType, commPercent, bonus, other, fixedAmount]);

	useEffect(() => {
		console.log(currentIndex);
		console.log(commissions[currentIndex]?.id);

		const obj = { ...commissions[currentIndex] };
		console.log(obj);
		setPersonRecNum(obj?.personRecNum || null);
		setCommType(obj?.commType || 10);
		setFixedAmount(obj?.fixedAmount || 0);
		setCommPercent(obj?.commPercent || 0);
		setCommAmount(obj?.commAmount || 0);
		setBonus(obj?.bonus || 0);
		setOther(obj?.other || 0);
		setTotalComm(obj?.totalComm || 0);
		changeText("Save Commission");
		return clearFields;
		// eslint-disable-next-line
	}, [currentIndex]);
	useEffect(() => {
		console.log(personRecNum);
		console.log(commissions.length === currentIndex);

		if (commissions.length === currentIndex) {
			const SalesPersonValue = salesPeople.filter(
				(salespersonList) => salespersonList.ID === personRecNum
			);
			if (SalesPersonValue.length > 0) {
				setFixedAmount(SalesPersonValue[0].commissionFixedAmount);
				setMinCommission(SalesPersonValue[0].commissionMinimum);
				setCommPercent(SalesPersonValue[0].commissionPercent);
			}
		}
		calculate();
		// eslint-disable-next-line
	}, [personRecNum]);

	// const updateCommissionVal = (val, key) => {
	// 	let newCommissions = [...commissions];
	// 	let updatedCommission = { ...commissions[currentIndex] };
	// 	updatedCommission[key] = val;
	// 	newCommissions[currentIndex] = updatedCommission;
	// 	editCommissions(newCommissions);
	// };
	return (
		<div>
			<Form className="px-0">
				<InputWrapper
					inputComponents={[
						<DealSalesmanDropDown
							label={"Person"}
							isComm={true}
							setPersonRecNum={setPersonRecNum}
							personRecNum={personRecNum}
						/>,
						<FormGroup className="col-md-3">
							<Selector
								selectedOption={
									commType === null || commType === "" ? 0 : commType
								}
								handleChange={(e) => {
									setCommType(e);
								}}
								selectType="commType"
								selectLabel="Commission Type"
								labelClass=" required"
							/>
						</FormGroup>,
						<CurrencyInput
							value={commissionable}
							readOnly={true}
							onChange={setCommissionable}
							name="commissionable"
							label="Commissionable"
							type="Currency"
							disableValidation
						/>,
						<PercentInput
							value={commPercent}
							onChange={setCommPercent}
							name="commPercent"
							label="Percent"
							disableValidation
						/>,
						<CurrencyInput
							// value={commissions[currentIndex].fixedAmount}
							// onChange={updateCommissionVal}
							value={fixedAmount}
							onChange={setFixedAmount}
							name="fixedAmount"
							label="Fixed Amount"
							type="Currency"
							disableValidation
						/>,
						<CurrencyInput
							readOnly={true}
							value={minCommission}
							onChange={setMinCommission}
							name="Minimum"
							label="Minimum"
							type="Currency"
							disableValidation
						/>,
						<CurrencyInput
							value={commAmount}
							onChange={setCommAmount}
							readOnly={true}
							name="commAmount"
							label="Amount"
							type="Currency"
							disableValidation
						/>,
						<CurrencyInput
							value={bonus}
							onChange={setBonus}
							name="bonus"
							label="Bonus"
							type="Currency"
							disableValidation
						/>,
						<CurrencyInput
							value={other}
							onChange={setOther}
							name="other"
							label="Other"
							type="Currency"
							disableValidation
						/>,
						<CurrencyInput
							value={totalComm}
							onChange={setTotalComm}
							readOnly={true}
							name="totalComm"
							label="Total"
							type="Currency"
							disableValidation
						/>,
					]}
					buttons={
						<div className="d-flex justify-content-center mt-2">
							<Button color="primary" onClick={addCommission}>
								{buttonText === "Save Commission" ? (
									<i className="nc-icon nc-check-2"></i>
								) : (
									<i className="nc-icon nc-simple-add"></i>
								)}
								{buttonText}
							</Button>
							<div className="btn btn-link btn-md" onClick={clearFields}>
								Reset
							</div>
						</div>
					}
				/>
			</Form>
		</div>
	);
};

export default CommissionForm;
