import React from "react";

/**
 * The image container for the reordered images.
 */
const DraggablePhoto = ({
	index,
	onClick,
	photo,
	margin,
	direction,
	top,
	left,
}) => {
	// Image properties
	const imgWithClick = { cursor: "pointer" };
	const imgStyle = { margin };

	if (direction === "column") {
		imgStyle.position = "absolute";
		imgStyle.left = left;
		imgStyle.top = top;
	}

	// Click handler when image is clicked to be dragged
	const handleClick = (event) => {
		onClick(event, { photo, index });
	};

	return (
		<img
			style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
			width={photo.width || "350px"}
			height={photo.height || "240px"}
			alt={photo.alt}
			src={photo.src}
			srcSet={photo.srcSet}
			onClick={onClick ? handleClick : null}
		/>
	);
};

export default DraggablePhoto;
