import React, { useState } from "react";
import { CardBody } from "reactstrap";

import useCalculateSales from "helperFunction/deal/useCalculateSales";
import Aftermarket from "component/dc/deal/sale/saleComponents/Aftermarket";
import ChildrenModal from "../../../../component/dc/modal/ChildrenModal";
import CommissionTable from "../../../../component/dc/table/CommissionTable";
import ContractInfoDetail from "../../../../component/dc/deal/dealDetails/ContractInfoDetail";
import DMVFee from "../../../../component/dc/deal/sale/saleComponents/DMVFee";
import InsuranceAndGAP from "component/dc/deal/sale/saleComponents/InsuranceAndGAP";
import OpenTotalModal from "../../../../component/dc/deal/sale/OpenTotalModal";
import SellerFees from "component/dc/deal/sale/saleComponents/SellerFees";
import ServiceContract from "component/dc/deal/sale/saleComponents/ServiceContract";

const TemplateSale = () => {
	const [openCommissions, setOpenCommissions] = useState(false);

	useCalculateSales();

	// Toggles commission modal
	const toggleCommissions = () => setOpenCommissions(!openCommissions);

	return (
		<CardBody>
			<DMVFee />
			<SellerFees />
			<InsuranceAndGAP />
			<ServiceContract />
			<Aftermarket />
			<ContractInfoDetail />
			<OpenTotalModal
				btnClass="w-100 btn btn-info btn-md px-0 col-sm-6"
				label="Commissions"
				toggle={toggleCommissions}
			/>
			<ChildrenModal
				modal={openCommissions}
				toggle={toggleCommissions}
				size="xl"
				modalTitle="Commissions"
			>
				<CommissionTable />
			</ChildrenModal>
		</CardBody>
	);
};

export default TemplateSale;
