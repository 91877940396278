// Returns a list ids of all the equipment that have the selected
// property set to true
// Keep this
export const retrieveAllSelected = (arr) => {
	if (arr == null || arr.length < 1) {
		console.log("No equipment are available.");
		return;
	}

	let idArr = [];

	for (let i = 0; i < arr.length; i++) {
		if (arr[i].isSelected) {
			idArr.push(arr[i].vehicleOptionID);
		}
	}

	return idArr;
};

// Returns an equipment object. If the key for equipment group exists,
// then return with the value array appended, else return new key as
// equipment group and value as an array of equipment names
// keep this
export const setEquipObj = (obj, key, val) => {
	if (obj.hasOwnProperty([key])) {
		return {
			...obj,
			[key]: [...obj[key], val],
		};
	}

	return { ...obj, [key]: [val] };
};
