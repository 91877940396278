import React from "react";
import { FormProvider } from "react-hook-form";
import VendorSettings from "./VendorSettings";
import NavBar from "component/dc/tab/NavTabsHolder";

const VendorSettingTab = () => {
	const tabs = [
		"Vendor",
		"Warranty",
		"Lender",
		"Recon",
		"Aftermarket",
		"Flooring",
		"Gap/Insurance",
		"Shipping",
	];

	const tabComponents = [
		<VendorSettings useType={10} vendorType="vendor" />,
		<VendorSettings useType={20} vendorType="warranty" />,
		<VendorSettings useType={30} vendorType="lender" />,
		<VendorSettings useType={50} vendorType="recon" />,
		<VendorSettings useType={60} vendorType="aftermarket" />,
		<VendorSettings useType={70} vendorType="flooring" />,
		<VendorSettings useType={90} vendorType="gap" />,
		<VendorSettings useType={40} vendorType="shipping" />,
	];
	return (
		<div>
			<FormProvider>
				<NavBar
					navClass="nav-pills-primary justify-content-center nav nav-pills"
					tabs={tabs}
					tabComponents={tabComponents}
				/>
			</FormProvider>
		</div>
	);
};

export default VendorSettingTab;
