import React, { memo } from "react";
import { Input, Row } from "reactstrap";
import NumberFormat from "react-number-format";

/**
 * Takes in the label for this input field, the value that this input field is connected to,
 * the value's onChange function, the sign for this field (+ if we are adding to total, - if not),
 * and the color of the sign
 */
const LabelAndInput = memo(
	({
		label,
		value,
		onChange,
		readOnly = false,
		disabled = false,
		buttonLabel = false,
		btnClass,
		className = "bold",
		name,
	}) => {
		// regex for money
		// const money = /^\d*\.?\d{1,2}$/;

		// Returns the label for the field, the sign (+ or -), and the input field

		return (
			<Row>
				<div className="col-sm-6 px-0">
					{buttonLabel && <div className={btnClass}>{label}</div>}
				</div>
				<div className="col-sm-6 pr-0 dealInputs pl-2">
					<NumberFormat
						className={className}
						name={name}
						value={value}
						thousandSeparator={true}
						decimalScale={2}
						fixedDecimalScale={true}
						onFocus={(e) => e.target.select()}
						prefix={"$"}
						isNumericString={true}
						disabled={disabled}
						readOnly={readOnly}
						customInput={Input}
						onValueChange={(values) => {
							if (!disabled) {
								if (values.value === "") {
									onChange(0);
								} else {
									onChange(values.floatValue);
								}
							}
						}}
					/>
				</div>
			</Row>
		);
	},
	(prevProps, nextProps) => prevProps.value === nextProps.value
);

export default LabelAndInput;
