import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";

import CenteredLoadingSpinner from "component/dc/load/CenteredLoadingSpinner";
import ChildrenModal from "component/dc/modal/ChildrenModal";
import CountyTaxDetails from "./CountyTaxDetails";
import CountyTaxTable from "component/dc/table/CountyTaxTable";

import { failAlert } from "component/dc/modal/SweetAlerts";
import { getStateAndCustomTaxRate } from "api/DealAPI";

import { object, setLocale, string } from "yup";
import { yupResolver } from "@hookform/resolvers";
import { FormProvider, useForm } from "react-hook-form";
import {
	useFromLocations,
	useFromUser,
} from "../../../../../store/LocalStorageHelper";

const CountyTax = ({ toggle }) => {
	// Login Store
	// const { dealerState, dealerID, locationID } = useCurrentLoginStore(
	// 	(state) => ({
	// 		dealerState: state.state,
	// 		dealerID: state.dealerID,
	// 		locationID: state.locationID,
	// 	}),
	// 	shallow
	// );
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const dealerState = useFromLocations("state");

	// Local states
	const [taxData, setTaxData] = useState(null);
	const [taxDetails, setTaxDetails] = useState(null);
	const [noData, setNoData] = useState(false);
	const [openTaxDetails, setOpenTaxDetails] = useState(false);

	// Toggle for details modal
	const toggleTaxDetails = () => setOpenTaxDetails(!openTaxDetails);

	// Sets the details for the county tax details component
	const viewData = (details) => {
		setTaxDetails(details);
		toggleTaxDetails();
	};

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
			number: "Must be a number",
		},
	});

	// Define rules for each input field
	const schema = object().shape({
		state: string().required(),
		city: string().required().max(50),
		zip: string()
			.required()
			.matches(/^[\d]{5}$/, { message: "Must be a 5 digit number" }),
		taxRate1: string().required(),
		taxRate2: string().required(),
		taxRate3: string().required(),
		taxRate4: string().required(),
		taxRate5: string().required(),
		taxRate6: string().required(),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	// Load the county tax information for dealer state
	useEffect(() => {
		console.log(dealerState != null && dealerID != null && locationID != null);
		console.log({ dealerState, dealerID, locationID });
		if (dealerState != null && dealerID != null && locationID != null) {
			console.log(dealerState);
			getStateAndCustomTaxRate(dealerID, locationID, dealerState).then(
				(res) => setTaxData(res.data.content),
				(err) => {
					setNoData(true);
					failAlert(
						err?.response?.data?.message ||
							"Failed to retrieve county tax information"
					);
				}
			);
		}
	}, [dealerState, dealerID, locationID]);

	return (
		<div>
			<Button
				className="ml-3 mb-3"
				color="primary"
				onClick={() => {
					setTaxDetails({ isCustom: true });
					toggleTaxDetails();
				}}
			>
				<i className="nc-icon nc-simple-add"></i> Add Custom Tax
			</Button>
			{taxData == null && <CenteredLoadingSpinner />}
			{dealerState != null && taxData != null && (
				<CountyTaxTable data={taxData} viewData={viewData} />
			)}
			{taxDetails != null && (
				<ChildrenModal
					modalTitle={`Tax Information for ${taxDetails.city}, ${taxDetails.state} - ${taxDetails.zip}`}
					modal={openTaxDetails}
					toggle={toggleTaxDetails}
					size="lg"
				>
					<FormProvider {...methods}>
						<CountyTaxDetails
							details={taxDetails}
							toggleTable={toggle}
							toggleDetails={toggleTaxDetails}
						/>
					</FormProvider>
				</ChildrenModal>
			)}
			{noData && (
				<h6 style={{ color: "red" }} className="d-flex justify-content-center">
					No county tax information found for the state of: {dealerState}
				</h6>
			)}
		</div>
	);
};

export default CountyTax;
