import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col } from "reactstrap";

import useCurrentDealStore from "store/DealStore";
import LenderModal from "../LenderModal";
import UACApplicationStatus from "./UACApplicationStatus";
import GenericLenderForm from "../GenericLenderForm";
import UAClogo from "../../../../assets/img/UACC.svg";

import { checkUACSubmitted } from "api/dealerIntegrationsApi";
import shallow from "zustand/shallow";
import { submitUACApp } from "../../../../api/dealerIntegrationsApi";

const UAC = () => {
	// Modal toggles
	const [toggleApplication, setToggleApplication] = useState(false);
	const [toggleStatus, setToggleStatus] = useState(false);

	// Local states
	const [isAppSubmitted, setIsAppSubmitted] = useState(false);

	// Deal store
	const { ID } = useCurrentDealStore(
		(state) => ({
			ID: state.deal.ID,
		}),
		shallow
	);

	// Toggles the modal to open or close
	const toggleApplicationModal = () => setToggleApplication(!toggleApplication);
	const toggleStatusModal = () => setToggleStatus(!toggleStatus);

	// Buttons that opens the application modal
	const applicationButton = (resubmit) => (
		<Button size="md" className="w-100 btn" onClick={toggleApplicationModal}>
			{resubmit === true ? "Resubmit" : "Send App"}
		</Button>
	);

	// Buttons that opens the status modal
	const statusButton = () => (
		<Button size="md" className="w-100 btn" onClick={toggleStatusModal}>
			Status
		</Button>
	);

	useEffect(() => {
		ID != null &&
			checkUACSubmitted(ID).then(
				(res) => setIsAppSubmitted(res.data.content),
				(err) => console.log(err)
			);
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<Card>
				<CardBody>
					<img src={UAClogo} alt="UAC Logo" className="rounded lenderImg" />
					<Col>
						{!isAppSubmitted && (
							<LenderModal
								ButtonComponent={applicationButton}
								modalSize="xl"
								modalTitle="UACC Application"
								toggle={toggleApplication}
								toggleModal={toggleApplicationModal}
							>
								<GenericLenderForm
									toggleApplicationModal={toggleApplicationModal}
									setIsAppSubmitted={setIsAppSubmitted}
									submitToLender={submitUACApp}
								/>
							</LenderModal>
						)}
						{isAppSubmitted && (
							<div className="d-flex justify-content-space-between">
								<LenderModal
									ButtonComponent={statusButton}
									modalSize="lg"
									modalTitle="UACC Application Status"
									toggle={toggleStatus}
									toggleModal={toggleStatusModal}
								>
									<UACApplicationStatus />
								</LenderModal>
								<LenderModal
									ButtonComponent={() => applicationButton(true)}
									modalSize="xl"
									modalTitle="UACC Application"
									toggle={toggleApplication}
									toggleModal={toggleApplicationModal}
								>
									<GenericLenderForm
										toggleApplicationModal={toggleApplicationModal}
										setIsAppSubmitted={setIsAppSubmitted}
										submitToLender={submitUACApp}
									/>
								</LenderModal>
							</div>
						)}
					</Col>
				</CardBody>
			</Card>
		</>
	);
};

export default UAC;
