import React, { useState, useContext, useEffect } from "react";
import shallow from "zustand/shallow";
import { saveVehicle } from "../../api/InventoryAPI";
import {
	saveFailAlert,
	saveSuccessAlert,
} from "../../component/dc/modal/SweetAlerts";
import { showApiError } from "../../helperFunction/ApiErrorHandler";
import useBuildVehicle from "../../helperFunction/BuildInventoryObj";
import useCurrentVehicleStore from "../../store/InventoryStore";
import InventoryKbbTab from "./inventoryDetailTab/value/kbb/InventoryKbbTab";
import {
	KbbContext,
	KbbValuesProvider,
} from "./inventoryDetailTab/value/kbb/KbbContext";
import { vehicleStatus } from "../../Constants";
import CenteredLoadingSpinner from "../../component/dc/load/CenteredLoadingSpinner";

const InventoryAppraisalPage = () => {
	return (
		<AppraisalWrapper>
			<AppraisalContent />
		</AppraisalWrapper>
	);
};

export default InventoryAppraisalPage;

const AppraisalWrapper = ({ children }) => {
	return <KbbValuesProvider>{children}</KbbValuesProvider>;
};

const AppraisalContent = () => {
	// Progress spinner state
	const [showSubmitProgress, setShowSubmitProgress] = useState(false);
	// kbb vehicle values
	const {
		reset,
		inventoryID,
		stockNo,
		vin,
		year,
		make,
		model,
		odometerIn,
		editOdometerIn,
		editVin,
		editYear,
		editMake,
		editModel,
	} = useCurrentVehicleStore((state) => state, shallow);

	const vehicleKbbObj = {
		stockNo,
		vin,
		year,
		make,
		model,
		odometerIn,
		editOdometerIn,
		editVin,
		editYear,
		editMake,
		editModel,
	};

	const { getDcKbbObj, setCondition, reset: resetKbb } = useContext(KbbContext);

	useEffect(() => {
		reset();
		resetKbb();
		return () => {
			reset();
			resetKbb();
		};
		// eslint-disable-next-line
	}, []);
	// Vehicle obj
	const getVehicle = useBuildVehicle();

	// Saves a vehicle without any images (can be new or existing)
	const saveData = () => {
		setShowSubmitProgress(true);
		const inventoryVehicle = getVehicle();
		console.log(inventoryVehicle);
		inventoryVehicle.stockNo = vin.slice(11);
		inventoryVehicle.status = vehicleStatus.APPRAISAL;
		const kbb = getDcKbbObj();
		const request = {
			vehicle: inventoryVehicle,
			kbb: kbb,
		};
		saveVehicle(request).then(
			(response) => {
				setCondition("Good");
				reset();
				resetKbb();
				saveSuccessAlert();
				setShowSubmitProgress(false);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
					setShowSubmitProgress(false);
				}
			}
		);
	};
	return (
		<div className="content">
			{(showSubmitProgress && <CenteredLoadingSpinner />) || (
				<>
					<InventoryKbbTab
						inventoryID={inventoryID}
						vehicleKbbObj={vehicleKbbObj}
					/>
					<div
						className="btn btn-lg btn-success float-button"
						onClick={saveData}
					>
						<i className="nc-icon nc-check-2"></i>&nbsp;Save
					</div>
				</>
			)}
		</div>
	);
};
