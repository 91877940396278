import React, { useState } from "react";
import { Col, Row, Table, Button, CardText, Card, CardBody } from "reactstrap";

import useCurrentDealStore from "../../../../../../store/DealStore";
import {
	confirmDeleteAlert,
	deleteFailAlert,
	deleteSuccessAlert,
} from "../../../../../../component/dc/modal/SweetAlerts";
import { CurrencyDisplayElement } from "../../../../../../component/dc/label/DisplayElement";
//import { DealStatus } from "../../../../../../Constants";
import ChildrenModal from "component/dc/modal/ChildrenModal";
import SellerFeesEditForm from "./SellerFeeEditForm";
import { deleteMiscSellerFee } from "api/DealAPI";
import { showApiError } from "helperFunction/ApiErrorHandler";
import shallow from "zustand/shallow";
import { DealStatus } from "../../../../../../Constants";
import NumberFormat from "react-number-format";
import useCurrentSaleStore from "../../../../../../store/SaleStore";

const SellerFeesTable = () => {
	const {
		miscSellerFees,
		editMiscSellerFees,
		sellerFeeTypes,
		type,
		calculate,
	} = useCurrentDealStore(
		(state) => ({
			miscSellerFees: state.miscSellerFees,
			editMiscSellerFees: state.editMiscSellerFees,
			sellerFeeTypes: state.sellerFeeTypes,
			type: state.deal.type,
			calculate: state.calculate,
		}),
		shallow
	);
	const { totalSellerFees } = useCurrentSaleStore(
		(state) => ({
			totalSellerFees: state.totalSellerFees,
		}),
		shallow
	);
	// convert sellerFeeTypes into optionlist for selectorElement
	const optionList = [];
	for (const key in sellerFeeTypes) {
		optionList.push({
			label: sellerFeeTypes[key].description,
			value: sellerFeeTypes[key].type,
		});
	}
	const sellerFeesHead = [
		"#",
		"Type",
		"Amount",
		"Company",
		"Taxable",
		"Cost",
		"Actions",
	];

	const removeMiscSellerFee = (ID, index) => {
		console.log("Removing misc seller fee with ID: " + ID);
		let obj = [];
		if (ID) {
			deleteMiscSellerFee(ID).then(
				(response) => {
					console.log(response);
				},
				(err) => {
					console.error(err);

					if (!err.isGeneralError) {
						showApiError(err, deleteFailAlert);
					}
				}
			);
		}

		if (ID !== null) {
			obj = miscSellerFees.filter((item) => item.ID !== ID);
		} else {
			obj = miscSellerFees.filter((item) => item.currentIndex !== index);
		}
		editMiscSellerFees(obj);
		setCurrentIndex(obj.length);
		calculate();
		deleteSuccessAlert();
	};

	const [currentIndex, setCurrentIndex] = useState(miscSellerFees.length);
	const [showAddSellerFee, setShowAddSellerFee] = useState(false);
	const toggleAddSellerFee = () => setShowAddSellerFee(!showAddSellerFee);

	const toggleModal = (currentID, currentIndex) => {
		confirmDeleteAlert(removeMiscSellerFee, currentID, currentIndex);
	};

	const tableHead = sellerFeesHead.map((key) => <th>{key}</th>);
	const rows = miscSellerFees.map((key, index) => (
		<tr key={index}>
			<th scope="row">{index + 1}</th>
			<td>{optionList.filter((row) => row.value === key.type)[0]?.label}</td>
			<td>{<CurrencyDisplayElement val={key.amount} />}</td>
			<td>{key.company}</td>
			<td>{key.isTaxable ? "Yes" : "No"}</td>
			<td>{<CurrencyDisplayElement val={key.cost} />}</td>
			<td>
				{type === DealStatus.PENDING && (
					<div className="d-flex justify-content-center">
						<div
							className="btn btn-md btn-primary"
							onClick={() => {
								setCurrentIndex(index);
								toggleAddSellerFee();
								console.log(index);
							}}
						>
							<i className="nc-icon nc-align-center"></i>
							<span className="noMobile">Edit</span>
						</div>
						<div
							className="btn btn-md btn-link btn-link-danger text-danger ml-2"
							onClick={() => {
								console.log(key);
								console.log(key.ID + " " + index);
								toggleModal(key.ID, key.currentIndex);
							}}
						>
							X
						</div>
					</div>
				)}
			</td>
		</tr>
	));
	console.log(miscSellerFees.length);
	return (
		<>
			<ChildrenModal
				modal={showAddSellerFee}
				toggle={toggleAddSellerFee}
				modalTitle="Seller Fee"
				size="lg"
			>
				<SellerFeesEditForm
					currentIndex={currentIndex}
					setCurrentIndex={setCurrentIndex}
					toggle={toggleAddSellerFee}
					optionList={optionList}
				/>
			</ChildrenModal>
			{
				<>
					<Table striped bordered hover>
						<thead>
							<tr>{tableHead}</tr>
						</thead>
						<tbody>{rows}</tbody>
					</Table>
					<Card>
						<CardBody>
							<Row>
								<Col sm="6">
									<CardText tag="h5" className="text-center">
										Total Seller Fees
									</CardText>
								</Col>
								<Col sm="6">
									<CardText tag="h5" className="text-center mt-0">
										<NumberFormat
											value={totalSellerFees}
											thousandSeparator={true}
											decimalScale={2}
											fixedDecimalScale={true}
											prefix={"$"}
											isNumericString={true}
											displayType="text"
											disabled
										/>
									</CardText>
								</Col>
							</Row>
						</CardBody>
					</Card>

					{type === DealStatus.PENDING && (
						<Table>
							<Row>
								<Col>
									<Button
										className="btn btn-secondary btn-md"
										onClick={toggleAddSellerFee}
									>
										<i className="nc-icon nc-simple-add" />
										Add Seller Fee
									</Button>
								</Col>
								<Col></Col>
								<Col></Col>
								<Col></Col>
								<Col></Col>
							</Row>
						</Table>
					)}
				</>
			}
		</>
	);
};
export default SellerFeesTable;
