/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)

* Coded by DealerClick Software **RoarinLion))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useLocation } from "react-router-dom";

export const useRoute = (newPath) => {
	const location = useLocation();
	if (location.pathname.startsWith("/dev")) {
		return "/dev" + newPath;
	} else {
		return newPath;
	}
};

export default useRoute;
