import dayjs from "dayjs";
import { LoanPeriod } from "../../Constants.js";

export const calculateFinalDueDate = (loanPeriod, term, firstDueDate) => {
	let finalDate = dayjs(firstDueDate);
	switch (loanPeriod) {
		// Weekly - 52 times a year
		case 0:
			finalDate = finalDate.add(term - 1, "week");
			break;

		// Bi-Weekly
		case 1:
			finalDate = finalDate.add((term - 1) * 2, "week");
			break;

		// Semi-Monthly
		case 2:
			let days = 0;
			for (let i = 0; i < term - 1; i++) {
				if (finalDate.date() <= 15) {
					if (finalDate.month() === 1 && finalDate.date() + 15 > 28) {
						days = finalDate.date() + 15 - 28;
						finalDate = finalDate.add(15 - days, "day");
					} else {
						finalDate = finalDate.add(15 + days, "day");
						days = 0;
					}
				} else if (finalDate.date() > 15) {
					finalDate = finalDate.add(days - 15, "day");
					finalDate = finalDate.add(1, "month");
					days = 0;
				}
			}
			break;

		// Monthly
		case 3:
			finalDate = finalDate.add(term - 1, "month");
			break;

		// Annually
		case 4:
			finalDate = finalDate.add(term - 1, "year");
			break;

		// Cash
		case 5:
			break;

		case 6:
			finalDate = finalDate.add((term - 1) * 6, "month");
			break;

		default:
			break;
	}
	return finalDate.format("YYYY-MM-DD");
};

export const calculateFirstDueDate = (daysToPay, loanDate) => {
	const firstDate = dayjs(loanDate).add(daysToPay, "day");
	return firstDate.format("YYYY-MM-DD");
};
// Typically called on values while calculating
export const convertToInt = (floatNum) => {
	return floatNum * 100;
};
// typically called on values returned from calculation
export const convertToFloat = (intNum) => {
	return intNum / 100;
};
// truncates to cents
export const convertToDollar = (value) => {
	if (value === null) return 0;
	return parseFloat(parseFloat(value).toFixed(2));
};

export const calculateAmountFinanced = (
	price,
	totalDMVFees,
	totalSellerFees,
	gap,
	warranty,
	aftmkt,
	down,
	totalPickupPmts,
	totalNetTrade,
	taxType,
	tax,
	deferredTax,
	manufacturerRebate,
	cancellationAmt,
	cancellationAccepted,
	collisionTotPremium,
	lifePremium,
	disabilityPremium
) => {
	const amountFinanced =
		(price ? convertToInt(price) : 0) +
		(totalDMVFees ? convertToInt(totalDMVFees) : 0) +
		(totalSellerFees ? convertToInt(totalSellerFees) : 0) +
		(gap ? convertToInt(gap) : 0) +
		(warranty ? convertToInt(warranty) : 0) +
		(aftmkt ? convertToInt(aftmkt) : 0) -
		(down ? convertToInt(down) : 0) -
		(totalPickupPmts ? convertToInt(totalPickupPmts) : 0) -
		(manufacturerRebate ? convertToInt(manufacturerRebate) : 0) -
		(totalNetTrade ? convertToInt(totalNetTrade) : 0) +
		(tax ? convertToInt(tax) : 0) +
		(cancellationAccepted === 1 ? convertToInt(cancellationAmt) : 0) +
		//		(bankFee ? convertToInt(bankFee) : 0) +
		(collisionTotPremium ? convertToInt(collisionTotPremium) : 0) +
		(lifePremium ? convertToInt(lifePremium) : 0) +
		(disabilityPremium ? convertToInt(disabilityPremium) : 0) -
		(taxType !== null && taxType === 1 ? convertToInt(deferredTax) : 0); //if Tax is Defered (taxType === 1)
	console.log(deferredTax, taxType);
	console.log(cancellationAmt + "Amount Financed: " + amountFinanced);
	return convertToDollar(convertToFloat(amountFinanced));
};

// No idea what V or MOANN are, copied from VB6
export const calculatePayment = (
	apr,
	loanPeriod,
	term,
	amtFinanced,
	loanDate,
	firstDueDate,
	bankFee
) => {
	// console.log({
	// 	apr,
	// 	loanPeriod,
	// 	term,
	// 	amtFinanced,
	// 	loanDate,
	// 	firstDueDate,
	// 	bankFee,
	// });
	console.log(bankFee);
	let payment;
	if (loanPeriod === 5) {
		payment = amtFinanced;
	} else if (apr === 0) {
		payment = amtFinanced / term || 0;
	} else {
		let amtF = bankFee + amtFinanced;
		const MOANN = calculateMOANN(apr, loanPeriod, term, loanDate, firstDueDate);
		payment = amtF / MOANN || 0;
	}
	return parseFloat(convertToDollar(payment));
	//return payment;
};
// export const calculateFinalPayment = (payment, term, loan, deferredTax) => {
// 	console.log("payment", payment);
// 	console.log("term", term);
// 	console.log("loan", loan);
// 	console.log("deferredTax", deferredTax);
// 	const finalPmt = loan + deferredTax - payment * (term - 1);
// 	return finalPmt;
// };

export const calculateFinalPayment = (apr, payment, term, amtFinanced) => {
	console.log({ apr, payment, term, amtFinanced });
	let totalPayment = payment * term;
	if (term === 1) {
		return 0;
	} else if (apr === 0) {
		const finalPmt =
			payment + amtFinanced - convertToFloat(convertToInt(totalPayment));
		return convertToDollar(finalPmt);
	} else {
		return payment;
	}
};

export const calculateFinanceCharge = (
	amtFinanced,
	payment,
	finalPmt,
	term
) => {
	console.log({ amtFinanced, payment, finalPmt, term });
	if (term === 1) {
		return 0;
	} else {
		const financeCharge = finalPmt + payment * (term - 1) - amtFinanced;
		console.log({ finalPmt, payment, term, amtFinanced, financeCharge });
		return isNaN(financeCharge) ? 0 : Math.abs(convertToDollar(financeCharge));
	}
};

export const calculateLoan = (amtFinanced, financeCharge, deferredTax) => {
	console.log({ amtFinanced }, { financeCharge }, { deferredTax });
	const total = amtFinanced + financeCharge + deferredTax;
	console.log(total);
	return total;
};

export const calculateTaxes = (
	{ price, totalDMVFees, gap, warranty, dealerSmog, docFee },
	{
		gapTaxRate,
		docFeeTaxRate,
		dmvTaxRate,
		servContractTaxRate,
		dealerSmogTaxRate,
		taxRate,
	}
) => {
	// console.log("Amounts");
	// console.log(price);
	// console.log(totalDMVFees);
	// console.log(gap);
	// console.log(warranty);
	// console.log(dealerSmog);
	// console.log(docFee);
	// console.log("End Amounts");

	// console.log("Tax Rates");
	// console.log(gapTaxRate);
	// console.log(docFeeTaxRate);
	// console.log(dmvTaxRate);
	// console.log(servContractTaxRate);
	// console.log(dealerSmogTaxRate);
	// console.log(taxRate);
	// console.log("End Rates");

	const priceTaxes = price * taxRate;
	const DMVTaxes = totalDMVFees * (dmvTaxRate !== -1 ? dmvTaxRate : taxRate);
	const gapTaxes = gap * (gapTaxRate !== -1 ? gapTaxRate : taxRate);
	const warrantyTaxes =
		warranty * (servContractTaxRate !== -1 ? servContractTaxRate : taxRate);
	const smogTaxes =
		dealerSmog * (dealerSmogTaxRate !== -1 ? dealerSmogTaxRate : taxRate);
	const docTaxes = docFee * (docFeeTaxRate !== -1 ? docFeeTaxRate : taxRate);

	// console.log("Taxes");
	// console.log(priceTaxes);
	// console.log(DMVTaxes);
	// console.log(gapTaxes);
	// console.log(warrantyTaxes);
	// console.log(smogTaxes);
	// console.log(docTaxes);
	// console.log("End Taxes");

	const tax =
		priceTaxes + DMVTaxes + gapTaxes + warrantyTaxes + smogTaxes + docTaxes;
	console.log(tax);
	return convertToDollar(tax ? tax : 0);
};

export const calcLicenseFee = (licenseFee, licensePct, price) => {
	let total =
		parseFloat(licenseFee ? licenseFee : 0) +
		parseFloat(
			licensePct ? (parseFloat(licensePct) * parseFloat(price)).toFixed(0) : 0
		);

	total = parseFloat(total);
	return total;
};

// Return a memoized callback of the registration fee calculation
export const calcRegistrationFee = (
	registrationFee,
	registrationPct,
	price
) => {
	let total =
		parseFloat(registrationFee ? registrationFee : 0) +
		parseFloat(
			registrationPct
				? (parseFloat(registrationPct) * parseFloat(price)).toFixed(0)
				: 0
		);

	total = parseFloat(total);
	return total;
};
// Return a memoized version of the total calculations
export const calcDMVFees = (
	license,
	registration,
	titleFee,
	filingFee,
	smogExemption,
	weightFee,
	tireFee,
	bpa,
	miscDMVFees
) => {
	let total =
		parseFloat(license ? license : 0) +
		parseFloat(registration ? registration : 0) +
		parseFloat(titleFee ? titleFee : 0) +
		parseFloat(filingFee ? filingFee : 0) +
		parseFloat(smogExemption ? smogExemption : 0) +
		parseFloat(weightFee ? weightFee : 0) +
		parseFloat(tireFee ? tireFee : 0) +
		parseFloat(bpa ? bpa : 0);
	miscDMVFees.forEach((key) => {
		total += parseFloat(key.amount ? key.amount : 0);
	});
	parseFloat(total).toFixed(2);
	return total;
};
export const FindAddOn = (
	apr,
	loanPeriod,
	term,
	amtFinanced,
	loanDate,
	firstDueDate,
	bankFee
) => {
	const periodPerYear = LoanPeriod[loanPeriod].term;
	if (apr > 0 && term > 0 && amtFinanced > 0) {
		let tmpPmt = calculatePayment(
			apr,
			loanPeriod,
			term,
			amtFinanced,
			loanDate,
			firstDueDate,
			bankFee
		);
		return (periodPerYear * (tmpPmt / amtFinanced - 1 / term)).toFixed(2);
	} else {
		return 0;
	}
};
export const FindApr = (
	apr,
	loanPeriod,
	term,
	amtFinanced,
	loanDate,
	firstDueDate,
	payment,
	bankfee
) => {
	let upper = 0;
	let lower = 0;
	let rate = 0;
	let tmpPmt = 0;

	console.log(apr, term, amtFinanced);
	if (apr > 0 && term > 0 && amtFinanced > 0) {
		tmpPmt = 0;
		upper = 1;
		lower = apr;

		while (tmpPmt !== payment && upper - lower >= 0.00000001) {
			rate = (upper + lower) / 2;

			tmpPmt = calculatePayment(
				rate,
				loanPeriod,
				term,
				amtFinanced,
				loanDate,
				firstDueDate,
				bankfee
			);

			//console.log(tmpPmt);
			if (tmpPmt > payment) {
				upper = rate;
			} else {
				lower = rate;
			}
		}
		rate = (upper + lower) / 2;
		rate = Number(rate.toFixed(6));
		console.log(upper, lower, rate);
		return rate;
	} else {
		return 0;
	}
};

export const calculateMOANN = (
	apr,
	loanPeriod,
	term,
	loanDate,
	firstDueDate
) => {
	if (apr === 0) return 0;

	const start = dayjs(loanDate);
	const end = dayjs(firstDueDate);
	const daysToPay = end.diff(start, "day");
	const daysInPeriod = LoanPeriod[loanPeriod].daysInPeriod;
	const periodPerYear = LoanPeriod[loanPeriod].term;
	const interestPerPeriod = apr / periodPerYear;

	const V = 1 / (1 + interestPerPeriod);
	let MOANN = (1 - Math.pow(V, term)) / interestPerPeriod;
	const oddDays = Math.pow(
		1 + (interestPerPeriod * Math.abs(daysToPay - daysInPeriod)) / daysInPeriod,
		Math.sign(daysToPay - daysInPeriod)
	);
	MOANN = MOANN / oddDays;
	return MOANN;
};

export const rollByPrice = (
	desiredPmt,
	term,
	amtFinanced,
	oldPrice,
	taxRate,
	vitRate,
	licensePct,
	registrationPct,
	apr,
	loanPeriod,
	loanDate,
	firstDueDate
) => {
	let MOANN = calculateMOANN(apr, loanPeriod, term, loanDate, firstDueDate);
	if (loanPeriod === 5) {
		MOANN = 1;
	}
	const newAmtFinanced = desiredPmt * MOANN;

	const percentages = 1 + taxRate + vitRate + licensePct + registrationPct;
	const amtFinancedWithoutPrice = amtFinanced - oldPrice * percentages;

	const newPrice = (newAmtFinanced - amtFinancedWithoutPrice) / percentages;
	console.log(newPrice);
	return newPrice < 0 ? 0 : newPrice;
};

export const rollByTerm = (desiredPmt, amtFinanced, apr, loanPeriod) => {
	let newTerm = 0;
	const interestPerPeriod = apr / LoanPeriod[loanPeriod].term;
	newTerm =
		-1 *
		(Math.log(1 - (interestPerPeriod * amtFinanced) / desiredPmt) /
			Math.log(1 + interestPerPeriod));
	console.log(newTerm);
	return newTerm < 0 ? 0 : newTerm;
};

export const rollByDown = (
	desiredPmt,
	term,
	amtFinanced,
	oldDown,
	apr,
	loanPeriod,
	loanDate,
	firstDueDate
) => {
	const MOANN = calculateMOANN(apr, loanPeriod, term, loanDate, firstDueDate);
	const newAmtFinanced = desiredPmt * MOANN;

	const amtFinancedWithoutDown = amtFinanced + oldDown;

	const newDown = newAmtFinanced - amtFinancedWithoutDown;
	console.log(newAmtFinanced);
	console.log(amtFinancedWithoutDown);
	console.log(newDown);

	return newDown > 0 ? 0 : -newDown;
};

export const calculateBalloon = (
	amtFinanced,
	balloonTerm,
	apr,
	payment,
	loanPeriod
) => {
	console.log({ amtFinanced, balloonTerm, apr, payment, loanPeriod });

	const interestPerPeriod = apr / LoanPeriod[loanPeriod].term;
	const balloon =
		amtFinanced * Math.pow(1 + interestPerPeriod, balloonTerm) -
		payment *
			((Math.pow(1 + interestPerPeriod, balloonTerm) - 1) / interestPerPeriod);
	console.log(balloon);
	return balloon;
};
export const calculateTXapr = (
	amtFinanced,
	firstDueDate,
	finalDueDate,
	carYear,
	loanDate
) => {
	let addOn = 0.01;
	const start = dayjs(firstDueDate);
	const end = dayjs(finalDueDate);
	console.log(firstDueDate);
	console.log(finalDueDate);
	console.log(start);
	console.log(end);

	let term = end.diff(start, "day") + 30;
	console.log(term);
	term = Number(((term / 365) * 12).toFixed(0));
	console.log("term " + term);

	if (carYear < 1900) carYear = dayjs(new Date()).year();

	// if ((dayjs.year(loanDate) - carYear) <= 1)
	// {
	//     addOn = 0.075
	// }
	//else
	if (dayjs(loanDate).year() - carYear <= 2) {
		return 0.18;
	} else if (dayjs(loanDate).year() - carYear <= 4) {
		addOn = 0.125;
	} else {
		addOn = 0.15;
	}
	console.log(addOn);

	let financeCharge = Number(((amtFinanced * addOn) / 12) * term);
	let totPayments = financeCharge + amtFinanced;
	let payment = Number(totPayments / term);

	console.log(amtFinanced);
	console.log(financeCharge);
	console.log(totPayments);
	console.log(term);
	console.log(payment);

	// TMPDAYS = .daysToPay

	// If dl.AdditionalDealer.isSchedule Then
	//    .daysToPay = 30.5
	// Else
	//    .daysToPay = 30
	// End If
	console.log(addOn, term, amtFinanced, loanDate, firstDueDate, payment);
	let newApr = FindApr(
		addOn,
		3,
		term,
		amtFinanced,
		loanDate,
		firstDueDate,
		payment
	);

	// if (apr > NewApr) {
	// 	apr = NewApr;
	// }
	newApr = newApr.toFixed(6);
	console.log(newApr);
	return newApr;
};
// This is payment calculation another way we didn't use
export const calculatePayment2 = (
	rate,
	loanPeriod,
	term,
	amtFinanced,
	loanDate,
	firstDueDate,
	bankFee
) => {
	//amtFinanced = amtFinanced+ bankFee
	console.log(bankFee);
	const start = dayjs(loanDate);
	const end = dayjs(firstDueDate);
	const daysToPay = end.diff(start, "day");
	let intPerPeriod = rate / LoanPeriod[loanPeriod].term;
	let tmpPmt = 0;
	let an = 0;
	if (intPerPeriod > 0) {
		let temp1 = 1 / (1 + intPerPeriod);
		let temp2 = (1 - Math.pow(temp1, term)) / intPerPeriod;

		an =
			(temp2 * (1 + intPerPeriod)) /
			(1 + (intPerPeriod * daysToPay) / LoanPeriod[loanPeriod].daysInPeriod);
	} else {
		an = 0;
	}
	if (an > 0) {
		tmpPmt = amtFinanced / an;
	} else {
		tmpPmt = amtFinanced / term;
	}
	return tmpPmt;
};
