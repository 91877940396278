import React from "react";
import { Container, Row } from "reactstrap";

import { version } from "../version";
// used for making the prop types of this component

const Footer = (props) => {
	// Local storage

	const { dealerID, locationID, companyName } = props;
	return (
		<footer className={"footer" + (props.default ? " footer-default" : "")}>
			<Container>
				<Row>
					<nav className="footer-nav">
						<ul>
							<span className="copyright">
								{dealerID ? dealerID + "-" : ""}
								{locationID ? locationID : ""}{" "}
								{companyName ? '"' + companyName + '"' : ""}
							</span>
							<li>
								<a href="https://dealerclick.com">DealerClick</a>
							</li>
							<li>
								<p>
									<a href="https://dealerclick.com/blog">Blog</a>
								</p>
							</li>
							<li>
								<a
									href="https://dealerclick.com/license"
									target="_blank"
									rel="noopener noreferrer"
								>
									Licenses
								</a>
							</li>
							<li>
								<a
									href="https://dealerclick.com/privacy/"
									target="_blank"
									rel="noopener noreferrer"
								>
									Privacy Notice
								</a>
							</li>
						</ul>
					</nav>
					<div className="credits ml-auto">v{version}</div>
					<div className="credits ml-auto">
						<span className="copyright">
							&copy; {1900 + new Date().getYear()}, made with{" "}
							<i className="fa fa-heart heart" /> by DealerClick
						</span>
					</div>
				</Row>
			</Container>
		</footer>
	);
};

export default Footer;
