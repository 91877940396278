import useCurrentVehicleStore from "../store/InventoryStore";
import { useFromUser } from "../store/LocalStorageHelper";
import { convertFromReactSelect } from "../component/dc/selector//helperFunction/SelectorFormat.js";
import shallow from "zustand/shallow";
import { version } from "../version";
// BuildInventoryOBJ
export const useBuildVehicle = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	// Vehicle Store Values
	const {
		inventoryID,
		stockNo,
		year,
		make,
		model,
		trim,
		odometerIn,
		color,
		intColor,
		condition,
		transmission,
		doors,
		engine,
		mPGHwy,
		mPGCity,
		weight,
		tag,
		description,
		titleNo,
		customEquip,
		selectedEquip,
		optionalEquip,
		type,
		vin,
		standardEquip,
		specificationEquip,
		askingPrice,
		dateIn,
		pack,
		cost,
		downPayment,
		msrp,
		weightFee,
		isDraft,
		datePaid,
		checkNo,
		newUsed,
		license,
		licenseExpires,
		location,
		buyer,
		notes,
		vendorRecNum,
		style,
		status,
		thumbnail,
		odometerOut,
		driveTrain,
		fuelType,
		reportOfSale,
		onlinePrice,
		wholesalePrice,
		numCylinders,
		actualMileageFlag,
		titleInDate,
		titleState,
		newTitleState,
		newTitleReceivedDate,
		newTitleNumber,
		titleDateOfIssue,
		shippedDate,
		auctionDate,
		smogCheckDate,
		soldDate,
		createdByID,
		modifiedByID,
		isSmogCheck,
		openedBy,
		modifiedOn,
		createdOn,
		dateFloored,
		flooringInt,
		flooringComp,
		flooringAmt,
		flooringValue,
		flooringAdminFee,
		flooringCheckNo,
		isFloored,
		flooringStopDate,
		isTradeIn,
		titleBrand,
		paymentMethod,
		tradeLenderRecNum,
		allowance,
		acv,
		payoff,
		hasGPS,
		gps_ID,
		gpsCompany,
		gpsWebsite,
		gps_ID2,
		gpsCompany2,
		gpsWebsite2,
		hasGPS2,
		videoURL,
		isAdvertised,
		axelCount,
	} = useCurrentVehicleStore((state) => state, shallow);

	const vehicle = {
		inventoryID,
		stockNo,
		year,
		make,
		model,
		trim,
		odometerIn,
		color,
		intColor,
		condition,
		transmission,
		doors,
		engine,
		mPGHwy,
		mPGCity,
		weight,
		tag,
		description,
		titleNo,
		customEquip,
		selectedEquip,
		optionalEquip: convertFromReactSelect(optionalEquip),
		type,
		vin,
		standardEquip,
		specificationEquip,
		dealerID,
		locationID,
		askingPrice: parseFloat(askingPrice),
		dateIn,
		pack: parseFloat(pack),
		cost: parseFloat(cost),
		downPmt: downPayment,
		msrp,
		weightFee,
		isDraft,
		datePaid,
		checkNo,
		newUsed,
		license,
		expireDate: licenseExpires,
		location,
		buyer,
		notes: notes.length > 0 ? JSON.stringify(notes) : "",
		vendorRecNum,
		subModel: style,
		status,
		thumbnail,
		odometerOut,
		driveTrain,
		fuelType,
		reportOfSale,
		onlinePrice: parseFloat(onlinePrice),
		wholesalePrice: parseFloat(wholesalePrice),
		numCylinders,
		actualMileageFlag,
		titleInDate,
		titleState,
		newTitleState,
		newTitleReceivedDate,
		newTitleNumber,
		titleDateOfIssue,
		shippedDate,
		auctionDate,
		smogCheckDate,
		soldDate,
		dCVersion: "v" + version,
		createdByID,
		modifiedByID,
		isSmogCheck,
		openedBy,
		modifiedOn,
		createdOn,
		dateFloored,
		flooringInt,
		flooringComp,
		flooringAmt,
		flooringValue,
		flooringAdminFee,
		flooringCheckNo,
		isFloored,
		flooringStopDate,
		isTradeIn,
		titleBrand,
		paymentMethod,
		tradeLenderRecNum,
		allowance,
		acv,
		payoff,
		hasGPS,
		gps_ID,
		gpsCompany,
		gpsWebsite,
		gps_ID2,
		gpsCompany2,
		gpsWebsite2,
		hasGPS2,
		videoURL,
		isAdvertised,
		axelCount,
	};
	const getVehicle = () => {
		console.log(vehicle);
		return vehicle;
	};
	return getVehicle;
};
export default useBuildVehicle;
