export const PATHS = {
	//Base
	DEV: "/dev",

	//Paths
	DASHBOARD: "/dashboard",
	LOGIN: "/login",
	INVENTORY_LIST: "/inventory-list",
	INVENTORY_ADD: "/inventory-add",
	INVENTORY_APPRAISAL: "/inventory-appraisal",
	INVENTORY_REPORTS: "/inventory-reports",
	INVENTORY_DETAIL: "/inventory-detail",
	CUSTOMER_LIST: "/customer-list",
	CUSTOMER_ADD: "/customer-add",
	CUSTOMER_DETAIL: "/customer-detail",
	MESSAGING: "/message",
	MESSAGE_RECENT: "/messages/recent",
	LEAD_ADD: "/lead-add",
	LEAD_LIST: "/lead-list",
	CreditApp_LIST: "/CreditApp-list",
	LEAD_DETAIL: "/lead-detail",
	COMMUNICATIONS: "/communications",
	MESSAGE_TEMPLATES: "/message-templates",
	CREDIT_APPS: "/credit-apps",
	DEAL_LIST: "/deal-list",
	DEAL_ADD: "/deal-add",
	DEAL_SETTINGS: "/deal-settings",
	DEAL_DETAIL: "/deal-detail",
	BUY_HERE_PAY_HERE: "/buy-here-pay-here",
	PAYMENT_ADD: "/payment-add",
	USER_PROFILE: "/user-profile",
	REPORTS: "/reports",
	BUYERS_GUIDE: "/buyers-guide",
	SETTINGS: "/settings",
	NEW_USER: "/new-user",
	USER_ACCOUNT: "/user-account",
	PAYMENT_TERMINAL: "/payment-terminal",
	INVENTORY_ADVERTISING: "/inventory-advertising",
	VENDORS: "/vendors",
	UPDATE_PASSWORD: "/update-password",
	FORGOTTEN_PASSWORD: "/forgotten-password",
	BackDoor_Login: "/backdoor_login",
	FormGen: "/formgen",
	QuikQuote: "/QuikQuote",
};

/**
 * Takes in an enumerated object and returns an array used for selectors.
 * Enumerated objects are good for accessing values without having to rely on index
 * @param {*} enumObj
 * @returns
 */
export const getOptions = (enumObj) => {
	return Object.keys(enumObj).map((option) => enumObj[option]);
};

export const vendorTypesOBJ = {
	VENDOR: { value: 10, label: "Vendor" },
	WARRANTY: { value: 20, label: "Warranty" },
	//LienHolder: { value: 25, label: "Lien Holder" },
	LENDER: { value: 30, label: "Lender" },
	DEALERCLICK_LENDER: { value: 31, label: "DealerClick Lender" },
	SHIPPING_COMPANIES: { value: 40, label: "Shipping Companies" },
	RECON_SUPPLIER: { value: 50, label: "Recon Supplier" },
	AFTMKT_SUPPLIER: { value: 60, label: "Aftermarket Supplier" },
	FLOORING_VENDOR: { value: 70, label: "Flooring Vendor" },
	//TradeLender: { value: 80, label: "Trade Lender" },
	GAP_COMPANIES: { value: 90, label: "Gap/Insurance Companies" },
};

export const vendorTypes = {
	10: "Vendor",
	20: "Warranty",
	//	25: "Lien Holder",
	30: "Lender",
	40: "Shipping Companies",
	50: "Recon Supplier",
	60: "Aftermarket Supplier",
	70: "Flooring Vendor",
	//	80: "Trade Lender",
	90: "Gap/Insurance Companies",
};
export const INS_TYPE = [
	{ value: 0, label: "None" },
	{ value: 1, label: "SINGLE_LIFE" },
	{ value: 2, label: "JOINT_LIFE" },
];
export const INS_TYPE_AH = [
	{ value: 0, label: "None" },
	{ value: 3, label: "14 DAYS RETRO" },
	{ value: 4, label: "7  DAYS RETRO" },
	{ value: 5, label: "30 DAYS RETRO" },
];
export const taxRateEnum = {
	SPECIFIC_RATE: 1,
	NONTAXABLE: 0,
	DEFAULT_TAX_RATE: -1,
};
export const genderType = [
	{ value: "M", label: "Male" },
	{ value: "F", label: "Female" },
	{ value: "O", label: "Other" },
];
export const generationType = [
	{ value: "N/A", label: "N/A" },
	{ value: "Sr", label: "Sr" },
	{ value: "Jr", label: "Jr" },
	{ value: "2nd", label: "2nd" },
	{ value: "3rd", label: "3rd" },
	{ value: "4th", label: "4th" },
	{ value: "I", label: "I" },
	{ value: "II", label: "II" },
	{ value: "III", label: "III" },
	{ value: "IV", label: "IV" },
];
export const titleStatusOptions = [
	{ label: "Clean", value: "Clean" },
	{ label: "Salvage", value: "Salvage" },
	{ label: "Bonded", value: "Bonded" },
	{ label: "Lemon", value: "Lemon" },
	{ label: "Rebuilt", value: "Rebuilt" },
	{ label: "Dismantled", value: "Dismantled" },
	{ label: "Junk", value: "Junk" },
	{ label: "Flood/Water Damage", value: "Flood/Water Damage" },
];

export const stateOptions = [
	{ label: "AK", value: "AK" },
	{ label: "AL", value: "AL" },
	{ label: "AR", value: "AR" },
	{ label: "AZ", value: "AZ" },
	{ label: "CA", value: "CA" },
	{ label: "CO", value: "CO" },
	{ label: "CT", value: "CT" },
	{ label: "DE", value: "DE" },
	{ label: "FL", value: "FL" },
	{ label: "GA", value: "GA" },
	{ label: "HI", value: "HI" },
	{ label: "IA", value: "IA" },
	{ label: "ID", value: "ID" },
	{ label: "IL", value: "IL" },
	{ label: "IN", value: "IN" },
	{ label: "KS", value: "KS" },
	{ label: "KY", value: "KY" },
	{ label: "LA", value: "LA" },
	{ label: "MA", value: "MA" },
	{ label: "MD", value: "MD" },
	{ label: "ME", value: "ME" },
	{ label: "MI", value: "MI" },
	{ label: "MN", value: "MN" },
	{ label: "MO", value: "MO" },
	{ label: "MS", value: "MS" },
	{ label: "MT", value: "MT" },
	{ label: "NC", value: "NC" },
	{ label: "ND", value: "ND" },
	{ label: "NE", value: "NE" },
	{ label: "NH", value: "NH" },
	{ label: "NJ", value: "NJ" },
	{ label: "NM", value: "NM" },
	{ label: "NV", value: "NV" },
	{ label: "NY", value: "NY" },
	{ label: "OH", value: "OH" },
	{ label: "OK", value: "OK" },
	{ label: "OR", value: "OR" },
	{ label: "PA", value: "PA" },
	{ label: "RI", value: "RI" },
	{ label: "SC", value: "SC" },
	{ label: "SD", value: "SD" },
	{ label: "TN", value: "TN" },
	{ label: "TX", value: "TX" },
	{ label: "UT", value: "UT" },
	{ label: "VA", value: "VA" },
	{ label: "VT", value: "VT" },
	{ label: "WA", value: "WA" },
	{ label: "WI", value: "WI" },
	{ label: "WV", value: "WV" },
	{ label: "WY", value: "WY" },
];
export const StateCodeByStateName = [
	{ label: "Armed Forces America	", value: "AA" },
	{ label: "Armed Forces", value: "AE" },
	{ label: "Alaska", value: "AK" },
	{ label: "Alabama", value: "AL" },
	{ label: "Armed Forces Pacific", value: "AP" },
	{ label: "Arkansas", value: "AR" },
	{ label: "Arizona", value: "AZ" },
	{ label: "California", value: "CA" },
	{ label: "Colorado", value: "CO" },
	{ label: "Connecticut", value: "CT" },
	{ label: "Washington DC", value: "DC" },
	{ label: "Delaware", value: "DE" },
	{ label: "Florida", value: "FL" },
	{ label: "Georgia", value: "GA" },
	{ label: "Guam", value: "GU" },
	{ label: "Hawaii", value: "HI" },
	{ label: "Iowa", value: "IA" },
	{ label: "Idaho", value: "ID" },
	{ label: "Illinois", value: "IL" },
	{ label: "Indiana", value: "IN" },
	{ label: "Kansas", value: "KS" },
	{ label: "Kentucky", value: "KY" },
	{ label: "Louisiana", value: "LA" },
	{ label: "Massachusetts", value: "MA" },
	{ label: "Maryland", value: "MD" },
	{ label: "Maine", value: "ME" },
	{ label: "Michigan", value: "MI" },
	{ label: "Minnesota", value: "MN" },
	{ label: "Missouri", value: "MO" },
	{ label: "Mississippi", value: "MS" },
	{ label: "Montana", value: "MT" },
	{ label: "North Carolina", value: "NC" },
	{ label: "North Dakota", value: "ND" },
	{ label: "Nebraska", value: "NE" },
	{ label: "New Hampshire", value: "NH" },
	{ label: "New Jersey", value: "NJ" },
	{ label: "New Mexico", value: "NM" },
	{ label: "Nevada", value: "NV" },
	{ label: "New York", value: "NY" },
	{ label: "Ohio", value: "OH" },
	{ label: "Oklahoma", value: "OK" },
	{ label: "Oregon", value: "OR" },
	{ label: "Pennsylvania", value: "PA" },
	{ label: "Puerto Rico", value: "PR" },
	{ label: "Rhode Island", value: "RI" },
	{ label: "South Carolina", value: "SC" },
	{ label: "South Dakota", value: "SD" },
	{ label: "Tennessee", value: "TN" },
	{ label: "Texas", value: "TX" },
	{ label: "Utah", value: "UT" },
	{ label: "Virginia", value: "VA" },
	{ label: "Virgin Islands", value: "VI" },
	{ label: "Vermont", value: "VT" },
	{ label: "Washington", value: "WA" },
	{ label: "Wisconsin", value: "WI" },
	{ label: "West Virginia", value: "WV" },
	{ label: "Wyoming", value: "WY" },
];

export const vehicleTypeOptions = [
	{ label: "Auto", value: "Auto" },
	{ label: "Car", value: "Car" },
	{ label: "RV", value: "RV" },
	{ label: "SUV", value: "SUV" },
	{ label: "Semi-trucks", value: "Semi-trucks" },
	{ label: "Truck", value: "Truck" },
	{ label: "Trailer", value: "Trailer" },
	{ label: "Trailer ONLY", value: "Trailer ONLY" },
	{ label: "Motorcycle", value: "Motorcycle" },
	{ label: "Mobile Home", value: "Mobile Home" },
	{ label: "ATV (Rec. Sport)", value: "ATV (Rec. Sport)" },
	{ label: "Camper", value: "Camper" },
	{ label: "Marine", value: "Marine" },
	{ label: "Per Water Craft", value: "Per Water Craft" },
	{ label: "Motor Scooter", value: "Motor Scooter" },
	{ label: "Snow Mobile", value: "Snow Mobile" },
	{ label: "MPV", value: "MPV" },
	{ label: "Other", value: "Other" },
];

// export const newUseOptions = [
// 	{ label: "New", value: "New" },
// 	{ label: "Used", value: "Used" },
// 	{ label: "Demo", value: "Demo" },
// 	{ label: "Consign", value: "Consign" },
// 	{ label: "Trade-In", value: "Trade-In" },
// 	{ label: "BroKered", value: "BroKered" },
// 	{ label: "Wholesale", value: "Wholesale" },
// 	{ label: "Pre-Owned", value: "Pre-Owned" },
// 	{ label: "Certified Pre-Owned", value: "Certified Pre-Owned" },
// ];
export const conditionOptions = [
	{ label: "Excellent", value: "Excellent" },
	{ label: "Very Good", value: "Very Good" },
	{ label: "Good", value: "Good" },
	{ label: "Fair", value: "Fair" },
];

export const kbbConditionOptions = [
	{ label: "Fair", value: "Fair" },
	{ label: "Good", value: "Good" },
	{ label: "Very Good", value: "Very Good" },
	{ label: "Excellent", value: "Excellent" },
];

export const newOrUsed = [
	{ label: "New", value: "New" },
	{ label: "Used", value: "Used" },
];

export const SaleType = [
	{ label: "RETAIL", value: 0 },
	{ label: "WHOLESALE", value: 1 },
	{ label: "BUSINESS", value: 2 },
	{ label: "FLEET", value: 3 },
	{ label: "DEALER_TRADE", value: 4 },
	{ label: "PURCHASE", value: 5 },
	{ label: "LEASE", value: 6 },
	{ label: "RTO", value: 7 },
	{ label: "QUICK_DEAL", value: 8 },
];
// daysInPeriod is derived from Peymans code, it is constant
export const LoanPeriod = [
	{ label: "WEEKLY", term: 52, daysInPeriod: 7, value: 0 },
	{ label: "BIWEEKLY", term: 26, daysInPeriod: 14, value: 1 },
	{ label: "SEMIMONTHLY", term: 24, daysInPeriod: 15, value: 2 },
	{ label: "MONTHLY", term: 12, daysInPeriod: 30, value: 3 },
	{ label: "ANNUALLY", term: 1, daysInPeriod: 360, value: 4 },
	{ label: "COD", term: 1, daysInPeriod: 0, value: 5 },
	{ label: "BIANNUALLY", term: 2, daysInPeriod: 180, value: 6 },
];

export const DealType = [
	{ label: "PURCHASE", value: "Purchase" },
	{ label: "PENDING", value: "Pending" },
	{ label: "SOLD", value: "Sold" },
	{ label: "REPO", value: "Repo" },
	{ label: "DELETED", value: "Deleted" },
	{ label: "FUNDED", value: "Funded" },
];
export const TaxRegion = [
	{ label: "Customer Zip", value: 0 },
	{ label: "Dealership Zip", value: 1 },
	{ label: "Manual", value: 2 },
];
export const TaxType = [
	{ label: "Advanced", value: 0 },
	{ label: "Deferred", value: 1 },
];

export const vehicleCostType = [
	{ label: "Reconditioning", value: 0 },
	{ label: "Flooring", value: 2 },
	{ label: "Other", value: 9 },
];

// AutoInventory equivalents
export const vehicleStatusDesc = [
	{ label: "AVAILABLE", value: 0 },
	{ label: "SOLD", value: 1 },
	{ label: "PENDING", value: 2 },
	{ label: "HOLD", value: 3 },
	{ label: "DELETED", value: 4 },
	{ label: "APPRAISAL", value: 5 },
	{ label: "TRADE", value: 6 },
	{ label: "SOLD WITHOUT DEAL", value: 9 },
];
export const vehicleStatusList = [
	{ label: "AVAILABLE", value: "Available" },
	{ label: "SOLD", value: "Sold" },
	{ label: "PENDING", value: "Pending" },
	{ label: "HOLD", value: "Hold" },
	{ label: "DELETED", value: "Deleted" },
	{ label: "APPRAISAL", value: "Appraisal" },
	{ label: "TRADE", value: "Trade" },
	{ label: "SOLD WITHOUT DEAL", value: "Sold Without Deal" },
];

export const vehicleStatus = {
	AVAILABLE: "Available",
	SOLD: "Sold",
	PENDING: "Pending",
	HOLD: "Hold",
	DELETED: "Deleted",
	APPRAISAL: "Appraisal",
	TRADE: "Trade",
	SOLDWITHOUTDEAL: "Sold Without Deal",
};

export const personnelType = [
	{ label: "Salesperson", value: 0 },
	{ label: "Closer", value: 1 },
	{ label: "Manager", value: 2 },
	{ label: "F & I Manager", value: 3 },
	{ label: "Clerical", value: 4 },
	{ label: "Other", value: 5 },
	{ label: "Service Technician", value: 6 },
	{ label: "Owner", value: 9 },
];

export const defaultPictureURL =
	"https://www.dealernetwork.com/images/inventory/nopicture1.jpg";
export const FTP_IMAGE_URL = "https://www.dealernetwork.com/images/inventory";

export const profitSettingsEnum = {
	NOT_PROFIT: { value: 0, label: "Not Profit" },
	FRONT: { value: 1, label: "Frontend Profit" },
	BACK: { value: 2, label: "Backend Profit" },
};

export const commissionSettingsEnum = {
	NO_COMMISSION: { value: 0, label: "Not commissionable" },
	FRONT_COMMISSION: { value: 1, label: "Frontend(SP) commissionable" },
	BACK_COMMISSION: { value: 2, label: "Backend(FI) commissionable" },
	BOTH_COMMISION: { value: 3, label: "Both(SP & FI) commisionable" },
};

export const commissionSettingsOptions = [
	commissionSettingsEnum.NO_COMMISSION,
	commissionSettingsEnum.FRONT_COMMISSION,
	commissionSettingsEnum.BACK_COMMISSION,
	commissionSettingsEnum.BOTH_COMMISION,
];
export const commTypeList = {
	SALE: { value: 10, label: "SALE" },
	FI: { value: 30, label: "F&I" },
	TOTAL: { value: 40, label: "TOTAL" },
	NETPROFIT: { value: 50, label: "NET_PROFIT" },
};
export const commType = [
	{ value: 10, label: "SALE" },
	{ value: 30, label: "F&I" },
	{ value: 40, label: "TOTAL" },
	{ value: 50, label: "NET_PROFIT" },
];
// export const commType = {
// 	10: "SALESPERSON",
// 	30: "CLOSER",
// 	40: "MANAGER",
// 	50: "FI_MANAGER",
// };

export const DealStatus = {
	PURCHASE: "Purchase",
	PENDING: "Pending",
	SOLD: "Sold",
	REPO: "Repo",
	DELETED: "Deleted",
	FUNDED: "Funded",
	VOID: "Void",
};

export const CustomerStatus = {
	PENDING: 0,
	SOLD: 1,
	DELETED: 2,
};

export const HousingStatus = [
	{ label: "Rent", value: "rent" },
	{ label: "Own", value: "own" },
	{ label: "Other", value: "Other" },
];

export const IncomeType = [
	{ label: "Pay Stub", value: "paystub" },
	{ label: "Cash Bank Statements", value: "cash-paid-bank-stmts" },
	{ label: "Job Letter", value: "job-letter" },
	{ label: "Permanent Disability", value: "perm-disability" },
	{ label: "Retirement", value: "retirement" },
	{ label: "Social Security Income", value: "social-security-income" },
	{ label: "VA Income", value: "va-income" },
	{ label: "Self-employment Tax Returns", value: "self-empl-tax-rtns" },
	{ label: "Self-employment Bank Statements", value: "self-empl-bank-stmts" },
	{ label: "Temporary Job", value: "temporary-job" },
	{ label: "Other", value: "other" },
];

export const EmployerType = [
	{ label: "Current Employer/Employment", value: "current" },
	{ label: "Secondary Employer/Employment", value: "secondary" },
	{ label: "Previous Employer/Employment", value: "previous" },
];

export const CountryCodes = [
	{ label: "Argentina", value: "ARG" },
	{ label: "Australia", value: "AUS" },
	{ label: "Brazil", value: "BRA" },
	{ label: "Canada", value: "CAN" },
	{ label: "Switzerland", value: "CHE" },
	{ label: "Chile", value: "CHL" },
	{ label: "China", value: "CHN" },
	{ label: "Colombia", value: "COL" },
	{ label: "Germany", value: "DEU" },
	{ label: "Egypt", value: "EGY" },
	{ label: "France", value: "FRA" },
	{ label: "Great Britain", value: "GBR" },
	{ label: "Indonesia", value: "IDN" },
	{ label: "Israel", value: "ISR" },
	{ label: "India", value: "IND" },
	{ label: "Japan", value: "JPN" },
	{ label: "South Korea", value: "KOR" },
	{ label: "Mexico", value: "MEX" },
	{ label: "Malaysia", value: "MYS" },
	{ label: "Norway", value: "NOR" },
	{ label: "New Zealand", value: "NZL" },
	{ label: "Pakistan", value: "PAK" },
	{ label: "Panama", value: "PAN" },
	{ label: "Peru", value: "PER" },
	{ label: "Philippines", value: "PHL" },
	{ label: "Russia", value: "RUS" },
	{ label: "Saudi Arabia", value: "SAU" },
	{ label: "Sweden", value: "SWE" },
	{ label: "Singapore", value: "SGG" },
	{ label: "Thailand", value: "THA" },
	{ label: "Turkey", value: "TUR" },
	{ label: "Ukraine", value: "UKR" },
	{ label: "United States of America", value: "USA" },
	{ label: "Venezuela", value: "VEN" },
	{ label: "Vietnam", value: "VNM" },
	{ label: "South Africa", value: "ZAF" },
];

export const CurrencyCodes = [
	{ label: "Argentine Peso", value: "ARS" },
	{ label: "Australian Dollar", value: "AUD" },
	{ label: "Brazilian Real", value: "BRL" },
	{ label: "Canadian Dollar", value: "CAD" },
	{ label: "Swiss Franc", value: "CHF" },
	{ label: "Chilean Peso", value: "CLP" },
	{ label: "Chinese Yuan Renminbi", value: "CNY" },
	{ label: "Colombian Peso", value: "COP" },
	{ label: "Egypt Pound", value: "EGP" },
	{ label: "Euro", value: "EUR" },
	{ label: "British Pound Sterling", value: "GBP" },
	{ label: "Indonesian Rupiah", value: "IDR" },
	{ label: "Israeli New Shekel", value: "ILS" },
	{ label: "Indian Rupee", value: "INR" },
	{ label: "Japanese Yen", value: "JPY" },
	{ label: "South Korean Won", value: "KRW" },
	{ label: "Mexican Peso", value: "MXN" },
	{ label: "Malaysian Ringgit", value: "MYR" },
	{ label: "Norwegian Krone", value: "NOK" },
	{ label: "New Zealand Dollar", value: "NZD" },
	{ label: "Panamanian Balboa", value: "PAB" },
	{ label: "Peruvian Sol", value: "PEN" },
	{ label: "Philippines Peso", value: "PHP" },
	{ label: "Pakistani Rupee", value: "PKR" },
	{ label: "Russian Ruble", value: "RUB" },
	{ label: "Saudi Arabia Riyal", value: "SAR" },
	{ label: "Swedish Krona", value: "SEK" },
	{ label: "Singapore Dollar", value: "SGD" },
	{ label: "Thai Baht", value: "THB" },
	{ label: "Turkish Lira", value: "TRY" },
	{ label: "Ukrainian Hryvnia", value: "UAH" },
	{ label: "United States Dollar", value: "USD" },
	{ label: "Venezuelan Bolívar Soberano", value: "VES" },
	{ label: "Vietnamese Dong", value: "VND" },
	{ label: "South African Rand", value: "ZAR" },
];

export const AdSource = [
	{ label: "Credit Application", value: "credit application" },
	{ label: "Website", value: "website" },
	{ label: "Facebook", value: "facebook" },
	{ label: "Craigslist", value: "craigslist" },
	{ label: "Referral", value: "referral" },
];

export const militaryType = [
	{ label: "N/A", value: "n/a" },
	{ label: "Active Duty", value: "active duty" },
	{ label: "Reserve", value: "reserve" },
	{ label: "Veteran", value: "veteran" },
];

export const customerType = [
	{ label: "Personal", value: 0 },
	{ labe: "Business", value: 1 },
	{ labe: "Purchase", value: 5 },
];

export const ownershipType = [
	{ label: "Own", value: "own" },
	{ label: "Rent", value: "rent" },
	{ label: "Other", value: "other" },
];

export const commissionType = [
	{ label: "Front", value: 0 },
	{ label: "Back", value: 1 },
	{ label: "Net Commissionable", value: 2 },
	{ label: "Net Profit", value: 3 },
];
export const fuelTypeList = [
	{ label: "GAS", value: "G" },
	{ label: "ELECTRIC", value: "E" },
	{ label: "NATURAL GAS", value: "N" },
	{ label: "PROPANE", value: "P" },
	{ label: "HYBRID", value: "H" },
	{ label: "DIESEL", value: "D" },
	{ label: "FUEL CELL", value: "F" },
	{ label: "OTHER", value: "O" },
];

export const ATTACHMENT_CATEGORY = [
	{ label: "Credit Report", value: "Credit Report" },
	{ label: "ID Card", value: "ID Card" },
	{ label: "Income Documents", value: "Income Documents" },
	{ label: "Tax Documents", value: "Tax Documents" },
	{ label: "Utility Bill", value: "Utility Bill" },
	{ label: "Bank Statements", value: "Bank Statements" },
	{ label: "Title", value: "Title" },
	{ label: "Inspection/Appraisal", value: "Inspection/Appraisal" },
	{ label: "Insurance", value: "Insurance" },
	{ label: "Proof of Payment", value: "Payment" },
	{ label: "Other", value: "Other" },
];
export const OnlineLeadStatus = [
	{ label: "Active", value: 0 },
	{ label: "Sold", value: 1 },
	{ label: "Pending Sale", value: 2 },
	{ label: "Lost Lead", value: 3 },
	{ label: "Deleted/Trash", value: 4 },
	{ label: "Archive", value: 5 },
	{ label: "Appointment", value: 6 },
	{ label: "Seller", value: 7 },
	{ label: "Unknown", value: 8 },
	{ label: "Cold Lead", value: 9 },
];

export const OnlineCreditAppStatus = [
	{ label: "Active", value: 1 },
	{ label: "DeActive", value: 0 },
];

export const ActualMileageFlagOption = [
	{ label: "Actual", value: 0 },
	{ label: "Not Actual", value: 1 },
	{ label: "Exempt", value: 2 },
	{ label: "Excess", value: 3 },
];

// this should contain dcTableCustomer specific variables
export const CUSTOMER_MSG_TEMPLATE_VAR = {
	// Buyer/DcTableCustomer stuff
	"<BuyerFirstName>": "Buyer First Name",
	"<BuyerLastName>": "Buyer Last Name",
	"<BuyerCellPhone>": "Buyer Cellphone",
	"<BuyerEmail>": "Buyer Email",
	"<BuyerStreet>": "Buyer Street",
	"<BuyerCity>": "Buyer City",
	"<BuyerState>": "Buyer State",
	"<BuyerZip>": "Buyer Zip",
	"<BuyerCountry>": "Buyer Country",
	"<BuyerCreditScore>": "TransUnion Score",
	"<BuyerCreditScoreEfx>": "Equifax Score",
	"<BuyerCreditScoreXpn>": "Experian Score",
	"<BuyerCreditReport>": "Buyer Credit Report",
	"<RequestDocs>": "Request Docs",

	// Dealer stuff
	"<DealerNumber>": "Dealer Number",
	"<DealerFax>": "Dealer Fax",
	"<DealerName>": "Dealer Name",
	"<DealerStreet>": "Dealer Street",
	"<DealerCity>": "Dealer City",
	"<DealerState>": "Dealer State",
	"<DealerZip>": "Dealer Zip",
	"<DealerPhone>": "Dealer Phone",
	"<DealerFullAddress>": "Dealer Full Address",
};

export const MESSAGE_TEMPLATE_VARIABLES = {
	// CoBuyer stuff
	"<CoBuyerFirstName>": "Co-Buyer First Name",
	"<CoBuyerLastName>": "Co-Buyer Last Name",

	// Deal Stuff
	"<APR>": "APR",
	"<AmountFinanced>": "Amount Financed",
	"<DealDate>": "Deal Date",
	"<DealNumber>": "Deal Number",
	"<DownPayment>": "Down Payment",
	"<DealStatus>": "Deal Status",
	"<DealType>": "Deal Type",
	"<Lender>": "Lender",
	"<NetTrade>": "Net Trade",
	"<Payment>": "Payment",
	"<Period>": "Period",
	"<Price>": "Price",
	"<Salesperson>": "Salesperson",
	"<Tax>": "Tax",
	"<Term>": "Term",
	"<TodaysDate>": "Today's Date",

	// Vehicle stuff
	"<VehicleMake>": "Vehicle Make",
	"<VehicleModel>": "Vehicle Model",
	"<VehicleStockNumber>": "Vehicle Stock Number",
	"<VehicleYear>": "Vehicle Year",
	"<VIN>": "VIN",
	"<VehicleOdometerIn>": "Odometer In",
	...CUSTOMER_MSG_TEMPLATE_VAR,
};

export const sellerFeeTypeList = [
	{ label: "Other", value: null },
	{ label: "Business Partner Automation (BPA)", value: "BPA" },
	{ label: "Electronic Registration Title (ERT)", value: "ERT" },
	{ label: "Electric Vehicle (EV)", value: "EV" },
	{ label: "Theft Deterrent", value: "theft" },
	{ label: "Surface Protection", value: "surface" },
	{ label: "Vehicle Single Insurance (VSI)", value: "VSI" },
];

export const driverLicenseOption = [
	{ label: "In-State Driver's License", value: "In-State Driver's License" },
	{
		label: "Out-of-State Driver's License",
		value: "Out-of-State Driver's License",
	},
	{
		label: "Consulate Issued ID / Matricula",
		value: "Consulate Issued ID / Matricula",
	},
	{ label: "ID Card", value: "ID Card" },
	{
		label: "Out-of-County / Int'l Driver's License",
		value: "Out-of-County / Int'l Driver's License",
	},
	{ label: "Passport", value: "Passport" },
	{
		label: "Restricted / Suspended Driver's License",
		value: "Restricted / Suspended Driver's License",
	},
];

export const canadaStateOption = [
	{ label: "Ontario", value: "ON" },
	{ label: "Quebec", value: "QC" },
	{ label: "Nova Scotia", value: "NS" },
	{ label: "New Brunswick", value: "NB" },
	{ label: "Manitoba", value: "MB" },
	{ label: "British Columbia", value: "BC" },
	{ label: "Prince Edward Island", value: "PE" },
	{ label: "Saskatchewan", value: "SK" },
	{ label: "Alberta", value: "AB" },
	{ label: "Newfoundland and Labrador", value: "NL" },
];

export const isTaxableOption = [
	{ value: "true", label: "Yes" },
	{ value: "false", label: "No" },
];

export const rollOptions = [
	// here for informative reasons
	// {label: "No Roll", value: 0},
	{ label: "Price", value: 10 },
	{ label: "Down", value: 20 },
	{ label: "Interest (APR)", value: 40 },
	{ label: "Term", value: 90 },
];

export const companyFeaturesList = [
	{ value: "isWordpressActive", label: "Wordpress" },
	{ value: "useKall8", label: "Kall8" },
	{ value: "sendXMRadio", label: "SiriusXM" },
	{ value: "useTrueValue", label: "TrueValue" },
	{ value: "sendCarfax", label: "Carfax" },
	{ value: "sendAutoCheck", label: "Autocheck" },
	{ value: "useLenderArea", label: "Lender Area" },
	{ value: "useCRM", label: "CRM Area" },
	{ value: "sendWebsite", label: "Hosting" },
	{ value: "sendExternalWebsite", label: "External Inventory" },
	{ value: "sendXML", label: "XML Inventory" },
	{ value: "usedCreditAppArea", label: "Credit App Area" },
	{ value: "sendCBC", label: "CBC" },
	{ value: "useAndroid", label: "Android" },
	{ value: "usePop3", label: "Pop3" },
	{ value: "usePreviousOwner", label: "Previous Owner" },
	{ value: "kbbidws", label: "KBB IDWS" },
	{ value: "sendFacebook", label: "Facebook.com" },
	{ value: "useIphone", label: "iPhone" },
	{ value: "sendTitleTec", label: "TitleTec" },
	{ value: "sendBlockchain", label: "Blockchain" },
	{ value: "useUACC", label: "UACC" },
];

export const locationFeatureList = [
	{ value: "keyless", label: "Keyless" },
	{ value: "dc", label: "DMS" },
	{ value: "cr", label: "Credit Reports" },
	{ value: "qb", label: "QuickBook" },
	{ value: "ls", label: "Leasing" },
	{ value: "nls", label: "RTO" },
	{ value: "fn", label: "BHPH" },
	{ value: "ps", label: "Part & Service" },
	{ value: "psTimeClock", label: "Time Clock" },
	{ value: "psBarCode", label: "BarCode" },
];

export const paymentMethod = [
	{ value: "cash", label: "Cash" },
	{ value: "creditCard", label: "Credit Card" },
	{ value: "debitCard", label: "Debit Card" },
	{ value: "moneyOrder", label: "Money Order" },
	{ value: "cashierCheck", label: "CashiersCheck" },
	{ value: "zelle", label: "Zelle" },
	{ value: "moneyGram", label: "MoneyGram" },
	{ value: "paypal", label: "PayPal" },
	{ value: "transfer", label: "TRANSFER" },
	{ value: "echeck", label: "E-Check" },
	{ value: "check", label: "Check" },
	{ value: "other", label: "Other" },
	{ value: "creditIssued", label: "Credit Issued" },
	{ value: "skipped", label: "Skipped" },
	{ value: "ach", label: "A C H" },
];
export const purchaseType = [
	...paymentMethod,
	{ value: "flooring", label: "Flooring" },
	{ value: "draft", label: "Draft" },
	{ value: "consignment", label: "Consignment" },
	{ value: "certified Check", label: "Certified Check" },
];

export const paymentMode = [
	{ value: 0, label: "Regular" },
	{ value: 1, label: "Principal Only" },
	{ value: 2, label: "Interest Only" },
	{ value: 3, label: "Escrow only" },
	{ value: 4, label: "Adjustment" },
	{ value: 5, label: "LATE FEE" },
	{ value: 6, label: "Deposit" },
	{ value: 7, label: "PickUp" },
	{ value: 8, label: "Down" },
	{ value: 9, label: "DriveOff" },
	{ value: 10, label: "Mileage" },
	{ value: 11, label: "Insurance" },
	{ value: 12, label: "Lender Check" },
];
export const driveType = [
	{ label: "Front-wheel drive (FWD)", value: "FWD" },
	{ label: "Rear-wheel drive (RWD)", value: "RWD" },
	{ label: "All-wheel drive (AWD)", value: "AWD" },
	{ label: "Four-wheel drive (4WD)", value: "4WD" },
];

export const smogCheck = [
	{ label: "Not Checked", value: 0 },
	{ label: "Passed", value: 1 },
	{ label: "Failed", value: 2 },
];

export const credit700Products = {
	saveOnly: "SAVEONLY",
	credit: "CREDIT",
	prescreen: "PRESCREEN",
};

export const credit700Lenders = {
	routeOne: "R1",
	dealerTrack: "DT",
	cudl: "CUDL",
	appOne: "APPONE",
};

export const credit700Bureaus = {
	experian: "XPN",
	transUnion: "TU",
	equifax: "EFX",
};
export const titleBrands = [
	{ value: "Clean", label: "Clean" },
	{ value: "Collision", label: "Collision" },
	{ value: "Damaged", label: "Damaged" },
	{ value: "Water-Damage", label: "Water Damage" },
	{ value: "Storm-Damage", label: "Storm Damage" },
	{ value: "Dismantled", label: "Dismantled" },
	{ value: "Grey-Market", label: "Grey Market" }, //(Non-USA)
	{ value: "Junk", label: "Junk" },
	{ value: "Lemon", label: "Lemon law buyback" }, //(Warranty Return)
	{ value: "Police", label: "Original police" },
	{ value: "Taxi", label: "Original Taxi" },
	{ value: "Park Trailer", label: "Park Trailer" },
	{ value: "Rebuilt", label: "Rebuilt" },
	{ value: "Reconditioned", label: "Reconditioned" },
	{ value: "Remanufactured", label: "Remanufactured" },
	{ value: "Replica", label: "Replica" },
	{ value: "Revived Junk", label: "Revived Junk" },
	{ value: "Revived Salvage", label: "Revived Salvage" },
	{ value: "Recycled", label: "Recycled" },
	{ value: "Salvage", label: "Salvage" },
	{ value: "Scrap Vehicle", label: "Scrap Vehicle" },
	{ value: "Totaled", label: "Totaled" },
	{ value: "Warranty Returned", label: "Warranty Returned" },
	{ value: "Vandalism", label: "Vandalism" },
	{ value: "TMU", label: "TMU" }, //True Mileage Unknown
	{ value: "Municipal", label: "Municipal Vehicle" },
	{ value: "Tripped", label: "Tripped" },
	{ value: "Flood", label: "Flood" },
];

/**
 * PepperPay option lists
 */
export const MailingType = [
	{ value: "DBA Address", label: "DBA Address" },
	{ value: "Legal Address", label: "Legal Address" },
];
export const jobTitles = [
	{ value: "CEO", label: "CEO" },
	{ value: "CFO", label: "CFO" },
	{ value: "Chairman", label: "Chairman" },
	{ value: "Co-owner", label: "Co-owner" },
	{ value: "Comptroller", label: "Comptroller" },
	{ value: "Controller", label: "Controller" },
	{ value: "Director", label: "Director" },
	{ value: "General Manager", label: "General Manager" },
	{ value: "Office Manager", label: "Office Manager" },
	{ value: "Owner", label: "Owner" },
	{ value: "Partner", label: "Partner" },
	{ value: "President", label: "President" },
	{ value: "Treasurer", label: "Treasurer" },
	{ value: "Vice President", label: "Vice President" },
];
export const OwnershipTypePeperpay = [
	{ value: "Individual/Sole Proprietor", label: "Individual/Sole Proprietor" },
	{ value: "Partnership", label: "Partnership" },
	{ value: "LLC", label: "LLC" },
	{ value: "Association/Estate/Trust", label: "Association/Estate/Trust" },
	{
		value: "Government(Federal, State, Local)",
		label: "Government(Federal, State, Local)",
	},
	{ value: "Corporation - Chapter S, C", label: "Corporation - Chapter S, C" },
	{ value: "Tax Exempt Organization", label: "Tax Exempt Organization" },
	{
		value: "Medical or Legal Corporation",
		label: "Medical or Legal Corporation",
	},
	{ value: "International Organization", label: "International Organization" },
];
export const AccountOwnership = [
	{ value: "business", label: "business" },
	{ value: "personal", label: "personal" },
];
export const BankruptcyFiled = [
	{ value: "never filed", label: "never filed" },
	{ value: "business bankruptcy", label: "business bankruptcy" },
	{ value: "personal bankruptcy", label: "personal bankruptcy" },
];
export const BankAccountType = [
	{ value: "Checking", label: "Checking" },
	{ value: "Savings", label: "Savings" },
];
export const typeOfGoodsServices = [
	{ value: "Retail", label: "Retail" },
	{ value: "Retail W Tip", label: "Retail W Tip" },
	{ value: "Mail Phone", label: "Mail Phone" },
	{ value: "Internet", label: "Internet" },
	{ value: "Restaurant", label: "Restaurant" },
	{ value: "Fast Food", label: "Fast Food" },
	{ value: "Lodging", label: "Lodging" },
	{ value: "QRS", label: "QRS" },
	{ value: "Convenience", label: "Convenience" },
	{ value: "Public Sector", label: "Public Sector" },
	{ value: "Petroleum", label: "Petroleum" },
	{ value: "Utility", label: "Utility" },
	{ value: "Health Care", label: "Health Care" },
	{ value: "Other", label: "Other" },
];
export const LocationAreaZoned = [
	{ value: "Commercial", label: "Commercial" },
	{ value: "Industrial", label: "Industrial" },
	{ value: "Residential", label: "Residential" },
];
export const locationBuildingType = [
	{ value: "Shopping center", label: "Shopping center" },
	{ value: "Office Building", label: "Office Building" },
	{ value: "Industrial Building", label: "Industrial Building" },
	{ value: "Residence", label: "Residence" },
	{ value: "Other", label: "Other" },
];
export const LocationMerchant = [
	{ value: "Rents", label: "Rents" },
	{ value: "Owns", label: "Owns" },
];
export const LocationSquareFootage = [
	{ value: "0-500", label: "0-500" },
	{ value: "501-2500", label: "501-2500" },
	{ value: "2501-5000", label: "2501-5000" },
	{ value: "5001-10000", label: "5001-10000" },
	{ value: "10000+", label: "10000+" },
];
export const BillingFrequency = [
	{ label: "Daily", value: "Daily" },
	{ label: "Weekly", value: "Weekly" },
	// { label: "Bi-Weekly", value: "Bi-Weekly" },
	{ label: "Monthly", value: "Monthly" },
	// { label: "Quarterly", value: "Quarterly" },
	// { label: "Annual", value: "Annual" },
	// { label: "Hourly", value: "Hourly" },
	// { label: "Custom", value: "Custom" },
];
export const employmentStatus = [
	{ label: "Employed", value: "Employed" },
	{ label: "Unemployed", value: "Unemployed" },
	{ label: "Self-Employed", value: "Self-Employed" },
	{ label: "Student", value: "Student" },
	{ label: "Retired", value: "Retired" },
	{ label: "Active Military", value: "Active Military" },
	{ label: "Retired Military", value: "Retired Military" },
	{ label: "Other", value: "Other" },
];
