import React, { useEffect, useState } from "react";
import { Input, Label } from "reactstrap";
import useCurrentSaleStore from "store/SaleStore";
import NumberFormat from "react-number-format";
import useCurrentDealStore from "store/DealStore";
import { DealStatus } from "Constants";
import shallow from "zustand/shallow";
import useCurrentRecapStore from "../../../../store/RecapStore";
import { calculateTXapr } from "../../../../helperFunction/deal/SaleFunctions";
import { useFromLocations } from "../../../../store/LocalStorageHelper";

const FinanceAPRInput = () => {
	const state = useFromLocations("state");
	const {
		amtFinanced,
		loanPeriod,
		editApr,
		// calculateCompoundInterest,

		firstDueDate,
		finalDueDate,
		daysToPay,
		apr,
		loanDate,
		term,
	} = useCurrentSaleStore(
		(state) => ({
			loanDate: state.loanDate,
			loanPeriod: state.loanPeriod,
			apr: state.apr,
			editApr: state.editApr,
			amtFinanced: state.amtFinanced,
			firstDueDate: state.firstDueDate,
			finalDueDate: state.finalDueDate,
			daysToPay: state.daysToPay,
			term: state.term,
		}),
		shallow
	);
	const [maxApr, setMaxApr] = useState(0);
	const { editInterestCost } = useCurrentRecapStore((state) => state, shallow);

	// Deal store
	const { type, vehicleYear } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,

			vehicleYear: state?.vehicle?.year,
		}),
		shallow
	);

	useEffect(() => {
		if (Number(loanPeriod) === 5) {
			editApr(0);
			editInterestCost(0);
		} else if (state === "TX") {
			console.log(
				amtFinanced,
				firstDueDate,
				finalDueDate,
				vehicleYear,
				loanDate
			);
			const xApr =
				calculateTXapr(
					amtFinanced,
					firstDueDate,
					finalDueDate,
					vehicleYear,
					loanDate
				) * 100;

			setMaxApr(xApr);
			console.log(apr);
			console.log(xApr);
			if (apr * 100 > xApr) editApr(xApr);
		}
		// eslint-disable-next-line
	}, [
		loanPeriod,
		term,
		amtFinanced,
		firstDueDate,
		finalDueDate,
		daysToPay,
		vehicleYear,
		loanDate,
		vehicleYear,
	]);
	const displayMax = () => {
		if (state === "TX") {
			return "MAX(" + maxApr.toFixed(4) + ")";
		} else {
			return "";
		}
	};

	return (
		<>
			<Label>APR (%) {displayMax()} </Label>
			<NumberFormat
				name="financeAPR"
				value={apr * 100}
				decimalScale={4}
				fixedDecimalScale={true}
				onFocus={(e) => e.target.select()}
				isNumericString={true}
				readOnly={Number(loanPeriod) === 5 || type !== DealStatus.PENDING}
				customInput={Input}
				className="bold"
				onValueChange={(values) => {
					if (Number(loanPeriod) !== 5) {
						if (values.value === "") {
							editApr(0);
							editInterestCost(0);
						} else {
							if (maxApr > 0 && maxApr < values.floatValue) {
								editApr(maxApr / 100);
								editInterestCost(maxApr / 100);
							} else {
								editApr(values.floatValue / 100);
								editInterestCost(values.floatValue / 100);
							}
						}
					}
				}}
			/>
		</>
	);
};

export default FinanceAPRInput;
