import React, { useState } from "react";

import CenteredLoadingSpinner from "component/dc/load/CenteredLoadingSpinner";
import InputElement from "view/formValidation/InputElement";

import { createDigitzsMerchant } from "api/PaymentsAPI";
import { failAlert } from "component/dc/modal/SweetAlerts";
import { showApiError } from "../../../../../helperFunction/ApiErrorHandler";
import { successAlertCustom } from "component/dc/modal/SweetAlerts";
import { useFormContext } from "react-hook-form";
import { useFromUser } from "store/LocalStorageHelper";

const DigitzsSummary = ({
	children,
	data,
	setCurrentForm,
	previousForm,
	toggle,
}) => {
	// Local storage
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	// Local state
	const [showSubmitProgress, setShowSubmitProgress] = useState(false);

	// Create a Digitzs account with the information provided in the sign up form
	const handleSubmit = () => {
		setShowSubmitProgress(true);

		let saveDate = {
			dealerID,
			locationID,
			merchantInfo: {
				accountType: data.bankInfo.accountOwnership,
				accountName: data.bankInfo.accountName,
				personalInfo: { ...data.personalInfo },
				personalAddress: {
					...data.personalAddress,
					line2:
						data.personalAddress.line2?.trim()?.length === 0
							? null
							: data.personalAddress.line2,
				},
				businessInfo: {
					...data.businessInfo,
					ein:
						data.businessInfo.ein?.trim()?.length === 0
							? null
							: data.businessInfo.ein,
				},
				businessAddress: {
					...data.businessAddress,
					line2:
						data.businessAddress.line2.trim().length === 0
							? null
							: data.businessAddress.line2,
				},
				bankInfo: { ...data.bankInfo },
				verificationData: { ...data.agreementInfo },
			},
		};

		createDigitzsMerchant(saveDate).then(
			() => {
				setShowSubmitProgress(false);
				toggle();
				successAlertCustom("Successfully created Digitzs account");
			},
			(err) => {
				setShowSubmitProgress(false);

				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}
			}
		);
	};

	const methods = useFormContext();

	return (
		<div>
			{(showSubmitProgress && <CenteredLoadingSpinner />) || (
				<div className="row row-12">
					{children}
					<h3 className="form-section section-title">Bank Information</h3>
					<div className="row">
						<InputElement
							{...methods}
							colSize="col-lg-4"
							readOnly={true}
							value={data.bankInfo.bankName}
							name="bankName"
							label="Bank Name"
							type="text"
						/>
						<InputElement
							{...methods}
							colSize="col-lg-4"
							readOnly={true}
							value={data.bankInfo.accountType}
							type="text"
							name="accountType"
							label="Account Type"
						/>
						<InputElement
							{...methods}
							colSize="col-lg-4"
							readOnly={true}
							value={data.bankInfo.accountOwnership}
							type="text"
							name="accountOwnership"
							label="Account Used For"
						/>
						<InputElement
							{...methods}
							colSize="col-lg-4"
							readOnly={true}
							value={data.bankInfo.accountName}
							name="accountName"
							label="Account Name"
							type="text"
						/>
						<InputElement
							{...methods}
							colSize="col-lg-4"
							readOnly={true}
							value={data.bankInfo.accountNumber}
							name="accountNumber"
							label="Account Number"
							type="text"
						/>
						<InputElement
							{...methods}
							colSize="col-lg-4"
							readOnly={true}
							value={data.bankInfo.routingNumber}
							name="routingNumber"
							label="Routing Number"
							type="text"
						/>
					</div>
					<button
						className="previous-button"
						onClick={() => setCurrentForm(previousForm)}
					>
						Previous
					</button>
					<button
						type="submit"
						className="submit-button"
						onClick={handleSubmit}
					>
						Submit
					</button>
				</div>
			)}
		</div>
	);
};

export default DigitzsSummary;
