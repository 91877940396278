import React from "react";
import { Card, CardTitle, CardBody } from "reactstrap";
import { ReviewRow } from "component/dc/deal/review/Review";
import useCurrentDealStore from "store/DealStore";
import shallow from "zustand/shallow";

const ReviewVehicle = () => {
	const {
		stockNo,
		year,
		make,
		model,
		vin,
		license,
		odometer,
		numCylin,
	} = useCurrentDealStore(
		(state) => ({
			stockNo: state?.vehicle?.stockNo || "",
			year: state?.vehicle?.year || "",
			make: state?.vehicle?.make || "",
			model: state?.vehicle?.model || "",
			vin: state?.vehicle?.vin || "",
			license: state?.vehicle?.license || "",
			odometer: state.vehicle?.odometerOut || "",
			numCylin: state.vehicle?.numCylinders || "",
		}),
		shallow
	);

	//const { stockNo, year, make, model, vin } = vehicle;

	return (
		<Card style={{ minHeight: "350px" }}>
			<CardBody>
				<CardTitle tag="bold">Vehicle</CardTitle>
				<ReviewRow label="Stock No" value={stockNo} />
				<ReviewRow label="Vehicle" colSize={"4"} value={year + " " + make} />
				<ReviewRow label="" value={model} />
				<ReviewRow label="VIN" colSize={"3"} value={vin} />
				<ReviewRow label="License" value={license} />
				<ReviewRow label="Odometer" value={odometer} />
				<ReviewRow label="# of Cylinders" value={numCylin} />
			</CardBody>
		</Card>
	);
};

export default ReviewVehicle;
