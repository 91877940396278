import React from "react";
import useCurrentDealStore from "../../../../store/DealStore";
import shallow from "zustand/shallow";
import { personnelType } from "Constants";
import { Label } from "reactstrap";
import Select from "react-select";

const DealSalesmanDropDown = ({
	label = "Sales Person",
	isComm = false,
	setPersonRecNum,
	personRecNum,
}) => {
	// Deal store
	const {
		salesmanRecNum,
		editSalesmanRecNum,
		salePeople,
	} = useCurrentDealStore(
		(state) => ({
			salesmanRecNum: state.deal.salesmanRecNum,
			editSalesmanRecNum: state.editSalesmanRecNum,
			salePeople: state.salesPeople,
		}),
		shallow
	);

	const salespeopleList = salePeople.map((obj) => {
		return {
			label:
				obj.firstName +
				" " +
				obj.lastName +
				" - " +
				"" +
				personnelType.find((pt) => pt.value === obj.personnelType).label,

			value: obj.ID,
			dcLogin: obj,
		};
	});

	//add None to salePeople List
	if (!isComm) {
		salespeopleList.push({ label: "None", value: null });
	} else {
		if (personRecNum === null) {
			setPersonRecNum(salespeopleList[0]?.value);
		}
	}

	return (
		<div className={isComm && "col-md-3"}>
			<Label>{label}</Label>
			<Select
				className="bold"
				noOptionsMessage={() => "No person available"}
				value={
					isComm
						? {
								value: personRecNum,
								label: salespeopleList.filter(
									(element) => element.value === personRecNum
								)[0]?.label,
						  }
						: {
								value: salesmanRecNum,
								label: salespeopleList.filter(
									(element) => element.value === salesmanRecNum
								)[0]?.label,
						  }
				}
				options={salespeopleList}
				onChange={(e) => {
					if (isComm) setPersonRecNum(e.value);
					else editSalesmanRecNum(e.value);
				}}
			/>
		</div>
	);
};

export default DealSalesmanDropDown;
