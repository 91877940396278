/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)

* Coded by DealerClick Software **Peyman))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import {
	Row,
	Table,
	Button,
	Col,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
} from "reactstrap";

import ChildrenModal from "../../../component/dc/modal/ChildrenModal";
import CommissionForm from "../../../component/dc/deal/commission/CommissionForm";

import {
	deleteSuccessAlert,
	deleteFailAlert,
	confirmDeleteAlert,
} from "component/dc/modal/SweetAlerts";
import { deleteCommission } from "api/DealAPI";
import useCurrentDealStore from "../../../store/DealStore";
import { FormattedText } from "view/formValidation/InputElement";
import { commTypeList } from "../../../Constants.js";
import useCurrentRecapStore from "store/RecapStore";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";
import shallow from "zustand/shallow";
import { personnelType } from "Constants";
// GrossProfit	= Total of all profits and fees (new)
// salesGross	= total of Sale commissionable
// fiGross	= total of F&I commissionable
// totalGross	= total of all commissionable (new) (edited)

// profitComm	= total of all commission with type GP(GrossProfit) (new)
// salesComm	= total of all commission with type Sale (salesGross)
// fiComm	    = total of all commission with type F&I(fiGross)
// totalComm	= total of all commission with type TOTAL
// commGross	= total of all commission

// netProfit	= profit after commission (new)

//const CommissionTable = ({ setCurrentIndex, toggle }) => {
const CommissionTable = () => {
	const {
		commissions,
		editCommissions,
		salesPeople,
		calculate,
	} = useCurrentDealStore(
		(state) => ({
			commissions: state.commissions,
			editCommissions: state.editCommissions,
			salesPeople: state.salesPeople,
			calculate: state.calculate,
		}),
		shallow
	);

	const [currentIndex, setCurrentIndex] = useState(commissions.length);
	const [showAddCommission, setShowAddCommission] = useState(false);
	const toggleAddCommission = () => setShowAddCommission(!showAddCommission);
	//const [tmpDesc, setTmpDesc] = useState("");

	const { commGross } = useCurrentRecapStore((state) => state, shallow);

	const commTypeDesc = (commType) => {
		// eslint-disable-next-line
		if (commType == commTypeList.SALE.value) {
			return commTypeList.SALE.label;
		}
		// eslint-disable-next-line
		if (commType == commTypeList.FI.value) {
			return commTypeList.FI.label;
		}
		// eslint-disable-next-line
		if (commType == commTypeList.TOTAL.value) {
			return commTypeList.TOTAL.label;
		}
		// eslint-disable-next-line
		if (commType == commTypeList.NETPROFIT.value) {
			return commTypeList.NETPROFIT.label;
		}
	};

	const commHead = [
		"#",
		"Person",
		"Commission Type",
		"Commission",
		"Bonus",
		"Other",
		"Total",
		"Actions",
	];

	const salespeopleList = salesPeople.map((obj) => {
		return {
			label:
				obj.firstName +
				" " +
				obj.lastName +
				" - " +
				personnelType.find((pt) => pt.value === obj.personnelType).label,
			value: obj.ID,
			dcLogin: obj,
		};
	});

	const head = commHead.map((key, index) => (
		<th key={`${key} ${index}`}>{key}</th>
	));
	console.log(commissions);
	const rows = commissions.map((key, index) => (
		<tr key={`${key} ${index}`}>
			<th scope="row">{index + 1}</th>
			<td>
				{
					salespeopleList.filter(
						(element) => element.value === key.personRecNum
					)[0]?.label
				}
			</td>
			<td> {commTypeDesc(key.commType)}</td>
			<td>
				<FormattedText
					value={key.commAmount}
					fixedDecScale={true}
					prefix={"$"}
				/>
			</td>
			<td>
				<FormattedText value={key.bonus} fixedDecScale={true} prefix={"$"} />
			</td>
			<td>
				<FormattedText value={key.other} fixedDecScale={true} prefix={"$"} />
			</td>
			<td>
				<FormattedText
					value={key.bonus + key.other + key.commAmount}
					fixedDecScale={true}
					prefix={"$"}
				/>
			</td>
			<td>
				<div className="d-flex justify-content-center">
					<Button
						className="btn btn-md btn-primary"
						onClick={() => {
							setCurrentIndex(index);
							toggleAddCommission();
						}}
					>
						<i className="nc-icon nc-align-center"></i>
						<span className="noMobile"> Edit</span>
					</Button>
					<div
						className="btn btn-md btn-link btn-link-danger text-danger ml-2"
						onClick={() => {
							console.log(key.id + " " + index);
							toggleModal(key.id, key.currentIndex, index);
						}}
					>
						<i className="nc-icon nc-simple-remove" /> Remove
					</div>
				</div>
			</td>
		</tr>
	));

	// Modal
	const toggleModal = (currentID, currentIndex, arrayIndex) => {
		if (currentID == null) {
			const removedCommission = commissions.filter(
				(_com, index) => index !== arrayIndex
			);

			editCommissions(removedCommission);
			setCurrentIndex(removedCommission.length);
			calculate();
		} else {
			confirmDeleteAlert(removeCommission, currentID, currentIndex);
		}
	};

	const removeCommission = (ID) => {
		deleteCommission(ID).then(
			() => {
				let obj = commissions.filter((item) => item.id !== ID);
				editCommissions(obj);
				setCurrentIndex(obj.length);
				calculate();
				deleteSuccessAlert();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, deleteFailAlert);
				}
			}
		);
	};

	return (
		<>
			<ChildrenModal
				modal={showAddCommission}
				toggle={toggleAddCommission}
				modalTitle="Commission"
				children={
					<CommissionForm
						currentIndex={currentIndex}
						setCurrentIndex={setCurrentIndex}
						//toggle={toggle}
						toggle={toggleAddCommission}
						salespersonList={salespeopleList}
					/>
				}
			/>
			<Button
				className="btn btn-primary btn-sm mb-2"
				onClick={toggleAddCommission}
			>
				<i className="nc-icon nc-simple-add" />
				Add Commission
			</Button>
			<Card>
				<CardHeader>
					<CardTitle tag="h3">Commissions</CardTitle>
				</CardHeader>
				<CardBody>
					<Row className="mx-0 px-3 pt-3">
						<Table striped bordered hover>
							<thead>
								<tr>{head}</tr>
							</thead>
							<tbody>{rows}</tbody>
						</Table>
						<Table className="mb-0">
							<Row>
								<Col className="mt-3 col-6 text-right">
									<h6>Total Commission:</h6>
								</Col>
								<Col className="mt-3 col-6 text-left">
									<h6>
										<FormattedText
											value={commGross}
											fixedDecScale={true}
											prefix={"  $"}
										/>
									</h6>
								</Col>
							</Row>
						</Table>
					</Row>
				</CardBody>
			</Card>
		</>
	);
};
export default CommissionTable;
