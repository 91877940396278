import React, { useState } from "react";
import { Button, Input, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useFromUser } from "store/LocalStorageHelper";

const AddVendorModal = ({
	title,
	vendorType,
	vendorLabel,
	addVendor,
	toggle,
	modal,
}) => {
	// Local storage
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	// Local state
	const [vendorName, setVendorName] = useState("");

	// Calls callback function to save the new vendor and closes modal
	const saveNewVendor = () => {
		if (vendorName != null && vendorName !== "" && vendorType != null) {
			const newVendor = {
				name: vendorName,
				vendorType,
				dealerID,
				locationID,
			};

			addVendor(newVendor);
		}

		toggle();
	};

	return (
		<Modal
			toggle={toggle}
			size="md"
			isOpen={modal}
			onClosed={() => setVendorName("")}
		>
			<div className="modal-header justify-content-center">
				<button
					type="button"
					className="close"
					data-dismiss="modal"
					aria-label="Close"
					onClick={toggle}
				>
					<span aria-hidden="true">×</span>
				</button>
				<h5 className="modal-title">{title}</h5>
			</div>
			<ModalBody>
				<Input
					placeholder={`${vendorLabel} Name`}
					value={vendorName}
					onChange={(e) => setVendorName(e.target.value)}
				/>
			</ModalBody>
			<ModalFooter className="d-flex justify-content-center">
				<Button onClick={saveNewVendor}>Save</Button>
			</ModalFooter>
		</Modal>
	);
};

export default AddVendorModal;
