import React, { Fragment } from "react";

import { Row, Card, CardHeader, CardTitle, CardBody } from "reactstrap";

const InputWrapper = ({
	formTitle,
	centerTitle,
	inputComponents,
	buttons,
	otherComponents,
	footer,
}) => {
	const componentList = inputComponents.map((component, i) => (
		<Fragment key={i}>{component}</Fragment>
	));

	return (
		<>
			<Card>
				<CardHeader>
					<CardTitle>
						<h3 className={centerTitle && "d-flex justify-content-center"}>
							{formTitle}
						</h3>
					</CardTitle>
				</CardHeader>
				<CardBody>
					<Row className="mx-0">{componentList}</Row>

					{buttons}
				</CardBody>
				{footer}
			</Card>
			{otherComponents}
		</>
	);
};

export default InputWrapper;
