/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 DealerClick (https://dealerclick.com)

* Coded by DealerClick Software ))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";

import history from "../../../helperFunction/History";
import useCurrentDealStore from "store/DealStore";
import useCurrentVehicleStore from "store/InventoryStore";
import useRoute from "helperFunction/UseRoute";
import Camera from "assets/img/imgPlaceholder.png";
import ChildrenModal from "../modal/ChildrenModal";
import DealTemplateModal from "../../../view/settings/settingsTabs/dealTemplates/DealTemplateModal";
import InventoryTable from "../../../component/dc/table/InventoryTable";
import Loading from "component/dc/load/Loading";

import { getVehicleListByStatus, getVehicleDetails } from "api/InventoryAPI";
import { retrieveFailAlert } from "component/dc/modal/SweetAlerts";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";
import { useFromUser } from "store/LocalStorageHelper";
import { useLocation } from "react-router-dom";
import { PATHS } from "Constants";
import { removeVehicle } from "api/InventoryAPI";
import {
	successAlertCustom,
	confirmDeleteMessage,
	deleteFailAlert,
} from "../../../component/dc/modal/SweetAlerts";
import shallow from "zustand/shallow";
import dayjs from "dayjs";

//make a container class to get, and hold all our data for this container
const InventoryListData = ({ statusList }) => {
	console.log(statusList);
	const location = useLocation();
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	//let statusList = "Availabel,Pending";
	const inventoryDetail = useRoute(PATHS.INVENTORY_DETAIL);
	const homeRoute = useRoute(PATHS.DASHBOARD);
	const newRoute = useRoute(PATHS.INVENTORY_LIST);

	// Local states
	const [data, setData] = useState([]);
	const [loadingList, setLoadingList] = useState(true);
	const [templateModal, setTemplateModal] = useState(false);
	const [vehicle, setVehicle] = useState({});

	const toggleTemplate = () => setTemplateModal(!templateModal);
	const RemoveVehicle = (inventoryID) => {
		let answer = "Cancel";
		confirmDeleteMessage("Yes, Delete it").then((res) => {
			answer = res;
			if (answer === "Yes, Delete it") {
				removeVehicle(inventoryID).then(
					(response) => {
						history.push(newRoute);

						successAlertCustom("Your vehicle removed from your inventory!");
					},
					(err) => {
						if (!err.isGeneralError) {
							showApiError(err, deleteFailAlert);
						}
					}
				);
			}
		});
	};

	// Vehicle store
	const { editThumbnail, editInventoryID } = useCurrentVehicleStore(
		(state) => ({
			editThumbnail: state.editThumbnail,
			editInventoryID: state.editInventoryID,
		}),
		shallow
	);

	//Deal Store
	const { editCarRecNum } = useCurrentDealStore(
		(state) => ({
			editCarRecNum: state.editCarRecNum,
		}),
		shallow
	);

	// Opens the inventory details page
	const accessDetailsHandler = (inventoryID, thumbnail) => {
		editThumbnail(thumbnail);
		editInventoryID(inventoryID);

		const params = new URLSearchParams(location.search);
		const queryParamState = params.get("state");
		const queryParamCode = params.get("code");

		if (queryParamCode != null && queryParamState != null) {
			history.push(
				`${inventoryDetail}?code=${queryParamCode}&state=${queryParamState}`,
				{ ID: inventoryID }
			);
		} else {
			history.push(inventoryDetail, { ID: inventoryID });
		}
	};

	// Formats the JSON response object
	const dataFormat = (content) => {
		const container = Object.keys(content).map((key) => {
			let container = {};
			console.log(content[key]);
			container.thumbnail = (
				<div
					onClick={() => {
						accessDetailsHandler(container.inventoryID, container.thumbnailURL);
					}}
				>
					<img
						className="zoom"
						alt="vehicle"
						src={`${content[key].thumbnail}?lastmod=${Date.now()}`}
						onError={(e) => {
							e.target.onerror = null;
							e.target.src = Camera;
						}}
					/>
				</div>
			);
			container.thumbnailURL = content[key].thumbnail;

			container.inventoryID = content[key].inventoryID;
			container.license = content[key].license;
			container.stockAndVin =
				dayjs(content[key].dateIn).format("YYYY/MM/DD") +
				" " +
				content[key].stockNo;
			container.stockNo = content[key].stockNo;
			container.vehicle =
				content[key].year + " " + content[key].make + " " + content[key].model;
			container.vehicleSearch =
				content[key].make +
				" " +
				content[key].model +
				" " +
				content[key].year +
				content[key].vin +
				content[key].license;
			container.askingPrice =
				"$" +
				parseFloat(content[key].price)
					.toFixed(2)
					.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
			container.vin = content[key].vin;
			container.status = content[key].vehicleStatus;

			const today = dayjs();
			const start = dayjs(content[key].dateIn);
			const end =
				content[key]?.dateOut === null ||
				content[key]?.dateOut === "" ||
				content[key]?.dateOut <= content[key]?.dateIn
					? today
					: dayjs(content[key].dateOut);
			const diff = end.diff(start, "day");
			container.age = diff;
			container.start = start.format("MM/DD/YYYY");
			container.end = "";
			container.end =
				content[key]?.dateOut === null ? "" : end.format("MM/DD/YYYY");
			console.log(container.end);
			container.Actions = (
				<Row>
					<div
						className="zoom btn-md btn btn-primary"
						onClick={() => {
							accessDetailsHandler(
								container.inventoryID,
								container.thumbnailURL
							);
						}}
					>
						<i className="icon-big text-center nc-icon nc-zoom-split text-default"></i>{" "}
						<span className="noMobile"> View</span>
					</div>
					{statusList === "Available,Pending" && (
						<div
							className="zoom btn-md btn btn-link btn-link-success"
							onClick={() => {
								getVehicleData(container.inventoryID, container.thumbnailURL);
							}}
							readOnly={
								content[key].vehicleStatus !== "Pending" &&
								content[key].vehicleStatus !== "Available"
							}
						>
							<i className="icon-big text-center nc-icon nc-briefcase-24"></i>{" "}
							<span className="noMobile"> Deal</span>{" "}
						</div>
					)}
					{statusList === "Trade,Hold,Deleted" &&
						(container.status === "Deleted" ||
							container.status === "deleted" ||
							container.status === "DELETED") && (
							<div
								className="btn btn-danger btn-md mt-2" //"zoom btn-md btn btn-link btn-danger"
								onClick={() => {
									RemoveVehicle(container.inventoryID);
									//getVehicleData(container.inventoryID, container.thumbnailURL);
								}}
							>
								<i className="icon-big text-center nc-icon nc-briefcase-24"></i>{" "}
								<span className="noMobile"> Remove </span>{" "}
							</div>
						)}
				</Row>
			);
			return container;
		});
		return container;
	};
	const getListData = (statusList) => {
		console.log("ID: " + dealerID + " " + locationID);
		if (dealerID !== "" && locationID !== "") {
			getVehicleListByStatus(dealerID, locationID, statusList).then(
				(response) => {
					console.log("new list");
					console.log(response);
					const result = dataFormat(response.data.content);
					setData(result);
					setLoadingList(false);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
						history.push(homeRoute);
					}
				}
			);
		}
	};
	const getVehicleData = (inventoryID) => {
		getVehicleDetails(inventoryID).then(
			(res) => {
				console.log(res.data.content);
				setVehicle(res.data.content);
				editCarRecNum(res.data.content.inventoryID);
				editThumbnail(res.data.content.thumbnail);
				toggleTemplate();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
					history.push(homeRoute);
				}
			}
		);
	};
	useEffect(() => {
		setLoadingList(true);
		getListData(statusList);
		// eslint-disable-next-line
	}, [statusList]);
	return (
		<div>
			{loadingList && <Loading />}
			{!loadingList && <InventoryTable data={data} />}
			<ChildrenModal
				modalTitle="Deal Templates"
				modal={templateModal}
				toggle={toggleTemplate}
			>
				<DealTemplateModal newVehicle={vehicle} />
			</ChildrenModal>
		</div>
	);
};

export default InventoryListData;
