/*!
 
=========================================================
* DealerClick 2.0 - v1.0
=========================================================
 
* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)
 
* Coded by DealerClick Software **RoarinLion))
 
=========================================================
 
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 
*/
import React, { useContext, useEffect, useState } from "react";

import useCurrentCustomerStore from "../../store/CustomerStore";
import useRoute from "../../helperFunction/UseRoute";
import AttachmentModal from "../../component/dc/attachment/AttachmentModal";
import BusinessDetailsTab from "./customerDetailTab/detail/BusinessDetailsTab";
import ChildrenModal from "../../component/dc/modal/ChildrenModal";
import CustomerDealList from "./CustomerDealList";
import CustomerDetailsTab from "./customerDetailTab/detail/CustomerDetailsTab";
import CustomerAddressHistoryTab from "./customerDetailTab/address/CustomerAddressHistoryTab";
import CustomerEmployeeHistoryTab from "./customerDetailTab/employment/CustomerEmployeeHistoryTab";
import CustomerInsuranceTab from "./customerDetailTab/insurance/CustomerInsuranceTab";
import CustomerReferencesTab from "./customerDetailTab/reference/CustomerReferencesTab";
import CustomerSaveNoteButton from "../../component/dc/customer/CustomerSaveNoteButton";
import MailgunEmail from "../../component/dc/integration/mailgun/MailgunEmail";
import NavBar from "../../component/dc/tab/NavTabsHolder";
import Twilio from "../../component/dc/integration/twilio/Twilio";
import UpdateCustomerButton from "../../component/dc/customer/UpdateCustomerButton";

import { getAllCustomerInfo } from "../../api/CustomerAPI";
import { showApiError } from "../../helperFunction/ApiErrorHandler";
import { retrieveFailAlert } from "../../component/dc/modal/SweetAlerts";
import { CustomerStartDealButton } from "../../component/dc/customer/CustomerStartDealButton";
import { CallButton } from "../../component/dc/button/CallAndTextButton";
import { PATHS } from "../../Constants";

import shallow from "zustand/shallow";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import { setLocale } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { FormProvider } from "react-hook-form";
import {
	TemplateVariableContext,
	TemplateVariableProvider,
} from "../../helperFunction/TemplateVariableContext";
import TemplateVariableInit from "../../helperFunction/TemplateVariableInit";

const CustomerDetailPage = () => {
	// Route
	const history = useHistory();
	const newRoute = useRoute(PATHS.CUSTOMER_LIST);
	const [customerListModal, setCustomerListModal] = useState(false);
	// Opens or closes the deal list modal
	const toggleCustomerListModal = () =>
		setCustomerListModal(!customerListModal);

	// Customer store
	const {
		ID,
		isLoading,
		reset,
		cellPhone,
		email,
		setCustomerFields,
		editIsLoadingFalse,
		editAddressHist,
		editEmpHist,
		editInsurance,
		editReferences,
		editCurrentAddress,
		editMailingAddress,
		editCredit700Hist,
		customerType,
		originalID,
	} = useCurrentCustomerStore(
		(state) => ({
			ID: state.ID,
			firstName: state.firstName,
			lastName: state.lastName,
			isLoading: state.isLoading,
			reset: state.reset,
			cellPhone: state.cellPhone,
			email: state.email,
			setCustomerFields: state.setCustomerFields,
			editIsLoadingFalse: state.editIsLoadingFalse,
			editAddressHist: state.editAddressHist,
			editEmpHist: state.editEmpHist,
			editInsurance: state.editInsurance,
			editReferences: state.editReferences,
			editCurrentAddress: state.editCurrentAddress,
			editMailingAddress: state.editMailingAddress,
			editCredit700Hist: state.editCredit700Hist,
			customerType: state.type,
			originalID: state.originalID,
		}),
		shallow
	);
	console.log(ID);

	// Calls API to get all this customer's information
	const getData = () => {
		const currentID = ID || history.location.state?.ID;
		if (currentID != null) {
			getAllCustomerInfo(currentID).then(
				(res) => updateStore(res.data.content),
				(err) => {
					console.log(err);

					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
						history.push(newRoute);
					}
				}
			);
		} else {
			retrieveFailAlert();
			history.push(newRoute);
		}
	};

	// Updates customer store fields with the return API data
	const updateStore = (data) => {
		console.log(data);
		setCustomerFields(data.customer);
		editAddressHist(data.addressHistory);
		editEmpHist(data.employmentHistory);
		editInsurance(data.insurance);
		editReferences(data.references);
		editCurrentAddress(data.currentAddress);
		editMailingAddress(data.mailingAddress);
		editCredit700Hist(data.credit700);
		editIsLoadingFalse();
	};

	useEffect(() => {
		getData();

		return () => {
			reset();
		};
		// eslint-disable-next-line
	}, []);

	const personalTabs = [
		"Details",
		"Residential",
		"Employment",
		"Insurance",
		"Refs",
	];

	const personalTabComponents = [
		<CustomerDetailsTab />,
		<div id="addressHist">
			<CustomerAddressHistoryTab>
				<UpdateCustomerButton />
			</CustomerAddressHistoryTab>
		</div>,
		<div id="empHist">
			<CustomerEmployeeHistoryTab />
		</div>,
		<CustomerInsuranceTab />,
		<CustomerReferencesTab />,
	];

	const businessTabs = ["Business"];

	const businessTabComponents = [<BusinessDetailsTab />];
	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
		},
	});

	// Define rules for each input field
	// If phone validation needed: regex -> ^\+1\s\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}|^\+1\s\(\s{3}\)\s_{3}-_{4}
	const schema = yup.object().shape({
		firstName: yup.string().required().max(100),
		lastName: yup.string().required().max(100),

		ssn: yup.string().matches(/^[0-9]{3}-[0-9]{2}-[0-9]{4}/, {
			message: "Must be 9 digits or empty",
			excludeEmptyString: true,
		}),
		email: yup.string().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, {
			message: "Invalid Email",
			excludeEmptyString: true,
		}),
		cellPhone: yup
			.string()
			.matches(/^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, {
				message: "Invalid Phone Number",
				excludeEmptyString: true,
			}),
		homePhone: yup
			.string()
			.matches(/^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, {
				message: "Invalid Phone Number",
				excludeEmptyString: true,
			}),
		extraPhone: yup
			.string()
			.matches(/^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, {
				message: "Invalid Phone Number",
				excludeEmptyString: true,
			}),
		workPhone: yup
			.string()
			.matches(/^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, {
				message: "Invalid Phone Number",
				excludeEmptyString: true,
			}),
		city: yup.string().max(50),
		currentStreet: yup.string().max(50),
		currentCity: yup.string().max(50),
		mailStreet: yup.string().max(50),
		mailCity: yup.string().max(50),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<TemplateVariableProvider>
			<div className="content">
				<div className="action-bar action-bar pt-1 mx-0 px-0">
					<div className="text-center">
						<CallButton />
						<TemplateVariableInit />
						<CommunicationButtons cellPhone={cellPhone} ID={ID} email={email} />
						<AttachmentModal relatedID={originalID} type="customer" />

						<CustomerStartDealButton />
						<div
							className="btn btn-primary-dark btn-md mr-3 onMobileScreen"
							onClick={toggleCustomerListModal}
						>
							View Deals
						</div>
						<CustomerSaveNoteButton />

						<FormProvider {...methods}>
							<UpdateCustomerButton isHeader={true} />
						</FormProvider>
					</div>
				</div>

				{!isLoading && (
					<div className="col-md-12 px-0">
						<FormProvider {...methods}>
							{customerType === 0 || customerType === null ? (
								<NavBar
									navClass="nav-pills-primary justify-content-center nav nav-pills"
									tabs={personalTabs}
									tabComponents={personalTabComponents}
								/>
							) : (
								<NavBar
									navClass="nav-pills-primary justify-content-center nav nav-pills"
									tabs={businessTabs}
									tabComponents={businessTabComponents}
								/>
							)}
						</FormProvider>
						<ChildrenModal
							modal={customerListModal}
							toggle={toggleCustomerListModal}
							modalTitle="Customer Deals"
							children={
								<CustomerDealList
									toggle={toggleCustomerListModal}
									originalID={originalID}
								/>
							}
						/>
					</div>
				)}
			</div>
		</TemplateVariableProvider>
	);
};

const CommunicationButtons = ({ cellPhone, ID, email }) => {
	const { setRequestDocs } = useContext(TemplateVariableContext);
	useEffect(() => {
		setRequestDocs(true);
		// eslint-disable-next-line
	}, []);
	return (
		<>
			<Twilio sendToNumber={cellPhone} customerID={ID} />
			<MailgunEmail customerEmail={email} />
		</>
	);
};
export default CustomerDetailPage;
