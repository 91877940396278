import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardFooter,
	Form,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Col,
} from "reactstrap";

import Logo from "../../assets/img/logo-dark.svg";
import Footer from "layout/Footer.js";

import { failAlert } from "component/dc/modal/SweetAlerts";
import { getUserDetails } from "api/LoginAPI";
import { login } from "../../auth/Authentication";
import { loginFailAlert } from "component/dc/modal/SweetAlerts";
import { PATHS } from "../../Constants";

const LoginForm = () => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const history = useHistory();

	const handleSubmitClick = (e) => {
		e.preventDefault();

		getUserDetails({
			username,
			password,
		}).then(
			(res) => {
				if (res.status === 200) {
					let resp = res.data.content;

					const data = {
						...resp.dcLogin,
						locations: resp.locations,
						dealers: resp.dealers,
						jws: resp.jws,
						profileData: resp.profileData,
						messagingNumber: resp.phoneNumber,
					};

					login(JSON.stringify(data));

					if (resp.dcLogin.lastLoginDate === null) {
						history.push(PATHS.UPDATE_PASSWORD);
					} else {
						history.push(PATHS.DASHBOARD);
					}
				}
			},
			(error) => {
				if (error?.response?.data?.message) {
					failAlert(error.response.data.message);
				} else {
					loginFailAlert();
				}
			}
		);
	};

	return (
		<>
			<div className="login-page">
				<Container className="py-5">
					<Col className="ml-auto mr-auto vertical-align" lg="4" md="6">
						<Form action="" className="form mt-10" method="">
							<Card className="card-login">
								<img src={Logo} alt="DealerClick Logo" className="logo mt-3" />
								<CardBody className="px-4">
									<InputGroup>
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="nc-icon nc-single-02 text-primary" />
											</InputGroupText>
										</InputGroupAddon>
										<input
											type="text"
											className="form-control"
											id="username"
											aria-describedby="usernameHelp"
											placeholder="Enter username"
											value={username}
											onChange={(e) => setUsername(e.target.value)}
										/>
									</InputGroup>
									<InputGroup className="mt-2">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="nc-icon nc-key-25 text-primary" />
											</InputGroupText>
										</InputGroupAddon>
										<input
											type="password"
											className="form-control"
											id="password"
											placeholder="Password"
											value={password}
											onChange={(e) => setPassword(e.target.value)}
										/>
									</InputGroup>
									<div className="text-center mt-3">
										<div
											type="submit"
											className="btn btn-primary mx-auto"
											onClick={handleSubmitClick}
										>
											<i className="nc-icon nc-spaceship" /> Log In
										</div>
									</div>
								</CardBody>
								<CardFooter className="text-center">
									<div className="">
										<a
											className="mx-2 text-center"
											href="/"
											onClick={(e) => {
												e.preventDefault();
												history.push(PATHS.FORGOTTEN_PASSWORD);
											}}
										>
											I forgot my password...
										</a>
									</div>
									<hr className="mt-2 pb-2" />
									<a
										href="/#"
										onClick={(e) => {
											e.preventDefault();
											history.push(PATHS.NEW_USER);
										}}
									>
										Click here to activate new account
									</a>
								</CardFooter>
							</Card>
						</Form>
					</Col>
				</Container>
			</div>
			<Footer />
		</>
	);
};

export default LoginForm;
