import { driveType } from "Constants";
import React, { useState } from "react";
import {
	Card,
	CardBody,
	CardHeader,
	ListGroup,
	ListGroupItem,
	Collapse,
	Col,
	Row,
} from "reactstrap";

const InventorySpecificEquipment = (props) => {
	const [open, setOpen] = useState(false);

	const handleCollapse = () => {
		setOpen(!open);
	};
	let specObj = "";
	let specifications = "";
	let specificationsEnd = "";
	try {
		specObj = JSON.parse(props.specificationEquip);
	} catch (e) {
		console.log(e.message);
	}

	if (specObj !== null && specObj !== "") {
		let halfSize = specObj.length / 2;
		specifications = Object.keys(specObj.slice(0, halfSize)).map((key) => (
			<div key={specObj[key].Key}>
				<br></br>
				<h6>{specObj[key].Key}</h6>
				<ListGroup>
					{Object.keys(specObj[key].Value).map((key2, i) => (
						<ListGroupItem key={i}>
							{specObj[key].Value[key2].Name === "Drive Type"
								? driveType.filter(
										(e) => specObj[key].Value[key2].Value === e.value
								  )[0]?.label
								: specObj[key].Value[key2].Name +
								  " " +
								  specObj[key].Value[key2].Value}
						</ListGroupItem>
					))}
				</ListGroup>
			</div>
		));

		let specEnd = specObj.slice(halfSize, specObj.length - 1);
		specificationsEnd = Object.keys(specEnd).map((key) => (
			<div key={specEnd[key].Key}>
				<br></br>
				<h6>{specEnd[key].Key}</h6>
				<ListGroup>
					{Object.keys(specEnd[key].Value).map((key2, i) => (
						<ListGroupItem key={i}>
							{specEnd[key].Value[key2].Name +
								" " +
								specEnd[key].Value[key2].Value}
						</ListGroupItem>
					))}
				</ListGroup>
			</div>
		));
	}
	return (
		<Card>
			<CardHeader onClick={handleCollapse} className="cursor-pointer">
				<h3>Specifications </h3> <i className="nc-icon nc-minimal-down" />
			</CardHeader>
			<CardBody>
				<Collapse role="tabpanel" isOpen={open}>
					<Row>
						<Col>
							<CardBody>{specifications}</CardBody>
						</Col>
						<Col>
							<CardBody>{specificationsEnd}</CardBody>
						</Col>
					</Row>
				</Collapse>
			</CardBody>
		</Card>
	);
};

export default InventorySpecificEquipment;
