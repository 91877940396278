import React, { useState } from "react";
import { Button } from "reactstrap";

import tabOnEnter from "../../../../helperFunction/tabOnEnter";
import useCurrentCustomerStore from "../../../../store/CustomerStore";
import useCurrentDealStore from "../../../../store/DealStore";
import AddressHistoryTable from "./AddressHistoryTable";
import AddressHistoryForm from "./AddressHistoryForm";
import ChildrenModal from "../../../../component/dc/modal/ChildrenModal";
import CurrentAddress from "./CurrentAddress";
import CustomerLandlordForm from "../detail/CustomerLandLordForm";
import ZipCityTable from "../../../../component/dc/address/ZipCityTable";

import { DealStatus } from "../../../../Constants";

import shallow from "zustand/shallow";

const CustomerAddressHistoryTab = ({
	isDeal = false,
	isBuyer = false,
	children,
}) => {
	const [cityModal, setCityModal] = useState(false);
	const [addressData, setAddressData] = useState({});

	// Customer store
	const { currentAddress, editCurrentAddress } = useCurrentCustomerStore(
		(state) => ({
			currentAddress: state.currentAddress,
			editCurrentAddress: state.editCurrentAddress,
		}),
		shallow
	);

	// Opens and closes the current zip city modal
	const toggleCityModal = () => setCityModal(!cityModal);

	// Show modal containing cities with the 5 digit zip entered for current address
	const handleZipChange = (zip, address) => {
		setAddressData({ zip, address });
		toggleCityModal();
	};

	// Deal store
	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);

	// Customer store
	const { addressHist } = useCurrentCustomerStore(
		(state) => ({
			addressHist: state.addressHist,
		}),
		shallow
	);

	// Local state
	const [currentIndex, setCurrentIndex] = useState(addressHist.length);
	const [modal, setModal] = useState(false);

	const toggle = () => setModal(!modal);

	return (
		<>
			<div className="col" onKeyDown={tabOnEnter}>
				<div id="currentAddress">
					<CurrentAddress getCities={handleZipChange} />
					<ChildrenModal
						modal={cityModal}
						toggle={toggleCityModal}
						modalTitle="Cities"
					>
						<ZipCityTable
							data={addressData}
							toggle={toggleCityModal}
							address={currentAddress}
							setAddress={editCurrentAddress}
							isDeal={isDeal}
							isBuyer={isBuyer}
						/>
					</ChildrenModal>
				</div>
				<CustomerLandlordForm />
				<ChildrenModal
					modal={modal}
					toggle={toggle}
					modalTitle={"Add Address History"}
					children={
						<AddressHistoryForm
							currentIndex={currentIndex}
							setCurrentIndex={setCurrentIndex}
							toggle={toggle}
						/>
					}
				/>
				<AddressHistoryTable
					readOnly={type !== DealStatus.PENDING}
					setCurrentIndex={setCurrentIndex}
					toggle={toggle}
				/>
			</div>

			<div className="col pb-3">
				<div className="row mx-0">
					<div className="col-md-6">
						<Button
							className="btn btn-primary btn-md"
							readOnly={type !== DealStatus.PENDING}
							onClick={toggle}
						>
							<i className="nc-icon nc-simple-add"></i> Add Address
						</Button>
					</div>
					<div className="col-md-6 text-right">{children}</div>
				</div>
			</div>
		</>
	);
};
export default CustomerAddressHistoryTab;
