import React from "react";
import NumberFormat from "react-number-format";
import {
	Row,
	Card,
	CardBody,
	Col,
	Label,
	CardText,
	Input,
	CardTitle,
} from "reactstrap";
import useCurrentDealStore from "store/DealStore";
import useCurrentSaleStore from "store/SaleStore";
import shallow from "zustand/shallow";

const SellerFeeTax = () => {
	const { miscSellerFees } = useCurrentDealStore(
		(state) => ({
			miscSellerFees: state.miscSellerFees,
		}),
		shallow
	);
	const { taxRate } = useCurrentSaleStore(
		(state) => ({
			taxRate: state.taxRate,
		}),
		shallow
	);
	const Currency = ({ value }) => {
		return (
			<NumberFormat
				value={value}
				thousandSeparator={true}
				decimalScale={2}
				fixedDecimalScale={true}
				prefix={"$"}
				isNumericString={true}
				displayType="text"
			/>
		);
	};

	const Percent = ({ value }) => {
		return (
			<NumberFormat
				value={value * 100}
				decimalScale={2}
				fixedDecimalScale={true}
				suffix={"%"}
				isNumericString={true}
				displayType="text"
			/>
		);
	};

	return (
		miscSellerFees.length > 0 && (
			<Card>
				<h5 className="p-2">Seller Fees</h5>
				<CardTitle className="px-4">
					{miscSellerFees.length < 1 &&
						"There is no additional Seller fee in this deal."}
				</CardTitle>
				<CardBody>
					{miscSellerFees.map((element) => (
						<>
							<Row>
								<Col>
									<Label>
										<Label>{element.type}</Label>
									</Label>
								</Col>
								<Col>
									<CardText className="px-5">
										<Input type="checkbox" checked={element.isTaxable} />
									</CardText>
								</Col>
								<Col>
									<CardText>
										<Currency value={element.amount} />
									</CardText>
								</Col>
								<Col>
									<CardText>
										<Percent value={element.isTaxable ? taxRate : 0} />
									</CardText>
								</Col>
								<Col>
									<CardText>
										<Currency
											value={element.isTaxable ? element.amount * taxRate : 0}
										/>
									</CardText>
								</Col>
							</Row>

							<hr />
						</>
					))}
				</CardBody>
			</Card>
		)
	);
};

export default SellerFeeTax;
