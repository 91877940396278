import React, { useState } from "react";
import { Button } from "reactstrap";

import tabOnEnter from "../../../helperFunction/tabOnEnter";
import useCurrentDealStore from "../../../store/DealStore";
import useBuildDeal from "../../../helperFunction/deal/useBuildDeal";
import CenteredLoadingSpinner from "../load/CenteredLoadingSpinner";
import LenderWrapper from "./LenderWrapper";
import UACDeal from "./uac/UACDeal";
import UACApplicant from "./uac/UACApplicant";
import UACApplicantAddress from "./uac/UACApplicantAddress";
import UACVehicle from "./uac/UACVehicle";
import UACApplicantIncomes from "./uac/UACApplicantIncomes";
import UACTrades from "./uac/UACTrades";

import { failAlert, saveSuccessAlert } from "../modal/SweetAlerts";
import { saveUACDealInfo } from "../../../api/DealAPI";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";
import { useFromUser } from "../../../store/LocalStorageHelper";

import { lazy, mixed, object, setLocale, string } from "yup";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import shallow from "zustand/shallow";
/**
 * Lender form used for submitting loan info to lenders
 *
 * Currently based off of UAC validation, this component saves all necessary
 * deal information while the backend handles compiling the actual request
 * and submitting the actual application
 *
 * @param toggleApplicationModal function that toggles the modal
 * @param setIsAppSubmitted function for changing app submission boolean
 * @param submitToLender api function, currently either UAC or 700Credit
 * @param los path variable for submitting to lender platform with 700Credit
 * @returns
 */
const GenericLenderForm = ({
	toggleApplicationModal,
	setIsAppSubmitted,
	submitToLender,
	los = "",
}) => {
	// Get deal
	const getDeal = useBuildDeal();

	// Dealer info from local storage
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	// Deal store
	const {
		dealID,
		buyer,
		buyerAddress,
		buyerEmployments,
		buyerRecNum,
		coBuyer,
		coBuyerRecNum,
		coBuyerAddress,
		coBuyerEmployments,
		vehicle,
		trades,
		editBuyer,
		editBuyerAddress,
		editBuyerEmployments,
		editCoBuyer,
		editCoBuyerAddress,
		editCoBuyerEmployments,
		editVehicle,
		editTrades,
		calculate,
	} = useCurrentDealStore(
		(state) => ({
			dealID: state.deal.ID,
			buyer: state.buyer,
			buyerAddress: state.buyerAddress,
			buyerEmployments: state.buyerEmploymentHistory,
			buyerRecNum: state.deal.buyerRecNum,
			coBuyerRecNum: state.deal.coBuyerRecNum,
			coBuyer: state.coBuyer,
			coBuyerAddress: state.coBuyerAddress,
			coBuyerEmployments: state.coBuyerEmploymentHistory,
			vehicle: state.vehicle,
			trades: state.trades,
			editBuyer: state.editBuyer,
			editBuyerAddress: state.editBuyerAddress,
			editBuyerEmployments: state.editBuyerEmploymentHistory,
			editCoBuyer: state.editCoBuyer,
			editCoBuyerAddress: state.editCoBuyerAddress,
			editCoBuyerEmployments: state.editCoBuyerEmploymentHistory,
			editVehicle: state.editVehicle,
			editTrades: state.editTrades,
			calculate: state.calculate,
		}),
		shallow
	);

	const employmentFields = {
		dealerID,
		locationID,
		salaryType: "",
		employmentStatus: "",
		salary: "",
		company: "",
		position: "",
		years: "",
		months: "",
		phone: "",
		street: "",
		city: "",
		state: "",
		zip: "",
	};

	// Progress spinner state
	const [showSubmitProgress, setShowSubmitProgress] = useState(false);

	// Buyer fields
	const [buyerFirstName, setBuyerFirstName] = useState(buyer?.firstName);
	const [buyerLastName, setBuyerLastName] = useState(buyer?.lastName);
	const [buyerSsn, setBuyerSsn] = useState(buyer?.SSN);
	const [buyerDob, setBuyerDob] = useState(buyer?.birthDate);
	const [buyerLicense, setBuyerLicense] = useState(buyer?.license);
	const [buyerLicState, setBuyerLicState] = useState(buyer?.licState);
	const [buyerLicExpire, setBuyerLicExpire] = useState(buyer?.licExpires);
	const [buyerLicType, setBuyerLicType] = useState(buyer?.licType);
	const [buyerLicCountry, setBuyerLicCountry] = useState(buyer?.licCountry);
	const [buyerHomePhone, setBuyerHomePhone] = useState(buyer?.homePhone);
	const [buyerCellPhone, setBuyerCellPhone] = useState(buyer?.cellPhone);
	const [buyerStreet, setBuyerStreet] = useState(buyerAddress?.street);
	const [buyerUnit, setBuyerUnit] = useState(buyerAddress?.unit);
	const [buyerCity, setBuyerCity] = useState(buyerAddress?.city);
	const [buyerState, setBuyerState] = useState(buyerAddress?.state);
	const [buyerZip, setBuyerZip] = useState(buyerAddress?.zip);
	const [buyerHousingStatus, setBuyerHousingStatus] = useState(
		buyerAddress?.ownership
	);
	const [buyerMonthsAtAddress, setBuyerMonthsAtAddress] = useState(
		buyerAddress?.months
	);
	const [buyerYearsAtAddress, setBuyerYearsAtAddress] = useState(
		buyerAddress?.years
	);
	const [buyerHousingPayment, setBuyerHousingPayment] = useState(
		buyerAddress?.monthlyPayment
	);
	const [buyerEmploymentsList, setBuyerEmploymentsList] = useState(
		buyerEmployments?.length > 0
			? [...buyerEmployments]
			: [{ ...employmentFields, custRecNum: buyerRecNum }]
	);

	// Co-buyer Fields
	const [coBuyerFirstName, setCoBuyerFirstName] = useState(coBuyer?.firstName);
	const [coBuyerLastName, setCoBuyerLastName] = useState(coBuyer?.lastName);
	const [coBuyerSsn, setCoBuyerSsn] = useState(coBuyer?.SSN);
	const [coBuyerDob, setCoBuyerDob] = useState(coBuyer?.birthDate);
	const [coBuyerLicense, setCoBuyerLicense] = useState(coBuyer?.license);
	const [coBuyerLicState, setCoBuyerLicState] = useState(coBuyer?.licState);
	const [coBuyerLicType, setCoBuyerLicType] = useState(coBuyer?.licType);
	const [coBuyerLicCountry, setCoBuyerLicCountry] = useState(
		coBuyer?.licCountry
	);
	const [coBuyerLicExpire, setCoBuyerLicExpire] = useState(coBuyer?.licExpires);
	const [coBuyerHomePhone, setCoBuyerHomePhone] = useState(coBuyer?.homePhone);
	const [coBuyerCellPhone, setCoBuyerCellPhone] = useState(coBuyer?.cellPhone);
	const [coBuyerStreet, setCoBuyerStreet] = useState(coBuyerAddress?.street);
	const [coBuyerUnit, setCoBuyerUnit] = useState(coBuyerAddress?.unit);
	const [coBuyerCity, setCoBuyerCity] = useState(coBuyerAddress?.city);
	const [coBuyerState, setCoBuyerState] = useState(coBuyerAddress?.state);
	const [coBuyerZip, setCoBuyerZip] = useState(coBuyerAddress?.zip);
	const [coBuyerHousingStatus, setCoBuyerHousingStatus] = useState(
		coBuyerAddress?.ownership
	);
	const [coBuyerMonthsAtAddress, setCoBuyerMonthsAtAddress] = useState(
		coBuyerAddress?.months
	);
	const [coBuyerYearsAtAddress, setCoBuyerYearsAtAddress] = useState(
		coBuyerAddress?.years
	);
	const [coBuyerHousingPayment, setCoBuyerHousingPayment] = useState(
		coBuyerAddress?.monthlyPayment
	);
	const [coBuyerEmploymentsList, setCoBuyerEmploymentsList] = useState(
		coBuyerEmployments?.length > 0
			? [...coBuyerEmployments]
			: [{ ...employmentFields, custRecNum: coBuyerRecNum }]
	);

	// Vehicle fields
	const [vin, setVin] = useState(vehicle?.vin);
	const [odometerIn, setOdometerIn] = useState(vehicle?.odometerIn);
	const [year, setYear] = useState(vehicle?.year);
	const [make, setMake] = useState(vehicle?.make);
	const [model, setModel] = useState(vehicle?.model);
	const [trim, setTrim] = useState(vehicle?.trim);
	const [newUsed, setNewUsed] = useState(vehicle?.newUsed);
	const [tradesList, setTradesList] = useState(
		trades?.length > 0
			? trades.map((obj) => {
					return obj.car;
			  })
			: []
	);

	// Fields wrapper
	const buyerInfoFields = {
		firstName: buyerFirstName,
		lastName: buyerLastName,
		SSN: buyerSsn,
		birthDate: buyerDob,
		license: buyerLicense,
		licState: buyerLicState,
		licExpires: buyerLicExpire,
		licCountry: buyerLicCountry,
		licType: buyerLicType,
		homePhone: buyerHomePhone,
		cellPhone: buyerCellPhone,
	};

	const buyerAddressFields = {
		street: buyerStreet,
		unit: buyerUnit,
		city: buyerCity,
		state: buyerState,
		zip: buyerZip,
		ownership: buyerHousingStatus,
		monthlyPayment: buyerHousingPayment,
		months: buyerMonthsAtAddress,
		years: buyerYearsAtAddress,
		isCurrent: true,
		dealerID,
		locationID,
	};

	const coBuyerInfoFields = {
		firstName: coBuyerFirstName,
		lastName: coBuyerLastName,
		SSN: coBuyerSsn,
		birthDate: coBuyerDob,
		license: coBuyerLicense,
		licState: coBuyerLicState,
		licCountry: coBuyerLicCountry,
		licType: coBuyerLicType,
		licExpires: coBuyerLicExpire,
		homePhone: coBuyerHomePhone,
		cellPhone: coBuyerCellPhone,
	};

	const coBuyerAddressFields = {
		street: coBuyerStreet,
		unit: coBuyerUnit,
		city: coBuyerCity,
		state: coBuyerState,
		zip: coBuyerZip,
		ownership: coBuyerHousingStatus,
		monthlyPayment: coBuyerHousingPayment,
		months: coBuyerMonthsAtAddress,
		years: coBuyerYearsAtAddress,
		isCurrent: true,
		dealerID,
		locationID,
	};

	// Vehicle object fields
	const vehicleFields = {
		vin,
		odometerIn,
		year,
		make,
		model,
		trim,
		newUsed,
		dealerID,
		locationID,
	};

	// Setters wrapper
	const buyerInfoSetters = {
		setFirstName: setBuyerFirstName,
		setLastName: setBuyerLastName,
		setSsn: setBuyerSsn,
		setDob: setBuyerDob,
		setHomePhone: setBuyerHomePhone,
		setCellPhone: setBuyerCellPhone,
		setLicense: setBuyerLicense,
		setLicenseState: setBuyerLicState,
		setLicenseExpire: setBuyerLicExpire,
		setLicenseCountry: setBuyerLicCountry,
		setLicenseType: setBuyerLicType,
	};

	const buyerAddressSetters = {
		setStreet: setBuyerStreet,
		setUnit: setBuyerUnit,
		setCity: setBuyerCity,
		setState: setBuyerState,
		setZip: setBuyerZip,
		setHousingStatus: setBuyerHousingStatus,
		setHousingPayment: setBuyerHousingPayment,
		setMonthsAtAddress: setBuyerMonthsAtAddress,
		setYearsAtAddress: setBuyerYearsAtAddress,
	};

	const coBuyerInfoSetters = {
		setFirstName: setCoBuyerFirstName,
		setLastName: setCoBuyerLastName,
		setSsn: setCoBuyerSsn,
		setDob: setCoBuyerDob,
		setHomePhone: setCoBuyerHomePhone,
		setCellPhone: setCoBuyerCellPhone,
		setLicense: setCoBuyerLicense,
		setLicenseState: setCoBuyerLicState,
		setLicenseExpire: setCoBuyerLicExpire,
		setLicenseCountry: setCoBuyerLicCountry,
		setLicenseType: setCoBuyerLicType,
	};

	const coBuyerAddressSetters = {
		setStreet: setCoBuyerStreet,
		setUnit: setCoBuyerUnit,
		setCity: setCoBuyerCity,
		setState: setCoBuyerState,
		setZip: setCoBuyerZip,
		setHousingStatus: setCoBuyerHousingStatus,
		setHousingPayment: setCoBuyerHousingPayment,
		setMonthsAtAddress: setCoBuyerMonthsAtAddress,
		setYearsAtAddress: setCoBuyerYearsAtAddress,
	};

	const vehicleFieldSetters = {
		setVin,
		setOdometerIn,
		setYear,
		setMake,
		setModel,
		setTrim,
		setNewUsed,
	};

	// Adds the input to the employment history object for buyer
	const handleBuyerIncomeInputs = (name, value, index) => {
		const employmentsCopy = [...buyerEmploymentsList];
		const sliceIndex = name.indexOf("_");
		const extractedName = name.slice(0, sliceIndex);

		employmentsCopy[index][extractedName] = value;
		setBuyerEmploymentsList(employmentsCopy);
	};

	// Adds the input to the employment history object for coBuyer
	const handleCoBuyerIncomeInputs = (name, value, index) => {
		const employmentsCopy = [...coBuyerEmploymentsList];
		const sliceIndex = name.indexOf("_");
		const extractedName = name.slice(0, sliceIndex);

		employmentsCopy[index][extractedName] = value;
		setCoBuyerEmploymentsList(employmentsCopy);
	};

	// Adds the input to the trades object
	const handleTradeInputs = (name, value, index) => {
		const tradesCopy = [...tradesList];
		const sliceIndex = name.indexOf("_");
		const extractedName = name.slice(0, sliceIndex);

		tradesCopy[index][extractedName] = value;
		setTradesList(tradesCopy);
	};

	// Updates each employment object in the array with the updated
	// objects from the updated list
	const updateEmploymentInfo = (currentEmployments, updatedEmployments) => {
		const updatedList = [];

		for (let i = 0; i < updatedEmployments.length; i++) {
			updatedList.push({
				...currentEmployments[i],
				...updatedEmployments[i],
			});
		}

		return updatedList;
	};

	// Updates each trade object in the array with the updated
	// objects from the updated list
	const updateTrades = (currentTrades, updatedTrades) => {
		const updatedList = [...currentTrades];

		for (let i = 0; i < currentTrades.length; i++) {
			updatedList[i].car = { ...updatedList[i].car, ...updatedTrades[i] };
		}

		return updatedList;
	};

	// Get all the new/updated info and put it in appropriate objects and update store
	const submitApp = () => {
		let updatedVehicle = { ...vehicle, ...vehicleFields };
		let updatedTrades = updateTrades(trades, tradesList);
		let updatedBuyer = { ...buyer, ...buyerInfoFields };
		let updatedBuyerAddress = { ...buyerAddress, ...buyerAddressFields };
		let updatedCoBuyer = {
			...coBuyer,
			...coBuyerInfoFields,
		};
		let updatedCoBuyerAddress = {
			...coBuyerAddress,
			...coBuyerAddressFields,
		};
		let updatedBuyerEmployment = updateEmploymentInfo(
			buyerEmployments,
			buyerEmploymentsList
		);
		let updatedCoBuyerEmployment = updateEmploymentInfo(
			coBuyerEmployments,
			coBuyerEmploymentsList
		);

		const dealObj = getDeal();

		if (coBuyerRecNum == null) {
			saveAllDealInfo(
				dealObj,
				updatedVehicle,
				updatedTrades,
				updatedBuyer,
				updatedBuyerAddress,
				coBuyer,
				coBuyerAddress,
				updatedBuyerEmployment,
				coBuyerEmployments
			);

			editCoBuyer(updatedCoBuyer);
			editCoBuyerEmployments(updatedCoBuyerEmployment);
			editCoBuyerAddress(updatedCoBuyerAddress);
			editBuyer(updatedBuyer);
			editBuyerEmployments(updatedBuyerEmployment);
			editBuyerAddress(updatedBuyerAddress);
			editVehicle(updatedVehicle);
			editTrades(updatedTrades);
		} else {
			saveAllDealInfo(
				dealObj,
				updatedVehicle,
				updatedTrades,
				updatedBuyer,
				updatedBuyerAddress,
				updatedCoBuyer,
				updatedCoBuyerAddress,
				updatedBuyerEmployment,
				updatedCoBuyerEmployment
			);

			editBuyer(updatedBuyer);
			editBuyerEmployments(updatedBuyerEmployment);
			editBuyerAddress(updatedBuyerAddress);
			editVehicle(updatedVehicle);
			editTrades(updatedTrades);
			calculate();
		}
	};

	// Modify the dealObj with the current info and save it all
	const saveAllDealInfo = async (
		deal,
		updateVehicle,
		updateTrades,
		updateBuyer,
		updateBuyerAddress,
		updateCoBuyer,
		updateCoBuyerAddress,
		updateBuyerEmployment,
		updateCoBuyerEmployment
	) => {
		let data = {
			...deal,
			vehicle: { ...updateVehicle },
			trades: [...updateTrades],
			buyer: { ...updateBuyer },
			buyerAddress: { ...updateBuyerAddress },
			buyerEmploymentHistory: [...updateBuyerEmployment],
			coBuyer: updateCoBuyer == null ? null : { ...updateCoBuyer },
			coBuyerAddress:
				updateCoBuyerAddress == null ? null : { ...updateCoBuyerAddress },
			coBuyerEmploymentHistory: [...updateCoBuyerEmployment],
		};

		try {
			setShowSubmitProgress(true);

			let saveUACDealInfoResp = await saveUACDealInfo(data);

			if (saveUACDealInfoResp.status === 200) {
				submitToLender(dealID, los).then(
					(res) => {
						console.log(res);
						saveSuccessAlert("Successfully Submitted UAC Application");
						toggleApplicationModal();
						setShowSubmitProgress(false);
						setIsAppSubmitted(true);
					},
					(err) => {
						setShowSubmitProgress(false);

						if (!err.isGeneralError) {
							showApiError(err, failAlert);
						}
					}
				);
			} else {
				setShowSubmitProgress(false);
				failAlert(
					"Failed to save UAC info. Error: " + saveUACDealInfoResp.status
				);
			}
		} catch (err) {
			setShowSubmitProgress(false);

			if (!err.isGeneralError) {
				showApiError(err, failAlert);
			}
		}
	};

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
		},
	});

	// Validation for each input field
	const getSchemaObj = (obj) => {
		let schemaObj = {};

		Object.keys(obj).map((key) => {
			if (key.includes("ssn")) {
				return (schemaObj = {
					...schemaObj,
					[key]: string().matches(/^[0-9]{3}-[0-9]{2}-[0-9]{4}/, {
						message: "Must be 9 digits",
					}),
				});
			} else if (
				key.includes("buyer") ||
				key.includes("coBuyer") ||
				key.includes("trade") ||
				key.includes("vehicle")
			) {
				if (!key.includes("unit"))
					return (schemaObj = {
						...schemaObj,
						[key]: string().required().max(100),
					});
			}
			return (schemaObj = { ...schemaObj, [key]: mixed() });
		});
		return schemaObj;
	};

	// Define the fields to put validations on
	const schema = lazy((obj) => object(getSchemaObj(obj)));

	// Define form validation parameters for address history
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<FormProvider {...methods}>
			{(showSubmitProgress && <CenteredLoadingSpinner />) || (
				<div onKeyDown={tabOnEnter}>
					<UACDeal />
					<UACApplicant
						prefix="buyer"
						cardTitle="Buyer Information"
						formId="buyerInfo"
						fields={buyerInfoFields}
						setters={buyerInfoSetters}
					/>
					<UACApplicantAddress
						prefix="buyer"
						cardTitle="Buyer Address"
						formId="buyerAddress"
						fields={buyerAddressFields}
						setters={buyerAddressSetters}
					/>
					{buyerEmploymentsList.map((obj, i) => (
						<LenderWrapper
							key={i}
							cardTitle="Buyer Employment"
							cardId="buyerEmployment"
						>
							<div className="col col-12">
								<UACApplicantIncomes
									prefix="buyer"
									employment={obj}
									index={i}
									onInputChange={handleBuyerIncomeInputs}
								/>
							</div>
						</LenderWrapper>
					))}
					{coBuyerRecNum != null && (
						<div>
							<UACApplicant
								prefix="coBuyer"
								cardTitle="Co-Buyer Information"
								formId="coBuyerInfo"
								fields={coBuyerInfoFields}
								setters={coBuyerInfoSetters}
							/>
							<UACApplicantAddress
								prefix="coBuyer"
								cardTitle="Co-Buyer Address"
								formId="coBuyerAddress"
								fields={coBuyerAddressFields}
								setters={coBuyerAddressSetters}
							/>
							{coBuyerEmploymentsList.map((obj, i) => (
								<LenderWrapper
									key={i}
									cardTitle="Co-Buyer Employment"
									cardId="coBuyerEmployment"
								>
									<div className="col col-12" key={i}>
										<UACApplicantIncomes
											prefix="coBuyer"
											employment={obj}
											index={i}
											onInputChange={handleCoBuyerIncomeInputs}
										/>
									</div>
								</LenderWrapper>
							))}
						</div>
					)}
					<UACVehicle
						cardId="vehicle"
						cardTitle="Vehicle Information"
						fields={vehicleFields}
						setters={vehicleFieldSetters}
					/>
					{trades.length > 0 && (
						<LenderWrapper cardTitle="Trades" cardId="trade">
							{tradesList.map((obj, i) => (
								<div className="col col-12" key={i}>
									<UACTrades
										trade={obj}
										index={i}
										onInputChange={handleTradeInputs}
									/>
								</div>
							))}
						</LenderWrapper>
					)}
					<div className="d-flex justify-content-center mt-2">
						<Button onClick={methods.handleSubmit(submitApp)}>
							Submit Application
						</Button>
					</div>
				</div>
			)}
		</FormProvider>
	);
};

export default GenericLenderForm;
