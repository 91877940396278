import React, { useEffect, useState } from "react";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Form,
	Row,
} from "reactstrap";

import digitzsPaymentValidation from "./DigitzsPaymentValidation";
import history from "../../../../helperFunction/History";
import tabOnEnter from "../../../../helperFunction/tabOnEnter";
import useCurrentDealStore from "../../../../store/DealStore";
import CenteredLoadingSpinner from "../../load/CenteredLoadingSpinner";
import ChildrenModal from "../../modal/ChildrenModal";
import DigitzsPaymentsTable from "./DigitzsPaymentsTable";
import FormattedMaskInput from "../../input/FormattedMaskInput";
import InputElement from "../../../../view/formValidation/InputElement";
import ZipCityTable from "../../address/ZipCityTable";

import {
	failAlert,
	successAlertCustom,
	twoOptionsAlert,
} from "../../modal/SweetAlerts";
import {
	getDigitzsMerchant,
	getDigitzsPayments,
	processDigitzsTransaction,
} from "../../../../api/PaymentsAPI";
import { useFromUser } from "../../../../store/LocalStorageHelper";
import {
	stateOptions,
	CountryCodes,
	CurrencyCodes,
	PATHS,
} from "../../../../Constants";
import {
	CurrencyInputElement,
	IntegerInputElement,
	SelectorElement,
	ZipInputElement,
} from "../../../../view/formValidation/InputElement";
import { TIME_FORMAT } from "../../note/NoteConstants";

import dayjs from "dayjs";

import { yupResolver } from "@hookform/resolvers";
import { FormProvider, useForm } from "react-hook-form";
import shallow from "zustand/shallow";

const DigitzsPayment = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const today = dayjs().format("YYYY.MM.DDTHH:mm:ss");
	const settingsPage = PATHS.SETTINGS;

	// Deal store
	const { customerID } = useCurrentDealStore(
		(state) => ({
			customerID: state.buyerRecNum,
		}),
		shallow
	);

	const [cityModal, setCityModal] = useState(false);
	const [addressData, setAddressData] = useState({});
	const [hasDigitzsAccount, setHasDigitzsAccount] = useState(false);
	const [showPaymentProgress, setShowPaymentProgress] = useState(false);
	const [merchantId, setMerchantId] = useState("");
	const [payments, setPayments] = useState([]);
	const [miscData, setMiscData] = useState("");
	const [cardHolder, setCardHolder] = useState("");
	const [cardNumber, setCardNumber] = useState("");
	const [cardExpires, setCardExpires] = useState("");
	const [cardCode, setCardCode] = useState("");
	const [amount, setAmount] = useState(1);
	const [currency, setCurrency] = useState("USD");
	const [billingStreet, setBillingStreet] = useState("");
	const [billingUnit, setBillingUnit] = useState("");
	const [billingCity, setBillingCity] = useState("");
	const [billingState, setBillingState] = useState("");
	const [billingZip, setBillingZip] = useState("");
	const [billingCountry, setBillingCountry] = useState("USA");

	// Toggles the modal
	const toggleCityModal = () => setCityModal(!cityModal);

	const handleZipChange = (zip) => {
		setAddressData({
			zip,
			address: {
				city: billingCity,
				state: billingState,
			},
		});
		toggleCityModal();
	};

	const setAddressFields = (address) => {
		setBillingCity(address.city);
		setBillingState(address.state);
	};

	// Formats the date we display so that we can sort it
	const formatTransactionDate = (arr) => {
		return arr.map((obj) => {
			return {
				...obj,
				dmsTimestamp: dayjs(obj.dmsTimestamp).format(TIME_FORMAT),
			};
		});
	};

	// Asks if user wants to sign up for Digitzs
	const askToSignUp = async () => {
		const didConfirm = await twoOptionsAlert(
			"No Digitzs account found. Do you want to sign up for Digitzs?",
			"Yes",
			"No"
		);

		if (didConfirm === "Yes") {
			history.push({
				pathname: settingsPage,
				state: {
					tab: "signUp",
				},
			});
		}
	};

	// Checks that amount is at least 1, per digitzs requirement
	const isAmountValid = () => {
		if (amount < 1) {
			methods.setError("digitzsAmount", {
				message: "Must be at least 1",
				shouldFocus: true,
			});

			return false;
		}

		return true;
	};

	// Calls api to process payment if all data is valid
	const processPayment = () => {
		if (!isAmountValid()) {
			return;
		}

		if (hasDigitzsAccount) {
			setShowPaymentProgress(true);

			const paymentData = {
				dealerID,
				locationID,
				customerID,
				paymentRequest: {
					miscData:
						miscData == null || miscData.trim().length === 0 ? null : miscData,
					paymentType: "card",
					merchantId,
					card: {
						holder: cardHolder,
						number: cardNumber,
						expiry: cardExpires,
						code: cardCode,
					},
					transaction: {
						amount,
						currency,
						invoice: `${dealerID}-${locationID}-${today}-DC2`,
					},
					billingAddress: {
						line1: billingStreet,
						line2: billingUnit.trim().length === 0 ? null : billingUnit,
						city: billingCity,
						state: billingState,
						zip: billingZip,
						country: billingCountry,
					},
				},
			};

			processDigitzsTransaction(paymentData).then(
				(res) => {
					setShowPaymentProgress(false);
					successAlertCustom(res.data.message);
					setPayments([
						{
							...res.data.content,
							dmsTimestamp: dayjs(res.data.content.dmsTimestamp).format(TIME_FORMAT), // prettier-ignore
						},
						...payments,
					]);
				},
				(err) => {
					setShowPaymentProgress(false);
					failAlert(
						err?.response?.data?.message || "Failed to process payment"
					);
				}
			);
		} else {
			askToSignUp();
		}
	};

	// Dealer's can only process payment for a customer if they are signed up for Digitzs
	useEffect(() => {
		getDigitzsMerchant(dealerID, locationID).then((res) => {
			if (res.data.content !== null && res.data.content.merchantId != null) {
				setMerchantId(res.data.content.merchantId);
				setHasDigitzsAccount(true);
			}
		});
		// eslint-disable-next-line
	}, []);

	// Shows the customer's payment history table
	useEffect(() => {
		if (customerID != null) {
			getDigitzsPayments(customerID).then(
				(res) => setPayments(formatTransactionDate(res.data.content.payments)),
				(err) => console.log(err)
			);
		}
		// eslint-disable-next-line
	}, []);

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(digitzsPaymentValidation),
		shouldFocusError: true,
	});

	return (
		<div className="col-lg-12 mx-auto">
			<FormProvider {...methods}>
				<Form onKeyDown={tabOnEnter}>
					<Card className="pt-2">
						<CardHeader>
							<CardTitle>
								<h3>Payment Terminal</h3>
							</CardTitle>
						</CardHeader>
						{(showPaymentProgress && <CenteredLoadingSpinner />) || (
							<div>
								<CardBody className="mx-1">
									<Row>
										<CurrencyInputElement
											{...methods}
											value={amount}
											onChange={setAmount}
											name="digitzsAmount"
											placeholder="Enter amount to charge"
											label="Amount"
										/>
										<SelectorElement
											{...methods}
											colSize="col-lg-3"
											name="digitzsCurrency"
											label="Currency"
											value={currency}
											onChange={(e) => setCurrency(e.target.value)}
											options={CurrencyCodes}
										/>
									</Row>
									<Row>
										<InputElement
											{...methods}
											colSize="col-lg-3"
											value={cardHolder}
											onChange={(e) => setCardHolder(e.target.value)}
											name="digitzsCardHolder"
											label="Name"
											placeholder=""
											type="text"
										/>
										<FormattedMaskInput
											{...methods}
											colSize="col-lg-4"
											format="#### #### #### #######"
											value={cardNumber}
											onChange={setCardNumber}
											name="digitzsCardNumber"
											label="Card Number"
										/>
										<FormattedMaskInput
											{...methods}
											colSize="col-md-2"
											format="##/##"
											placeholder="MM/YY"
											mask={["M", "M", "Y", "Y"]}
											value={cardExpires}
											onChange={setCardExpires}
											name="digitzsCardExpires"
											label="Exp."
										/>
										<IntegerInputElement
											{...methods}
											colSize="col-lg-2"
											value={cardCode}
											thousandSeparator={false}
											onChange={setCardCode}
											name="digitzsCardCode"
											label="CVV"
											placeholder="Security Code"
										/>
									</Row>
								</CardBody>
								<CardBody className="row mx-1 mt-3">
									<CardTitle tag="h3" className="col-12 mb-3">
										Billing Address
									</CardTitle>
									<InputElement
										{...methods}
										colSize="col-lg-3"
										value={billingStreet}
										onChange={(e) => setBillingStreet(e.target.value)}
										name="digitzsBillingStreet"
										label="Street"
										type="text"
									/>
									<InputElement
										{...methods}
										colSize="col-lg-2"
										value={billingUnit}
										onChange={(e) => setBillingUnit(e.target.value)}
										name="digitzsBillingUnit"
										label="Apt/Unit/Bld"
										type="text"
									/>
									<InputElement
										{...methods}
										value={billingCity}
										onChange={(e) => setBillingCity(e.target.value)}
										name="digitzsBillingCity"
										label="City"
										type="text"
									/>
									<SelectorElement
										{...methods}
										colSize="col-2"
										name="digitzsBillingState"
										label="State"
										value={billingState}
										onChange={(e) => setBillingState(e.target.value)}
										options={stateOptions}
									/>
									<ZipInputElement
										{...methods}
										colSize="col-lg-2"
										value={billingZip}
										onChange={setBillingZip}
										openZipModal={handleZipChange}
										name="digitzsBillingZip"
										label="Zip"
									/>
									<SelectorElement
										{...methods}
										colSize="col-lg-3"
										name="digitzsBillingCountry"
										label="Country"
										value={billingCountry}
										onChange={(e) => setBillingCountry(e.target.value)}
										options={CountryCodes}
									/>
									<InputElement
										{...methods}
										colSize="col-lg-12"
										value={miscData}
										name="digitzsNotes"
										label="Additional Info"
										placeholder="Enter any additional information"
										type="textarea"
										onChange={(e) => setMiscData(e.target.value)}
									/>
									<div className="col-12 d-flex justify-content-center my-3">
										<Button
											readOnly={customerID != null ? false : true}
											color="primary"
											onClick={methods.handleSubmit(processPayment)}
										>
											Process Payment
										</Button>
									</div>
									<ChildrenModal
										modal={cityModal}
										toggle={toggleCityModal}
										modalTitle="Cities"
									>
										<ZipCityTable
											data={addressData}
											toggle={toggleCityModal}
											address={{
												zip: billingZip,
												city: billingCity,
												state: billingState,
											}}
											setAddress={setAddressFields}
										/>
									</ChildrenModal>
								</CardBody>
							</div>
						)}
					</Card>
					{payments.length > 0 && (
						<Card className="mx-1 mt-3">
							<CardTitle
								tag="h3"
								className="d-flex justify-content-center col-12 my-3 mx-3"
							>
								Customer's Payments
							</CardTitle>
							<CardBody className="row-12 mx-1">
								<DigitzsPaymentsTable
									payments={payments}
									editPayments={setPayments}
								/>
							</CardBody>
						</Card>
					)}
				</Form>
			</FormProvider>
		</div>
	);
};

export default DigitzsPayment;
