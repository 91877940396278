import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col } from "reactstrap";

import useCurrentDealStore from "store/DealStore";
import LenderModal from "../LenderModal";
import GenericLenderForm from "../GenericLenderForm";

import shallow from "zustand/shallow";
import { submitToLenders } from "../../../../api/dealerIntegrationsApi";
import { credit700Lenders } from "../../../../Constants";
import swal from "sweetalert2";
import credit700Logo from "assets/img/700CreditLogo2.png";

const Credit700 = ({ los, logo, has700 }) => {
	// Modal toggles
	const [toggleApplication, setToggleApplication] = useState(false);
	// Local states
	const [isAppSubmitted, setIsAppSubmitted] = useState(false);
	const [lenderPlatform, setLenderPlatform] = useState("");
	// Deal store
	const { lenders } = useCurrentDealStore(
		(state) => ({
			lenders: state.credit700Lenders,
		}),
		shallow
	);

	// Toggles the modal to open or close
	const toggleApplicationModal = () => setToggleApplication(!toggleApplication);

	useEffect(() => {
		switch (los) {
			case credit700Lenders.appOne:
				setLenderPlatform("AppOne");
				setIsAppSubmitted(lenders?.appOneSubmissions?.length > 0);
				break;
			case credit700Lenders.cudl:
				setLenderPlatform("CUDL");
				setIsAppSubmitted(lenders?.cudlSubmissions?.length > 0);
				break;
			case credit700Lenders.routeOne:
				setLenderPlatform("RouteOne");
				setIsAppSubmitted(lenders?.routeOneSubmissions?.length > 0);
				break;
			case credit700Lenders.dealerTrack:
				setLenderPlatform("DealerTrack");
				setIsAppSubmitted(lenders?.dealerTrackSubmissions?.length > 0);
				break;
			default:
				break;
		}
		// eslint-disable-next-line
	}, []);

	// Buttons that opens the application modal
	const applicationButton = (resubmit) => (
		<Button
			size="md"
			className="w-100 btn"
			onClick={() => {
				if (has700) {
					toggleApplicationModal();
				} else {
					swal.fire({
						text: "Sign up for 700Credit?",
						showCancelButton: true,
						showCloseButton: true,
						imageUrl: credit700Logo,
						confirmButtonText:
							'<a href="https://700credit.com/dealerclick" target="_blank" rel="noopener noreferrer">Sign up</a>',
					});
				}
			}}
		>
			{has700 ? (resubmit === true ? "Resubmit" : "Send App") : "Sign Up"}
		</Button>
	);

	return (
		<Card>
			<CardBody>
				<img src={logo} alt="UAC Logo" className="lenderImg" />
				<Col>
					<LenderModal
						ButtonComponent={() => applicationButton(isAppSubmitted)}
						modalSize="xl"
						modalTitle={lenderPlatform + " Application"}
						toggle={toggleApplication}
						toggleModal={toggleApplicationModal}
					>
						<GenericLenderForm
							toggleApplicationModal={toggleApplicationModal}
							setIsAppSubmitted={setIsAppSubmitted}
							submitToLender={submitToLenders}
							los={los}
						/>
					</LenderModal>
				</Col>
			</CardBody>
		</Card>
	);
};

export default Credit700;
