import React, { useState } from "react";
import { Form, Button } from "reactstrap";

import tabOnEnter from "../../../../helperFunction/tabOnEnter";
import useCurrentCustomerStore from "../../../../store/CustomerStore";
import ChildrenModal from "../../../../component/dc/modal/ChildrenModal";
import InputWrapper from "../../../formValidation/InputWrapper";
import ZipCityTable from "../../../../component/dc/address/ZipCityTable";

import { saveInsurance } from "../../../../api/CustomerAPI";
import { stateOptions } from "../../../../Constants";
import { useFromUser } from "../../../../store/LocalStorageHelper";
import {
	saveSuccessAlert,
	saveFailAlert,
} from "../../../../component/dc/modal/SweetAlerts";
import InputElement, {
	CurrencyInputElement,
	PhoneInputElement,
	SelectorElement,
	ZipInputElement,
} from "view/formValidation/InputElement";

import { useFormContext } from "react-hook-form";

import shallow from "zustand/shallow";
import { CheckBoxElement } from "../../../formValidation/InputElement";

const CustomerInsurance = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	// Customer store
	const { ID, insurance } = useCurrentCustomerStore(
		(state) => ({
			ID: state.ID,
			insurance: state.insurance,
		}),
		shallow
	);

	// Local states
	const [cityModal, setCityModal] = useState(false);
	const [addressData, setAddressData] = useState({});
	const [company, setCompany] = useState(insurance?.insCompany);
	const [policyNum, setPolicyNum] = useState(insurance?.policyNum);
	const [startDate, setStartDate] = useState(insurance?.fromDate);
	const [endDate, setEndDate] = useState(insurance?.toDate);
	const [agentName, setAgentName] = useState(insurance?.agentName);
	const [agentCellNum, setAgentCellNum] = useState(insurance?.pager);
	const [agentPhoneNum1, setAgentPhoneNum1] = useState(insurance?.phone1);
	const [agentPhoneExt1, setAgentPhoneExt1] = useState(insurance?.ext1);
	const [agentPhoneNum2, setAgentPhoneNum2] = useState(insurance?.phone2);
	const [agentPhoneExt2, setAgentPhoneExt2] = useState(insurance?.ext2);
	const [agentFaxNum, setAgentFaxNum] = useState(insurance?.fax);
	const [agentAddress, setAgentAddress] = useState(insurance?.street);
	const [agentCity, setAgentCity] = useState(insurance?.city);
	const [state, setState] = useState(insurance?.state);
	const [agentZip, setAgentZip] = useState(insurance?.zip);

	const [fire, setFire] = useState(insurance?.fire);
	const [theft, setTheft] = useState(insurance?.theft);
	const [collision, setCollision] = useState(insurance?.collision);
	const [comprehensive, setComprehensive] = useState(insurance?.comprehensive);
	const [collDeductible, setCollDeductible] = useState(insurance?.deductible);
	const [compDeductible, setCompDeductible] = useState(
		insurance?.compDeductible
	);

	// Toggles the modal
	const toggleCityModal = () => setCityModal(!cityModal);

	const handleZipBlur = (fieldZip) => {
		setAddressData({
			zip: fieldZip,
			address: {
				city: agentCity,
				state,
			},
		});
		toggleCityModal();
	};

	const setAddressFields = (address) => {
		setAgentCity(address.city);
		setState(address.state);
	};

	// Saves the insurance information in the database
	const saveIns = () => {
		const saveObj = {
			...insurance,
			ID: insurance?.ID,
			custRecNum: ID,
			policyNum,
			agentName,
			city: agentCity,
			fromDate: startDate,
			toDate: endDate,
			insCompany: company,
			pager: agentCellNum,
			phone1: agentPhoneNum1,
			ext1: agentPhoneExt1,
			phone2: agentPhoneNum2,
			ext2: agentPhoneExt2,
			fax: agentFaxNum,
			state,
			street: agentAddress,
			zip: agentZip,
			fire: fire ? 1 : 0,
			theft: theft ? 1 : 0,
			collision: collision ? 1 : 0,
			comprehensive: comprehensive ? 1 : 0,
			deductible: collision ? collDeductible : 0,
			compDeductible: comprehensive ? compDeductible : 0,
			dealerID,
			locationID,
		};

		saveInsurance(saveObj).then(
			(response) => {
				saveSuccessAlert();
				console.log(response);
			},
			(err) => {
				saveFailAlert();
				console.log(err);
			}
		);
	};

	// Get validation context
	const methods = useFormContext();

	return (
		<>
			<Form onKeyDown={tabOnEnter}>
				<InputWrapper
					formTitle="Insurance & Agent"
					inputComponents={[
						<InputElement
							{...methods}
							value={company}
							onChange={(e) => {
								setCompany(e.target.value);
							}}
							name="insuranceCompany"
							label="Company"
							type="text"
						/>,
						<InputElement
							{...methods}
							value={policyNum}
							onChange={(e) => {
								setPolicyNum(e.target.value);
							}}
							name="insurancePolicyNum"
							label="Policy Number"
							type="text"
						/>,
						<InputElement
							{...methods}
							value={startDate}
							onChange={(e) => setStartDate(e.target.value)}
							name="insuranceStartDate"
							label="Start Date"
							type="date"
						/>,
						<InputElement
							{...methods}
							value={endDate}
							onChange={(e) => setEndDate(e.target.value)}
							name="insuranceEndDate"
							label="End Date"
							type="date"
						/>,
						<InputElement
							{...methods}
							value={agentName}
							onChange={(e) => setAgentName(e.target.value)}
							name="agentName"
							label="Agent Name"
							type="text"
						/>,
						<PhoneInputElement
							{...methods}
							value={agentCellNum}
							onChange={setAgentCellNum}
							name="insuranceAgentCellNum"
							label="Agent Cell Number"
						/>,
						<PhoneInputElement
							{...methods}
							value={agentPhoneNum1}
							onChange={setAgentPhoneNum1}
							name="agentAgentPhoneNum1"
							label="Agent Phone Number1"
						/>,
						<InputElement
							{...methods}
							value={agentPhoneExt1}
							onChange={(e) => setAgentPhoneExt1(e.target.value)}
							name="agentPhoneExt1"
							label="Extension"
							type="text"
						/>,
						<PhoneInputElement
							{...methods}
							value={agentPhoneNum2}
							onChange={setAgentPhoneNum2}
							name="agentAgentPhoneNum2"
							label="Agent Phone Number2"
						/>,
						<InputElement
							{...methods}
							value={agentPhoneExt2}
							onChange={(e) => setAgentPhoneExt2(e.target.value)}
							name="agentPhoneExt2"
							label="Extension"
							type="text"
						/>,
						<PhoneInputElement
							{...methods}
							value={agentFaxNum}
							onChange={setAgentFaxNum}
							name="agentFaxNum"
							label="Agent Fax "
						/>,
						<InputElement
							{...methods}
							value={agentAddress}
							onChange={(e) => setAgentAddress(e.target.value)}
							name="insuranceAgentAddress"
							label="Agent Address"
							type="text"
						/>,
						<InputElement
							{...methods}
							value={agentCity}
							onChange={(e) => setAgentCity(e.target.value)}
							name="insuranceAgentCity"
							label="Agent City"
							type="text"
						/>,
						<SelectorElement
							{...methods}
							name="insuranceState"
							label="State"
							value={state}
							onChange={(e) => setState(e.target.value)}
							options={stateOptions}
						/>,
						<ZipInputElement
							{...methods}
							value={agentZip}
							onChange={setAgentZip}
							openZipModal={handleZipBlur}
							name="insuranceAgentZip"
							label="Agent Zip Code"
						/>,
					]}
				/>
				<form>
					<InputWrapper
						formTitle="Coverage"
						inputComponents={[
							<CheckBoxElement
								{...methods}
								checked={fire}
								onChange={() => setFire(!fire)}
								name="fire"
								label="Fire"
								labelClass="pl-4 required"
							/>,
							<CheckBoxElement
								{...methods}
								checked={theft}
								onChange={() => setTheft(!theft)}
								name="theft"
								label="Theft"
								labelClass="pl-4 required"
							/>,
							<CheckBoxElement
								{...methods}
								checked={comprehensive}
								onChange={() => setComprehensive(!comprehensive)}
								name="comprehensive"
								label="Comprehensive"
								labelClass="pl-4 required"
							/>,
							<CurrencyInputElement
								value={compDeductible}
								onChange={(e) => {
									setCompDeductible(e);
								}}
								name="compDeductible"
								label="Deductible Comprehensive"
								type="text"
							/>,
							<CheckBoxElement
								{...methods}
								checked={collision}
								onChange={() => setCollision(!collision)}
								name="collision"
								label="Collision"
								labelClass="pl-4 required"
							/>,
							<CurrencyInputElement
								value={collDeductible}
								onChange={(e) => {
									setCollDeductible(e);
								}}
								name="collisionDeductible"
								label="Deductible Collision"
								type="text"
							/>,
						]}
					/>
				</form>
				<div className="d-flex justify-content-center">
					<Button color="success" onClick={() => saveIns()}>
						Save
					</Button>
				</div>
				<ChildrenModal
					modal={cityModal}
					toggle={toggleCityModal}
					modalTitle="Cities"
				>
					<ZipCityTable
						data={addressData}
						toggle={toggleCityModal}
						address={{
							city: agentCity,
							zip: agentZip,
							state,
						}}
						setAddress={setAddressFields}
					/>
				</ChildrenModal>
			</Form>
		</>
	);
};

export default CustomerInsurance;
