import { useEffect, useState } from "react";
import crypto from "crypto";

const useCarfaxUrl = () => {
	const [url, setUrl] = useState("");
	const userString = sessionStorage.getItem("user");
	let user = JSON.parse(userString);
	const carfaxState = user["cfState"];

	if (carfaxState == null || carfaxState === "") {
		const state = encodeURIComponent(crypto.randomBytes(16).toString("base64"));

		user = { ...user, cfState: state };
		sessionStorage.setItem("user", JSON.stringify(user));
	}

	useEffect(() => {
		const clientId = encodeURIComponent("13Q0IA2IIW6e6JfgrpI9mossgbjbesKC");
		const callBackUrl = encodeURIComponent(
			"https://dealerclick.app/inventory-list"
		);
		const audience = encodeURIComponent("https://connect.carfax.com");
		const scope = encodeURIComponent("offline_access");
		const resType = encodeURIComponent("code");

		// prettier-ignore
		const carfaxUrl =
		"https://auth.carfax.com/authorize?" + 
		"client_id=" + clientId + 
		"&redirect_uri=" + callBackUrl + 
		"&state=" + carfaxState + 
		"&response_type=" + resType + 
		"&audience=" + audience + 
		"&scope=" + scope;

		setUrl(carfaxUrl);

		// eslint-disable-next-line
	}, []);

	return url;
};

export default useCarfaxUrl;
