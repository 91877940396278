import React from "react";
import GAPForm from "component/dc/deal/sale/modal/gap/GAPForm";
import ChildrenModal from "component/dc/modal/ChildrenModal";
import CollisionForm from "./CollisionForm";
//import { Col, Row } from "reactstrap";
import NavBar from "component/dc/tab/NavTabsHolder";
import CreditInsurance from "./CreditInsurance";

const tabs = ["Gap Insurance", "Collision Insurance", "Credit Insurance"];
const tabComponents = [<GAPForm />, <CollisionForm />, <CreditInsurance />];

// DMV fees calculations are done here
const GAPModal = ({ modal, toggle }) => {
	// Returns input fields and a text field that shows the total of the inputs
	return (
		<ChildrenModal
			modal={modal}
			toggle={toggle}
			size="lg"
			modalTitle="Gap & Insurance"
			children={
				<>
					<div className="col">
						<NavBar
							navClass="nav-pills-secondary justify-content-center nav nav-pills"
							tabs={tabs}
							tabComponents={tabComponents}
						/>
					</div>
					{/* <Row>
						<Col>
							<CollisionForm />
						</Col>
						<Col>
							<GAPForm />
						</Col>
					</Row> */}
				</>
			}
		/>
	);
};

export default GAPModal;
