import React, { useRef } from "react";

//library used to print
import ReactToPrint from "react-to-print";

//here is the component to print, it must be a class to work!!
import BuyersGuide from "view/form/inventory/state/ca/form/BuyersGuide";

//import default reactstrap components
import { Button } from "reactstrap";

//function of the buyers guide
const BuyersGuideView = () => {
	const componentRef = useRef();
	return (
		<div className="content caBuyersGuide text-center" id="caBuyersGuide">
			<div style={{ background: "assets/img/testForm.png" }} alt="buyers guide">
				<BuyersGuide />
			</div>
			<ReactToPrint
				trigger={() => <Button>Print this out!</Button>}
				content={() => componentRef.current}
			/>
		</div>
	);
};

export default BuyersGuideView;
