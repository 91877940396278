import React from "react";
import { NavLink, NavItem } from "reactstrap";

function NavTab({ tabName, onClick, setActive }) {
	return (
		<NavItem>
			<NavLink
				href="#"
				data-toggle="tab"
				role="tablist"
				className={setActive}
				onClick={onClick}
			>
				{tabName}
			</NavLink>
		</NavItem>
	);
}

export default NavTab;
