import React from "react";
import { Row, Table, Button } from "reactstrap";
import useCurrentDealStore from "store/DealStore";
import { confirmDeleteAlert } from "component/dc/modal/SweetAlerts";
import { CurrencyDisplayElement } from "component/dc/label/DisplayElement";
import dayjs from "dayjs";
import { DealStatus } from "Constants";
import {
	deleteSuccessAlert,
	deleteFailAlert,
} from "component/dc/modal/SweetAlerts";
import { showApiError } from "../../../../../../helperFunction/ApiErrorHandler";
import { deleteWarranty } from "api/DealAPI";
import shallow from "zustand/shallow";

const ServiceContractTable = (props) => {
	const { toggle, setCurrentIndex, vendorOption } = props;
	const {
		warranty,
		type,
		editWarrantyList,
		warrantyList,
		calculate,
	} = useCurrentDealStore(
		(state) => ({
			warranty: state.warranty,
			type: state.deal.type,
			editWarrantyList: state.editWarranty,
			warrantyList: state.warranty,
			calculate: state.calculate,
		}),
		shallow
	);

	const serviceContractHead = [
		"#",
		"Company",
		"Amount",
		"Term",
		"Miles",
		// "Exp Miles",
		// "Exp Date",
		"Actions",
	];

	const toggleModal = (currentID, currentIndex) => {
		confirmDeleteAlert(removeWarranty, currentID, currentIndex);
	};
	const removeWarranty = (ID, index) => {
		console.log("Removing service contract with ID: " + ID);
		// item has been saved to database so need to delete there too
		if (ID) {
			deleteWarranty(ID).then(
				(response) => {
					console.log(response);
				},
				(err) => {
					console.error(err);

					if (!err.isGeneralError) {
						showApiError(err, deleteFailAlert);
					}
				}
			);
		}
		console.log(index);

		let obj = [];
		if (ID !== null) {
			obj = warrantyList.filter((item) => item.ID !== ID);
		} else {
			obj = warrantyList.filter((item) => item.currentIndex !== index);
		}
		editWarrantyList(obj);
		setCurrentIndex(obj.length);
		calculate();
		deleteSuccessAlert();
	};

	const tableHead = serviceContractHead.map((key) => <th>{key}</th>);
	console.log(warranty);
	const rows = warranty.map((key, index) => (
		<tr key={index}>
			<th scope="row">{index + 1}</th>
			<td>{vendorOption.filter((e) => e.value === key.coRecNum)[0]?.label}</td>
			<td>{<CurrencyDisplayElement val={key.amount} />}</td>
			<td>{key.term}</td>
			{/* <td>{key.miles}</td>
			<td>{key.expMiles}</td> */}
			<td>{dayjs(key.expires).format("MM/DD/YYYY")}</td>
			<td className="text-center">
				<Button
					readOnly={type !== DealStatus.PENDING}
					className="btn-sm"
					color="primary"
					onClick={() => {
						setCurrentIndex(index);
						toggle();
					}}
				>
					Edit
				</Button>
				<Button
					readOnly={type !== DealStatus.PENDING}
					className="btn-sm"
					color="danger"
					onClick={() => {
						toggleModal(key.ID, key.currentIndex);
					}}
				>
					X
				</Button>
			</td>
		</tr>
	));
	return (
		<Row>
			<Table striped bordered hover>
				<thead>
					<tr>{tableHead}</tr>
				</thead>
				<tbody>{rows}</tbody>
			</Table>
		</Row>
	);
};

export default ServiceContractTable;
