import React from "react";
import { Row, Col } from "reactstrap";
import { Button } from "reactstrap";

import RecapSummaryCard from "../../../../component/dc/deal/recap/RecapSummaryCard";
import RecapMainCard from "../../../../component/dc/deal/recap/RecapMainCard";
import RecapFinanceCard from "../../../../component/dc/deal/recap/RecapFinanceCard";
import useSetDeal from "../../../../helperFunction/deal/useSetDeal";
import { saveDeal } from "../../../../api/DealAPI";
import { saveFailAlert, saveSuccessAlert } from "../../modal/SweetAlerts";
import { showApiError } from "../../../../helperFunction/ApiErrorHandler";
import { useFromUser } from "store/LocalStorageHelper";
import useBuildDeal from "helperFunction/deal/useBuildDeal";
import { formLoading } from "api/FormsAPI";
import { getPdf } from "api/FormsAPI";
import { createFileAndDownload, displayPdf } from "../../../../api/FormsAPI";
import Swal from "sweetalert2";
import { displayError } from "api/FormsAPI";

const Recap = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const getDeal = useBuildDeal();
	const setDeal = useSetDeal();

	const handlePrint = () => {
		const deal = getDeal();
		const dealID = deal.deal.ID;
		const body = {
			dealID: dealID,
			inventoryID: null,
			// 205 is ID for deal recap in dcReport
			dcReportID: 205,
			params: { dealerID: dealerID, locationID: locationID },
		};
		formLoading("PDF");
		saveDeal(deal).then(
			(res) => {
				setDeal(res);
				saveSuccessAlert();
				body.dealID = res.data.content.deal.ID;
				getPdf(body).then(
					(response) => {
						const fileName = "ST_DEAL_RECAP_" + dealID + ".pdf";
						createFileAndDownload(response, fileName);
						displayPdf(response);
						Swal.close();
					},
					(err) => {
						displayError(err);
					}
				);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	return (
		<Row>
			<Col xl="8" lg="12">
				<RecapMainCard />
				<RecapFinanceCard />
			</Col>
			<Col>
				<RecapSummaryCard />
				<Button
					onClick={() => {
						handlePrint();
					}}
				>
					Print
				</Button>
			</Col>
		</Row>
	);
};

export default Recap;
