import { useEffect, useCallback } from "react";
import useCurrentSaleStore from "../../store/SaleStore";
import useCurrentDealStore from "../../store/DealStore";
import useCurrentRecapStore from "../../store/RecapStore";
import {
	FindApr,
	calculateAmountFinanced,
	calculateFirstDueDate,
	calculateFinalDueDate,
	calculatePayment,
	calculateFinalPayment,
	calculateLoan,
	calculateFinanceCharge,
	calcLicenseFee,
	calcRegistrationFee,
	calcDMVFees,
	rollByTerm,
	calculateBalloon,
	convertToDollar,
} from "./SaleFunctions";
import { profitSettingsEnum } from "Constants.js";
import shallow from "zustand/shallow";
import { commissionSettingsEnum, commTypeList } from "../../Constants";
//import { getByCountyRecNum } from "../../api/DealAPI";

const useCalculateSales = () => {
	const {
		price,
		totalDMVFees,
		totalSellerFees,
		gap,
		warranty,
		aftmkt,
		down,
		trade,
		totalPickupPmts,
		totalNetTrade,
		tax,
		manufacturerRebate,
		editAmtFinanced,
		daysToPay,
		loanDate,
		loanPeriod,
		term,
		firstDueDate,
		apr,
		addOn,
		editAddOn,
		amtFinanced,
		loan,
		financeCharge,
		editFirstDueDate,
		editFinalDueDate,
		editPayment,
		editFinalPmt,
		editFinanceCharge,
		editLoan,
		editRegularPmt,
		editAftmkt,
		editAftmktCost,
		editTrade,
		editPayoff: editSalePayoff,
		editTotalNetTrade,
		editTotalPickupPmts,
		docFee,
		dealerSmog,
		stateSmog,
		editTotalSellerFees,
		editTotalNonTaxableSellerFees,
		editWarranty,
		aftmktNonTaxable,
		editAftmktNonTaxable,
		editTaxableAmount,
		editSalesTax,
		editMonthlyTax,
		editDeferredTax,
		editTotalTaxable,
		// editLocalTax,
		// editBusinessTax,
		// editLuxuryTax,
		editVit,
		editVitRate,
		editTax,
		taxRate,
		taxType,
		monthlyTax,
		deferredTax,
		editTaxType,
		editLicense,
		licenseFee,
		licensePct,
		editRegistration,
		registrationFee,
		registrationPct,
		titleFee,
		filingFee,
		smogExemption,
		weightFee,
		tireFee,
		bpa,
		editTotalDMVFees,
		bankFee,
		aftmktCost,
		fiDiscount,
		editFiDiscount,
		discount,
		vit,
		rollBack,
		editTerm,
		desiredPmt,
		balloonTerm,
		editBalloon,
		editBalloonDate,
		editBalloonTerm,
		editBalloonFinanceCharge,
		editDown,
		editOnePay,
		collisionTotPremium,
		editCollisionTotPremium,
		// userTax1,
		// userTax2,
		// userTax3,
		// userTax4,
		// userTax5,
		// userTax6,
		editUserTax1,
		editUserTax2,
		editUserTax3,
		editUserTax4,
		editUserTax5,
		editUserTax6,
		lifePremium,
		disabilityPremium,
		hasBalloon,
		fixedAmountTax,
		editTradeTaxCredit,
	} = useCurrentSaleStore((state) => state, shallow);

	const {
		aftermarket,
		trades,
		pickupPmts,
		miscSellerFees,
		miscDMVFees,
		warranty: warrantyList,
		vehicle,
		downPayments,
		commissions,
		editCommissions,
		editCancellationAmt,
		editRestockingFee,
		county,
		vehicleRecon,
		calculateBool,
	} = useCurrentDealStore((state) => state, shallow);
	const {
		dmvTaxRate,
		servContractTaxRate,
		aftMktTaxRate,
		gapTaxRate,
		// taxRate,
		docFeeTaxRate,
		dealerSmogTaxRate,
		// vitRate,
		taxCreditRate,

		taxCreditFixAmount,
		maxTaxCredit,

		// localTaxRate,
		// businessTaxRate,
		// luxuryTaxRate,
		vitRate,
		bodyInjuryPremium,
		collisionPremium,
		compPremium,
		medicalPremium,
		otherPremium,
		propertyPremium,
	} = useCurrentDealStore(
		(state) => ({
			dmvTaxRate: state?.deal?.dealSettings?.taxDefaults?.dmvTaxRate,
			servContractTaxRate:
				state?.deal?.dealSettings?.taxDefaults?.servContractTaxRate,
			aftMktTaxRate: state?.deal?.dealSettings?.taxDefaults?.aftMktTaxRate,
			gapTaxRate: state?.deal?.dealSettings?.taxDefaults?.gapTaxRate,
			// taxRate: state?.deal?.dealSettings?.taxDefaults?.taxRate,
			docFeeTaxRate: state?.deal?.dealSettings?.taxDefaults?.docFeeTaxRate,
			dealerSmogTaxRate:
				state?.deal?.dealSettings?.taxDefaults?.dealerSmogTaxRate,
			taxCreditRate: state?.deal?.dealSettings?.taxDefaults?.taxCreditRate,
			taxCreditFixAmount:
				state?.deal?.dealSettings?.taxDefaults?.taxCreditFixAmount,
			maxTaxCredit: state?.deal?.dealSettings?.taxDefaults?.maxTaxCredit,

			vitRate: state?.deal?.dealSettings?.taxDefaults?.vitRate,

			bodyInjuryPremium: state.collisionInsurance?.bodyInjuryPremium,
			collisionPremium: state.collisionInsurance?.collisionPremium,
			compPremium: state.collisionInsurance?.compPremium,
			medicalPremium: state.collisionInsurance?.medicalPremium,
			otherPremium: state.collisionInsurance?.otherPremium,
			propertyPremium: state.collisionInsurance?.propertyPremium,
		}),
		shallow
	);

	const { cancellationAmt, cancellationAccepted } = useCurrentDealStore(
		(state) => state?.contractInfo,
		shallow
	);

	const { countyRecNum } = useCurrentDealStore((state) => state.deal, shallow);

	// profit settings
	const {
		isDocFeeProfit,
		isDealerSmogProfit,
		isReserveProfit,
		isServConProfit,
		isGapProfit,
		isAftMktProfit,
		isMiscProfit,
		isBankFeeProfit,
	} = useCurrentDealStore(
		(state) => state?.deal?.dealSettings?.profitSettings,
		shallow
	);

	// Commission settings
	const {
		isDocFeeComm,
		isAftMktComm,
		isServConComm,
		isGapComm,
		isMiscComm,
		isBankFeeComm,
		isReserveComm,
		isSmogComm,
	} = useCurrentDealStore(
		(state) => state?.deal?.dealSettings?.commissionSettings,
		shallow
	);

	// used for commission calc
	const {
		//credLifeProfit,
		//disabProfit,
		collisionTotProfit,
		fiGross,
		totalGross,
		salesGross,
		collisionTotCost,
		totalLenderFee,
		editFiGross,
		editSalesGross,
		editTotalGross,
		editTotalLenderFee,
		editNetCheckToDealer,
		editCollisionTotProfit,
	} = useCurrentRecapStore((state) => state, shallow);

	const {
		VSICost,
		miscCost,
		gapInsCost,
		warrantyCost,
		editCarProfit,
		editAftmktProfit,
		editTotalTradeAllowance,
		editTotalTradeACV,
		editTotalTradePayoff,
		editTotalTradeProfit,
		editTotalDown,
		editMiscProfit,
		editGapInsProfit,
		editWarrantyProfit,
		interestCost,
		dlrParticipation,
		additionalProfit,
		editInterestProfit,
		editInterestNetProfit,
		//editAdditionalProfit,
		carProfit,
		aftmktProfit,
		totalTradeProfit,
		miscProfit,
		gapInsProfit,
		warrantyProfit,
		frontendProfit,
		backendProfit,
		lenderFee,
		lenderDiscont,
		incentive,
		reserve,
		totalDown,
		editFrontendProfit,
		editBackendProfit,
		interestNetProfit,
		grossProfit,
		commGross,
		//credLifeProfit,
		//disabProfit,
		editGrossProfit,
		//recon,
		//official,
		//additional,
		gpBeforeFees,
		gpBeforeReserve,
		//gpBeforeComm,
		//receivable,
		reservePercent,
		editOfficial,
		//editAdditional,
		editGpBeforeFees,
		editGpBeforeReserve,
		editGpBeforeComm,
		editReceivable,
		editReserve,
		//editReservePercent,
		// editLenderFee,
		editInterestCost,
		editDlrParticipation,
		editMiscCost,
		editWarrantyCost,
		editNetProfit,
		editSalesComm,
		editFiComm,
		editTotalComm,
		editProfitComm,
		editCommGross,
		editReconProfit,
		editRecon,
	} = useCurrentRecapStore((state) => state, shallow);

	const twoDecimal = (value) => {
		return parseFloat(parseFloat(value).toFixed(2));
	};

	// Can modify this once we are tracking in house deals
	// For now this provides the necessary logic
	const isInHouse = () => {
		return false;
	};

	const jsonRecondtioning = JSON.stringify(vehicleRecon);
	const vehicleCost = vehicle?.totalCost || 0;
	const vehicleTotalOtherCost = vehicle?.totalOtherCost || 0;
	const vehicleTotalRecon = vehicle?.totalRecon || 0;
	const holdback = vehicle?.holdback || 0;
	const pack = vehicle?.pack || 0;

	// try memoCalculateSales with all of the function calls and then a single useEffect for them all
	const memoCalculateSales = useCallback(() => {
		// Calculate  Total Seller Fees

		const calcMiscSellerFees = () => {
			let nonTaxSum = 0.0;
			let sum =
				parseFloat(docFee ? docFee : 0) +
				parseFloat(dealerSmog ? dealerSmog : 0) +
				parseFloat(stateSmog ? stateSmog : 0);
			let sumCost = 0;
			miscSellerFees.forEach((key) => {
				//totalNonTaxableSellerFees
				if (key.isTaxable) {
				} else {
					nonTaxSum += key.amount;
				}
				sum += key.amount;
				sumCost += key.cost;
			});
			console.log(miscSellerFees);
			parseFloat(nonTaxSum).toFixed(2);
			parseFloat(sum).toFixed(2);
			parseFloat(sumCost).toFixed(2);
			editMiscCost(sumCost);
			editTotalSellerFees(sum);
			editTotalNonTaxableSellerFees(nonTaxSum);
		};
		calcMiscSellerFees();

		// Calculate  Total Service Contract (Warranty)
		const calcServConTotal = () => {
			let sum = 0;
			let sumCost = 0;
			warrantyList.forEach((key) => {
				sum += key.amount;
				sumCost += key.cost;
			});
			console.log(warrantyList);
			parseFloat(sum).toFixed(2);
			parseFloat(sumCost).toFixed(2);
			editWarranty(sum);
			editWarrantyCost(sumCost);
		};
		calcServConTotal();

		// Calculate  Total DMV Fees
		let license = calcLicenseFee(licenseFee, licensePct, price);
		editLicense(license);

		let registration = calcRegistrationFee(
			registrationFee,
			registrationPct,
			price
		);
		editRegistration(registration);

		let totDMVFees = calcDMVFees(
			license,
			registration,
			titleFee,
			filingFee,
			smogExemption,
			weightFee,
			tireFee,
			bpa,
			miscDMVFees
		);
		editTotalDMVFees(totDMVFees);

		// Calculate  Total AfterMarket
		const calcAftermarket = () => {
			let sum = 0;
			let nonTaxSum = 0;
			let sumCost = 0;
			aftermarket.forEach((key) => {
				if (key.isTaxable) {
					sum += key.price * key.qty;
				} else {
					nonTaxSum += key.price * key.qty;
					sum += key.price * key.qty;
				}
				sumCost += key.cost * key.qty;
			});

			editAftmktCost(sumCost);
			editAftmktNonTaxable(nonTaxSum);
			editAftmkt(sum);
		};
		calcAftermarket();

		const calcTotPremium = () => {
			editCollisionTotPremium(
				bodyInjuryPremium +
					collisionPremium +
					compPremium +
					medicalPremium +
					otherPremium +
					propertyPremium
			);
		};
		calcTotPremium();

		// Calculate Net Trade
		const calcNetTrade = () => {
			// total allowance
			let totalTrade = 0;
			let totalPayoff = 0;
			if (trades.length > 0) {
				trades.forEach((element) => {
					totalTrade += element.allowance;
					totalPayoff += element.payoff;
				});
			}
			editTrade(totalTrade);
			editSalePayoff(totalPayoff);
			let totalNet = totalTrade - totalPayoff;
			editTotalNetTrade(totalNet);
		};
		calcNetTrade();

		// Calculate Total Pickup Payments
		const calcTotalPickupPmts = () => {
			let sum = 0;
			pickupPmts.forEach((key) => {
				sum += parseFloat(key.amount);
				console.log(sum);
			});
			const total = parseFloat(sum);
			editTotalPickupPmts(total);
		};
		calcTotalPickupPmts();

		//calculate total down payments
		const calcDownPayments = () => {
			let sum = 0;
			if (downPayments.length > 0) {
				downPayments.forEach((key) => {
					if (key.status !== "1") {
						sum += parseFloat(key.amtPaid);
					}
				});
				parseFloat(sum).toFixed(2);
				editDown(sum);
			}
			console.log(down);
		};
		calcDownPayments();

		// Calculate TAX and SalesTAX
		let xmonthlyTax = 0;
		let xdeferredTax = 0;
		const calculateTaxes = () => {
			/**
			 * Total taxable amount that uses the default taxRate.
			 * This value can be either the taxRate of dealership zip or customer zip.
			 * "-1" signifies the item is taxed with the default tax rate in which case it is added to taxable ammount
			 */
			console.log({
				dmvTaxRate,
				servContractTaxRate,
				aftMktTaxRate,
				gapTaxRate,
				docFeeTaxRate,
				dealerSmogTaxRate,
				taxCreditRate,
				taxCreditFixAmount,
				maxTaxCredit,
				vitRate,
				bodyInjuryPremium,
				collisionPremium,
				compPremium,
				medicalPremium,
				otherPremium,
				propertyPremium,
			});
			console.log("price = " + price);
			console.log("totalDMVFees = " + totalDMVFees);
			console.log("warranty = " + warranty);
			console.log("docFee = " + docFee);
			console.log("dealerSmog = " + dealerSmog);
			//console.log("collision = " + collisionTotPremium);
			console.log("gap = " + gap);
			console.log("gapTaxRate = " + gapTaxRate);
			console.log("TaxRate = " + taxRate);

			let taxableAmount =
				parseFloat(price) +
				(dmvTaxRate === -1 ? parseFloat(totalDMVFees) : 0) +
				(servContractTaxRate === -1 ? parseFloat(warranty) : 0) +
				(docFeeTaxRate === -1 ? parseFloat(docFee) : 0) +
				(dealerSmogTaxRate === -1 ? parseFloat(dealerSmog) : 0) +
				//	(gapTaxRate === -1 ? parseFloat(collisionTotPremium) : 0) +
				(gapTaxRate === -1 ? parseFloat(gap) : 0) +
				(aftMktTaxRate === -1
					? parseFloat(aftmkt) - parseFloat(aftmktNonTaxable)
					: 0);

			console.log("taxableAmount = " + taxableAmount);
			console.log("aftmkt = " + aftmkt);
			console.log("aftmktNonTaxable = " + aftmktNonTaxable);
			console.log("miscSellerFees = " + miscSellerFees.length);

			miscSellerFees.forEach((element) => {
				taxableAmount += element.isTaxable ? parseFloat(element.amount) : 0;
				console.log(element.isTaxable + "Misc = " + parseFloat(element.amount));
			});
			console.log("taxableAmount = " + taxableAmount);

			miscDMVFees.forEach((element) => {
				taxableAmount += element.isTaxable ? parseFloat(element.amount) : 0;
				console.log(
					element.isTaxable + "MiscDMV = " + parseFloat(element.amount)
				);
			});

			let taxTradinCredit = 0.0;
			console.log(trade);
			console.log(taxCreditRate);

			taxTradinCredit =
				parseFloat(trade) * parseFloat(taxCreditRate) +
				parseFloat(taxCreditFixAmount);

			if (taxTradinCredit > maxTaxCredit && maxTaxCredit > 0)
				taxTradinCredit = maxTaxCredit;

			console.log("taxTradinCredit = " + taxTradinCredit);
			editTradeTaxCredit(taxTradinCredit);
			taxableAmount = parseFloat(taxableAmount) - parseFloat(taxTradinCredit);
			taxableAmount = taxableAmount < 0 ? 0 : taxableAmount;
			editTaxableAmount(taxableAmount);
			/**
			 * Total amount of money that is taxable.
			 * This is has no regard for associated tax rates and is not used for
			 * any calculations, simply informative
			 **/
			console.log("taxableAmount = " + taxableAmount);
			console.log("totalDMVFees = " + totalDMVFees);
			console.log("warranty = " + warranty);
			console.log("docFee = " + docFee);
			console.log("dealerSmog = " + dealerSmog);
			console.log("aftmkt = " + aftmkt);
			console.log("aftmktNonTaxable = " + aftmktNonTaxable);

			let totalTaxable = 0.0;
			totalTaxable =
				parseFloat(taxableAmount) +
				(dmvTaxRate > 0 ? parseFloat(totalDMVFees) : 0) +
				(servContractTaxRate > 0 ? parseFloat(warranty) : 0) +
				(docFeeTaxRate > 0 ? parseFloat(docFee) : 0) +
				(dealerSmogTaxRate > 0 ? parseFloat(dealerSmog) : 0) +
				(aftMktTaxRate > 0
					? parseFloat(aftmkt) - parseFloat(aftmktNonTaxable)
					: 0) +
				//(gapTaxRate > 0 ? parseFloat(collisionTotPremium) : 0) +
				(gapTaxRate > 0 ? parseFloat(gap) : 0);

			console.log("totalTaxable = " + totalTaxable);
			editTotalTaxable(totalTaxable);
			let tmpSalesTax = parseFloat(taxableAmount) * parseFloat(taxRate);
			console.log(taxableAmount, taxRate, tmpSalesTax);
			const calcUserTax = (taxRate, border, rateBelow) => {
				let bord = parseFloat(taxableAmount) - parseFloat(border);
				let tmpUserTax = (bord < 0, 0, parseFloat(bord)) * parseFloat(taxRate);
				tmpUserTax +=
					(bord < 0, parseFloat(taxableAmount), parseFloat(border)) *
					parseFloat(rateBelow);
				console.log(taxableAmount, taxRate, border, rateBelow, tmpUserTax);
				return tmpUserTax;
			};

			if (countyRecNum > 0 && !county === null) {
				if (county.isTaxSplit) {
					tmpSalesTax = 0;
					//#1
					let tmpUserTax = 0;
					tmpUserTax = calcUserTax(
						county.userTaxRate1,
						county.userBorder1,
						county.userRateBelow1,
						county.userRateBelow1
					);
					editUserTax1(tmpUserTax);
					tmpSalesTax = tmpSalesTax + parseFloat(tmpUserTax);
					console.log(tmpSalesTax);
					//#2
					tmpUserTax = calcUserTax(
						county.userTaxRate2,
						county.userBorder2,
						county.userRateBelow2,
						county.userRateBelow2
					);
					editUserTax2(tmpUserTax);
					tmpSalesTax += tmpUserTax;
					console.log(tmpSalesTax);
					//#3
					tmpUserTax = calcUserTax(
						county.userTaxRate3,
						county.userBorder3,
						county.userRateBelow3,
						county.userRateBelow3
					);
					editUserTax3(tmpUserTax);
					tmpSalesTax += tmpUserTax;
					console.log(tmpSalesTax);
					//#4
					tmpUserTax = calcUserTax(
						county.userTaxRate4,
						county.userBorder4,
						county.userRateBelow4,
						county.userRateBelow4
					);
					editUserTax4(tmpUserTax);
					tmpSalesTax += tmpUserTax;
					console.log(tmpSalesTax);
					//#5
					tmpUserTax = calcUserTax(
						county.userTaxRate5,
						county.userBorder5,
						county.userRateBelow5,
						county.userRateBelow5
					);
					editUserTax5(tmpUserTax);
					tmpSalesTax += tmpUserTax;
					console.log(tmpSalesTax);
					//#6
					tmpUserTax = calcUserTax(
						county.userTaxRate6,
						county.userBorder6,
						county.userRateBelow6,
						county.userRateBelow6
					);
					editUserTax6(tmpUserTax);
					tmpSalesTax += tmpUserTax;
				}
			}
			console.log(county);
			console.log(tmpSalesTax);
			let tmpOtherSalesTax =
				(dmvTaxRate > 0 ? dmvTaxRate * parseFloat(totalDMVFees) : 0) +
				(servContractTaxRate > 0
					? servContractTaxRate * parseFloat(warranty)
					: 0) +
				(docFeeTaxRate > 0 ? docFeeTaxRate * parseFloat(docFee) : 0) +
				(dealerSmogTaxRate > 0
					? dealerSmogTaxRate * parseFloat(dealerSmog)
					: 0) +
				(gapTaxRate > 0 ? gapTaxRate * parseFloat(gap) : 0) +
				//(gapTaxRate > 0 ? gapTaxRate * parseFloat(collisionTotPremium) : 0) +
				(aftMktTaxRate > 0
					? aftMktTaxRate * (parseFloat(aftmkt) - parseFloat(aftmktNonTaxable))
					: 0);
			console.log(fixedAmountTax);
			tmpSalesTax = tmpSalesTax + tmpOtherSalesTax + fixedAmountTax;
			if (county != null) {
				const taxMin = county.minimumTax ? county.minimumTax : tmpSalesTax;
				const taxMax = county.maximumTax ? county.maximumTax : tmpSalesTax;
				tmpSalesTax = tmpSalesTax < taxMin ? county.minimumTax : tmpSalesTax;
				tmpSalesTax = tmpSalesTax > taxMax ? county.maximumTax : tmpSalesTax;
			}

			editSalesTax(tmpSalesTax);

			console.log(taxType);
			if (taxType === 1) {
				editMonthlyTax(parseFloat(convertToDollar(tmpSalesTax / term)));
				editDeferredTax(tmpSalesTax);
				xmonthlyTax = parseFloat(convertToDollar(tmpSalesTax / term));
				xdeferredTax = tmpSalesTax;
			} else {
				editMonthlyTax(0);
				editDeferredTax(0);
				xmonthlyTax = 0;
				xdeferredTax = 0;
			}
			console.log({ xmonthlyTax, xdeferredTax });

			// TODO
			// define what items can have a specified tax rate, need to determine a general form.
			// Should we only allow the dealer to use default tax rate or non taxable?
			// should this be only be controlled in settings?
			console.log({ taxRate });
			console.log({ tmpSalesTax });
			const tmpVit = price * vitRate;
			console.log(vitRate);
			if (isNaN(tmpVit)) {
				editVit(0);
			} else {
				editVit(tmpVit);
				editVitRate(vitRate);
			}
			if (isNaN(taxType)) {
				editTaxType(0);
			}
			console.log("Vit = " + tmpVit);
			const tmpTax =
				Math.round((tmpSalesTax + vit + Number.EPSILON) * 100) / 100;

			//Tax is NaN, set it to 0
			if (isNaN(tmpTax)) {
				editTax(0);
			} else editTax(tmpTax);
			console.log("Tax = " + tmpTax);
		};
		calculateTaxes();

		/**
		 * Calculates cancellation amount and restocking fee based on the CA CCOA
		 */
		const calcCancellationAmt = () => {
			// determine cancellation amount
			if (price <= 5000) {
				editCancellationAmt(75);
			} else if (price <= 10000) {
				editCancellationAmt(150);
			} else if (price <= 30000) {
				editCancellationAmt(250);
			} else {
				editCancellationAmt(price * 0.01);
			}

			// determine restocking fee
			if (price <= 5000) {
				editRestockingFee(175);
			} else if (price <= 10000) {
				editRestockingFee(350);
			} else {
				editRestockingFee(500);
			}
		};
		calcCancellationAmt();

		//Calculate Cash price
		const calcCashPrice = () => {
			const total =
				parseFloat(price) +
				parseFloat(totalDMVFees) +
				parseFloat(totalSellerFees) +
				parseFloat(gap) +
				parseFloat(collisionTotPremium) +
				parseFloat(lifePremium) +
				parseFloat(disabilityPremium) +
				parseFloat(warranty) +
				parseFloat(aftmkt) +
				parseFloat(tax) +
				(cancellationAccepted === 1 ? parseFloat(cancellationAmt) : 0); // +
			//parseFloat(bankFee) +

			parseFloat(total).toFixed(2);
			editOnePay(total);
		};
		calcCashPrice();

		// Calculate First DueDate
		const tmpFirstDueDate = calculateFirstDueDate(daysToPay, loanDate);
		editFirstDueDate(tmpFirstDueDate);
		// Calculate Final DueDate
		const tempFinalDueDate = calculateFinalDueDate(
			loanPeriod,
			term,
			firstDueDate
		);
		editFinalDueDate(tempFinalDueDate);

		// Calculate Amount Finance
		const tempAmountFinanced = calculateAmountFinanced(
			price,
			totalDMVFees,
			totalSellerFees,
			gap,
			warranty,
			aftmkt,
			down,
			totalPickupPmts,
			totalNetTrade,
			taxType,
			tax,
			xdeferredTax,
			manufacturerRebate,
			cancellationAmt,
			cancellationAccepted,
			collisionTotPremium,
			lifePremium,
			disabilityPremium
		);
		console.log(tempAmountFinanced);
		editAmtFinanced(tempAmountFinanced);

		// Calculate Payment
		const tempPayment = calculatePayment(
			apr,
			loanPeriod,
			term,
			tempAmountFinanced,
			loanDate,
			firstDueDate,
			bankFee
		);

		// Calculate Final Payment
		const tempFinalPayment = calculateFinalPayment(
			apr,
			tempPayment,
			term,
			tempAmountFinanced
		);

		const calculatePayments = () => {
			editPayment(tempPayment);
			editFinalPmt(tempFinalPayment);
		};

		console.log(rollBack);
		if (rollBack === 90) {
			console.log("rolling by term...");
			const tempTerm = rollByTerm(
				desiredPmt,
				tempAmountFinanced,
				apr,
				loanPeriod
			);
			const extra = tempTerm - Math.floor(tempTerm);
			editTerm(Math.ceil(tempTerm));
			editPayment(desiredPmt);
			editFinalPmt(extra * desiredPmt);
		} else {
			calculatePayments();
		}

		// Calculate FinanceCharge
		let tempFinanceCharge = calculateFinanceCharge(
			tempAmountFinanced,
			tempPayment,
			tempFinalPayment,
			term
		);
		editFinanceCharge(tempFinanceCharge);
		console.log({ tempFinanceCharge });
		// TODO check this
		const tempAdjustmentApr = FindApr(
			apr,
			loanPeriod,
			term,
			tempAmountFinanced,
			loanDate,
			firstDueDate,
			tempPayment
		);
		editAddOn(tempAdjustmentApr);

		const calculateBalloonValues = () => {
			console.log(hasBalloon);
			if (hasBalloon) {
				const tempBalloon = calculateBalloon(
					tempAmountFinanced,
					balloonTerm,
					apr,
					tempPayment,
					loanPeriod
				);
				editBalloon(tempBalloon);
				const tempBalloondate = calculateFinalDueDate(
					loanPeriod,
					balloonTerm,
					firstDueDate
				);
				editBalloonDate(tempBalloondate);
				if (balloonTerm < 0 || balloonTerm > term - 1) {
					editBalloonTerm(term - 1);
				}
				if (tempBalloon > 0 && balloonTerm > 0) {
					const balloonFCh =
						tempBalloon + tempPayment * balloonTerm - tempAmountFinanced;
					console.log({ balloonFCh });
					editBalloonFinanceCharge(balloonFCh);
					editFinanceCharge(balloonFCh);
					tempFinanceCharge = balloonFCh;
				}
			}
		};
		calculateBalloonValues();

		editRegularPmt(tempPayment);
		console.log({ tempPayment });
		console.log({ taxType });
		if (taxType === 1) {
			editPayment(tempPayment + xmonthlyTax);
			console.log(tempPayment + xmonthlyTax);

			let defTax =
				xdeferredTax > 0
					? parseFloat(convertToDollar(xdeferredTax - xmonthlyTax * (term - 1)))
					: 0;
			editFinalPmt(tempFinalPayment + defTax);
			console.log(tempFinalPayment + defTax);
		} else {
			editPayment(tempPayment);
			editFinalPmt(tempFinalPayment);
		}
		console.log({ tempAmountFinanced, tempFinanceCharge, xdeferredTax });
		const tempLoan = calculateLoan(
			tempAmountFinanced,
			tempFinanceCharge,
			xdeferredTax
		);
		console.log({ tempLoan });
		editLoan(tempLoan);
	}, [
		county,
		countyRecNum,
		price,
		totalDMVFees,
		totalSellerFees,
		gap,
		warranty,
		aftmkt,
		down,
		totalPickupPmts,
		totalNetTrade,
		taxType,
		tax,
		editDeferredTax,
		manufacturerRebate,
		daysToPay,
		loanDate,
		loanPeriod,
		term,
		firstDueDate,
		apr,
		aftermarket,
		trades,
		pickupPmts,
		docFee,
		dealerSmog,
		stateSmog,
		miscSellerFees,
		warrantyList,
		aftmktNonTaxable,
		aftMktTaxRate,
		//businessTaxRate,
		dmvTaxRate,
		// editBusinessTax,
		// editLocalTax,
		// editLuxuryTax,
		//localTaxRate,
		//luxuryTaxRate,
		servContractTaxRate,
		taxRate,
		dealerSmogTaxRate,
		docFeeTaxRate,
		gapTaxRate,
		taxCreditRate,
		taxCreditFixAmount,
		maxTaxCredit,
		trade,
		cancellationAmt,
		cancellationAccepted,
		licenseFee,
		licensePct,
		registrationFee,
		registrationPct,
		titleFee,
		filingFee,
		smogExemption,
		weightFee,
		tireFee,
		bpa,
		bankFee,
		miscDMVFees,
		vitRate,
		vit,
		rollBack,
		desiredPmt,
		balloonTerm,
		hasBalloon,
		downPayments,
		bodyInjuryPremium,
		collisionPremium,
		compPremium,
		medicalPremium,
		otherPremium,
		propertyPremium,
		collisionTotPremium,
		lifePremium,
		disabilityPremium,
		fixedAmountTax,

		// edit functions
		editAmtFinanced,
		editFirstDueDate,
		editFinalDueDate,
		editPayment,
		editFinalPmt,
		editFinanceCharge,
		editLoan,
		editRegularPmt,
		editAftmktCost,
		editAftmkt,
		editTrade,
		editSalePayoff,
		editTotalNetTrade,
		editTotalPickupPmts,
		editTotalSellerFees,
		editTotalNonTaxableSellerFees,
		editMiscCost,
		editWarranty,
		editWarrantyCost,
		editAftmktNonTaxable,
		editSalesTax,
		editTax,
		editMonthlyTax,
		editTaxableAmount,
		editTotalTaxable,
		editLicense,
		editRegistration,
		editTotalDMVFees,
		editAddOn,
		editVit,
		editVitRate,
		editTerm,
		editBalloon,
		editBalloonDate,
		editBalloonTerm,
		editBalloonFinanceCharge,
		editDown,
		editOnePay,
		editCollisionTotPremium,
		editCancellationAmt,
		editRestockingFee,
		editUserTax1,
		editUserTax2,
		editUserTax3,
		editUserTax4,
		editUserTax5,
		editUserTax6,
		editTaxType,
		editTradeTaxCredit,
	]);

	const memoCalculateRecap = useCallback(() => {
		console.log({
			amtFinanced,
			financeCharge,
			monthlyTax,
			deferredTax,
		});
		const calcVehicleProfit = () => {
			const total = price - vehicleCost;
			editCarProfit(total);
		};
		calcVehicleProfit();

		const calcReconProfit = () => {
			const total = vehicleTotalOtherCost + vehicleTotalRecon;
			editRecon(total);
			editReconProfit(total * -1);
		};
		calcReconProfit();

		const calcTradeProfit = () => {
			// need to add up all acv and allowance
			let totalAcv = 0;
			let totalAllowance = 0;
			let totalPayoff = 0;
			trades.forEach((element) => {
				totalAcv += element.acv;
				totalAllowance += element.allowance;
				totalPayoff += element.payoff;
			});
			editTotalTradeACV(totalAcv);
			editTotalTradeAllowance(totalAllowance);
			editTotalTradePayoff(totalPayoff);
			const profit = totalAcv - totalAllowance;
			editTotalTradeProfit(profit);
		};
		calcTradeProfit();

		const calcDownProfit = () => {
			const total = totalNetTrade + down + totalPickupPmts + manufacturerRebate;
			console.log(totalNetTrade);
			console.log(down);
			console.log(totalPickupPmts);
			console.log(manufacturerRebate);

			editTotalDown(total);
		};
		calcDownProfit();
		////is not profitable
		const calcOfficialFeesProfit = () => {
			const total = tax + totalDMVFees;
			editOfficial(total);
		};
		calcOfficialFeesProfit();

		const calcAftermarketProfit = () => {
			const total = aftmkt - aftmktCost;
			if (isAftMktProfit !== profitSettingsEnum.NOT_PROFIT.value) {
				editAftmktProfit(total);
			} else {
				editAftmktProfit(0);
			}
		};
		calcAftermarketProfit();

		const calcMiscProfit = () => {
			let total = totalSellerFees - miscCost - docFee - dealerSmog - stateSmog;

			if (isMiscProfit === profitSettingsEnum.NOT_PROFIT.value) {
				total = 0;
			}
			if (isDocFeeProfit !== profitSettingsEnum.NOT_PROFIT.value) {
				total += docFee;
			}
			if (isDealerSmogProfit !== profitSettingsEnum.NOT_PROFIT.value) {
				total = total + dealerSmog;
			}
			editMiscProfit(total);
			console.log(total);
		};
		calcMiscProfit();
		const calcGapProfit = () => {
			const total = gap - gapInsCost;
			if (isGapProfit !== profitSettingsEnum.NOT_PROFIT.value) {
				editGapInsProfit(total);
			} else {
				editGapInsProfit(0);
			}
		};
		calcGapProfit();

		const calcCollisionTotProfit = () => {
			const total = collisionTotPremium - collisionTotCost;
			if (isGapProfit !== profitSettingsEnum.NOT_PROFIT.value) {
				editCollisionTotProfit(total);
			} else {
				editCollisionTotProfit(0);
			}
		};
		calcCollisionTotProfit();

		const calcWarrantyProfit = () => {
			const total = warranty - warrantyCost;
			if (isServConProfit !== profitSettingsEnum.NOT_PROFIT.value) {
				editWarrantyProfit(total);
			} else {
				editWarrantyProfit(0);
			}
		};
		calcWarrantyProfit();

		const calcGrossFinanProfit = () => {
			if (isInHouse()) {
				editInterestProfit(financeCharge);
				editInterestCost(0);
				editDlrParticipation(1);
			} else {
				// Not cash deal
				if (loanPeriod !== 5) {
					const pmt = calculatePayment(
						interestCost,
						loanPeriod,
						term,
						amtFinanced,
						loanDate,
						firstDueDate,
						bankFee
					);
					console.log(loan);
					const finalPmt = calculateFinalPayment(
						apr,
						pmt,
						term,
						amtFinanced,
						deferredTax,
						monthlyTax
					);
					const lenderFinanceCharge = calculateFinanceCharge(
						amtFinanced,
						pmt,
						finalPmt,
						term,
						deferredTax
					);
					console.log({ pmt, finalPmt, lenderFinanceCharge });
					const grossFinanProfit =
						parseFloat(financeCharge) - parseFloat(lenderFinanceCharge);
					const finalVal = twoDecimal(grossFinanProfit);
					editInterestProfit(finalVal);
					console.log("Interest Profit: " + finalVal);
					const participation =
						parseFloat(finalVal) * parseFloat(dlrParticipation);
					const total = twoDecimal(
						participation + parseFloat(additionalProfit)
					);
					console.log("Interest Net Profit: " + total);
					editInterestNetProfit(total);
				} else {
					// cash deal
					editInterestProfit(0);
				}
			}
		};
		calcGrossFinanProfit();

		// const calcNetFinancingProfit = () => {
		// 	console.log("Interest Profit: " + interestProfit);
		// 	const participation =
		// 		parseFloat(interestProfit) * parseFloat(dlrParticipation);
		// 	const total = participation + parseFloat(additionalProfit);
		// 	const finalVal = twoDecimal(total);
		// 	console.log("Interest Net Profit: " + finalVal);
		// 	editInterestNetProfit(finalVal);
		// };
		// calcNetFinancingProfit();

		const calcFrontend = () => {
			let front = carProfit + totalTradeProfit;
			console.log({ front });
			console.log(isDocFeeProfit === profitSettingsEnum.FRONT.value);
			if (isDocFeeProfit === profitSettingsEnum.FRONT.value) {
				console.log(docFee);
				front += parseFloat(docFee);
			}
			console.log({ front });
			if (isDealerSmogProfit === profitSettingsEnum.FRONT.value) {
				front += parseFloat(dealerSmog);
			}
			if (isMiscProfit === profitSettingsEnum.FRONT.value) {
				front += parseFloat(
					totalSellerFees - miscCost - docFee - dealerSmog - stateSmog
				);
			}
			console.log(totalSellerFees - miscCost - docFee - dealerSmog - stateSmog);

			if (isReserveProfit === profitSettingsEnum.FRONT.value) {
				front += parseFloat(reserve);
			}
			if (isServConProfit === profitSettingsEnum.FRONT.value) {
				front += parseFloat(warrantyProfit);
			}
			if (isGapProfit === profitSettingsEnum.FRONT.value) {
				front += parseFloat(gapInsProfit);
			}
			if (isAftMktProfit === profitSettingsEnum.FRONT.value) {
				front += parseFloat(aftmktProfit);
			}
			if (isBankFeeProfit === profitSettingsEnum.FRONT.value) {
				front += parseFloat(lenderFee);
			}

			const finalVal = twoDecimal(front);
			console.log({ finalVal });
			editFrontendProfit(finalVal);
		};
		calcFrontend();

		const calcBackend = () => {
			let back = interestNetProfit;
			// credLifeProfit +
			// disabProfit +
			if (isDocFeeProfit === profitSettingsEnum.BACK.value) {
				back += parseFloat(docFee);
			}
			if (isDealerSmogProfit === profitSettingsEnum.BACK.value) {
				back += parseFloat(dealerSmog);
			}
			if (isMiscProfit === profitSettingsEnum.BACK.value) {
				// back +=
				// 	parseFloat(miscProfit) - parseFloat(docFee) - parseFloat(dealerSmog);
				back += parseFloat(
					totalSellerFees - miscCost - docFee - dealerSmog - stateSmog
				);
			}

			if (isReserveProfit === profitSettingsEnum.BACK.value) {
				back += parseFloat(reserve);
			}
			if (isServConProfit === profitSettingsEnum.BACK.value) {
				back += parseFloat(warrantyProfit);
			}
			if (isGapProfit === profitSettingsEnum.BACK.value) {
				back += parseFloat(gapInsProfit);
				back += parseFloat(collisionTotProfit);
			}
			if (isAftMktProfit === profitSettingsEnum.BACK.value) {
				back += parseFloat(aftmktProfit);
			}
			if (isBankFeeProfit === profitSettingsEnum.BACK.value) {
				back += parseFloat(lenderFee);
			}

			const finalVal = twoDecimal(back);
			editBackendProfit(finalVal);
		};
		calcBackend();

		const calcGPBeforeFees = () => {
			console.log(frontendProfit);
			console.log(backendProfit);
			const total = parseFloat(frontendProfit) + parseFloat(backendProfit);
			const finalVal = twoDecimal(total);
			editGpBeforeFees(finalVal);
		};
		calcGPBeforeFees();
		const calcLenderFeeInfo = () => {
			console.log(discount);
			const tempFiDisc = amtFinanced * discount;
			const tempTotalLenderfee = lenderFee + tempFiDisc;
			const tempNetCheck = amtFinanced - tempTotalLenderfee;
			console.log(tempNetCheck);
			editFiDiscount(tempFiDisc);
			editTotalLenderFee(tempTotalLenderfee);
			editNetCheckToDealer(tempNetCheck);
		};
		calcLenderFeeInfo();

		const calcGPBeforeReserve = () => {
			console.log(gpBeforeFees);
			console.log(lenderFee);
			console.log(totalLenderFee);
			console.log(lenderDiscont);

			console.log(holdback);
			console.log(incentive);
			console.log(pack);
			const total =
				parseFloat(gpBeforeFees) -
				//parseFloat(lenderFee) +
				parseFloat(totalLenderFee) +
				parseFloat(incentive) +
				parseFloat(holdback);
			const finalVal = twoDecimal(total);
			editGpBeforeReserve(finalVal);
			console.log(gpBeforeFees);
			console.log(finalVal);
		};
		calcGPBeforeReserve();

		const calcGPBeforeComm = () => {
			console.log(gpBeforeReserve);
			console.log(reserve);
			const total = parseFloat(gpBeforeReserve) - parseFloat(reserve);
			const finalVal = twoDecimal(total);
			editGpBeforeComm(finalVal);
			editGrossProfit(finalVal);
			console.log(finalVal);
		};
		calcGPBeforeComm();

		const calcReceivable = () => {
			const total =
				parseFloat(amtFinanced) -
				(parseFloat(lenderFee) + parseFloat(fiDiscount)) +
				parseFloat(interestNetProfit) +
				parseFloat(totalDown);
			const finalVal = twoDecimal(total);
			editReceivable(finalVal);
		};
		calcReceivable();

		const calcNetProfit = () => {
			const finalVal = twoDecimal(grossProfit - commGross);
			editNetProfit(finalVal);
		};
		calcNetProfit();

		const calcReserve = () => {
			const resAmt = amtFinanced * reservePercent;
			editReserve(resAmt);
		};

		calcReserve();
	}, [
		vehicleTotalOtherCost,
		vehicleTotalRecon,
		bankFee,
		price,
		aftmkt,
		aftmktCost,
		trades,
		totalNetTrade,
		down,
		totalPickupPmts,
		manufacturerRebate,
		loan,
		apr,
		tax,
		monthlyTax,
		deferredTax,
		totalDMVFees,
		dealerSmog,
		stateSmog,
		totalSellerFees,
		miscCost,
		gap,
		gapInsCost,
		warranty,
		warrantyCost,
		financeCharge,
		amtFinanced,
		firstDueDate,
		interestCost,
		loanDate,
		term,
		loanPeriod,
		dlrParticipation,
		carProfit,
		totalTradeProfit,
		reserve,
		warrantyProfit,
		gapInsProfit,
		collisionTotProfit,
		isDocFeeProfit,
		isDealerSmogProfit,
		isReserveProfit,
		isServConProfit,
		isGapProfit,
		isAftMktProfit,
		isMiscProfit,
		isBankFeeProfit,
		additionalProfit,
		docFee,
		aftmktProfit,
		backendProfit,
		frontendProfit,
		gpBeforeFees,
		gpBeforeReserve,
		fiDiscount,
		interestNetProfit,
		incentive,
		totalDown,
		discount,
		lenderFee,
		reservePercent,
		holdback,
		pack,
		vehicleCost,
		commGross,
		collisionTotPremium,
		totalLenderFee,
		lenderDiscont,
		// edit functions
		editCarProfit,
		editAftmktProfit,
		editTotalTradeACV,
		editTotalTradeAllowance,
		editTotalTradePayoff,
		editTotalTradeProfit,
		editTotalDown,
		editOfficial,
		editMiscProfit,
		editGapInsProfit,
		editCollisionTotProfit,
		editWarrantyProfit,
		editInterestProfit,
		editInterestNetProfit,
		editFrontendProfit,
		editBackendProfit,
		editGpBeforeFees,
		editGpBeforeReserve,
		editGpBeforeComm,
		editGrossProfit,
		editReceivable,
		editFiDiscount,
		editReserve,
		editInterestCost,
		editDlrParticipation,
		editRecon,
		editReconProfit,
		grossProfit,
		editNetProfit,
		editTotalLenderFee,
		editNetCheckToDealer,
		collisionTotCost,
	]);

	const memoCalculateCommissions = useCallback(() => {
		const twoDecimal = (value) => {
			return parseFloat(parseFloat(value).toFixed(2));
		};

		const calcSalesGross = () => {
			let total = 0;
			total += carProfit;
			total += totalTradeProfit;
			if (
				isDocFeeComm === commissionSettingsEnum.FRONT_COMMISSION.value ||
				isDocFeeComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				total += parseFloat(docFee);
			}

			if (
				isSmogComm === commissionSettingsEnum.FRONT_COMMISSION.value ||
				isSmogComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				total += parseFloat(dealerSmog);
			}

			if (
				isReserveComm === commissionSettingsEnum.FRONT_COMMISSION.value ||
				isReserveComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				total += parseFloat(reserve);
			}

			if (
				isServConComm === commissionSettingsEnum.FRONT_COMMISSION.value ||
				isServConComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				total += parseFloat(warrantyProfit);
			}

			if (
				isGapComm === commissionSettingsEnum.FRONT_COMMISSION.value ||
				isGapComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				total += parseFloat(gapInsProfit);
				total += parseFloat(collisionTotProfit);
			}

			if (
				isAftMktComm === commissionSettingsEnum.FRONT_COMMISSION.value ||
				isAftMktComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				total += parseFloat(aftmktProfit);
			}

			if (
				isMiscComm === commissionSettingsEnum.FRONT_COMMISSION.value ||
				isMiscComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				total += parseFloat(miscProfit);
			}

			if (
				isBankFeeComm === commissionSettingsEnum.FRONT_COMMISSION.value ||
				isBankFeeComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				total += parseFloat(lenderFee);
			}

			editSalesGross(twoDecimal(total));
			// eslint-disable-next-line
		};
		//calcSalesGross();
		const calcFIGross = () => {
			let total = 0;
			total += interestNetProfit;

			if (
				isDocFeeComm === commissionSettingsEnum.BACK_COMMISSION.value ||
				isDocFeeComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				total += parseFloat(docFee);
			}

			if (
				isSmogComm === commissionSettingsEnum.BACK_COMMISSION.value ||
				isSmogComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				total += parseFloat(dealerSmog);
			}

			if (
				isReserveComm === commissionSettingsEnum.BACK_COMMISSION.value ||
				isReserveComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				total += parseFloat(reserve);
			}

			if (
				isServConComm === commissionSettingsEnum.BACK_COMMISSION.value ||
				isServConComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				total += parseFloat(warrantyProfit);
			}
			if (
				isGapComm === commissionSettingsEnum.BACK_COMMISSION.value ||
				isGapComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				total += parseFloat(gapInsProfit);
				total += parseFloat(collisionTotProfit);
				console.log(total);
			}
			if (
				isAftMktComm === commissionSettingsEnum.BACK_COMMISSION.value ||
				isAftMktComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				total += parseFloat(aftmktProfit);
			}

			if (
				isMiscComm === commissionSettingsEnum.BACK_COMMISSION.value ||
				isMiscComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				total += parseFloat(miscProfit);
			}

			if (
				isBankFeeComm === commissionSettingsEnum.BACK_COMMISSION.value ||
				isBankFeeComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				total += parseFloat(lenderFee);
			}

			editFiGross(twoDecimal(total));
		};
		//calcFIGross();
		const calcTotalGross = () => {
			let total = 0;
			total += carProfit;

			total += totalTradeProfit;

			total += interestNetProfit;

			if (isDocFeeComm !== commissionSettingsEnum.NO_COMMISSION.value) {
				total += parseFloat(docFee);
			}
			if (isSmogComm !== commissionSettingsEnum.NO_COMMISSION.value) {
				total += parseFloat(dealerSmog);
			}
			if (isReserveComm !== commissionSettingsEnum.NO_COMMISSION.value) {
				total += parseFloat(reserve);
			}
			if (isServConComm !== commissionSettingsEnum.NO_COMMISSION.value) {
				total += parseFloat(warrantyProfit);
			}
			if (isGapComm !== commissionSettingsEnum.NO_COMMISSION.value) {
				total += parseFloat(gapInsProfit);
			}
			if (isAftMktComm !== commissionSettingsEnum.NO_COMMISSION.value) {
				total += parseFloat(aftmktProfit);
			}
			if (isMiscComm !== commissionSettingsEnum.NO_COMMISSION.value) {
				total += parseFloat(miscProfit);
			}
			if (isBankFeeComm !== commissionSettingsEnum.NO_COMMISSION.value) {
				total += parseFloat(lenderFee);
			}

			total += parseFloat(additionalProfit);

			editTotalGross(twoDecimal(total));

			console.log(total);
			// eslint-disable-next-line
		};

		const calculateCommission = () => {
			let totalComm = 0;
			let totalSale = 0;
			let totalFI = 0;
			let totalAll = 0;
			let totalProfit = 0;
			const newComm = commissions.map((commission) => {
				console.log(commission.commType);

				if (Number(commission.commType) === commTypeList.SALE.value) {
					commission.commAmount = twoDecimal(
						salesGross * commission.commPercent + commission.fixedAmount
					);
					totalSale += commission.commAmount;
				} else if (Number(commission.commType) === commTypeList.FI.value) {
					commission.commAmount = twoDecimal(
						fiGross * commission.commPercent + commission.fixedAmount
					);
					totalFI += commission.commAmount;
				} else if (Number(commission.commType) === commTypeList.TOTAL.value) {
					commission.commAmount = twoDecimal(
						totalGross * commission.commPercent + commission.fixedAmount
					);
					totalAll += commission.commAmount;
				} else if (
					Number(commission.commType) === commTypeList.NETPROFIT.value
				) {
					commission.commAmount = twoDecimal(
						grossProfit * commission.commPercent + commission.fixedAmount
					);
					totalProfit += commission.commAmount;
				}
				totalComm =
					totalComm +
					commission.bonus +
					commission.other +
					commission.commAmount;
				return commission;
			});
			editCommissions(newComm);
			editSalesComm(totalSale);
			editFiComm(totalFI);
			editTotalComm(totalAll);
			editProfitComm(totalProfit);
			editCommGross(totalComm);
			editNetProfit(grossProfit - totalComm);
			console.log({ totalComm, totalSale, totalFI, totalAll, totalProfit });
		};

		calcSalesGross();
		calcFIGross();
		calcTotalGross();
		calculateCommission();
	}, [
		additionalProfit,
		aftmktProfit,
		carProfit,
		commissions,
		dealerSmog,
		docFee,
		editCommissions,
		editFiGross,
		editSalesGross,
		editTotalGross,
		fiGross,
		gapInsProfit,
		collisionTotProfit,
		grossProfit,
		interestNetProfit,
		isAftMktComm,
		isBankFeeComm,
		isDocFeeComm,
		isGapComm,
		isMiscComm,
		isReserveComm,
		isServConComm,
		isSmogComm,
		lenderFee,
		miscProfit,
		reserve,
		salesGross,
		totalGross,
		totalTradeProfit,
		warrantyProfit,
		editSalesComm,
		editFiComm,
		editTotalComm,
		editProfitComm,
		editCommGross,
		editNetProfit,
	]);

	useEffect(() => {
		// left out some values from the dependency array since they get calculated ie payment
		console.log("Beginning Sale Calculation...");
		memoCalculateSales();
		memoCalculateRecap();
		memoCalculateCommissions();
		console.log("Ending Sale Calculation...");
		// eslint-disable-next-line
	}, [
		calculateBool,
		taxType,
		price,
		//totalDMVFees,
		//totalSellerFees,
		//totalNonTaxableSellerFees,
		//gap,
		//warranty,
		//aftmkt,
		down,
		//totalPickup,
		//totalNetTrade,
		//tax,
		manufacturerRebate,
		daysToPay,
		loanDate,
		loanPeriod,
		term,
		firstDueDate,
		apr,
		addOn,
		//amtFinanced,
		//payment,
		//finalPmt,
		//financeCharge,
		//memoCalculateSales,
		jsonRecondtioning,
		taxRate,
		countyRecNum,
		cancellationAmt,
		cancellationAccepted,
		vehicleCost,
		vehicleTotalOtherCost,
		vehicleTotalRecon,
		holdback,
		pack,
		aftmktCost,
		docFee,
		dealerSmog,
		stateSmog,
		VSICost,
		miscCost,
		gapInsCost,
		warrantyCost,
		bankFee,
		interestCost,
		dlrParticipation,
		isDocFeeProfit,
		isDealerSmogProfit,
		isReserveProfit,
		isServConProfit,
		isGapProfit,
		isAftMktProfit,
		isMiscProfit,
		isBankFeeProfit,
		reservePercent,
		rollBack,
		balloonTerm,
		discount,
		bodyInjuryPremium,
		collisionPremium,
		compPremium,
		medicalPremium,
		otherPremium,
		propertyPremium,
		additionalProfit,
		reserve,
		grossProfit,
		salesGross,
		fiGross,
		totalGross,
		carProfit,
		totalTradeProfit,
		docFee,
		dealerSmog,
		reserve,
		warrantyProfit,
		gapInsProfit,
		aftmktProfit,
		miscProfit,
		lenderFee,
		incentive,
		gpBeforeReserve,
		isDocFeeProfit,
		isDealerSmogProfit,
		isReserveProfit,
		isServConProfit,
		isGapProfit,
		isAftMktProfit,
		isMiscProfit,
		isBankFeeProfit,
		isDocFeeComm,
		isAftMktComm,
		isServConComm,
		isGapComm,
		isMiscComm,
		isBankFeeComm,
		isReserveComm,
		isSmogComm,
		additionalProfit,
		collisionTotCost,
		monthlyTax,
		deferredTax,
		hasBalloon,
		licenseFee,
		licensePct,
		registrationFee,
		registrationPct,
		titleFee,
		filingFee,
		smogExemption,
		weightFee,
		tireFee,
		bpa,
		fixedAmountTax,
		taxCreditRate,
		taxCreditFixAmount,
		maxTaxCredit,
	]);
};

export default useCalculateSales;
