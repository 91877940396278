import React from "react";
import { Row, Col, Card, CardTitle, CardBody } from "reactstrap";
import { ReviewRow } from "component/dc/deal/review/Review";
import useCurrentDealStore from "store/DealStore";
import shallow from "zustand/shallow";

const ReviewCustomer = () => {
	const {
		buyerFirstName,
		buyerLastName,
		buyerStreet,
		buyerCity,
		buyerState,
		buyerZip,
		buyerPhone,
		coBuyerFirstName,
		coBuyerLastName,
		// coBuyerStreet,
		// coBuyerCity,
		// coBuyerState,
		// coBuyerZip,
		coBuyerPhone,
	} = useCurrentDealStore(
		(state) => ({
			buyerFirstName: state?.buyer?.firstName || "",
			buyerLastName: state?.buyer?.lastName || "",
			buyerStreet: state?.buyerAddress?.street || "",
			buyerCity: state?.buyerAddress?.city || "",
			buyerState: state?.buyerAddress?.state || "",
			buyerZip: state?.buyerAddress?.zip || "",
			buyerPhone: state?.buyer?.homePhone || "",
			coBuyerFirstName: state?.coBuyer?.firstName || "",
			coBuyerLastName: state?.coBuyer?.lastName || "",
			coBuyerStreet: state?.coBuyerAddress?.street || "",
			coBuyerCity: state?.coBuyerAddress?.city || "",
			coBuyerState: state?.coBuyerAddress?.state || "",
			coBuyerZip: state?.buyerAddress?.zip || "",
			coBuyerPhone: state?.coBuyer?.homePhone || "",
		}),
		shallow
	);

	return (
		<Card style={{ minHeight: "350px" }}>
			<CardBody>
				<CardTitle tag="h6">Customer</CardTitle>
				<Row>
					<Col>
						<ReviewRow
							label="Buyer"
							right={false}
							value={buyerFirstName + " " + buyerLastName}
						/>

						<ReviewRow
							label="Address"
							right={false}
							value={
								buyerStreet +
								" " +
								buyerCity +
								" " +
								buyerState +
								" " +
								buyerZip
							}
						/>
						<ReviewRow
							isPhone={true}
							label="Phone"
							right={false}
							value={buyerPhone}
						/>

						<ReviewRow
							label="Cobuyer"
							right={false}
							value={coBuyerFirstName + " " + coBuyerLastName}
						/>

						{/* <ReviewRow
							label="Address"
							right={false}
							value={
								coBuyerStreet +
								" " +
								coBuyerCity +
								" " +
								coBuyerState +
								" " +
								coBuyerZip
							}
						/> */}
						<ReviewRow
							isPhone={true}
							label="Phone"
							right={false}
							value={coBuyerPhone}
						/>
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
};

export default ReviewCustomer;
