import React, { useState } from "react";

import { TabContent, TabPane, Nav, Card, Row } from "reactstrap";
import classnames from "classnames";
import NavTab from "./NavTab";

function NavTabsHolder({ tabs, tabComponents, active, navClass, getTabName }) {
	const [activeTab, setActiveTab] = useState(active || 0);

	// Toggles between different tabs
	const toggle = (tab, tabName) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}

		if (getTabName != null) {
			getTabName(tabName);
		}
	};

	// All the nav tabs we need
	const navTabs = tabs.map((tab, index) => (
		<NavTab
			id={index}
			key={index}
			tabName={tab}
			setActive={classnames({ active: activeTab === index })}
			onClick={() => toggle(index, tab)}
		/>
	));

	// Content for each tab
	const navTabContents = tabComponents.map((component, index) => (
		<TabPane id={index} key={index} tabId={index}>
			{component}
		</TabPane>
	));

	return (
		<Row className="mx-0">
			<div className="col-12 px-0">
				<Card className="card-plain">
					<Nav className={navClass} pills role="tablist">
						{navTabs}
					</Nav>
					<TabContent
						className="tab-space tab-subcategories"
						activeTab={activeTab}
					>
						{navTabContents}
					</TabContent>
				</Card>
			</div>
		</Row>
	);
}

export default NavTabsHolder;
