import React from "react";

import useBuildCustomer from "../../../helperFunction/BuildCustomerObj";
import useCurrentCustomerStore from "../../../store/CustomerStore";
import NoteButton from "../note/NoteButton";

import { saveFailAlert } from "../modal/SweetAlerts";
import { saveJustCustomer } from "../../../api/CustomerAPI";
import { showApiError } from "../../../helperFunction/ApiErrorHandler";

import shallow from "zustand/shallow";

const CustomerSaveNoteButton = () => {
	const customer = useBuildCustomer();

	// Customer store
	const { ID, notes, editNotes } = useCurrentCustomerStore(
		(state) => ({
			ID: state.ID,
			notes: state.notes,
			editNotes: state.editNotes,
		}),
		shallow
	);

	// If the customer object exists in the DB, save it when adding a new note
	const saveNotes = () => {
		if (ID != null) {
			let modifiedCustomer = { ...customer };

			modifiedCustomer.notes = notes.length > 0 ? JSON.stringify(notes) : "";

			saveJustCustomer(modifiedCustomer).then(
				() => console.log("Customer notes saved"),
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, saveFailAlert);
					}
				}
			);
		}
	};
	console.log(notes);
	console.log(customer.notes);
	return (
		<>
			<NoteButton notes={notes} editNotes={editNotes} saveNotes={saveNotes} />
		</>
	);
};

export default CustomerSaveNoteButton;
