import React from "react";
import logo from "./autocheck-logo.png"; //"C:/JavaProjects/dc2/src/api/assets/img/AutoCheck-logo.png";
import { Input } from "reactstrap";
import { getAutoCheck } from "api/dealerIntegrationsApi";
import useBuildVehicle from "../../../helperFunction/BuildInventoryObj";

export const AutoCheckButton = ({ inventoryID = null }) => {
	const getVehicle = useBuildVehicle();

	const commitData = () => {
		const vehicle = getVehicle();
		let id = null;
		if (inventoryID === null) {
			id = vehicle.inventoryID;
		} else {
			id = inventoryID;
		}
		console.log(id);
		getAutoCheck(id).then(
			(response) => {
				const file = new Blob([response.data], {
					type: "text/html",
				});
				const fileURL = URL.createObjectURL(file);
				window.open(fileURL);
			},
			(error) => {
				console.log(error);
			}
		);
	};

	return (
		<div>
			<Input
				type="image"
				onClick={commitData}
				src={logo}
				alt="AutoCheck"
				className="historyButtons zoom card p-2"
			/>
		</div>
	);
};
export default AutoCheckButton;
